import { Icon, Status } from "components";

const PreparationStatusTemplate = ({ item }: any) => {
  return (
    <div className="space-y-2">
      <h6 className="text-heading-6">
        <Status.SellOff
          id={"salesManagment.sellOffSales.sellOffProduct.preparation"}
        />
      </h6>
      {item?.clonedPreparationPhaseCheckListItems?.length > 0 &&
        item?.clonedPreparationPhaseCheckListItems?.map((p: any) => {
          return (
            <p
              key={p.id}
              className="text-gray-600 text-body-2 font-normal flex text-left items-center"
            >
              {p.isChecked ? (
                <Icon
                  icon={"circle-check"}
                  className="text-success mr-2 w-4 h-4"
                />
              ) : (
                <Icon icon={"circle"} className="mr-2 w-4 h-4" />
              )}
              {p.label}
            </p>
          );
        })}
    </div>
  );
};

export default PreparationStatusTemplate;
