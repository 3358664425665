export const dashboard = {
  title: "Dashboard",
  subTitle: "Startseite",
  productsCompleteness: "Produktvollständigkeit",
  channel: "Kanal",
  publishedProducts: "Veröffentlichte Produkte",
  recentActivity: "Letzte Aktivität",
  recentActivityFilters: {
    last10Days: "Die letzten 10 Tage",
    week: "Woche",
    month: "Monat",
  },
  recentlyAdded: "Kürzlich hinzugefügt",
  sellOffProducts: "Ausverkaufsprodukte",
  recentlySoldSellOffProducts: "Recently Sold Sell-off Products DE",
  soldAt: "Sold At DE",
  soldSellOffProducts: "Verkaufte Ausverkaufsprodukte",
  soldSellOffProductsBadge: "Letzte 7 Tage",
  totalPIMProducts: "Gesamtzahl PIM-Produkte",
  totalPIMProductsItems: {
    in7days: "Produkte in den letzten 7 Tagen",
    newAdded: "Neu hinzugefügt",
    completed: "Abgeschlossen",
    published: "Veröffentlicht",
  },
};
