import { NotificationManager } from "common/notifications";
import {
  Button,
  DeleteModal,
  Icon,
  Select,
  Skeleton,
  Status,
  Text,
  Wrapper,
  InputGroup,
  NoItems
} from "components";
import { useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import AttributeDrawer from "./AttributeDrawer";
import { onFilter } from "utils";

const AttributeGroups = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [isOpen, toggle] = useToggleState();
  const [data, setData] = useState<any>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [availableAttr, setAvailableAttr] = useState<any>([]);
  const [loading, setLoading] = useToggleState();
  const [buttonLoading, setButtonLoading] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [selectedAttribute, setSelectedAttribute] = useState<any>([]);
  const [selectedAvailableAttr, setSelectedAvailableAttr] = useState("");
  const [deleteModal, setDeleteModal] = useToggleState();
  const [deleteData, setDeleteData] = useState<any>({});
  const [searchValue, setSearchValue] = useState("");

  const getAttribute = () => {
    setLoading();
    const url2 = URLS.GET_ALL_ATTRIBUTE(id);
    ServiceApi.get(url2)
      .then(({ data }) => {
        const attributes = data.items.filter(
          (attr: any) => attr.availability !== 1
        );
        const availableAttribute = data.items.filter(
          (attr: any) => attr.availability === 1
        );
        attributes?.forEach((item: any) => {
          item?.translations?.forEach((i: any, index: number) => {
            if (i.language === i18n.language) {
              item["title"] = i.name
            }
          })
        })
        setData(attributes);
        setDataList(attributes);
        setAvailableAttr(availableAttribute);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    getAttribute();
  }, [i18n]);

  const createItems = () => {
    const clone = availableAttr?.map((item: any) => ({
      id: item.attributeGroupTemplateId,
      name: item.code,
    }));
    return clone;
  };

  const addAttrToProductFamilies = () => {
    setButtonLoading();
    const body = {
      attributeGroupTemplateId: selectedAvailableAttr,
      pimProductFamilyId: id,
    };
    const url = URLS.ADD_ATTRIBUTE_TO_PIM_PRODUCT_FAMILY_URL;
    ServiceApi.put(url, body)
      .then(({ data }) => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        setButtonLoading();
        setSelectedAvailableAttr("");
        getAttribute();
      })
      .catch(() => setButtonLoading());
  };

  const onDeleteHandler = () => {
    setDeleteLoading();
    const body = {
      attributeGroupTemplateId: deleteData.attributeGroupTemplateId,
      pimProductFamilyId: id,
    };
    const url = URLS.DELETE_ATTRIBUTE_FROM_PIM_PRODUCT_FAMILY_URL;
    ServiceApi.put(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        setDeleteModal();
        setDeleteLoading();
        getAttribute();
      })
      .catch(() => {
        setDeleteModal();
        setDeleteLoading();
      });
  };

  const onSearchHandler = (e: string) => {
    let keys = ["title", "code"];
    if (e !== "") {
      setDataList(onFilter(data, keys, e));
    } else {
      setDataList(data)
    }
    setSearchValue(e)
  };

  return (
    <>
      {loading ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <Wrapper>
          <Wrapper.Body className="space-y-6">
            <div className="bg-primary-light border border-primary border-dashed p-4 rounded grid grid-cols-3 gap-4">
              <div>
                <Select
                  placeholder="Select"
                  value={selectedAvailableAttr}
                  setValue={(e) => setSelectedAvailableAttr(e)}
                  items={createItems()}
                />
              </div>
              <div>
                <Button
                  className="h-full"
                  onClick={addAttrToProductFamilies}
                  loading={buttonLoading}
                  disabled={!selectedAvailableAttr}
                >
                  <Text>
                    productManagement.masterData.productFamilies.addToFamily
                  </Text>
                </Button>
              </div>
            </div>
            {data?.length !== 0 && <div className="grid grid-cols-5 gap-4 items-center">
              <InputGroup
                value={searchValue}
                setValue={onSearchHandler}
                placeholder="productManagement.masterData.attributesGroups.searchAttribute"
                disabled={loading}
              />
              <p className="text-body-2">
                {searchValue.length === 0
                  ? dataList?.length
                  : dataList?.length}{" "}
                Items
              </p>
            </div>}
            {dataList?.length !== 0 ?
              <div className="w-full ">
                <table
                  className={`min-w-full border-separate border-spacing-y-4 -my-4 text-center [&_tr]:shadow [&_tr]:rounded-2xl [&_tr]:bg-gray-100 [&_td]:p-4 [&_td:first-child]:rounded-l-2xl [&_td:last-child]:rounded-r-2xl [&_td:first-child]:text-left [&_td:last-child]:text-right`}
                >
                  <tbody>
                    {dataList?.map((attr: any) => {
                      return (
                        <tr>
                          <td>
                            <span className="text-heading-6 font-semibold">
                              {
                                attr?.translations?.filter(
                                  (tr: any) =>
                                    tr.language === i18n.language.toLowerCase()
                                )?.[0]?.name
                              }
                            </span>
                          </td>
                          <td>
                            <span className="text-body-2 text-gray-500 font-medium">
                              {attr.code}
                            </span>
                          </td>
                          <td className="space-x-4">
                            {attr.availability === 2 && (
                              <Status.ProductFamilies
                                id={
                                  "productManagement.masterData.productFamilies.inherit"
                                }
                              />
                            )}
                            {attr.availability !== 2 && (
                              <Button
                                size="sm"
                                variant="white"
                                onClick={() => {
                                  setDeleteData(attr);
                                  setDeleteModal();
                                }}
                              >
                                <Icon
                                  icon={"trash-xmark"}
                                  className="text-danger"
                                />
                              </Button>
                            )}
                            <Button
                              variant="white"
                              disabled={attr.templates.length === 0}
                              onClick={() => {
                                setSelectedAttribute(
                                  attr.attributeGroupTemplateId
                                );
                                toggle();
                              }}
                            >
                              <Text>
                                productManagement.masterData.productFamilies.attributes
                              </Text>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              :
              <div className="flex flex-col items-center space-y-4 w-full">
                <NoItems />
              </div>
            }
          </Wrapper.Body>
        </Wrapper>
      )}
      <AttributeDrawer
        isOpen={isOpen}
        toggle={toggle}
        selectedAttribute={selectedAttribute}
      />
      <DeleteModal
        isOpen={deleteModal}
        loading={deleteLoading}
        toggle={setDeleteModal}
        onConfirm={onDeleteHandler}
        selected={{ name: deleteData.code }}
      />
    </>
  );
};

export default AttributeGroups;
