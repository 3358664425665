import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  Icon,
  NoItems,
  Status
} from "components";
import { usePermission, useToggleState } from "hooks";
import { ServiceApi, URLS } from "services";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { config, appPermissions } from "constant";
import { HandleParamsUrl } from "utils"
import FilterDrawer from "./FilterDrawer"

type EmployeesListItems = {
  employeeId: string;
  displayName: string;
  jobTitle: string;
  emailAddress: string;
  store: any;
  departments: any;
  employeeNumber: string;
  userType: string;
};


type ListType = {
  items?: EmployeesListItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function EmployeesList() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [filterModal, setFilterModal] = useToggleState(false);
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const originalFilterData = {
    keyword: "",
    userCreatedFromDate: null,
    userCreatedToDate: null,
    pageNumber: "1",
    pageSize: config.pageSize,
  };
  const [params, setParams] = useSearchParams();

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_EMPLOYEES_LIST_URL +
      `?pageNumber=${params?.pageNumber}&pageSize=${params?.pageSize}&keyword=${params?.keyword}&userCreatedFromDate=${params?.userCreatedFromDate ? params?.userCreatedFromDate : ""}&userCreatedToDate=${params?.userCreatedToDate ? params?.userCreatedToDate : ""}`
    setLoadingList(true);
    ServiceApi.get(url).then(({ data }) => {
      setData(data);
      setKeyword(params.keyword)
    }).finally(() => {
      setLoadingList(false);
    });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps


  //permissions
  const { AS_EmployeeFullAccess, AS_GetEmployees, AS_GetEmployeeDetail } = appPermissions;
  const FULL_ACCESS = usePermission(AS_EmployeeFullAccess);
  const GET_LIST_PERMISSION = usePermission(AS_GetEmployees);
  const GET_DETAIL_PERMISSION = usePermission(AS_GetEmployeeDetail);


  const handelDepartmentsLables = (departments: any) => {
    let label: any = [];
    departments?.forEach((department: any) => {
      label.push(department.name)
    })

    return label.length === 0 ? "" : label?.toString();

  }

  const resetFilter = () => {
    setParams({})
    setKeyword("")
  };

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      if (key === "userCreatedFromDate" || key === "userCreatedToDate") {
        value = value?.toDateString();
      }
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    }
  };


  return FULL_ACCESS && GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <SearchBox
        value={keyword}
        onSubmit={handleSetFilterData("keyword")}
        disabled={loadingList}
        onFilter={() => setFilterModal()}
        resetFilter={searchParams?.size === 0 ? undefined : resetFilter}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ?
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.employeeId} >
                      <td>
                        <Link to={GET_DETAIL_PERMISSION ? item.employeeId : ""} className="w-fit inline-flex flex-center flex gap-2">
                          <Table.Image placeholder="user" src="" alt={item.employeeId} className="h-[78px] w-[78px]" />
                          <div className="space-y-2">
                            <div><h6 className="text-heading-6 text-gray-800 font-semibold">{item?.displayName}</h6></div>
                            <div><Icon className="text-gray-500 font-black mr-2 h-4 w-4" icon="user-tag" /><span className="text-body-2 text-gray-500 font-medium">{item?.jobTitle}</span></div>
                            <div><Icon className="text-gray-500 font-black mr-2 h-4 w-4" icon="envelope" /><span className="text-body-2 text-gray-500 font-medium">{item?.emailAddress}</span></div>
                          </div>
                        </Link>
                      </td>
                      <td className="text-left space-y-2">
                        <div><span className="text-body-base text-gray-600 font-medium">{item?.store?.title}</span></div>
                        <div><span className="text-body-base text-gray-600 font-medium">{handelDepartmentsLables(item?.departments)}</span></div>
                        <div><span className="text-body-base text-gray-800 font-medium mr-1"><Text>company.employees.employeeNumber</Text>:</span><span className="text-body-base text-gray-600 font-medium">{item?.employeeNumber}</span></div>
                      </td>
                      <td>
                        <Status.EmployeesType id={item?.userType} />
                      </td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button as={Link} to={item.employeeId} variant="light" size="sm">
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetFilterData("pageNumber")}
                onPageSizeChange={handleSetFilterData("pageSize")}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
      <FilterDrawer
        isOpen={filterModal}
        data={data}
        keyword={keyword}
        setKeyword={setKeyword}
        toggle={setFilterModal}
        onChange={handleSetFilterData}
        resetFilter={resetFilter}
        setParams={setParams}
        params={params}
      />
    </div>
  ) : (
    <>Permission Error</>
  );
}
