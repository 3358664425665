import { Button, Select, Skeleton, Text } from "components";
import { dateFormats } from "constant";
import { useConverter, useToggleState } from "hooks";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { SalesReportContext, dataType } from ".";
import AllStoresReport from "./AllStoresReport";
import FilterDrawer from "./FilterDrawer";
import SellersReport from "./SellersReport";
import StoreReport from "./StoreReport";

export default function StoresReport() {
  const { convertDate } = useConverter();
  const { excludeSideCosts, searchSalesReport } =
    useContext(SalesReportContext);

  const date = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const from = new Date(`${currentYear}-01-01`);
    const to = new Date(`${currentYear}-12-30`);
    return { from, to };
  }, []);

  const [loading, setLoading] = useState(false);
  const [allStoreData, setAllStoreData] = useState({} as dataType);
  const [activeStoreData, setActiveStoreData] = useState<dataType | null>(null);

  const [location, setLocation] = useState("");
  const [params, setParams] = useState({
    dateFrom: date.from,
    dateTo: date.to,
  });

  const [isOpenFilterDrawer, toggleFilterDrawer] = useToggleState(false);

  const selectedStore = useMemo(() => location, [location]);
  const storeItems = useMemo(() => {
    const locations = allStoreData.locationsBasedOnTimeInterval ?? [];
    const items = locations.map((e) => ({ name: e.location, id: e.location }));
    const nullItem = {
      name: "salesManagment.kitchenSales.salesReport.all",
      id: "",
    };
    return [nullItem, ...items];
  }, [allStoreData.locationsBasedOnTimeInterval]);

  const getAllStoreData = () => {
    setLoading(true);
    searchSalesReport({
      ...params,
      advisorFullName: "",
      location: "",
      excludeSideCosts,
    })
      .then(({ data }) => {
        setAllStoreData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getActiveStoreData = () => {
    if (!location) return;
    setLoading(true);
    searchSalesReport({
      ...params,
      location,
      advisorFullName: "",
      excludeSideCosts,
    })
      .then(({ data }) => {
        setActiveStoreData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(getAllStoreData, [params, excludeSideCosts, searchSalesReport]);
  useEffect(getActiveStoreData, [
    params,
    location,
    excludeSideCosts,
    searchSalesReport,
  ]);
  return (
    <Fragment>
      <section className="col-span-full flex flex-col lg:flex-row lg:items-center gap-4">
        <h6>
          <Text>salesManagment.kitchenSales.salesReport.stores</Text>
        </h6>
        <div className="lg:w-60 lg:me-auto">
          <Select
            value={location}
            items={storeItems}
            setValue={setLocation}
            className="[&_.input-box]:bg-white"
          />
        </div>
        <h6>
          <Text>salesManagment.kitchenSales.salesReport.date</Text>
        </h6>
        <FilterDrawer
          isOpen={isOpenFilterDrawer}
          toggle={toggleFilterDrawer}
          params={params}
          setParams={setParams}
        />
        <Button
          variant={null}
          className="bg-white text-primary"
          onClick={toggleFilterDrawer}
        >
          {convertDate(params.dateFrom, dateFormats.onlyDate)}
          <span className="inline-block text-gray-700 mx-2">
            <Text>salesManagment.kitchenSales.salesReport.toDate</Text>
          </span>
          {convertDate(params.dateTo, dateFormats.onlyDate)}
        </Button>
      </section>
      {loading ? (
        <div className="col-span-full">
          <Skeleton.ImageList />
        </div>
      ) : (
        <Fragment>
          {selectedStore && !!activeStoreData ? (
            <Fragment>
              <StoreReport
                data={allStoreData}
                storeData={activeStoreData}
                dateFrom={params.dateFrom}
                dateTo={params.dateTo}
              />
              <SellersReport
                storeData={activeStoreData}
                params={{
                  ...params,
                  advisorFullName: "",
                  excludeSideCosts: false,
                  location: "",
                }}
              />
            </Fragment>
          ) : (
            <AllStoresReport
              data={allStoreData}
              dateFrom={params.dateFrom}
              dateTo={params.dateTo}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
