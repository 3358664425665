import { ComponentProps, useEffect, useRef, useState } from "react";
import { useTranslate } from "hooks";
import InputGroup from "./InputGroup";
import Skeleton from "./Skeleton";
import Dropdown from "./Dropdown";
import Icon from "./Icon";
import Text from "./Text";

type itemType = {
  name: string;
  id: number | string;
  code?: string;
  country?: string;
};
type defaultSelectProps = {
  items: itemType[];
  id?: string;
  className?: string;
  disabled?: boolean;
  size?: string;
  hint?: any;
  flag?: string;
  clear?: boolean;
  loading?: boolean;
  actions?: any;
};
type selectProps = defaultSelectProps &
  Omit<
    ComponentProps<typeof InputGroup>,
    keyof "onClick" | "append" | "readOnly" | "type" | "size"
  >;

export default function Select({
  label,
  value,
  items,
  rules,
  prepend,
  placeholder,
  className,
  disabled,
  size,
  flag,
  hint,
  actions,
  clear,
  loading,
  setValue = () => { },
}: selectProps) {
  const ref = useRef<any>(null);
  const selectRef = useRef<any>(null);
  const translate = useTranslate();

  const activeItemName = items?.find((e) => `${e.id}` === `${value}`)?.name;

  return (
    <>
      {loading ? (
        <>
          <label
            data-lang-map={label}
            className="block h6 text-gray-800 w-full font-[400] truncate group-[.input-group.required]:after:content-['*'] after:text-danger"
          >
            <Text>{label}</Text>
          </label>
          <Skeleton.Input />
        </>
      ) : (
        <div ref={selectRef} key={label} >
          <Dropdown
            className={`block w-full cursor-pointer ${className}  ${disabled ? "pointer-events-none" : ""
              }`}
            onSelect={setValue}
          >
            <Dropdown.Toggle
              ref={ref}
              key={label}
              as={InputGroup}
              size={size}
              label={label}
              value={translate(activeItemName ?? "")}
              rules={rules}
              prepend={prepend}
              placeholder={placeholder}
              disabled={disabled}
              flag={flag}
              hint={hint}
              actions={actions}
              append={
                <div className="flex gap-1">
                  {clear && value !== undefined && value && (
                    <i
                      onClick={() => {
                        setValue(null);
                      }}
                    >
                      <Icon className="mr-2" icon={"times"} />
                    </i>
                  )}
                  <i
                    // onClick={() => ref.current?.toggleShow()}
                    className="bi bi-chevron-down px-0 input-group-text text-primary group-data-active:rotate-180 transition-transform"
                  />
                </div>
              }
            />
            <Dropdown.Menu className="max-h-[10rem] overflow-auto">
              {
                items?.length !== 0 
                  ? items?.map((e: any, index: any) => {
                    return (
                      <Dropdown.Item
                        key={e.id + e.name + index}
                        as="button"
                        type="button"
                        eventKey={e.id}
                        isActive={value === e.id}
                      >
                        {e.code
                          ? `${translate(e.name)} ${e.code}` ||
                          `${e.name} ${e.code}`
                          : translate(e.name) || e.name}
                      </Dropdown.Item>
                    );
                  })
                  : translate("global.noItems")}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </>
  );
}
