const IMPORT_PRODUCT = {
  POST_SEARCH_PRODUCT_IMPORT_LIST:
    "/productservice/api/providerproductimports/search-provider-product-import",
  POST_PRODUCT_IMPORT:
    "productservice/api/pimproducts/import-from-provider-product-import",
  GET_PRODUCT_IMPORT_DETAILS: (id) =>
    `productservice/api/providerproductimports/${id}/b2b-detail`,

  CHECK_CONFIGURATION_PROVIDER: (providerName, configurationVersion, lang) =>
    `productservice/api/b2bconfigurationproviders/${providerName}/${configurationVersion}/${lang}/check`,
  IMPORT_CONFIGURATION_PROVIDER: `productservice/api/b2bconfigurationproviders`,
  GET_B2B_CONFIG_PROVIDERS: `productservice/api/b2bconfigurationproviders`,
  // SEARCH_PIM_PRODUCT: `productservice/api/providerproductimports/basic-info`,
  // GET_B2B_PRODUCT_DETAIL: (id) =>
  //   `productservice/api/providerproductimports/${id}/b2b-detail`,
  // IMPORT_PRODUCTS: `productservice/api/pimproducts/import-from-provider-product-import`,
  // GET_CONFIGURATION_PROVIDERS_ITEM: (name) =>
  //   `productservice/api/configurationproviders/${name}`,

  // GET_MEASUREMENT_LIST_BY_CODE: (code) =>
  //   `productservice/api/measurementfamilies/detail-by-code/${code}`,
};

export default IMPORT_PRODUCT;
