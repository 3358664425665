import {
  Breadcrumb,
  Button,
  HandleName,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text
} from "components";
import { appPermissions, config } from "constant";
import { usePermission } from "hooks";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { AttributesTypes } from "utils";

type AttributesItems = {
  attributeTemplateId: string;
  title: string;
  code: string;
  type: number;
  perChannel: boolean;
  perLanguage: boolean;
  isRequired: boolean;
  translations: any;
};

type ListType = {
  items?: AttributesItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function Attributes() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_ATTRIBUTES_URLS;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config).then(({ data }) => {
      setData(data);
    }).finally(() => {
      setLoadingList(false);
    });
  };

  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  //permissions
  const { PS_GetAttributeTemplateList, PS_GetAttributeTemplateDetail, PS_CreateAttributeTemplate } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(PS_GetAttributeTemplateList);
  const CREATE_PERMISSION = usePermission(PS_CreateAttributeTemplate);
  const GET_DETAIL_PERMISSION = usePermission(PS_GetAttributeTemplateDetail);

  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        {loadingList ? (
          <Skeleton.Button />
        ) : (CREATE_PERMISSION ?
          <Button
            as={Link}
            to="new"
          >
            <Icon className="mr-2" icon={"plus"} /><Text>productManagement.masterData.attributes.addAttributes</Text>
          </Button>
          : null)}
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ?
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.attributeTemplateId} >
                      <td>
                        <Link to={GET_DETAIL_PERMISSION ? `${item.attributeTemplateId}?tab=0` : ""} className="w-fit inline-flex flex-center flex gap-2">
                          <div className="space-y-4">
                            <div><h6 className="text-heading-6 text-gray-800 font-semibold">{<HandleName translations={item?.translations} code={item?.code} keyName="displayName" />}</h6></div>
                            <div>
                              <span className="text-body-2 text-gray-500 pr-2 font-medium">{item.code}</span>
                              <span className="text-body-2 text-gray-500 border-l-2 border-gray-300  px-2 font-medium">{AttributesTypes(item?.type)}</span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td>
                        <div className="flex flex-wrap gap-4">
                          <Status.RequiredAttribute id={item?.isRequired} />
                          <Status.PerChannelAttribute id={item?.perChannel} />
                          <Status.PerLanguageAttribute id={item?.perLanguage} />
                        </div>
                      </td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button as={Link} to={`${item.attributeTemplateId}?tab=0`} variant="light" size="sm">
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) => setParams((p) => ({ ...p, pageSize: e }))}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
    </div>
  ) : (
    <>Permission Error</>
  );
}
