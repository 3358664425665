import {
    InputGroup,
    SelectLang,
    Skeleton,
    Text,
    Toggler,
    Wrapper
} from "components";
import { flagCountry, rules } from "constant";
import { Fragment, useContext } from "react";

import { AttributesContext } from "..";


export default function General() {
  const {
    attributesData,
    setAttributesData,
    description,
    name,
    placeHolder,
    langItems,
    setName,
    setDescription,
    setPlaceHolder,
    loading,
    setLangSelected,
    langSelected
  } = useContext<any>(AttributesContext);

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    attributesData?.translations?.forEach((item: any) => {
      if (item?.language?.toLocaleLowerCase() === lang?.toLocaleLowerCase()) {
        setName(item.displayName);
        setDescription(item.description);
        setPlaceHolder(item.placeHolder);
      }
    });
  };

  const handleSetData = (key: any) => {
    return (value: any) =>
      setAttributesData((p: any) => ({ ...p, [key]: value }));
  };

  return (
    <Fragment>
      <div className="flex gap-6">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-gray-800 font-semibold">
                    <Text>
                      productManagement.masterData.attributes.basicInfo
                    </Text>
                  </h2>
                </div>
                <div>
                  <SelectLang value={langSelected} items={langItems} onChangeLanguage={onChangeLanguage} />
                </div>
              </div>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.attributeName"
                  value={name}
                  flag={flagCountry[langSelected]}
                  setValue={(value) => {
                    attributesData?.translations?.forEach((item: any) => {
                      if (
                        item?.language?.toLocaleLowerCase() ===
                        langSelected?.toLocaleLowerCase()
                      ) {
                        item.displayName = value;
                      }
                    });
                    setAttributesData((p: any) => ({
                      ...p,
                      translates: attributesData?.translations,
                    }));
                    setName(value);
                  }}
                  rules={rules.required}
                />
                <InputGroup
                  label="productManagement.masterData.attributes.placeHolder"
                  value={placeHolder}
                  flag={flagCountry[langSelected]}
                  setValue={(value) => {
                    attributesData?.translations?.forEach((item: any) => {
                      if (
                        item?.language?.toLocaleLowerCase() ===
                        langSelected?.toLocaleLowerCase()
                      ) {
                        item.placeHolder = value;
                      }
                    });
                    setAttributesData((p: any) => ({
                      ...p,
                      translates: attributesData?.translations,
                    }));
                    setPlaceHolder(value);
                  }}
                />
                <InputGroup
                  as="textarea"
                  flag={flagCountry[langSelected]}
                  label="productManagement.masterData.attributes.shortDescription"
                  value={description}
                  setValue={(value) => {
                    attributesData?.translations?.forEach((item: any) => {
                      if (
                        item?.language?.toLocaleLowerCase() ===
                        langSelected?.toLocaleLowerCase()
                      ) {
                        item.description = value;
                      }
                    });
                    setAttributesData((p: any) => ({
                      ...p,
                      translates: attributesData?.translations,
                    }));
                    setDescription(value);
                  }}
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) : (
          <Wrapper>
            <Wrapper.Header>
              <h2 className="text-gray-800 font-semibold">
                <Text>productManagement.masterData.attributes.properties</Text>
              </h2>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.attributeCode"
                  value={attributesData?.code}
                  disabled
                />
                <Toggler
                  label={"productManagement.masterData.attributes.isRequired"}
                  value={attributesData.isRequired}
                  setValue={handleSetData("isRequired")}
                />
                <Toggler
                  label={"productManagement.masterData.attributes.perLanguage"}
                  value={attributesData.perLanguage}
                  setValue={handleSetData("perLanguage")}
                />
                <Toggler
                  label={"productManagement.masterData.attributes.perChannel"}
                  value={attributesData.perChannel}
                  setValue={handleSetData("perChannel")}
                />
                <Toggler
                  label={
                    "productManagement.masterData.attributes.updatefromProviderAttribute"
                  }
                  value={attributesData.isConnected}
                  setValue={handleSetData("isConnected")}
                />
                <InputGroup
                  label="productManagement.masterData.attributes.order"
                  value={attributesData?.orderAttribute}
                  setValue={handleSetData("orderAttribute")}
                  type="number"
                  keyfilter="onlyNum"
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        )}
      </div>
    </Fragment>
  );
}
