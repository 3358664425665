export const userList = {
    inviteUser: "Invite User DE",
    addUser: "Add User DE",
    general: "General DE",
    group: "Group DE",
    store: "Store DE",
    stores: "Stores DE",
    authenticationSetting: "Authentication setting DE",
    basicInfo: "Basic Info DE",
    address: "Address DE",
    usersGroup: "Users Group DE",
    firstName: "First Name DE",
    lastName: "last Name DE",
    gender: "gender DE",
    dateOfBirth: "Date Of Birth DE",
    personalNumber: "Personal Number DE",
    phoneNumber: "Phone Number DE",
    mobileNumber: "Mobile Number DE",
    emailAddress: "Email Address DE",
    active: "Active DE",
    setDefaultFilter: "Set as default filter DE",
    providerName: "Provider name DE",
    userName: "User Name DE",
    activeLogin: "Active Login DE",
    generalDesc: "First Name, Last Name, ... DE",
    location: "Location DE",
    locationDesc: "User location DE",
    userGroup: "User Group DE",
    userGroupDesc: "Select User Group DE",
    review: "Review DE",
    reviewDesc: "Finalize information DE",
    searchUser: "Search user DE",
    search: "Search DE",
    //.....Provider name 
    local: "Local DE",
    add: "ADD DE",
}