import BrandList from "pages/product-managment/master-data/brand";
import Details from "pages/product-managment/master-data/brand/details";

const Suppliers = [
  {
    path: "",
    element: <BrandList />,
  },
  {
    path: ":brandId",
    element: <Details />,
  },
];

export default Suppliers;
