import { NotificationManager } from "common/notifications";
import {
    Button,
    Drawer,
    Dropdown,
    Form,
    Icon,
    InputGroup,
    RadioButton,
    Text,
    Toggler,
    Wrapper
} from "components";
import { config, rules } from "constant";
import cloneDeep from "lodash/cloneDeep";
import { FormEvent, useEffect, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import { ServiceApi, URLS } from "services";

type createModalType = {
  isOpen: boolean;
  supplierCode: string;
  supplierData: any;
  toggle: (value?: boolean) => void;
  getData: () => void;
};


export default function Attribute(props: createModalType) {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<any>();
  const [selectedItem, setSelectedItem] = useState<any>("");
  const [selectedOptionsBrand, setSelectedOptionsBrand] = useState<any>();
  const [items] = useState([{
    name: "none", id: 0,
  }, {
    name: "WorkDay", id: 1
  }, {
    name: "CalendarDay", id: 2
  }])
  const [data, setData] = useState({
    title: "",
    shortDescription: "",
    code: "",
    zrNumber: "",
    isActive: true,
    programType: 2,
    brandId: "",
    brandName: "",
    supplierCode: props.supplierCode,
    hasShippingInfo: true,
    shippingInfo: { minimumShippingDay: null, shippingDayType: null },
    hasBankInfo: true,
    bankInfo: {
      bankName: null,
      iban: null,
      accountOwnerName: null,
      bic: null,
    }

  });

  useEffect(() => {
    if (props.isOpen) {
      setData({
        title: "",
        shortDescription: "",
        code: "",
        zrNumber: "",
        brandId: "",
        brandName: "",
        isActive: true,
        programType: 2,
        supplierCode: props.supplierCode,
        hasShippingInfo: true,
        shippingInfo: { minimumShippingDay: null, shippingDayType: null },
        hasBankInfo: true,
        bankInfo: {
          bankName: null,
          iban: null,
          accountOwnerName: null,
          bic: null,
        }

      })
      getBrands(data?.brandId);
    }
  }, [props.isOpen])


  const getBrands = (brandId: any) => {
    if (brandId) {
      const urlBrand = URLS.GET_BRAND_DETAILS_URL(brandId)
      ServiceApi.get(urlBrand).then(({ data }) => {
        setSelectedOptionsBrand({ ...data, label: data.name, value: data.id });
      });
    } else {
      setSelectedOptionsBrand(null)
    }
  };


  const onChangeHandlerBrand = (selected: any) => {
    if (!selected) {
      setSelectedOptionsBrand(null);
    } else {
      setSelectedOptionsBrand(selected)
    }
    setData((p: any) => ({
      ...p,
      brandId: selected?.value,
      brandName: selected?.label,
    }));
  };

  const handleSearchBrands = async (filter: string) => {
    const url = URLS.GET_SELL_OFF_BRANDS;
    const params = {
      pageNumber: 1,
      pageSize: 1000,
      keyword: filter,
    };
    return ServiceApi.get(url, { params }).then(({ data }) => {
      const brands = data.items.map((brand: any) => ({
        value: brand.brandId,
        label: brand.name,
      }));
      return brands;
    });
  };

  const promiseOptionsBrand = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(handleSearchBrands(inputValue));
      }, 200);
    });

  const onSubmitForm = (formData: any) => {
    setLoading(true);
    const url = URLS.CREATE_NEW_PROGRAM;
    if (formData?.hasShippingInfo) {
      formData.shippingInfo = null;
    } else {
      if (formData?.shippingInfo?.minimumShippingDay === null || formData?.shippingInfo?.minimumShippingDay === "") {
        formData.shippingInfo = { shippingDayType: formData?.shippingInfo?.shippingDayType };
      }
      if (formData?.shippingInfo?.shippingDayType === null) {
        formData.shippingInfo = { minimumShippingDay: formData?.shippingInfo?.minimumShippingDay };
      }
    }
    if (formData.hasBankInfo) {
      formData.bankInfo = null;
    }
    ServiceApi.post(url, formData).then(({ }) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      props.toggle(false);
      props.getData();
    }).finally(() => {
      setLoading(false);
    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };


  const handleSetValue = (key: any) => {
    if (!data.hasShippingInfo) {
      return (value: any) =>
        setData((p: any) => {
          const data = cloneDeep(p);
          if (data.shippingInfo !== null) {
            data.shippingInfo[key] = value;
          } else {
            data.shippingInfo = { minimumShippingDay: null, shippingDayType: null }
            data.shippingInfo[key] = value;
          }
          return data;
        });
    }
  };

  const handleSetValueBank = (key: any) => {
    if (!data?.hasBankInfo) {
      return (value: any) =>
        setData((p: any) => {
          const data = cloneDeep(p);
          if (data.bankInfo !== null) {
            data.bankInfo[key] = value;
          } else {
            data.bankInfo = {
              bankName: null,
              iban: null,
              accountOwnerName: null,
              bic: null,
            }
            data.bankInfo[key] = value;
          }
          return data;
        });
    };
  }

  const shippingType = (e: any) => {
    switch (e) {
      case 1:
        return "WorkDay"
      case 2:
        return "CalendarDay"
      default:
        return "none"
    }
  }


  const selectedShippingDayType = (selected: any) => {
    return items?.find(
      (item: any) => item.id === selected)?.name;
  };

  const onChangeHandler = (e: any) => {
    if (!data.hasShippingInfo) {
      setData((p: any) => {
        const data = cloneDeep(p);
        if (data.shippingInfo !== null) {
          data.shippingInfo.shippingDayType = e;
        } else {
          data.shippingInfo = { minimumShippingDay: null, shippingDayType: null }
          data.shippingInfo.shippingDayType = e;
        }
        return data;
      });
    }
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <h2 className="text-gray-800 text-heading-2"><Text>productManagement.masterData.suppliers.addProgram</Text></h2>
            <Button
              type="button"
              variant="light"
              size="sm"
              className="ml-2"
              onClick={() => props.toggle()}
            >
              <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
            </Button>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4 overflow-auto">
          <Form ref={formRef} onSubmit={onSubmitForm} id={"create-modal"} className="space-y-4 px-2 pt-4">
            <InputGroup
              label="productManagement.masterData.suppliers.title"
              value={data.title}
              className="border-white"
              setValue={(title) => setData((p) => ({ ...p, title }))}
              rules={rules.required}
            />
            <Toggler
              label={
                data.isActive
                  ? "productManagement.masterData.suppliers.active"
                  : "productManagement.masterData.suppliers.deActive"
              }
              value={data.isActive}
              setValue={(isActive) => setData((p) => ({ ...p, isActive }))}
            />
            <InputGroup
              label="productManagement.masterData.suppliers.code"
              value={data.code}
              setValue={(code) => setData((p) => ({ ...p, code }))}
              rules={rules.required}
            />
            <InputGroup
              label="productManagement.masterData.suppliers.zrNumber"
              value={data.zrNumber}
              setValue={(zrNumber) => setData((p) => ({ ...p, zrNumber }))}
            />
            <label
              data-lang-map={"productManagement.masterData.suppliers.brand"}
              className="block h6 text-gray-800 w-full font-[400] truncate group-[.input-group.required]:after:content-['*'] after:text-danger"
            >
              <Text>{"productManagement.masterData.suppliers.brand"}</Text>
            </label>
            <AsyncSelect
              value={selectedOptionsBrand}
              cacheOptions
              loadOptions={promiseOptionsBrand}
              defaultOptions
              styles={config.styleSelectReact}
              onChange={onChangeHandlerBrand}
              isClearable
            />
            <InputGroup
              as="short-textarea"
              label="productManagement.masterData.suppliers.description"
              value={data.shortDescription}
              setValue={(shortDescription) =>
                setData((p) => ({ ...p, shortDescription }))
              }
            />
            <div className="flex space-x-2">
              <RadioButton
                label="productManagement.masterData.suppliers.configurable"
                value={data.programType === 2 ? true : false}
                setValue={() =>
                  setData((p: any) => ({ ...p, ["programType"]: 2 }))
                }
              />
              <RadioButton
                label="productManagement.masterData.suppliers.manual"
                value={data.programType === 1 ? true : false}
                setValue={() =>
                  setData((p: any) => ({ ...p, ["programType"]: 1 }))
                }
              />
            </div>
            <Toggler
              label={"productManagement.masterData.suppliers.useDefaultMinimumShippingTime"}
              value={data.hasShippingInfo}
              setValue={(hasShippingInfo) => {
                setData((p: any) => ({ ...p, hasShippingInfo }))
                if (hasShippingInfo) {
                  setData((p: any) => {
                    const data = cloneDeep(p);
                    data.shippingInfo = null;
                    return data;
                  });
                } else {
                  setData((p: any) => {
                    const data = cloneDeep(p);
                    data.shippingInfo = { minimumShippingDay: null, shippingDayType: null }
                    return data;
                  });
                }
              }}
            />
            {data.hasShippingInfo ?
              <Wrapper.Section className="grid grid-cols-3 gap-4 items-start text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
                <div className="flex items-center">
                  <span>{props?.supplierData?.shippingInfo?.minimumShippingDay}</span>
                  <span className="ml-2">{shippingType(props?.supplierData?.shippingInfo?.shippingDayType)}</span>
                </div>
              </Wrapper.Section>
              :
              <div>
                <div className="flex items-center space-x-4 justify-between mb-2">
                  <h6 className="block h6 font-normal text-gray-800 truncate group-[.input-group.required]:after:content-['*'] after:text-danger">
                    <Text>productManagement.masterData.suppliers.defaultMinimumShippingTime</Text>
                  </h6>
                </div>
                <div className="mt-4" style={{ display: "flex" }}>
                  <InputGroup
                    type="number"
                    value={data?.shippingInfo?.minimumShippingDay}
                    setValue={handleSetValue("minimumShippingDay")}
                    className="rounded-r-none"
                  />
                  <Dropdown>
                    <Dropdown.Toggle
                      as="button"
                      type="button"
                      className="items-center font-medium text-body-base h-full flex bg-primary-light text-primary p-3 rounded-md align-middle rounded-l-none"
                    >
                      {selectedShippingDayType(data?.shippingInfo?.shippingDayType)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="min-w-fit mt-2 p-2">
                      <ul className="text-gray-700 w-[104px]">
                        {items?.map((e: any) => (
                          <li
                            key={e.id}
                            onClick={() => {
                              setSelectedItem(e.id);
                              onChangeHandler(e.id)
                            }}
                            className={`${e.id === selectedItem && "bg-primary-light text-primary "
                              } py-2 font-medium first:mb-1 px-4 h6  hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                          >
                            {e?.name}
                          </li>
                        ))}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            }
            <Toggler
              label={"productManagement.masterData.suppliers.useDefaultBankInfo"}
              value={data?.hasBankInfo}
              setValue={(hasBankInfo) => {
                setData((p: any) => ({ ...p, hasBankInfo }))
                if (hasBankInfo) {
                  setData((p: any) => {
                    const data = cloneDeep(p);
                    data.bankInfo = null;
                    return data;
                  });
                } else {
                  setData((p: any) => {
                    const data = cloneDeep(p);
                    data.bankInfo = {
                      bankName: null,
                      iban: null,
                      accountOwnerName: null,
                      bic: null,
                    }
                    return data;
                  });
                }

              }}
            />
            {data?.hasBankInfo ?
              <Wrapper.Section className="grid grid-cols-3 gap-4 items-start text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
                <div className="space-y-2">
                  <div>
                    <span className="text-gray-800 font-normal"><Text>productManagement.masterData.suppliers.bankName</Text>:</span>
                    <span className="ml-2 text-body-base text-gray-700">{props?.supplierData?.bankInfo?.bankName}</span>
                  </div>
                  <div>
                    <span className="text-gray-800 font-normal"><Text>productManagement.masterData.suppliers.accountOwnerName</Text>:</span>
                    <span className="ml-2 text-body-base text-gray-700">{props?.supplierData?.bankInfo?.accountOwnerName}</span>
                  </div>
                  <div>
                    <span className="text-gray-800 font-normal"><Text>productManagement.masterData.suppliers.iban</Text>:</span>
                    <span className="ml-2 text-body-base text-gray-700">{props?.supplierData?.bankInfo?.iban}</span>
                  </div>
                  <div>
                    <span className="text-gray-800 font-normal"><Text>productManagement.masterData.suppliers.bic</Text>:</span>
                    <span className="ml-2 text-body-base text-gray-700">{props?.supplierData?.bankInfo?.bic}</span>
                  </div>
                </div>
              </Wrapper.Section>
              :
              <>
                <InputGroup
                  label="productManagement.masterData.suppliers.bankName"
                  value={data?.bankInfo?.bankName}
                  setValue={handleSetValueBank("bankName")}
                  rules={rules.required}
                />
                <InputGroup
                  label="productManagement.masterData.suppliers.accountOwnerName"
                  value={data?.bankInfo?.accountOwnerName}
                  setValue={handleSetValueBank("accountOwnerName")}
                  rules={rules.required}
                />
                <InputGroup
                  label="productManagement.masterData.suppliers.iban"
                  value={data?.bankInfo?.iban}
                  setValue={handleSetValueBank("iban")}
                  rules={rules.required}
                />
                <InputGroup
                  label="productManagement.masterData.suppliers.bic"
                  value={data?.bankInfo?.bic}
                  setValue={handleSetValueBank("bic")}
                  rules={rules.required}
                />
              </>
            }
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between items-center">
          <Button type="button" onClick={props.toggle} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loading}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer >
  );
}
