import React, { useContext } from "react";
import { Text, Table } from "components";
import { ProfileContext } from ".";
import { useTranslation } from "react-i18next";

const Channels: React.FunctionComponent = () => {
  const { profileData } = useContext<any>(ProfileContext);

  const { i18n } = useTranslation();

  return (
    <>
      <Table>
        {profileData?.channels?.map((channel: any) => {
          return (
            <tr className="text-body-2 ">
              <td>
                {
                  channel.channelNameTranslates?.find(
                    (name: any) => name.language === i18n.language
                  )?.name
                }
              </td>
              <td>
                <span className="text-gray-500">
                  <Text>company.profile.availableLanguages</Text>
                </span>{" "}
                :{" "}
                {channel.availableLanguages?.map((lang: any, index: number) => {
                  return (
                    <span>
                      {index > 0 && <span className="mx-1">,</span>} {lang}
                    </span>
                  );
                })}
              </td>
              <td>
                <span className="text-gray-500">
                  <Text>company.profile.defaultLanguage</Text>
                </span>{" "}
                : {channel.channelDefaultLanguage}
              </td>
            </tr>
          );
        })}
      </Table>
    </>
  );
};

export default Channels;
