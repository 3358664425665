import { Button, Form, Icon, InputGroup, Modal, Text } from "components";
import { rules, types } from "constant";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { ServiceApi } from "services";
import { toggleProps } from "types";
type dataType = {
  id?: string;
  title: string;
  description: string | null;
  code: string;
  componentType: number;
  isActive: boolean;
};
type PositionFormProps = toggleProps & {
  initData?: dataType;
  onSubmitted: (data: dataType) => void;
};
export default function PositionForm({
  initData = {
    title: "",
    description: "",
    code: "",
    componentType: 1,
    isActive: true,
  },
  isOpen,
  toggle,
  onSubmitted,
}: PositionFormProps) {
  const isNew = !initData.id;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initData);
  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const handleToggle = () => {
    // setData(initData);
    toggle();
  };
  const addPosition = () => {
    setLoading(true);
    const url = "/productservice/api/positions";
    const body = { ...data };
    ServiceApi.post(url, body)
      .then(() => {
        toast.success("applications.positions.positionAddFormToast");
        onSubmitted(body);
        setData(initData);
        handleToggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const editPosition = () => {
    setLoading(true);
    const url = `/productservice/api/positions/${data.id}`;
    const body = { ...data };
    ServiceApi.put(url, body)
      .then(() => {
        toast.success("applications.positions.positionEditFormToast");
        onSubmitted(body);
        setData(body);
        handleToggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <Form onSubmit={isNew ? addPosition : editPosition}>
        <Modal.Header>
          <h4 className="text-start">
            <Text>
              {isNew
                ? "applications.positions.positionAddFormHeader"
                : "applications.positions.positionEditFormHeader"}
            </Text>
          </h4>
        </Modal.Header>
        <Modal.Body className="space-y-8">
          <InputGroup
            label="applications.positions.positionFormName"
            value={data.title}
            setValue={handleSetValue("title")}
            rules={rules.required}
          />
          {isNew && (
            <InputGroup
              label="applications.positions.positionFormCode"
              value={data.code}
              setValue={handleSetValue("code")}
              rules={rules.required}
            />
          )}
          <InputGroup
            as="short-textarea"
            label="applications.positions.positionFormDescription"
            value={data.description}
            setValue={handleSetValue("description")}
            rules={rules.required}
          />
          {isNew && (
            <fieldset className="border-0 border-t border-gray-200">
              <legend className="h5">
                <Text>applications.positions.positionFormComponentLayout</Text>
              </legend>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 mt-6">
                {types.position.map((e) => (
                  <button
                    key={e.id}
                    type="button"
                    data-active={e.id === data.componentType}
                    className="group w-full space-y-2"
                    onClick={() =>
                      setData((p) => ({ ...p, componentType: e.id }))
                    }
                  >
                    <img
                      src={e.activeIcon}
                      alt={e.name}
                      className="w-full filter grayscale opacity-50 group-data-active:grayscale-0 group-data-active:opacity-100 transition-[filter,opacity]"
                    />
                    <h5 className="w-full text-start">
                      <span className="inline-flex flex-center align-middle w-4 h-4 rounded-full bg-gray-200 group-data-active:bg-primary me-2 transition-colors">
                        <span className="w-2 h-2 rounded-full bg-gray-200" />
                      </span>
                      <Text>{e.name}</Text>
                    </h5>
                  </button>
                ))}
              </div>
            </fieldset>
          )}
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" variant="white" onClick={handleToggle}>
            <Icon icon="close" />{" "}
            <Text>applications.positions.positionFormCloseButton</Text>
          </Button>
          <Button type="submit" variant="primary" loading={loading}>
            <Text>applications.positions.positionFormSubmitButton</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
