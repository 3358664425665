import { NotificationManager } from "common/notifications";
import {
    Button,
    CheckBox,
    Form,
    InputGroup,
    Text
} from "components";
import { rules } from "constant";
import { useDataState } from "hooks";
import { FormEvent, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";

type createType = {
  setShowCreate: (value?: boolean) => void;
  programId: string;
  setShowButtonCreate:  (value?: boolean) => void;
  getData: () => void;
  getDataAttribute: () => void;
  toggle: () => void;
};

export default function Create(props: createType) {
  const [loadingPage, setLoadingPage] = useState(false);
  const [data, setData] = useDataState<any>({});
  const formRef = useRef<any>();


  const onSubmitForm = (formData: any) => {
    setLoadingPage(true)
    const url = URLS.ADD_ATTRIBUTE(props.programId);
    ServiceApi.put(url, formData).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      props.getData();
      props.getDataAttribute();
      props.setShowCreate(false)
      props.setShowButtonCreate(true)
    }).finally(() => {
      setLoadingPage(false)
    })
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  return (
    <div className="rounded" style={{ boxShadow: "0px 4px 20px rgba(56, 71, 109, 0.09)", padding: "24px", marginBottom: "16px" }}>
      <Form ref={formRef} onSubmit={onSubmitForm} id={"create-attribute"}>
        <div className="col-start-1 col-end-4 mb-6">
          <InputGroup
            label="productManagement.masterData.suppliers.title"
            value={data.name}
            className="border-white"
            setValue={(name) => setData((p: any) => ({ ...p, name }))}
            rules={rules.required}
          />
        </div>
        <div className="col-start-1 col-end-4 mb-6">
          <InputGroup
            label="productManagement.masterData.suppliers.code"
            value={data.code}
            setValue={(code) => setData((p: any) => ({ ...p, code }))}
            rules={rules.required}
          />
        </div>
        <div className="col-start-1 col-end-4 mb-6">
          <InputGroup
            as="short-textarea"
            label="productManagement.masterData.suppliers.description"
            value={data.description}
            setValue={(description) => setData((p: any) => ({ ...p, description }))}
          />
        </div>
        <div className="col-start-1 mb-6">
          <CheckBox
            label="productManagement.masterData.suppliers.required"
            value={data.isRequired}
            setValue={(isRequired) => setData((p: any) => ({ ...p, isRequired }))}
          />
        </div>
        {/* </Modal.Body> */}
        <div className="flex items-center justify-between">
          <Button type="button" className="mr-2" onClick={() => {
            props.setShowCreate(false)
            props.setShowButtonCreate(true)
          }} 
          variant="light">
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingPage}>
            <Text>global.buttons.submit</Text>
          </Button>
        </div>
      </Form>
    </div>
  );
}
