import UserGroupsPage from "pages/configurations/user-managment/user-groups";

const UserGroups = [
  {
    path: "",
    element: <UserGroupsPage />,
  }
];


export default UserGroups;
