import React, { useContext, useEffect, useState } from "react";
import { Wrapper, Text, MultiSelect, Select } from "components";
import { ProfileContext } from ".";
import { rules } from "constant";

const Language: React.FunctionComponent = () => {
  const { profileData, setProfileData } = useContext<any>(ProfileContext);


  const defaultDashboardLangItems = [
    { id: "de", name: "de" },
    { id: "en", name: "en" }
  ];

  const defaultContentLangItems = [
    { id: "de", name: "de" },
    { id: "en", name: "en" },
    { id: "tr", name: "tr" },
    { id: "fr", name: "fr" },
  ];

  //...dashboard
  const [activeDashboardLanguage] = useState(defaultDashboardLangItems);
  const [defaultDashboardLanguage, setDefaultDashboardLanguage] = useState([]);
  //...content
  const [activeContentLanguage] = useState(defaultContentLangItems);
  const [defaultContentLanguage, setDefaultContentLanguage] = useState([]);



  useEffect(() => {
    let arrayDashboard: any = [];
    let arrayContent: any = [];
    profileData?.languages.map((item: any) => {
      arrayDashboard.push({ id: item, name: item },)
    })
    profileData?.contentLanguages.map((item: any) => {
      arrayContent.push({ id: item, name: item },)
    })
    setDefaultDashboardLanguage(arrayDashboard)
    setDefaultContentLanguage(arrayContent)
  }, [])

  // console.log("profileData", profileData)

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-x-8 lg:gap-y-16">
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>company.profile.dashboardLanguage</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:gap-16">
          <div className="space-y-8">
            <MultiSelect
              label="company.profile.activeDashboardLanguage"
              items={activeDashboardLanguage}
              value={profileData?.languages}
              setValue={(value) => {
                if (value.length) {
                  setDefaultDashboardLanguage(value);
                } else {
                  setDefaultDashboardLanguage([]);
                }
                setProfileData((p: any) => ({
                  ...p,
                  languages: value.length !== 0 ? value.map((val: any) => val.name) : [],
                }));
              }}
              rules={rules.required}
            />
            <Select
              label="company.profile.defaultDashboardLanguage"
              value={profileData?.defaultLanguage}
              items={defaultDashboardLanguage}
              setValue={(value) =>
                setProfileData((p: any) => ({
                  ...p,
                  defaultLanguage: value,
                }))
              }
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>company.profile.contentLanguage</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:gap-16">
          <div className="space-y-8">
            {
              <MultiSelect
                label="company.profile.activeContentLanguage"
                items={activeContentLanguage}
                value={profileData?.contentLanguages}
                setValue={(value) => {
                  if (value.length) {
                    setDefaultContentLanguage(value);
                  } else {
                    setDefaultContentLanguage([]);
                  }
                  setProfileData((p: any) => ({
                    ...p,
                    contentLanguages: value.length !== 0 ? value?.map((val: any) => val.name) : [],
                  }));
                }}
                rules={rules.required}
              />
            }
            <Select
              label="company.profile.defaultContentLanguage"
              value={profileData?.defaultContentLanguage}
              items={defaultContentLanguage}
              setValue={(value) =>
                setProfileData((p: any) => ({
                  ...p,
                  defaultContentLanguage: value,
                }))
              }
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
    </div>
  );
};

export default Language;
