import EmployeesList from "pages/compony/employees";
import Details from "pages/compony/employees/details"

const Employees = [
  {
    path: "",
    element: <EmployeesList />,
  },
  {
    path: ":employeeId",
    element: <Details />,
  }
];

export default Employees;
