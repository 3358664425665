

export const HandleParamsUrl = (urlparams: any, newKey: any, newValue: any, newKey2: any = "", newValue2: any = "") => {
  let params: any = {};
  urlparams?.forEach((val: any, keyObj: any) => {
    params[keyObj] = val;
  });
  let paramUrl: any = [];
  params[newKey] = newValue;
  if (newKey2 !== "") {
    params[newKey2] = newValue2;
  }
  Object.keys(params)?.forEach((i: any) => {
    if (params[i] !== null && params[i] !== "" && params[i] !== false) {
      paramUrl.push(`${i}=${params[i]}`);
    }
  })
  return paramUrl.join('&');
};


