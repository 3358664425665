import {
    Button,
    DatePicker,
    Drawer,
    Icon,
    InputGroup,
    Select,
    Text,
    TextEditor,
    Toggler,
} from "components";
import { flagCountry } from "constant";
import { createElement, useContext } from "react";
import { PimContext } from "..";
import { findValue } from "../../helpers/find-attribute.value";
import { updateValue } from "../../helpers/update-attribute-value";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  attribute?: any;
  setValues?: any;
  values?: any;
  id?: any;
  onChangeValue?: any;
};

const CompareValuesDrawer = ({
  isOpen,
  toggle,
  attribute,
  values,
  setValues,
  id,
  onChangeValue,
}: drawerProps) => {
  const language = ["de", "en"];
  const { channels } = useContext(PimContext);


  const label = attribute.isRequired ? (
    <>
      {attribute.translations?.[0]?.displayName}{" "}
      <Icon icon="star" size="sm" className="text-danger ml-1" />{" "}
    </>
  ) : (
    attribute.translations?.[0]?.displayName
  );

  const onChangeHandler = (e: any, channel: any, language: any) => {
    const changedData = updateValue(
      attribute,
      values,
      id,
      e,
      channel,
      language
    );
    setValues(changedData);
    onChangeValue(attribute, id);
  };

  const createProps = (value: any, lang: any, channel: any, attribute: any) => {
    let items;
    if (attribute.type === 30) {
      items = attribute?.items?.map((item: any) => ({
        id: item.id,
        name: item.translates?.[0].displayName,
      }));
    }

    return {
      label: label,
      value:
        (attribute.type === 40 ||
          attribute.type === 50 ||
          attribute.type === 60) &&
        value
          ? new Date(value)
          : value,

      flag: flagCountry[lang],
      hint: { label: channel },
      ...(attribute.type === 20 && { size: "sm" }),
      ...(attribute.type === 30 && { items: items }),
      ...(attribute.type === 50 && {
        dateFormat: "h:mm aa",
        showTimeSelect: true,
        showTimeSelectOnly: true,
      }),
      ...(attribute.type === 60 && {
        dateFormat: "MM/dd/yyyy h:mm aa",
        showTimeSelect: true,
      }),
    };
  };

  const perLanguageTemplate = () => {
    return (
      <>
        {language.map((lang: any) => {
          const { value } = findValue(attribute, values, id, undefined, lang);
          const element = findFieldComponent(attribute);
          //@ts-ignore
          return createElement(element, {
            ...createProps(value, lang, undefined, attribute),
            ...((attribute.type === 40 ||
              attribute.type === 50 ||
              attribute.type === 60) && {
              onChange: (e: any) => {
                onChangeHandler(e, undefined, lang);
              },
            }),
            setValue: (e: any) => onChangeHandler(e, undefined, lang),
          });
        })}
      </>
    );
  };
  const perChannelTemplate = () => {
    return (
      <>
        {channels.map((channel: any) => {
          const { value } = findValue(
            attribute,
            values,
            id,
            channel.code,
            undefined
          );
          const element = findFieldComponent(attribute);
          //@ts-ignore
          return createElement(element, {
            ...createProps(value, undefined, channel.code, attribute),
            ...((attribute.type === 40 ||
              attribute.type === 50 ||
              attribute.type === 60) && {
              onChange: (e: any) => {
                onChangeHandler(e, channel.code, undefined);
              },
            }),
            setValue: (e: any) => onChangeHandler(e, channel.code, undefined),
          });
        })}
      </>
    );
  };

  const perBothTemplate = () => {
    return (
      <>
        {channels.map((channel: any) => {
          return language.map((lang) => {
            const { value } = findValue(
              attribute,
              values,
              id,
              channel.code,
              lang
            );
            const element = findFieldComponent(attribute);
            //@ts-ignore
            return createElement(element, {
              ...createProps(value, lang, channel.code, attribute),
              ...((attribute.type === 40 ||
                attribute.type === 50 ||
                attribute.type === 60) && {
                onChange: (e: any) => {
                  onChangeHandler(e, channel.code, lang);
                },
              }),
              setValue: (e: any) => onChangeHandler(e, channel.code, lang),
            });
          });
        })}
      </>
    );
  };

  const createTemplate = (perChannel: boolean, perLang: boolean) => {
    if (perChannel && perLang) {
      return perBothTemplate();
    } else if (perChannel) {
      return perChannelTemplate();
    } else if (perLang) {
      return perLanguageTemplate();
    }
  };
  const findFieldComponent = (attribute: any) => {
    switch (attribute.type) {
      case 10:
        return InputGroup;
      case 20:
        return Toggler;
      case 30:
        return Select;
      case 40:
        return DatePicker;
      case 50:
        return DatePicker;
      case 60:
        return DatePicker;
      //   case 70:
      //     return MeasurmentUnit;
      case 80:
        return TextEditor;
      default:
        return InputGroup;
    }
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.Details.assignCategory</Text>
            </span>
            <div className="flex gap-x-2">
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-6 pr-4">
          {createTemplate(attribute.perChannel, attribute.perLanguage)}
          {/* {createElement(findFieldComponent(attribute))} */}
        </Drawer.Body>
        <Drawer.Footer className="flex justify-end">
          <Button
            size="sm"
            onClick={toggle}
            // disabled={isEmpty(publishedCategory)}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
};

export default CompareValuesDrawer;
