import i18n from "langs/i18n";

export default function convertNumber(num = "") {
  const lang: string = i18n.language;
  const decimalPoints: any = {
    de: ",",
    en: ".",
  };
  const decimalPoint = decimalPoints[lang];
  const [int, decimal = null] = num.split(".");
  const hasDecimal = decimal !== null;
  if (hasDecimal) return [int, decimal].join(decimalPoint);
  return int;
}
