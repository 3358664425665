import { Fragment } from "react";
import { ToastBar, Toaster as DefaultToaster } from "react-hot-toast";
import { useTranslate } from "../hooks";

export default function Toaster() {
  const translate = useTranslate();
  return (
    <DefaultToaster
      position="top-center"
      toastOptions={{ duration: 5000, className: "text-lg" }}
    >
      {(t: any) => (
        <ToastBar toast={{ ...t, message: translate(t.message) }}>
          {({ icon, message }) => (
            <Fragment>
              {icon}
              {message}
            </Fragment>
          )}
        </ToastBar>
      )}
    </DefaultToaster>
  );
}
