import {
    Button,
    DatePicker,
    Form,
    Image,
    InputGroup,
    NoItems,
    // Select,
    Skeleton,
    Text,
    Wrapper,
} from "components";
import { appPermissions, config, rules } from "constant";
import { usePermission, useToggleState } from "hooks";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { ServiceApi, URLS } from "services";
import { PimContext } from "..";

const GeneralTab = () => {
  const {
    productData,
    loading,
    setData,
    isChanged,
    editProduct,
    submitLoading,
    setPatchData,
  } = useContext(PimContext);
  const [programs, setPrograms] = useState([]);
  const [loadingPrograms, setLoadingPrograms] = useState(false);
  const [programBrand, setProgramBrand] = useState<any>();

  const [loadingBrandInfo, setLoadingBrandInfo] = useToggleState(false);
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const [selectedOptionsProgram, setSelectedOptionsProgram] = useState<any>([]);

  const getSupplier = () => {
    if (productData?.supplierId) {
      const urlSupplers = URLS.GET_SUPPLIER_DETAILS(productData?.supplierId);
      ServiceApi.get(urlSupplers).then(({ data }) => {
        setSelectedOptions({ ...data, label: data.name + " - " + data.code, value: data.code });
      });
    }
  };

  const getSupplierProgramList = (code?: string) => {
    setLoadingPrograms(true);
    const url = URLS.GET_SUPPLIER_PROGRAMS(code);
    ServiceApi.get(url).then(({ data }) => {
      const program = data?.map((item: any) => ({
        ...item,
        id: item.id,
        code: item.code,
        value: item.title,
        label: item.title,
      }));
      setPrograms(program);
      if (productData?.programInfo && program?.length > 0) {
        let programInfo: any = program?.find(
          (item: any) => item.id === productData?.programInfo?.programId
        );

        setSelectedOptionsProgram(programInfo);
      } else {
        setSelectedOptionsProgram([]);
      }
      setLoadingPrograms(false);
    });
  };

  useEffect(() => {
    //setBrand
    if (productData?.supplierId && productData?.programInfo?.brandId) {
      findBrandInfo();
    } else {
      setProgramBrand({});
    }
  }, [productData?.programInfo?.brandId]);

  useEffect(() => {
    getSupplier();
  }, [productData?.supplierId]);

  useEffect(() => {
    if (productData?.supplierInfo) {
      getSupplierProgramList(productData?.supplierInfo?.code);
    }
  }, [productData?.supplierInfo?.code]);

  const findBrandInfo = () => {
    setLoadingBrandInfo();
    const id = URLS.GET_BRAND_DETAILS(productData?.programInfo?.brandId);
    ServiceApi.get(id).then(({ data }) => {
      setProgramBrand(data);
      setLoadingBrandInfo();
    });
  };

  const onSupplierChange = (event: any) => {
    let supplierInfo: any = event;
    setSelectedOptions(event);
    if (!event) {
      setSelectedOptionsProgram(null);
      setPrograms([]);
    }
    setPatchData((p: any) => ({
      ...p,
      supplierId: supplierInfo?.supplierId || null,
      programId: null,
    }));
    setData((p: any) => ({
      ...p,
      supplierInfo: supplierInfo || null,
      supplierId: supplierInfo?.supplierId || null,
      programInfo: null,
      programId: null,
    }));
  };

  const onProgramChange = (event: any) => {
    let programInfo: any = programs?.find(
      (item: any) => item.code === event?.code
    );
    setSelectedOptionsProgram(programInfo);
    setPatchData((p: any) => ({
      ...p,
      programId: programInfo?.id || null,
    }));
    setData((p: any) => ({
      ...p,
      programInfo: programInfo || null,
      programId: programInfo?.id || null,
    }));
  };

  const onChangeHandler = (key: string) => {
    return (value: any) => {
      setPatchData((p: any) => ({ ...p, [key]: value }));
      return setData((p: any) => ({ ...p, [key]: value }));
    };
  };

  const handleSearchSuppliers = async (filter: string) => {
    const url = URLS.GET_SUPPLIERS;
    const params = {
      pageNumber: 1,
      pageSize: 10,
      keyword: filter,
    };
    return ServiceApi.get(url, { params }).then(({ data }) => {
      const suppliers = data.items.map((supplier: any) => ({
        ...supplier,
        id: supplier.supplierId,
        label: supplier.name + ' - ' + supplier.code,
        value: supplier.code,
        code: supplier.code,
      }));
      return suppliers;
    });
  };

  //permissions

  const { PS_UpdatePimProduct } = appPermissions;
  const UPDATE_PERMISSION = usePermission(PS_UpdatePimProduct);

  const promiseOptions = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(handleSearchSuppliers(inputValue));
      }, 200);
    });

  return (
    <Form className="space-y-6" onSubmit={editProduct}>
      <Wrapper>
        <Wrapper.Body>
          <div className="grid grid-cols-3 gap-16">
            {loading ? (
              [1, 2].map((key) => <Skeleton.Input key={key} />)
            ) : (
              <>
                <div className="col-span-2">
                  <InputGroup
                    label="productManagement.products.allProducts.sku"
                    placeholder="productManagement.products.allProducts.sku"
                    value={productData?.sku}
                    setValue={onChangeHandler("sku")}
                    rules={rules.required}
                  />
                </div>
                <DatePicker
                  placeholderText="productManagement.products.Details.selectDate"
                  label="productManagement.products.Details.availableTill"
                  value={
                    productData?.availableTill
                      ? new Date(productData?.availableTill)
                      : null
                  }
                  onChange={onChangeHandler("availableTill")}
                />
              </>
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <div className="grid grid-cols-2 gap-6">
        <Wrapper>
          <Wrapper.Header>
            <Text>productManagement.products.Details.supplier</Text>
          </Wrapper.Header>
          <Wrapper.Body className="space-y-6">
            {loading ? (
              [1, 2].map((key) => <Skeleton.Input key={key} />)
            ) : (
              <>
                <InputGroup
                  label="productManagement.products.Details.supplierArticleNumber"
                  placeholder="productManagement.products.Details.supplierArticleNumber"
                  value={productData?.supplierArticleNumber}
                  setValue={onChangeHandler("supplierArticleNumber")}
                />
                <label
                  data-lang-map={"productManagement.products.Details.suppliers"}
                  className="block h6 text-gray-800 w-full font-[400] truncate group-[.input-group.required]:after:content-['*'] after:text-danger"
                >
                  <Text>{"productManagement.products.Details.suppliers"}</Text>
                </label>
                <AsyncSelect
                  value={selectedOptions}
                  cacheOptions
                  loadOptions={promiseOptions}
                  defaultOptions
                  onChange={onSupplierChange}
                  styles={config.styleSelectReact}
                  isClearable
                />
                <label
                  data-lang-map={"productManagement.products.Details.program"}
                  className="block h6 text-gray-800 w-full font-[400] truncate group-[.input-group.required]:after:content-['*'] after:text-danger"
                >
                  <Text>{"productManagement.products.Details.program"}</Text>
                </label>
                <Select
                  value={selectedOptionsProgram}
                  onChange={onProgramChange}
                  isSearchable
                  name="color"
                  styles={config.styleSelectReact}
                  isLoading={loadingPrograms}
                  isDisabled={loadingPrograms}
                  options={programs}
                  isClearable
                />
              </>
            )}
          </Wrapper.Body>
        </Wrapper>
        <Wrapper>
          <Wrapper.Header>
            <Text>productManagement.products.Details.brand</Text>
          </Wrapper.Header>
          <Wrapper.Body className="space-y-6">
            {loadingBrandInfo || loading ? (
              <div className="shadow rounded-md  max-w-sm w-full ">
                <div className="animate-pulse flex flex-col space-y-4">
                  <div className="rounded bg-slate-200 h-20 w-20"></div>
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 w-32 bg-slate-200 rounded"></div>
                  </div>

                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    </div>
                    <div className="h-2 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col space-y-6">
                {!isEmpty(programBrand) ? (
                  <>
                    <span>
                      <Image src={programBrand?.logoUrl} className="w-40" />
                    </span>
                    <span>{programBrand?.name}</span>
                    <span className="leading-5 text-body-2">
                      {programBrand?.description}
                    </span>
                  </>
                ) : (
                  <div className="flex flex-col items-center w-full">
                    <NoItems />
                  </div>
                )}
              </div>
            )}
          </Wrapper.Body>
        </Wrapper>
      </div>
      {!loading && UPDATE_PERMISSION && (
        <Wrapper className="sticky bottom-0 shadow-card">
          <Wrapper.Body className="flex items-center justify-end gap-4">
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              // onClick={editProduct}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Wrapper.Body>
        </Wrapper>
      )}
    </Form>
  );
};

export default GeneralTab;
