export const allProducts = {
  addProduct: "Produkt hinzufügen",
  skuName: "Produkt Originalname",
  sku: "Produkt Originalname",
  article: "Artikel",
  productFamily: "Produktfamilie",
  created: "Erstellt",
  modified: "Geändert",
  completeness: "Vollständigkeit",
  keyword: "Stichwort",
  productCategory: "Produktkategorie",
  status: "Status",
  brand: "Marke",
  suppliers: "Lieferanten",
  program: "Programm",
  store: "Geschäft",
  priceRange: "Preisspanne",
  to: "Bis",
  from: "Von",
  useAsProductNameInThisDashboard: "Als Produktname in diesem Dashboard verwenden",
  filters: "Filter",
  resetFilters: "Filter zurücksetzen",
  close: "Schließen"
};
