import {
  Breadcrumb,
  Button,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
} from "components";
import { appPermissions, config } from "constant";
import { useConverter, usePermission, useToggleState } from "hooks";
import { createContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import ConfigurationsDrawer from "./ConfigDrawer";
import FilterDrawer from "./FilterDrawer";
import DetailsDrawer from "./details/index";

export const ProductsContext = createContext<any>({ id: "" });

export default function ImportProducts() {
  const [data, setData] = useState<any>();
  const [loadingList, setLoadingList] = useState(true);
  const [filterModal, setFilterModal] = useToggleState(false);
  const [configurationsModal, setConfigurationsModal] = useToggleState(false);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    keyword: "",
    language: null,
    pageNumber: 1,
    pageSize: config.pageSize,
    priceFrom: 0,
    priceTo: null,
    productFamily: null,
    providerName: null,
    sortType: null,
    version: null,
  };
  const [params, setParams] = useSearchParams();
  const [selectedID, setSelectedID] = useState([]);
  const [isOpenDrawerDetails, toggleDrawerDetails] = useToggleState(false);
  const [keyword, setKeyword] = useState("");
  const [configurationProviders, setConfigurationProviders] = useState<any>([]);
  const [isConfigurationImported, setIsConfigurationImported] = useState(true);
  const { convertDate } = useConverter();
  useEffect(() => {
    ServiceApi.get(URLS.GET_B2B_CONFIG_PROVIDERS).then(({ data }) => {
      const providers = data?.map((item: any) => ({
        ...item,
        label: data?.[0].providerName,
        value: data?.[0].b2BConfigurationProviderId,
      }));
      setConfigurationProviders(providers);
      if (data?.length > 0) {
        const { providerName, version, language } = data[0];
        ServiceApi.get(
          URLS.CHECK_CONFIGURATION_PROVIDER(providerName, version, language)
        ).then(({ data }) => {
          setIsConfigurationImported(data?.isImported);
        });
      }
    });
  }, []);

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.POST_SEARCH_PRODUCT_IMPORT_LIST;
    const config = params;
    setLoadingList(true);
    ServiceApi.post(url, config)
      .then(({ data }) => {
        let newData = data;
        newData?.searchResult?.forEach((item: any) => {
          item["checked"] = false;
          item?.translations?.forEach((i: any, index: number) => {
            if (index === 0) {
              item["title"] = i.title;
            }
          });
        });
        setKeyword(params.keyword);
        setData(newData);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetFilter = () => {
    setParams({})
    setKeyword("");
  };

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    }
  };

  //permissions
  const { PS_ImportPimProduct } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(PS_ImportPimProduct);
  const GET_DETAIL_PERMISSION = usePermission(PS_ImportPimProduct);

  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <SearchBox
        onSubmit={handleSetFilterData("keyword")}
        value={keyword}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onFilter={() => setFilterModal()}
        resetFilter={searchParams?.size === 0 ? undefined : resetFilter}
      />
      {!isConfigurationImported && (
        <div className="bg-warning-light p-4 rounded justify-between flex items-center">
          <span className="text-body text-warning-active">
            please import provider configurations
          </span>
          <Button size="sm" light onClick={setConfigurationsModal}>
            import
          </Button>
        </div>
      )}
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.searchResult?.length !== 0 ? (
            <>
              <Table>
                {data?.searchResult?.map((item: any) => {
                  return (
                    <tr key={item.providerProductImportId}>
                      <td>
                        <Link
                          to={window.location.href}
                          onClick={() => {
                            if (GET_DETAIL_PERMISSION) {
                              setSelectedID(item.providerProductImportId);
                              toggleDrawerDetails();
                            }
                          }}
                          className="w-fit inline-flex flex gap-2"
                        >
                          <div className="space-y-2">
                            <h6 className="text-heading-6 font-semibold text-gray-800">
                              {item.title}
                            </h6>
                            <div className="text-body-2 text-gray-500 font-medium">
                              <Text>
                                productManagement.products.importProduct.article
                              </Text>
                              <span className="ml-1">#{item.productKey}</span>
                            </div>
                            <div className="text-gray-500 text-body-2 font-medium">
                              <Text>
                                productManagement.products.importProduct.bestellnrLieferantArtikelVersion
                              </Text>
                              :
                              <span className="ml-1">
                                {item.bestellnrLieferantArtikelVersion}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="text-body-2 font-medium text-gray-600 text-left space-y-[2px]">
                        <div>
                          <Text>
                            productManagement.products.importProduct.productFamily
                          </Text>
                          :<span className="ml-1">{item.productFamily}</span>
                        </div>
                        <div>
                          <Text>
                            productManagement.products.importProduct.imported
                          </Text>
                          :
                          <span className="ml-1">
                            {convertDate(item.importedDate)}
                          </span>
                          <span className="ml-1">{item.importedBy}</span>
                        </div>
                        <div>
                          <Text>
                            productManagement.products.importProduct.version
                          </Text>
                          :<span className="ml-1">{item.version}</span>
                        </div>
                      </td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button
                            onClick={() => {
                              setSelectedID(item.providerProductImportId);
                              toggleDrawerDetails();
                            }}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetFilterData("pageNumber")}
                onPageSizeChange={handleSetFilterData("pageSize")}
              />
            </>
          ) : (
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          )}
        </>
      )}
      <ProductsContext.Provider value={{ id: selectedID }}>
        <FilterDrawer
          isOpen={filterModal}
          data={data}
          keyword={keyword}
          setKeyword={setKeyword}
          toggle={setFilterModal}
          onChange={handleSetFilterData}
          resetFilter={resetFilter}
          setParams={setParams}
          params={params}
        />
        <ConfigurationsDrawer
          isOpen={configurationsModal}
          toggle={setConfigurationsModal}
          configurationProviders={configurationProviders}
          setIsConfigurationImported={setIsConfigurationImported}
        />
        <DetailsDrawer
          isOpen={isOpenDrawerDetails}
          toggle={toggleDrawerDetails}
          getDataList={getData}
          selectedID={selectedID}
        />
      </ProductsContext.Provider>
    </div>
  ) : (
    <>Permission Error</>
  );
}
