import { Fragment } from "react";
import { dataType } from ".";
import { Text, Wrapper } from "components";
import { useConverter } from "hooks";
import Percent from "./Percent";
import AverageFactor from "./AverageFactor";
import ProgressBars from "./ProgressBars";
import LineChart from "./LineChart";
type allStoresReportProps = {
  data: dataType;
  dateFrom: Date;
  dateTo: Date;
};
export default function AllStoresReport({
  data,
  dateFrom,
  dateTo,
}: allStoresReportProps) {
  const { convertAmount } = useConverter();
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Body className="space-y-8">
          <div className="space-x-3">
            <span className="text-gray-700 text-body-2">
              <Text>salesManagment.kitchenSales.salesReport.totalSales</Text>
            </span>
            <span className="text-info h6">
              {convertAmount(data.totalSales)}
            </span>
            <Percent value={data.growPercentage} />
          </div>
          <AverageFactor
            average={data.averageSellFactor}
            target={data.averageTargetSellFactor}
            className="bg-gray-100 py-5 px-4"
          />
          <ProgressBars
            totalSalesPerYear={data.totalSalesPerYear}
            totalSales={data.totalSales}
            perYear={dateTo.getFullYear() - 1}
            year={dateTo.getFullYear()}
            growPercentage={data.growPercentage}
          />
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="xl:col-span-2">
        <Wrapper.Header className="flex items-center gap-4">
          <h2 className="me-auto">
            <Text>salesManagment.kitchenSales.salesReport.salesCompare</Text>
          </h2>
          <p className="text-body-1 font-medium text-gray-500">
            <span className="inline-block w-1 h-1 rounded-full bg-current me-1 align-middle" />
            {dateTo.getFullYear() - 1}
          </p>
          <p className="text-body-1 font-medium text-info">
            <span className="inline-block w-1 h-1 rounded-full bg-current me-1 align-middle" />
            {dateTo.getFullYear()}
          </p>
        </Wrapper.Header>
        <Wrapper.Body>
          <LineChart
            currentData={data.salesItemsPerMonth}
            lastYearData={data.lastYearSalesItemsPerMonth}
          />
        </Wrapper.Body>
      </Wrapper>
    </Fragment>
  );
}
