export const productCategory = {
  primaryCategories: "Primary Categories",
  addButton: "Add",
  generalTab: "General",
  subCategoriesTab: "Sub Category",
  mediaTab: "Media",
  channelsTab: "Channel",
  deleteButton: "Delete",
  unPublish: "unpublish",
  publish: "publish",
  basicInfo: "Basic Info",
  categoryName: "Category Name",
  categoryCode: "Category Code",
  shortDescription: "Short Description",
  editSubCategory: "Edit",
  addBranch: "Add Branch",
  addSubCategory: "Add Sub Category",
  images: "Images",
  mainImage: "Main Image",
  uploadImage: "Upload Image",
  thumbnail: "Thumbnail",
  showInThisChannel: "Show In This Channel",
  note: "Note",
  noteText:
    "To display the changes in channels, you should publish the primary category in the channel.",
  addCategory: "Add Category",
};
