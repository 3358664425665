const SELL_PHASE = {
  GET_SELL_PHASE_URL: "productservice/api/sellphasetemplate",
  ADD_SELL_PHASE_URL: "productservice/api/sellphasetemplate",
  GET_DETAILS_SELL_PHASES_URL: (id) => `productservice/api/sellphasetemplate/${id}`,
  UPDATE_DETAILS_SELL_PHASES_URL: (id) => `productservice/api/sellphasetemplate/${id}`,
  GET_SELL_PHASES_URL: "productservice/api/sellphasetemplate/all?",
  SELL_PHASE_DETAIL_URL:
    "productservice/api/sellphasetemplate?sellPhaseTemplateId=",
  UPDATE_USER_PROVIDER: (ID) =>
    `accountservice/api/users/${ID}/update-providers`,
};

export default SELL_PHASE;
