import UserList from "./users-list";
import UserGroups from "./user-groups";
import Roles from "./roles";

const UserManagment = [
  {
    path: "users-list",
    children: [...UserList],
  },
  {
    path: "user-groups",
    children: [...UserGroups],
  },
  {
    path: "roles",
    children: [...Roles],
  },
];

export default UserManagment;
