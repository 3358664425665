import { Button, Form, InputGroup, Text, Wrapper } from "components";
import { useContext, useState } from "react";
import { ConfigurationsContext } from ".";
import { rules } from "constant";
import { ServiceApi } from "services";
import { toast } from "react-hot-toast";

export default function SellFactor() {
  const { data, setData } = useContext(ConfigurationsContext);
  const [loading, setLoading] = useState(false);
  const submit = () => {
    setLoading(true);
    const url = "/statisticsservice/api/companies/sell-factor";
    const body = { sellFactor: data.sellFactor };
    ServiceApi.patch(url, body)
      .then(() => {
        toast.success(
          "salesManagment.kitchenSales.configuration.updateSuccessSellFactor"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Form onSubmit={submit}>
      <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <InputGroup
          label="salesManagment.kitchenSales.configuration.sellFactorField"
          type="number"
          value={data.sellFactor}
          setValue={(sellFactor) => setData((p) => ({ ...p, sellFactor }))}
          rules={rules.required}
        />
      </Wrapper.Body>
      <Wrapper.Footer>
        <Button type="submit" variant="primary" loading={loading} size="sm">
          <Text>
            salesManagment.kitchenSales.configuration.submitSellFactor
          </Text>
        </Button>
      </Wrapper.Footer>
    </Form>
  );
}
