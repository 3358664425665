import { NotificationManager } from "common/notifications";
import {
  AddressViewer,
  Breadcrumb,
  Button,
  Footer,
  Form,
  Icon,
  Image,
  ImageUploader,
  Skeleton,
  Tabs,
  Text,
  Wrapper,
} from "components";
import { useDataState, usePermission } from "hooks";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";

import { appPermissions } from "constant";
import { useToggleState } from "hooks";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { Media } from "types";
import { ObjectUtils } from "utils";
import BankInfo from "./tabs/bankInfo";
import ContactPerson from "./tabs/contactPerson";
import General from "./tabs/general";
import Program from "./tabs/program";
import SaleSetting from "./tabs/saleSetting";

export const SuppliersContext = createContext({});

export default function Update() {
  const { supplierId } = useParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [isOpen, toggle] = useToggleState(false);

  //permissions
  const { PS_UpdateSupplier } = appPermissions;
  const UPDATE_PERMISSIONS = usePermission(PS_UpdateSupplier);

  const tabs = [
    {
      label: "productManagement.masterData.suppliers.generalInfo",
      component: General,
    },
    {
      label: "productManagement.masterData.suppliers.bankInfo",
      component: BankInfo,
    },
    {
      label: "productManagement.masterData.suppliers.program",
      component: Program,
    },
    {
      label: "productManagement.masterData.suppliers.contactPerson",
      component: ContactPerson,
    },
    {
      label: "productManagement.masterData.suppliers.settings",
      component: SaleSetting,
    },
  ];
  const getData = () => {
    setLoading(true);
    const url = URLS.GET_SUPPLIER_DETAILS(supplierId);
    ServiceApi.get(url)
      .then(({ data }) => {
        setBaseData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submit = () => {
    setSubmitLoading(true);
    const url = URLS.SUPPLIER_URL(supplierId);
    const body = { ...data };
    ServiceApi.put(url, body)
      .then(() => {
        setBaseData(data);
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  useEffect(getData, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onUploadImage = (data: Media) => {
    const url = URLS.UPLOAD_SUPPLIERS_IMAGES(supplierId);
    const body = {
      url: data.url,
      type: data.type,
      thumbnailUrl: data.thumbnailUrl,
    };
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
      })
      .finally(() => {
        getData();
      });
  };

  return (
    <Tabs activeKey={tabs[0].label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
            <div>
              <Image
                onClick={() => toggle()}
                src={data.logoUrl}
                logo
                editor
                alt={data.name}
                className="flex justify-center bg-gray-100 p-2 rounded overflow-hidden relative w-fit aspect-image lg:aspect-auto cursor-pointer lg:w-80 lg:h-40"
              />
            </div>
            <div className="lg:flex-1 space-y-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <h6 className="text-heading-6 text-gray-800">{data.name}</h6>
                  {data.phoneNumber ? (
                    <p className="text-gray-500 text-body-2 font-medium">
                      <Icon
                        icon="phone-flip"
                        className="rotate-90 text-gray-700 text-[14px]"
                        size="xl"
                      />{" "}
                      {data.phoneNumber}
                    </p>
                  ) : (
                    ""
                  )}
                  {data.address ? <AddressViewer address={data.address} /> : ""}
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e) => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map((e) => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <SuppliersContext.Provider
              value={{
                suppliersData: data,
                setSuppliersData: setData,
                getData: getData,
                supplierLoading: loading,
              }}
            >
              {!ObjectUtils.isEmpty(data) && createElement(e.component)}
            </SuppliersContext.Provider>
          </Tabs.Item>
        ))}
        {UPDATE_PERMISSIONS && (
          <Footer show={!isChanged}>
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        )}
      </Form>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={data.logoUrl}
        onUpload={(data: Media) => onUploadImage(data)}
      />
    </Tabs>
  );
}
