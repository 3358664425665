const NOTIFICATIONS = {
  NOTIFICATION_ACTION_GROUPS_URL: (id) =>
    `notificationservice/api/notification/${id}/action-group`,
  GET_NOTIFICATION_ACTION_GROUPS_URL: (id) =>
    `notificationservice/api/notification/${id}/action-groups`,
  UPDATE_NOTIFICATION_ACTION_GROUPS_URL: (id) =>
    `notificationservice/api/notification/${id}`,
  GET_EMAIL_RECEIVERS_URL: (id) =>
    `notificationservice/api/actiongroups/${id}/email-receivers`,
  GET_WEBHOOK_RECEIVERS_URL: (id) =>
    `notificationservice/api/actiongroups/${id}/webhook-receivers`,
  PATCH_EMAIL_RECEIVERS_URL: (id) =>
    `notificationservice/api/actiongroups/${id}/email-receiver`,
  PATCH_WEBHOOK_RECEIVERS_URL: (id) =>
    `notificationservice/api/actiongroups/${id}/webhook-receiver`,
  NOTIFICATION_URL: "notificationservice/api/notification",
  NOTIFICATION_TRIGGER_TYPE_URL:
    "notificationservice/api/notification/trigger-event-types",
  ACTION_GROUP_URL: "notificationservice/api/actiongroups",
};

export default NOTIFICATIONS;
