import {
  InputGroup,
  Text,
  Button,
  Form,
  Select,
  Drawer,
  Icon
} from "components";
import { NotificationManager } from "common/notifications";
import { usePermission } from "hooks";
import {
  useState,
  useRef,
  FormEvent,
  useEffect
} from "react";

import { ServiceApi, URLS } from "services";
import { appPermissions, rules, config } from "constant";

export default function AddLocations(props: any) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({
    storeId: {
      value: ""
    },
    code: "",
    displayName: "",
    space: 0
  });
  const [storeItems, setStoreItems] = useState([])
  const formRef = useRef<any>();
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  useEffect(() => {
    if (props.isOpen) {
      setData({
        storeId: {
          value: ""
        },
        code: "",
        displayName: "",
        space: 0
      })
      getDataStore();
    }
  }, [props.isOpen])

  const getDataStore = () => {
    const url = URLS.ADD_STORE_URL;
    const config = { params };
    ServiceApi.get(url, config)
      .then(({ data }) => {
        let items: any = [];
        // console.log("data", data)
        data?.items?.forEach((item: any) => {
          items.push({ id: item.id, name: item.title })
        })
        setStoreItems(items);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //permissions
  const {
    PS_CreatePlace,
  } = appPermissions;
  const CREATE_PERMISSIONS = usePermission(PS_CreatePlace);

  const onSubmitForm = (formData: any) => {
    setLoading(true);
    const url = URLS.POST_LOCATIONS_URL;
    ServiceApi.post(url, formData).then((res: any) => {
      if (formRef?.current?.dataImg?.url !== "") {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        props.getData();
        props.toggle();
      }
    }).catch(() => {
      setLoading(false);
    });
  };

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };


  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);

  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <h2 className="text-gray-800 text-heading-2"><Text>salesManagment.salesArea.locations.addLocation</Text></h2>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                className="ml-2"
                onClick={() => props.toggle()}
              >
                <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <Form ref={formRef} onSubmit={onSubmitForm} className="space-y-8 pr-8 mt-8" id={"create-drawer"} >
            <Select
              label="salesManagment.salesArea.locations.store"
              value={data.storeId}
              items={storeItems}
              setValue={(value: any) =>
                setData((p: any) => ({
                  ...p,
                  storeId: value,
                }))
              }
              rules={rules.required}
            />
            <InputGroup
              label="salesManagment.salesArea.locations.code"
              value={data.code}
              setValue={handleSetData("code")}
              rules={rules.required}
            />
            <InputGroup
              label="salesManagment.salesArea.locations.name"
              value={data.displayName}
              setValue={handleSetData("displayName")}
              rules={rules.required}
            />
            <InputGroup
              label="salesManagment.salesArea.locations.space"
              value={data.space}
              setValue={handleSetData("space")}
              type="number"
              append={<h5 className="text-heading-5 text-gray-400 font-normal border-gray-300 border-l px-4 py-3">m<sup>2</sup></h5>}

            />
          </Form>
        </Drawer.Body>
        {CREATE_PERMISSIONS && (<Drawer.Footer className="flex justify-between">
          <Button
            variant="white"
            type="button"
            onClick={() => props.toggle()}
          >
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={submit}
            loading={loading}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>)}
      </Drawer.Menu>
    </Drawer>
  );
}
