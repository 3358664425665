import { Button, DatePicker, DeleteModal, Icon, InputGroup } from "components";
import { useToggleState } from "hooks";
import { useState } from "react";
type Props = {
  price: any;
  index: number;
  onRemove: any;
  onChange: any;
};

const AddPrice = ({ price, index, onRemove, onChange }: Props) => {
  const [deletModal, toggleDelete] = useToggleState();
  const [selectedIndex, setIndex] = useState<number>();
  return (
    <div className="grid grid-cols-3 gap-6">
      <InputGroup
        label="productManagement.products.Details.price"
        value={price.price.amount}
        setValue={(e) => onChange(index, "price", e)}
        type="number"
        append={
          <h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">
            €
          </h5>
        }
      />
      <DatePicker
        placeholderText="productManagement.products.Details.selectDate"
        label="productManagement.products.Details.startDate"
        onChange={(e: any) => onChange(index, "from", e)}
        value={price.from && new Date(price.from)}
      />
      <div className="flex items-end">
        <DatePicker
          placeholderText="productManagement.products.Details.selectDate"
          label="productManagement.products.Details.endDate"
          value={price.to && new Date(price.to)}
          onChange={(e: any) => onChange(index, "to", e)}
        />
        <Button
          variant={"white"}
          size="sm"
          className="ml-2"
          onClick={() => {
            setIndex(index);
            toggleDelete();
          }}
        >
          <Icon icon="trash" className="text-danger" />
        </Button>
      </div>
      <DeleteModal
        isOpen={deletModal}
        toggle={toggleDelete}
        selected={{ name: "Price field" }}
        onConfirm={() => {
          onRemove(selectedIndex);
          toggleDelete();
        }}
        loading={false}
      />
    </div>
  );
};

export default AddPrice;
