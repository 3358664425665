import ApiKeysList from "pages/configurations/global-settings/api-keys";

const ApiKeys = [
  {
    path: "",
    element: <ApiKeysList />,
  },
];

export default ApiKeys;
