export const importProduct = {
    importProduct: "Produkt importieren",
    productDetails: "Produktdetails",
    article: "Artikel",
    articleNumber: "Artikelnummer",
    productFamily: "Produktfamilie",
    created: "Erstellt",
    modified: "Geändert",
    completeness: "Vollständigkeit",
    keyword: "Stichwort",
    productCategory: "Produktkategorie",
    status: "Status",
    brand: "Marke",
    suppliers: "Lieferanten",
    program: "Programm",
    store: "Geschäft",
    priceRange: "Preisspanne",
    to: "Bis",
    from: "Von",
    bestellnrLieferantArtikelVersion: "Bestellnummer Lieferant Artikel Version",
    imported: "Importiert",
    version: "Version",
    language: "Sprache",
    providerName: "Anbietername",
    productTitle: "Produkttitel",
    basicInfo: "Grundlegende Informationen",
    price: "Preis",
    shortDescription: "Kurze Beschreibung",
    description: "Beschreibung",
    media: "Medien",
    thisProductWasImportedAt: "Dieses Produkt wurde importiert am",
    viewProduct: "Produkt anzeigen",
    productAddedSuccessfully: "Produkt erfolgreich hinzugefügt",
    importAgain: "Erneut importieren"
}