const BRANDS = {
  ADD_BRAND_URL: "productservice/api/brands",
  GET_BRANDS_URL: "productservice/api/brands",
  GET_BRAND_DETAILS_URL:(id)=> `productservice/api/brands/${id}`,
  UPDATE_BRAND_DETAILS_URL:(id)=> `productservice/api/brands/${id}`,
  ADD_ASSETS_TO_BRAND_URL: "/productservice/api/brands/",
  UPLOAD_BRAND_IMAGES: (id) => `productservice/api/brands/${id}/logo`,
 
};

export default BRANDS;
