import { NotificationManager } from "common/notifications";
import {
    Button,
    CardDraggable,
    DeleteModal,
    Drawer,
    Icon,
    NoItems,
    Skeleton,
    Status,
    Text,
    Wrapper
} from "components";
import { useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ServiceApi, URLS } from "services";

import Create from "./create";
import Details from "./details";


export default function Discount(props: any) {
  const [loading, setLoading] = useState<boolean>(false)
  const [titleItems, setTitleItems] = useState<any>([])
  const [showButtonCreate, setShowButtonCreate] = useState(true);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [selected, setSelected] = useState<any>();
  const [totalItems, setTotalItems] = useState<any>(0);
  const [title, setTitle] = useState<any>("");
  const [loadingButton, setLoadingButton] = useState(false);

  const [data, setData] = useState([])


  const getDataDiscount = () => {
    setShowButtonCreate(true);
    setLoading(true);
    setTitle("");
    const urlPrograms = URLS.GET_PROGRAM_DETAILS(props.programId);
    ServiceApi.get(urlPrograms)
      .then(({ data }) => {
        let list = data?.discounts?.sort((a: any, b: any) => a.order - b.order)
        setData(list);
        setTitle(data.title);
        setTotalItems(data?.discounts?.length);
      }).finally(() => {
        setLoading(false);
      })
    setTitleItems([
      { id: "supplierDiscount", name: "productManagement.masterData.suppliers.supplierDiscount" },
      { id: "programDiscount", name: "productManagement.masterData.suppliers.programDiscount" },
      { id: "customText", name: "productManagement.masterData.suppliers.customText" },
    ])
  };

  useEffect(() => {
    if (props.isOpen) {
      setIsOpenUpdate(false)
      setIsOpenCreate(false)
      getDataDiscount()
    }
  }, [props.isOpen]);


  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_DISCOUNT_PROGRAMS_SUPPLIERS(props.programId, id);
    ServiceApi.delete(url).then(({ }) => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      getDataDiscount();
      props.getData()
      toggleDeleteModal()
      setLoadingButton(false)
    }).catch(() => setLoadingButton(false));
  };

  const onHandel = (e: any) => {
    // let discounts: any = [];
    // e.forEach((i: any) => {
    //   return suppliersData?.discounts?.map((item: any) => {
    //     if (item.id === i.id) {
    //       item.order = i.order;
    //       discounts.push(item)
    //     }
    //   })
    // })
    // if (discounts.length !== 0) {
    //   setSuppliersData((p: any) => {
    //     return {
    //       ...p,
    //       discounts: discounts
    //     }
    //   });
    // }
  }

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <div className="flex">
              <h2 className="text-heading-2 text-gray-800 font-semibold">{title}</h2>
              {/* <h2 className="text-heading-2 text-gray-800 font-semibold"><Text>productManagement.masterData.suppliers.discounts</Text></h2> */}
              {/* <span className="text-body-base text-gray-700 ml-4 mt-1"><Text>productManagement.masterData.suppliers.dragChangeOrder</Text></span> */}
            </div>
            <Button
              type="button"
              variant="light"
              size="sm"
              className="ml-2"
              onClick={() => props.toggle()}
            >
              <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
            </Button>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4">
          {loading ? (
            <>
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
            </>
          ) : (
            <div>
              {isOpenUpdate &&
                <Details
                  titleItems={titleItems}
                  selected={selected}
                  setIsOpenUpdate={setIsOpenUpdate}
                  getData={getDataDiscount}
                  getDataProgram={props.getData}
                  setShowButtonCreate={setShowButtonCreate}
                  programId={props.programId}
                />
              }
              {isOpenCreate ? (
                <Create
                  titleItems={titleItems}
                  totalItems={totalItems}
                  setIsOpenCreate={setIsOpenCreate}
                  getData={getDataDiscount}
                  getDataProgram={props.getData}
                  setShowButtonCreate={setShowButtonCreate}
                  programId={props.programId}
                />
              ) : (
                ""
              )}
              {showButtonCreate &&
                <Wrapper.Section className="w-full h-[52px] border-primary text-center py-[17px] courser-pointer cursor-pointer mb-4" >
                  <div className="w-full" onClick={() => {
                    setIsOpenCreate(true);
                    setShowButtonCreate(false)
                  }}>
                    <h5 className="text-primary">
                      <Icon className="mr-2 text-primary" icon={"square-plus"} />
                      <Text>productManagement.masterData.suppliers.adddiscount</Text>
                    </h5>
                  </div>
                </Wrapper.Section>
              }
              {data?.length !== 0 ?
                <CardDraggable setItems={(arr: any) => onHandel(arr)}>
                  {data?.map((item: any) => {
                    return (
                      <div id={item.id} key={item.id} className="grid grid-cols-6 gap-8 px-4 py-[18px] w-full">
                        <Link
                          to=""
                          onClick={() => {
                            setIsOpenCreate(false)
                            setSelected(item);
                            setIsOpenUpdate(true)
                            setShowButtonCreate(false)
                          }}
                          className="col-span-3 mt-2">
                          <h5 className="text-gray-800">{item.title}</h5>
                        </Link>
                        <div className="flex col-span-1 mt-2">
                          {/* <span className="text-body-base text-gray-700 mr-2"><Text>productManagement.masterData.suppliers.amount</Text></span> */}
                          <h5 className="text-gray-800">{item.amount}%</h5>
                        </div>
                        <div className="mt-[2px] ml-6">
                          <Status.Supplier id={item.isActive} />
                        </div>
                        <div className="col-end-8 text-end">
                          <Button
                            type="button"
                            size="sm"
                            variant="danger"
                            className="mr-2"
                            light
                            onClick={() => {
                              setSelected(item);
                              toggleDeleteModal();
                              setIsOpenUpdate(false)
                            }}
                          >
                            <Icon icon={"trash"} />
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              setIsOpenCreate(false)
                              setShowButtonCreate(false)
                              setSelected(item);
                              setIsOpenUpdate(true)
                            }}
                            variant="light"
                            size="sm">
                            <Icon icon={"pencil"} />
                          </Button>
                        </div>
                      </div>

                    );
                  })}
                </CardDraggable>
                :
                <div className="flex flex-col items-center space-y-4 w-full">
                  <NoItems />
                </div>
              }
              <DeleteModal
                isOpen={isOpenDeleteModal}
                toggle={toggleDeleteModal}
                selected={{ name: selected?.title, id: selected?.id }}
                loading={loadingButton}
                onConfirm={submitDelete}
              />
            </div>
          )}
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
