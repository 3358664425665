import { useEffect, useState, useRef, FormEvent } from "react";
import {
  Form,
  Icon,
  Text,
  InputGroup,
  Button,
  MultiSelect,
  Drawer
} from "components";
import { rules } from "constant";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";

type drawerProps = {
  isOpen: boolean;
  salesTemplateId?: string;
  toggle: () => void;
  data: any;
  getData: () => void;
};


export default function Phase({ isOpen, salesTemplateId, data, toggle, getData }: drawerProps) {
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataAdd, setDataAdd] = useState<any>({
    title: "",
    discount: "",
    duration: "",
    sellerCommission: "",
    description: "",
    automaticPhaseChange: false,
    checkListItemIds: []
  });
  const [checkListItems, setCheckListItems] = useState([])
  const [params] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
  });
  const formRef = useRef<any>();

  useEffect(() => {
    if (isOpen) {
      formRef.current.data = data;
      setDataAdd({
        title: "",
        discount: "",
        duration: "",
        sellerCommission: "",
        description: "",
        automaticPhaseChange: false,
        checkListItemIds: []
      })
      setCheckListItems([]);
      const url = URLS.GET_CHECK_LIST_URL;
      const config = { params };
      ServiceApi.get(url, config).then(({ data }) => {
        let arr: any = []
        data.items.forEach((e: any) => {
          arr.push({ id: e.checkListItemId, name: e.label })
        })
        setCheckListItems(arr);
      });
    }
  }, [isOpen])// eslint-disable-line react-hooks/exhaustive-deps


  const handleSetValue = (key: any) => {
    return (value: any) => setDataAdd((p: any) => ({ ...p, [key]: value }));
  };

  const onSubmitForm = (formData: any) => {
    let dataNew = formRef.current.data;
    dataNew?.sellPhases?.push(formData)
    delete dataNew.sellPhaseTemplateId
    dataNew.sellPhases?.forEach((item: any) => {
      item.checkListItemIds?.forEach((i: any, index: any) => {
        item.checkListItemIds[index] = i.id ? i.id : i.checkListItemId
      })
    })
    setLoadingPage(true)
    const url = URLS.UPDATE_DETAILS_SELL_PHASES_URL(salesTemplateId);
    ServiceApi.put(url, dataNew).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      getData();
      toggle()
    }).finally(() => {
      setLoadingPage(false);
    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, dataAdd);
  };




  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <h2 className="text-gray-800 text-heading-2 mt-2"><Text>salesManagment.sellOffSales.salesTemplates.addPhase</Text></h2>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                className="ml-2"
                onClick={() => toggle()}
              >
                <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body >
          <Form ref={formRef} className="space-y-4 pr-4" onSubmit={onSubmitForm} id={"create-modal"}>
            <InputGroup
              label="salesManagment.sellOffSales.salesTemplates.phaseName"
              value={dataAdd.title}
              setValue={handleSetValue("title")}
              rules={rules.required}
            />
            <InputGroup
              label="salesManagment.sellOffSales.salesTemplates.discount"
              value={dataAdd.discount}
              setValue={(discount) => {
                if (discount >= 0 && discount <= 100) {
                  setDataAdd((p: any) => ({ ...p, "discount": discount }));
                }
              }}
              rules={rules.discount}
              keyfilter="pnum"
              append={<h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">%</h5>}
            />
            <InputGroup
              type="number"
              label="salesManagment.sellOffSales.salesTemplates.duration"
              value={dataAdd.duration}
              setValue={handleSetValue("duration")}
              rules={rules.required}
              append={<h5 className="text-heading-5 font-normal text-gray-400 border-gray-300 border-l px-4 py-3">Day</h5>}
            />
            <InputGroup
              label="salesManagment.sellOffSales.salesTemplates.sellerCommission"
              value={dataAdd.sellerCommission}
              setValue={(sellerCommission) => {
                if (sellerCommission >= 0 && sellerCommission <= 100) {
                  setDataAdd((p: any) => ({ ...p, "sellerCommission": sellerCommission }));
                }
              }}
              rules={rules.discount}
              keyfilter="pnum"
              append={<h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">%</h5>}
            />
            <InputGroup
              as="short-textarea"
              label="salesManagment.sellOffSales.salesTemplates.shortDescription"
              value={dataAdd.description}
              setValue={handleSetValue("description")}
            />
            {checkListItems.length !== 0 && <MultiSelect
              label="salesManagment.sellOffSales.salesTemplates.checkListItems"
              items={checkListItems}
              value={dataAdd.checkListItems}
              setValue={(value) => {
                return setDataAdd((p: any) => ({ ...p, checkListItemIds: value.length !== 0 ? value.map((val: any) => val) : [] }));
              }}
            />}
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button type="button" className="mr-2" onClick={() => toggle()} variant="light">
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingPage}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
