import { NotificationManager } from "common/notifications";
import {
  Breadcrumb,
  Button,
  Form,
  Icon,
  Stepper,
  Text,
  Wrapper
} from "components";
import { appPermissions } from "constant";
import { usePermission, useSelector } from "hooks";
import { Fragment, createContext, createElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import AttributeType from "./tabs/attribute-type";
import InfoAndProperties from "./tabs/info-and-properties";
import Review from "./tabs/review";



export const AttributeContext = createContext({});

export default function Create() {
  const profile = useSelector((s) => s.profile);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();
  const tabs = [
    {
      label: "productManagement.masterData.attributes.attributeType",
      desc: "productManagement.masterData.attributes.attributeTypeDesc",
      component: AttributeType,
    },
    {
      label: "productManagement.masterData.attributes.infoAndProperties",
      desc: "productManagement.masterData.attributes.infoAndPropertiesDesc",
      component: InfoAndProperties,
    },
    {
      label: "productManagement.masterData.attributes.review",
      desc: "productManagement.masterData.attributes.reviewDesc",
      component: Review,
    },
  ];
  const [loading, setLoading] = useState(false);
  const activeTab = tabs[activeTabIndex];
  const isFirstTab = activeTabIndex === 0;
  const isLastTab = activeTabIndex === tabs.length - 1;

  useEffect(() => {
    let arr: any = [];
    profile?.companyContentLanguages?.forEach((item: any) => {
      arr.push({ displayName: "", description: "", placeHolder: "", language: item })
    })
    setData({
      code: "",
      defaultValue: "",
      isRequired: false,
      items: [],
      measurementFamilyCode: null,
      perChannel: false,
      perLanguage: false,
      translations: arr,
      type: 10,
    })
  }, [profile])



  const goNext = () => {
    if (isLastTab) return submit();
    setActiveTabIndex((p) => p + 1);
  };
  const goPrev = () => {
    if (isFirstTab) return;
    setActiveTabIndex((p) => p - 1);
  };


  //permissions
  const {
    PS_CreateAttributeTemplate,
  } = appPermissions;
  const CREATE_PERMISSIONS = usePermission(PS_CreateAttributeTemplate);


  const submit = () => {
    setLoading(true);
    data["translates"] = [];
    data["order"] = data.orderAttribute;
    data?.translations?.forEach((item: any) => {
      if (item.displayName !== "") {
        data["translates"].push(item)
      }
    })
    const url = URLS.ADD_ATTRIBUTES_URLS;
    ServiceApi.post(url, data).then((res) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      navigate(`/productManagement/master-data/attributes/${res.data}?tab=1`);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 items-start">
        <Stepper tabs={tabs as []} activeTab={activeTabIndex} />
        <Wrapper
          as={Form}
          onSubmit={goNext}
          className="col-span-full lg:col-span-8"
        >
          <Wrapper.Body className="w-full lg:w-[calc(100%-9rem)] mx-auto space-y-8">
            <AttributeContext.Provider
              value={{
                data,
                setData,
              }}
            >
              {createElement(activeTab.component)}
            </AttributeContext.Provider>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                disabled={isFirstTab}
                onClick={goPrev}
              >
                <Icon icon="arrow-left" /> <Text>global.buttons.back</Text>
              </Button>
              {CREATE_PERMISSIONS && (
                <Button
                  type="submit"
                  variant="primary"
                  loading={loading}
                >
                  {isLastTab ? (
                    <Fragment>
                      <Icon icon="check" /> <Text>global.buttons.submit</Text>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Text>global.buttons.next</Text> <Icon icon="arrow-right" />
                    </Fragment>
                  )}
                </Button>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>
      </div>
    </div>
  );
}
