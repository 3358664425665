import ProductFamilies from "pages/product-managment/master-data/product-families";
import EditProductFamilies from "pages/product-managment/master-data/product-families/Edit";

const ProductFamiliesRoutes = [
  {
    path: "",
    element: <ProductFamilies />,
  },
  {
    path: ":id",
    element: <EditProductFamilies />,
  },
];

export default ProductFamiliesRoutes;
