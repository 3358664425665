const SUPPLIERS = {
  SUPPLIER_URL: (id) => `productservice/api/suppliers/${id}`,
  GET_SUPPLIERS: "productservice/api/suppliers",
  CREATE_SUPPLIERS: "productservice/api/suppliers",
  ADD_SUPPLIER: "productservice/api/suppliers",
  UPLOAD_SUPPLIERS_IMAGES: (id) => `productservice/api/suppliers/${id}/logo`,
  GET_SUPPLIER_DETAILS: (id) => `productservice/api/suppliers/${id}`,
  EDIT_SUPPLIER: "productservice/api/suppliers/",
  DELETE_SUPPLIER_ID: "productservice/api/suppliers/",
  //...... discount
  ADD_DISCOUNT_SUPPLIERS: (id) => `productservice/api/suppliers/${id}/discount`,
  DELETE_DISCOUNT_SUPPLIERS: (id, discountId) =>
    `productservice/api/suppliers/${id}/discount/${discountId}`,
  UPDATE_DISCOUNT_SUPPLIERS: (id, discountId) =>
    `productservice/api/suppliers/${id}/discount/${discountId}`,

  //...... programs
  GET_SUPPLIER_PROGRAMS: (code) =>
    `productservice/bysuppliercode?suuplierCode=${code}`,
  GET_PROGRAM_DETAILS: (id) => `productservice/api/programs/${id}`,
  CREATE_NEW_PROGRAM: "productservice/api/programs",
  UPDATE_PROGRAM: (id) => `productservice/api/programs/${id}`,
  DELETE_SUPPLIER: (id) => `productservice/api/suppliers/${id}`,

  //...... attributs - programs
  ADD_ATTRIBUTE: (id) => `productservice/api/programs/${id}/addattribute`,
  DELETE_ATTRIBUTE: (id, code) =>
    `productservice/api/programs/${id}/attribute/${code}`,

  //...... discount - programs
  ADD_DISCOUNT_PROGRAMS_SUPPLIERS: (id) =>
    `productservice/api/programs/${id}/discount`,
  DELETE_DISCOUNT_PROGRAMS_SUPPLIERS: (id, discountId) =>
    `productservice/api/programs/${id}/discount/${discountId}`,
  UPDATE_DISCOUNT_PROGRAMS_SUPPLIERS: (id, discountId) =>
    `productservice/api/programs/${id}/discount/${discountId}`,

  //....... Holidays
  GET_HOLIDAYS_SUPPLIERS: `productservice/api/holidays`,
  POST_HOLIDAYS_SUPPLIERS: `productservice/api/holidays`,
  PUT_HOLIDAYS_SUPPLIERS: (id) => `productservice/api/holidays/${id}`,
  DELETE_HOLIDAYS_SUPPLIERS: (id) => `productservice/api/holidays/${id}`,

  //........ Contact Person
  ADD_CONTACT_PERSON: (id) =>
    `productservice/api/suppliers/${id}/contactperson`,
  EDIT_DELETE_CONTACT_PERSON: (id, contactPersonId) =>
    `productservice/api/suppliers/${id}/contactperson/${contactPersonId}`,
};

export default SUPPLIERS;
