import { Icon, Text } from "components";

const InfoTemplate = ({ item }: any) => {
  return (
    <div className="flex flex-col gap-y-2 text-gray-500 text-body-2 font-medium">
      <h6 className="text-heading-6">{item.title}</h6>
      <p>
        <Text>salesManagment.sellOffSales.sellOffProduct.articleNumber</Text> #
        {item.articleNumber}
      </p>
      <p>{item.productFamilyTranslates[0].name}</p>
      <p>
        <Icon icon="location-dot" size="xl" className="mr-1" />{" "}
        {item.storeTitle} / {item.location}
      </p>
    </div>
  );
};

export default InfoTemplate;
