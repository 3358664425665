import ImagePlaceHolder from "assets/image/ImagePlaceholder.svg";
import UserplaceHolder from "assets/image/UserPlaceholder.svg";
import Button from "./Button";
import Icon from "./Icon";
import Text from "./Text";

type ImageProps = {
  src?: string | null;
  alt?: string;
  className?: string;
  imageClassName?: string;
  logo?: boolean;
  onClick?: () => void;
  placeholder?: "user" | "image";
  editor?: boolean;
  info?: boolean;
  cover?: boolean;
  setCover?: boolean;
  onInfoClick?: () => void;
  onSetCover?: () => void;
  setCoverLoading?: boolean;
};

export default function Image({
  src,
  alt = "",
  className = "",
  placeholder = "image",
  imageClassName = "",
  logo = false,
  editor = false,
  info = false,
  setCoverLoading = false,
  setCover,
  cover,
  onClick,
  onInfoClick,
  onSetCover,
}: ImageProps) {
  const onClickHandler = () => {
    onClick && onClick();
  };

  return (
    <div
      onClick={onClickHandler}
      className={`${logo ? "flex justify-center" : "flex-center"
        } rounded overflow-hidden relative ${className}`}
    >
      {cover && (
        <Button
          type="button"
          size="sm"
          variant="white"
          textColor="primary"
          className="absolute top-2 left-2  opacity-80"
        >
          <Text>global.buttons.coverPhoto</Text>
        </Button>
      )}
      {editor && (
        <Button
          type="button"
          size="sm"
          className="absolute top-2 right-2 !p-2 opacity-80 !w-[32px] !h-[32px]"
        >
          <Icon icon="edit" />
        </Button>
      )}
      {info && (
        <Button
          type="button"
          size="sm"
          className="absolute bottom-2 right-2  opacity-80"
          onClick={(e) => {
            onInfoClick && onInfoClick();
            e.stopPropagation();
          }}
        >
          <Icon icon="info" />
        </Button>
      )}
      {setCover && (
        <Button
          type="button"
          size="sm"
          className="absolute bottom-2 left-2  opacity-80"
          loading={setCoverLoading}
          onClick={(e) => {
            onSetCover && onSetCover();
            e.stopPropagation();
          }}
        >
          <Text>global.buttons.setAsCoverPhoto</Text>
        </Button>
      )}
      {
        <img
          className={`${logo ? "w-auto h-auto" : "w-full object-cover"
            } ${imageClassName}`}
          src={
            src || (placeholder === "user" ? UserplaceHolder : ImagePlaceHolder)
          }
          alt={alt}
        />
      }
    </div>
  );
}
