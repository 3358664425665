import { Fragment, useContext, useState, useEffect } from "react";
import { Select, Skeleton } from "components";
import { ServiceApi, URLS } from "services";
import { AddUserListContext } from "..";
import { config, rules } from "constant";

export default function UsersGroup() {
  const { data, setData } = useContext<any>(AddUserListContext);
  const [groupList, setGroupList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  const getData = () => {
    const url = URLS.USER_GROUP_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setGroupList(data.items);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loadingList ? (
        <Skeleton.Input />
      ) : (
        <Select
          label="configurations.userManagment.userList.usersGroup"
          items={groupList}
          value={data.userGroupId}
          setValue={(e) => {
            groupList?.forEach((i: any) => {
              if (i.id === e) {
                setData((p: any) => ({
                  ...p,
                  userGroupName: i.name,
                  userGroupId: i.id,
                }));
              }
            });
          }}
          rules={rules.required}
        />
      )}
    </Fragment>
  );
}
