export const sellOffCommissions = {
    soldPrice:  "Sold Price",
    articleNumber: "Article Number",
    sellerCommission: "Seller Commission",
    sellerCommissionAmount: "Seller Commission amount",
    fastSaleCommission: "Fast Sale Commission",
    keyword:"Keyword",
    seller:"Seller",
    status:"Status",
    soldDateRange:"Sold Date Range",
    thisWeek:"This Week",
    thisMonth:"This Month",
    lastMonth:"Last Month",
    from:"From",
    to:"To",
    export:"Export"
}