import { NotificationManager } from "common/notifications";
import {
    Button,
    Drawer,
    Form,
    Icon,
    InputGroup,
    SelectLang,
    Text
} from "components";
import { flagCountry, rules } from "constant";
import { useSelector } from "hooks";
import { useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";


export default function Create(props: any) {
  const [data, setData] = useState<any>({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [langSelected, setLangSelected] = useState("de");
  const [name, setName] = useState("");
  const [standardUnitName, setStandardUnitName] = useState("");
  const profile = useSelector((s) => s.profile);
  const formRef = useRef<any>();

  useEffect(() => {
    if (props.isOpen) {
      setName("")
      setStandardUnitName("")
      let arr: any = [];
      let translatesNew: any = [];
      let standardUnitTranslationsNew: any = [];
      profile?.companyContentLanguages?.forEach((item: any) => {
        arr.push({ id: item, name: item })
        translatesNew.push({ name: "", language: item })
        standardUnitTranslationsNew.push({ name: "", language: item })
      })
      setData({
        measurementFamilyId: props.measurementFamilyId,
        symbol: "",
        translations: translatesNew,
        code: "",
        isActive: true,
        standardUnitCode: "",
        standardUnitSymbol: "",
        standardUnitTranslations: standardUnitTranslationsNew
      })
      setLangItems(arr)
      setLangSelected(profile.companyDefaultLanguage)
    }
  }, [props.isOpen])// eslint-disable-line react-hooks/exhaustive-deps


  const submit = () => {
    let translates: any = [];
    let standardUnitTranslates: any = [];
    setLoadingButton(true);
    data.translations?.forEach((item: any) => {
      if (item.name !== "") {
        translates.push(item)
      }
    })
    data.standardUnitTranslations?.forEach((item: any) => {
      if (item.name !== "") {
        standardUnitTranslates.push(item)
      }
    })
    data.translations = translates;
    data.standardUnitTranslations = standardUnitTranslates;
    const url = URLS.ADD_MEASUREMENT_FAMILY_URL;
    ServiceApi.post(url, data).then((res) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      props.getData();
    }).finally(() => {
      props.toggle();
      setLoadingButton(false);

    });
  };

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    data?.translations?.forEach((item: any) => {
      if (item.language === lang) {
        setName(item.name)
      }
    })
    data?.standardUnitTranslations?.forEach((item: any) => {
      if (item.language === lang) {
        setStandardUnitName(item.name)
      }
    })
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Form ref={formRef} onSubmit={submit} id={"create-modal"}>
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pt-6 pr-4">
            <div className="flex items-center justify-between">
              <h2 className="text-gray-800 text-heading-2"><Text>productManagement.masterData.measurementUnits.addMeasurement</Text></h2>
              <div className="flex items-center justify-between">
                <Button
                  type="button"
                  variant="light"
                  size="sm"
                  className="ml-2"
                  onClick={() => props.toggle()}
                >
                  <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
                </Button>
                <div className="ml-1">
                <SelectLang value={langSelected} items={langItems} onChangeLanguage={onChangeLanguage} />
                </div>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-8 pr-4">
            <InputGroup
              label="productManagement.masterData.measurementUnits.name"
              value={name}
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                data?.translations?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.name = value
                  }
                })
                setData((p: any) => ({ ...p, translates: data?.translations }));
                setName(value)
              }}
              rules={rules.required}
            />
            <InputGroup
              label="productManagement.masterData.measurementUnits.code"
              value={data?.code}
              className="border-white"
              setValue={(code) => setData((p: any) => ({ ...p, code }))}
              rules={rules.required}
            />
            <div><h5 className="text-gray-800" >Standard Unit</h5></div>
            <InputGroup
              label="productManagement.masterData.measurementUnits.standardUnitName"
              value={standardUnitName}
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                data?.standardUnitTranslations?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.name = value
                  }
                })
                setData((p: any) => ({ ...p, standardUnitTranslations: data?.standardUnitTranslations }));
                setStandardUnitName(value)
              }}
              rules={rules.required}
            />
            <InputGroup
              label="productManagement.masterData.measurementUnits.standardUnitCode"
              value={data?.standardUnitCode}
              className="border-white"
              setValue={(standardUnitCode) => setData((p: any) => ({ ...p, standardUnitCode }))}
              rules={rules.required}
            />
            <InputGroup
              label="productManagement.masterData.measurementUnits.standardUnitSymbol"
              value={data?.standardUnitSymbol}
              className="border-white"
              setValue={(standardUnitSymbol) => setData((p: any) => ({ ...p, standardUnitSymbol }))}
            />
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button
              type="submit"
              variant="primary"
              loading={loadingButton}
            >
              <Icon icon="check" /> <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
