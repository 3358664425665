import { NotificationManager } from "common/notifications";
import {
  Button,
  DeleteModal,
  HandleName,
  Icon,
  SelectLang,
  Skeleton,
  Status,
  Table,
  Text,
  Wrapper,
  InputGroup,
  NoItems
} from "components";
import { appPermissions } from "constant";
import { usePermission, useSelector, useToggleState } from "hooks";
import {
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { ServiceApi, URLS } from "services";
import { MeasurementContext } from "..";
import CreateModal from "./unit-modals/create-modal";
import UpdateModal from "./unit-modals/update-modal";
import { onFilter } from "utils";
import { useTranslation } from "react-i18next";


export default function Attributes() {
  const { i18n } = useTranslation();
  const profile = useSelector((s) => s.profile);
  const { measurementData, measurementFamilyId } = useContext<any>(MeasurementContext);
  const [data, setData] = useState<any>();
  const [dataList, setDataList] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<any>({});
  const [isOpenCreateModal, toggleCreateModal] = useToggleState(false);
  const [isOpenUpdateModal, toggleUpdateModal] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [langSelected, setLangSelected] = useState("de");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getData()
  }, [measurementData])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    let arr: any = [];
    profile?.companyContentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item })
    })
    setLangItems(arr)
  }, [profile])// eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    setLoading(true);
    const url = URLS.GET_DETAIL_MEASUREMENT_FAMILY_URL(measurementFamilyId);
    ServiceApi.get(url)
      .then(({ data }) => {
        data?.measurementFamilyUnits?.forEach((item: any) => {
          item?.measurementFamilyUnitTranslation?.forEach((i: any, index: number) => {
            if (i.language === i18n.language) {
              item["title"] = i.name
            }
          })
        })
        setData(data?.measurementFamilyUnits);
        setDataList(data?.measurementFamilyUnits);
      }).finally(() => {
        setLoading(false);
      });
  };


  //permissions
  const {
    PS_ViewMeasurementFamily,
  } = appPermissions;
  const UPDATE_PERMISSIONS = usePermission(PS_ViewMeasurementFamily);

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_MEASUREMENT_FAMILY_UNIT_URL(measurementFamilyId, id);
    ServiceApi.patch(url).then(({ data }) => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      setLoadingButton(false);
      getData()
      toggleDeleteModal();
    }).catch(() => setLoadingButton(false));
  };

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
  };

  const onSearchHandler = (e: string) => {
    let keys = ["title", "code"];
    if (e !== "") {
      setDataList(onFilter(data, keys, e));
    } else {
      setDataList(data)
    }
    setSearchValue(e)
  };

  return (
    <div className="space-y-4">
      {loading ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <Fragment>
          <div className="flex justify-between items-center">
            {data?.length !== 0 && <div className="grid grid-cols-5 gap-4 items-center">
              <InputGroup
                value={searchValue}
                setValue={onSearchHandler}
                placeholder="productManagement.masterData.attributesGroups.searchAttribute"
                disabled={loading}
              />
              <p className="text-body-2">
                {searchValue.length === 0
                  ? dataList?.length
                  : dataList?.length}{" "}
                Items
              </p>
            </div>}
            <div className="text-right">
              <SelectLang className="bg-white" value={langSelected} items={langItems} onChangeLanguage={onChangeLanguage} />
            </div>
          </div>
          {dataList?.length !== 0 ?
            <Table>
              {dataList?.map((e: any) => {
                return (
                  <tr>
                    <td>
                      <div className="w-fit inline-flex flex-center gap-4">
                        <div className="flex justify-center items-center w-[42px] h-[42px] rounded-[6px] bg-primary-light">
                          <div><h3 className="text-primary font-semibold text-heading-3">{e.symbol}</h3></div>
                        </div>
                        <div className="space-y-3">
                          <div><h6 className="text-heading-6 text-gray-800 font-semibold">{e && <HandleName translations={e?.measurementFamilyUnitTranslation} code={e?.code} keyName="name" language={langSelected} />}</h6></div>
                          <div><span className="text-body-2 text-gray-500 font-medium">{e.code}</span></div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <Status.Standard id={e.isStandard} />
                    </td>
                    <td>
                      {e.isStandard ? <></> :
                        UPDATE_PERMISSIONS && <Button
                          type="button"
                          size="sm"
                          variant="danger"
                          className="mr-2"
                          light
                          onClick={() => {
                            setSelected(e);
                            toggleDeleteModal();
                          }}
                        >
                          <Icon icon={"trash"} />
                        </Button>
                      }
                      {UPDATE_PERMISSIONS && <Button
                        type="button"
                        onClick={() => {
                          setSelected(e);
                          toggleUpdateModal()
                        }}
                        variant="primary"
                        light
                        size="sm">
                        <Icon icon={"edit"} />
                      </Button>}
                    </td>
                  </tr>
                )
              })}
            </Table>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
          <Wrapper.Section className="w-full h-[52px] bg-gray-100 border-primary text-center py-[17px] courser-pointer cursor-pointer mb-4" >
            <div className="w-full" onClick={() => {
              toggleCreateModal();
            }}>
              <h5 className="text-primary">
                <Icon className="mr-2 text-primary" icon={"square-plus"} />
                <Text>productManagement.masterData.measurementUnits.addUnit</Text>
              </h5>
            </div>
          </Wrapper.Section>
          <CreateModal isOpen={isOpenCreateModal} toggle={toggleCreateModal} measurementFamilyId={measurementFamilyId} getData={getData} />
          <UpdateModal isOpen={isOpenUpdateModal} toggle={toggleUpdateModal} measurementFamilyId={measurementFamilyId} getData={getData} selected={selected} />
          <DeleteModal
            isOpen={isOpenDeleteModal}
            toggle={toggleDeleteModal}
            selected={{ name: <HandleName translations={selected?.measurementFamilyUnitTranslation} code={selected?.code} keyName="name" language={langSelected} />, id: selected.unitId }}
            loading={loadingButton}
            onConfirm={submitDelete}
          />

        </Fragment>
      )}
    </div>
  )
}