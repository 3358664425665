import { userList } from "./users-list";
import { userGroups } from "./user-groups";
import { roles } from "./roles";


export const userManagment = {
   userList: userList,
   userGroups: userGroups,
   roles: roles,

}