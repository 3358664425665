export const legalEntities = {
  home: "STARTSEITE",
  legalEntities: "Rechtseinheiten",
  legalEntity: "Rechtseinheit",
  compony: "Firma",
  configurations: "Konfigurationen",
  addLegalEntity: "Neue Rechtseinheit",
  updateLegalEntity: "Rechtseinheit aktualisieren",
  create: "Erstellen",
  discard: "Verwerfen",
  view: "Details anzeigen",
  description: "Beschreibung",
  code: "Code",
  leganName: "Titel",
  generalInfo: "Allgemeine Informationen",
  saveChanges: "Änderungen speichern"
};