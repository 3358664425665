import { Fragment, useContext, useMemo, useState } from "react";
import { PositionContext } from ".";
import { types } from "constant";
import { componentType } from "..";
import { Badge, Button, Icon, Text, DeleteModal } from "components";
import { ServiceApi } from "services";
import { cloneDeep } from "lodash";
import { toast } from "react-hot-toast";
import EditCarouselComponent from "./EditCarouselComponent";
import EditBannerComponent from "./EditBannerComponent";
import { useToggleState } from "hooks";
import { NotificationManager } from "common/notifications";

export default function Component({ id, items, title }: componentType) {
  const { positionData, setPositionData, isCarousel, isBanner } =
    useContext(PositionContext);
  const [loading, setLoading] = useState(false);
  const [isEditOpen, toggleEdit] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [selected, setSelected] = useState<any>();
  const removeComponent = () => {

    setLoading(true);
    const url = `/productservice/api/positions/${positionData.id}/component/${id}`;
    ServiceApi.delete(url)
      .then(() => {
        setPositionData((p) => {
          const data = cloneDeep(p);
          const index = data.components.findIndex(
            (e) => e.id === id
          );
          data.components.splice(index, 1);
          return data;
        });
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        toggleDeleteModal();
      })
      .finally(() => {
        setLoading(false);
      });

  };
  const componentType = useMemo(() => {
    return types.position.find((e) => e.id === positionData.componentType);
  }, [positionData.componentType]);
  return (
    <Fragment>
      <section className="w-full bg-white rounded shadow-[0px_4px_20px_0px_#38476d17] p-4 flex items-center gap-4">
        <img className="w-32" src={componentType?.icon} alt={title} />
        <div className="space-y-2 flex-1">
          <h6 className="text-gray-800">{title}</h6>
          <Badge variant="gray">
            <Text>{componentType?.name ?? ""}</Text>
          </Badge>
        </div>
        <Button
          variant="danger"
          light
          size="sm"
          loading={loading}
          onClick={() => {
            setSelected(componentType);
            toggleDeleteModal();
          }}
        >
          <Icon icon="trash" />
        </Button>
        <Button type="button" variant="light" size="sm" onClick={toggleEdit}>
          <Text>applications.positions.componentDetailsButton</Text>
        </Button>
      </section>
      {isCarousel && (
        <EditCarouselComponent
          isOpen={isEditOpen}
          toggle={toggleEdit}
          id={id}
          items={items}
          title={title}
        />
      )}
      {isBanner && (
        <EditBannerComponent
          isOpen={isEditOpen}
          toggle={toggleEdit}
          componentId={id}
          initData={items?.at(0)}
        />
      )}
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: title, id: id }}
        loading={loading}
        onConfirm={removeComponent}
      />
    </Fragment>
  );
}
