import { ReactNode, ElementType } from "react";

type wrapperProps = {
  as?: any;
  className?: string;
  children: ReactNode;
  onSubmit?:()=>void;
};

function Wrapper<E extends ElementType>({
  as: Component = "div",
  className = "",
  children,
  ...props
}: wrapperProps) {
  return (
    <Component
      className={`w-full bg-white rounded-xl shadow ${className}`}
      {...props}
    >
      {children}
    </Component>
  );
}
function WrapperHeader({ className = "", children }: wrapperProps) {
  return (
    <div className={`w-full border-b border-gray-200 px-8 py-5 ${className}`}>
      {children}
    </div>
  );
}
function WrapperBody({ className = "", children }: wrapperProps) {
  return <div className={`w-full p-8 ${className}`}>{children}</div>;
}
function WrapperFooter({ className = "", children }: wrapperProps) {
  return (
    <div className={`w-full border-t border-gray-200 px-8 py-5 ${className}`}>
      {children}
    </div>
  );
}
function WrapperSection({ className = "", children }: wrapperProps) {
  return (
    <div
      className={`w-full border border-gray-500 border-dashed rounded p-8 ${className}`}
    >
      {children}
    </div>
  );
}
Wrapper.Header = WrapperHeader;
Wrapper.Body = WrapperBody;
Wrapper.Footer = WrapperFooter;
Wrapper.Section = WrapperSection;
export default Wrapper;
