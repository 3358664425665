import i18n from "langs/i18n";

type valueType = Date | string | number | null | undefined;
export const convertDate = (value: valueType): string => {
  const langs: any = {
    de: "de-DE",
    en: "en-EN"
  }
  if (!value || value.toString().includes("0001")) return "";
  const date = new Date(value);
  return date.toLocaleString(langs[i18n.language], {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}