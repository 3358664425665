import { InputGroup, Select, Text, Wrapper } from "components";
import { rules } from "constant";
import { useContext, useEffect, useState } from "react";
import { ServiceApi } from "services";
import LEGAL_ENTITY from "services/urls/legal-entity";
import { StoreContext } from ".";

export default function General() {
  const { storeData, setStoreData } = useContext(StoreContext);
  const [legalEntities, setLegalEntities] = useState([]);
  const getLegalEntities = () => {
    const url = LEGAL_ENTITY.GET_LEGALENTITY;
    const config = { params: { pageNumber: 1, pageSize: 50 } };
    ServiceApi.get(url, config).then(({ data }) => {
      const result = data.items.map((e) => ({
        name: e.name,
        id: e.code,
      }));
      setLegalEntities(result);
    });
  };
  const handleSetData = (key) => {
    return (value) => setStoreData((p) => ({ ...p, [key]: value }));
  };
  useEffect(getLegalEntities, []);
  return (
    <Wrapper>
      <Wrapper.Header>
        <h1 className="text-heading-2 font-semibold">
          <Text>company.stores.basicInfo</Text>
        </h1>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
        <div className="space-y-8">
          <InputGroup
            label="company.stores.storeName"
            value={storeData.title}
            setValue={handleSetData("title")}
            rules={rules.required}
          />
          <Select
            label="company.stores.legalEntity"
            items={legalEntities}
            value={storeData.legalEntityCode}
            setValue={handleSetData("legalEntityCode")}
            rules={rules.required}
          />
          <InputGroup
            as="short-textarea"
            label="company.stores.shortDescription"
            value={storeData.shortDescription}
            setValue={handleSetData("shortDescription")}
            rules={rules.required}
          />
        </div>
        <div className="[&_.input-group]:h-full [&_.input-box]:h-[calc(100%-17px-1rem)] [&_.form-control]:h-full">
          <InputGroup
            as="textarea"
            label="company.stores.description"
            value={storeData.body}
            setValue={handleSetData("body")}
            rules={rules.required}
          />
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
