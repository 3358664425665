import { NotificationManager } from "common/notifications";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Select,
  Text,
  Toggler,
} from "components";
import { rules } from "constant";
import { useDataState } from "hooks";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";


type detailsModalType = {
  isOpen: boolean;
  supplierId: string;
  titleItems: any;
  selected: any;
  toggle: (value?: boolean) => void;
  getData: () => void;
};

export default function DetailsModal(props: detailsModalType) {
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState("");
  const [showTextInput, setShowTextInput] = useState<boolean>(false);
  const formRef = useRef<any>();
  const [data, setData, setBaseData, isChanged] = useDataState({
    title: "",
    amount: "",
    order: "",
    isActive: true,
  });


  useEffect(() => {
    if (props.isOpen) {
      if (props.selected.title !== "supplierDiscount" && props.selected.title !== "programDiscount") {
        setShowTextInput(true)
        setSelect("customText")
      } else {
        setShowTextInput(false)
        setSelect(props.selected.title)
      }
      setBaseData(props.selected)
    }
  }, [props.isOpen])


  const onSubmitForm = (formData: any) => {
    setLoading(true);
    const url = URLS.UPDATE_DISCOUNT_SUPPLIERS(props.supplierId, formData.id);
    ServiceApi.put(url, formData).then(({ }) => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
      props.toggle(false);
      props.getData();
    }).finally(() => {
      setLoading(false);
    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };


  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Modal.Header className="!text-left">
        <h2><Text>productManagement.masterData.suppliers.discount</Text></h2>
      </Modal.Header>
      <Form ref={formRef} onSubmit={onSubmitForm} id={`update-modal-discount-${props?.selected?.id}`}>
        <Modal.Body className="grid grid-cols-1 lg:grid-cols-3 px-8">
          <div className="col-start-1 col-end-4 mb-8">
            <Select
              label="productManagement.masterData.suppliers.title"
              setValue={(title) => {
                if (title === "customText") {
                  setSelect(title)
                  setShowTextInput(true)
                  setData((p) => ({ ...p, title: "" }))
                } else {
                  setShowTextInput(false)
                  setSelect(title)
                  setData((p) => ({ ...p, title }))
                }
              }}
              items={props.titleItems}
              value={select}
              rules={rules.required}
            />
          </div>
          {showTextInput ? <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              label="productManagement.masterData.suppliers.customTitle"
              value={data.title}
              setValue={(title) => setData((p) => ({ ...p, title }))}
            />
          </div>
            : ""}
          <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              label="productManagement.masterData.suppliers.amount"
              value={data.amount}
              setValue={(amount) => {
                if (amount >= 0 && amount <= 100)
                  setData((p) => ({ ...p, amount }))
              }}
              append={<h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">%</h5>}
              rules={rules.discount}
              keyfilter="pnum"

            />
          </div>
          <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              type="number"
              label="productManagement.masterData.suppliers.sortOrder"
              value={data.order}
              setValue={(order) => setData((p) => ({ ...p, order }))}
              rules={rules.required}
              keyfilter="onlyNum"
            />
          </div>
          <div className="col-start-1 col-end-1 mb-2">
            <Toggler
              label={
                data.isActive
                  ? "productManagement.masterData.suppliers.active"
                  : "productManagement.masterData.suppliers.deActive"
              }
              value={data.isActive}
              setValue={(isActive) => setData((p) => ({ ...p, isActive }))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" className="mr-2" onClick={props.toggle} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loading} disabled={!isChanged}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}