import { useEffect, useState, useContext } from "react";
import { Modal, Button, Text, Icon, CheckBox, Skeleton } from "components";
import { checkPriceAvailability, convertDate } from "utils";
import { ServiceApi, URLS } from "services";
import { SellOffProductsContext } from "..";

import { NotificationManager } from "common/notifications";
import { useToggleState } from "hooks";
import { isEmpty } from "lodash";

type Props = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: CallableFunction;
  productId?: string;
};

const StartPreparationPhaseModal = ({
  isOpen,
  toggle,
  getData,
  productId,
}: Props) => {
  const { id } = useContext(SellOffProductsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [startPhaseLoading, setStartPhaseLoading] = useToggleState();
  const [startPrePhaseData, setStartPrePhaseData] = useState<any>({});

  const preparationCheckList = () => {
    setLoading(true);
    const pid = id || productId;
    const url = URLS.PREPARATION_PHASE_START_REQUIREMENT_URL(pid);
    ServiceApi.get(url).then(({ data }) => {
      setStartPrePhaseData(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    const pid = id || productId;
    if (pid) {
      preparationCheckList();
    }
  }, [id, productId]);

  const onChangeHandler = (listId: string) => {
    setLoading(true);
    const pid = id || productId;
    const url = URLS.PREPARATION_PHASE_CHECKLIST_URL(pid);
    const body = { clonedPreparationPhaseCheckListItemId: listId };
    ServiceApi.patch(url, body).then(({ data }) => {
      getData();
      preparationCheckList();
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
      setLoading(false);
    });
  };

  const startPhase = () => {
    setStartPhaseLoading();
    const pid = id || productId;
    const url = URLS.START_PHASE_URL(pid);
    ServiceApi.post(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.start-phase-msg",
          "global.toast.start-phase-title"
        );
        setStartPhaseLoading();
        getData();
        toggle();
      })
      .catch(() => {
        setStartPhaseLoading();
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>
            salesManagment.sellOffSales.sellOffProduct.startSelloffProccess
          </Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="max-h-100 overflow-auto space-y-4">
        <>
          <div className="flex flex-col gap-y-2 text-gray-500 text-body-2 font-medium">
            <h6 className="text-heading-6">{startPrePhaseData?.title}</h6>
            <p>
              <Text>
                salesManagment.sellOffSales.sellOffProduct.articleNumber
              </Text>
              #{startPrePhaseData?.articleNumber}
            </p>
          </div>
          <div className="grid grid-cols-11 gap-2">
            <div className="col-span-5 bg-gray-100 p-4 rounded space-y-4">
              <p>
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.currentPhase
                </Text>
              </p>
              <p className="text-gray-800 text-body-2 font-medium">
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.preparation
                </Text>
              </p>
              <p>{checkPriceAvailability(startPrePhaseData?.originalPrice)}</p>
            </div>
            <div className="flex flex-center">
              <Icon icon="arrow-right" />
            </div>
            <div className="col-span-5 bg-gray-100 p-4 rounded space-y-4">
              <p>
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.nextPhase
                </Text>
              </p>
              <p className="text-gray-800 text-body-2 font-medium">
                {startPrePhaseData?.firstPhaseTitle}
              </p>
              <p className="text-gray-800 text-body-2 font-medium">
                0/{startPrePhaseData?.firstPhaseTotalDays}{" "}
                <Text>salesManagment.sellOffSales.sellOffProduct.days</Text>
              </p>
              <div className="text-body-2 space-x-2">
                <span className="text-gray-500 line-through">
                  {checkPriceAvailability(startPrePhaseData?.originalPrice)}
                </span>
                <span className="text-danger">
                  %{startPrePhaseData?.firstPhaseDiscount}
                </span>
                <span className="text-primary-active text-heading-6 font-semibold">
                  {checkPriceAvailability(startPrePhaseData?.firstPhasePrice)}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2 text-gray-500 text-body-2 font-medium">
            <h6 className="text-heading-6">
              <Text>salesManagment.sellOffSales.sellOffProduct.checkList</Text>
            </h6>
            <p>{startPrePhaseData?.preparationPhaseDescription}</p>
          </div>
          {loading && isEmpty(startPrePhaseData) && (
            <>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </>
          )}
          <div className="grid gap-4">
            {startPrePhaseData.clonedPreparationPhaseCheckListItems?.map(
              (list: any, index: number) => {
                return (
                  <>
                    {loading ? (
                      <Skeleton.Input />
                    ) : (
                      <div
                        className="p-4 bg-primary-light rounded flex items-center space-x-4"
                        key={list.label}
                      >
                        <div>
                          <CheckBox
                            setValue={(checked) => {
                              let clone: any = [
                                ...startPrePhaseData.clonedPreparationPhaseCheckListItems,
                              ];
                              clone[index].isChecked = checked;
                              onChangeHandler(list.id);
                            }}
                            value={list.isChecked}
                            className="bg-white"
                          />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <span className="text-gray-700 text-heading-5 font-normal">
                            {list.label}
                          </span>
                          <p className="text-body-2">
                            {list.updatedBy} {convertDate(list.updatedAt)}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                );
              }
            )}
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          className="mr-2"
          onClick={toggle}
          variant="white"
          disabled={loading}
        >
          <Text>salesManagment.sellOffSales.sellOffProduct.close</Text>
        </Button>
        <Button
          type="button"
          onClick={startPhase}
          loading={startPhaseLoading}
          disabled={
            !startPrePhaseData.clonedPreparationPhaseCheckListItems ||
            startPrePhaseData.clonedPreparationPhaseCheckListItems
              ?.map((item: any) => item.isChecked)
              .includes(false)
          }
        >
          <Text>salesManagment.sellOffSales.sellOffProduct.start</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default StartPreparationPhaseModal;
