import {
  Breadcrumb,
  Button,
  Form,
  Icon,
  Text,
  Wrapper,
  Stepper,
} from "components";
import { createContext, createElement } from "react";
import { Fragment, useState } from "react";
import { ServiceApi } from "services";
import STORE from "services/urls/store";
import BasicInfo from "./BasicInfo";
import Location from "./Location";
import Image from "./Image";
import Review from "./Review";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
export const AddStoreContext = createContext({});
export default function AddNewStore() {
  const location = useLocation();
  const navigate = useNavigate();
  const tabs = [
    {
      label: "company.stores.basicInfo",
      desc: "company.stores.basicInfoDesc",
      component: BasicInfo,
    },
    {
      label: "company.stores.location",
      desc: "company.stores.locationDesc",
      component: Location,
    },
    {
      label: "company.stores.image",
      desc: "company.stores.imageDesc",
      component: Image,
    },
    {
      label: "company.stores.review",
      desc: "company.stores.reviewDesc",
      component: Review,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    code: "",
    title: "",
    legalEntityCode: null,
    shortDescription: "",
    body: "",
    mainPhoto: "",
    isActive: true,
    address: {
      name: "",
      country: "",
      state: "",
      city: "",
      postalCode: "",
      street: "",
      houseNo: "",
      location: {
        longitude: null,
        latitude: null,
      },
    },
    openingHours: [],
    generalSettings: {
      iln: "",
    },
    salesSettings: {
      iwofurnSalesCalculationFactor: 0,
      iwofurnRoundingMethod: "",
    },
  });
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTab = tabs[activeTabIndex];
  const isFirstTab = activeTabIndex === 0;
  const isLastTab = activeTabIndex === tabs.length - 1;
  const hasNotMainPhoto = activeTabIndex === 2 && !data.mainPhoto;
  const goNext = () => {
    if (isLastTab) return submit();
    setActiveTabIndex((p) => p + 1);
  };
  const goPrev = () => {
    if (isFirstTab) return;
    setActiveTabIndex((p) => p - 1);
  };
  const submit = () => {
    setLoading(true);
    const url = STORE.ADD_STORE_URL;
    const body = { ...data };
    ServiceApi.post(url, body)
      .then(({ data: storeId }) => {
        toast.success("company.stores.storeAdded");
        const baseUrl = location.pathname.split("/");
        baseUrl[baseUrl.length - 1] = storeId;
        navigate(baseUrl.join("/"));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 items-start">
        <Stepper tabs={tabs} activeTab={activeTabIndex} />
        <Wrapper
          as={Form}
          onSubmit={goNext}
          className="col-span-full lg:col-span-8"
        >
          <Wrapper.Body className="w-full lg:w-[calc(100%-9rem)] mx-auto space-y-8">
            <AddStoreContext.Provider
              value={{
                data,
                setData,
              }}
            >
              {createElement(activeTab.component)}
            </AddStoreContext.Provider>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                disabled={isFirstTab}
                onClick={goPrev}
              >
                <Icon icon="arrow-left" /> <Text>global.buttons.back</Text>
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={hasNotMainPhoto}
                loading={loading}
              >
                {isLastTab ? (
                  <Fragment>
                    <Icon icon="check" /> <Text>global.buttons.submit</Text>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Text>global.buttons.next</Text> <Icon icon="arrow-right" />
                  </Fragment>
                )}
              </Button>
            </div>
          </Wrapper.Body>
        </Wrapper>
      </div>
    </div>
  );
}
