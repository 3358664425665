import { ServiceApi } from "services";
import { useEffect, useState } from "react";
import {
  AddressViewer,
  Breadcrumb,
  Pagination,
  Skeleton,
  Status,
  Table,
} from "components";
import STORE from "services/urls/store";
import { Button, SearchBox, Text } from "components";
import { Link, useSearchParams } from "react-router-dom";
import { config, appPermissions } from "constant";
import { usePermission } from "hooks";

const StoreList = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  //permissions
  const { AS_CreateStore, AS_GetStores, AS_GetStoreDetail } = appPermissions;
  const CREATE_PERMISSIONS = usePermission(AS_CreateStore);
  const GET_LIST_PERMISSIONS = usePermission(AS_GetStores);
  const DETAILS_PERMISSIONS = usePermission(AS_GetStoreDetail);

  const handleSetParams = (key) => {
    return (value) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = STORE.ADD_STORE_URL;
    const config = { params };
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getData, [params]);
  return GET_LIST_PERMISSIONS ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <Breadcrumb />
        </div>
        {CREATE_PERMISSIONS && (
          <Button as={Link} to="new">
            <Text>company.stores.addStore</Text>
          </Button>
        )}
      </div>
      <div className="flex items-center gap-4">
        <SearchBox
          value={params?.get("keyword") || ""}
          onSubmit={handleSetParams("keyword")}
          disabled={loading}
          totalItems={data.totalItems}
        />
      </div>
      {[1, 2, 3, 4, 5]
        .filter(() => loading)
        .map((e) => (
          <Skeleton.ImageList key={e} />
        ))}
      <Table>
        {data.items?.map((e) => (
          <tr key={e.id}>
            <td>
              <Link
                to={DETAILS_PERMISSIONS ? e.id : ""}
                className="w-fit inline-flex flex-center gap-2"
              >
                <Table.Logo src={e.mainPhoto} alt={e.title} />
                <div className="space-y-4">
                  <h6 className="text-heading-6">{e.title}</h6>
                  <AddressViewer address={e.address} />
                </div>
              </Link>
            </td>
            <td className="text-heading-5">{e.code}</td>
            <td>
              <Status.Store id={e.isActive} />
            </td>
            <td>
              {DETAILS_PERMISSIONS && (
                <Button as={Link} to={e.id} variant="light" size="sm">
                  <Text>global.buttons.view</Text>
                </Button>
              )}
            </td>
          </tr>
        ))}
      </Table>
      <Pagination
        totalItems={data.totalItems}
        totalPages={data.totalPages}
        pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber")) : 1}
        pageSize={parseInt(params?.get("pageSize"))}
        setActivePage={handleSetParams("pageNumber")}
        onPageSizeChange={(e) => setParams((p) => ({ ...p, pageSize: e }))}
      />
    </div>
  ) : null;
};

export default StoreList;
