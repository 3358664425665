import { NotificationManager } from "common/notifications";
import {
  Button,
  Form,
  InputGroup,
  Select,
  Text,
  Toggler
} from "components";
import { rules } from "constant";
import { useDataState } from "hooks";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";


type detailsType = {
  programId: string;
  titleItems: any;
  selected: any;
  setShowButtonCreate: any;
  setIsOpenUpdate: any;
  getData: () => void;
  getDataProgram: () => void;
};

export default function Details(props: detailsType) {
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState("");
  const [showTextInput, setShowTextInput] = useState<boolean>(false);
  const formRef = useRef<any>();
  const [data, setData, setBaseData, isChanged] = useDataState({
    title: "",
    amount: "",
    order: "",
    isActive: true,
  });


  useEffect(() => {
    if (props.selected.title !== "supplierDiscount" && props.selected.title !== "programDiscount") {
      setShowTextInput(true)
      setSelect("customText")
    } else {
      setSelect(props.selected.title)
    }
    setBaseData(props.selected)
  }, [])


  const onSubmitForm = (formData: any) => {
    setLoading(true);
    const url = URLS.UPDATE_DISCOUNT_PROGRAMS_SUPPLIERS(props.programId, formData.id);
    ServiceApi.put(url, formData).then(({ }) => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
      props.setIsOpenUpdate(false);
      props.setShowButtonCreate(true)
      props.getData();
      props.getDataProgram();
    }).finally(() => {
      setLoading(false);
    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  return (
    <div className="rounded" style={{ boxShadow: "0px 4px 20px rgba(56, 71, 109, 0.09)", marginBottom: "16px" }}>
      <Form ref={formRef} onSubmit={onSubmitForm} id={"update-discount"}>
        <div className="grid grid-cols-1 lg:grid-cols-3 p-4">
          <div className="col-start-1 col-end-4 mb-8">
            <Select
              label="productManagement.masterData.suppliers.title"
              setValue={(title) => {
                if (title === "customText") {
                  setSelect(title)
                  setShowTextInput(true)
                  setData((p) => ({ ...p, title: "" }))
                } else {
                  setShowTextInput(false)
                  setSelect(title)
                  setData((p) => ({ ...p, title }))
                }
              }}
              items={props.titleItems}
              value={select}
              rules={rules.required}
            />
          </div>
          {showTextInput ? <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              label="productManagement.masterData.suppliers.customTitle"
              value={data.title}
              setValue={(title) => setData((p) => ({ ...p, title }))}
            />
          </div>
            : ""}
          <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              label="productManagement.masterData.suppliers.amount"
              value={data.amount}
              setValue={(amount) => {
                if (amount >= 0 && amount <= 100)
                  setData((p) => ({ ...p, amount }))
              }}
              rules={rules.discount}
              keyfilter="pnum"
              append={<h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">%</h5>}
            />
          </div>
          <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              type="number"
              label="productManagement.masterData.suppliers.sortOrder"
              value={data.order}
              setValue={(order) => setData((p) => ({ ...p, order }))}
              rules={rules.required}
              keyfilter="onlyNum"
            />
          </div>
          <div className="col-start-1 col-end-1 mb-2">
            <Toggler
              label={
                data.isActive
                  ? "productManagement.masterData.suppliers.active"
                  : "productManagement.masterData.suppliers.deActive"
              }
              value={data.isActive}
              setValue={(isActive) => setData((p) => ({ ...p, isActive }))}
            />
          </div>
        </div>
        <div className="flex items-center justify-between p-4">
          <Button className="mr-2" onClick={() => {
            props.setIsOpenUpdate(false)
            props.setShowButtonCreate(true)
          }} variant="white">
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loading} disabled={!isChanged}>
            <Text>global.buttons.submit</Text>
          </Button>
        </div>
      </Form>
    </div>
  );
}