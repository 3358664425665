import {
  Button,
  Form,
  Icon,
  InputGroup,
  Drawer,
  Text,
  Image,
  ImageUploader,
} from "components";
import { rules } from "constant";
import { useContext, useEffect, useState } from "react";
import { Media, toggleProps, channelProductType, mediaListType } from "types";
import { InfoPageContext } from "../../..";
import { LayoutContext } from "..";
import { productType } from "../../../../type";
import { ServiceApi } from "services";
import { cloneDeep } from "lodash";
import { arrayToMatrix } from "utils";
import { useToggleState } from "hooks";
type productDataProps = toggleProps & {
  channelProduct: channelProductType | null;
  initData?: {
    productId?: null | string;
    tag: string;
    price: string;
    discountPercent: string;
    url: string;
    articleNumber: string;
  };
};
export default function ProductData({
  isOpen,
  toggle,
  channelProduct,
  initData = {
    productId: null,
    tag: "",
    price: "",
    discountPercent: "",
    url: "",
    articleNumber: "",
  },
}: productDataProps) {
  const isNew = !initData.productId;
  const { infoPageData, setInfoPageData } = useContext(InfoPageContext);
  const { layoutData } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initData);
  const [isImageUploaderOpen, toggleImageUploader] = useToggleState(false);
  const [imageCovers, setImageCovers] = useState<mediaListType[]>([]);
  const mediaList = [...imageCovers, ...(channelProduct?.mediaList ?? [])];
  const handleSetBaseData = () => {
    if (!isOpen || !isNew || !channelProduct) return;
    const { sku, mediaList, price } = channelProduct;
    setData((p) => ({
      ...p,
      tag: sku,
      url: mediaList?.at(0)?.url ?? "",
      price: String(price.amount),
    }));
  };
  const handleUploadImage = (media: Media) => {
    if (isNew) {
      setImageCovers((p) => [
        {
          createdAt: new Date(),
          thumbnailUrl: media.thumbnailUrl,
          type: "",
          url: media.url,
        },
        ...p,
      ]);
    }
    setData((p) => ({ ...p, url: media.url }));
  };
  const handleToggle = () => {
    isNew && setData(initData);
    toggle();
  };
  const addProduct = () => {
    setLoading(true);
    const url = `/productservice/api/infopages/${infoPageData.id}/layout/${layoutData.id}`;
    const hasDiscount = Boolean(Number(data.discountPercent));
    const currentPercent = 100 - Number(data.discountPercent);
    const discountAmount = hasDiscount
      ? (Number(data.price) * currentPercent) / 100
      : "";
    const discountPercent = hasDiscount ? Number(data.discountPercent) : "";
    const product: productType = {
      articleNumber: "",
      discountAmount: String(discountAmount),
      discountPercent: String(discountPercent),
      price: { currency: "euro", amount: +data.price, currencySymbol: "€" },
      productId: "",
      tag: data.tag,
      title: null,
      url: data.url,
    };
    product.articleNumber = isNew
      ? channelProduct?.articleNumber ?? ""
      : data.articleNumber;
    product.productId = isNew ? channelProduct?.id ?? "" : data.productId ?? "";
    const body = cloneDeep(layoutData);
    if (isNew) {
      body.products.push(product);
    } else {
      const index = body.products.findIndex(
        (e) => e.productId === initData.productId
      );
      body.products[index] = product;
    }
    ServiceApi.put(url, body)
      .then(() => {
        setInfoPageData((p) => {
          const data = cloneDeep(p);
          const index = data.infoPageLayouts.findIndex((e) => e.id === body.id);
          data.infoPageLayouts[index] = body;
          return data;
        });
        handleToggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(handleSetBaseData, [channelProduct, isOpen, isNew]);
  return (
    <Drawer isOpen={isOpen} toggle={handleToggle} className="!z-40">
      <Form onSubmit={addProduct}>
        <Drawer.Menu>
          <Drawer.Header>
            <h2 className="text-gray-800 text-start">
              <Text>
                {isNew
                  ? "applications.infoPage.productAddTitle"
                  : "applications.infoPage.productEditTitle"}
              </Text>
            </h2>
          </Drawer.Header>
          <Drawer.Body className="space-y-8">
            {!isNew && (
              <Image
                className="w-full aspect-image"
                src={data.url}
                alt={data.tag}
                editor
                onClick={toggleImageUploader}
              />
            )}
            <InputGroup
              label="applications.infoPage.productLabel"
              value={data.tag}
              setValue={(tag) => setData((p) => ({ ...p, tag }))}
              rules={rules.required}
            />
            <InputGroup
              label="applications.infoPage.productPrice"
              value={data.price}
              setValue={(price) => setData((p) => ({ ...p, price }))}
              rules={rules.required}
              type="price"
            />
            <InputGroup
              label="applications.infoPage.productDiscount"
              value={data.discountPercent}
              setValue={(discountPercent) => {
                if (discountPercent >= 0 && discountPercent <= 100)
                  setData((p) => ({ ...p, discountPercent }))
              }}
              rules={rules.discount}
              append={<span className="input-group-text">%</span>}
              keyfilter="pnum"
            />
            <ImageUploader
              isOpen={isImageUploaderOpen}
              toggle={toggleImageUploader}
              onUpload={handleUploadImage}
            />
            {isNew && (
              <fieldset>
                <legend className="h5">
                  <Text>applications.infoPage.productSelectImage</Text>
                </legend>
                <div className="grid grid-cols-3 gap-4 mt-4">
                  <button
                    type="button"
                    className="h4 block col-span-full bg-primary-light border border-dashed border-primary w-full rounded p-3"
                    onClick={toggleImageUploader}
                  >
                    <Icon icon="plus" className="text-primary mb-2" />
                    <span className="block text-gray-800 text-center truncate">
                      <Text>applications.infoPage.productAddImageButton</Text>
                    </span>
                  </button>
                  {arrayToMatrix(mediaList, mediaList.length / 3).map(
                    (arr, i) => (
                      <div
                        key={i}
                        className="flex flex-col gap-4 [&:nth-child(odd)>*:nth-child(odd)]:aspect-image [&:nth-child(odd)>*:nth-child(even)]:aspect-vertical-image [&:nth-child(even)>*:nth-child(odd)]:aspect-vertical-image [&:nth-child(even)>*:nth-child(even)]:aspect-image"
                      >
                        {arr.map((e) => (
                          <button
                            key={e.thumbnailUrl}
                            type="button"
                            data-active={e.url === data.url}
                            className="w-full rounded overflow-hidden border-2 border-gray-300 data-active:border-primary transition-colors"
                            onClick={() =>
                              setData((p) => ({ ...p, url: e.url }))
                            }
                          >
                            <Image
                              src={e.thumbnailUrl}
                              alt={`${e.createdAt}`}
                              className="w-full h-full"
                            />
                          </button>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </fieldset>
            )}
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button
              type="button"
              variant="white"
              size="sm"
              onClick={handleToggle}
            >
              <Icon icon="close" />{" "}
              <Text>applications.infoPage.productCancelButton</Text>
            </Button>
            <Button type="submit" variant="primary" size="sm" loading={loading}>
              <Text>applications.infoPage.productSubmitButton</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
