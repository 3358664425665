import SellOffSales from "./sell-off-sales";
import Settings from "./settings";
import Report from "./report";
import KitchenSales from "./kitchen-sales";
import SalesArea from "./sales-area";
const salesManagment = [
  {
    path: "salesmanagment/sell-off-sales",
    children: [...SellOffSales],
  },
  {
    path: "salesmanagment/settings",
    children: [...Settings],
  },
  {
    path: "salesmanagment/reports",
    children: [...Report],
  },
  {
    path: "salesmanagment/kitchen-sales",
    children: [...KitchenSales],
  },
  {
    path: "salesmanagment/sales-area",
    children: [...SalesArea],
  },
];

export default salesManagment;
