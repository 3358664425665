import { classNames } from "utils";

export const createClassNames = (isActive: boolean) => {
  const main = classNames("w-full min-h-screen transition-[padding-left]", {
    "pl-[75px]": !isActive,
    "pl-64": isActive,
  });
  return {
    main,
  };
};
