import { infoPage } from "./info-page";
import { positions } from "./positions";

export const applications = {
  positions,
  infoPage,
  container: {
    infoPage: {
      header: "Choose info page",
      link: "Link",
      closeButton: "CLose",
    },
    product: {
      header: "Choose Product",
      link: "Link",
      closeButton: "CLose",
    },
  },
};
