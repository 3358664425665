
import { Fragment, useContext } from "react";
import { ServiceApi, URLS } from "services";
import { Table, Toggler, Skeleton, Text, NoItems } from "components";
import { UserContext } from "..";
import { NotificationManager } from "common/notifications";


export default function AuthenticationSetting() {
  const { userData, loading, getData } = useContext<any>(UserContext);


  const updateProviders = (item: any) => {
    const url = URLS.UPDATE_PROVIDERS_USER_URL(userData.userId);
    const body = {
      providers: [{
        authenticationProviderId: item.id,
        isActive: item.isActive
      }]
    }
    ServiceApi.patch(url, body).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
    }).finally(() => {
      getData();
    })

  }



  return (
    <Fragment>
      {loading ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {userData?.authenticationProviders?.length !== 0 ?
            <>
              <Table>
                {userData?.authenticationProviders?.map((item: any) => {
                  return (
                    <tr>
                      <td>
                        <div className="space-y-2">
                          <div className="flex gap-4">
                            <h6 className="text-heading-6 text-gray-800 font-semibold"><Text>configurations.userManagment.userList.providerName</Text>:</h6>
                            <span className="text-heading-6 text-gray-800 font-normal">
                              {item.signInType === 'emailaddress' ? (
                                <Text>configurations.userManagment.userList.local</Text>
                              ) : (
                                <Text>configurations.userManagment.userList.add</Text>
                              )}
                            </span>
                          </div>
                          <div className="flex gap-4">
                            <h6 className="text-heading-6 text-gray-800 font-semibold"><Text>configurations.userManagment.userList.userName</Text>:</h6>
                            <span className="text-heading-6 text-gray-800 font-normal">{userData?.username}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Toggler
                          label={"configurations.userManagment.userList.activeLogin"}
                          value={item.isActive}
                          size="sm"
                          setValue={(value) => {
                            item.isActive = value;
                            updateProviders(item)
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </Table>
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
    </Fragment>
  );
}
