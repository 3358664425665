import { NotificationManager } from "common/notifications";
import { Button, Icon, Modal, SearchBox, Skeleton, Text } from "components";
import { useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";

type AssignPlaceType = {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
  ids: any;
  id: string;
};

type LocationsItems = {
  placeId: string;
  code: string;
  displayName: string;
  storeTitle: string;
  space: number;
};

export default function AssignPlace(props: AssignPlaceType) {
  const [data, setData] = useState<any>([]);
  const [selectedIndex, setIndex] = useState<number>();
  const [loading, setLoading] = useToggleState();
  const [loadingList, setLoadingList] = useToggleState();

  useEffect(() => {
    setData([]);
    setIndex(undefined);
  }, [props.isOpen]);

  const searchLocations = (code: string) => {
    if (code) {
      setLoadingList();
      const url = URLS.GET_LOCATIONS_URL;
      const config = { pageNumber: 1, pageSize: 100, keyword: code };
      ServiceApi.get(url, { params: config })
        .then(({ data }) => {
          setData(data.items);
          setLoadingList();
        })
        .catch(() => setLoadingList());
    } else {
      setData([]);
      setIndex(undefined);
    }
  };

  const onSubmit = () => {
    const clone = [...props.ids];
    const location = data[selectedIndex as number];
    if (!clone.includes(location.placeId)) {
      setLoading();
      clone.push(location.placeId);
      const url = URLS.ADD_PLACES(props.id);
      const body = { placeIds: clone };
      ServiceApi.put(url, body)
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title"
          );
          setLoading();
          props.onConfirm();
          props.toggle();
        })
        .catch(() => setLoading());
    }
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Modal.Header>
        <Text>productManagement.products.Details.assignPlace</Text>
      </Modal.Header>
      <Modal.Body className="overflow-auto min-h-[300px] space-y-4">
        <SearchBox
          variant="gray"
          onSubmit={(e) => searchLocations(e as string)}
          disabled={loadingList}
          className="[&>*]:w-full"
          formClassName={"w-full"}
        />

        <div className="space-y-6">
          {loadingList && [1, 2, 3].map((key) => <Skeleton.Input key={key} />)}
          {data?.map((item: LocationsItems, index: number) => {
            return (
              <div
                onClick={() => setIndex(index)}
                className={`${
                  index === selectedIndex && "bg-primary-light"
                } shadow-high p-4 rounded-xl flex justify-between items-center cursor-pointer hover:bg-primary-light`}
              >
                <div className="space-y-1">
                  <h6 className="text-heading-6 text-gray-800 font-semibold">
                    {item.code}
                  </h6>
                  <div>
                    <span className="text-body-base text-gray-500 font-normal">
                      {item.displayName}
                    </span>
                  </div>
                </div>
                <div className="text-body-base text-gray-800">
                  {item.storeTitle}
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button size="sm" onClick={props.toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.close</Text>
        </Button>
        <Button
          type="button"
          className=""
          size="sm"
          onClick={onSubmit}
          disabled={selectedIndex === undefined}
          loading={loading}
        >
          <Text>global.buttons.submit</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
