import SellOffProducts from "pages/sales-managment/sell-off-sales/sell-off-products";
import SellOffProductDetails from "pages/sales-managment/sell-off-sales/sell-off-products/Details";

const SellOffProductsRoutes = [
  {
    path: "",
    element: <SellOffProducts />,
  },
  {
    path: ":store/:productId",
    element: <SellOffProductDetails />,
  },
];

export default SellOffProductsRoutes;
