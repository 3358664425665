import {
  Button,
  Icon,
  InputGroup,
  Modal,
  Pagination,
  Skeleton,
  Table,
  Text,
} from "components";
import { rules } from "constant";
import { useConverter, useToggleState } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { ServiceApi } from "services";
import { channelProductType } from "types";
type productType = {
  productId: string;
  price: {
    currency: "EUR";
    amount: number;
    currencySymbol: "€";
  };
  articleNumber: string;
  url: string;
  title: string;
  discountAmount: string;
  discountPercent: string;
  tag: string;
};
type selectProductProps = {
  value: any[];
  setValue: (product: productType) => void;
};

type dataType = {
  items: channelProductType[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};
export default function SelectProduct({ value, setValue }: selectProductProps) {
  const { convertAmount } = useConverter();
  const [isOpen, toggle] = useToggleState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} as dataType);
  const [params, setParams] = useState({
    pageSize: 50,
    pageNumber: 1,
    channelCode: "digitalstorefront",
    cache: true,
  });
  const handleSetValue = (product: channelProductType) => {
    return () => {
      const value = {
        price: product.price,
        title: product.sku,
        discountAmount: "",
        discountPercent: "",
        tag: product.sku,
        key: product.id ?? "",
        productId: product.pimProductId ?? "",
        articleNumber: product.articleNumber ?? "",
        thumbnailUrl: product.mediaList?.at(0)?.thumbnailUrl ?? "",
        url: product.mediaList?.at(0)?.url ?? "",
      };
      setValue(value);
      toggle();
    };
  };
  const getProducts = () => {
    if (!isOpen) return;
    setLoading(true);
    const url =
      "/productservice/api/channelproducts/get-channelproduct-by-channelcode";
    const config = { params };
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getProducts, [isOpen, params]);
  return (
    <Fragment>
      <InputGroup
        label="applications.container.product.link"
        value={value?.at(0)?.title ?? ""}
        rules={rules.required}
        onClick={toggle}
        readOnly
      />
      <Modal isOpen={isOpen} toggle={toggle} modalClassName="!z-50" size="lg">
        <Modal.Header className="flex items-center justify-between">
          <h5>
            <Text>applications.container.product.header</Text>
          </h5>
          <Button
            type="button"
            variant="white"
            className="!text-danger"
            onClick={toggle}
          >
            <Icon icon="close" />{" "}
            <Text>applications.container.product.closeButton</Text>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="space-y-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </div>
          ) : (
            <Table>
              {data.items?.map((e) => (
                <tr
                  key={e.id}
                  onClick={handleSetValue(e)}
                  className="cursor-pointer"
                >
                  <td>
                    <div className="flex items-center gap-4">
                      <Table.Image
                        src={e.mediaList?.at(0)?.thumbnailUrl}
                        alt={e.sku}
                      />
                      <div className="flex-1">
                        <h5 className="block w-full max-w-md truncate text-gray-800">
                          {e.sku ?? "--"}
                        </h5>
                        <p className="block w-full max-w-md truncate text-body-1 text-gray-600">
                          {e.articleNumber}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>{convertAmount(e.price?.amount)}</td>
                </tr>
              ))}
            </Table>
          )}
          <Pagination
            pageNumber={params.pageNumber}
            pageSize={params.pageSize}
            totalItems={data.totalItems}
            totalPages={data.totalPages}
            setActivePage={(value) =>
              setParams((p) => ({ ...p, pageNumber: +value }))
            }
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
