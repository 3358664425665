import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useMountEffect, useUpdateEffect } from "hooks";
import { classNames } from "utils";
import { PanelMenuSub } from "./PanelMenuSub";
import { Icon } from "components";
import { Text } from "components";
import "./PanelMenu.scss";
import Menu from "./Menu";
import { useLocation } from "react-router";

type PanelMenuProps = {
  isActive: boolean;
  setIsActive: CallableFunction;
  model: any;
  multiple?: boolean;
};

export const PanelMenu = React.memo(
  React.forwardRef(
    (
      { isActive, setIsActive, multiple = true, ...props }: PanelMenuProps,
      ref
    ) => {
      const location = useLocation();
      const [selectedMenu, setSelectedMenu] = React.useState("");
      const [activeItemState, setActiveItemState] = React.useState(null);
      const [model, setModel] = useState(props.model);

      const findActiveItem = () => {
        if (props.model) {
          if (multiple) {
            return props.model.filter((item: any) => item.expanded);
          }
        }

        return null;
      };

      const onItemClick = (event: any, item: any) => {
        if (item.disabled) {
          event.preventDefault();

          return;
        }

        if (!item.url) {
          event.preventDefault();
        }

        if (item.command) {
          setSelectedMenu(item.label);
          item.command({
            originalEvent: event,
            item: item,
          });
        }

        if (!isActive) {
          setIsActive(true);
        }
        let activeItem: any = activeItemState;
        let active: any = isItemActive(item);
        if (active) {
          item.expanded = false;
          let clone = model.map((_item: any) => {
            if (_item.label === item.label) {
              return {
                ...item,
                expanded: false,
              };
            }
            return _item;
          });
          setModel(clone);
          setActiveItemState(
            multiple
              ? activeItem.filter((a_item: any) => a_item !== item)
              : null
          );
        } else {
          item.expanded = true;

          let clone = model.map((_item: any) => {
            if (_item.label === item.label) {
              return {
                ...item,
                expanded: true,
              };
            }
            return _item;
          });
          setModel(clone);
          setActiveItemState(multiple ? [...(activeItem || []), item] : item);
        }
      };

      const isItemActive = (item: any): boolean => {
        if (!isActive) {
          return false;
        }
        return item.expanded;
        // return activeItemState && activeItemState.indexOf(item) > -1;
      };

      useMountEffect(() => {
        setActiveItemState(findActiveItem());
      });

      useUpdateEffect(() => {
        setActiveItemState(findActiveItem());
      }, [props.model]);

      const createPanel = (item: any) => {
        if (item.visible === false) {
          return null;
        }

        const icon = <Icon icon={item.icon} className=" mr-2" fixedWidth />;
        const label = item.label && (
          <span className="w-11/12 menuitem-text">
            <Text>{item.label}</Text>
          </span>
        );
        let content = (
          <a
            href={item.url || "#"}
            className={`panelmenu-header-link text-body-base w-full`}
            onClick={(e) => onItemClick(e, item)}
            aria-expanded={true}
            aria-disabled={item.disabled}
          >
            <div className="flex items-center">
              {icon}
              <CSSTransition
                in={isActive}
                timeout={200}
                unmountOnExit
                classNames="alert"
              >
                {label}
              </CSSTransition>
            </div>
          </a>
        );
        return (
          <Menu key={item.label}>
            <Menu.Item
              selected={selectedMenu === item.label && isActive}
              className="mb-[12px]"
              active={location.pathname.includes(item.path)}
            >
              <Menu.Toggle
                clickable={item.items?.length > 0}
                isActive={isActive}
                selected={selectedMenu === item.label && isActive}
                className="h-[32px]"
              >
                {content}
              </Menu.Toggle>
              <Menu.Body>
                <PanelMenuSub
                  model={item.items}
                  className="panelmenu-root-submenu text-gray-500"
                  multiple={multiple}
                  isActive={isActive}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                />
              </Menu.Body>
              {/* )} */}
            </Menu.Item>
          </Menu>
        );
      };

      const createPanels = () => {
        return model ? model.map(createPanel) : null;
      };

      const className = classNames("panelmenu py-4", {
        "px-4": !isActive,
        "px-2": isActive,
      });
      const panels = createPanels();

      return <div className={className}>{panels}</div>;
    }
  )
);

PanelMenu.displayName = "PanelMenu";
