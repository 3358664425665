import { useState } from "react";
import { Outlet } from "react-router";
import { createClassNames } from "./classname";
import { Header } from "..";
import Sidebar from "../sidebar/sidebar";
import { useMountEffect, useUpdateEffect, useSelector } from "hooks";
import { ServiceApi } from "services";
import { useDispatch } from "react-redux";
import { setProfile } from "store/profile";
import { setPermissions } from "store/permissions";
import { URLS } from "services";
import { useTranslation } from "react-i18next";
import { Loading } from "components";
import { DomHandler } from "utils";

const MainLayout = () => {
  const { i18n } = useTranslation();

  const profile = useSelector((s) => s.profile);
  // const loading = useSelector((s) => s.loading.loading);

  const dispatch = useDispatch();

  const documentWidth = DomHandler.getClientWidth(document.body);
  const [isActive, setIsActive] = useState<boolean>(
    documentWidth > 768 ? true : false
  );
  const [loading, setLoading] = useState<boolean>(true);

  const { main } = createClassNames(isActive);

  useMountEffect(() => {
    const getProfile = async () => {
      setLoading(true);
      const result = await ServiceApi.get(URLS.B2B_USER_URL);
      dispatch(setProfile(result.data));
      const permissions = await ServiceApi.get(URLS.GET_USER_PERMISSIONS_URL);
      dispatch(setPermissions(permissions.data.permissions));
      setLoading(false);
    };
    getProfile();
  });

  useUpdateEffect(() => {
    if (profile) {
      i18n.changeLanguage(profile.companyDefaultLanguage);
    }
  }, [profile.companyDefaultLanguage]);

  return (
    <>
      <Sidebar isActive={isActive} setIsActive={setIsActive} />
      <main className={main}>
        <div className="min-h-screen pb-20 text-">
          <Header />
          <div className="container px-8 pt-6">
            <Outlet />
          </div>
        </div>
      </main>
      {loading && <Loading />}
    </>
  );
};

export default MainLayout;
