const SUB_ITEMS = {
  UPDATE_SUB_ITEMS: (subItemId, productId) =>
    `productservice/api/selloffproducts/${productId}/sub-item/${subItemId}`,
  UPDATE_SUB_ITEM_PRICE: (subItemId, productId) =>
    `productservice/api/selloffproducts/${productId}/sub-item-price/${subItemId}`,
  ADD_SUB_ITEMS: (productId) =>
    `productservice/api/selloffproducts/${productId}/sub-item`,
  DOWNLOAD_SELL_OFF_PRODUCT:
    "/productservice/api/selloffproducts/download-sell-off-product",
};

export default SUB_ITEMS;
