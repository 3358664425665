import { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup, Text, Icon, Skeleton } from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import { useDataState } from "hooks";
import { rules } from "constant";
import { NotificationManager } from "common/notifications";

type detailsModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: () => void;
  selected?: string
};

export default function DetailsModal({ isOpen, toggle, getData, selected }: detailsModalType) {
  const [data, setData] = useState({
    label: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);


  useEffect(() => {
    if (isOpen) {
      getDataDetails()
    }
  }, [isOpen])// eslint-disable-line react-hooks/exhaustive-deps

  const getDataDetails = () => {
    setLoading(true);
    const url = URLS.GET_CHECK_LIST_DETAILS_URL_(selected);
    ServiceApi.get(url)
      .then(({ data }) => {
        setData(data);
      }).finally(() => {
        setLoading(false);
      });
  };

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.UPDATE_CHECK_LIST_URL(selected);
    ServiceApi.put(url, data).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
      getData();
    }).finally(() => {
      toggle();
      setLoadingButton(false);

    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Form onSubmit={submit}>
        <Modal.Header className="flex flex-row">
          <h6 className="text-heading-2 text-gray-800">
            <Text>salesManagment.sellOffSales.salesChecklist.detailCheckList</Text>
          </h6>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            {loading ? (
              <>
                <div className="mb-6">
                  <h6 className="font-normal text-gray-800 text">
                    <Text>salesManagment.sellOffSales.salesChecklist.title</Text>
                  </h6>
                </div>
                <Skeleton.Input />
              </>
            ) : (
              <InputGroup
                label="salesManagment.sellOffSales.salesChecklist.title"
                value={data.label}
                className="border-white"
                setValue={(label) => setData((p) => ({ ...p, label }))}
                rules={rules.required}
              />
            )}
            {loading ? (
              <>
                <div className="mb-6">
                  <h6 className="font-normal text-gray-800 text">
                    <Text>salesManagment.sellOffSales.salesChecklist.description</Text>
                  </h6>
                </div>
                <Skeleton.TextArea />
              </>
            ) : (
              <InputGroup
                as="short-textarea"
                label="salesManagment.sellOffSales.salesChecklist.description"
                value={data.description}
                className="border-white"
                setValue={(description) => setData((p) => ({ ...p, description }))}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="submit" loading={loadingButton}>
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
