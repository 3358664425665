import {
  InputGroup,
  Select,
  Skeleton,
  Text,
  Wrapper
} from "components";
import { config } from "constant";
import {
  Fragment,
  useContext,
  useEffect,
  useState
} from "react";
import { ServiceApi, URLS } from "services";
import { AttributesTypes } from "utils";
import { AttributesContext } from "..";

export default function AttributesTypeMeasurement() {

  const { attributesData, setAttributesData, loading } = useContext<any>(AttributesContext);
  const [items, setItems] = useState([])
  const [itemsUnits, setItemsUnits] = useState([])
  const [params] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  useEffect(() => {
    const url = URLS.GET_MEASUREMENT_FAMILY_URL;
    const config = { params };
    let arr: any = [];
    ServiceApi.get(url, config).then(({ data }) => {
      let measurementId;
      data?.items?.forEach((item: any) => {
        if (item?.code === attributesData?.measurementFamilyCode) {
          measurementId = item.measurementFamilyId
        }
        item?.translations?.forEach((i: any, index: number) => {
          if (index === 0) {
            arr.push({ id: item.code, name: i.name, code: item.measurementFamilyId })
          }
        })
      })
      getDataUnits(measurementId)
      setItems(arr);
    })
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  const getDataUnits = (id: any) => {
    if (id) {
      const urlUnits = URLS.GET_DETAIL_MEASUREMENT_FAMILY_URL(id);
      let arrUnits: any = []
      ServiceApi.get(urlUnits).then(({ data }) => {
        data?.measurementFamilyUnits?.forEach((item: any) => {
          item?.measurementFamilyUnitTranslation?.forEach((i: any, index: number) => {
            if (index === 0) {
              arrUnits.push({ id: item.code, name: i.name })
            }
          })
        })
        setItemsUnits(arrUnits);
      })
    }
  }


  const handleSetData = (key: any) => {
    return (value: any) => setAttributesData((p: any) => ({ ...p, [key]: value }));
  };


  return (
    <Fragment>
      <div className="flex">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) :
          <Wrapper>
            <Wrapper.Header>
              <h2 className="text-gray-800 font-semibold" ><Text>productManagement.masterData.attributes.setting</Text></h2>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.attributeType"
                  value={AttributesTypes(attributesData?.type)}
                  disabled
                />
                <Select
                  label="productManagement.masterData.attributes.measurementTypeList"
                  items={items}
                  value={attributesData?.measurementFamilyCode}
                  setValue={(e) => {
                    items.forEach((item: any) => {
                      if (item.id === e) {
                        getDataUnits(item.code)
                      }
                    })
                    setAttributesData((p: any) => ({ ...p, measurementFamilyCode: e }));
                  }}
                />
                <Select
                  label="productManagement.masterData.attributes.unitsList"
                  items={itemsUnits}
                  value={attributesData?.defaultValue}
                  setValue={handleSetData("defaultValue")}
                />

              </div>
            </Wrapper.Body>
          </Wrapper>
        }
      </div>
    </Fragment>
  );
}
