import SalesList from "./sales-list";
import SalesReport from "pages/sales-managment/kitchen-sales/sales-report";
import Configurations from "pages/sales-managment/kitchen-sales/configurations";

const KitchenSales = [
  {
    path: "sales-list",
    children: [...SalesList],
  },
  {
    path: "sales-report",
    element: <SalesReport />,
  },
  {
    path: "configurations",
    element: <Configurations />,
  },
];

export default KitchenSales;
