import { NotificationManager } from "common/notifications";
import {
  Button,
  Drawer,
  Icon,
  Select,
  InputGroup,
  Skeleton,
  Text,
  Form,
  SelectSearchable
} from "components";
import { useToggleState } from "hooks";
import { FormEvent, useEffect, useState, useRef } from "react";
import { ServiceApi, URLS } from "services";
import DeleteModal from "./delet-modal";
import { rules } from "constant";

type drawerProps = {
  isOpen: boolean;
  isEdit: boolean;
  toggle: () => void;
  getData: () => void;
  data: any;
  productId: string;
};

const EditSubProductDrawer = ({
  isOpen,
  data,
  toggle,
  productId,
  getData,
  isEdit,
}: drawerProps) => {
  const [subProductData, setSubProductData] = useState<any>();
  const [stores, setStores] = useState<any>();
  const formRef = useRef<any>();
  const [selectedOptionsProductFamily, setSelectedOptionsProductFamily] = useState<any>();
  const [selectedOptionsBrand, setSelectedOptionsBrand] = useState<any>();
  const [selectedOptionsSuppliers, setSelectedOptionsSuppliers] = useState<any>();
  const [loading, setLoading] = useToggleState();
  const [submitLoading, setSubmitLoading] = useToggleState();
  const [confirmDelet, setConfirmDelete] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();

  useEffect(() => {
    if (isOpen) {
      setLoading();
      ServiceApi.get(URLS.GET_SELL_OFF_STORES).then(({ data }) => {
        const stores = data.items.map((store: any) => ({
          id: store.id,
          name: store.title,
        }));
        setStores(stores);
      });

      if (data?.brandId) {
        const urlBrandsDetails = URLS.GET_BRAND_DETAILS_URL(data?.brandId);
        ServiceApi.get(urlBrandsDetails).then(({ data }) => {
          setSelectedOptionsBrand({ ...data, label: data.name, value: data.id });
        });
      } else {
        setSelectedOptionsBrand(null);
      }

      if (data?.supplierId) {
        const urlSupplersDetails = URLS.GET_SUPPLIER_DETAILS(data?.supplierId);
        ServiceApi.get(urlSupplersDetails).then(({ data }) => {
          setSelectedOptionsSuppliers({ ...data, label: data.name + " - " + data.code, value: data.id });
        });
      } else {
        setSelectedOptionsSuppliers(null);
      }
      if (data?.productFamilyId) {
        const urlProductFamiliesDetails = URLS.GET_SELL_OFF_PRODUCT_FAMILIES_Details(data?.productFamilyId);
        ServiceApi.get(urlProductFamiliesDetails).then(({ data }) => {
          setSelectedOptionsProductFamily({ ...data, label: data.translations[0]?.name, value: data.productFamilyId });
          setLoading();
        });
      } else {
        setSelectedOptionsProductFamily(null);
        setLoading();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setSubProductData({ ...data, productId: productId });
  }, [data]);

  const onSubmit = (formData: any) => {
    setSubmitLoading();
    const url = formRef?.current?.isEdit
      ? URLS.PUT_SUB_PRODUCT_URL(formData?.productId, formData?.id)
      : URLS.ADD_SUB_PRODUCT_URL(formData?.productId);

    const editMessage = ["global.toast.edit-msg", "global.toast.edit-title"];
    const addMessage = ["global.toast.add-msg", "global.toast.add-title"];

    const message = formRef?.current?.isEdit ? editMessage : addMessage;

    ServiceApi.patch(url, formData)
      .then(async () => {
        try {
          if (formRef?.current?.isEdit) {
            // const provisionUrl = URLS.UPDATE_PROVISION(data?.sellOffProductId);
            const priceUrl = URLS.SELL_OFF_SUB_PRODUCTS_PRICE_URL(
              formData?.productId,
              formData?.id
            );
            // await ServiceApi.patch(provisionUrl, {
            //   fastSalesProvisionAmount: +subProductData?.fastSalesProvisionAmount,
            // });
            await ServiceApi.patch(priceUrl, {
              originalPrice: +formData?.originalPrice,
              supplierPrice: +formData?.supplierPrice,
            });
          }
        } catch (error) {
          setSubmitLoading();
        }
        NotificationManager.success(message[0], message[1]);
        getData();
        setSubmitLoading();
        toggle();
      })
      .catch(() => setSubmitLoading());
  };

  const onChangeHandler = (key: string) => {
    return (value: any) => {
      setSubProductData((p: any) => ({ ...p, [key]: value }));
    };
  };

  const onChangeHandlerProductFamily = (selected: any) => {
    if (!selected) {
      setSelectedOptionsProductFamily(null);
    } else {
      setSelectedOptionsProductFamily(selected)
    }
    setSubProductData((p: any) => ({ ...p, productFamilyId: selected?.value || null }));
  };

  const onChangeHandlerBrand = (selected: any) => {
    if (!selected) {
      setSelectedOptionsBrand(null);
    } else {
      setSelectedOptionsBrand(selected)
    }
    setSubProductData((p: any) => ({ ...p, brandId: selected?.value || null }));
  };

  const onChangeHandlerSupplier = (selected: any) => {
    if (!selected) {
      setSelectedOptionsSuppliers(null);
    } else {
      setSelectedOptionsSuppliers(selected)
    }
    setSubProductData((p: any) => ({ ...p, supplierId: selected?.value || null }));
  };

  const onDelete = (id: string) => {
    setDeleteLoading();
    const url = URLS.DELETE_SUB_PRODUCT_URL(id, subProductData?.id);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        setDeleteLoading();
        setConfirmDelete();
        toggle();
        getData();
      })
      .catch(() => setDeleteLoading());
  };



  const handleSearchProductFamilys = async (filter: string) => {
    const url = URLS.GET_SELL_OFF_PRODUCT_FAMILIES;
    const params = {
      pageNumber: 1,
      pageSize: 1000,
      keyword: filter,
    };
    return ServiceApi.get(url, { params }).then(({ data }) => {
      const prfamilies = data.items.map((pr: any) => ({
        value: pr.productFamilyId,
        label: pr.translations[0]?.name,
      }));
      return prfamilies;
    });
  };

  const promiseOptionsProductFamily = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(handleSearchProductFamilys(inputValue));
      }, 200);
    });

  const handleSearchBrands = async (filter: string) => {
    const url = URLS.GET_SELL_OFF_BRANDS;
    const params = {
      pageNumber: 1,
      pageSize: 1000,
      keyword: filter,
    };
    return ServiceApi.get(url, { params }).then(({ data }) => {
      const brands = data.items.map((brand: any) => ({
        value: brand.brandId,
        label: brand.name,
      }));
      return brands;
    });
  };

  const promiseOptionsBrand = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(handleSearchBrands(inputValue));
      }, 200);
    });

  const handleSearchSuppliers = async (filter: string) => {
    const url = URLS.GET_SUPPLIERS;
    const params = {
      pageNumber: 1,
      pageSize: 1000,
      keyword: filter,
    };
    return ServiceApi.get(url, { params }).then(({ data }) => {
      const suppliers = data.items.map((supplier: any) => ({
        value: supplier.supplierId,
        label: supplier.name + " - " + supplier.code,
        code: supplier.code,
      }));
      return suppliers;
    });
  };

  const promiseOptionsSupplier = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(handleSearchSuppliers(inputValue));
      }, 200);
    });

  const submit = (e?: FormEvent) => {
    formRef.current.isEdit = isEdit;
    formRef.current.submitHandler(e, subProductData);
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} size="md" >
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pt-6 pr-4">
            <div className="flex items-center justify-between">
              <span className="text-heading-2 font-semibold">
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.editSubProduct
                </Text>
              </span>
              <div className="flex gap-x-2">
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={() => setConfirmDelete()}
                  disabled={loading || !isEdit}
                >
                  <Icon icon="circle-trash" size="xl" className="mr-2" />
                  <Text>
                    salesManagment.sellOffSales.sellOffProduct.deleteSubProduct
                  </Text>
                </Button>
                <Button
                  size="sm"
                  variant={"light"}
                  onClick={() => {
                    toggle();
                  }}
                >
                  <Icon icon="times" className="mr-1" />
                  <Text>salesManagment.sellOffSales.sellOffProduct.close</Text>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4 pr-4">
            <Form ref={formRef} onSubmit={onSubmit} id={"editSubProduct-drawer"} className="space-y-4">
              {loading ? (
                <>
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                </>
              ) : (
                <>
                  <InputGroup
                    label="salesManagment.sellOffSales.sellOffProduct.subProductTitle"
                    value={subProductData?.title}
                    setValue={onChangeHandler("title")}
                    rules={rules.required}
                  />
                  <InputGroup
                    label="salesManagment.sellOffSales.sellOffProduct.articleNumber"
                    value={subProductData?.articleNumber}
                    setValue={onChangeHandler("articleNumber")}
                    type="number"
                    rules={rules.required}
                  />
                  <InputGroup
                    label="salesManagment.sellOffSales.sellOffProduct.count"
                    value={subProductData?.count}
                    setValue={onChangeHandler("count")}
                    type="number"
                    rules={rules.required}
                  />
                  <SelectSearchable
                    label="salesManagment.sellOffSales.sellOffProduct.productFamily"
                    value={selectedOptionsProductFamily}
                    cacheOptions
                    loadOptions={promiseOptionsProductFamily}
                    defaultOptions
                    onChange={onChangeHandlerProductFamily}
                    isClearable={true}
                    rules={rules.required}
                  />
                  <SelectSearchable
                    label="salesManagment.sellOffSales.sellOffProduct.brand"
                    value={selectedOptionsBrand}
                    cacheOptions
                    loadOptions={promiseOptionsBrand}
                    defaultOptions
                    onChange={onChangeHandlerBrand}
                    isClearable
                    rules={rules.required}
                  />
                  <SelectSearchable
                    label="salesManagment.sellOffSales.sellOffProduct.suppliers"
                    value={selectedOptionsSuppliers}
                    cacheOptions
                    loadOptions={promiseOptionsSupplier}
                    defaultOptions
                    onChange={onChangeHandlerSupplier}
                    isClearable
                    rules={rules.required}
                  />
                  <Select
                    label="salesManagment.sellOffSales.sellOffProduct.store"
                    placeholder="Select"
                    value={subProductData?.storeId}
                    setValue={onChangeHandler("storeId")}
                    items={stores}
                    rules={rules.required}
                  />
                  <InputGroup
                    label="salesManagment.sellOffSales.sellOffProduct.location"
                    value={subProductData?.location}
                    setValue={onChangeHandler("location")}
                    type="number"
                  />
                  <InputGroup
                    as={"short-textarea"}
                    label="salesManagment.sellOffSales.sellOffProduct.shortDescription"
                    value={subProductData?.description}
                    setValue={onChangeHandler("description")}
                  />
                  <InputGroup
                    label="salesManagment.sellOffSales.sellOffProduct.originalPrice"
                    value={subProductData?.originalPrice}
                    setValue={onChangeHandler("originalPrice")}
                    type="number"
                    append={
                      <h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">
                        €
                      </h5>
                    }
                    rules={rules.required}
                  />
                  <InputGroup
                    label="salesManagment.sellOffSales.sellOffProduct.supplierPrice"
                    value={subProductData?.supplierPrice}
                    setValue={onChangeHandler("supplierPrice")}
                    type="number"
                    append={
                      <h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">
                        €
                      </h5>
                    }
                    rules={rules.required}
                  />
                  {/* <InputGroup
                  label="Provision"
                  value={subProductData?.fastSalesProvisionAmount}
                  setValue={onChangeHandler("fastSalesProvisionAmount")}
                  type="number"
                  append={
                    <h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">
                      €
                    </h5>
                  }
                /> */}
                </>
              )}
            </Form>
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button type="button" variant={"light"} onClick={toggle}>
              <Text>salesManagment.sellOffSales.sellOffProduct.cancel</Text>
            </Button>
            <Button type="button" onClick={submit} loading={submitLoading}>
              <Text>salesManagment.sellOffSales.sellOffProduct.submit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <DeleteModal
        isOpen={confirmDelet}
        loading={deleteLoading}
        toggle={setConfirmDelete}
        id={productId}
        selectedName={subProductData?.title}
        onConfirm={onDelete}
      />
    </>
  );
};

export default EditSubProductDrawer;
