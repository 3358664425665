import { useContext, useEffect, useState, Fragment } from "react";
import { InputGroup, Select, Text, Wrapper, DatePicker, Skeleton } from "components";
import { rules } from "constant";
import { UserContext } from "..";
import SearchAddress from "components/SearchAddress";
import { countries } from "constant";

export default function General() {
  const { userData, setUserData, loading } = useContext<any>(UserContext);

  const handleSetData = (key: any) => {
    return (value: any) => setUserData((p: any) => ({ ...p, [key]: value }));
  };

  const handleSetAddress = (address: any) => {
    setUserData((p: any) => {
      return {
        ...p,
        street: address.street,
        houseNumber: address.houseNo,
        postalCode: address.postalCode,
        city: address.city,
        country: address.country,
        state: address.state
      }
    });
    return {};
  };



  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>configurations.userManagment.userList.basicInfo</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8" >
            {loading ? (
              <Fragment>
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
              </Fragment>
            ) : (
              <Fragment>
                <InputGroup
                  label="configurations.userManagment.userList.firstName"
                  value={userData.firstName}
                  setValue={handleSetData("firstName")}
                  rules={rules.required}
                />
                <InputGroup
                  label="configurations.userManagment.userList.lastName"
                  value={userData.lastName}
                  setValue={handleSetData("lastName")}
                  rules={rules.required}
                />
                <Select
                  label="configurations.userManagment.userList.gender"
                  items={[
                    { id: 0, name: "Rather not say" },
                    { id: 1, name: "Male" },
                    { id: 2, name: "Female" }
                  ]}
                  value={userData.gender}
                  setValue={handleSetData("gender")}
                  rules={rules.required}
                />
                <DatePicker
                  label="configurations.userManagment.userList.dateOfBirth"
                  showYearDropdown
                  showMonthDropdown
                  value={userData?.dateOfBirth !== null ? new Date(userData?.dateOfBirth) : null}
                  onChange={handleSetData("dateOfBirth")}
                  rules={rules.required}
                />
              </Fragment>
            )}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-1 mt-8" >
            {loading ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.userManagment.userList.personalNumber"
                value={userData.personalNumber}
                setValue={handleSetData("personalNumber")}
                rules={rules.required}
              />
            )}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8" >
            {loading ? (
              <Fragment>
                <Skeleton.Input />
                <Skeleton.Input />
              </Fragment>
            ) : (
              <Fragment>
                <InputGroup
                  label="configurations.userManagment.userList.phoneNumber"
                  value={userData.phoneNumber}
                  setValue={handleSetData("phoneNumber")}
                  rules={rules.required}
                />
                <InputGroup
                  label="configurations.userManagment.userList.mobileNumber"
                  value={userData.mobileNumber}
                  setValue={handleSetData("mobileNumber")}
                  rules={rules.required}
                />
              </Fragment>
            )}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-1 mt-8" >
            <div className="space-y-8">
              {loading ? (
                <Skeleton.Input />
              ) : (
                <InputGroup
                  label="configurations.userManagment.userList.emailAddress"
                  value={userData.emailAddress}
                  setValue={handleSetData("emailAddress")}
                  rules={rules.emailRequired}
                />
              )}
            </div>
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>configurations.userManagment.userList.address</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-1 gap-8 lg:gap-8">
          <section className="space-y-8">
            <SearchAddress setValue={handleSetAddress} />
            <Wrapper.Section className="grid grid-cols-1 lg:grid-cols-1 gap-x-6 gap-y-8 items-stretch">
              <div className="grid grid-cols-1 lg:grid-cols-1 gap-8 lg:gap-8">
                <div>
                  <InputGroup
                    label="global.locations.street"
                    value={userData?.street}
                    setValue={handleSetData("street")}
                    rules={rules.required}

                  />
                </div>
                <div>
                  <InputGroup
                    label="global.locations.houseNumber"
                    value={userData?.houseNumber}
                    setValue={handleSetData("houseNumber")}
                    rules={rules.required}

                  />
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
                <div>
                  <InputGroup
                    label="global.locations.postalCode"
                    value={userData?.postalCode}
                    setValue={handleSetData("postalCode")}
                    rules={rules.required}

                  />
                </div>
                <div>
                  <InputGroup
                    label="global.locations.city"
                    value={userData?.city}
                    setValue={handleSetData("city")}
                    rules={rules.required}

                  />
                </div>
              </div>
              <div className="col-span-full">
                <Select
                  label="global.locations.country"
                  value={userData?.country}
                  setValue={handleSetData("country")}
                  items={countries}
                  rules={rules.required}
                />
              </div>
              <div className="col-span-full">
                <Select
                  label="global.locations.state"
                  value={userData?.state}
                  setValue={handleSetData("state")}
                  items={
                    countries.find((e) => e.id === userData?.country)?.states as []
                  }
                  rules={rules.required}
                />
              </div>
            </Wrapper.Section>
          </section>
        </Wrapper.Body>
      </Wrapper>
    </div>
  );
}
