const salesReport = {
  all: "All",
  allSellers: "All sellers",
  allStores: "All Stores",
  totalSales: "Total sales",
  excludeSideCost: "Exclude side cost",
  salesCompare: "Sales Compare",
  targetSellFactor: "Target sell factor",
  averageSellFactor: "Average sell factor",
  date: "Date",
  year: "Year",
  quarter: "Quarter",
  quarterItem: "Quarter {{ value }}",
  customDate: "Custom Date",
  from: "From",
  to: "To",
  cancel: "Cancel",
  apply: "Apply",
  similarToLastYear: "Similar to last year",
  selectedDate: "Selected Date",
  toDate: "To",
  stores: "stores",
};
export default salesReport;
