import { Fragment, useMemo, useRef, useState } from "react";

import { Button, Icon, Image, Modal, Text } from "components";
import { accepts } from "constant";
import { useToggleState } from "hooks";
import { ServiceApi } from "services";
import ETC from "services/urls/etc";
import { Event, Media } from "types";
import { isMobile } from "utils";
import EditImageModal from "./EditImageModal";
import TakePhotoModal from "./TakePhotoModal";

type imageUploaderType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  image?: string | null;
  onUpload: (image: Media) => void;
};

export default function ImageUploader(props: imageUploaderType) {
  const imageFileInput = useRef<HTMLInputElement>(null);
  const isDesktop = useMemo(() => !isMobile(), []);
  const [active, toggle] = useToggleState();
  const [isTakePhotoModalOpen, toggleTakePhotoModal] = useToggleState(false);
  const [image, setImage] = useState<any>("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [state, setState] = useState({
    imageLoading: false,
    blobImage: "",
    currentImage: image,
    fileName: "",
  });
  const onUploadImage = () => {
    imageFileInput?.current?.click();
  };
  const handleClickFromCamera = () => {
    toggleTakePhotoModal();
  };
  const uploadImage = async (e: Event<HTMLInputElement>) => {
    // Reference to the DOM input element
    const { files } = e.target;

    // Ensure that you have a file before attempting to read it
    if (files && files[0]) {
      const blob = URL.createObjectURL(files[0]);
      const reader = new FileReader();

      reader.onload = () => {
        // Read image as base64 and set it as src:
        setState((prev) => ({
          ...prev,
          blobImage: blob,
          fileName: files?.[0]?.name ?? "",
        }));
        toggle();
      };
      // Start the reader job - read file as a data url (base64 format) and get the real file type
      reader.readAsArrayBuffer(files[0]);
    }
    // Clear the event target value to give the possibility to upload the same image:
    e.target.value = "";
  };

  const closeModales = () => {
    toggle();
    //close first modal
    props.toggle();
  };
  const handleCropImage = async (image: FormData) => {
    if (!image) return;
    setSubmitLoading(true);
    const url = ETC.UPLOAD_IMAGES_URL;
    ServiceApi.post(url, image)
      .then(({ data }) => {
        closeModales();
        props.onUpload(data[0]);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const handleTaken = (blobImage: string) => {
    setState((prev) => ({ ...prev, blobImage }));
    toggle();
  };
  const discardHandler = () => {
    props.toggle();
    setImage("");
  };
  return (
    <>
      <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="!z-50">
        <Modal.Header className="flex flex-row">
          <h6 className="text-heading-2 text-gray-800">
            <Text>global.editImage</Text>
          </h6>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="grid grid-cols-1 gap-8">
            <input
              ref={imageFileInput}
              hidden
              type="file"
              accept={accepts.image}
              onChange={(event) => uploadImage(event)}
            />
            <Image
              src={props.image}
              className="w-full aspect-image lg:h-40 lg:aspect-auto"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center gap-2">
          <Button
            type="button"
            onClick={discardHandler}
            variant="white"
            size="sm"
            className="me-auto"
          >
            <Text>global.discard</Text>
          </Button>
          {isDesktop ? (
            <Fragment>
              <Button
                type="button"
                light
                size="sm"
                onClick={handleClickFromCamera}
              >
                <Icon icon="camera" />
                {/* <Text>global.fromCamera</Text> */}
              </Button>
              <Button type="button" size="sm" onClick={onUploadImage}>
                <Text>global.chooseFile</Text>
              </Button>
            </Fragment>
          ) : (
            <Button type="button" size="sm" onClick={onUploadImage}>
              <Text>global.uploadNewImage</Text>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {isDesktop && (
        <TakePhotoModal
          isOpen={isTakePhotoModalOpen}
          toggle={toggleTakePhotoModal}
          onTaken={handleTaken}
        />
      )}
      <EditImageModal
        isOpen={active}
        image={state.blobImage}
        onCrop={handleCropImage}
        toggle={toggle}
        loading={submitLoading}
      />
    </>
  );
}
