export const suppliers = {
  addSupplier: "Neuen Lieferanten hinzufügen",
  supplierName: "Lieferantenname",
  code: "Code",
  name: "Name",
  title: "Titel",
  text: "Text",
  ilnNumber: "ILN (Internationale Standortnummer)",
  iln: "ILN",
  description: "Beschreibung",
  emailAddress: "E-Mail-Adresse",
  emailAddress1: "E-Mail-Adresse 1",
  emailAddress2: "E-Mail-Adresse 2",
  phoneNumber: "Telefonnummer",
  basicInfo: "Grundlegende Informationen",
  generalInfo: "Allgemeine Informationen",
  contactInfo: "Kontaktinformationen",
  program: "Programm",
  saleSetting: "Verkaufseinstellung",
  location: "Standort",
  review: "Überprüfung",
  configurable: "Konfigurierbar",
  manual: "Manuell",
  addProgram: "Programm hinzufügen",
  editProgram: "Programm bearbeiten",
  attribute: "Attribut",
  attributes: "Attribute",
  active: "Aktiv",
  deActive: "Inaktiv",
  addAttribute: "Attribut hinzufügen",
  required: "Erforderlich",
  generalInfoDesc: "Name, Beschreibung ...",
  contactInfoDesc: "Logo, E-Mail-Adresse ...",
  reviewDesc: "Informationen abschließen",
  locationDesc: "Lieferantenstandort",
  logo: "Logo",
  amount: "Betrag",
  discounts: "Rabatte",
  discount: "Rabatt",
  adddiscount: "Rabatt hinzufügen",
  supplierDiscount: "Lieferantenrabatt",
  programDiscount: "Programmrabatt",
  customText: "Benutzerdefinierter Text",
  customTitle: "Benutzerdefinierter Titel",
  dragChangeOrder: "Elemente ziehen, um die Reihenfolge zu ändern",
  sortOrder: "Sortierreihenfolge",
  zrNumber: "ZR-Nummer",
  settings: "Einstellungen",
  holidays: "Feiertage",
  addHoliday: "Feiertag hinzufügen",
  start: "Start",
  end: "Ende",
  lastOrder: "Letzte Bestellung",
  earliestShip: "Frühester Versand",
  startDate: "Startdatum",
  endDate: "Enddatum",
  lastOrderDate: "Datum der letzten Bestellung",
  earliestShipDate: "Datum des frühesten Versands",
  bankInfo: "Bankinformationen",
  defaultBankInfo: "Standard-Bankinformationen",
  bankName: "Bankname",
  accountOwnerName: "Kontoinhabername",
  iban: "IBAN",
  bic: "BIC",
  shipping: "Versand",
  defaultMinimumShippingTime: "Standardmindestversandzeit",
  none: "Keine",
  workDay: "Arbeitstag",
  calendarDay: "Kalendertag",
  holiday: "Feiertag",
  useDefaultMinimumShippingTime: "Standardmindestversandzeit verwenden",
  minimumShippingTime: "Mindestversandzeit",
  useDefaultBankInfo: "Standard-Bankinformationen verwenden",
  brand: "Marke",
  select: "Auswählen...",
  contactPerson: "Contact Person DE",
  addContactPerson: "Add Contact Person DE",
  firstName: "First Name DE",
  lastName: "Last Name DE",
  department: "Department DE",
  phone: "Phone DE",
  phone1: "Phone 1 DE",
  phone2: "Phone 2 DE",
};
