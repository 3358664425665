import { Fragment, createContext, useEffect, useState } from "react";
import {
  Image,
  Breadcrumb,
  Wrapper,
  Tabs,
  Form,
  Skeleton,
  ImageUploader,
  Icon,
} from "components";
import { useDataState, usePermission, useToggleState } from "hooks";
import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import { Media } from "types";
import { appPermissions } from "constant";
import { NotificationManager } from "common/notifications";

//... tabs
import General from "./tabs/general";

export const CustomerContext = createContext({});
export default function Details() {
  const [isOpen, toggle] = useToggleState(false);
  const { customerId } = useParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [headerData, setHeaderData] = useState<any>({});
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});

  //permissions
  const { AS_UpdateCustomer } = appPermissions;
  const UPDATE_PERMISSIONS = usePermission(AS_UpdateCustomer);

  let tabs: any = [];

  tabs = [
    {
      label: "configurations.userManagment.userList.general",
      component: General,
    },
    // {
    //   label: "configurations.userManagment.userList.group",
    //   component: UsersGroup,
    // }
  ];

  const getData = () => {
    const url = URLS.DETAILS_CUSTOMERS_URL(customerId);
    ServiceApi.get(url)
      .then(({ data }) => {
        let result = JSON.parse(JSON.stringify(data));
        setBaseData(result);
        setHeaderData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(getData, []);

  const submit = () => {
    setSubmitLoading(true);
    const url = URLS.UPDATE_B2B_USERS_URL(customerId);
    const body = { ...data };
    ServiceApi.put(url, body)
      .then(() => {
        const url = URLS.UPDATE_SET_DEFAULT_FILTER_USER_URL(customerId);
        ServiceApi.patch(url, body).then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title"
          );
        });
        setBaseData(data);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const onUploadImage = (data: Media) => {
    const url = URLS.UPLOAD_USER_IMAGES(customerId);
    const body = {
      url: data.url,
      type: data.type,
      thumbnailUrl: data.thumbnailUrl,
    };
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
      })
      .finally(() => {
        getData();
      });
  };

  return (
    <Tabs activeKey={tabs[0].label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
            <div className="w-[114px] h-[114px]">
              <Image
                placeholder="user"
                // onClick={() => toggle()}
                src=""
                alt={data?.cimNumber}
                className="w-full h-full aspect-image lg:aspect-auto cursor-pointer"
              />
            </div>
            <div className="lg:flex-1 space-y-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="space-y-3">
                    <div className="flex justify-between items-center">
                      <h5 className="text-heading-5 text-gray-800 font-semibold">
                        {`${headerData?.personInfo?.firstName} ${headerData?.personInfo?.lastName}`}
                      </h5>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                        <Icon
                          className="text-gray-500 font-black h-4 w-4"
                          icon="hashtag"
                        />
                      </div>
                      <div>
                        <span className="text-body-2 text-gray-500  font-medium">
                          {headerData?.cimNumber}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                        <Icon
                          className="text-gray-500 font-black h-4 w-4"
                          icon="envelope"
                        />
                      </div>
                      <div>
                        <span className="text-body-2 text-gray-500  font-medium">
                          {headerData?.personInfo?.emailAddress}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                        <Icon
                          className="text-gray-500 font-black h-4 w-4"
                          icon="mobile"
                        />
                      </div>
                      <div>
                        <span className="text-body-2 text-gray-500  font-medium">
                          {headerData?.personInfo?.emailAddress}
                        </span>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>
        <Wrapper className="p-8">
          <CustomerContext.Provider
            value={{
              userData: data,
              setUserData: setData,
              loading: loading,
              getData: getData,
            }}
          >
            <General />
          </CustomerContext.Provider>
        </Wrapper>
        {/* {UPDATE_PERMISSIONS && (
          <Footer show={!isChanged} >
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        )} */}
      </Form>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={data.imageUrl}
        onUpload={(data: Media) => onUploadImage(data)}
      />
    </Tabs>
  );
}
