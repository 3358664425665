import { useContext } from "react";
import cloneDeep from "lodash/cloneDeep";
import { InputGroup, Select, Text, Wrapper } from "components";
import SearchAddress from "components/SearchAddress";
import { countries } from "constant";
import { rules } from "constant";
import { StoreContext } from ".";
import { generateMapSrc } from "utils";

export default function Location() {
  const { storeData, setStoreData } = useContext(StoreContext);
  const handleSetValue = (key) => {
    return (value) =>
      setStoreData((p) => {
        const data = cloneDeep(p);
        data.address[key] = value;
        return data;
      });
  };
  const handleSetAddress = (address) => {
    setStoreData((p) => ({ ...p, address }));
  };
  return (
    <Wrapper>
      <Wrapper.Header>
        <h1 className="text-heading-2 font-semibold">
          <Text>company.stores.location</Text>
        </h1>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
        <section className="space-y-8">
          <SearchAddress setValue={handleSetAddress} />
          <Wrapper.Section className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 items-stretch">
            <InputGroup
              label="global.locations.street"
              value={storeData.address?.street}
              setValue={handleSetValue("street")}
              rules={rules.required}
            />
            <InputGroup
              label="global.locations.houseNumber"
              value={storeData.address?.houseNo}
              setValue={handleSetValue("houseNo")}
              rules={rules.required}
            />
            <InputGroup
              label="global.locations.postalCode"
              value={storeData.address?.postalCode}
              setValue={handleSetValue("postalCode")}
              rules={rules.required}
            />
            <InputGroup
              label="global.locations.city"
              value={storeData.address?.city}
              setValue={handleSetValue("city")}
              rules={rules.required}
            />
            <div className="col-span-full">
              <Select
                label="global.locations.country"
                value={storeData.address?.country}
                setValue={handleSetValue("country")}
                items={countries}
                rules={rules.required}
              />
            </div>
            <div className="col-span-full">
              <Select
                label="global.locations.state"
                value={storeData.address?.state}
                setValue={handleSetValue("state")}
                items={
                  countries.find((e) => e.id === storeData.address?.country)
                    ?.states
                }
                rules={rules.required}
              />
            </div>
          </Wrapper.Section>
        </section>
        <div className="flex flex-col">
          <h6 className="mb-2">
            <Text>company.stores.locationOnMap</Text>
          </h6>
          <iframe
            title="Address"
            src={generateMapSrc(storeData.address)}
            className="bg-gray-300 block aspect-square lg:aspect-auto w-full lg:flex-1 rounded shadow"
          />
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
