import Text from "./Text";

type TotalItemsProps = {
  totalItems?: number | string;
};

export default function TotalItems({ totalItems = 0 }: TotalItemsProps) {
  const hasItems = !!totalItems;
  if (!hasItems) return null;
  return (
    <span className="text-gray-400 font-medium text-body-2">
      {totalItems} <Text>global.items</Text>
    </span>
  );
}
