import { Icon, Image, ImageUploader, Text, Wrapper } from "components";
import { useSelector, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ServiceApi, URLS } from "services";
import { setproductCategories } from "store/productCategories";
import { Media as MediaType } from "types";

const Media = () => {
  const dispatch = useDispatch();
  const [isOpen, toggle] = useToggleState(false);
  const [isOpenThumbnailUrl, toggleThumbnailUrl] = useToggleState(false);
  const productCategory = useSelector((s) => s.productCategories);
  const [data, setData] = useState<any>();

  useEffect(() => {
    setData(productCategory);
  }, [productCategory]);

  const onUploadImage = (image: any) => {
    const url = URLS.EDIT_CATEGORY_TRANSLATES(data.productCategoryId);
    const body = {
      code: data.code,
      pictureThumbnailUrl: data.pictureThumbnailUrl,
      pictureUrl: image.url,
      translates: data.translations,
    };
    ServiceApi.patch(url, body).then(() => {})
    dispatch(setproductCategories({ ...data, pictureUrl: image.url }));
  };

  const onUploadImageThumbnailUrl = (image: any) => {
    const url = URLS.EDIT_CATEGORY_TRANSLATES(data.productCategoryId);
    const body = {
      code: data.code,
      pictureThumbnailUrl: image.thumbnailUrl,
      pictureUrl: data.pictureUrl,
      translates: data.translations,
    };
    ServiceApi.patch(url, body).then(() => {})
    dispatch(setproductCategories({ ...data, pictureThumbnailUrl: image.thumbnailUrl }));
  };

  return (
    <Wrapper>
      <Wrapper.Header className="text-heading-2 font-semibold">
        <Text>productManagement.masterData.productCategory.images</Text>
      </Wrapper.Header>
      <Wrapper.Body className="flex space-x-6">
        <div className="space-y-4">
          <span className="text-heading-6 text-gray-800 font-normal">
            <Text>productManagement.masterData.productCategory.mainImage</Text>
          </span>
          <div
            className="h-[220px] w-[176px] bg-gray-200 flex flex-center rounded-xl cursor-pointer"
            onClick={() => {
              toggle();
            }}
          >
            {data?.pictureUrl ? (
              <Image
                src={data?.pictureUrl}
                alt={""}
                editor
                imageClassName="h-full"
                className="aspect-image h-[220px] w-[176px] lg:aspect-auto cursor-pointer"
              />
            ) : (
              <div className="flex flex-col space-y-4">
                <Icon
                  icon="file-arrow-up"
                  size="3x"
                  className="text-gray-500"
                />
                <span className="text-body-base text-gray-600 font-medium">
                  <Text>
                    productManagement.masterData.productCategory.uploadImage
                  </Text>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4">
          <span className="text-heading-6 text-gray-800 font-normal">
            <Text>productManagement.masterData.productCategory.thumbnail</Text>
          </span>
          <div
            className="h-[220px] w-[176px] bg-gray-200 flex flex-center rounded-xl cursor-pointer"
            onClick={() => {
              toggleThumbnailUrl();
            }}
          >
            {data?.pictureThumbnailUrl ? (
              <Image
                src={data?.pictureThumbnailUrl}
                alt={""}
                editor
                imageClassName="h-full"
                className="aspect-image h-[220px] w-[176px] lg:aspect-auto cursor-pointer"
              />
            ) : (
              <div className="flex flex-col space-y-4">
                <Icon
                  icon="file-arrow-up"
                  size="3x"
                  className="text-gray-500"
                />
                <span className="text-body-base text-gray-600 font-medium">
                  <Text>
                    productManagement.masterData.productCategory.uploadImage
                  </Text>
                </span>
              </div>
            )}
          </div>
        </div>
      </Wrapper.Body>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={data?.pictureUrl}
        onUpload={(data: MediaType) => onUploadImage(data)}
      />
      <ImageUploader
        isOpen={isOpenThumbnailUrl}
        toggle={toggleThumbnailUrl}
        image={data?.pictureUrlThumbnailUrl}
        onUpload={(data: MediaType) => onUploadImageThumbnailUrl(data)}
      />
    </Wrapper>
  );
};

export default Media;
