
import {
  InputGroup,
  Select,
  Wrapper
} from "components";
import { countries } from "constant";
import SearchAddress from "components/SearchAddress";
import { AddSupplierContext } from "..";
import { Fragment, useContext, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";

export default function Location() {
  const { data, setData } = useContext<any>(AddSupplierContext);

  const handleSetValue = (key: any) => {
    return (value: any) =>
      setData((p: any) => {
        const data = cloneDeep(p);
        data.address[key] = value;
        return data;
      });
  };
  const handleSetAddress = (address: any) => {
    address["number"] = address?.houseNo;
    setData((p: any) => {
      return {
        ...p,
        address: address
      }
    });
    return {};
  };


  return (
    <Fragment>
      <SearchAddress setValue={handleSetAddress as any} />
      <Wrapper.Section className="grid grid-cols-1 lg:grid-cols-1 gap-x-6 gap-y-8 items-stretch">
        <InputGroup
          label="global.locations.street"
          value={data.address?.street}
          setValue={handleSetValue("street")}

        />
        <InputGroup
          label="global.locations.houseNumber"
          value={data.address?.number}
          setValue={handleSetValue("number")}

        />
        <InputGroup
          label="global.locations.postalCode"
          value={data.address?.postalCode}
          setValue={handleSetValue("postalCode")}

        />
        <InputGroup
          label="global.locations.city"
          value={data.address?.city}
          setValue={handleSetValue("city")}

        />
        <div className="col-span-full">
          <Select
            label="global.locations.country"
            value={data.address?.country}
            setValue={handleSetValue("country")}
            items={countries}
          />
        </div>
        <div className="col-span-full">
          <Select
            label="global.locations.state"
            value={data.address?.state}
            setValue={handleSetValue("state")}
            items={
              countries.find((e) => e.id === data.address?.country)?.states as []
            }

          />
        </div>
      </Wrapper.Section>
    </Fragment>
  );
}
