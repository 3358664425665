import {
  createContext,
  ReactNode,
  TransitionEvent,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import { classNames } from "utils";

type eventKeyType = string | null;
type accordionProps = {
  children: ReactNode;
  defaultActiveKey?: eventKeyType;
};
type accordionItemProps = {
  className?: string;
  eventKey?: eventKeyType;
  children: ReactNode;
  active?: boolean;
};
type accordionToggleProps = {
  className?: string;
  children: ReactNode;
};
type accordionBodyProps = {
  className?: string;
  children: ReactNode;
};
type accordionContextType = {
  activeKey: eventKeyType;
  setActiveKey: (val: eventKeyType) => void;
};
type accordionItemContextType = {
  eventKey: eventKeyType;
  isActive: boolean;
  setIsActive: (val: boolean) => void;
  id: string;
};
const AccordionContext = createContext({} as accordionContextType);
const AccordionItemContext = createContext({} as accordionItemContextType);

function Accordion({
  children = null,
  defaultActiveKey = null,
}: accordionProps) {
  const [activeKey, setActiveKey] = useState<eventKeyType>(defaultActiveKey);
  return (
    <AccordionContext.Provider value={{ activeKey, setActiveKey }}>
      {children}
    </AccordionContext.Provider>
  );
}
function AccordionItem({
  className = "",
  eventKey = null,
  active = false,
  children,
}: accordionItemProps) {
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  const key = eventKey ?? id;
  const { activeKey } = useContext(AccordionContext);
  const [isActive, setIsActive] = useState<boolean>(active);
  // let isActive: boolean = active ? active : activeKey === key;

  useEffect(() => {
    setIsActive(active ? active : activeKey === key);
  }, [active]);

  useEffect(() => {
    ref.current?.classList.toggle("active", isActive);
    ref.current?.classList.toggle("notactive", !isActive);
  }, [isActive]);
  return (
    <AccordionItemContext.Provider
      value={{ eventKey: key, isActive, id, setIsActive }}
    >
      <div
        ref={ref}
        id={id}
        className={`accordion-item group w-full border-b border-gray-100 ${className}`}
      >
        {children}
      </div>
    </AccordionItemContext.Provider>
  );
}
function AccordionToggle({
  className = "",
  children = null,
}: accordionToggleProps) {
  const { setActiveKey } = useContext(AccordionContext);
  const { eventKey, isActive, setIsActive } = useContext(AccordionItemContext);
  const handleSetActiveKey = () => {
    setIsActive(!isActive);
    if (isActive) return setActiveKey(null);
    setActiveKey(eventKey);
  };
  return (
    <button
      type="button"
      onClick={handleSetActiveKey}
      className={`w-full flex items-center gap-4 py-5 font-bold ${className}`}
    >
      {children}
      {/* <i className="bi bi-chevron-down transition-transform ml-auto group-[.accordion-item.active]:rotate-180" /> */}
    </button>
  );
}
function AccordionBody({
  className = "",
  children = null,
}: accordionBodyProps) {
  const collapseRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const { isActive, id } = useContext(AccordionItemContext);
  const handleTransition = ({
    currentTarget,
  }: TransitionEvent<HTMLDivElement>) => {
    currentTarget.style.height = isActive ? "auto" : "0";
    currentTarget.style.overflow = isActive ? "visible" : "hidden";
  };
  useEffect(() => {
    const collapse = collapseRef.current;
    const body = bodyRef.current;
    const height = body?.getBoundingClientRect().height;
    if (collapse && body) {
      if (isActive) {
        collapse.style.height = `${height}px`;
        // collapse.style.overflow = "visible";
      } else {
        collapse.style.height = `${height}px`;
        requestAnimationFrame(() => {
          collapse.style.height = "0px";
          collapse.style.overflow = "hidden";
        });
      }
    }
  }, [isActive]);
  return (
    <div
      ref={collapseRef}
      onTransitionEnd={handleTransition}
      className={`w-full h-0 group-[.accordion-item.active]:h-auto overflow-hidden transition-[height] ${className}`}
    >
      {children && (
        <div ref={bodyRef} className="h-fit pt-2 pb-4">
          {children}
        </div>
      )}
    </div>
  );
}

Accordion.Item = AccordionItem;
Accordion.Toggle = AccordionToggle;
Accordion.Body = AccordionBody;

export default Accordion;
