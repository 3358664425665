import { Text } from "components";
import { useConverter } from "hooks";
import { useMemo } from "react";
import { Cell, PieChart as Chart, Label, Pie, Tooltip } from "recharts";
import { dataType } from ".";

type pieChartProps = {
  allData: dataType;
  currentData: dataType;
  isSeller?: boolean;
};

export default function PieChart({
  allData,
  currentData,
  isSeller = false,
}: pieChartProps) {
  const { convertAmount } = useConverter();

  const pieChartData = useMemo(() => {
    const currentItem = {
      label:
        currentData.requestFilter[isSeller ? "advisorFullName" : "location"],
      value: currentData.totalSales,
      totalSales: currentData.totalSales,
      tooltipClassName: "text-info",
      className: "text-info",
      isAll: false,
    };
    const allItem = {
      label: "salesManagment.kitchenSales.salesReport.allStores",
      value: allData.totalSales - currentData.totalSales,
      totalSales: allData.totalSales,
      tooltipClassName: "text-gray-500",
      className: "text-gray-300",
      isAll: true,
    };
    return [currentItem, allItem];
  }, [allData, currentData, isSeller]);

  const totalSales = useMemo(() => {
    return pieChartData.reduce((a, b) => a + b.value, 0);
  }, [pieChartData]);

  const label = useMemo(() => {
    const percent = (pieChartData[0].value / totalSales) * 100;
    const value = percent.toFixed(1);
    return `${value}%`;
  }, [pieChartData, totalSales]);

  return (
    <div className="flex-center flex-col">
      <Chart width={175} height={175} className="mb-6">
        <Pie
          data={pieChartData}
          dataKey="value"
          nameKey="label"
          cx="50%"
          cy="50%"
          innerRadius={40}
          outerRadius={80}
          fill="currentColor"
          startAngle={90}
          endAngle={450}
        >
          {pieChartData?.map((e, index) => (
            <Cell
              key={`cell-${index}`}
              fill="currentColor"
              className={e.className}
            />
          ))}
          <Label
            value={label}
            position="center"
            fill="currentColor"
            className="text-heading-2 text-info font-semibold"
          />
        </Pie>
        <Tooltip
          content={({ active, payload }) => {
            const hasData = [active, payload?.length].every(Boolean);
            if (!hasData) return null;
            const data = payload?.at(0);
            const label = data?.payload?.label ?? "";
            const tooltipClassName = data?.payload?.tooltipClassName ?? "";
            const isAll = !!data?.payload?.isAll;
            const value = +(data?.value ?? 0);
            const total = +data?.payload?.totalSales;
            const percent = isAll
              ? 100
              : ((100 * value) / totalSales).toFixed(1);
            return (
              <div
                className={`bg-white text-center shadow p-4 rounded space-y-2 [&>*]:text-current ${tooltipClassName}`}
              >
                <h4 className="text-base">{percent}%</h4>
                <h5 className="text-sm">{convertAmount(total)}</h5>
                <h6 className="text-xs">
                  <span className="relative inline-flex flex-center h-2 w-2 align-middle">
                    <span className="absolute inset-0 animate-ping rounded-full bg-current opacity-75"></span>
                    <span className="absolute inset-0 rounded-full bg-current"></span>
                  </span>{" "}
                  <Text>{label}</Text>
                </h6>
              </div>
            );
          }}
        />
      </Chart>
      <section className="w-full space-y-2">
        <div className="w-full flex items-center justify-between gap-2 text-info">
          <p className="text-current text-body-base font-medium">
            {
              currentData.requestFilter[
                isSeller ? "advisorFullName" : "location"
              ]
            }
          </p>
          <p className="text-current text-body-base font-medium">
            {convertAmount(currentData.totalSales)}
          </p>
        </div>
        <div className="w-full flex items-center justify-between gap-2 text-gray-700">
          <p className="text-current text-body-base font-medium">
            <Text>
              {isSeller
                ? "salesManagment.kitchenSales.salesReport.allSellers"
                : "salesManagment.kitchenSales.salesReport.allStores"}
            </Text>
          </p>
          <p className="text-current text-body-base font-medium">
            {convertAmount(allData.totalSales)}
          </p>
        </div>
      </section>
    </div>
  );
}
