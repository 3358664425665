import { Button, Icon, InputGroup, Skeleton, Text, Wrapper } from "components";
import { appPermissions } from "constant";
import { usePermission } from "hooks";
import { useContext, useEffect, useState } from "react";
import { PimContext } from "..";
import AddPrice from "../components/add-price";

const PriceTab = () => {
  const priceTemplate = {
    from: "",
    to: "",
    price: {
      amount: "",
      currency: "EUR",
      currencySymbol: "€",
    },
  };

  const {
    productData,
    setData,
    loading,
    isChanged,
    editProduct,
    submitLoading,
    setPatchData,
  } = useContext(PimContext);
  const [newPrice, setNewPrice] = useState<any>([]);

  useEffect(() => {
    setNewPrice(productData?.priceCharts || []);
  }, [productData?.priceCharts]);

  const onChangeHandler = (key: string) => {
    return (value: any) => {
      setPatchData((p: any) => ({
        ...p,
        [key]: { ...p.price, amount: value },
      }));
      setData((p: any) => ({ ...p, [key]: { ...p.price, amount: value } }));
    };
  };

  const removeOnAdd = (index: number) => {
    let clone = [...newPrice];
    clone.splice(index, 1);
    setData((p: any) => ({ ...p, priceCharts: clone }));
  };

  const onPriceChangeHandler = (index: number, key: string, value: string) => {
    let clone = [...newPrice];
    if (key !== "price") {
      clone[index][key] = value;
      setPatchData((p: any) => ({ ...p, priceChart: clone }));
      setData((p: any) => ({ ...p, priceCharts: clone }));
    } else {
      clone[index][key]["amount"] = value;
      setPatchData((p: any) => ({ ...p, priceChart: clone }));
      setData((p: any) => ({ ...p, priceCharts: clone }));
    }
  };

  const onChangeData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };

  //permissions

  const { PS_UpdatePimProduct } = appPermissions;
  const UPDATE_PERMISSION = usePermission(PS_UpdatePimProduct);

  return (
    <div className="space-y-6">
      <Wrapper>
        <Wrapper.Header>
          <Text>productManagement.products.Details.price</Text>
        </Wrapper.Header>
        <Wrapper.Body>
          <div className="grid grid-cols-2">
            {loading ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="productManagement.products.Details.supplierPrice"
                value={productData?.price?.amount}
                setValue={onChangeHandler("price")}
                type="number"
                append={
                  <h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">
                    {productData?.price?.currencySymbol}
                  </h5>
                }
              />
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <Text>productManagement.products.Details.salesPrice</Text>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-6">
          {newPrice?.map((price: any, index: number) => (
            <AddPrice
              price={price}
              index={index}
              onRemove={removeOnAdd}
              onChange={onPriceChangeHandler}
            />
          ))}
          <Button
            light
            onClick={() => setNewPrice([...newPrice, { ...priceTemplate }])}
          >
            <Icon icon="plus" className="mr-4" />
            <Text>productManagement.products.Details.addPriceDate</Text>
          </Button>
        </Wrapper.Body>
      </Wrapper>
      {!loading && UPDATE_PERMISSION && (
        <Wrapper className="sticky bottom-0 shadow-card">
          <Wrapper.Body className="flex items-center justify-end gap-4">
            <Button
              type="button"
              variant="primary"
              disabled={!isChanged}
              onClick={editProduct}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Wrapper.Body>
        </Wrapper>
      )}
    </div>
  );
};

export default PriceTab;
