export const apiKeys = {
    apiKeysList: "API Key List",
    generateAPIKey: "Generate API Key",
    apiKeyName: "API Key Name DE",
    apiKeys: "API Key",
    compony: "Compony",
    configurations: "Configurations",
    create: "Create",
    discard: "Discard",
    view: "View",
    description: "Description",
    code: "Code",
    copy: "Copy",
    leganName: "Legan Name",
    generalInfo: "General Info",
    saveChanges: "Save Changes",
    yourApiKey: "Your API Key",
    itemSelected: "Item Selected",
    pmGenerateApikey: "You must copy your API key because you will not be able to see it again",
    pmDeleteApikey: "Are you sure you want to delete this item"
}