export const apiKeys = {
  apiKeysList: "API-Schlüsselliste",
  generateAPIKey: "Schlüssel generieren",
  apiKeyName: "Name",
  apiKeys: "API-Schlüssel",
  compony: "Unternehmen",
  configurations: "Konfigurationen",
  create: "Erstellen",
  discard: "Verwerfen",
  view: "Anzeigen",
  description: "Beschreibung",
  code: "Code",
  copy: "Kopieren",
  leganName: "Rechtsname",
  generalInfo: "Allgemeine Informationen",
  saveChanges: "Änderungen speichern",
  yourApiKey: "Ihr API-Schlüssel",
  itemSelected: "Element ausgewählt",
  pmGenerateApikey: "Sie müssen Ihren API-Schlüssel kopieren, da Sie ihn später nicht mehr anzeigen können.",
  pmDeleteApikey: "Sind Sie sicher, dass Sie dieses Element löschen möchten?"
};