import { Fragment, useContext, useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { config } from "constant";
import { Table, Toggler, Skeleton } from "components";
import { UserContext } from "..";

export default function Store() {
  const { userData, setUserData, setFilterStore, filterStore } =
    useContext<any>(UserContext);
  const [storeList, setStoreList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  const getDataStore = () => {
    const url = URLS.ADD_STORE_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config)
      .then(({ data }) => {
        let newList = JSON.parse(JSON.stringify(data.items));
        newList.forEach((item: any) => {
          item["storeUser"] = false;
          userData?.stores?.forEach((i: any) => {
            if (item.code === i.code) {
              item["storeUser"] = true;
            }
          });
        });
        setStoreList(newList);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  const getList = (data: any) => {
    let newList: any = JSON.parse(JSON.stringify(storeList));
    newList.forEach((item: any) => {
      item["storeUser"] = false;
      data?.forEach((i: any) => {
        if (item.code === i.code) {
          item["storeUser"] = true;
        }
      });
    });
    setStoreList(newList);
  };

  useEffect(getDataStore, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <Table>
          {storeList?.map((item: any) => {
            return (
              <tr>
                <td>{item?.title}</td>
                <td>
                  <div className="w-[60%]">
                    <Toggler
                      label={"configurations.userManagment.userList.active"}
                      value={item?.storeUser}
                      size="sm"
                      // disabled={profile?.userId === userData?.userId ? true : false}
                      setValue={(value) => {
                        let newStores: any =
                          userData?.stores !== null ? userData?.stores : [];
                        if (value) {
                          newStores.push({
                            code: item.code,
                            storeId: item.id,
                            mainPhoto: item.mainPhoto,
                            storeAddress: item.address,
                            title: item.title,
                          });
                        } else {
                          newStores = [];
                          userData?.stores?.forEach((i: any) => {
                            if (i.code !== item.code) {
                              newStores.push(i);
                            }
                          });
                          if (item?.id === filterStore) {
                            setFilterStore(null);
                          }
                        }
                        setUserData((p: any) => ({ ...p, stores: newStores }));
                        setUserData((p: any) => ({ ...p, ac: value }));
                        getList(newStores);
                      }}
                    />
                  </div>
                </td>
                <td>
                  <Toggler
                    label={
                      "configurations.userManagment.userList.setDefaultFilter"
                    }
                    value={item.id === filterStore ? true : false}
                    disabled={!item?.storeUser}
                    size="sm"
                    setValue={(value) => {
                      setFilterStore(value ? item.id : null);
                      setUserData((p: any) => ({ ...p, fi: item.id + value }));
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </Table>
      )}
    </Fragment>
  );
}
