import i18n from "langs/i18n";

export const getCultureName = () => {
    let culture : any = i18n.language;
    let result = culture + '-' + culture.toUpperCase();
    return result;
  };

export const DecimalFormatter = (item : any) => {
    const culture =  getCultureName();
    return new Intl.NumberFormat(culture, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(item);
  };

  export const CurrencyFormatter = (item : any) => {
    const culture = getCultureName()
    return new Intl.NumberFormat(culture, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(item);
  };

  export const checkPriceAvailability = (_price : any) => {
    let price = parseInt(_price);
    if (price) {
      if (price > 0) {
        return CurrencyFormatter(price.toFixed(2));
      } else {
        return "--";
      }
    } else {
      return "--";
    }
  };
  