const SELL_OF_COMMISSIONS = {
  SEARCH_SELL_OFF_COMISSION:
    "/productservice/api/selloffcommissions/search-sell-off-commission",
  SEARCH_OWN_SELL_OFF_COMISSION:
    "/productservice/api/selloffcommissions/search-own-sell-off-commission",
  DOWNLOAD_SELL_OFF_COMISSION:
    "/productservice/api/selloffcommissions/download-sell-off-commission",
};

export default SELL_OF_COMMISSIONS;
