import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  NoItems,
  Status
} from "components";
import { useToggleState, useConverter } from "hooks";
import { ServiceApi, URLS } from "services";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { config } from "constant";
import { HandleParamsUrl } from "utils"
import FilterDrawer from "./FilterDrawer";
import AverageFactor from "./AverageFactor";


export default function SalesList() {
  const [data, setData] = useState<any>();
  const [loadingList, setLoadingList] = useState(true);
  const [filterModal, setFilterModal] = useToggleState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData = {
    advisorFullName: null,
    currentStatus: null,
    dateFrom: null,
    dateTo: null,
    hasContractNumber: null,
    keyword: "",
    location: null,
    pageNumber: "1",
    pageSize: config.pageSize,
    sellFactorHasWarning: null,
    shippingCostHasWarning: null,
    sortType: null,
    topBuy: null,
    totalAmountFrom: null,
    totalAmountTo: null,
    totalTargetAmountFrom: null,
    totalTargetAmountTo: null
  };

  const [keyword, setKeyword] = useState("");
  const [thisDate, setThisDate] = useState("");
  const [topBuy, setTopBuy] = useState("");
  const { convertDate } = useConverter();

  const [filterData, setFilterData] = useState(originalFilterData);
  const [params, setParams] = useSearchParams();


  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.KITCHEN_CALCULATIONS_URL;
    const config = params;
    setLoadingList(true);
    ServiceApi.post(url, config).then(({ data }) => {
      setData(data);
    }).finally(() => {
      setLoadingList(false);
    });
  };

  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps


  const resetFilter = () => {
    setParams({})
    setKeyword("")
    setThisDate("")
    setTopBuy("")
  };

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      if (key === "dateFrom" || key === "dateTo") {
        value = value.toDateString();
      }
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    }
  };

  // //permissions
  // const { PS_ImportPimProduct } = appPermissions;
  // const GET_LIST_PERMISSION = usePermission(PS_ImportPimProduct);
  // const GET_DETAIL_PERMISSION = usePermission(PS_ImportPimProduct);



  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <SearchBox
        value={keyword}
        onSubmit={handleSetFilterData("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onFilter={() => setFilterModal()}
        resetFilter={searchParams?.size === 0 ? undefined : resetFilter}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.searchResult?.length !== 0 ?
            <>
              <Table>
                {data?.searchResult?.map((item: any) => {
                  return (
                    <tr key={item.projectId} >
                      <td>
                        <Link
                          to={item.projectId}
                          className="w-fit inline-flex flex gap-2">
                          <div className="space-y-2">
                            <div className="flex items-center gap-1">
                              <span className="text-body-base text-gray-800 font-normal">
                                <Text>salesManagment.kitchenSales.salesList.projectNumber</Text>:
                              </span>
                              <h6 className="text-heading-6 text-gray-800 font-semibold">{item.projectId}</h6>
                            </div>
                            <div className="flex items-center gap-1">
                              <span className="text-body-base text-gray-800 font-normal">
                                <Text>salesManagment.kitchenSales.salesList.projectName</Text>:
                              </span>
                              <h6 className="text-heading-6 text-gray-800 font-semibold">{item.projectName}</h6>
                            </div>
                            <div className="flex items-center gap-1">
                              <span className="text-body-base text-gray-600 font-normal">
                                <Text>salesManagment.kitchenSales.salesList.contract</Text>:
                              </span>
                              <span className="text-body-base text-gray-800 font-normal">{item.updates[0].contractNumber}</span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="text-left">
                        <div className="space-y-2">
                          <div className="flex items-center gap-1">
                            <span className="text-body-base text-gray-600 font-normal">
                              <Text>salesManagment.kitchenSales.salesList.advisor</Text>:
                            </span>
                            <span className="text-body-base text-gray-800 font-normal">{item?.updates[0]?.advisorFullName}</span>
                          </div>
                          <div className="flex items-center gap-1">
                            <span className="text-body-base text-gray-600 font-normal">
                              <Text>salesManagment.kitchenSales.salesList.createdAt</Text>:
                            </span>
                            <span className="text-body-base text-gray-800 font-normal">{convertDate(item?.updates[0]?.createdAt)}</span>
                          </div>
                          <div className="flex items-center gap-1">
                            <span className="text-body-base text-gray-600 font-normal">
                              <Text>salesManagment.kitchenSales.salesList.lastUpdated</Text>:
                            </span>
                            <span className="text-body-base text-gray-800 font-normal">{convertDate(item?.updates[0]?.updatedAt)}</span>
                          </div>
                        </div>
                      </td>
                      <td className="text-left">
                        <AverageFactor
                          average={item?.updates[0]?.sellFactor}
                          target={item?.updates[0]?.targetSellFactor}
                          warning={item?.sellFactorHasWarning}
                        />
                      </td>
                      <td className="space-y-[10px]">
                        {(item?.currentStatus === 3 || item?.currentStatus === 4) && <div>
                          <Status.salesListStatus id={item?.currentStatus} />
                        </div>}
                        {item?.currentStatus !== 4 &&
                          <>
                            {item?.sellFactorHasWarning && <div>
                              <Status.sellFactorHasWarning id={item?.sellFactorHasWarning} />
                            </div>}
                            {item?.shippingCostHasWarning && <div>
                              <Status.shippingCostHasWarning id={item?.shippingCostHasWarning} />
                            </div>}
                          </>
                        }
                      </td>
                      <td>
                        <Button as={Link} to={item.projectId} variant="light" size="sm">
                          <Text>global.buttons.details</Text>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetFilterData("pageNumber")}
                onPageSizeChange={handleSetFilterData("pageSize")}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
      <FilterDrawer
        isOpen={filterModal}
        data={data}
        keyword={keyword}
        setKeyword={setKeyword}
        thisDate={thisDate}
        setThisDate={setThisDate}
        toggle={setFilterModal}
        onChange={handleSetFilterData}
        resetFilter={resetFilter}
        topBuy={topBuy}
        setTopBuy={setTopBuy}
        setParams={setParams}
        params={params}
      />
    </div>
  )
}
