import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  Icon,
  NoItems
} from "components";
import { usePermission, useToggleState } from "hooks";
import { ServiceApi, URLS } from "services";
import { Link, useSearchParams } from "react-router-dom";
import { config, appPermissions } from "constant";
import AddModal from "./add-modal";

type UserRolesItems = {
  id: string;
  name: string;
  description: string;
};


type ListType = {
  items?: UserRolesItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function Roles() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [isOpen, toggle] = useToggleState(false);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();


  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_ROLES_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config).then(({ data }) => {
      setData(data);
    }).finally(() => {
      setLoadingList(false);
    });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps


  //permissions
  const { AS_GetRoles, AS_GetRoleDetail, AS_CreateRole } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(AS_GetRoles);
  const CREATE_PERMISSION = usePermission(AS_CreateRole);
  const GET_DETAIL_PERMISSION = usePermission(AS_GetRoleDetail);


  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
          {loadingList ? (
            <Skeleton.Button />
          ) : (CREATE_PERMISSION ?
            <Button
              type="button"
              onClick={() => toggle()}
            >
              <Icon className="mr-2" icon={"plus"} /><Text>configurations.userManagment.roles.addRole</Text>
            </Button>
            : null)}
        </div>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ?
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.id} >
                      <td>
                        <Link to={GET_DETAIL_PERMISSION ? `${item.id}?tab=0` : ""} className="w-fit inline-flex flex-center flex gap-2">
                          <div><h6 className="text-heading-6 text-gray-800 font-semibold">{item.name}</h6></div>
                        </Link>
                      </td>
                      <td>
                        <div className="text-body-base font-normal text-left" >
                          <span className="text-gray-600">{item.description}</span>
                        </div>
                      </td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button
                            as={Link}
                            to={`${item.id}?tab=0`}
                            variant="light"
                            size="sm">
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) => setParams((p) => ({ ...p, pageSize: e }))}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
          <AddModal isOpen={isOpen} toggle={toggle} getData={getData} />
        </>
      )}
    </div>
  ) : (
    <>Permission Error</>
  );
}
