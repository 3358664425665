import { Icon, Skeleton } from "components";
import { useConverter } from "hooks";
import { Fragment, useContext, useState } from "react";
import { SalesReportContext, dataType, paramsType } from ".";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import AverageFactor from "./AverageFactor";

type sellerReportProps = dataType["advisorsBasedOnTimeInterval"][0] & {
  storeData: dataType;
  params: paramsType;
};

export default function SellerReport({
  advisorFullName,
  totalSales,
  storeData,
  params,
}: sellerReportProps) {
  const { convertAmount } = useConverter();
  const { searchSalesReport, excludeSideCosts } =
    useContext(SalesReportContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<dataType | null>(null);
  const [isActive, setIsActive] = useState(false);
  const toggleIsActive = () => {
    setIsActive((p) => !p);
  };
  const getData = () => {
    if (!!data || !!loading) return toggleIsActive();
    setLoading(true);
    const body = { ...params, advisorFullName, excludeSideCosts };
    searchSalesReport(body)
      .then(({ data }) => {
        setData(data);
        setIsActive(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <tr>
        <td className="space-y-4">
          <section className="flex items-center gap-4">
            <div className="flex items-center gap-4 flex-1">
              <div className="flex-center w-20 h-20 bg-gray-100 rounded">
                <Icon icon="user-large" size="3x" className="text-gray-400" />
              </div>
              <div className="space-y-1">
                <h6>{advisorFullName}</h6>
              </div>
            </div>
            <h3 className="text-info flex-1 text-start">
              {convertAmount(totalSales)}
            </h3>
            {!!data && (
              <div className="w-fit">
                <AverageFactor
                  average={data.averageSellFactor}
                  target={data.averageTargetSellFactor}
                  className="rounded bg-gray-100 py-5 px-4"
                />
              </div>
            )}
            <button
              type="button"
              data-active={isActive}
              className="flex-center w-20 h-20 bg-gray-100 rounded data-active:bg-primary-light transition-colors"
              onClick={getData}
            >
              <Icon icon="chart-line" size="2x" className="text-primary" />
            </button>
          </section>
          {loading && <Skeleton.ImageList />}
          {isActive && !!data && (
            <section className="grid grid-cols-1 xl:grid-cols-3 gap-4">
              <div className="bg-white shadow-[0px_4px_20px_0px_#3F42541A] rounded p-4 lg:col-span-1">
                <PieChart isSeller allData={storeData} currentData={data} />
              </div>
              <div className="bg-white shadow-[0px_4px_20px_0px_#3F42541A] rounded p-4 xl:col-span-2">
                <LineChart
                  currentData={data.salesItemsPerMonth}
                  lastYearData={data.lastYearSalesItemsPerMonth}
                  height={225}
                />
              </div>
            </section>
          )}
        </td>
      </tr>
    </Fragment>
  );
}
