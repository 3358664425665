import { Icon, Dropdown } from "components";
import { useMsal } from "@azure/msal-react";
import { getLocalIdToken } from "services/service-api";
import { useNavigate } from "react-router";

const Profile = ({ profile }: any) => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const logoutHandler = async () => {
    const token = getLocalIdToken();
    const currentAccount = instance.getAccountByHomeId(
      accounts[0].homeAccountId
    );
    await instance.logoutRedirect({
      idTokenHint: token.secret,
      account: currentAccount,
      postLogoutRedirectUri: "/",
    });
    localStorage.clear();
  };
  return (
    <Dropdown onSelect={() => console.log("")}>
      <Dropdown.Toggle
        as="button"
        className="bg-gray-200 p-3 rounded-md [&>i]:hidden"
      >
        <div>
          <Icon icon="user" size="lg" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="min-w-fit">
        <div className="flex p-4 border-b border-gray-200">
          <div className="bg-gray-300 p-2 rounded-md w-[48px] h-[48px] flex justify-center items-center">
            <Icon icon="user" size="lg" />
          </div>
          <div className="h-[48px] flex justify-between flex-col pl-2">
            <p className="text-gray-700 mb-[10px] h5">
              {profile.firstName} {profile.lastName}
            </p>
            <p className="text-gray-400 text-body-2 font-medium">
              {profile.username}
            </p>
          </div>
        </div>
        <div className="pt-2 pb-4 px-4">
          {/* <Dropdown.Item>Profile</Dropdown.Item>
          <Dropdown.Item onClick={logoutHandler}>Sign out</Dropdown.Item> */}
          <ul className="text-gray-700">
            <li
              onClick={() => {
                navigate(`/profile/${profile?.userId}`);
              }}
              className="py-2 px-4 h6 hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg"
            >
              Profile
            </li>
            <li
              onClick={logoutHandler}
              className="py-2 px-4 h6 hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg"
            >
              Sign out
            </li>
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Profile;
