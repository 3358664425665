import { Button, Drawer, Text, Icon } from "components";
import Select from "react-select";
import { config } from "constant";
import { useState } from "react";
import { isEmpty } from "lodash";
import { useToggleState } from "hooks";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  configurationProviders: any;
  setIsConfigurationImported: any;
};

const ConfigurationsDrawer = ({
  isOpen,
  toggle,
  configurationProviders,
  setIsConfigurationImported,
}: drawerProps) => {
  const [selected, setSelected] = useState<any>({});
  const [importData, setImportData] = useState<any>({});
  const [loading, setLoading] = useToggleState(false);

  const onImport = () => {
    setLoading();
    const url = URLS.IMPORT_CONFIGURATION_PROVIDER;
    ServiceApi.post(url, importData)
      .then(() => {
        NotificationManager.success(
          "global.toast.import-msg",
          "global.toast.import-title"
        );
        setIsConfigurationImported();
        setLoading();
        toggle();
      })
      .catch(() => setLoading());
  };

  const onChangeConfig = (e: any) => {
    setSelected(e);
    setImportData({
      providerName: e.providerName,
      configurationVersion: e.version,
      language: e.language,
    });
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>salesManagment.sellOffSales.sellOffProduct.filters</Text>
            </span>
            <Button size="sm" variant={"light"} onClick={() => toggle()}>
              <Icon icon="times" className="mr-1" />
              <Text>global.buttons.close</Text>
            </Button>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 px-6 py-8">
          <Select
            name="provider"
            styles={config.styleSelectReact}
            options={configurationProviders}
            onChange={onChangeConfig}
          />
          {!isEmpty(selected) && (
            <>
              <p>Provider Name : {selected.providerName}</p>
              <p>Version : {selected.version}</p>
              <p>Language : {selected.language}</p>
              <p></p>
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex justify-end">
          <Button
            onClick={onImport}
            size="sm"
            disabled={isEmpty(selected)}
            loading={loading}
          >
            import
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
};

export default ConfigurationsDrawer;
