import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import {
  Image,
  Breadcrumb,
  Wrapper,
  Status,
  Tabs,
  Text,
  Button,
  Form,
  Skeleton,
  ImageUploader,
  Icon,
  Footer
} from "components";
import { useDataState, usePermission, useToggleState, useSelector } from "hooks";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import { Link } from "react-router-dom";
import { Media } from "types";
import { appPermissions } from "constant";
import { NotificationManager } from "common/notifications";

//... tabs
import General from "./tabs/general";
import UsersGroup from "./tabs/users-group";
import Store from "./tabs/store";
import AuthenticationSetting from "./tabs/authentication-setting";

export const UserContext = createContext({});
export default function Details() {
  const [isOpen, toggle] = useToggleState(false);
  const profile = useSelector((s) => s.profile);
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [headerData, setHeaderData] = useState<any>({});
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [filterStore, setFilterStore] = useState<any>(null)


  //permissions
  const {
    AS_UpdateUser,
  } = appPermissions;
  const UPDATE_PERMISSIONS = usePermission(AS_UpdateUser);

  let tabs: any = [];

  if (userId === profile?.userId) {
    tabs = [{
      label: "configurations.userManagment.userList.general",
      component: General,
    },
    {
      label: "configurations.userManagment.userList.stores",
      component: Store,
    }]
  } else {
    tabs = [{
      label: "configurations.userManagment.userList.general",
      component: General,
    },
    {
      label: "configurations.userManagment.userList.group",
      component: UsersGroup,
    },
    {
      label: "configurations.userManagment.userList.stores",
      component: Store,
    },
    {
      label: "configurations.userManagment.userList.authenticationSetting",
      component: AuthenticationSetting,
    },
    ];
  }


  const getData = () => {
    const url = URLS.B2B_USER_DETAIL_URL(userId);
    ServiceApi.get(url)
      .then(({ data }) => {
        let result = JSON.parse(JSON.stringify(data));
        setBaseData(result);
        setHeaderData(result);
        setFilterStore(data?.storeDefaultFilter?.id)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(getData, []);


  const submit = () => {
    setSubmitLoading(true);
    const url = URLS.UPDATE_B2B_USERS_URL(userId);
    const body = { ...data };
    ServiceApi.put(url, body)
      .then(() => {
        const url = URLS.UPDATE_SET_DEFAULT_FILTER_USER_URL(userId);
        const body = {
          storeId: filterStore
        }
        ServiceApi.patch(url, body).then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title"
          );
        })
        setBaseData(data);
      }).finally(() => {
        setSubmitLoading(false);
      });
  };

  const onUploadImage = (data: Media) => {
    const url = URLS.UPLOAD_USER_IMAGES(userId);
    const body = {
      url: data.url,
      type: data.type,
      thumbnailUrl: data.thumbnailUrl,
    };
    ServiceApi.patch(url, body).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
    }).finally(() => {
      getData();
    })
  };



  return (
    <Tabs activeKey={tabs[0].label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
            <div className="w-[176px] h-[176px]">
              <Image
                placeholder="user"
                onClick={() => toggle()}
                src={data.imageUrl}
                alt={data.name}
                editor
                className="w-full h-full aspect-image lg:aspect-auto cursor-pointer"
              />
            </div>
            <div className="lg:flex-1 space-y-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      <h5 className="text-heading-5 text-gray-800 font-semibold">
                        {`${headerData.firstName} ${headerData.lastName}`}
                      </h5>
                      <Status.roles id={headerData.userType} />
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                        <Icon className="text-gray-500 font-black h-4 w-4" icon="hashtag" />
                      </div>
                      <div>
                        <span className="text-body-2 text-gray-500  font-medium">{headerData.personalNumber}</span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                        <Icon className="text-gray-500 font-black h-4 w-4" icon="envelope" />
                      </div>
                      <div>
                        <span className="text-body-2 text-gray-500 font-medium">{headerData.emailAddress}</span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                        <Icon className="text-gray-500 font-black h-4 w-4" icon="mobile" />
                      </div>
                      <div>
                        <span className="text-body-2 text-gray-500 font-medium">{headerData.mobileNumber}</span>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div>
                        <Icon className="text-gray-500 font-black h-4 w-4" icon="users-line" />
                      </div>
                      <div>
                        <span className="text-body-2 text-gray-500 font-medium">{headerData.userGroupName}</span>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e: any) => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map((e: any) => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <UserContext.Provider
              value={{
                userData: data,
                setUserData: setData,
                loading: loading,
                getData: getData,
                filterStore: filterStore,
                setFilterStore: setFilterStore
              }}
            >
              {!isEmpty(data) && createElement(e.component)}
            </UserContext.Provider>
          </Tabs.Item>
        ))}
        {UPDATE_PERMISSIONS && (
          <Footer show={!isChanged} >
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        )}
      </Form>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={data.imageUrl}
        onUpload={(data: Media) => onUploadImage(data)}
      />
    </Tabs>
  );
}
