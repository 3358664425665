import { NotificationManager } from "common/notifications";
import { Breadcrumb, Button, Icon, Tabs, Text, Wrapper } from "components";
import { useDataState, useToggleState } from "hooks";
import { createContext, createElement, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import Share from "./drawers/share";
import Info from "./info";
import AttributesTab from "./tabs/attributes";
import CategoriesTab from "./tabs/categories";
import ChannelsTab from "./tabs/channels";
import GeneralTab from "./tabs/general";
import LocationsTab from "./tabs/locations";
import MediaTab from "./tabs/media";
import PriceTab from "./tabs/price";

export const PimContext = createContext<any>({});

export default function PimProductDetails() {
  const { productId } = useParams();
  const [data, setData, setBaseData, isChanged, baseData] = useDataState<any>(
    {}
  );
  const [patchData, setPatchData] = useState({});
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useToggleState();
  const [submitLoading, setSubmitLoading] = useToggleState();
  const [share, shareToggle] = useToggleState();
  const [attributeChanged, setAttributeChanged] = useState(false);
  const tabs = [
    {
      label: "productManagement.products.Details.generalTab",
      component: GeneralTab,
    },
    {
      label: "productManagement.products.Details.priceTab",
      component: PriceTab,
    },
    {
      label: "productManagement.products.Details.attributesTab",
      component: AttributesTab,
    },
    {
      label: "productManagement.products.Details.mediaTab",
      component: MediaTab,
    },
    {
      label: "productManagement.products.Details.categoriesTab",
      component: CategoriesTab,
    },
    {
      label: "productManagement.products.Details.locationsTab",
      component: LocationsTab,
    },
    {
      label: "productManagement.products.Details.channelsTab",
      component: ChannelsTab,
    },
  ];

  const getChannels = () => {
    const url = URLS.CHANNEL_URL;
    ServiceApi.get(url)
      .then(({ data }) => {
        setChannels(data);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getPIMProduct();
    getChannels();
  }, []);

  const getPIMProduct = () => {
    setLoading();
    const url = URLS.GET_PIM_PRODUCT_BY_ID_URL(productId);
    ServiceApi.get(url)
      .then(({ data }) => {
        let newData = data;
        newData["supplierArticleNumber"]= data.productKey;
        setBaseData(data);
        setLoading();
        setPatchData({});
      })
      .catch(() => setLoading());
  };

  const onChangeData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };

  const editProduct = () => {
    setSubmitLoading();
    const url = URLS.PATCH_PIM_PRODUCT_URL(productId);
    ServiceApi.patch(url, { model: patchData })
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        getPIMProduct();
        setSubmitLoading();
      })
      .catch(() => setSubmitLoading());
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <Breadcrumb />
        <Button
          size="sm"
          variant={"white"}
          textColor="primary"
          onClick={shareToggle}
          disabled={loading}
        >
          <Icon icon="share-nodes" className="mr-2" />
          <span>
            <Text>productManagement.products.Details.shareButton</Text>
          </span>
        </Button>
      </div>
      <Tabs
        activeKey={tabs[0].label}
        checkSave={isChanged || attributeChanged}
        onSave={() => setData(baseData)}
      >
        <div className="space-y-6">
          <Wrapper>
            <Wrapper.Body className="flex justify-between">
              <Info
                productData={data}
                setData={setData}
                setPatchData={setPatchData}
                loading={loading}
                getPIMProduct={getPIMProduct}
              />
            </Wrapper.Body>
            <Wrapper.Footer className="!py-0">
              <Tabs.ButtonGroup>
                {tabs.map((e) => (
                  <Tabs.Button
                    key={e.label}
                    eventKey={e.label}
                    disabled={loading}
                  >
                    <Text>{e.label}</Text>
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
            </Wrapper.Footer>
          </Wrapper>
          {tabs.map((e) => (
            <PimContext.Provider
              value={{
                productData: data,
                setData,
                getPIMProduct,
                loading,
                channels,
                isChanged,
                editProduct,
                submitLoading,
                setAttributeChanged,
                setPatchData,
              }}
            >
              <Tabs.Item key={e.label} eventKey={e.label}>
                {createElement(e.component)}
              </Tabs.Item>
            </PimContext.Provider>
          ))}
        </div>
      </Tabs>
      <Share isOpen={share} toggle={shareToggle} id={productId} />
    </div>
  );
}
