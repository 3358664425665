import { useEffect } from "react";
import dragula from "dragula";
import "dragula/dist/dragula.css";
import rowHandle from "assets/image/rowHandle.svg";

type CardDraggableProps = {
  children: any;
  setItems?: CallableFunction;
};

function CardDraggable({ children, setItems }: CardDraggableProps) {
  useEffect(() => {
    var drake = dragula([document.getElementById("cardDrag")] as any, {
      moves: function (el: any, container: any, handle: any) {
        return handle.classList.contains("handle");
      },
    });
    drake.on("drop", (el: any, container: any, handle: any) => {
      let arr: any = [];
      for (var i = 0; i < container?.childNodes.length; ++i) {
        arr.push({ id: container?.childNodes[i]?.id, order: i + 1 });
      }
      setItems && setItems(arr);
    });
  }, []);

  return (
    <div id="cardDrag">
      {children?.map((child: any, index: string) => {
        return (
          <div
            id={child.key}
            key={child.key}
            className="flex mb-4 rounded-[12px]"
            style={{ boxShadow: "0px 4px 20px rgba(56, 71, 109, 0.09)" }}
          >
            <div
              id={child.key}
              className="handle cursor-move flex-center w-[36px] border border-gray-200 bg-gray-200 overflow-hidden  rounded-l-[10px]"
            >
              <img
                id={child.key}
                className="handle w-full object-cover"
                src={rowHandle}
                alt="t"
              />
            </div>
            {child}
          </div>
        );
      })}
    </div>
  );
}

export default CardDraggable;
