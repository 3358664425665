import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  Icon,
  NoItems
} from "components";
import { usePermission } from "hooks";
import { ServiceApi } from "services";
import { URLS } from "services";
import { Link, useSearchParams } from "react-router-dom";
import { config, appPermissions } from "constant";

type SalesTemplatesItems = {
  sellPhaseTemplateId: string;
  title: string;
  code: string;
  description: string;
};


type ListType = {
  items?: SalesTemplatesItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function SalesTemplates() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();


  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_SELL_PHASE_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config).then(({ data }) => {
      setData(data);
    }).finally(() => {
      setLoadingList(false);
    });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps


  //permissions
  const { PS_GetSellPhaseTemplate, PS_GetSellPhaseTemplateDetail, PS_CreateSellPhaseTemplate } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(PS_GetSellPhaseTemplate);
  const CREATE_PERMISSION = usePermission(PS_CreateSellPhaseTemplate);
  const GET_DETAIL_PERMISSION = usePermission(PS_GetSellPhaseTemplateDetail);


  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        {loadingList ? (
          <Skeleton.Button />
        ) : (CREATE_PERMISSION ?
          <Button
            as={Link}
            to="new"
          >
            <Icon className="mr-2" icon={"plus"} /><Text>salesManagment.sellOffSales.salesTemplates.addTemplates</Text>
          </Button>
          : null)}
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ?
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.sellPhaseTemplateId} >
                      <td className="w-[26rem]">
                        <Link to={GET_DETAIL_PERMISSION ? item.sellPhaseTemplateId : ""} className="w-fit inline-flex flex-center flex gap-2">
                          <div className="space-y-4">
                            <div><h6 className="text-heading-6 text-gray-800">{item.title}</h6></div>
                            <div><span className="text-body-2 text-gray-500 mr-1" ><Text>code:</Text></span><span className="text-body-2 text-gray-500">{item.code}</span></div>
                          </div>
                        </Link>
                      </td>
                      <td className="text-body-base text-gray-600 text-left w-[39rem]">{item.description}</td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button as={Link} to={item.sellPhaseTemplateId} variant="light" size="sm">
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) => setParams((p) => ({ ...p, pageSize: e }))}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
    </div>
  ) : (
    <>Permission Error</>
  );
}
