import { Icon, Text } from "components";
import { useMenu } from "hooks";
import { Fragment, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { isUUID } from "utils";

export default function Breadcrumb() {
  const { menu } = useMenu();
  const navigate = useNavigate();
  const location = useLocation();
  const items = useMemo(() => {
    const pathname = location.pathname;
    const pathnameItems = pathname.split("/").slice(1);
    const items: any = [];
    pathnameItems.forEach((key) => {
      const parentItems = items.at(-1)?.items ?? menu;
      if (isUUID(key)) {
        items.push({ label: "global.details" });
      } else if (key === "new") {
        items.push({ label: "global.addNew" });
      } else {
        const item = parentItems.find((e: any) => e.path === key);
        if (item) {
          items.push(item);
        } else {
          items.push({ label: key });
        }
      }
    });
    items.unshift({ label: "global.home" });
    return items.map((e: any) => e?.label);
  }, [location.pathname, menu]);
  return (
    <div className="block w-full space-y-2">
      <h3 className="text-gray-800 flex items-center">
        <Icon
          icon="chevron-left"
          size="sm"
          className="mr-2 cursor-pointer p-1 w-[14px] hover:bg-gray-300 rounded-sm"
          onClick={() => navigate(-1)}
        />
        <span onClick={() => navigate(-1)} className="cursor-pointer">
          <Text>{items.at(-1)}</Text>
        </span>
      </h3>
      <p className="text-gray-500 text-body-2 font-medium">
        {items.map((e: any) => (
          <Fragment key={e}>
            <span className="first:hidden">/</span>{" "}
            <span className="last:text-gray-800">
              <Text>{e}</Text>
            </span>{" "}
          </Fragment>
        ))}
      </p>
    </div>
  );
}
