import { Text } from "components";
import { Fragment } from "react";
import ProductsCompleteness from "./ProductsCompleteness";
import PublishedProducts from "./PublishedProducts";
import RecentActivity from "./RecentActivity";
import RecentlyAdded from "./RecentlyAdded";
import RecentlySoldSellOffProducts from "./RecentlySoldSellOffProducts";
import SellOffProducts from "./SellOffProducts";
import SoldSellOffProducts from "./SoldSellOffProducts";
import TotalPimProducts from "./TotalPimProducts";

export default function Dashboard() {
  return (
    <Fragment>
      <h3 className="text-gray-800 mb-2">
        <Text>dashboard.title</Text>
      </h3>
      <p className="text-gray-800 mb-4">
        <Text>dashboard.subTitle</Text>
      </p>
      <section className="grid grid-cols-1 xl:grid-cols-3 gap-6">
        {/* <TotalPimProducts /> */}
        {/* <RecentActivity /> */}
        {/* <RecentlyAdded /> */}
        {/* <ProductsCompleteness /> */}
        {/* <PublishedProducts /> */}
        <RecentlySoldSellOffProducts />
        <SoldSellOffProducts />
        <SellOffProducts />
      </section>
    </Fragment>
  );
}
