import {
    InputGroup,
    Text,
    Wrapper
} from "components";
import cloneDeep from "lodash/cloneDeep";
import { useContext } from "react";
import { SuppliersContext } from "..";


export default function Location() {
  const { suppliersData, setSuppliersData } = useContext<any>(SuppliersContext);

  const handleSetValue = (key: any) => {
    return (value: any) =>
      setSuppliersData((p: any) => {
        const data = cloneDeep(p);
        if (data.bankInfo !== null) {
          data.bankInfo[key] = value;
        } else {
          data.bankInfo = {
            bankName: null,
            iban: null,
            accountOwnerName: null,
            bic: null,
          }
          data.bankInfo[key] = value;
        }
        return data;
      });
  };

  return (
    <Wrapper>
      <Wrapper.Header>
        <h2 className="text-heading-2 text-gray-800 font-semibold"> <Text>productManagement.masterData.suppliers.defaultBankInfo</Text></h2>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
        <InputGroup
          label="productManagement.masterData.suppliers.bankName"
          value={suppliersData?.bankInfo?.bankName}
          setValue={handleSetValue("bankName")}
        />
        <InputGroup
          label="productManagement.masterData.suppliers.iban"
          value={suppliersData?.bankInfo?.iban}
          setValue={handleSetValue("iban")}
        />
        <InputGroup
          label="productManagement.masterData.suppliers.accountOwnerName"
          value={suppliersData?.bankInfo?.accountOwnerName}
          setValue={handleSetValue("accountOwnerName")}
        />
        <InputGroup
          label="productManagement.masterData.suppliers.bic"
          value={suppliersData?.bankInfo?.bic}
          setValue={handleSetValue("bic")}
        />
      </Wrapper.Body>
    </Wrapper>
  );
}
