import React, { ReactNode } from "react";
import Icon from "components/Icon";
import { rulesType } from "types";
import { useTranslate } from "hooks";

type chipItemsProps = {
  value?: string[];
  className?: string;
  rules?: rulesType;
  placeholder?: string;
  append?: ReactNode;
  prepend?: ReactNode;
  onClickDeleteItem?: (val: any) => void;
  onClickDeleteItems?: () => void;
  onClick?: (val: any) => void;
};

export default function ChipItems({
  value,
  className,
  placeholder,
  rules = [],
  append = null,
  prepend = null,
  onClickDeleteItem = () => { },
  onClickDeleteItems = () => { },
  onClick = () => { },
}: chipItemsProps) {
  const translate = useTranslate();
  const handleClick = (e: chipItemsProps) => {
    onClick(e);
  };

  return (
    <React.Fragment>
      <div
        className={`input-box text-heading-5 text-gray-700 font-normal relative row items-stretch bg-gray-100 border border-gray-100 rounded-md [&>textarea]:min-h-[14rem] [&>textarea]:py-2 [&>textarea]:leading-5 [&>*]:min-w-0 focus-within:border-gray-500 group-[.input-group.error]:border-danger [&>*:first-child]:rounded-l-md [&>*:last-child]:rounded-r-md transition-colors w-full ${className}`}
      >
        {prepend}
        <div
          className="form-control flex-1 gap-8 px-4 py-2"
          onClick={(e: any) => handleClick(e)}
        >
          {!!placeholder ? translate(placeholder) || "" : ""}
          {value &&
            value?.map((item: any) => {
              return (
                <div
                  key={item.id}
                  onClick={(e: any) => {
                    handleClick(e);
                  }}
                  className={`inline-block m-0.5 align-middle px-2 py-1 gap-8 font-medium rounded-md text-primary bg-primary-light text-body-2`}
                >

                  <span>{item.name}</span>
                  <span onClick={() => onClickDeleteItem(item.id)}>
                    <Icon
                      className="text-gray-700 ml-2 cursor-pointer"
                      icon={"times"}
                    />
                  </span>
                </div>
              );
            })}
        </div>
        {value?.length ? (
          <span
            className="gap-8 px-2 py-3"
            onClick={() => onClickDeleteItems()}
          >
            <Icon className="text-danger ml-2 cursor-pointer" icon={"times"} />
          </span>
        ) : (
          <></>
        )}
        <div
          className="gap-8 px-2 py-3 mr-1"
          onClick={(e: any) => handleClick(e)}
        >
          {append}
        </div>
      </div>
      {/* {!!rules.length && (
        <i className="bi bi-asterisk input-group-text text-xs text-secondary" />
      )} */}
    </React.Fragment>
  );
}
