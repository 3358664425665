import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NotificationManager from "./NotificationManager";
import Notifications from "./Notifications";
import "./notification.scss";

const NotificationContainer = (props) => {
  const [notifications, setNotifications] = useState([]);
  // constructor(props) {
  //   super(props);
  //   NotificationManager.addChangeListener(this.handleStoreChange);
  //   this.state = {
  //     notifications: [],
  //   };
  // }

  // componentWillUnmount = () => {
  //   NotificationManager.removeChangeListener(this.handleStoreChange);
  // };

  useEffect(() => {
    NotificationManager.addChangeListener(handleStoreChange);
    return () => {
      NotificationManager.removeChangeListener(handleStoreChange);
    };
  });

  const handleStoreChange = (notifications) => {
    setNotifications([...notifications]);
  };

  const handleRequestHide = (notification) => {
    NotificationManager.remove(notification);
  };

  const { enterTimeout, leaveTimeout } = props;

  return (
    <Notifications
      enterTimeout={enterTimeout}
      leaveTimeout={leaveTimeout}
      notifications={notifications}
      onRequestHide={handleRequestHide}
    />
  );
};

NotificationContainer.propTypes = {
  enterTimeout: PropTypes.number,
  leaveTimeout: PropTypes.number,
};

NotificationContainer.defaultProps = {
  enterTimeout: 400,
  leaveTimeout: 400,
};

export default NotificationContainer;
