import { salesChecklist } from "./sales-check-list";
import { salesTemplates } from "./sales-templates";
import { preparationPhase } from "./preparation-phase";
import { sellOffProduct } from "./sell-off-product";
import { sellOffCommissions } from "./sell-off-commissions";

export const sellOffSales = {
  salesChecklist,
  salesTemplates,
  preparationPhase,
  sellOffProduct,
  sellOffCommissions
};
