import { NotificationManager } from "common/notifications";
import {
  Breadcrumb,
  Button,
  Icon,
  Image,
  Skeleton,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "components";
import { useToggleState,useConverter } from "hooks";
import { isEmpty } from "lodash";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import { checkPriceAvailability, classNames } from "utils";
import PriceTemplate from "../List/PriceTemplate";
import ChangePhaseModal from "../sell-off-drawers/ChangePhase";
import ClosePhaseModal from "../sell-off-drawers/ClosePhase";
import EditImageDrawer from "../sell-off-drawers/EditImageDrawer";
import EditProductDrawer from "../sell-off-drawers/EditProductDrawer";
import StartPreparationPhaseModal from "../sell-off-drawers/StartPrearationPhase";
import WithdrawModal from "../sell-off-drawers/Withdraw";
import DeleteModal from "../sell-off-drawers/delet-modal";
import SoldModal from "../sell-off-drawers/sold";
import OverViewTab from "./Overview";
import SaleProccessTab from "./SaleProccess";

export const SellOffProductDetailsContext = createContext<any>({});

const SellOffProductDetails = () => {
  const { convertAmount } = useConverter();
  const [startPrePhase, setStartPrePhase] = useToggleState();
  const [changePhase, setChangePhase] = useToggleState();
  const [closePhase, setClosePhase] = useToggleState();
  const [soldProduct, setSoldProduct] = useToggleState();
  const [withdraw, setWithdraw] = useToggleState();
  const [confirmDelet, setConfirmDelete] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();

  const tabs = [
    {
      label: "salesManagment.sellOffSales.sellOffProduct.overview",
      component: OverViewTab,
    },
    {
      label: "salesManagment.sellOffSales.sellOffProduct.saleProccess",
      component: SaleProccessTab,
    },
    // {
    //   label: "History",
    //   component: <>History</>,
    // },
  ];

  const navigate = useNavigate();

  const { store, productId } = useParams();
  const [editImages, editImageToggle] = useToggleState();
  const [editProductOpen, editProductToggle] = useToggleState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();

  const getInfoData = () => {
    setLoading(true);
    const url = URLS.GET_DETAILS_BASIC_INFO(productId);
    ServiceApi.get(url).then(({ data }) => {
      const url = URLS.GET_DETAILS_STATUS_INFO(productId);
      ServiceApi.get(url).then((response) => {
        setData({ ...data, ...response.data });
        setLoading(false);
      });
    });
  };

  const getPhaseState = (item: any) => {
    const isSold = item?.isSold;
    const active = item?.currentPhaseDay;

    return (
      <div className="text-heading-6">
        {item?.isClosed ? (
          <Status.SellOff id="salesManagment.sellOffSales.sellOffProduct.closed" />
        ) : isSold ? (
          <Status.SellOff id="salesManagment.sellOffSales.sellOffProduct.sold" />
        ) : active ? (
          <>
            <Status.SellOff
              id={"salesManagment.sellOffSales.sellOffProduct.active"}
            />{" "}
            {item?.changePhaseRequired && (
              <Status.SellOff
                id={
                  "salesManagment.sellOffSales.sellOffProduct.needPhaseChange"
                }
              />
            )}
            {item?.closePhaseRequired && (
              <Status.SellOff
                id={"salesManagment.sellOffSales.sellOffProduct.needClose"}
              />
            )}
          </>
        ) : (
          <Status.SellOff id="salesManagment.sellOffSales.sellOffProduct.preparation" />
        )}
      </div>
    );
  };

  useEffect(getInfoData, []);

  const hiddenClassName = classNames({
    hidden: data?.isClosed || data?.isSold,
  });

  const onDelete = (id: string) => {
    setDeleteLoading();
    const url = URLS.DELETE_PRODUCT_URL(id);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        setDeleteLoading();
        setConfirmDelete();
        navigate(-1);
      })
      .catch(() => setDeleteLoading());
  };

  return (
    <>
      <Tabs activeKey={tabs[0].label}>
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <div>
              <Breadcrumb />
            </div>
            {(data && data?.isSold) || data?.isClosed ? (
              <Button
                size="sm"
                variant={"white"}
                textColor="danger"
                disabled={loading}
                onClick={() => setConfirmDelete()}
              >
                <Icon icon={"trash"} className="mr-2" />
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.deleteProduct
                </Text>
              </Button>
            ) : !isEmpty(data) && !data?.currentPhaseDay ? (
              <Button size="sm" onClick={() => setStartPrePhase()}>
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.startProccess
                </Text>
              </Button>
            ) : data?.changePhaseRequired ? (
              <Button
                size="sm"
                variant={"warning"}
                onClick={() => setChangePhase()}
              >
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.changePhase
                </Text>
              </Button>
            ) : data?.closePhaseRequired ? (
              <Button
                size="sm"
                variant={"danger"}
                onClick={() => setClosePhase()}
              >
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.closeProccess
                </Text>
              </Button>
            ) : null}
          </div>
          <Wrapper>
            <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
              <Image
                //   onClick={() => toggle()}
                src={
                  data?.assets?.filter((image: any) => image.order === 1)[0]
                    ?.url
                }
                alt={""}
                className="w-full aspect-image lg:w-80 lg:h-40 lg:aspect-auto"
              />
              {!loading && (
                <div className="flex justify-between w-full h-full ">
                  <div className="flex flex-col gap-y-2 text-gray-500 space-y-2 text-body-2 font-medium">
                    <h6 className="text-heading-5 space-x-2 flex items-center">
                      <Icon
                        icon="edit"
                        className={`mr-2 text-primary cursor-pointer ${hiddenClassName}`}
                        onClick={() => {
                          editProductToggle();
                        }}
                      />
                      {data?.title}
                      {getPhaseState(data)}
                    </h6>
                    <p>
                      <Text>
                        salesManagment.sellOffSales.sellOffProduct.articleNumber
                      </Text>
                      : #{data?.articleNumber}
                    </p>
                    <p>{data?.productFamilyTranslates[0].name}</p>
                    <p>
                      <Icon icon="location-dot" size="xl" className="mr-1" />{" "}
                      {store} / {data?.location}
                    </p>
                    <div className="flex gap-2">
                      <p className="flex gap-2 items-center">
                        {data?.brandLogoUrl && (
                          <Image
                            src={data?.brandLogoUrl}
                            className="w-14 h-6 border-none"
                          />
                        )}
                        {data?.brandName}
                      </p>
                      <p className="flex gap-2 items-center">
                        {data?.supplierLogoUrl && (
                          <Image
                            src={data?.supplierLogoUrl}
                            className="w-14 h-6 border-none"
                          />
                        )}
                        {data?.supplierName}
                      </p>
                    </div>
                  </div>
                  <div className="p-4 space-y-4 bg-gray-100 rounded min-w-[230px]  flex flex-col">
                    <PriceTemplate
                      inDetails
                      item={data}
                      className="space-y-2"
                    />
                    {data?.isClosed ? <Button
                      className="w-full cursor-default"
                      variant={"white"}
                      textColor="gray"
                    >
                      <Text>
                        salesManagment.sellOffSales.sellOffProduct.closed
                      </Text>
                    </Button> :
                      data?.isSold ? (
                        <Button
                          className="w-full"
                          variant={"warning"}
                          onClick={() => setWithdraw()}
                        >
                          <Text>
                            salesManagment.sellOffSales.sellOffProduct.withdraw
                          </Text>
                        </Button>
                      ) : (
                        <Button
                          className="w-full"
                          disabled={
                            !data?.currentPhaseDay ||
                            data?.isClosed ||
                            data?.isSold
                          }
                          onClick={() => setSoldProduct()}
                        >
                          <Text>
                            salesManagment.sellOffSales.sellOffProduct.sold
                          </Text>
                        </Button>
                      )
                    }
                  </div>
                </div>
              )}
              {loading && (
                <div className="lg:flex-1 space-y-4">
                  <Fragment>
                    <Skeleton.Input />
                    <Skeleton.Input />
                  </Fragment>
                </div>
              )}
            </Wrapper.Body>
            <Wrapper.Footer className="!py-0">
              <Tabs.ButtonGroup>
                {tabs.map((e) => (
                  <Tabs.Button key={e.label} eventKey={e.label}>
                    <Text>{e.label}</Text>
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
            </Wrapper.Footer>
          </Wrapper>
          {loading
            ? [1, 2, 3, 4].map(() => <Skeleton.List />)
            : tabs.map((e) => (
              <SellOffProductDetailsContext.Provider
                value={{
                  data,
                  editImageToggle,
                  getData: getInfoData,
                  setChangePhase,
                  setStartPrePhase,
                  setClosePhase,
                }}
              >
                <Tabs.Item key={e.label} eventKey={e.label}>
                  {createElement(e.component)}
                </Tabs.Item>
              </SellOffProductDetailsContext.Provider>
            ))}
        </div>
      </Tabs>
      <EditImageDrawer
        isOpen={editImages}
        toggle={editImageToggle}
        data={data}
        onComplete={getInfoData}
      />
      <EditProductDrawer
        isOpen={editProductOpen}
        data={data}
        toggle={editProductToggle}
        getData={getInfoData}
      />
      <StartPreparationPhaseModal
        isOpen={startPrePhase}
        toggle={setStartPrePhase}
        getData={getInfoData}
        productId={data?.sellOffProductId}
      />
      <ChangePhaseModal
        isOpen={changePhase}
        toggle={setChangePhase}
        getData={getInfoData}
        productId={data?.sellOffProductId}
      />
      <ClosePhaseModal
        isOpen={closePhase}
        toggle={setClosePhase}
        getData={getInfoData}
        id={data?.sellOffProductId}
      />
      <SoldModal
        isOpen={soldProduct}
        toggle={setSoldProduct}
        getData={getInfoData}
        id={data?.sellOffProductId}
        data={data}
      />
      <WithdrawModal
        isOpen={withdraw}
        toggle={setWithdraw}
        getData={getInfoData}
        id={data?.sellOffProductId}
        data={data}
      />
      <DeleteModal
        isOpen={confirmDelet}
        loading={deleteLoading}
        toggle={setConfirmDelete}
        id={data?.sellOffProductId}
        selectedName={data?.title}
        onConfirm={onDelete}
      />
    </>
  );
};

export default SellOffProductDetails;
