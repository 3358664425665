export const importProduct = {
  importProduct: "Import Product",
  productDetails: "Product Details",
  article: "Article",
  articleNumber: "Article Number",
  productFamily: "Product Family",
  created: "Created",
  modified: "Modified",
  completeness: "Completeness",
  keyword: "Keyword",
  productCategory: "Product Category",
  status: "Status",
  brand: "Brand",
  suppliers: "Suppliers",
  program: "Program",
  store: "Store",
  priceRange: "Price Range",
  to: "To",
  from: "From",
  bestellnrLieferantArtikelVersion: "bestellnrLieferantArtikelVersion",
  imported: "Imported",
  version: "Version",
  language: "Language",
  providerName: "Provider Name",
  productTitle: "Product Title",
  basicInfo: "Basic Info",
  price: "Price",
  shortDescription: "Short Description",
  description: "Description",
  media: "Media",
  thisProductWasImportedAt: "This product was imported at",
  viewProduct: "View Product",
  productAddedSuccessfully: "Product Added Successfully",
  importAgain: "Import Again",
};
