import { Fragment, useEffect, useState, useContext } from "react";
import {
  Wrapper,
  Icon,
  Text,
  InputGroup,
  Button,
  MultiSelect,
} from "components";
import { AddSalesTemplatesContext } from "..";
import cloneDeep from "lodash/cloneDeep";
import { rules } from "constant";
import { URLS } from "services";
import { ServiceApi } from "services";
export default function Phase() {
  const { data, setData } = useContext<any>(AddSalesTemplatesContext);
  const [checkListItems, setCheckListItems] = useState([])
  const [params] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
  });
  useEffect(() => {
    setCheckListItems([]);
    const url = URLS.GET_CHECK_LIST_URL;
    const config = { params };
    ServiceApi.get(url, config).then(({ data }) => {
      let arr: any = []
      data.items.forEach((e: any) => {
        arr.push({ id: e.label, name: e.label, code: e.checkListItemId })
      })
      setCheckListItems(arr);
    });
  }, [])


  const handleSetValue = (index: string, key: any) => {
    return (value: any) =>
      setData((p: any) => {
        const data = cloneDeep(p);
        data.sellPhases[index][key] = value;
        return data;
      });
  };
  const removeItem = (index: any) => {
    return () =>
      setData((p: any) => {
        const data = cloneDeep(p);
        data.sellPhases.splice(index, 1);
        return data;
      });
  };
  const addPhase = () => {
    const item = {
      title: "",
      value: "",
      discount: "",
      duration: "",
      sellerCommission: "",
      description: "",
      checkListItemIds: []
    };

    setData((p: any) => {
      const data = cloneDeep(p);
      data.sellPhases.push(item);
      return data;
    });
  };

  return (
    <Fragment>
      {data.sellPhases?.map((item: any, index: any) => {
        return <Fragment key={index}>
          <div className="flex justify-between w-full">
            <div className="flex flex-center gap-6">
              <h4><Text>salesManagment.sellOffSales.salesTemplates.phase</Text></h4>
              <Button
                type="button"
                size="sm"
                variant="danger"
                light
                onClick={removeItem(index)}
              >
                <Icon icon={"trash"} />
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-start">
            <InputGroup
              label="salesManagment.sellOffSales.salesTemplates.phaseName"
              value={item?.title}
              setValue={handleSetValue(index, "title")}
              rules={rules.required}
            />
            <InputGroup
              label="salesManagment.sellOffSales.salesTemplates.discount"
              value={item?.discount}
              setValue={(discount) => {
                if (discount >= 0 && discount <= 100) {
                  setData((p: any) => {
                    const data = cloneDeep(p);
                    data.sellPhases[index]["discount"] = discount;
                    return data;
                  });
                }
              }}
              rules={rules.discount}
              keyfilter="pnum"
              append={<h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">%</h5>}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-start">
            <InputGroup
              type="number"
              label="salesManagment.sellOffSales.salesTemplates.duration"
              value={item?.duration}
              setValue={handleSetValue(index, "duration")}
              rules={rules.required}
              append={<h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">Day</h5>}
            />
            <InputGroup
              label="salesManagment.sellOffSales.salesTemplates.sellerCommission"
              value={item?.sellerCommission}
              setValue={(sellerCommission) => {
                if (sellerCommission >= 0 && sellerCommission <= 100) {
                  setData((p: any) => {
                    const data = cloneDeep(p);
                    data.sellPhases[index]["sellerCommission"] = sellerCommission;
                    return data;
                  });
                }
              }}
              rules={rules.discount}
              keyfilter="pnum"
              append={<h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">%</h5>}
            />
          </div>
          <InputGroup
            as="short-textarea"
            label="salesManagment.sellOffSales.salesTemplates.shortDescription"
            value={item?.description}
            setValue={handleSetValue(index, "description")}
          />
          {checkListItems.length !== 0 ? index !== 0 && <MultiSelect
            label="salesManagment.sellOffSales.salesTemplates.checkListItems"
            items={checkListItems}
            value={item?.checkListItems}
            setValue={(value) => {
              setData((p: any) => {
                const data = cloneDeep(p);
                data.sellPhases[index]["checkListItemIds"] = value.map((val: any) => val.code);
                return data;
              });
            }}
          /> : ""}
        </Fragment>
      })
      }
      <Wrapper.Section className="w-full h-[52px] bg-primary-light border-primary text-center py-[17px] courser-pointer cursor-pointer mb-4" >
        <div className="w-full" onClick={() => {
          addPhase();
        }}>
          <h4 className="text-gray-800">
            <Icon className="mr-2 text-primary" icon={"plus"} />
            <Text>salesManagment.sellOffSales.salesTemplates.addPhase</Text>
          </h4>
        </div>
      </Wrapper.Section>
    </Fragment>
  );
}
