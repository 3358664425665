import { stores } from "./stores";
import { legalEntities } from "./legal-entities";
import { profile } from "./profile";
import { customers } from "./customers";
import { employees } from "./employees";

export const company = {
   stores: stores,
   legalEntities: legalEntities,
   profile: profile,
   customers: customers,
   employees: employees
}