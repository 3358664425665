import { Fragment, useContext } from "react";
import {
  InputGroup,
  Select,
  DatePicker
} from "components";
import { AddUserListContext } from "..";
import { rules } from "constant";

export default function Basic() {
  const { data, setData } = useContext<any>(AddUserListContext);

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };


  return (
    <Fragment>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8" >
        <InputGroup
          label="configurations.userManagment.userList.firstName"
          value={data.firstName}
          setValue={handleSetData("firstName")}
          rules={rules.required}
        />
        <InputGroup
          label="configurations.userManagment.userList.lastName"
          value={data.lastName}
          setValue={handleSetData("lastName")}
          rules={rules.required}
        />
        <InputGroup
          label="configurations.userManagment.userList.personalNumber"
          value={data.personalNumber}
          setValue={handleSetData("personalNumber")}
        />
        <InputGroup
          label="configurations.userManagment.userList.emailAddress"
          value={data.emailAddress}
          setValue={handleSetData("emailAddress")}
          rules={rules.emailRequired}
        />
        <Select
          label="configurations.userManagment.userList.gender"
          items={[
            { id: 0, name: "Rather not say" },
            { id: 1, name: "Male" },
            { id: 2, name: "Female" }
          ]}
          value={data.gender}
          setValue={handleSetData("gender")}
        />
        <DatePicker
          label="configurations.userManagment.userList.dateOfBirth"
          showYearDropdown
          showMonthDropdown
          value={data?.dateOfBirth}
          onChange={handleSetData("dateOfBirth")}
          rules={rules.required}
        />
        <InputGroup
          label="configurations.userManagment.userList.phoneNumber"
          value={data.phoneNumber}
          setValue={handleSetData("phoneNumber")}
          rules={rules.required}
        />
        <InputGroup
          label="configurations.userManagment.userList.mobileNumber"
          value={data.mobileNumber}
          setValue={handleSetData("mobileNumber")}
          rules={rules.required}
        />
      </div>
    </Fragment>
  );
}
