import { createClassNames } from "./classname";
import { PanelMenu } from "components/panelmenu/PanelMenu";
import { useMenu, useSelector } from "hooks";
import { Icon } from "components";
import Logo from "assets/logos/logo.svg";
const Sidebar = ({ isActive, setIsActive }) => {
  const profile = useSelector((s) => s.profile);

  const { sidebar, logo, menuHeader, drawer } = createClassNames(isActive);
  const { menu } = useMenu();

  return (
    <div className={sidebar} id="sidbar-b2b">
      <div className={menuHeader}>
        {isActive && profile?.companyAvatarThumbnailUrl ? (
          <img
            className={`${logo} max-h-[40px]`}
            src={isActive ? profile?.companyAvatarThumbnailUrl : Logo}
            alt="moebelhof"
          />
        ) : (
          isActive && (
            <div className="text-white ml-1">{profile?.companyName}</div>
          )
        )}
        <button
          className={drawer}
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          <Icon icon={"chevrons-left"} size="sm" />
        </button>
      </div>
      <aside className="text-gray-200 overflow-auto">
        <PanelMenu model={menu} isActive={isActive} setIsActive={setIsActive} />
      </aside>
    </div>
  );
};
export default Sidebar;
