import {
  createContext,
  Fragment,
  useContext,
  useState,
  ReactNode,
} from "react";
type tabContextType = {
  active: string | null;
  setActive: (val: string) => void;
  onSave?: () => void;
  checkSave?: boolean;
};
type tabsProps = {
  children: ReactNode;
  activeKey?: string | null;
  checkSave?: boolean;
  onSave?: () => void;
};
type tabWrapperProps = {
  children: ReactNode;
};
type tabButtonProps = {
  children: ReactNode;
  eventKey: string;
  disabled?: boolean;
};
type tabItemProps = {
  children: ReactNode;
  eventKey: string;
};
const TabContext = createContext({} as tabContextType);
function Tabs({
  activeKey = null,
  children,
  checkSave = false,
  onSave,
}: tabsProps) {
  const [active, setActive] = useState(activeKey);
  return (
    <TabContext.Provider value={{ active, setActive, checkSave, onSave }}>
      {children}
    </TabContext.Provider>
  );
}
function TabButtonGroup({ children }: tabWrapperProps) {
  return (
    <div className="w-full flex items-center gap-8 overflow-auto">
      {children}
    </div>
  );
}

function TabButton({ eventKey, children, disabled }: tabButtonProps) {
  const { active, setActive, checkSave, onSave } = useContext(TabContext);
  const isActive = active === eventKey;
  const handleClick = () => {
    if (checkSave) {
      const response = window.confirm(
        "Are your sure you want discard your changes?"
      );
      if (response) {
        setActive(eventKey);
        onSave && onSave();
      }
    } else {
      setActive(eventKey);
    }
  };
  return (
    <button
      disabled={disabled}
      data-active={isActive}
      type="button"
      onClick={handleClick}
      className="text-heading-5 text-gray-500 border-b-2 border-transparent px-1 py-4 data-active:text-primary data-active:border-b-primary transition-colors whitespace-nowrap min-w-fit font-semibold"
    >
      {children}
    </button>
  );
}
function TabItem({ eventKey, children }: tabItemProps) {
  const { active } = useContext(TabContext);
  const isActive = active === eventKey;
  return <Fragment>{isActive && children}</Fragment>;
}
Tabs.ButtonGroup = TabButtonGroup;
Tabs.Button = TabButton;
Tabs.Item = TabItem;
export default Tabs;
