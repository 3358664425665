import { NotificationManager } from "common/notifications";
import {
    Button,
    DeleteModal,
    Drawer,
    Form,
    Icon,
    InputGroup,
    Select,
    Skeleton,
    Text,
    Wrapper,
} from "components";
import SearchAddress from "components/SearchAddress";
import { countries, rules } from "constant";
import { useToggleState } from "hooks";
import { isEmpty } from "lodash";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";

type ModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  person: any;
  id: string;
  getData: any;
};

type AddressType = {
  city?: string | null;
  country?: string | null;
  houseNo?: string | null;
  postalCode?: string | null;
  state?: string | null;
  street?: string | null;
};

export default function ContactPersonDrawer({
  toggle,
  isOpen,
  person,
  id,
  getData,
}: ModalType) {
  const [data, setData] = useState<any>({});
  const formRef = useRef<any>();
  const [loading, setLoading] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);

  useEffect(() => {
    setData(person);
  }, [person]);

  const onChangeHandler = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };
  const onChangeAddressHandler = (key: any) => {
    return (value: any) =>
      setData((p: any) => ({ ...p, address: { ...p.address, [key]: value } }));
  };

  const searchAddressHandler = (address: AddressType) => {
    setData((p: any) => {
      return {
        ...p,
        address: {
          street: address.street,
          city: address.city,
          houseNumber: address.houseNo,
          postalCode: address.postalCode,
          country: address.country,
          state: address.state,
        },
      };
    });
    return {};
  };

  const updateContactPerson = (formData: any) => {
    setLoading();
    const url = URLS.EDIT_DELETE_CONTACT_PERSON(id, formData.id);
    ServiceApi.put(url, formData)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        toggle();
        getData();
        setLoading();
      })
      .catch(() => {
        setLoading();
      });
  };
  const createContactPerson = (formData: any) => {
    setLoading();
    const url = URLS.ADD_CONTACT_PERSON(id);
    ServiceApi.post(url, formData)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        toggle();
        getData();
        setLoading();
      })
      .catch(() => {
        setLoading();
      });
  };

  const onDeleteHandler = () => {
    setDeleteLoading();
    const url = URLS.EDIT_DELETE_CONTACT_PERSON(id, data.id);
    ServiceApi.delete(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        toggleDeleteModal();
        toggle();
        getData();
        setDeleteLoading();
      })
      .catch(() => {
        setDeleteLoading();
      });
  };

  const departmentItems = [
    {
      id: 0,
      name: "General",
    },
    {
      id: 1,
      name: "Sales",
    },
    {
      id: 2,
      name: "Logistics",
    },
    {
      id: 3,
      name: "Marketing",
    },
    {
      id: 4,
      name: "Support",
    },
    {
      id: 10,
      name: "Other",
    },
  ];

  const onSubmitForm = (formData: any, condition?: boolean) => {
    if (condition) {
      createContactPerson(formData);
    } else {
      updateContactPerson(formData);
    }
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data, isEmpty(person));
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} size={"xl"}>
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pt-6 pr-4">
            <div className="flex items-center justify-between">
              <h2 className="text-gray-800 text-heading-2">
                <Text>productManagement.masterData.suppliers.contactPerson</Text>
              </h2>
              <div>
                {!isEmpty(person) && (
                  <Button
                    type="button"
                    variant="danger"
                    size="sm"
                    light
                    className="ml-2"
                    onClick={() => toggleDeleteModal()}
                  >
                    <Icon className="mr-2" icon={"trash"} />
                    <Text>global.buttons.delete</Text>
                  </Button>
                )}
                <Button
                  type="button"
                  variant="light"
                  size="sm"
                  className="ml-2"
                  onClick={() => toggle()}
                >
                  <Icon className="mr-2" icon={"times"} />
                  <Text>global.buttons.close</Text>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4 pr-4">
            <div className="grid grid-cols-2 gap-4">
              {loading ? (
                <>
                  {/* loading */}
                  <div className="space-y-4">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((item: number) => (
                      <Skeleton.Input key={item} />
                    ))}
                  </div>
                  <div className="space-y-4">
                    {[1, 2, 3, 4].map((item: number) => (
                      <Skeleton.Input key={item} />
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <Form
                    ref={formRef}
                    onSubmit={onSubmitForm}
                    id={"create-modal"}
                  >
                    <div className="space-y-4">
                      <InputGroup
                        label="productManagement.masterData.suppliers.firstName"
                        value={data?.firstName}
                        setValue={onChangeHandler("firstName")}
                        rules={rules.required}
                      />
                      <InputGroup
                        label="productManagement.masterData.suppliers.lastName"
                        value={data?.lastName}
                        setValue={onChangeHandler("lastName")}
                        rules={rules.required}
                      />
                      <Select
                        label="productManagement.masterData.suppliers.department"
                        items={departmentItems}
                        value={data?.department}
                        setValue={onChangeHandler("department")}
                        rules={rules.required}
                      />
                      <InputGroup
                        label="productManagement.masterData.suppliers.emailAddress1"
                        value={data?.emailAddress1}
                        setValue={onChangeHandler("emailAddress1")}
                        rules={rules.emailAddress}
                      />
                      <InputGroup
                        label="productManagement.masterData.suppliers.emailAddress2"
                        value={data?.emailAddress2}
                        setValue={onChangeHandler("emailAddress2")}
                        rules={rules.emailAddress}
                      />
                      <InputGroup
                        label="productManagement.masterData.suppliers.phone1"
                        value={data?.phoneNumber1}
                        setValue={onChangeHandler("phoneNumber1")}
                      />
                      <InputGroup
                        label="productManagement.masterData.suppliers.phone2"
                        value={data?.phoneNumber2}
                        setValue={onChangeHandler("phoneNumber2")}
                      />
                      <InputGroup
                        as={"short-textarea"}
                        label="productManagement.masterData.suppliers.description"
                        value={data?.description}
                        setValue={onChangeHandler("description")}
                      />
                    </div>
                  </Form>
                  <section className="space-y-8">
                    <SearchAddress setValue={searchAddressHandler} />
                    <Wrapper.Section className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 items-stretch">
                      <div className="col-span-2">
                        <InputGroup
                          value={data.address?.name}
                          label="global.locations.name"
                          setValue={onChangeAddressHandler("name")}
                        />
                      </div>
                      <InputGroup
                        value={data.address?.street}
                        label="global.locations.street"
                        setValue={onChangeAddressHandler("street")}
                      />
                      <InputGroup
                        value={data.address?.houseNumber}
                        setValue={onChangeAddressHandler("houseNumber")}
                        label="global.locations.houseNumber"
                      />
                      <InputGroup
                        value={data.address?.postalCode}
                        setValue={onChangeAddressHandler("postalCode")}
                        label="global.locations.postalCode"
                      />
                      <InputGroup
                        value={data.address?.city}
                        setValue={onChangeAddressHandler("city")}
                        label="global.locations.city"
                      />
                      <div className="col-span-2 space-y-8">
                        <Select
                          label="global.locations.country"
                          value={data.address?.country}
                          setValue={onChangeAddressHandler("country")}
                          items={countries}
                        />
                        <Select
                          label="global.locations.state"
                          value={data.address?.state}
                          setValue={onChangeAddressHandler("state")}
                          items={
                            countries.find(
                              (e) => e.id === data.address?.country
                            )?.states as []
                          }
                        />
                      </div>
                    </Wrapper.Section>
                  </section>
                </>
              )}
            </div>
          </Drawer.Body>
          <Drawer.Footer className="flex justify-end items-center">
            <Button
              type="button"
              loading={loading}
              onClick={() => {
                submit();
              }}
            >
              <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: data?.name }}
        loading={deleteLoading}
        onConfirm={onDeleteHandler}
      />
    </>
  );
}
