import MasterData from "./master-data";
import Products from "./products";
import Settings from "./settings";

const productManagement = [
  {
    path: "productManagement/master-data",
    children: [...MasterData],
  },
  {
    path: "productManagement/products",
    children: [...Products],
  },
  {
    path: "productManagement/settings",
    children: [...Settings],
  },
];

export default productManagement;
