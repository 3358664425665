export const sellOffCommissions = {
    soldPrice: "Verkaufspreis",
    articleNumber: "Artikelnummer",
    sellerCommission: "Verkäuferprovision",
    sellerCommissionAmount: "Verkäuferprovisionsbetrag",
    fastSaleCommission: "Schnellverkaufsprovision",
    keyword: "Stichwort",
    seller: "Verkäufer",
    status: "Status",
    soldDateRange: "Verkaufszeitraum",
    thisWeek: "Diese Woche",
    thisMonth: "Diesen Monat",
    lastMonth: "Letzten Monat",
    from: "Von",
    to: "Bis",
    export: "Exportieren"
}