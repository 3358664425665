import Profile from "pages/profile";

const profile = [
  {
    path: "profile/:userId",
    element: <Profile />,
  },
];

export default profile;
