import { useState, useEffect, Fragment } from "react";
import { Button, Modal, Form, InputGroup, Text, Icon } from "components";
import { rules } from "constant";
import { useNavigate } from "react-router-dom";

type inviteModalType = {
  isOpen: boolean;
  toggle?: () => void;
};

export default function InviteModal({ isOpen, toggle }: inviteModalType) {
  const [data, setData] = useState({
    keyword: "",
  });
  const navigate = useNavigate();
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setData({ keyword: "" });
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoadingButton(true);
    navigate(
      `/configurations/user-managment/users-list/Invite?search=${data?.keyword}`,
      {}
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Form onSubmit={submit} id="invate-search-modal">
        <Modal.Header className="flex flex-row">
          <h2 className="text-heading-2 text-gray-800 font-semibold">
            <Text>configurations.userManagment.userList.searchUser</Text>
          </h2>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto p-8 grid grid-cols-1 lg:grid-cols-1 gap-8 lg:gap-8">
          <InputGroup
            label="configurations.userManagment.userList.search"
            value={data.keyword}
            className="border-white"
            placeholder="Search User Name, Email ..."
            setValue={(keyword) => setData((p) => ({ ...p, keyword }))}
            rules={rules.required}
          />
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="submit" loading={loadingButton}>
            <Fragment>
              <Icon icon="search" className="mr-2" />
              <Text>configurations.userManagment.userList.searchUser</Text>
            </Fragment>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
