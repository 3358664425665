export const salesList = {
  projectNumber: "Project Number",
  projectName: "Project Name",
  contract: "Contract",
  createdAt: "Created At",
  lastUpdated: "Last updated",
  targetSellFactor: "Target Sell Factor",
  sellFactor: "Sell Factor",
  filters: "Filters",
  resetFilters: "Reset Filters",
  close: "Close",
  keyword: "Keyword",
  dateRange: "Date Range",
  thisWeek: "This Week",
  thisMonth: "This Month",
  lastMonth: "Last Month",
  from: "From",
  to: "To",
  status: "Status",
  topBuyDevices: "Top Buyvices",
  advisor: "Advisor",
  location: "Location",
  store: "Store",
  sellFactorWarning: "Sell Factor Warning",
  shippingCostWarning: "Shipping Cost Warning",
  hasContractNumber: "Has Contract Number",
  details: "Details",
  customer: "Customer",
  comments: "Comments",
  topBuy: "Top Buy",
  targetShippingCost: "Target Shipping Cost",
  shippingCost: "Shipping Cost",
  shippingCostWithoutInstallation: "Shipping Cost Without Installation",
  serviceCost: "Service Cost",
  name: "Name",
  preferredShippingDate: "Preferred Shipping Date",
  contractProcess: "Contract Process",
  contractDate: "Contract Date",
  statusNote: "Status Note",
  updatedDateTime: "Updated Date Time",
  totalAmount: "Total Amount",
  totalTarget: "Total Target",
  purchaseNet: "Purchase Net",
  yourComment: "Your Comment",
};
