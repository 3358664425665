import { ComponentProps, ReactNode, useMemo } from "react";
import Image from "./Image";
import { isEmpty } from "lodash";
type TableProps = {
  children: ReactNode;
  className?: string;
  shadow?: boolean;
};

type TableImageProps = {
  src: string | null | undefined;
  alt?: string;
  className?: string;
  placeholder?: any;
};

function Table({
  children,
  className = "",
  shadow = false,
  ...props
}: TableProps & Omit<ComponentProps<"table">, keyof TableProps>) {
  const hasChildren = !isEmpty(children);
  const classes = useMemo(() => {
    return [
      "min-w-full border-separate border-spacing-y-4 -my-4 text-center [&_tr]:rounded-xl [&_tr]:bg-white [&_tr[draggable].dragging]:opacity-25 [&_td]:p-4 [&_td:first-child]:rounded-l-xl [&_td:last-child]:rounded-r-xl [&_td:first-child]:text-left [&_td:last-child]:text-right",
      className,
      shadow && "[&_tr]:shadow-high",
    ]
      .filter(Boolean)
      .join(" ");
  }, [className, shadow]);
  return (
    <div hidden={!hasChildren} className="w-full">
      <table className={classes} {...props}>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}
function TableImage({ src = "", alt = "", className, placeholder = "" }: TableImageProps) {
  return (
    <div
      className={`${className} flex-center w-32 h-20 rounded border border-gray-200 bg-gray-200 overflow-hidden`}
    >
      <Image placeholder={placeholder} className="w-full h-full object-cover" src={src} alt={alt} />
    </div>
  );
}

function TableLogo({ src = "", alt = "", className }: TableImageProps) {
  return (
    <div
      className={`${className} flex-center w-32 h-20 rounded border border-gray-200 bg-gray-200 overflow-hidden`}
    >
      <Image className="w-full h-full" logo={true} src={src} alt={alt} />
    </div>
  );
}
function TableTouchPad() {
  return (
    <button
      type="button"
      className="inline-flex flex-center h-32 rounded-s bg-gray-200 p-2 cursor-grab"
    >
      <div className="w-fit h-fit grid grid-cols-2 gap-2">
        {[...Array(6)].fill("").map((e, i) => (
          <div key={i} className="flex-center">
            <span className="w-[2px] h-[2px] rounded-full bg-gray-400" />
          </div>
        ))}
      </div>
    </button>
  );
}
Table.Image = TableImage;
Table.Logo = TableLogo;
Table.TouchPad = TableTouchPad;
export default Table;
