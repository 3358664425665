export const configuration = {
  sellFactorTab: "VERKAUFSFAKTOR",
  toleranceTab: "TOLERANZ",
  sellFactorField: "Verkaufsfaktor",
  minimumToleranceField: "Minimale Toleranz",
  maximumToleranceField: "Maximale Toleranz",
  submitSellFactor: "Aktualisieren",
  submitTolerance: "Aktualisieren",
  updateSuccessSellFactor: "Verkaufsfaktor erfolgreich aktualisiert.",
  updateSuccessTolerance: "Toleranz erfolgreich aktualisiert."
};
