import { Text } from "components";
import { useConverter } from "hooks";
import { useMemo, useRef } from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import Percent from "./Percent";

type lineChartProps = {
  currentData: any[];
  lastYearData: any[];
  height?: number;
};

export default function LineChart({
  currentData = [],
  lastYearData = [],
  height = 300,
}: lineChartProps) {
  const { convertAmount } = useConverter();
  const tooltipRef = useRef<number>(0);
  const data = useMemo(() => {
    return lastYearData?.map((e, i) => ({
      monthNumber: e.monthNumber,

      averageSellFactor: currentData[i].averageSellFactor,
      averageSellFactorPerYear: e.averageSellFactor,

      averageTargetSellFactor: currentData[i].averageTargetSellFactor,
      averageTargetSellFactorPerYear: e.averageTargetSellFactor,

      growPercentage: currentData[i].growPercentage,
      growPercentagePerYear: e.growPercentage,

      totalSales: currentData[i].totalSales,
      totalLastYearSales: e.totalSales,
    }))??[];
  }, [currentData, lastYearData]);
  const hasData = !!data.length;
  const handleSetTooltip = (index: number) => {
    return () => (tooltipRef.current = index);
  };
  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart className="w-full h-full" data={data}>
        <defs>
          <linearGradient id="colorTotalLastYear" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor="currentColor"
              className="text-gray-400"
              stopOpacity={0.2}
            />
            <stop
              offset="95%"
              stopColor="#currentColor"
              className="text-gray-400"
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor="currentColor"
              className="text-info"
              stopOpacity={0.2}
            />
            <stop
              offset="95%"
              stopColor="#currentColor"
              className="text-info"
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        {hasData && <XAxis dataKey="monthNumber" className="text-primary" />}
        {/* <YAxis /> */}
        <Tooltip
          content={({ active, payload }) => {
            const data = payload?.at(tooltipRef.current);
            const hasData = [active, payload?.length, data].every(Boolean);
            const isPerYearData = tooltipRef.current === 0;
            if (!hasData) return null;
            return (
              <div
                // @ts-ignore: Unreachable code error
                className={`bg-white text-start shadow text-base p-4 space-y-4 ${data?.className}`}
              >
                <h6 className="text-current">
                  {convertAmount(
                    data?.payload[
                      isPerYearData ? "totalLastYearSales" : "totalSales"
                    ]
                  )}{" "}
                  {!isPerYearData && (
                    <Percent value={data?.payload.growPercentage} />
                  )}
                </h6>
                <p className="text-current">
                  <span className="relative inline-flex flex-center h-2 w-2 align-middle">
                    <span className="absolute inset-0 animate-ping rounded-full bg-current opacity-75"></span>
                    <span className="absolute inset-0 rounded-full bg-current"></span>
                  </span>{" "}
                  <Text>
                    salesManagment.kitchenSales.salesReport.targetSellFactor
                  </Text>
                  {": "}
                  {data?.payload[
                    isPerYearData
                      ? "averageTargetSellFactorPerYear"
                      : "averageTargetSellFactor"
                  ].toFixed(2)}
                </p>
                <p className="text-current">
                  <span className="relative inline-flex flex-center h-2 w-2 align-middle">
                    <span className="absolute inset-0 animate-ping rounded-full bg-current opacity-75"></span>
                    <span className="absolute inset-0 rounded-full bg-current"></span>
                  </span>{" "}
                  <Text>
                    salesManagment.kitchenSales.salesReport.averageSellFactor
                  </Text>
                  {": "}
                  {data?.payload[
                    isPerYearData
                      ? "averageSellFactorPerYear"
                      : "averageSellFactor"
                  ].toFixed(2)}
                </p>
              </div>
            );
          }}
        />
        <Area
          type="linear"
          dataKey="totalLastYearSales"
          stroke="currentColor"
          strokeWidth="2"
          fill="url(#colorTotalLastYear)"
          className="text-gray-400"
          dot={{
            stroke: "currentColor",
            strokeWidth: 6,
            r: 1,
          }}
          activeDot={{
            onMouseOver: handleSetTooltip(0),
          }}
        />
        <Area
          type="linear"
          dataKey="totalSales"
          stroke="currentColor"
          strokeWidth="2"
          fill="url(#colorTotal)"
          className="text-info"
          dot={{
            stroke: "currentColor",
            strokeWidth: 6,
            r: 1,
          }}
          activeDot={{
            onMouseOver: handleSetTooltip(1),
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
