import { createSlice } from "@reduxjs/toolkit";

const loading = createSlice({
  name: "loading",
  initialState: {
    loading: false,
  },
  reducers: {
    toggleLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export const { toggleLoading } = loading.actions;
export default loading.reducer;
