import CrmConfiguration from "pages/third-party/crm-configuration";
import ErpConfiguration from "pages/third-party/erp-configuration";
import NopCommerce from "pages/third-party/nop-commerce";
import Iwofurn from "pages/third-party/iwofurn-configuration";

const crm = [
  {
    path: "thirdParty/crm-configuration",
    element: <CrmConfiguration />,
  },
  {
    path: "thirdParty/erp-configuration",
    element: <ErpConfiguration />,
  },
  {
    path: "thirdParty/nop-commerce",
    element: <NopCommerce />,
  },
  {
    path: "thirdParty/iwofurn",
    element: <Iwofurn />,
  },
];

export default crm;
