export const customerList = {
  cimNumber: "CIM-Nummer",
  firstName: "Vorname",
  lastName: "Nachname",
  basicInfo: "Grundlegende Informationen",
  address: "Adresse",
  gender: "Geschlecht",
  birthdate: "Geburtsdatum",
  mobileNumber: "Handynummer",
  phoneNumber: "Telefonnummer",
  emailAddress: "E-Mail-Adresse",
  street: "Straße",
  houseNumber: "Hausnummer",
  postalCode: "Postleitzahl",
  city: "Stadt",
  state: "Bundesland",
  country: "Land",
  companyName: "Firma",
  company: "Firmenname",
  taxNumber: "Steuernummer",
  channels: "Kanäle",
  email: "E-Mail",
  application: "Anwendung",
  sms: "SMS",
  phone: "Telefon",
  post: "Post",
  socialMedia: "Soziale Medien",
  familyBonus: "Familienbonus",
  newsLetterSubscription: "Newsletter-Abonnement"
};