import SalesListPage from "pages/sales-managment/kitchen-sales/sales-list";
import Details from "pages/sales-managment/kitchen-sales/sales-list/details";


const SalesList = [
  {
    path: "",
    element: <SalesListPage />,
  },
  {
    path: ":projectId",
    element: <Details />,
  },
];

export default SalesList;
