import { NotificationManager } from "common/notifications";
import {
    Button,
    DeleteModal,
    Drawer,
    Icon,
    NoItems,
    Skeleton,
    Status,
    Table,
    Text,
    Wrapper
} from "components";
import { useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import Create from "./create";


export default function Attribute(props: any) {
  const [showCreate, setShowCreate] = useState<any>(false)
  const [selected, setSelected] = useState<any>();
  const [loadingList, setLoadingList] = useState(true);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [data, setData] = useState([])
  const [title, setTitle] = useState("")
  const [loadingButton, setLoadingButton] = useState(false);
  const [showButtonCreate, setShowButtonCreate] = useState<any>(true);

  const getDataAttribute = () => {
    setLoadingList(true)
    setTitle("");
    const urlPrograms = URLS.GET_PROGRAM_DETAILS(props.programId);
    ServiceApi.get(urlPrograms)
      .then(({ data }) => {
        setData(data.attributes)
        setTitle(data.title)
      }).finally(() => {
        setLoadingList(false)
      })
  };

  useEffect(() => {
    if (props.isOpen) {
      setShowCreate(false)
      getDataAttribute()
    }
  }, [props.isOpen]);// eslint-disable-line react-hooks/exhaustive-deps


  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_ATTRIBUTE(props.programId, id);
    ServiceApi.delete(url).then(() => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      props.getData();
      getDataAttribute();
      toggleDeleteModal()
      setLoadingButton(false)
    }).catch(() => setLoadingButton(false));
  };


  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            {/* <h2 className="text-gray-800 text-heading-2"><Text>productManagement.masterData.suppliers.attributes</Text></h2> */}
            <h2 className="text-gray-800 text-heading-2">{title}</h2>
            <div className="flex items-center justify-between">
              {/* {!showCreate && <Button
                type="button"
                size="sm"
                onClick={() => {
                  setShowCreate(true)
                }}
              >
                <Icon className="mr-2" icon={"plus"} /><Text>productManagement.masterData.suppliers.addAttribute</Text>
              </Button>} */}
              <Button
                type="button"
                variant="light"
                size="sm"
                className="ml-2"
                onClick={() => props.toggle()}
              >
                <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4">
          {showCreate && <Create setShowCreate={setShowCreate} setShowButtonCreate={setShowButtonCreate} getDataAttribute={getDataAttribute} getData={props.getData} programId={props.programId} toggle={props.toggle} />}
          {loadingList ? (
            <>
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
              <Skeleton.List />
            </>
          ) :
            <div>
              {showButtonCreate &&
                <Wrapper.Section className="w-full h-[52px] border-primary text-center py-[17px] courser-pointer cursor-pointer mb-4" >
                  <div className="w-full" onClick={() => {
                    setShowCreate(true);
                    setShowButtonCreate(false)
                  }}>
                    <h5 className="text-primary">
                      <Icon className="mr-2 text-primary" icon={"square-plus"} />
                      <Text>productManagement.masterData.suppliers.addAttribute</Text>
                    </h5>
                  </div>
                </Wrapper.Section>
              }
              {data?.length !== 0 ?
                <Table className="pb-2">
                  {data?.map((item: any) => {
                    return (
                      <tr key={item.code} style={{ boxShadow: "0px 4px 20px rgba(56, 71, 109, 0.09)" }} className="rounded-xl" >
                        <td>
                          <div className="space-y-2">
                            <div className="flex items-center">
                              <div className="mt-2 mr-5">
                                <h5 className="items-start text-heading-5 text-gray-800">{item.name}</h5>
                              </div>
                              <div className="mt-1">
                                <Status.RequiredAttribute id={item.isRequired} />
                              </div>
                            </div>
                            <div className="felx">
                              <span className="text-body-base text-gray-700 mr-4">{item.code}</span>
                            </div>
                            <div className="felx">
                              <span className="text-body-base text-gray-700">{item.description}</span>
                            </div>
                          </div>
                        </td>
                        <td className="align-top">
                          <Button
                            type="button"
                            size="sm"
                            variant="danger"
                            light
                            onClick={() => {
                              setSelected({ id: item.code, name: item.name });
                              toggleDeleteModal();
                            }}
                          >
                            <Icon icon={"trash"} />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
                :
                <div className="flex flex-col items-center space-y-4 w-full">
                  <NoItems />
                </div>
              }
              <DeleteModal
                isOpen={isOpenDeleteModal}
                toggle={toggleDeleteModal}
                selected={{ name: selected?.name, id: selected?.id }}
                loading={loadingButton}
                onConfirm={submitDelete}
              />

            </div>
          }
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
