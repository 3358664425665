const CATEGORIES = {
  GET_ALL_CATEGORIES: "productservice/api/productcategories",
  ADD_CATEGORY: "productservice/api/productcategories",
  GET_CATEGORY_BY_ID: (id) => `productservice/api/productcategories/${id}`,
  EDIT_CATEGORY_TRANSLATES: (id) =>
    `productservice/api/productcategories/${id}/translates`,
  ADD_CATEGORY_TO_CHANNEL: (id) =>
    `productservice/api/productcategories/${id}/addchannel`,

  DELETE_CATEGORY: (id) => `productservice/api/productcategories/${id}`,
  GET_CHANNEL_CATEGORIES: (id) =>
    `productservice/api/channelproductcategories/productcategorypublishedlist?productCategoryId=${id}`,
  PUBLISH_CATEGORY: (productCategoryId) =>
    `productservice/api/productcategories/${productCategoryId}/publish`,
  MANAGE_CATEGORY_CHANNLES: (productCategoryId) =>
    `productservice/api/productcategories/${productCategoryId}/nonpublishablechannel`,
  GET_CATEGORY_IN_CHANNLES: `productservice/api/channelproductcategories/getbychannelcode`,

  UNPUBLISH_CATEGORY: (productCategoryId) =>
    `productservice/api/productcategories/${productCategoryId}/unpublish`,

  GET_ROOT_CATEGORIES: "productservice/api/productcategories/roots",
  GET_CATEGORY_WITH_CHILDREN: (categoryId) =>
    `productservice/api/productcategories/categoryid?categoryid=${categoryId}`,

  GET_CATEGORY_PATH: (id) => `productservice/api/productcategories/${id}/path`,
};

export default CATEGORIES;
