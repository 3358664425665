import AllProductsList from "pages/product-managment/products/all-products";
import PimProductDetails from "pages/product-managment/products/all-products/details";

const AllProducts = [
  {
    path: "",
    element: <AllProductsList />,
  },
  {
    path: ":productId",
    element: <PimProductDetails />,
  },
];

export default AllProducts;
