import { NotificationManager } from "common/notifications";
import {
  Button,
  DeleteModal,
  HandleName,
  Icon,
  InputGroup,
  NoItems,
  Skeleton,
  Status,
  Table,
  Text,
  Wrapper
} from "components";
import { appPermissions, config } from "constant";
import { usePermission, useToggleState } from "hooks";
import {
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import AsyncSelect from "react-select/async";
import { ServiceApi, URLS } from "services";
import { AttributesTypes, onFilter } from "utils";
import { AttributesContext } from "..";



type AttributesItems = {
  attributeTemplateId: string;
  title: string;
  code: string;
  type: string;
  perChannel: any;
  perLanguage: any;
  isRequired: any;
};

export default function Attributes() {
  const { attributesGroupData, attributeGroupTemplateId } = useContext<any>(AttributesContext);
  const [attributes, setAttributes] = useState<AttributesItems[]>()
  const [attributesList, setAttributesList] = useState<AttributesItems[]>()
  const [loadingList, setLoadingList] = useState(false)
  const [selected, setSelected] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const [selectedName, setSelectedName] = useState<string>();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [params] = useState({
    pageNumber: 1,
    pageSize: 1000,
  });

  useEffect(() => {
    getDataAttributes()
  }, [attributesGroupData])// eslint-disable-line react-hooks/exhaustive-deps


  const getDataAttributes = () => {
    setSelectedOptions(null)
    const url = URLS.GET_ATTRIBUTE_GROUP_ATTRIBUTES_URL(attributeGroupTemplateId);
    const config = { params };
    setLoadingList(true);
    let newData: any;
    ServiceApi.get(url, config).then(({ data }) => {
      newData = JSON.parse(JSON.stringify(data));
      newData?.forEach((item: any) => {
        item?.translations?.forEach((i: any, index: number) => {
          if (index === 0) {
            item["title"] = i.displayName
          }
        })
      })
      setAttributes(newData);
      setAttributesList(newData)
    }).finally(() => {
      setLoadingList(false);
    });

    const urlSearch = URLS.GET_SEARCH_ATTRIBUTES_URL(1, 10, "");
    setLoadingList(true);
    let newDataSearch: any;
    let arrSearch: any = [];
    ServiceApi.get(urlSearch).then(({ data }) => {
      newDataSearch = data;
      newDataSearch?.items?.forEach((item: any) => {
        item?.translations?.forEach((i: any, index: number) => {
          if (index === 0) {
            arrSearch.push({
              id: item.attributeTemplateId,
              name: i.displayName,
            })

          }
        })
      })
      setLoadingList(false);
    });

  }


  //permissions
  const {
    PS_UpdateAttributeGroupTemplate,
  } = appPermissions;
  const UPDATE_PERMISSIONS = usePermission(PS_UpdateAttributeGroupTemplate);

  const handleSearchAttributes = async (filter: string) => {
    const urlSearch = URLS.GET_SEARCH_ATTRIBUTES_URL(1, 10, filter);
    let newDataSearch: any;
    let arrSearch: any = [];
    return ServiceApi.get(urlSearch).then(({ data }) => {
      newDataSearch = data;
      newDataSearch?.items?.forEach((item: any) => {
        item?.translations?.forEach((i: any, index: number) => {
          if (index === 0) {
            arrSearch.push({
              value: item.attributeTemplateId,
              label: i.displayName,
            })

          }
        })
      })
      return arrSearch;
    });
  };

  const promiseOptionsAttributes = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(handleSearchAttributes(inputValue));
      }, 200);
    });

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_ATTRIBUTE_GROUP_ATTRIBUTES_URL(attributeGroupTemplateId);
    let body = { "attribute": { "attributeTemplateId": id } };
    ServiceApi.patch(url, body).then(({ data }) => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      setLoadingButton(false);
      getDataAttributes()
      toggleDeleteModal()
    }).catch(() => setLoadingButton(false));
  };

  const onSearchHandler = (e: string) => {
    let keys = ["title", "code"];
    if (e !== "") {
      setAttributesList(onFilter(attributes, keys, e));
    } else {
      setAttributesList(attributes)
    }
    setSearchValue(e)
  };

  return (
    <Fragment>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {UPDATE_PERMISSIONS && <Wrapper.Section className="flex gap-4 w-full h-[77px] bg-primary-light border-primary py-4 px-4" >
            <div className="w-[443px]">
              <AsyncSelect
                value={selectedOptions}
                cacheOptions
                placeholder={<Text>productManagement.masterData.attributesGroups.selectAttribute</Text>}
                loadOptions={promiseOptionsAttributes}
                defaultOptions
                styles={config.styleSelectReact}
                onChange={(e: any) => {
                  setSelectedOptions(e)
                }}
                isClearable
              />
            </div>
            <div>
              <Button
                type="button"
                variant="primary"
                loading={loadingList}
                disabled={selectedOptions === null ? true : false}
                onClick={() => {
                  setLoadingList(true)
                  const url = URLS.ADD_ATTRIBUTE_GROUP_ATTRIBUTES_URL(attributeGroupTemplateId);
                  let body = { "attribute": { "attributeTemplateId": selectedOptions?.value } };
                  ServiceApi.patch(url, body).then(({ data }) => {
                    NotificationManager.success(
                      "global.toast.delete-msg",
                      "global.toast.delete-title"
                    );
                    getDataAttributes();
                  }).finally(() => {
                    setLoadingList(false)
                    setSelectedOptions(null)
                  });
                }}
              >
                <Text>productManagement.masterData.attributesGroups.addToGroup</Text>
              </Button>
            </div>
          </Wrapper.Section>
          }
          {attributes?.length !== 0 && <div className="grid grid-cols-5 gap-4 items-center">
            <InputGroup
              value={searchValue}
              setValue={onSearchHandler}
              placeholder="productManagement.masterData.attributesGroups.searchAttribute"
              disabled={loadingList}
            />
            <p className="text-body-2">
              {searchValue.length === 0
                ? attributesList?.length
                : attributesList?.length}{" "}
              Items
            </p>
          </div>}
          {attributesList?.length !== 0 ?
            <Table>
              {attributesList?.map((item: any) => {
                return (
                  <tr key={Math.random()} >
                    <td className="w-[26rem]">
                      <div className="w-fit inline-flex flex-center flex gap-2">
                        <div className="space-y-4">
                          <div><h6 className="text-heading-6 text-gray-800">{<HandleName translations={item?.translations} code={item?.code} keyName="displayName" />}</h6></div>
                          <div>
                            <span className="text-body-2 text-gray-500 pr-2">{item.code}</span>
                            <span className="text-body-2 text-gray-500 border-l-2 border-gray-300  px-2">{AttributesTypes(item.type)}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="space-x-4">
                      <Status.RequiredAttribute id={item?.isRequired} />
                      <Status.PerChannelAttribute id={item?.perChannel} />
                      <Status.PerLanguageAttribute id={item?.perLanguage} />

                    </td>
                    <td>
                      <Button
                        type="button"
                        size="sm"
                        variant="danger"
                        light
                        onClick={() => {
                          setSelected(item.attributeTemplateId);
                          setSelectedName(item.title);
                          toggleDeleteModal();
                        }}
                      >
                        <Icon icon={"trash"} />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </Table>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>

          }
        </>
      )}
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ id: selected, name: selectedName }}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </Fragment >
  );
}
