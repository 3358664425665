import { Image as ImageComponent, ImageUploader, Text } from "components";
import { useToggleState } from "hooks";
import { Fragment, useContext } from "react";
import { AddStoreContext } from ".";

export default function Image() {
  const { data, setData } = useContext(AddStoreContext);
  const [isOpen, toggle] = useToggleState(false);

  return (
    <Fragment>
      <h2 className="text-heading-2 text-gray-800">
        <Text>company.stores.image</Text>
      </h2>
      <div className="grid grid-cols-1 gap-8">
        <ImageComponent
          onClick={() => toggle()}
          src={data.mainPhoto}
          alt={""}
          log
          className="flex-center rounded overflow-hidden cursor-pointer"
        />
        <ImageUploader
          isOpen={isOpen}
          toggle={toggle}
          image={data.mainPhoto}
          onUpload={(image) => setData((p) => ({ ...p, mainPhoto: image.url }))}
        />
      </div>
    </Fragment>
  );
}
