import { useMountEffect } from "hooks";
import { ReactNode, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
type portalProps = {
  children: ReactNode;
};
export default function Portal({ children }: portalProps) {
  const { current: div } = useRef(document.createElement("div"));
  const id = useMemo(() => {
    const uuid = crypto.randomUUID?.();
    const number = Math.floor(Math.random() * 100000);
    return String(uuid || number);
  }, []);
  useMountEffect(() => {
    div.dataset.key = id;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  });
  return ReactDOM.createPortal(children, div);
}
