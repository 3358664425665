import React, { useState, useEffect, createContext } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  Breadcrumb,
  Skeleton,
  SearchBox,
  Text,
  Button,
  Table,
  Pagination,
} from "components";

import { useDispatch } from "react-redux";

import { ServiceApi, URLS } from "services";
import { config } from "constant";
import { useSelector, useToggleState } from "hooks";

import ActiveStatusTemplate from "./List/ActiveStatusTemplate";
import SoldStatusTemplate from "./List/SoldStatusTemplate";
import PreparationStatusTemplate from "./List/PreparationStatusTemplate";
import PriceTemplate from "./List/PriceTemplate";
import InfoTemplate from "./List/InfoTemplate";
import FilterDrawer from "./sell-off-drawers/FilterDrawer";
import StartPreparationPhaseModal from "./sell-off-drawers/StartPrearationPhase";
import ChangePhaseModal from "./sell-off-drawers/ChangePhase";
import ClosePhaseModal from "./sell-off-drawers/ClosePhase";
import { HandleParamsUrl } from "utils"
import { setSellOff } from "store/sell-off";

export const SellOffProductsContext = createContext<any>({ id: "" });

const SellOffProducts: React.FC<any> = () => {
  const dispatch = useDispatch();
  //original filter data for reset
  const originalFilterData = {
    keyword: "",
    searchFields: "",
    pimProductFamilyId: null,
    storeId: null,
    brandId: null,
    supplierId: null,
    location: null,
    status: null,
    needChangePhase: null,
    needClosePhase: null,
    hasFastSalesProvisionAmount: null,
    startedDateFrom: null,
    startedDateTo: null,
    soldDateFrom: null,
    soldDateTo: null,
    phaseChangedFrom: null,
    phaseChangedTo: null,
    sortType: null,
    pageSize: config.pageSize,
    pageNumber: "1",
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const profile = useSelector((s) => s.profile);

  const [filterModal, setFilterModal] = useToggleState(false);
  const [selectedID, setSelectedID] = useState("");
  const [startPrePhase, setStartPrePhase] = useToggleState(false);
  const [changePhase, setChangePhase] = useToggleState(false);
  const [closePhase, setClosePhase] = useToggleState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [keyword, setKeyword] = useState("");
  const [params, setParams] = useSearchParams();
  const [buttonLoading] = useState(
    new Array(config?.pageSize).fill(false)
  );


  const resetFilter = () => {
    setParams({})
    setKeyword("")
  };

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      if (key === "phaseChangedTo" || key === "phaseChangedFrom" || key === "startedDateTo" || key === "startedDateFrom") {
        value = value?.toDateString();
      }
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    }
  };

  const getSellOffProductsList = () => {
    if (!isEmpty(profile)) {
      let params: any = originalFilterData;
      searchParams?.forEach((val, keyObj) => {
        params[keyObj] = val;
      });
      setLoading(true);
      const url = URLS.SEARCH_PRODUCTS_URL;
      ServiceApi.post(url, {
        ...params,
      })
        .then((response) => {
          setData(response.data);
          dispatch(setSellOff(response.data));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
      setKeyword(params.searchFields)
    }
  };

  //get product list
  useEffect(getSellOffProductsList, [params, profile]);

  const createPhaseSection = (item: any) => {
    const isSold = item.isSold;
    const active = item.currentPhaseDay;

    return (
      <>
        <h6 className="text-heading-6">
          {isSold ? (
            <SoldStatusTemplate item={item} />
          ) : active ? (
            <ActiveStatusTemplate item={item} />
          ) : (
            <PreparationStatusTemplate item={item} />
          )}
        </h6>
      </>
    );
  };

  const preparationCheckList = (item: any, index?: number) => {
    setSelectedID(item.sellOffProductId);
    setStartPrePhase();
  };
  const changePhaseCheckList = (item: any, index?: number) => {
    setSelectedID(item.sellOffProductId);
    setChangePhase();
  };
  const closePhaseHandler = (item: any, index?: number) => {
    setSelectedID(item.sellOffProductId);
    setClosePhase();
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <Breadcrumb />
        </div>
      </div>
      <SearchBox
        value={keyword}
        onSubmit={handleSetFilterData("searchFields")}
        disabled={loading}
        totalItems={data?.totalItems}
        onFilter={() => setFilterModal()}
        resetFilter={searchParams?.size === 0 ? undefined : resetFilter}
      />
      {loading ? (
        [1, 2, 3, 4, 5].map((e) => <Skeleton.ImageList key={e} />)
      ) : (
        <>
          <Table>
            {data?.searchResult?.map((result: any, index: number) => {
              return (
                <tr key={result.sellOffProductId}>
                  <td>
                    <Link
                      to={result.storeTitle + "/" + result.sellOffProductId}
                      className="w-fit inline-flex flex-center gap-4"
                    >
                      <Table.Image
                        src={result?.assets[0]?.thumbnailUrl}
                        alt={result.title}
                        className="h-24 w-40"
                      />
                      <InfoTemplate item={result} />
                    </Link>
                  </td>
                  <td>
                    <div className="w-fit text-left">
                      <div className="flex flex-col gap-y-2">
                        {createPhaseSection(result)}
                      </div>
                    </div>
                  </td>
                  <td className="text-heading-5">
                    <PriceTemplate item={result} className="space-y-2" />
                  </td>
                  <td>
                    {!result.currentPhaseDay ? (
                      <Button
                        size="sm"
                        loading={buttonLoading[index]}
                        onClick={() => preparationCheckList(result, index)}
                      >
                        <Text>
                          salesManagment.sellOffSales.sellOffProduct.startProccess
                        </Text>
                      </Button>
                    ) : result.changePhaseRequired ? (
                      <Button
                        size="sm"
                        variant={"warning"}
                        onClick={() => changePhaseCheckList(result)}
                      >
                        <Text>
                          salesManagment.sellOffSales.sellOffProduct.changePhase
                        </Text>
                      </Button>
                    ) : result.closePhaseRequired ? (
                      <Button
                        size="sm"
                        variant={"danger"}
                        onClick={() => closePhaseHandler(result)}
                      >
                        <Text>
                          salesManagment.sellOffSales.sellOffProduct.closeProccess
                        </Text>
                      </Button>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </Table>
          <Pagination
            totalItems={data?.totalItems}
            totalPages={data?.totalPages}
            pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
            pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
            setActivePage={handleSetFilterData("pageNumber")}
            onPageSizeChange={handleSetFilterData("pageSize")}
          />
        </>
      )}
      <SellOffProductsContext.Provider value={{ id: selectedID }}>
        <FilterDrawer
          isOpen={filterModal}
          data={data}
          toggle={setFilterModal}
          onChange={handleSetFilterData}
          resetFilter={resetFilter}
          keyword={keyword}
          setKeyword={setKeyword}
          setParams={setParams}
          params={params}
        />
        <StartPreparationPhaseModal
          isOpen={startPrePhase}
          toggle={setStartPrePhase}
          getData={getSellOffProductsList}
        />
        <ChangePhaseModal
          isOpen={changePhase}
          toggle={setChangePhase}
          getData={getSellOffProductsList}
        />
        <ClosePhaseModal
          isOpen={closePhase}
          toggle={setClosePhase}
          getData={getSellOffProductsList}
          id={selectedID}
        />
      </SellOffProductsContext.Provider>
    </div>
  );
};

export default SellOffProducts;
