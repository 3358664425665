import { Text as TextComponent } from "components";
import { useToggleState } from "hooks";
import {
    createContext,
    createElement,
    useEffect,
    useState
} from "react";
import { findValue } from "../../helpers/find-attribute.value";
import Measurment from "../components/measurment-units";
import CompareValuesDrawer from "../drawers/compare-values";
import Boolean from "./Boolean";
import DateField from "./Date";
import DateTime from "./DateTime";
import Select from "./Select";
import TextArea from "./TextArea";
import Time from "./Time";
import Text from "./text";

type Props = {
  attributes: any;
  values: any;
  language: string;
  channel: string;
  id: string;
  setValues: any;
  onChangeValue: any;
  flag?: any;
  filter?: string;
  showOriginalAttribute?: boolean;
};

export const AttributeContext = createContext<any>({});

const FormFieldGenerator = ({
  attributes,
  values,
  channel,
  language,
  id,
  setValues,
  flag,
  filter,
  showOriginalAttribute,
  onChangeValue,
}: Props) => {
  const [isOpen, toggle] = useToggleState();
  const [attribute, setAttribute] = useState<any>([]);
  const [filterAttribute, setFilterAttribute] = useState<any>([]);

  const filtring = (filter?: string) => {
    let filtered;
    if (filter === "all") {
      setFilterAttribute(attributes);
    } else if (filter === "missingRequierd") {
      filtered = attributes.filter((attr: any) => attr.isRequired);
      const requiredFields = filtered.filter((item: any) => {
        let { value } = findValue(item, values, id, channel, language);
        return item.isRequired && !value;
      });
      setFilterAttribute(requiredFields);
    } else if (filter === "missing") {
      const requiredFields = attributes.filter((item: any) => {
        let { value } = findValue(item, values, id, channel, language);
        return !value;
      });
      setFilterAttribute(requiredFields);
    }
  };

  useEffect(() => {
    filtring(filter);
  }, [filter]);

  const findFieldComponent = (attribute: any) => {
    switch (attribute?.type) {
      case 10:
        return Text;
      case 20:
        return Boolean;
      case 30:
        return Select;
      case 40:
        return DateField;
      case 50:
        return Time;
      case 60:
        return DateTime;
      case 70:
        return Measurment;
      case 80:
        return TextArea;
      default:
        return Text;
    }
  };

  return (
    <>
      {filterAttribute.length > 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          {filterAttribute?.map((attr: any) => {
            let showInChannel = attr.attributeChannelExceptions?.find(
              (at: any) => at.channelCode === channel
            );
            return (
              <>
                {(!showInChannel || showOriginalAttribute) && (
                  <AttributeContext.Provider
                    value={{
                      values,
                      id,
                      attribute: attr,
                      channel,
                      language,
                      setValues,
                      flag,
                      toggle,
                      setAttribute,
                      onChangeValue,
                      showInChannel,
                    }}
                  >
                    {createElement(findFieldComponent(attr))}
                  </AttributeContext.Provider>
                )}
              </>
            );
          })}
          <CompareValuesDrawer
            isOpen={isOpen}
            toggle={toggle}
            attribute={attribute}
            values={values}
            setValues={setValues}
            id={id}
            onChangeValue={onChangeValue}
          />
        </div>
      ) : (
        <span className="text-gray-800 text-body-base">
          <TextComponent>
            productManagement.products.Details.noAttributeFound
          </TextComponent>
        </span>
      )}
    </>
  );
};

export default FormFieldGenerator;
