import { Fragment, useMemo } from "react";
import ProgressBar from "./ProgressBar";

type progressBarsProps = {
  totalSalesPerYear: number;
  totalSales: number;
  perYear: number;
  year: number;
  growPercentage: number;
};

export default function ProgressBars({
  totalSales,
  totalSalesPerYear,
  growPercentage,
  perYear,
  year,
}: progressBarsProps) {
  const totalSalesPerYearValue = useMemo(() => {
    if (growPercentage < 0) return 100;
    return 100 - growPercentage;
  }, [growPercentage]);
  const totalSalesValue = useMemo(() => {
    if (growPercentage < 0) return 100 + growPercentage;
    return 100;
  }, [growPercentage]);
  const handleVariant = useMemo(() => {
    if (growPercentage < 0) return "danger";
    return "success";
  }, [growPercentage]);
  return (
    <Fragment>
      <ProgressBar
        amount={totalSalesPerYear}
        value={totalSalesPerYearValue}
        year={perYear}
        variant="gray"
      />
      <ProgressBar
        amount={totalSales}
        value={totalSalesValue}
        year={year}
        percent={growPercentage}
        variant={handleVariant}
      />
    </Fragment>
  );
}
