import Dashboard from "pages/dashboard";

const dashboard = [
  {
    path: "",
    element: <Dashboard />,
  },
];

export default dashboard;
