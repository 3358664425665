import { useContext, Fragment } from "react";
import { InputGroup, Text, Wrapper, Skeleton } from "components";
import { rules } from "constant";
import { UserContext } from "..";

export default function General() {
  const { rolesData, setRolesData, loading } = useContext<any>(UserContext);

  const handleSetData = (key: any) => {
    return (value: any) => setRolesData((p: any) => ({ ...p, [key]: value }));
  };

  return (
    <Wrapper>
      <Wrapper.Header>
        <h1 className="text-heading-2 font-semibold">
          <Text>configurations.userManagment.roles.basicInfo</Text>
        </h1>
      </Wrapper.Header>
      <Wrapper.Body>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8" >
          {loading ? (
            <Fragment>
              <div className="col-start-1 col-end-2">
                <Skeleton.Input />
              </div>
              <div className="col-start-1 col-end-2">
                <Skeleton.Input />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="col-start-1 col-end-2">
                <InputGroup
                  label="configurations.userManagment.roles.name"
                  value={rolesData.name}
                  setValue={handleSetData("name")}
                  rules={rules.required}
                />
              </div>
              <div className="col-start-1 col-end-2">
                <InputGroup
                  as="short-textarea"
                  label="configurations.userManagment.roles.shortDescription"
                  value={rolesData.description}
                  setValue={handleSetData("description")}
                />
              </div>
            </Fragment>
          )}
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
