import { Button, Drawer, Form, Icon, InputGroup, Text } from "components";
import { rules } from "constant";
import { useContext, useState } from "react";
import { ServiceApi } from "services";
import { toggleProps } from "types";
import { PositionContext } from ".";
import { cloneDeep } from "lodash";
import { componentType } from "..";
type addComponentProps = toggleProps;
export default function AddComponent({ isOpen, toggle }: addComponentProps) {
  const { positionData, setPositionData } = useContext(PositionContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ title: "" });
  const clearData = () => {
    setData({ title: "" });
  };
  const handleToggle = () => {
    clearData();
    toggle();
  };
  const addNewComponent = (component: componentType) => {
    setPositionData((p) => {
      const data = cloneDeep(p);
      data.components.push(component);
      return data;
    });
  };
  const submit = () => {
    setLoading(true);
    const url = `/productservice/api/positions/${positionData.id}/component`;
    const body = { ...data };
    ServiceApi.post(url, body)
      .then(({ data: id }) => {
        addNewComponent({ id, items: null, title: body.title });
        handleToggle();
        clearData();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Drawer isOpen={isOpen} toggle={handleToggle}>
      <Form onSubmit={submit}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between">
            <h2>
              <Text>applications.positions.addComponentHeader</Text>
            </h2>
            <Button
              variant="light"
              size="sm"
              type="button"
              onClick={handleToggle}
            >
              <Icon icon="close" />{" "}
              <Text>applications.positions.addComponentCloseButton</Text>
            </Button>
          </Drawer.Header>
          <Drawer.Body>
            <InputGroup
              label="applications.positions.addComponentTitle"
              value={data.title}
              setValue={(title) => setData((p) => ({ ...p, title }))}
              rules={rules.required}
            />
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button
              type="button"
              variant="light"
              size="sm"
              onClick={handleToggle}
            >
              <Text>applications.positions.addComponentCancelButton</Text>
            </Button>
            <Button type="submit" variant="primary" size="sm" loading={loading}>
              <Icon icon="add" />{" "}
              <Text>applications.positions.addComponentSubmitButton</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
