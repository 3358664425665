import { Breadcrumb } from "components";
import { useToggleState } from "hooks";
import { createContext } from "react";
import { ServiceApi } from "services";
import MainData from "./MainData";
import { AxiosResponse } from "axios";
import StoresReport from "./StoresReport";
export type dataType = {
  requestFilter: {
    location: null | string;
    advisorFullName: null | string;
    dateFrom: Date;
    dateTo: Date;
  };
  totalSales: number;
  totalTargetSales: number;
  averageSellFactor: number;
  averageTargetSellFactor: number;
  totalSalesPerYear: number;
  totalTargetSalesPerYear: number;
  averageSellFactorPerYear: number;
  averageTargetSellFactorPerYear: number;
  previousLevelTotalSales: number;
  previousLevelTotalTargetSales: number;
  previousLevelAverageSellFactor: number;
  previousLevelAverageTargetSellFactor: number;
  salesPercentageBasedOnPreviousLevelSales: number;
  salesPercentageBasedOnEntireYearSales: number;
  growPercentage: number;
  salesItemsPerMonth: {
    totalSales: number;
    totalTargetSales: number;
    averageSellFactor: number;
    averageTargetSellFactor: number;
    monthNumber: number;
    growPercentage: number;
  }[];
  lastYearSalesItemsPerMonth: {
    totalSales: number;
    totalTargetSales: number;
    averageSellFactor: number;
    averageTargetSellFactor: number;
    monthNumber: number;
    growPercentage: number;
  }[];
  totalSalesPerAllQuarters: {
    number: number;
    totalSales: number;
    totalTargetSales: number;
  }[];
  lastYearTotalSalesPerAllQuarters: {
    number: number;
    totalSales: number;
    totalTargetSales: number;
  }[];
  locationsBasedOnTimeInterval: {
    location: string;
    totalSales: number;
    totalTargetSales: number;
    salesPercentage: number;
    growPercentage: number;
  }[];
  advisorsBasedOnTimeInterval: {
    advisorFullName: string;
    totalSales: number;
    totalTargetSales: number;
  }[];
};
export type paramsType = {
  dateFrom: Date;
  dateTo: Date;
  location?: string;
  advisorFullName?: string;
  excludeSideCosts?: boolean;
};
type contextType = {
  searchSalesReport: (body: paramsType) => Promise<AxiosResponse<any, any>>;
  excludeSideCosts: boolean;
  toggleExcludeSideCosts: () => void;
};

export const SalesReportContext = createContext({} as contextType);

export default function SalesReport() {
  const [excludeSideCosts, toggleExcludeSideCosts] = useToggleState(false);
  const searchSalesReport = async (body: paramsType) => {
    const url =
      "/statisticsservice/api/kitchen-calculations/search-kitchen-sales-report";
    return await ServiceApi.post(url, body);
  };
  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
      <div className="col-span-full">
        <Breadcrumb />
      </div>
      <SalesReportContext.Provider
        value={{ searchSalesReport, excludeSideCosts, toggleExcludeSideCosts }}
      >
        <MainData />
        <StoresReport />
      </SalesReportContext.Provider>
    </div>
  );
}
