import { Fragment, useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  Button,
  Text,
  Icon,
  Wrapper,
  Skeleton,
  Breadcrumb,
} from "components";
import { ServiceApi, URLS } from "services";
import { Link } from "react-router-dom";
import { useDataState, usePermission, useToggleState } from "hooks";
import { useParams } from "react-router";
import { appPermissions, rules } from "constant";
import { NotificationManager } from "common/notifications";
import AddPhase from "./add-phase"
import UpdatePhase from "./update-phase"

export default function Update() {
  const { salesTemplateId } = useParams();
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [selected, setSelected] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [isOpenDrawerCreate, toggleDrawerCreate] = useToggleState(false)
  const [isOpenDrawerUpdate, toggleDrawerUpdate] = useToggleState(false)

  //permissions
  const { PS_UpdateSellPhaseTemplate } = appPermissions;
  const UPDATE_PERMISSION = usePermission(PS_UpdateSellPhaseTemplate);

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.UPDATE_DETAILS_SELL_PHASES_URL(salesTemplateId);
    delete data.sellPhaseTemplateId
    data.sellPhases.forEach((item: any) => {
      item.checkListItemIds.forEach((i: any, index: any) => {
        item.checkListItemIds[index] = i.checkListItemId
      })
    })
    ServiceApi.put(url, data).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
      getData();
    }).finally(() => {
      setLoadingButton(false);
    });
  };

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const getData = async () => {
    const result = await ServiceApi.get(
      URLS.GET_DETAILS_SELL_PHASES_URL(salesTemplateId)
    ).then(({ data }) => {
      setBaseData(data);
      setLoadingPage(false);
    })
  };

  return (
    <Fragment>
      <Form onSubmit={submit} className="relative space-y-6">
        <div className="flex items-center">
          <div className="flex-1">
            <h3 className="mr-auto text-heading-3">
              <Breadcrumb />
            </h3>
          </div>
        </div>
        <Wrapper>
          <Wrapper.Header>
            <div className="flex">
              <div className="mr-auto">
                <h6 className="text-heading-2 text-gray-800">
                  <Text>salesManagment.sellOffSales.salesTemplates.basicInfo</Text>
                </h6>
              </div>
            </div>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
            <div className="space-y-8">
              {loadingPage ? (
                <Skeleton.Input />
              ) : (
                <InputGroup
                  label="salesManagment.sellOffSales.salesTemplates.templateName"
                  value={data?.title}
                  setValue={handleSetData("title")}
                  rules={rules.required}
                />
              )}
              {loadingPage ? (
                <Skeleton.Input />
              ) : (
                <InputGroup
                  label="salesManagment.sellOffSales.salesTemplates.code"
                  value={data?.code}
                  setValue={handleSetData("code")}
                />
              )}
            </div>
            <div className="space-y-8">
              {loadingPage ? (
                <Skeleton.Input />
              ) : (
                <InputGroup
                  as="textarea"
                  label="salesManagment.sellOffSales.salesTemplates.shortDescription"
                  value={data?.description}
                  setValue={handleSetData("description")}
                />
              )}
            </div>
            <div className="flex col-span-2 items-center justify-end">
              {loadingPage ? (
                <Skeleton.Button />
              ) :
                <Button as={Link} to={-1} variant="white">
                  <Text>global.buttons.discard</Text>
                </Button>
              }
              {loadingPage ? (
                <Skeleton.Button />
              ) : (UPDATE_PERMISSION ? <Button
                type="submit"
                variant="primary"
                disabled={!isChanged}
                loading={loadingButton}
              >
                <Text>global.buttons.saveChanges</Text>
              </Button> : "")}
            </div>
          </Wrapper.Body>
        </Wrapper>
        <Wrapper>
          <Wrapper.Header>
            <div className="flex">
              <div className="mr-auto">
                <h6 className="text-heading-2 text-gray-800">
                  <Text>salesManagment.sellOffSales.salesTemplates.phaseInfo</Text>
                </h6>
              </div>
            </div>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-3 lg:grid-cols-3 gap-8 lg:gap-8">
            {data?.sellPhases?.map((item: any, index: any) => {
              return <div key={item.id} className="rounded-xl space-y-2 p-6" style={{ boxShadow: "0px 4px 20px rgba(56, 71, 109, 0.09)" }}>
                <div className="flex mb-6"><h4 className="text-heading-4 text-gray-800 mr-2.5">Phase {index + 1}</h4>
                  <div onClick={() => {
                    setSelected(item)
                    setSelectedIndex(index + 1)
                    toggleDrawerUpdate()
                  }}>
                    <Icon className="text-primary cursor-pointer" icon="edit"></Icon>
                  </div>
                </div>
                <div><h5 className="text-heading-5 text-gray-700">{item.title}</h5></div>
                <div>
                  <span className="text-body-base text-gray-600"><Text>salesManagment.sellOffSales.salesTemplates.discount</Text>:</span>
                  <span className="text-body-base semibold text-danger ml-1">{item.discount}<span>%</span></span>
                </div>
                <div>
                  <span className="text-body-base text-gray-600"><Text>salesManagment.sellOffSales.salesTemplates.duration</Text>:</span>
                  <span className="text-body-base semibold text-gray-700 ml-1">{item.duration}<span className="ml-1">day</span></span>
                </div>
                <div>
                  <span className="text-body-base text-gray-600"><Text>salesManagment.sellOffSales.salesTemplates.sellerCommission</Text>:</span>
                  <span className="text-body-base semibold text-gray-700 ml-1">{item.sellerCommission}<span>%</span></span>
                </div>
                <div>
                  <span className="text-body-base semibold text-gray-700">{item.description}</span>
                </div>
                <div className="flex-1">
                  {item?.checkListItemIds?.map((i: any) => {
                    return <span className={`inline-block align-middle px-2 py-1 gap-8 font-medium rounded-md text-primary bg-primary-light text-body-2 ml-0 mr-1 m-0.5`}
                    >{i.label}</span>
                  })}
                </div>
              </div>

            })}
            {UPDATE_PERMISSION && <Wrapper.Section className="w-full  h-full bg-primary-light border-primary text-center courser-pointer cursor-pointer" >
              <div className="flex items-center justify-center w-full h-[210px]" onClick={() => {
                toggleDrawerCreate()
              }}>
                <h4 className="text-gray-800">
                  <Icon className="mr-2 text-primary" icon={"plus"} />
                  <Text>salesManagment.sellOffSales.salesTemplates.addPhase</Text>
                </h4>
              </div>
            </Wrapper.Section>}
          </Wrapper.Body>

        </Wrapper>

      </Form>
      <AddPhase isOpen={isOpenDrawerCreate} salesTemplateId={salesTemplateId} data={data} getData={getData} toggle={toggleDrawerCreate} />
      <UpdatePhase isOpen={isOpenDrawerUpdate} toggle={toggleDrawerUpdate} selectedIndex={selectedIndex} selected={selected} salesTemplateId={salesTemplateId} data={data} getData={getData} />
    </Fragment>
  );
}
