import { InteractionRequiredAuthError } from "@azure/msal-browser";
import Axios from "axios";
import { authInstance, config, loginRequest } from "../constant";
import { ErrorHandler } from "./error-handler";
import store from "store";
import { toggleLoading } from "store/loading";

const data = {};

export const getLocalIdToken = () => {
  const homeAccountId = authInstance?.getAllAccounts()[0]?.homeAccountId;
  const Idtoken = `${homeAccountId}-${`${config.tenant}.b2clogin.com`}-idtoken-${
    config.clientId
  }----`;
  const token = JSON.parse(sessionStorage.getItem(Idtoken)) || "";
  return token;
};

const getAuthData = async () => {
  try {
    const authData = await authInstance.acquireTokenSilent(loginRequest);
    return authData;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return authInstance.acquireTokenRedirect(loginRequest);
    }
  }
};

const ServiceApi = Axios.create({
  baseURL: config.baseUrl,
});

ServiceApi.interceptors.request.use(
  async (req) => {
    store.dispatch(toggleLoading(true));
    const isGet = req.method === "get";
    const cache = req.params?.cache;
    const dataKey = `${req.url}${JSON.stringify(req.params)}`;
    const hasData = data[dataKey];
    const needCache = [isGet, cache, hasData].every(Boolean);
    const adapterData = {
      config: req,
      request: req,
      headers: req.headers,
      data: data[dataKey],
      status: 200,
      statusText: "OK",
    };
    if (needCache) {
      req.adapter = () => Promise.resolve(adapterData);
      return req;
    }
    const { idToken } = await getAuthData();
    if (idToken) {
      req.headers["Authorization"] = `Bearer ${idToken}`;
      req.headers["Ocp-Apim-Subscription-Key"] = config.subscriptionKey;
      return req;
    }
    authInstance.loginRedirect(loginRequest);
    return req;
  },
  (err) => {
    ErrorHandler(err);
    return Promise.reject(err);
  }
);
ServiceApi.interceptors.response.use(
  (res) => {
    store.dispatch(toggleLoading(false));
    const key = `${res.config.url}${JSON.stringify(res.config.params)}`;
    const value = res.data;
    data[key] = value;
    return res;
  },
  (err) => {
    store.dispatch(toggleLoading(false));
    ErrorHandler(err);
    return Promise.reject(err);
  }
);

export default ServiceApi;
