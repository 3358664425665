import { useContext, useEffect, useState } from "react";
import { useToggleState } from "hooks";
import { Wrapper, Image, Button, Icon, ImageUploader, Text } from "components";
import { SellOffProductDetailsContext } from "..";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";
import { isEmpty } from "lodash";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Share from "yet-another-react-lightbox/plugins/share";
import "yet-another-react-lightbox/styles.css";

const Gallery = () => {
  //Galery
  const [toggler, setToggler] = useToggleState();
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImage, setSelectedImage] = useState<number>();

  const { data, editImageToggle, getData } = useContext(
    SellOffProductDetailsContext
  );

  useEffect(() => {
    const clone: any = [];
    if (!isEmpty(data)) {
      data?.assets?.map((image: any) => {
        clone.push({ src: image.url });
      });
    }
    setImageUrls(clone);
  }, [data]);

  // useEffect(() => {
  //   const slider = document.querySelector(".parent") as HTMLDivElement;
  //   let mouseDown = false;
  //   let startX: any, scrollLeft: any;

  //   let startDragging = function (e: any) {
  //     mouseDown = true;
  //     startX = e.pageX - slider.offsetLeft;
  //     scrollLeft = slider.scrollLeft;
  //     slider.style.cursor = "grabbing";
  //     slider.style.userSelect = "none";
  //   };
  //   let stopDragging = function () {
  //     mouseDown = false;
  //     slider.style.cursor = "grab";
  //   };

  //   slider.addEventListener("mousemove", (e) => {
  //     e.preventDefault();
  //     if (!mouseDown) {
  //       return;
  //     }
  //     const x = e.pageX - slider.offsetLeft;

  //     const scroll = x - startX;
  //     slider.scrollLeft = scrollLeft - scroll;
  //   });

  //   // Add the event listeners
  //   slider.addEventListener("mousedown", startDragging, false);
  //   slider.addEventListener("mouseup", stopDragging, false);
  //   slider.addEventListener("mouseleave", stopDragging, false);

  //   return () => {
  //     slider.removeEventListener("mousedown", startDragging, false);
  //     slider.removeEventListener("mouseup", stopDragging, false);
  //     slider.removeEventListener("mouseleave", stopDragging, false);
  //   };
  // }, []);

  const [isOpenUploader, setUploaderToggle] = useToggleState();
  const addAssetsHandler = (image: any) => {
    const url = URLS.UPDATE_DELETE_ASSETS(data?.sellOffProductId);
    const body = {
      assets: [
        {
          id: null,
          url: image.url,
          type: image.type,
          thumbnailUrl: image.thumbnailUrl,
        },
      ],
    };

    ServiceApi.patch(url, body).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      getData();
    });
  };

  const hiddenButtons = data?.isClosed || data?.isSold;

  return (
    <>
      <Wrapper>
        <Wrapper.Header className="flex justify-between items-center">
          <h2 className="text-gray-800">
            <Text>salesManagment.sellOffSales.sellOffProduct.gallery</Text>
          </h2>
          {!hiddenButtons && (
            <div>
              <Button
                variant={"light"}
                size="sm"
                className="mr-4"
                onClick={setUploaderToggle}
              >
                <Icon icon="plus" className="mr-2" />
                <span>
                  <Text>
                    salesManagment.sellOffSales.sellOffProduct.addPhoto
                  </Text>
                </span>
              </Button>

              <Button variant={"light"} size="sm" onClick={editImageToggle}>
                <Icon icon="edit" className="mr-2" />
                <span>
                  <Text>salesManagment.sellOffSales.sellOffProduct.edit</Text>
                </span>
              </Button>
            </div>
          )}
        </Wrapper.Header>
        <Wrapper.Body>
          <>
            <div className="flex items-end gap-2 overflow-x-scroll parent pb-2">
              {data?.assets?.length > 0 &&
                data?.assets?.map((image: any, index: number) => (
                  <div className="min-w-max">
                    <Image
                      src={image?.url}
                      className="max-w-lg cursor-pointer"
                      imageClassName="h-[270px]"
                      onClick={() => {
                        setSelectedImage(index);
                        setToggler();
                        document.body.style.overflow = "hidden";
                      }}
                    />
                  </div>
                ))}
            </div>
            <Lightbox
              open={toggler}
              index={selectedImage}
              close={() => {
                document.body.style.overflow = "visible";
                setToggler();
              }}
              slides={imageUrls}
              plugins={[Zoom, Slideshow, Share]}
            />
          </>
        </Wrapper.Body>
      </Wrapper>
      <ImageUploader
        isOpen={isOpenUploader}
        toggle={setUploaderToggle}
        image={""}
        onUpload={(image) => addAssetsHandler(image)}
      />
    </>
  );
};

export default Gallery;
