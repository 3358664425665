import {
  Breadcrumb,
  Button,
  HandleName,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text
} from "components";
import { appPermissions, config } from "constant";
import { usePermission, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import CreateDrawer from "./create";

type ItemsType = {
  measurementFamilyId: string;
  title: string;
  code: string;
  truncated: string;
  translations: any;
};

type ListType = {
  items?: ItemsType[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function MeasurementUnits() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const [isOpen, toggle] = useToggleState(false);

  //permissions
  const { PS_ManageMeasurementFamily, PS_ViewMeasurementFamily } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(PS_ManageMeasurementFamily);
  const CREATE_PERMISSION = usePermission(PS_ViewMeasurementFamily);
  const GET_DETAIL_PERMISSION = usePermission(PS_ViewMeasurementFamily);

  const handleSetParams = (key: any) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_MEASUREMENT_FAMILY_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config).then(({ data }) => {
      let newData = data;
      newData?.items?.forEach((item: any) => {
        item?.translations?.forEach((i: any, index: number) => {
          if (index === 0) {
            item["title"] = i.name
          }
        })
      })
      setData(newData);
      setLoadingList(false);
    });
  };

  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        {loadingList ? (
          <Skeleton.Button />
        ) : CREATE_PERMISSION ? (
          <Button onClick={() => toggle()}>
            <Icon className="mr-2" icon={"plus"} />
            <Text>productManagement.masterData.measurementUnits.addMeasurement</Text>
          </Button>
        ) : null}
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ?
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.measurementFamilyId}>
                      <td className="space-y-4">
                        <Link to={GET_DETAIL_PERMISSION ? item.measurementFamilyId : ""}>
                          <div><h6 className="text-heading-6 text-gray-800 font-semibold">{<HandleName translations={item?.translations} code={item?.code} keyName="name" />}</h6></div>
                        </Link>
                        <div><span className="text-body-base text-gray-500 font-medium">{item.code}</span></div>
                      </td>
                      <td>
                      </td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button
                            variant="light"
                            size="sm"
                            as={Link}
                            to={item.measurementFamilyId}
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages || 1}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
      <CreateDrawer isOpen={isOpen} toggle={toggle} getData={getData} />
    </div>
  ) : (
    <>Permission Error</>
  );
}
