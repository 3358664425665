export const status = {
  "store": {
    "active": "Aktiv",
    "deActive": "Inaktiv"
  },
  "salesList": {
    "shippingCostHasWarning": "Warnung für Versandkosten",
    "sellFactorHasWarning": "Warnung für Verkaufsfaktor",
    "pendingApproval": "Genehmigung ausstehend",
    "approved": "Genehmigt"
  }
};
