import { NotificationManager } from "common/notifications";
import { Button, Icon, Skeleton, Text, Wrapper } from "components";
import { useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import { CategoryContext } from "..";
import ChannelDrawer from "../drawers/channel-drawer";

const Channels = () => {
  const { i18n } = useTranslation();
  const { selectedCategory } = useContext(CategoryContext);
  const [channels, setChannels] = useState([]);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState<any>({});
  const [publishedList, setPublishedList] = useState<any>([]);

  const [loading, setLoading] = useToggleState();
  const [publishLoading, setPublishLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();

  const getPublishedList = () => {
    setLoading();
    const url = URLS.GET_CHANNEL_CATEGORIES(selectedCategory.productCategoryId);
    ServiceApi.get(url).then(({ data }) => {
      const list = data.map((p: any) => p.channelCode);
      setPublishedList(list);
      setLoading();
    });
  };

  const getChannelsInfo = () => {
    const url = URLS.GET_CATEGORY_BY_ID(selectedCategory.productCategoryId);
    ServiceApi.get(url).then(({ data }) => {
      setAvailableChannels(data.availableChannels);
    });
  };

  useEffect(() => {
    setLoading();
    const url = URLS.CHANNEL_URL;
    ServiceApi.get(url)
      .then(({ data }) => {
        setChannels(data);
        setLoading();
        getPublishedList();
      })
      .catch(() => setLoading());
    getChannelsInfo();
  }, [selectedCategory]);

  const publishHandler = (channel: any) => {
    setPublishLoading();
    const url = URLS.PUBLISH_CATEGORY(selectedCategory.productCategoryId);
    const body = { channelCode: channel.code };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.publish-msg",
          "global.toast.publish-title"
        );
        setPublishLoading();
        const list = [...publishedList];
        list.push(channel.code);
        setPublishedList(list);
      })
      .catch(() => setPublishLoading());
  };

  const unPublishHandler = (channel: any) => {
    setPublishLoading();
    const url = URLS.UNPUBLISH_CATEGORY(selectedCategory.productCategoryId);
    const body = { channelCode: channel.code };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.unpublish-msg",
          "global.toast.unpublish-title"
        );
        setPublishLoading();
        getPublishedList();
      })
      .catch(() => setPublishLoading());
  };

  const getInfo = () => {
    const info = availableChannels?.filter(
      (channel: any) => channel.channelCode === selectedChannel.code
    )[0];
    return info;
  };

  const info = getInfo();

  return (
    <>
      <div className="space-y-4">
        {loading
          ? [1, 2, 3, 4, 5].map((l) => <Skeleton.List key={l} />)
          : channels.map((channel: any) => {
              return (
                <Wrapper>
                  <Wrapper.Body className="!p-4">
                    <div className="flex justify-between items-center">
                      <span className="text-heading-6 font-semibold">
                        {
                          channel?.channelNameTranslates?.filter(
                            (tr: any) =>
                              tr.language === i18n.language.toLowerCase()
                          )[0]?.name
                        }
                      </span>
                      <div className="space-x-4">
                        {publishedList.includes(channel?.code) && (
                          <Button
                            light
                            variant="danger"
                            size="sm"
                            onClick={() => unPublishHandler(channel)}
                            disabled={publishLoading}
                          >
                            <Text>
                              productManagement.masterData.productCategory.unPublish
                            </Text>
                          </Button>
                        )}
                        <Button
                          light
                          size="sm"
                          onClick={() => publishHandler(channel)}
                          disabled={publishLoading}
                        >
                          <Text>
                            productManagement.masterData.productCategory.publish
                          </Text>
                        </Button>
                        <Button
                          size="sm"
                          variant={"light"}
                          disabled={publishLoading}
                          onClick={() => {
                            setSelectedChannel(channel);
                            toggle();
                          }}
                        >
                          <Icon icon="sliders" className="text-gray-600" />
                        </Button>
                      </div>
                    </div>
                  </Wrapper.Body>
                </Wrapper>
              );
            })}
      </div>
      <ChannelDrawer
        isOpen={isOpen}
        toggle={toggle}
        channelInfo={info}
        id={selectedCategory.productCategoryId}
        selectedChanel={selectedChannel}
        getChannelsInfo={getChannelsInfo}
      />
    </>
  );
};

export default Channels;
