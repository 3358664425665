import LegalEntitiesList from "pages/compony/legal-entities";
import Details from "pages/compony/legal-entities/details"

const LegalEntities = [
  {
    path: "",
    element: <LegalEntitiesList />,
  },
  {
    path: ":legalEntityId",
    element: <Details />,
  },
];

export default LegalEntities;
