export const errors = {
  // 1001: "CompanyNotFound DE",
  // 1002: "MobileNumberIsAlreadyRegisterd DE",
  // 1003: "DeviceTokenIsNotValid DE",
  // 1004: "EmailAddressIsAlreadyRegistered DE",
  // 1005: "EmailConfirmationCodeIsExpired DE",
  // 1006: "EmailConfirmationCodeIsNotValid DE",
  // 1007: "MobileNumberIsNotConfirmed DE",
  // 1008: "MobileNumberVerificationCodeIsNotValid DE",
  // 1009: "PassCodeMustBeNullOrEmpty DE",
  // 1010: "PassCodeMustExists DE",
  // 1011: "RemoveCustomerIsNotPossible DE",
  // 1012: "CustomerMustAcceptGDPR DE",
  // 1013: "CustomerMustBePerson DE",
  // 1014: "NewPassCodeIsNotMatch DE",
  // 1015: "MobileNumberMustRegisterd DE",
  // 1201: "InvalidPassCode DE",
  // 1202: "",
  // 1203: "",
  // 1301: "CustomerNotFound DE",
  // 1302: "",
  // 1303: "",

  error: "Error",
  1000: "Place Code Should Be Unique",
  1001: "Invalid Channel",
  1003: "Brand Code Should Be Unique In Company",
  1004: "Place Code Should Be Unique In Area",
  1006: "Attribute Group Template Not Found",
  1007: "Brand Could Not Found",
  1008: "Brand Already Exists",
  1009: "Chek List Item Could Not Found",
  1010: "Check List Item Label Already Exists",
  1011: "Legal Entity Could Not Found",
  1012: "Legal Entity Code Should Be Unique In Company",
  1013: "Measurement Family Code Should be Unique",
  1014: "Measurement Family Unit Code Should be Unique",
  1015: "Measurement Family Could Not Found",
  1016: "Pim Product Family Could Not Found",
  1017: "Place Could Not Found",
  1018: "Pim Product Media Could Not Found",
  1020: "Attribute Values Can Not Have Duplicate Language In Attribute Values",
  1021: "Only Channel Of None Channel Specific Attribute Values Could Be Null",
  1022: "Channel Specific Attributes Should Use Built In Channels",
  1023: "Only Language Of None Language Specific Attribute Values Could Be Null",
  1024: "Pim Product Media Fields Can Not Have Duplicate Language In The Same Channel",
  1025: "Media Fields Either All Or Non Should Have Channel Value",
  1026: "Media Fields Either All Or Non Should Have Language Value",
  1027: "Required Items Should Have Value",
  1028: "Delete Check list Item Is possible When Not Used In Preparation Phase",
  1029: "Delete Check list Item Is possible When Not Used In Sell Phase",
  1030: "Product Family Code Should Be Unique In Company",
  1031: "Product Family Already Exist",
  1032: "Zone Code Should Be Unique In Store",
  1033: "Store Code Should Be Unique In Company",
  1034: "Store Not Found",
  1035: "Product Family Not Found",
  1036: "Channel Setting Not Found",
  1037: "Channel Is Not Availble In This Category",
  1039: "Access denied due to missing subscription key. Make sure to include subscription key when making requests to an API",

  2000: "Channels Should Be Unique in Searchable Attribute Channels",
  2001: "Channels Should Be In Built In Channels",
  2003: "Attribute Search able Channels Must Be Exist",
  2004: "Attribute Publish able Exception Channels Should Be Exist",
  2005: "Attribute Item Should Not Be Duplicated",
  2006: "Attribute Must Include At Least One Translation",
  2007: "Attribute Code Must Be Unique",
  2008: "Attribute Item Default Value Must Be In Item List Id",
  2009: "Item Must Be Exist In Attribute",
  2010: "Attribute Should not Used In Group",
  2011: "List Type Must Have Same Translate In Item Translate",
  2012: "Attribute Group Code Must Be Unique",
  2013: "Attribute Group Is Used In Pim Product Family",
  2014: "Attribute Can Not Be Repeated In Group",
  2015: "Attribute Must Be Exist In Group",

  3000: "Product Category Can Not Be Deleted When Is Used In Pim Product",
  3001: "Product Category Can Only Be Published To Available Channels",
  3002: "Product Category Can Only Be Published To Built In Channels",
  3003: "Product Category Code Must Be Unique",
  3004: "Product Category Can Not It selfs Parent",
  3005: "Parent Of Root Product Category Cannot Be Changed",
  3006: "The Product Category And Its Parent Shoud Be In The Same Category",
  3007: "The Product Category Should Not Have Any Sub Product Category",
  3008: "Product Category Not Found",

  4000: "Supplier Not Found",
  4001: "Supplier Iln Must Be Unique",
  4002: "Supplier Already Exist",
  4003: "Supplier Could Not Be Deleted When Used In Selloff Product",
  4004: "Supplier Can Only Be Published To Built In Channels",
  4005: "Supplier Code Should Be Unique In Company",
  4010: "Discount Not Found",
  4011: "Invalid Discount Amount",
  4012: "Contact Person Not Found",
  5000: "Down Payment Accepted Range",
  5001: "Address Could Not Be Null",
  5003: "Important Documents Should Be Signed",
  5004: "Offer Should Have Items With Down Payment",

  6000: "Attribute Not Found",
  6001: "Configurable Program Has NoAttribute",
  6002: "Program Not Found",
  6003: "Program Attribute Code Should Be Unique",
  6004: "Info Page Not Found",
  6005: "MediaId Must Exist For Remove Media From Lahoma Order",
  6006: "Remove Picture Or Document Is Not Possible For Inprogress Lahoma Order",
  6007: "Document Must Exist To Remove From Lahoma Orders",
  6008: "Media Direction Must Be Unique In Perspective Media",
  6009: "Order Posted Lahoma Should Be Unique With in The Company",
  6010: "Remove Picture Or Document Is Not Possible For Submitted Lahoma Order",
  6011: "Position Code Must Be Unique",
  6012: "Banner Position Components Must Have Only One Item",
  6013: "Component Not Exists",
  6014: "Info Page Layout Not Found",
  6015: "Info Page Code Must Be Unique",
  6016: "Channel Setting Should Have One Active Setting",
  6017: "Value Is Not An ItemId",
  6018: "Lahoma Order Not Found",
  6019: "Position Not Found",

  7000: "Pim Product Not Found",
  7001: "Attribute Group Should Not Used In Pim Product Family Tree Path",
  7002: "Attribute Group Should Exist In Pim Product Family Tree Path",
  7003: "Leaf Can Removed In Pim Product Family Tree Path",
  7004: "Pim Product Family Code Should be Unique",
  7005: "Pim Product Family Could Not Be Rempved When Is Used In Pim Product",
  7006: "Pim Product Location Could Not Be Repeated",
  7007: "Pim Product Media Could Be Published To Built In Channels",
  7008: "Article Number Should Be Unique",
  7009: "Attribute Values Can Not Be Null Nor Empty",
  7010: "Pim Product Attributes Can Not Ne Null",
  7011: 'Pim Product Can Only Be Published To Built In Channels',
  7012: "Article Number Is Out Of Range",
  7013: "Pim Product Family As Parent Not Found",

  8000: "Line Item Not Found",
  8001: "Duplicated Offer Version Is Not Allowed",
  8002: "Offer Customer Add ress Should Be In Different Type",
  8003: "Add Sub Line Item Is Not Possible When Line Item Not Found",
  8004: "Only Active Offer Could Be Cloned",
  8005: "Last Version Offer Could Be Cloned",
  8006: "Only Accepted Offer Could Be Reactivate",
  8007: "Only Rejected Offer Could Be Reactivate",
  8008: "Reject Offer Is Allowed For Active Offer",
  8009: "Remove Document Is Not Possible When Document Not Found",
  8010: "Not Existing Line Item Could Not be Removed",
  8011: "Remove Sub Line Item Is Not Possible When Line Item Not Found",
  8012: "Not Existing Sub Line Item Could Not Be Removed",
  8013: "Not Existing Document Could Not Be Removed Or Updated",
  8014: "Signed Document Could Not Be Removed Or Updateed",
  8015: "Update Line Item Is Not Possible When Line Item Not Found",
  8016: "Only Active Offer Could Be Updated",
  8017: "Last Version Of Offer Colud Be Updated",
  8018: "Update Sub Line Item Is Not Possible When Line Item Not Found",
  8019: "Update Sub Line Item Is Not Possible When Sub Line Item Not Found",
  8020: "Only Active Order Could Be Canceled",
  8021: "Order Can Only Be Published To Built In Channels",
  8022: "Publish To Erp Is Possible When Order Documents With Sign Mandatory Signed",
  8023: "Signed Document Could Not Be Removed Or Updated",
  8024: "Active Order Could Be Updated",
  8025: "Offer Not Found",
  8026: "Document Not Found",
  8027: "Order Not Found",

  9000: "Prepration Phase Not Found",
  9001: "Sell Off Product Not Found",
  9002: "Sell Phase Template Not Found",
  9003: "Preparation Phase Not Found",
  9004: "Sell Off Product Time line Not Found",
  9005: "Sell Off Product Time line Already Exist",
  9006: "Check List Items Not Found",
  9007: "Order Of Sell Off Product Assets Should Be Unique",
  9008: "Change Phase Is Available After Current Phase Duration",
  9009: "Change Phase Is Only Possible For Active Sell Off Product",
  9010: "Change Phase Is Only Possible When All Sell Phase Check List Items Have Been Checked",
  9011: "Close Phase Is Available Only After Last Phase Duration",
  9012: "Close Phase Is Only Possible For Active Sell Off Product",
  9013: "Last Phase Can Not Be Changed",
  9014: "Media Id Must Exist For Remove Asset From Sell Off Product",
  9015: "Next Sell Phase Should Contains Check List Item",
  9016: "Only Last Phase Can Be Closed",
  9017: "Preparation Phase Should Contains Check List Item",
  9018: "Real Sold Price Should Greater Than Zero",
  9019: "Remove Picture Is Not Possible For Closed Sell Off Product",
  9020: "Remove Picture Is Not Possible For Sold Sell Off Product",
  9021: "Reorder Assets Is Not Possible For Closed Sell Off Product",
  9022: "Reorder Assets Is Not Possible For Sold Sell Off Product",
  9023: "Start Phase Is Not Possible Without Product family",
  9024: "Start Phase Is Not Possible Without Sell Phase Template",
  9025: "Sold Is Available Only After Sell Off Product Start Date Time",
  9026: "Sold Is Only Possible For Active Sell Off Product",
  9027: "Start Phase Is Only Possible For Preparation Sell Off Product",
  9028: "Start Phase Is Only Possible When All Preparation Phase Check List Items Have Been Checked",
  9029: "Update Is Not Possible For Closed Sell Off Product",
  9030: "Update Is Not Possible For Sold Sell Off Product",
  9031: "Update Preparation Phase Check List Item Is Only Possible For Preparation Sell Off Product",
  9032: "Update Sell Phase Check List Item Is Available Only After Current Phase Duration",
  9033: "Update Sell Phase Check List Item Is Only Possible For Active Sell Off Product",
  9034: "Update Sell Phase Template Is Not Possible For Active Sell Off Product",
  9035: "Withdraw Sold Item Is Only Possible For Sold Sell Off Product",
  9036: "Sell Phase Template Code Must Be Unique",

  11000: "Provider Product Import Pursuit Not Found",
  11001: "Provider Product Import Not Found",

  7014:"Pim Product Attribute Is Required",
  7015:"Pim Product Attribute Is PerLanguage",
  7016:"Pim Product Attribute Is PerChannel",

  1019:"public const string Holiday Not Found",

  7018:"Pim Product Attribute Is Not In Pim Based On Pim Product Family"
};
