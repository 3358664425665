import { useState, useEffect } from "react";
import { useToggleState, usePermission } from "hooks";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  Icon,
  DeleteModal,
  NoItems
} from "components";
import { ServiceApi } from "services";
import { Link, useSearchParams } from "react-router-dom";
import { URLS } from "services";
import AddModal from "./add-modal";
import DetailsModal from "./details-modal";
import { config, appPermissions } from "constant";
import { NotificationManager } from "common/notifications";


type ItemsType = {
  checkListItemId: string;
  label: string;
  description: string;
};

type ListType = {
  items?: ItemsType[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function SalesChecklist() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [selected, setSelected] = useState<any>();
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();


  const [isOpen, toggle] = useToggleState(false);
  const [isOpenUpdate, toggleUpdate] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);


  //permissions
  const { PS_CreateCheckListItem, PS_DeleteCheckListItem, PS_GetCheckListItem, PS_UpdateCheckListItem } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(PS_GetCheckListItem);
  const DELETE_PERMISSION = usePermission(PS_DeleteCheckListItem);
  const CREATE_PERMISSION = usePermission(PS_CreateCheckListItem);
  const UPDATE_PERMISSION = usePermission(PS_UpdateCheckListItem);

  const handleSetParams = (key: any) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_CHECK_LIST_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config).then(({ data }) => {
      setData(data);
      setLoadingList(false);
    });
  };

  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_CHECK_LIST_URL(id);
    ServiceApi.delete(url).then(({ data }) => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      getData();
      setLoadingButton(false);
      toggleDeleteModal()
    }).catch(() => setLoadingButton(false));
  };

  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        {loadingList ? (
          <Skeleton.Button />
        ) : CREATE_PERMISSION ? (
          <Button onClick={() => toggle()}>
            <Icon className="mr-2" icon={"plus"} />
            <Text>salesManagment.sellOffSales.salesChecklist.addCheckList</Text>
          </Button>
        ) : null}
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ?
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.checkListItemId}>
                      <td className="text-heading-6 text-gray-800">
                        <Link to=""
                          onClick={() => {
                            if (UPDATE_PERMISSION) {
                              setSelected(item.checkListItemId)
                              toggleUpdate()
                            }
                          }} className="w-fit inline-flex flex-center gap-2">
                          <h6>{item.label}</h6>
                        </Link>
                      </td>
                      <td className="text-body-base text-gray-800 text-left">
                        <span className="ml-1">{item.description}</span>
                      </td>
                      <td>
                        <div className="flex flex-row-reverse">
                          <div>
                            {DELETE_PERMISSION && (
                              <Button
                                size="sm"
                                variant="danger"
                                light
                                onClick={() => {
                                  setSelected(item);
                                  toggleDeleteModal();
                                }}
                              >
                                <Icon icon={"trash"} />
                              </Button>
                            )}
                          </div>
                          <div>
                            {UPDATE_PERMISSION && <Button
                              type="button"
                              className="mr-2"
                              onClick={() => {
                                setSelected(item.checkListItemId);
                                toggleUpdate()
                              }}
                              variant="light"
                              size="sm">
                              <Text>global.buttons.details</Text>
                            </Button>}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages || 1}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
      <AddModal isOpen={isOpen} toggle={toggle} getData={getData} />
      <DetailsModal isOpen={isOpenUpdate} toggle={toggleUpdate} getData={getData} selected={selected} />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: selected?.label, id: selected?.checkListItemId }}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </div>
  ) : (
    <>Permission Error</>
  );
}
