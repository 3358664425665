import { Icon, Text } from "components";
import { useConverter } from "hooks";
import { useMemo } from "react";
import Percent from "./Percent";
type progressBarProps = {
  year: string | number;
  value: number;
  amount: number;
  percent?: null | number;
  variant?: "success" | "danger" | "gray";
};
export default function ProgressBar({
  year,
  value,
  amount,
  percent = null,
  variant = "gray",
}: progressBarProps) {
  const { convertAmount } = useConverter();
  const color = useMemo(() => {
    const bg = {
      success: "bg-success",
      danger: "bg-danger",
      gray: "bg-gray-400",
    }[variant];
    const text = {
      success: "text-success",
      danger: "text-danger",
      gray: "text-gray-600",
    }[variant];
    return { bg, text };
  }, [variant]);
  return (
    <section className="space-y-2">
      <div className="w-full h-4 rounded-full overflow-hidden">
        <span
          style={{ width: `${value}%` }}
          className={`block max-w-full h-full rounded-full ${color.bg}`}
        />
      </div>
      <p className={`text-body-1 ${color.text}`}>
        <Text>salesManagment.kitchenSales.salesReport.totalSales</Text> {year}{" "}
        <span className="text-heading-6 font-semibold">
          {convertAmount(amount)}
        </span>{" "}
        {percent && <Percent value={percent} />}
      </p>
    </section>
  );
}
