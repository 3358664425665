import { NotificationManager } from "common/notifications";
import { Button, Icon, NoItems, Skeleton, Table, Text } from "components";
import { appPermissions } from "constant";
import { usePermission, useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import { convertDate } from "utils";
import { PimContext } from "..";
import Preview from "../drawers/preview";

const ChannelsTab = () => {
  const { i18n } = useTranslation();
  const { productData, channels } = useContext(PimContext);
  const [loading, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [publishLoading, setPublishLoading] = useToggleState();
  const [publishedListCode, setPublishedListCode] = useState<any>([]);
  const [publishedList, setPublishedList] = useState<any>([]);

  const getPublishedPimChannels = () => {
    setLoading();
    const url = URLS.GET_CHANNEL_LIST_BY_PIM_PRODUCT_ID_URL(productData?.id);
    ServiceApi.get(url)
      .then(({ data }) => {
        const list = data.map((p: any) => p.channelCode);
        setPublishedListCode(list);
        setPublishedList(data);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    getPublishedPimChannels();
  }, []);

  const unPublishHandler = (channel: any) => {
    setPublishLoading();
    const url = URLS.UNPUBLISH_PRODUCT(productData?.id);
    const body = { channelCode: channel.code };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.unpublish-msg",
          "global.toast.unpublish-title"
        );
        setPublishLoading();
        getPublishedPimChannels();
      })
      .catch(() => setPublishLoading());
  };

  const publishHandler = (channel: any) => {
    setPublishLoading();
    const url = URLS.PUBLISH_PIM_PRODUCT_TO_CHANNEL_URL(productData?.id);
    const body = { channelCode: channel.code };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.publish-msg",
          "global.toast.publish-title"
        );
        setPublishLoading();
        getPublishedPimChannels();
      })
      .catch(() => setPublishLoading());
  };

  //permissions

  const { PS_PublishPimProductToChannel } = appPermissions;
  const PUBLISH_TO_CHANNEL_PERMISSION = usePermission(
    PS_PublishPimProductToChannel
  );

  return (
    <div className="space-y-4">
      {channels.length !== 0 && <div className="flex justify-between">
        <Button
          disabled={loading}
          variant={"white"}
          textColor="primary"
          onClick={toggle}
        >
          <Icon icon="eye" className="mr-2" />
          <Text>productManagement.products.Details.previewCurrentVersion</Text> (
          {productData?.version})
        </Button>
        {/* <div className="space-x-4">
          <Button disabled={loading} variant={"white"} textColor="danger">
            Unpublish from all channels
          </Button>
          <Button disabled={loading} variant={"white"} textColor="primary">
            Publish in all channels
          </Button>
        </div> */}
      </div>
      }
      {loading ? (
        [1, 2, 3, 4, 5].map((l) => <Skeleton.List key={l} />)
      ) : (
        <Table>
          {channels.length !== 0 ?
            channels.map((channel: any) => {
              return (
                <tr className="text-heading-6 font-semibold" key={channel?.code}>
                  <td>
                    {
                      channel?.channelNameTranslates?.filter(
                        (tr: any) => tr.language === i18n.language.toLowerCase()
                      )[0]?.name
                    }
                  </td>
                  <td>
                    {publishedListCode.includes(channel?.code) && (
                      <p className="text-body-base font-normal">
                        <Text>
                          productManagement.products.Details.publishedVersion
                        </Text>{" "}
                        :{" "}
                        <span className="font-bold">
                          {
                            publishedList.filter(
                              (info: any) => channel?.code === info.channelCode
                            )?.[0].version
                          }
                        </span>
                        <span className="ml-3">
                          {convertDate(
                            publishedList.filter(
                              (info: any) => channel?.code === info.channelCode
                            )?.[0].publishedAt
                          )}
                        </span>
                      </p>
                    )}
                  </td>
                  {PUBLISH_TO_CHANNEL_PERMISSION && (
                    <td>
                      {publishedListCode.includes(channel?.code) ? (
                        <>
                          <Button
                            light
                            variant="danger"
                            size="sm"
                            onClick={() => unPublishHandler(channel)}
                            disabled={publishLoading}
                            className="mr-2"
                          >
                            <Text>
                              productManagement.products.Details.unPublish
                            </Text>
                          </Button>
                          <Button
                            light
                            size="sm"
                            onClick={() => publishHandler(channel)}
                            disabled={publishLoading}
                          >
                            <Text>
                              productManagement.products.Details.rePublish
                            </Text>
                          </Button>
                        </>
                      ) : (
                        <Button
                          light
                          size="sm"
                          onClick={() => publishHandler(channel)}
                          disabled={publishLoading}
                        >
                          <Text>
                            productManagement.products.Details.publishCurrentVersion
                          </Text>
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              );
            }) :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
              <Text>productManagement.products.Details.thereIsNoChannelEnabledForYourCompany</Text>!
            </div>
          }
        </Table>
      )}
      <Preview isOpen={isOpen} toggle={toggle} />
    </div>
  );
};

export default ChannelsTab;
