export const userList = {
    inviteUser: "Invite User",
    addUser: "Add User",
    general: "General",
    group: "Group",
    store: "Store",
    stores: "Stores",
    authenticationSetting: "Authentication setting",
    basicInfo: "Basic Info",
    address: "Address",
    usersGroup: "Users Group",
    firstName: "First Name",
    lastName: "last Name",
    gender: "gender",
    dateOfBirth: "Date Of Birth",
    personalNumber: "Personal Number",
    phoneNumber: "Phone Number",
    mobileNumber: "Mobile Number",
    emailAddress: "Email Address",
    active: "Active",
    setDefaultFilter: "Set as default filter",
    providerName: "Provider name",
    userName: "User Name",
    activeLogin: "Active Login",
    generalDesc: "First Name, Last Name, ...",
    location: "Location",
    locationDesc: "User location",
    userGroup: "User Group",
    userGroupDesc: "Select User Group",
    review: "Review",
    reviewDesc: "Finalize information",
    searchUser: "Search user",
    search: "Search",
    //.....Provider name 
    local: "Local",
    add: "ADD",
}