
import de from "assets/icons/flag/de.svg"
import en from "assets/icons/flag/en.svg"
import tr from "assets/icons/flag/tr.svg"
import fr from "assets/icons/flag/fr.svg"

const flagCountry: any = {
  de,
  en,
  tr,
  fr
};
export default flagCountry;
