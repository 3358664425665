export const infoPage = {
  // Add info page
  addInfoPageTitle: "Add page",
  infoPageTitle: "Page title",
  infoPageCode: "Code",
  infoPageDescription: "Description",
  infoPageCloseButton: "Close",
  infoPageSubmitButton: "Submit",
  infoPageDetailsButton: "Details",
  // Main section
  deletePageButton: "Delete page",
  activeStatus: "Active",
  inactiveStatus: "Inactive",
  layoutDetails: "Details",
  // Edit info page
  editPage: "Edit page",
  pageTitle: "Page title",
  description: "Description",
  closeEditModal: "Close",
  submitEditModal: "Submit",
  reorderLayoutsToast: "Layout reordered successfully. DE",
  // Add layout form
  addLayoutButton: "Add layout",
  addLayoutHeader: "Add layout",
  layoutTitle: "Title",
  selectLayouts: "Layouts",
  closeLayoutMenu: "Close",
  submitAddLayout: "Add layout",
  addLayoutToast: "Layout added successfully.",
  // Edit banner
  bannerTitle: "Title",
  bannerDescription: "Description",
  bannerLinkAssignedToItem: "Link assigned to item",
  bannerLink: "Link",
  bannerButtonLink: "Button link",
  bannerButtonLabel: "Button label",
  bannerCancelButton: "Cancel",
  bannerSubmitButton: "Submit",
  bannerEditedToast: "Layout edited successfully.",
  // Edit Banner Style Items
  bannerBackgroundColor: "Background color",
  bannerTitleTextColor: "Title color",
  bannerBodyTextColor: "Description color",
  bannerButtonBackgroundColor: "Button background color",
  bannerButtonTextColor: "Button text color",
  // Edit products menu
  productsTitle: "Title",
  productsSection: "Products",
  productsAddButton: "Add product",
  productsCancelButton: "Cancel",
  productsSubmitButton: "Submit",
  productsEditedToast: "Layout edited successfully.",
  productsRemovedToast: "Layout removed successfully.",
  // Edit product menu
  productAddTitle: "Add product",
  productEditTitle: "Edit product",
  productLabel: "Label",
  productPrice: "Price",
  productDiscount: "Discount",
  productSelectImage: "Select image cover",
  productAddImageButton: "Add image",
  productCancelButton: "Cancel",
  productSubmitButton: "Submit",
  // Remove layout
  removeLayoutToast: "Layout removed successfully.",
};
