import { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup, Text, Icon } from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import { useDataState } from "hooks";
import { rules } from "constant";
import { NotificationManager } from "common/notifications";

type addModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: () => void;
};

export default function AddModal({ isOpen, toggle, getData }: addModalType) {
  const [data, setData] = useDataState({
    label: "",
    description: "",
  });
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setData({
        label: "",
        description: "",
      })
    }
  }, [isOpen])// eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.CREATE_CHECK_LIST_URL;
    ServiceApi.post(url, data).then((res) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      getData();
    }).finally(() => {
      toggle();
      setLoadingButton(false);

    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Form onSubmit={submit}>
        <Modal.Header className="flex flex-row">
          <h6 className="text-heading-2 text-gray-800">
            <Text>salesManagment.sellOffSales.salesChecklist.addCheckList</Text>
          </h6>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            <InputGroup
              label="salesManagment.sellOffSales.salesChecklist.title"
              value={data.label}
              className="border-white"
              setValue={(label) => setData((p) => ({ ...p, label }))}
              rules={rules.required}
            />
            <InputGroup
              as="short-textarea"
              label="salesManagment.sellOffSales.salesChecklist.description"
              value={data.description}
              className="border-white"
              setValue={(description) => setData((p) => ({ ...p, description }))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="submit" loading={loadingButton}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
