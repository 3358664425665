import {
  Button,
  Icon,
  InputGroup,
  Modal,
  Pagination,
  Skeleton,
  Table,
  Text,
} from "components";
import { rules } from "constant";
import { useToggleState } from "hooks";
import { Fragment, useEffect, useMemo, useState } from "react";
import { ServiceApi } from "services";

type selectInfoPageProps = {
  value: string | null;
  setValue: (code: string) => void;
};
type dataType = {
  items: { name: string; id: string }[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};
export default function SelectInfoPage({
  value,
  setValue,
}: selectInfoPageProps) {
  const [isOpen, toggle] = useToggleState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} as dataType);
  const [params, setParams] = useState({
    pageSize: 50,
    pageNumber: 1,
    cache: true,
  });
  const handleShowValue = useMemo(() => {
    if (!data.items?.length) return value;
    return data.items?.find((e) => e.id === value)?.name;
  }, [data.items, value]);
  const handleSetValue = (id: string) => {
    return () => {
      setValue(id);
      toggle();
    };
  };
  const getInfoPages = () => {
    if (!isOpen) return;
    setLoading(true);
    const url = "/productservice/api/infopages";
    const config = { params };
    ServiceApi.get(url, config)
      .then(({ data }) => {
        const result = { ...data };
        result.items = result.items.map((e: any) => ({
          name: e.title,
          id: e.code,
        }));
        setData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getInfoPages, [isOpen, params]);
  return (
    <Fragment>
      <InputGroup
        label="applications.container.infoPage.link"
        value={handleShowValue}
        rules={rules.required}
        onClick={toggle}
        readOnly
      />
      <Modal isOpen={isOpen} toggle={toggle} modalClassName="!z-50">
        <Modal.Header className="flex items-center justify-between">
          <h5>
            <Text>applications.container.infoPage.header</Text>:
          </h5>
          <Button
            type="button"
            variant="white"
            className="!text-danger"
            onClick={toggle}
          >
            <Icon icon="close" />{" "}
            <Text>applications.container.infoPage.closeButton</Text>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="space-y-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </div>
          ) : (
            <Table>
              {data.items?.map((e) => (
                <tr
                  key={e.id}
                  onClick={handleSetValue(e.id)}
                  className="cursor-pointer"
                >
                  <td>{e.name}</td>
                  <td>{e.id}</td>
                </tr>
              ))}
            </Table>
          )}
          <Pagination
            pageNumber={params.pageNumber}
            pageSize={params.pageSize}
            totalItems={data.totalItems}
            totalPages={data.totalPages}
            setActivePage={(value) =>
              setParams((p) => ({ ...p, pageNumber: +value }))
            }
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
