export const attributes = {
  addAttributes: "Attribute hinzufügen",
  code: "Code",
  name: "Name",
  title: "Titel",
  description: "Beschreibung",
  shortDescription: "Kurzbeschreibung",
  perChannel: "Pro Kanal",
  type: "Typ",
  attributeType: "Attributtyp",
  attributeTypeDesc: "Text, Datum, Ja/Nein ...",
  infoAndProperties: "Informationen und Eigenschaften",
  infoAndPropertiesDesc: "Name, Beschreibung ...",
  review: "Überprüfung",
  reviewDesc: "Informationen abschließen",
  attributeName: "Attributname",
  attributeCode: "Attributcode",
  isRequired: "Erforderlich",
  perLanguage: "Pro Sprache",
  chooseAttributeType: "Attributtyp wählen",
  basicInfo: "Grundlegende Informationen",
  properties: "Eigenschaften",
  translation: "Übersetzung",
  general: "Allgemein",
  channels: "Kanäle",
  setting: "Einstellung",
  itemsList: "Elementliste",
  default: "Standard",
  addItem: "Element hinzufügen",
  editItem: "Element bearbeiten",
  positiveName: "Positiver Name",
  negativeName: "Negativer Name",
  updatefromProviderAttribute: "Von Anbieterattribut aktualisieren",
  showAttributeInFilters: "Attribut in Filtern anzeigen",
  attributeDefaultValue: "Standardwert des Attributes",
  measurementTypeList: "Liste der Maßeinheitstypen",
  unitsList: "Einheitenliste",
  order: "Reihenfolge",
  placeHolder: "Placeholder DE",
  value: "Value DE",
};
