import React, { useContext, useEffect, useRef, ReactNode } from "react";
import Portal from "./Portal";
import { sizeType } from "types";
type modalContext = {
  closeBtn: boolean;
  toggle: () => void;
};
type modalProps = {
  isOpen: boolean;
  toggle?: () => void;
  children: ReactNode;
  closeBtn?: boolean;
  size?: sizeType;
  className?: string;
  modalClassName?: string;
  width?: string;
};
type modalHeader = {
  className?: string;
  children: ReactNode;
};

type modalBody = {
  className?: string;
  children: ReactNode;
};
type modalFooter = {
  className?: string;
  children: ReactNode;
};

const ModalContext = React.createContext({} as modalContext);
function Modal({
  isOpen,
  toggle = () => {},
  closeBtn = false,
  size = "md",
  className = "",
  modalClassName = "",
  children,
  width = "w-full",
}: modalProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    modalRef.current?.classList.toggle("active", isOpen);
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);
  return (
    <Portal>
      <div
        ref={modalRef}
        className={`modal group fixed inset-0 flex flex-col z-[100] bg-black/20 opacity-0 pointer-events-none transition-opacity !m-0 [&.active]:opacity-100 [&.active]:pointer-events-auto ${modalClassName}`}
      >
        <button
          type="button"
          onClick={() => toggle()}
          className="absolute inset-0 cursor-default opacity-0"
        />
        <div
          className={`modal-dialog modal-${size} relative flex flex-col bg-white m-auto ${width} rounded shadow transition-transform max-h-[97.5%] translate-y-[25vh] group-[.modal.active]:translate-y-0 min-[576px]:max-w-[500px] min-[576px]:[&.modal-sm]:max-w-[300px] min-[992px]:[&.modal-lg]:max-w-[800px] min-[992px]:[&.modal-xl]:max-w-[800px] min-[1200px]:[&.modal-xl]:max-w-[1140px] ${className}`}
        >
          <ModalContext.Provider value={{ closeBtn, toggle }}>
            {children}
          </ModalContext.Provider>
        </div>
      </div>
    </Portal>
  );
}
function ModalHeader({ className = "", children = null }: modalHeader) {
  const { closeBtn, toggle } = useContext(ModalContext);
  return (
    <div className="w-full flex-center rounded-t gap-2 p-6 border-b border-gray bg-white">
      {closeBtn && <span className="w-6 h-6" />}
      <div className={`flex-1 text-center ${className}`}>{children}</div>
      {closeBtn && (
        <button
          type="button"
          onClick={toggle}
          className="w-6 h-6 bi bi-x-lg text-dark rounded p-1 hover:text-danger transition-colors"
        />
      )}
    </div>
  );
}
function ModalBody({ className = "", children = null }: modalBody) {
  return (
    <div className={`p-6 overflow-auto flex-1 ${className}`}>{children}</div>
  );
}
function ModalFooter({ className = "", children = null }: modalFooter) {
  return (
    <div
      className={`w-full p-6 border-t border-gray rounded-b bottom-0 bg-white ${className}`}
    >
      {children}
    </div>
  );
}
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
export default Modal;
