import { useEffect, useMemo, useState } from "react";
import { useTranslate } from "../hooks";
import { Icon, Button, TotalItems, Text } from "components";

type searchBoxProps = {
  value?: string;
  placeholder?: string;
  variant?: "white" | "gray";
  onSubmit: (val: string | null) => void;
  onFilter?: () => void;
  resetFilter?: () => void;
  disabled?: boolean;
  totalItems?: any;
  className?: string;
  formClassName?: any;
};

export default function SearchBox({
  value,
  placeholder = "global.placeholders.search",
  onSubmit = () => { },
  onFilter,
  resetFilter,
  variant = "white",
  disabled = false,
  totalItems,
  className,
  formClassName = "",
}: searchBoxProps) {
  const translate = useTranslate();
  const activeVariant = useMemo(() => {
    const variants: any = {
      white: "bg-white",
      gray: "bg-gray-100",
    };
    return variants[variant];
  }, [variant]);
  const [submitted, setSubmitted] = useState(false);
  const [valueInput, setValueInput] = useState<any>(value);

  useEffect(() => {
    setValueInput(value);
    if (value && value !== "") {
      setSubmitted(true)
    } else {
      setSubmitted(false)
    }
  }, [value]);

  const clearValue = () => {
    setValueInput("");
    onSubmit("");
    setSubmitted(false);
  };
  const submit = (e: any) => {
    e.preventDefault();
    onSubmit(valueInput);
    if (valueInput && valueInput !== "") {
      setSubmitted(true)
    } else {
      setSubmitted(false)
    }
  };
  const handleChange = (e: any) => {
    setValueInput(e.target.value);
  };
  return (
    <div
      className={`lg:flex flex-wrap gap-1 lg:justify-between lg:items-center space-y-1 w-full ${className}`}
    >
      <div className="flex items-center lg:flex-center gap-2">
        <form
          onSubmit={submit}
          className={`${formClassName} md:w-[15rem] lg:w-[20rem] text-body-2 font-medium gap-2 rounded px-2 py-1 ${activeVariant} focus-within:ring-1 ring-gray-500 ring-offset-1 transition-shadow`}
        >
          <button type="submit">
            <Icon icon="search" />
          </button>
          <input
            value={valueInput}
            onChange={handleChange}
            placeholder={translate(placeholder) ?? ""}
            className="flex-1 h-8 px-2"
            disabled={disabled}
            data-lang-map={placeholder}
          />
          {submitted && (
            <button
              type="button"
              onClick={clearValue}
              className="bi bi-x-lg text-dark hover:text-danger transition-colors float-right leading-[31px]"
            />
          )}
        </form>
        <div
          className="bg-white flex items-center justify-center rounded-md cursor-pointer h-[38px] w-[38px]"
          onClick={() => {
            if (valueInput) {
              onSubmit(valueInput);
              setSubmitted(true);
            }
          }}
        >
          <Icon size="1x" className="text-primary" icon={"search"} />
        </div>
        <TotalItems totalItems={totalItems} />
      </div>
      {onFilter && (
        <div className="space-x-2">
          {resetFilter && (
            <Button
              variant={"danger"}
              light
              size="sm"
              onClick={() => {
                clearValue();
                resetFilter();
              }}
              disabled={disabled}
            >
              <Icon icon="times-circle" className="mr-1" />
              <Text>global.buttons.resetFilters</Text>
            </Button>
          )}
          <Button
            variant="white"
            size="sm"
            onClick={() => onFilter()}
            disabled={disabled}
          >
            <Text>global.buttons.filters</Text>
          </Button>
        </div>
      )}
    </div>
  );
}
