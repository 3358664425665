import { classNames } from "utils";

export const createClassNames = (isActive: boolean) => {
  const sidebar = classNames(
    `menu group fixed flex flex-col top-0 left-0 h-full bg-primary-active pb-8 border-r border-gray-200 shadow transition-[width] z-[70]`,
    {
      "w-[265px]": isActive,
      "w-[75px]": !isActive,
    }
  );

  const menuHeader = "h-16 bg-primary flex items-center flex-none";

  const drawer = classNames(
    "flex flex-center rounded overflow-hidden p-[5px] w-6 h-6 transition-transform absolute -right-[.7rem] bg-white shadow-md",
    {
      "rotate-180": !isActive,
    }
  ); //rotate-180

  const logo = classNames("max-w-none rounded-sm m-auto", {
    // hidden: !isActive,
    block: isActive,
  });

  return {
    drawer,
    logo,
    sidebar,
    menuHeader,
  };
};
