import { NotificationManager } from "common/notifications";
import { Dropdown, Icon, Image, ImageUploader, Text } from "components";
import { appPermissions } from "constant";
import { usePermission, useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import { PimContext } from "..";
import ImageSettingDrawer from "../drawers/image-setting";

const MediaTab = () => {
  const { i18n } = useTranslation();
  const { productData, channels } = useContext(PimContext);
  const [isOpen, toggle] = useToggleState();
  const [medias, setMedias] = useState<any>([]);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [isOpenUploader, setUploaderToggle] = useToggleState();
  const [loading, setLoading] = useToggleState();
  const [selectedChannel, setSelectedChannel] = useState<any>();
  const [channelDropdown, setchannelDropdown] = useState<any>([]);
  //permissions

  const {
    PS_CreatePimProductMedia,
    PS_UpdatePimProductMedia,
    PS_RemovePimProductMedia,
    PS_GetPimProductMedia,
    PS_GetPimProductMediaList,
  } = appPermissions;

  const CREATE_PERMISSION = usePermission(PS_CreatePimProductMedia);
  const UPDATE_PERMISSION = usePermission(PS_UpdatePimProductMedia);
  const REMOVE_PERMISSION = usePermission(PS_RemovePimProductMedia);
  const GET_LIST_PERMISSION = usePermission(PS_GetPimProductMediaList);

  const getMedias = () => {
    setMedias([]);
    if (GET_LIST_PERMISSION) {
      setLoading();
      const url = URLS.GET_PIM_PRODUCT_MEDIA_LIST_URL(productData.id, 1, 100);
      ServiceApi.get(url)
        .then(({ data }) => {
          setMedias(data.items);
          setLoading();
        })
        .catch(() => setLoading());
    }
  };

  useEffect(() => {
    getMedias();
    setchannelDropdown([{ code: "allChannel" }, ...channels]);
    if (!selectedChannel) {
      setSelectedChannel("allChannel");
    }
  }, [productData]);

  const onUploadHandler = (data: any) => {
    const mediaFields = channels?.map((channel: any) => [
      {
        channelCode: channel.code,
        description: null,
        language: "de",
        placeHolder: null,
      },
      {
        channelCode: channel.code,
        description: null,
        language: "en",
        placeHolder: null,
      },
    ]);
    const image = {
      thumbnailUrl: data.thumbnailUrl,
      url: data.url,
      type: data.type,
      pimProductId: productData?.id,
      publishForAllChannels: true,
      mediaFields: mediaFields.flat(),
      channelsCodeList: [],
      mediaMetadata: data.metaData,
      tags: [],
    };

    const url = URLS.CREATE_PIM_PRODUCT_MEDIA_URL;
    ServiceApi.post(url, image).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      getMedias();
    });
  };

  const findTranslatedName = (channel: any) => {
    return channel?.channelNameTranslates?.filter(
      (tr: any) => tr.language.toLowerCase() === i18n.language
    )?.[0]?.name;
  };

  return (
    <>
      {medias?.length > 0 && (
        <div className="flex justify-end space-x-2 items-center">
          <Dropdown>
            <Dropdown.Toggle
              as="button"
              type="button"
              className="text-gray-600 font-medium text-body-base uppercase flex space-x-2 bg-gray-100 p-3 rounded-md min-w-[10rem]"
            >
              <span>
                <span className="mr-2">
                  <Text>productManagement.products.Details.channels</Text> :
                </span>{" "}
                {findTranslatedName(
                  channels.filter(
                    (channel: any) => channel?.code === selectedChannel
                  )?.[0]
                ) || "All Channels"}{" "}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="min-w-fit mt-2 p-2">
              <ul className="text-gray-700 w-max">
                {channelDropdown.map((channel: any) => (
                  <li
                    key={channel?.code}
                    onClick={() => {
                      setSelectedChannel(channel.code);
                    }}
                    className={`py-2 font-medium first:mb-1 px-4 h6 ${
                      channel.code === selectedChannel &&
                      "bg-primary-light text-primary "
                    } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                  >
                    {findTranslatedName(channel) || "All Channels"}
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 ">
        {CREATE_PERMISSION && (
          <div
            className="h-[276px] bg-white text-primary flex-center rounded-lg border border-dashed border-primary flex flex-col gap-2 cursor-pointer select-none"
            onClick={setUploaderToggle}
          >
            <Icon
              icon="plus"
              size="xs"
              className="bg-primary-light p-2 rounded-md"
            />
            <Text>productManagement.products.Details.addMedia</Text>
          </div>
        )}
        {loading &&
          [1, 2, 3].map((key) => (
            <div
              className="w-[276px] bg-white rounded animate-pulse"
              key={key}
            ></div>
          ))}

        {medias?.map((image: any) => {
          return (
            <>
              {(image.channelsCodeList?.length === 0 ||
                image.channelsCodeList?.includes(selectedChannel) ||
                selectedChannel === "allChannel") && (
                <Image
                  key={image?.url}
                  src={image?.url}
                  className="max-w-lg cursor-pointer flex-none bg-gray-100"
                  imageClassName="h-[276px] w-full"
                  onClick={() => {
                    setSelectedImage(image);
                    if (UPDATE_PERMISSION) {
                      toggle();
                    }
                  }}
                />
              )}
            </>
          );
        })}
        <ImageSettingDrawer
          isOpen={isOpen}
          toggle={toggle}
          image={selectedImage}
          getMedias={getMedias}
        />
        <ImageUploader
          isOpen={isOpenUploader}
          toggle={setUploaderToggle}
          image={""}
          onUpload={(image) => onUploadHandler(image)}
        />
      </div>
    </>
  );
};

export default MediaTab;
