import { infoPage } from "./info-page";
import { positions } from "./positions";

export const applications = {
  positions,
  infoPage,
  container: {
    infoPage: {
      header: "Info-Seite auswählen",
      link: "Link",
      closeButton: "Schließen",
    },
    product: {
      header: "Produkt auswählen",
      link: "Link",
      closeButton: "Schließen",
    },
  },
};