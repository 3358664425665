import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  Icon,
  NoItems,
} from "components";
import { usePermission, useToggleState } from "hooks";
import { ServiceApi } from "services";
import { URLS } from "services";
import { Link, useSearchParams } from "react-router-dom";
import { config, appPermissions } from "constant";
import AddDrawer from "./add-drawer";

type LocationsItems = {
  placeId: string;
  code: string;
  displayName: string;
  storeTitle: string;
  space: number;
};

type ListType = {
  items?: LocationsItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function Locations() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [isOpenDrawerCreate, toggleDrawerCreate] = useToggleState(false);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_LOCATIONS_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  //permissions
  const { PS_GetPlaces, PS_CreatePlace, PS_GetPlaceDetail } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(PS_GetPlaces);
  const CREATE_PERMISSION = usePermission(PS_CreatePlace);
  const GET_DETAIL_PERMISSION = usePermission(PS_GetPlaceDetail);

  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        {loadingList ? (
          <Skeleton.Button />
        ) : CREATE_PERMISSION ? (
          <Button type="button" onClick={toggleDrawerCreate}>
            <Icon className="mr-2" icon={"plus"} />
            <Text>salesManagment.salesArea.locations.addLocation</Text>
          </Button>
        ) : null}
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.placeId}>
                      <td>
                        <Link
                          to={GET_DETAIL_PERMISSION ? item.placeId : ""}
                          className="w-fit inline-flex gap-2"
                        >
                          <div className="space-y-2">
                            <h6 className="text-heading-6 text-gray-800 font-semibold">
                              {item.code}
                            </h6>
                            <div>
                              <span className="text-body-2 text-gray-500 font-normal">
                                {item.displayName}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="text-body-base text-gray-800 font-normal text-left">
                        {item.storeTitle}
                      </td>
                      <td className="text-body-base text-gray-600 font-normal ">
                        <Text>salesManagment.salesArea.locations.space</Text>:{" "}
                        <span className="mr-1">{item.space}</span>m<sup>2</sup>
                      </td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button
                            as={Link}
                            to={item.placeId}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams((p) => ({ ...p, pageSize: e }))
                }
              />
            </>
          ) : (
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          )}
        </>
      )}
      <AddDrawer
        isOpen={isOpenDrawerCreate}
        toggle={toggleDrawerCreate}
        getData={getData}
      />
    </div>
  ) : (
    <>Permission Error</>
  );
}
