import { Skeleton, Status, Text, Wrapper } from "components";
import { dateFormats } from "constant";
import { useConverter } from "hooks";
import { Fragment, useContext } from "react";
import { CustomerContext } from "..";

export default function General() {
  const { userData, loading } = useContext<any>(CustomerContext);
  const { convertDate } = useConverter();

  const genderType = (e: any) => {
    switch (e) {
      case 1:
        return "Male";
      case 2:
        return "Female";
      default:
        return "Rather not say";
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      <Wrapper.Section className="space-y-4">
        <h3 className="text-heading-3 text-gray-600 font-semibold mb-2">
          <Text>company.customers.customerList.basicInfo</Text>
        </h3>
        {loading ? (
          <Fragment>
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
          </Fragment>
        ) : (
          <Fragment>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.firstName</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.personInfo?.firstName}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.lastName</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.personInfo?.lastName}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.birthdate</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {convertDate(
                  userData?.personInfo?.birthdate,
                  dateFormats.onlyDate
                )}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.gender</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {genderType(userData?.personInfo?.gender)}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.emailAddress</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.personInfo?.emailAddress}
              </h5>
            </div>
          </Fragment>
        )}
      </Wrapper.Section>
      <Wrapper.Section className="space-y-4">
        <h3 className="text-heading-3 text-gray-600 font-semibold mb-2">
          <Text>company.customers.customerList.address</Text>
        </h3>
        {loading ? (
          <Fragment>
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
          </Fragment>
        ) : (
          <>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.street</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.addresses?.street}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.houseNumber</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.addresses?.houseNumber}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.postalCode</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.addresses?.postalCode}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.city</Text>:
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.addresses?.city}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.state</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.addresses?.state}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.country</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.addresses?.country}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.phoneNumber</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.personInfo?.phoneNumber}
              </h5>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.mobileNumber</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.personInfo?.mobileNumber}
              </h5>
            </div>
          </>
        )}
      </Wrapper.Section>
      <Wrapper.Section className="space-y-4">
        <h3 className="text-heading-3 text-gray-600 font-semibold mb-2">
          <Text>company.customers.customerList.channels</Text>
        </h3>
        {loading ? (
          <Fragment>
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
          </Fragment>
        ) : (
          <>
            <div className="grid grid-cols-2 gap-8 items-center">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.email</Text>
              </span>
              <div>
                <Status.Active
                  id={
                    userData?.personInfo?.preferredCommunicationChannels
                      ?.byEmail
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 items-center">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.application</Text>
              </span>
              <div>
                <Status.Active
                  id={
                    userData?.personInfo?.preferredCommunicationChannels
                      ?.byApplication
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 items-center">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.sms</Text>
              </span>
              <div>
                <Status.Active
                  id={
                    userData?.personInfo?.preferredCommunicationChannels?.bySMS
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 items-center">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.phone</Text>
              </span>
              <div>
                <Status.Active
                  id={
                    userData?.personInfo?.preferredCommunicationChannels
                      ?.byPhone
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 items-center">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.post</Text>
              </span>
              <div>
                <Status.Active
                  id={
                    userData?.personInfo?.preferredCommunicationChannels?.byPost
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 items-center">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.socialMedia</Text>
              </span>
              <div>
                <Status.Active
                  id={
                    userData?.personInfo?.preferredCommunicationChannels
                      ?.bySocialMedia
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 items-center">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.customers.customerList.familyBonus</Text>
              </span>
              <div>
                <Status.Active id={userData?.personInfo?.familyBonusApplied} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 items-center">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>
                  company.customers.customerList.newsLetterSubscription
                </Text>
              </span>
              <div>
                <Status.Active
                  id={userData?.personInfo?.newsletterSubscribed}
                />
              </div>
            </div>
          </>
        )}
      </Wrapper.Section>
    </div>
  );
}
