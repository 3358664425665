import {
  Image,
  Breadcrumb,
  Wrapper,
  AddressViewer,
  Icon,
  Toggler,
  Tabs,
  Text,
  Button,
  Form,
  Skeleton,
  ImageUploader,
  Footer
} from "components";
import { useDataState, usePermission, useToggleState } from "hooks";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router";
import { ServiceApi } from "services";
import STORE from "services/urls/store";
import General from "./General";
import Location from "./Location";
import OpeningHours from "./OpeningHours";
import { Link } from "react-router-dom";
import Setting from "./Setting";
import { appPermissions } from "constant";
import { NotificationManager } from "common/notifications";

export const StoreContext = createContext({});
export default function StoreDetails() {
  const [isOpen, toggle] = useToggleState(false);

  const { storeId } = useParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [headerData, setHeaderData] = useState({});
  const [data, setData, setBaseData, isChanged] = useDataState({});

  //permissions
  const {
    AS_UpdateStore,
    // AS_RemoveStore,
    // AS_SearchStoresByLocation,
  } = appPermissions;
  const UPDATE_PERMISSIONS = usePermission(AS_UpdateStore);
  // const REMOVE_PERMISSIONS = usePermission(AS_RemoveStore);
  // const SEARCH_BY_LOCATION_PERMISSIONS = usePermission(
  //   AS_SearchStoresByLocation
  // );

  const tabs = [
    {
      label: "company.stores.general",
      component: General,
    },
    {
      label: "company.stores.location",
      component: Location,
    },
    {
      label: "company.stores.openingHours",
      component: OpeningHours,
    },
    {
      label: "company.stores.setting",
      component: Setting,
    },
  ];
  const getData = () => {
    const url = STORE.GET_STORE_URL(storeId);
    ServiceApi.get(url)
      .then(({ data }) => {
        const result = { ...data };
        result.legalEntityCode = data.legalEntityInfo?.code ?? null;
        result.openingHours ??= [];
        result.generalSettings ??= { iln: "" };
        result.salesSettings ??= {
          iwofurnSalesCalculationFactor: 0,
          iwofurnRoundingMethod: "",
        };
        setBaseData(result);
        setHeaderData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const submit = (image) => {
    setSubmitLoading(true);
    const url = STORE.UPDATE_STORE_URL(storeId);
    let body;
    if (image) {
      body = { ...data, mainPhoto: image };
    } else {
      body = { ...data };
    }
    ServiceApi.put(url, body)
      .then(() => {
        setBaseData(body);
        setHeaderData(body);
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  useEffect(getData, []);
  return (
    <Tabs activeKey={tabs[0].label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
            <ImageUploader
              isOpen={isOpen}
              toggle={toggle}
              image={headerData.mainPhoto}
              onUpload={(data) => {
                submit(data.url);
              }}
            />
            <Image
              onClick={() => toggle()}
              src={headerData.mainPhoto}
              alt={headerData.title}
              editor
              className="w-full aspect-image lg:w-80 lg:h-40 lg:aspect-auto cursor-pointer"
            />
            <div className="lg:flex-1 space-y-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <h6 className="text-heading-6 text-gray-800">
                    {headerData.title}
                  </h6>
                  <AddressViewer address={headerData.address} />
                  {[headerData.openingHours?.at(0)].filter(Boolean).map((e) => (
                    <p key={e.code} className="text-gray-500 text-body-2">
                      <Icon icon="clock" /> {e.code} {e.value}
                    </p>
                  ))}
                </Fragment>
              )}
            </div>
            <div>
              <Toggler
                label={
                  data.isActive
                    ? "company.stores.active"
                    : "company.stores.deActive"
                }
                value={data.isActive}
                setValue={(isActive) => setData((p) => ({ ...p, isActive }))}
              />
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e) => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map((e) => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <StoreContext.Provider
              value={{
                storeData: data,
                setStoreData: setData,
              }}
            >
              {!isEmpty(data) && createElement(e.component)}
            </StoreContext.Provider>
          </Tabs.Item>
        ))}
        {UPDATE_PERMISSIONS && (
          <Footer show={!isChanged} >
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        )}
      </Form>
    </Tabs>
  );
}
