import MobileApp from "./mobile-app";

const applications = [
  {
    path: "applications/mobile-app",
    children: [...MobileApp],
  },
];

export default applications;
