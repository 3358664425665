import { useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  Button,
  Text,
  Wrapper,
  Skeleton,
  Breadcrumb,
  Footer
} from "components";
import { ServiceApi, URLS } from "services";
import { useDataState, usePermission } from "hooks";
import { appPermissions, rules } from "constant";
import { NotificationManager } from "common/notifications";

export default function NopCommerce() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);

  //permissions
  const { PS_UpsertBlobConfig, PS_GetBlobConfigDetail } = appPermissions;
  const GET_PERMISSION = usePermission(PS_GetBlobConfigDetail);
  const UPDATE_PERMISSION = usePermission(PS_UpsertBlobConfig);

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.ADD_NOP_COMMERCE;
    ServiceApi.post(url, data).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
    }).finally(() => {
      setLoadingButton(false);
      getData();
    });
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setLoadingPage(true);
    await ServiceApi.get(
      URLS.GET_NOP_COMMERCE
    ).then(({ data }) => {
      setBaseData(data);
      setLoadingPage(false);
    })
  };

  return GET_PERMISSION ?
    <Form onSubmit={submit} className="relative space-y-6">
      <h3 className="mr-auto text-heading-3">
        <Breadcrumb />
      </h3>
      <Wrapper>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
          <div className="space-y-8">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.nopCommerce.userName"
                value={data.userName}
                setValue={(userName) => setData((p: any) => ({ ...p, userName }))}
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.nopCommerce.password"
                value={data.password}
                setValue={(password) => setData((p: any) => ({ ...p, password }))}
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.nopCommerce.baseURL"
                value={data.baseUrl}
                setValue={(baseUrl) => setData((p: any) => ({ ...p, baseUrl }))}
                rules={rules.required}
              />
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      {UPDATE_PERMISSION ? (
        <Footer show={!isChanged} >
          <Button type="button" onClick={getData} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      ) : null}
    </Form>
    : (
      <>Permission Error</>
    )

}
