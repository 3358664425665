import { Icon, InputGroup, Text as TextComponent } from "components";
import { useContext } from "react";
import { AttributeContext } from ".";
import { PimContext } from "..";
import { findValue } from "../../helpers/find-attribute.value";
import { updateValue } from "../../helpers/update-attribute-value";

const Text = () => {
  const { channels } = useContext(PimContext);
  const {
    attribute,
    values,
    channel,
    language,
    id,
    setValues,
    flag,
    toggle,
    setAttribute,
    onChangeValue,
    showInChannel,
  } = useContext(AttributeContext);

  const label = attribute.isRequired ? (
    <>
      {showInChannel && (
        <Icon icon="star" size="sm" className="text-primary mr-1" />
      )}
      {attribute.translations?.[0]?.displayName}{" "}
      <Icon icon="star" size="sm" className="text-danger ml-1" />{" "}
    </>
  ) : (
    <>
      {showInChannel && (
        <Icon icon="eye-slash" size="sm" className="text-primary mr-1" />
      )}
      {attribute.translations?.[0]?.displayName}{" "}
    </>
  );

  const object = findValue(attribute, values, id, channel, language);

  const onChangeHandler = (e: any) => {
    const changedData = updateValue(
      attribute,
      values,
      id,
      e,
      channel,
      language
    );
    setValues(changedData);
    onChangeValue(attribute, id);
  };

  const perLanguage = (value: any) => {
    return (
      <>
        {["de", "en"].map((lang: any) => {
          const changedData = updateValue(
            attribute,
            values,
            id,
            value,
            channel,
            lang
          );
          setValues(changedData);
        })}
      </>
    );
  };

  const perChannelTemplate = (value: any) => {
    return (
      <>
        {channels.map((channel: any) => {
          const changedData = updateValue(
            attribute,
            values,
            id,
            value,
            channel.code,
            language
          );
          setValues(changedData);
          onChangeValue(attribute, id);
        })}
      </>
    );
  };

  const perBothTemplate = (value: any) => {
    return (
      <>
        {channels.map((channel: any) => {
          return ["de", "en"].map((lang: any) => {
            const changedData = updateValue(
              attribute,
              values,
              id,
              value,
              channel.code,
              lang
            );
            setValues(changedData);
          });
        })}
      </>
    );
  };

  let items = [
    {
      title: (
        <TextComponent>
          productManagement.products.Details.compareValues
        </TextComponent>
      ),
      id: "compare",
      onClick: () => {
        setAttribute(attribute);
        toggle();
      },
    },
  ];

  if (attribute.perChannel) {
    items.push(
      attribute.perChannel && {
        title: (
          <TextComponent>
            productManagement.products.Details.setValueForAllChannel
          </TextComponent>
        ),
        id: "setValue",
        onClick: () => {
          perChannelTemplate(object.value);
        },
      }
    );
  }

  return (
    <>
      <InputGroup
        label={label}
        placeholder={attribute?.translations?.[0]?.placeHolder}
        value={object.value}
        setValue={onChangeHandler}
        flag={attribute?.perLanguage ? flag[language] : null}
        hint={attribute?.perChannel ? { label: channel } : null}
        actions={items}
      />
    </>
  );
};

export default Text;
