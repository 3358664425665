import LocationsList from "pages/sales-managment/sales-area/locations"
import Details from "pages/sales-managment/sales-area/locations/details";

const locations = [
  {
    path: "",
    element: <LocationsList />,
  },
  {
    path: ":placeId",
    element: <Details />,
  },
];

export default locations;
