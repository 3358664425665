import { Dropdown } from "components";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useUpdateEffect, useSelector } from "hooks";

export default function Lang() {
  const { i18n } = useTranslation();
  const profile = useSelector((s) => s.profile);
  const [lang, setLang] = useState<string>("");
  const changeLanguageHandler = (lang: string) => {
    setLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };
  const langCountry: any = {
    de: "German",
    en: "English",
    tr: "Turkish",
    fr: "French",
  };
  const items =
    profile.companyLanguages?.map((lang: string) => ({
      name: lang,
      id: lang,
    })) ?? [];
  useUpdateEffect(() => {
    setLang(profile?.companyDefaultLanguage);
  }, [profile?.companyDefaultLanguage]);

  // console.log(profile)

  return (
    <Dropdown onSelect={changeLanguageHandler}>
      <Dropdown.Toggle
        as="button"
        type="button"
        className="text-gray-600 font-medium text-body-base uppercase"
      >
        {lang}
      </Dropdown.Toggle>
      <Dropdown.Menu className="min-w-fit mt-2 p-2 z-50">
        <ul className="text-gray-700 w-[104px]">
          {items.map((e: any) => (
            <li
              key={e.id}
              onClick={() => changeLanguageHandler(e.id)}
              className={`py-2 font-medium first:mb-1 px-4 h6 ${
                e.id === lang && "bg-primary-light text-primary "
              } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
            >
              {langCountry[e.name]}
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
