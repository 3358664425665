const ATTRIBUTES = {
  GET_ATTRIBUTES_URLS: "/productservice/api/attributetemplates",
  ADD_ATTRIBUTES_URLS: "/productservice/api/attributetemplates",
  UPDATE_ATTRIBUTES_URLS: (id) => `/productservice/api/attributetemplates/${id}`,
  GET_ATTRIBUTES_DETAILS_URLS: (id) => `/productservice/api/attributetemplates/${id}`,
  DELETE_ATTRIBUTES_URLS: (id) => `/productservice/api/attributetemplates/${id}`,
  ATTRIBUTES_SEARCH_URL: "/productservice/api/attributetemplates/search?",
  GET_ATTRIBUTE_GROUP_ATTRIBUTES_URL:
    "/productservice/api/attributegrouptemplates/attribute-templates?",

  //..... Channel
  ADD_CHANNEL_TO_ATTRIBUTE: (id) =>
    `/productservice/api/attributetemplates/${id}/add-channel_publish_exception`,
  REMOVE_CHANNEL_TO_ATTRIBUTE: (id) =>
    `/productservice/api/attributetemplates/${id}/remove-channel_publish_exception`,
  DISCONNECT_ATTRIBUTE: (id, isConnected) =>
    `/productservice/api/attributetemplates/${id}/${isConnected}`,
  ADD_CHANNEL_TO_SEARCHABLE_ATTRIBUTE: (id) => `/productservice/api/attributetemplates/${id}/add-channelsearchableattribute`,
  REMOVE_CHANNEL_TO_SEARCHABLE_ATTRIBUTE: (id) => `/productservice/api/attributetemplates/${id}/remove-channelsearchableattribute`,

  //..... Item-select  
  DELETE_ITEM_SELECT_ATTRIBUTES_URLS: (id) =>
    `/productservice/api/attributetemplates/${id}/remove-item`,
  ADD_ITEM_SELECT_ATTRIBUTES_URLS: (id) => `/productservice/api/attributetemplates/${id}/add-item`,
  UPDATE_ITEM_SELECT_ATTRIBUTES_URLS: (id) => `/productservice/api/attributetemplates/${id}/update-item`,
  UPDATE_ITEM_YESNO_ATTRIBUTES_URLS: (id) => `/productservice/api/attributetemplates/${id}/update-item`

};

export default ATTRIBUTES;
