export const Details = {
  generalTab: "Allgemein",
  priceTab: "Preis",
  attributesTab: "Attribute",
  mediaTab: "Medien",
  categoriesTab: "Kategorien",
  locationsTab: "Standorte",
  channelsTab: "Kanäle",
  shareButton: "Teilen",
  articleNumber: "Artikelnummer",
  supplierArticleNumber: "Lieferantenartikelnummer",
  importVersion: "Importversion",
  currentProductVersion: "Aktuelle Produktversion",
  productFamily: "Produktfamilie",
  category: "Kategorie",
  creation: "Erstellung",
  modify: "Änderung",
  active: "Aktiv",
  notActive: "Nicht aktiv",
  supplierPrice: "UVP",
  salesPrice: "Verkaufspreis",
  addPriceDate: "Preis/Datum hinzufügen",
  addMedia: "Medien hinzufügen",
  assignPlace: "Standorte zuweisen",
  availableTill: "Verfügbar bis",
  supplier: "Lieferant",
  suppliers: "Lieferanten",
  program: "Programm",
  brand: "Marke",
  selectDate: "Datum auswählen ...",
  select: "Auswählen ...",
  previewCurrentVersion: "Vorschau der aktuellen Version",
  publishedVersion: "Veröffentlichte Version",
  unPublish: "Veröffentlichung aufheben",
  rePublish: "Erneut veröffentlichen",
  publishCurrentVersion: "Aktuelle Version veröffentlichen",
  assignCategory: "Kategorie zuweisen",
  missingRequiredAttribute: "Fehlendes Pflichtattribut",
  channels: "Kanäle",
  language: "Sprache",
  saveAttributes: "Attribute speichern",
  productCategory: "Produktkategorie",
  selectCategory: "Kategorie auswählen ...",
  fileInfo: "Dateiinformationen",
  imageSetting: "Bildeinstellung",
  deleteImage: "Löschen",
  imagePublicationInChannels: "Bildveröffentlichung in Kanälen",
  imageInfo: "Bildinformationen",
  placeHolder: "Platzhalter",
  description: "Beschreibung",
  productPreviewFor: "Produktvorschau für",
  basicInfo: "Grundlegende Informationen",
  price: "Preis",
  shareProduct: "Produkt teilen",
  sendEmail: "E-Mail senden",
  copyUrl: "URL kopieren",
  url: "URL",
  startDate: "Startdatum",
  endDate: "Enddatum",
  noData: "Keine Daten verfügbar",
  compareValues: "Werte vergleichen",
  setValueForAllChannel: "Wert für alle Kanäle festlegen",
  noAttributeFound: "Kein Attribut gefunden",
  allInGroupFilter: "Alle",
  showInvisibleAttributes: "Unsichtbare Attribute anzeigen",
  searchCategory: "Serach Category DE",
  thereIsNoChannelEnabledForYourCompany:
    "There is no channel enabled for your company DE",
  aiTextGenerator: "AI Text Generator DE",
  aiDescription: "Generate an AI Generated Text for the following product DE",
  basicInfo: "Basic Info(optional) DE",
  wordCount: "Word Count DE",
  generate: "Generate DE",
  copy: "copy DE",
  setValue: "Set Value DE",
};
