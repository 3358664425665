import React, { useContext } from "react";
import { Wrapper, Text, InputGroup } from "components";
import { ProfileContext } from ".";

const Setting: React.FunctionComponent = () => {
  const { profileData, setProfileData } = useContext<any>(ProfileContext);

  const onChangeHandler = (key: any) => {
    return (value: any) => setProfileData((p: any) => ({ ...p, [key]: value }));
  };

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <h2>
            <Text>company.profile.customerSettings</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body>
          <div className="s-full lg:w-1/2">
            <InputGroup
              label="company.profile.customerNumberPrefix"
              value={profileData.prefixNumber}
              setValue={onChangeHandler("prefixNumber")}
              type="number"
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
    </>
  );
};

export default Setting;
