import {
    Accordion,
    Button,
    DropdownButton,
    HandleName,
    Icon,
    Skeleton,
    Text
} from "components";
import { useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { CategoryContext } from "..";
// import AddCategory from "../drawers/add-category";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setproductCategories } from "store/productCategories";
import AddCategory from "../drawers/add-sub-category";

const SubCategories = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedCategory } = useContext(CategoryContext);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [loading, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [parentId, setParentId] = useState<any>("");

  const getSubCategories = () => {
    setSubCategories("");
    setLoading();
    const url = URLS.GET_CATEGORY_WITH_CHILDREN(selectedCategory?.productCategoryId);
    ServiceApi.get(url)
      .then(({ data }) => {
        setSubCategories(data);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    getSubCategories();
  }, [selectedCategory]);

  const getchildren = (item: any) => {
    return subCategories.filter(
      (sub: any) => sub.parentId === item.productCategoryId
    );
  };

  const createChilds = (sub?: any, index?: number, inner?: boolean) => {
    return (
      <Accordion>
        <Accordion.Item
          className={`bg-white shadow-nested border px-4 rounded-2xl`}
        >
          <div className="flex items-center">
            <Accordion.Toggle>
              <div className="flex space-x-4 items-center font-medium">
                {getchildren(sub).length > 0 && (
                  <Icon
                    icon={"caret-right"}
                    className="transition-transform group-[.accordion-item.active]:rotate-90"
                  />
                )}
                <div className="space-y-2 text-left cursor-pointer select-none">
                  <span className="text-heading-6 font-semibold">
                    {sub && <HandleName translations={sub?.translations} code={sub?.code} keyName="name" />}
                  </span>
                  <p className="text-body-2 font-medium">{sub.code}</p>
                </div>
              </div>
            </Accordion.Toggle>
            <DropdownButton
              iconOnly
              items={[
                {
                  id: "edit",
                  title:
                    "productManagement.masterData.productCategory.editSubCategory",
                  permission: true,
                  onClick: () => {
                    dispatch(setproductCategories(sub));
                    navigate(sub.productCategoryId);
                  },
                },
                {
                  id: "addBranch",
                  title:
                    "productManagement.masterData.productCategory.addBranch",
                  permission: true,
                  onClick: (e: any) => {
                    setParentId(sub.productCategoryId);
                    toggle();
                  },
                },
              ]}
            >
              <Icon icon={"ellipsis-vertical"} className="text-primary" />
            </DropdownButton>
          </div>
          {getchildren(sub).length > 0 && (
            <Accordion.Body className="px-4">
              {createSubFamilies(sub, true)}
            </Accordion.Body>
          )}
        </Accordion.Item>
      </Accordion>
    );
  };

  const createSubFamilies = (sub: any, inner = false) => {
    return (
      <div className="space-y-2 ">
        {getchildren(sub).map((child: any, index: number) =>
          createChilds(child, index, inner)
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-end">
        <Button
          onClick={() => {
            setParentId(selectedCategory.productCategoryId);
            toggle();
          }}
        >
          <Icon icon="plus" className="mr-2" />
          <Text>
            productManagement.masterData.productCategory.addSubCategory
          </Text>
        </Button>
      </div>
      <div className="space-y-4 rounded overflow-auto h-[500px]">
        {loading ? (
          [1, 2, 3, 4].map((item) => <Skeleton.List key={item} />)
        ) : (
          <Accordion>
            {subCategories?.map((sub: any) => {
              {
                return sub.parentId === selectedCategory.productCategoryId ? (
                  <Accordion.Item className="bg-white px-4 rounded-2xl">
                    <div className="flex items-center">
                      <Accordion.Toggle>
                        <div className="flex space-x-4 items-center font-medium">
                          {getchildren(sub).length > 0 && (
                            <Icon
                              icon={"caret-right"}
                              className="transition-transform group-[.accordion-item.active]:rotate-90"
                            />
                          )}
                          <div
                            className={`space-y-2 text-left cursor-pointer select-none`}
                          >
                            <span className="text-heading-6 font-semibold">
                              {<HandleName translations={sub?.translations} code={sub?.code} keyName="name" />}
                            </span>
                            <p className="text-body-2 font-medium">
                              {sub.code}
                            </p>
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <DropdownButton
                        iconOnly
                        items={[
                          {
                            id: "edit",
                            title:
                              "productManagement.masterData.productCategory.editSubCategory",
                            permission: true,
                            onClick: () => {
                              dispatch(setproductCategories(sub));
                              navigate(sub.productCategoryId);
                            },
                          },
                          {
                            id: "addBranch",
                            title:
                              "productManagement.masterData.productCategory.addBranch",
                            permission: true,
                            onClick: (e: any) => {
                              setParentId(sub.productCategoryId);
                              toggle();
                            },
                          },
                        ]}
                      >
                        <Icon
                          icon={"ellipsis-vertical"}
                          className="text-primary"
                        />
                      </DropdownButton>
                    </div>
                    {getchildren(sub).length > 0 && (
                      <Accordion.Body className="px-4">
                        {createSubFamilies(sub)}
                      </Accordion.Body>
                    )}
                  </Accordion.Item>
                ) : null;
              }
            })}
          </Accordion>
        )}
      </div>
      <AddCategory
        isOpen={isOpen}
        toggle={toggle}
        getCategories={getSubCategories}
        parentId={parentId}
      />
    </>
  );
};

export default SubCategories;
