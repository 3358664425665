import { useState, useEffect } from "react";
import { useToggleState, usePermission } from "hooks";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  Icon,
  NoItems,
  DeleteModal
} from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import AddModal from "./add-modal";
import { config, appPermissions } from "constant";
import { NotificationManager } from "common/notifications";
import { useSearchParams } from "react-router-dom";

type ItemsType = {
  apiKeyId: string;
  name: string;
  truncated: string;
};

type ListType = {
  items?: ItemsType[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function ApiKeysList() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [selected, setSelected] = useState<any>();
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const [isOpen, toggle] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);

  //permissions
  const { AS_GenerateApiKey, AS_DeleteApiKey, AS_GetApiKeys } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(AS_GetApiKeys);
  const DELETE_PERMISSION = usePermission(AS_DeleteApiKey);
  const GENERATE_PERMISSION = usePermission(AS_GenerateApiKey);

  const handleSetParams = (key: any) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_API_KEY_LIST_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config).then(({ data }) => {
      // data?.items?.map((item: any) => {
      //   item["checked"] = false;
      // });
      setData(data);
      setLoadingList(false);
    });
  };

  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps


  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.GET_DELETE_API_KEY_LIST_URL(id);
    ServiceApi.delete(url).then(({ data }) => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      getData();
      toggleDeleteModal();
      setLoadingButton(false);
    }).catch(() => setLoadingButton(false));
  };

  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        {loadingList ? (
          <Skeleton.Button />
        ) : GENERATE_PERMISSION ? (
          <Button onClick={() => toggle()}>
            <Icon className="mr-2" icon={"plus"} />
            <Text>configurations.globalSettings.apiKeys.generateAPIKey</Text>
          </Button>
        ) : null}
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ?
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.apiKeyId}>
                      <td className="text-heading-6 text-gray-800">
                        <h6>{item.name}</h6>
                      </td>
                      <td className="text-body-base text-gray-800">
                        <Text>
                          configurations.globalSettings.apiKeys.description
                        </Text>
                        :<span className="ml-1">{item.truncated}</span>
                      </td>

                      <td>
                        {DELETE_PERMISSION && (
                          <Button
                            size="sm"
                            variant="danger"
                            light
                            onClick={() => {
                              setSelected(item);
                              toggleDeleteModal();
                            }}
                          >
                            <Icon icon={"trash"} />
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages || 1}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
      <AddModal isOpen={isOpen} toggle={toggle} getData={getData} />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: selected?.name, id: selected?.apiKeyId }}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </div>
  ) : (
    <>Permission Error</>
  );
}
