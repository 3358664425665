import { useState, useEffect } from "react";
import { useToggleState, useConverter } from "hooks";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  Icon,
  NoItems
} from "components";
import { ServiceApi, URLS } from "services";
import { useNavigate, useSearchParams } from "react-router-dom";
import { config } from "constant";
import { HandleParamsUrl } from "utils"
import FilterDrawer from "./FilterDrawer"
import download from 'js-file-download';

export default function SellOffCommissions() {
  const [data, setData] = useState<any>();
  const [loadingList, setLoadingList] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [filterModal, setFilterModal] = useToggleState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const originalFilterData = {
    keyword: "",
    pageNumber: "1",
    pageSize: config.pageSize,
    status: null,
    sellerName: null,
    soldPriceFrom: null,
    soldPriceTo: null,
    createdDateFrom: null,
    createdDateTo: null,
    soldDateFrom: null,
    soldDateTo: null,
    sortType: "",
  };
  const { convertDate, convertAmount } = useConverter();
  const [keyword, setKeyword] = useState("");
  const [thisDate, setThisDate] = useState("");

  const [filterData, setFilterData] = useState(originalFilterData);
  const [params, setParams] = useSearchParams();
  //permissions
  // const { PS_CreateCheckListItem, PS_DeleteCheckListItem, PS_GetCheckListItem, PS_UpdateCheckListItem } = appPermissions;
  // const GET_LIST_PERMISSION = usePermission(PS_GetCheckListItem);
  // const DELETE_PERMISSION = usePermission(PS_DeleteCheckListItem);
  // const CREATE_PERMISSION = usePermission(PS_CreateCheckListItem);
  // const UPDATE_PERMISSION = usePermission(PS_UpdateCheckListItem);

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.SEARCH_SELL_OFF_COMISSION;
    const config = params
    setLoadingList(true);
    ServiceApi.post(url, config).then(({ data }) => {
      setData(data);
      setKeyword(params.keyword)
      setLoadingList(false);
    });
  };

  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetFilter = () => {
    setFilterData(originalFilterData)
    setKeyword("")
    setThisDate("")
    navigate(`/salesmanagment/sell-off-sales/sell-off-commissions`);
  };

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      if (key === "createdDateFrom" || key === "createdDateTo") {
        value = value.toDateString()
      }
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    }
  };

  const exportFile = () => {
    setLoadingBtn(true);
    const url = URLS.DOWNLOAD_SELL_OFF_COMISSION;
    const config = params
    ServiceApi.post(url, config).then(({ data }: any) => {
      download(data, `sellOffCommission_${convertDate(new Date())}.csv`);
    }).finally(() => {
      setLoadingBtn(false);
    });
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <Button onClick={() => exportFile()} loading={loadingBtn}>
          <Icon className="mr-2" icon={"file-arrow-down"} />
          <Text>salesManagment.sellOffSales.sellOffCommissions.export</Text>
        </Button>
      </div>
      <SearchBox
        value={keyword}
        onSubmit={handleSetFilterData("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onFilter={() => setFilterModal()}
        resetFilter={searchParams?.size === 0 ? undefined : resetFilter}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.searchResult?.length !== 0 ?
            <>
              <Table>
                {data?.searchResult?.map((item: any) => {
                  return (
                    <tr key={item.checkListItemId}>
                      <td className="space-y-2">
                        <h6 className="text-heading-6 text-gray-800 font-semibold">{item.title}</h6>
                        <div className="text-body-2 text-gray-600 font-medium" >
                          <Text>salesManagment.sellOffSales.sellOffCommissions.articleNumber</Text>:
                          <span className="ml-1">{item.articleNumber}</span></div>
                      </td>
                      <td className="flex flex-wrap gap-2 items-center text-left">
                        <div>
                          <Table.Image placeholder="user" src="" alt="" className="h-[78px] w-[78px]" />
                        </div>
                        <div className="space-y-3">
                          <div><span className="text-body-base text-gray-800 font-normal" >{item.creatorName}</span></div>
                          <div className="flex items-center">
                            <span className="text-body-base text-gray-600 font-normal">
                              <Text>salesManagment.sellOffSales.sellOffCommissions.soldPrice</Text>:</span>
                            <h6 className="text-heading-6 text-gray-800 font-semibold ml-1">{convertAmount(item.soldPrice)}</h6>
                          </div>
                          <div><span className="text-body-base text-gray-600 font-normal" >{convertDate(item.creationAt)}</span></div>
                        </div>
                      </td>
                      <td className="space-y-1 lg:w-[300px]" >
                        <div className="flex flex-wrap items-center lg:justify-between">
                          <span className="text-body-base text-gray-600 font-normal">
                            <Text>salesManagment.sellOffSales.sellOffCommissions.sellerCommission</Text>:</span>
                          <span className="text-body-base text-gray-800 font-normal">{item.sellerCommission}%</span>
                        </div>
                        <div className="flex flex-wrap items-center lg:justify-between">
                          <span className="text-body-base text-gray-600 font-normal">
                            <Text>salesManagment.sellOffSales.sellOffCommissions.sellerCommissionAmount</Text>:</span>
                          <h6 className="text-heading-6 text-gray-800 font-semibold">{convertAmount(item.sellerCommissionAmount)}</h6>
                        </div>
                        <div className="flex flex-wrap items-center lg:justify-between">
                          <span className="text-body-base text-gray-600 font-normal">
                            <Text>salesManagment.sellOffSales.sellOffCommissions.fastSaleCommission</Text>:</span>
                          <h6 className="text-body-base text-gray-800 font-normal">{convertAmount(item.fastSalesProvisionAmount)}</h6>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages || 1}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetFilterData("pageNumber")}
                onPageSizeChange={handleSetFilterData("pageSize")}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
      <FilterDrawer
        isOpen={filterModal}
        data={data}
        keyword={keyword}
        thisDate={thisDate}
        setThisDate={setThisDate}
        setKeyword={setKeyword}
        toggle={setFilterModal}
        onChange={handleSetFilterData}
        resetFilter={resetFilter}
        setParams={setParams}
        params={params}
      />
    </div>
  )
}
