const PIM = {
  // -------------------- PIM Product ----------------
  GET_BRAND_DETAILS: (id) => `productservice/api/brands/${id}`,
  POST_SEARCH_LIST_PRODUCTS: "productservice/api/pimproducts/search-pim",
  CREATE_PIM_PRODUCT_URL: "productservice/api/pimproducts?",
  EDIT_PIM_PRODUCT_URL: (id) => `productservice/api/pimproducts/${id}`,
  PATCH_PIM_PRODUCT_URL: (id) =>
    `productservice/api/pimproducts/${id}/update-dynamic`,
  EDIT_PIM_PRODUCT_ATTRIBUTE_URL: (id) =>
    `productservice/api/pimproducts/${id}/attribute-values`,
  GET_PIM_PRODUCT_FAMILIES_URL: "productservice/api/pimproductfamilies?",
  GET_PIM_PRODUCT_BY_ID_URL: (id) => `productservice/api/pimproducts/${id}`,
  GET_PIM_PRODUCT_LIST: "productservice/api/pimproducts",
  GET_PIM_PRODUCT_ATTRIBUTES: (id) =>
    `productservice/api/pimproducts/attributes/${id}`,
  DELETE_PRODUCT: (productId) =>
    `productservice/api/selloffproducts/${productId}`,
  UNPUBLISH_PRODUCT: (pimProductId) =>
    `productservice/api/pimproducts/${pimProductId}/unpublish`,
  ADD_BRAND_URL: "productservice/api/brands",

  // --------------------EDIT PIM Product Channel --------------

  GET_CHANNEL_LIST_BY_PIM_PRODUCT_ID_URL: (id) =>
    `productservice/api/channelproducts?pimProductId=${id}`,
  PUBLISH_PIM_PRODUCT_TO_CHANNEL_URL: (id) =>
    `productservice/api/pimproducts/${id}/publish`,
  GET_ALL_CHANNEL_URL: (id) => `productservice/api/channels?pimProductId=${id}`,
  GET_A_CHANNEL_PRODUCT_URL: (id, channelCode) =>
    `productservice/api/channelproducts/detail?pimProductId=${id}&channelCode=${channelCode}`,
  GET_PLACES: (id) =>
    `productservice/api/pimproducts/places?pimProductId=${id}`,
  ADD_PLACES: (id) => `productservice/api/pimproducts/${id}/addplace`,
  REMOVE_PLACES: (id) => `productservice/api/pimproducts/${id}/removeplace`,
  // -------------------- PIM Product Media --------------

  GET_PIM_PRODUCT_MEDIA_LIST_URL: (pimProductId, pageNumber, pageSize) =>
    `productservice/api/pimproductmedias?pimProductId=${pimProductId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  CREATE_PIM_PRODUCT_MEDIA_URL: "productservice/api/pimproductmedias",
  GET_PIM_PRODUCT_MEDIA_URL: (mediaId) =>
    `productservice/api/pimproductmedias/${mediaId}`,
  UPDATE_PIM_PRODUCT_MEDIA_URL: (mediaId) =>
    `productservice/api/pimproductmedias/${mediaId}`,
  UPDATE_PIM_PRODUCT_AVATAR_URL: (id) =>
    `productservice/api/pimproducts/${id}/avatar`,
  // -------------------- PIM Product Family --------------
  CREATE_GET_PUT_DELETE_PIM_PRODUCT_FAMILY_URL:
    "productservice/api/pimproductfamilies",
  GET_GROUP_ATTRIBUTE: (id) =>
    `productservice/api/pimproductfamilies/${id}/group-attributes`,
  GET_ALL_ATTRIBUTE: (id) =>
    `productservice/api/pimproductfamilies/${id}/all-attribute-groups?pageNumber=1&pageSize=100`,
  GET_ATTRIBUTE_TEMPLATES: (id) =>
    `productservice/api/attributegrouptemplates/attribute-templates?attributeTemplateGroupId=${id}`,
  GET_AVAILABLE_ATTRIBUTE: (id) =>
    `/productservice/api/pimproductfamilies/${id}/available-attribute-groups`,
  GET_DETAIL_PIM_PRODUCT_FAMILY_URL:
    "productservice/api/pimproductfamilies/detail",
  ADD_ATTRIBUTE_TO_PIM_PRODUCT_FAMILY_URL:
    "productservice/api/pimproductfamilies/add-attribute-group",
  DELETE_ATTRIBUTE_FROM_PIM_PRODUCT_FAMILY_URL:
    "productservice/api/pimproductfamilies/remove-attribute-group",

  // --------------- PIM product preview ---------------
  CREATE_PREVIEW: (pimProductId) =>
    `productservice/api/pimproducts/${pimProductId}/channel-product-preview`,
  GET_PRODUCT_PREVIEW: (pimProductId, channelCode) =>
    `productservice/api/pimproducts/preview?pimProductId=${pimProductId}&channelCode=${channelCode}`,

  // ----------------- import PIM product ---------
  CHECK_CONFIGURATION_PROVIDER: (providerName, configurationVersion, lang) =>
    `productservice/api/b2bconfigurationproviders/${providerName}/${configurationVersion}/${lang}/import-status`,
  SEARCH_PIM_PRODUCT: `productservice/api/providerproductimports/basic-info`,
  IMPORT_CONFIGURATION_PROVIDER: `productservice/api/b2bconfigurationproviders`,
  GET_B2B_CONFIG_PROVIDERS: `productservice/api/b2bconfigurationproviders`,
  GET_B2B_PRODUCT_DETAIL: (id) =>
    `productservice/api/providerproductimports/${id}/b2b-detail`,
  IMPORT_PRODUCT: `productservice/api/pimproducts/import-from-provider-product-import`,
  GET_CONFIGURATION_PROVIDERS_ITEM: (name) =>
    `productservice/api/configurationproviders/${name}`,

  GET_MEASUREMENT_LIST_BY_CODE: (code) =>
    `productservice/api/measurementfamilies/detail-by-code/${code}`,
};

export default PIM;
