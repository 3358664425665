export const employees = {
    general: "General DE",
    technicalInfo: "Technical Info DE",
    employeeNumber: "Employee Number DE",
    salesPersonNumber: "Sales Person Number DE",
    creationDate: "Creation Date DE",
    displayName: "Display Name DE",
    firstName: "First Name DE",
    lastName: "Last Name DE",
    nickName: "Nick Name DE",
    basicInfo: "Basic Info DE",
    employmentInfo: "Employment Info DE",
    contactInfo: "Contact Info DE",
    jobTitle: "Job Title DE",
    store: "Store DE",
    departments: "Departments DE",
    mobilePhone: "Mobile Phone DE",
    businessPhone: "Business Phone DE",
    faxNumber: "Fax Number DE",
    emailAddress: "Email Address DE",
    address: "Address DE",
    userPrincipalName: "User Principal Name DE",
    userObjectId: "User ObjectId DE",
    filters:"Filters DE",
    resetFilters:"Reset Filters DE",
    from:"From DE",
    to:"To DE",
    keyword:"Keyword DE"

}