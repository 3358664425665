
export interface AddressType {
  "street"?: string | null;
  "houseNo"?: string | number;
  "postalCode"?: string | number;
  "city"?: string;
  "state"?: string;
  "country"?: string | number;
}

export default function convertAddress(address: AddressType) {
  if (!address) return "";
  return [
    ["street", "houseNo"],
    ["postalCode", "city"],
    ["state", "country"],
  ]
    .map((arr) => arr.map((key) => address[key as keyof AddressType] || "").join(" "))
    .join(", ");
}
