import i18n from "langs/i18n";

type decimalPointsType = {
  de: string;
  en: string
}

export default function replaceNonDigits(str: string) {
  if (!str) return "";
  const lang: string = i18n.language;
  const regexLng = {
    de: /[^\d,-]/g,
    en: /[^\d.-]/g,
  };
  //   const separators = {
  //     de: ".",
  //     en: ",",
  //   };
  const decimalPoints = {
    de: ",",
    en: ".",
  };
  const regex = regexLng[lang as keyof decimalPointsType];
  //   const separator = separators[lang];
  const decimalPoint = decimalPoints[lang as keyof decimalPointsType];
  const [int, decimal = null] = str.replace(regex, "").split(decimalPoint);
  const hasDecimal = decimal !== null;
  if (hasDecimal) {
    return [int, decimal].join(".");
  }
  return int;
}
