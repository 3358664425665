export const salesTemplates = {
    addTemplates: "Add Templates",
    addPhase: "Add Phase DE",
    editPhase: "Edit Phase DE",
    tailSalesTemplates: "Detail Sales Templates",
    label: "Label",
    description: "Description",
    phase: "Phase DE",
    basicInfo: "Basic Info",
    basicInfoDesc: "Name,scription, ...",
    phaseInfo: "Phase Info",
    phaseInfoDesc: "Define Sales Phase",
    review: "Review",
    reviewDesc: "Finalize information",
    templateName: "Template Name",
    code: "Code",
    shortDescription: "Shortscription",
    phaseName:"Phase Name",
    discount:"Discount",
    duration:"Duration",
    sellerCommission:"Seller Commission",
    checkListItems:"CheckList Items",
}