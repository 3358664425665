import { Trans, useTranslation } from "react-i18next";

type textProps = {
  children?: string;
};

export default function Text({ children }: textProps) {
  const { t } = useTranslation();
  return <Trans t={t}>{children}</Trans>;
}
