import { NotificationManager } from "common/notifications";
import {
  Button,
  Form,
  InputGroup,
  Select,
  Text,
  Toggler,
} from "components";
import { rules } from "constant";
import { useDataState } from "hooks";
import { FormEvent, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";


type createModalType = {
  programId: string;
  titleItems: [];
  totalItems: any;
  setShowButtonCreate: any;
  setIsOpenCreate: any;
  getData: () => void;
  getDataProgram: () => void;
};

export default function CreateModal(props: createModalType) {
  const [loading, setLoading] = useState<boolean>(false);
  const [showTextInput, setShowTextInput] = useState<boolean>(false);
  const formRef = useRef<any>();
  const [data, setData] = useDataState({
    title: "",
    titleSelect: "",
    text: "",
    amount: "",
    order: "",
    isActive: true,
  });


  const onSubmitForm = (formData: any) => {
    setLoading(true);
    const url = URLS.ADD_DISCOUNT_PROGRAMS_SUPPLIERS(props.programId);
    if (formData.text !== "") {
      formData.title = formData.text;
      // delete formData.text;
    } else {
      formData.title = formData.titleSelect;
    }
    ServiceApi.post(url, formData).then(({ }) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      props.setIsOpenCreate(false);
      props.setShowButtonCreate(true)
      props.getData();
      props.getDataProgram();
    }).finally(() => {
      setLoading(false);
    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  return (
    <div className="rounded" style={{ boxShadow: "0px 4px 20px rgba(56, 71, 109, 0.09)", marginBottom: "16px" }}>
      <Form ref={formRef} onSubmit={onSubmitForm} id={"create-form"} >
        <div className="grid grid-cols-1 lg:grid-cols-3 p-4">
          <div className="col-start-1 col-end-4 mb-8">
            <Select
              label="productManagement.masterData.suppliers.title"
              setValue={(titleSelect) => {
                setShowTextInput(titleSelect === "customText" ? true : false)
                if (titleSelect === "customText") {
                  setData((p) => ({ ...p, titleSelect, "text": "" }))
                } else {
                  setData((p) => ({ ...p, titleSelect }))
                }
              }}
              items={props.titleItems}
              value={data.titleSelect}
              rules={rules.required}
            />
          </div>
          {showTextInput ? <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              label="productManagement.masterData.suppliers.customTitle"
              value={data.text}
              setValue={(text) => setData((p) => ({ ...p, text }))}
            />
          </div>
            : ""}
          <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              label="productManagement.masterData.suppliers.amount"
              value={data.amount}
              setValue={(amount) => {
                if (amount >= 1 && amount <= 100)
                  setData((p) => ({ ...p, amount }))
              }}
              rules={rules.discount}
              keyfilter="pnum"
              append={<h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">%</h5>}
            />
          </div>
          <div className="col-start-1 col-end-4 mb-8">
            <InputGroup
              type="number"
              label="productManagement.masterData.suppliers.sortOrder"
              value={data.order}
              setValue={(order) => setData((p) => ({ ...p, order }))}
              rules={rules.required}
              keyfilter="onlyNum"
            />
          </div>
          <div className="col-start-1 col-end-1 mb-2">
            <Toggler
              label={
                data.isActive
                  ? "productManagement.masterData.suppliers.active"
                  : "productManagement.masterData.suppliers.deActive"
              }
              value={data.isActive}
              setValue={(isActive) => setData((p) => ({ ...p, isActive }))}
            />
          </div>
        </div>
        <div className="flex items-center justify-between p-4">
          <Button type="button" className="mr-2" onClick={() => {
            props.setIsOpenCreate(false)
            props.setShowButtonCreate(true)
          }} variant="white">
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loading}>
            <Text>global.buttons.submit</Text>
          </Button>
        </div>
      </Form>
    </div>
  );
}