import { useState } from "react";
import { classNames } from "utils";
import Text from "./Text";
import Dropdown from "./Dropdown";
import Icon from "./Icon";
type togglerProps = {
  label?: any;
  size?: "sm" | "md";
  disabled?: boolean;
  value: boolean;
  setValue: (val: boolean) => void;
  className?: string;
  hint?: any;
  flag?: string;
  actions?: any;
};

export default function Toggler({
  label,
  value = false,
  setValue = () => {},
  disabled,
  size = "md",
  className = "",
  flag,
  hint,
  actions,
}: togglerProps) {
  const [selectedItem, setSelectedItem] = useState("");
  const customClassNames = classNames({
    " w-6 h-6 group-data-active:left-5": size === "md",
    " w-4 h-4 group-data-active:left-7": size === "sm",
  });

  return (
    <div
      className={`w-full flex items-center justify-between gap-4 ${className}`}
    >
      {(label || flag) && (
        <h6 className="flex-1 text-gray-800 font-normal" data-lang-map={label}>
          <Text>{label}</Text>
        </h6>
      )}
      {(flag || hint) && (
        <div className="flex items-center gap-4">
          {hint && (
            <span className={`text-body-2 font-normal text-${hint?.color}`}>
              {hint?.label}
            </span>
          )}
          <img className="block" src={flag} alt="" />
          {actions?.length > 0 && (
            <Dropdown onSelect={setSelectedItem}>
              <Dropdown.Toggle
                as="button"
                icon={true}
                type="button"
                className="text-gray-600 font-medium text-body-base uppercase"
              >
                <Icon icon="ellipsis-vertical"></Icon>
              </Dropdown.Toggle>
              <Dropdown.Menu className="min-w-fit mt-2 p-2">
                <ul className="text-gray-700 w-max">
                  {actions?.map((e: any) => (
                    <li
                      key={e.id}
                      onClick={(value) => {
                        setSelectedItem(e.id);
                        e.onClick(value);
                      }}
                      className={`py-2 font-medium first:mb-1 px-4 h6 ${
                        e.id === selectedItem &&
                        "bg-primary-light text-primary "
                      } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                    >
                      {e.title}
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      )}
      <button
        data-active={value}
        type="button"
        className={`toggler group relative w-[47px] ${
          size === "sm" ? "h-[22px]" : "h-[30px]"
        } bg-gray-600 rounded-full transition-colors enabled:data-active:bg-primary`}
        onClick={() => setValue(!value)}
        disabled={disabled}
      >
        <span
          className={`${customClassNames} absolute top-[3px] left-[3px] rounded-full bg-white transition-[left]`}
        />
      </button>
    </div>
  );
}
