import {
  ComponentProps,
  createContext,
  ElementType,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import Button from "./Button";
import { asProps } from "types";

type contextType = {
  toggleShow: () => void;
  onSelect: (val: any) => void;
  drop: "left" | "right";
  setShow: (val: any) => void;
};
type dropdownElementProps = {
  className?: string;
  children?: ReactNode;
};
type dropdownProps = {
  onSelect?: (val: any) => void;
  drop?: "left" | "right";
} & dropdownElementProps;
type dropdownItemProps = {
  eventKey?: any;
} & dropdownElementProps;
export const DropdownContext = createContext({} as contextType);

const Dropdown = ({
  className = "",
  onSelect = () => {},
  drop = "right",
  children,
}: dropdownProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow((p) => !p);
  };
  const handleClick = (e: MouseEvent) => {
    const outside = !ref.current?.contains(e.target as Node);
    outside && setShow(false);
  };
  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <div
      ref={ref}
      data-active={show}
      className={`dropdown inline-block relative group ${className}`}
    >
      <DropdownContext.Provider value={{ toggleShow, onSelect, drop, setShow }}>
        {children}
      </DropdownContext.Provider>
    </div>
  );
};
function DropdownToggle<E extends ElementType = typeof Button>(
  {
    as,
    className = "",
    children = null,
    icon = false,
    ...props
  }: dropdownElementProps & ComponentProps<E> & asProps<E>,
  ref: any
) {
  const Component = as || Button;
  const { toggleShow, setShow } = useContext(DropdownContext);
  useImperativeHandle(
    ref,
    () => {
      return {
        toggleShow,
        setShow,
      };
    },
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Component
      className={`dropdown-toggle ${className}`}
      onClick={() => setShow(true)}
      data-lang-map={props.label}
      {...props}
    >
      {children}
      {!icon && (
        <i className="bi bi-chevron-down group-data-active:rotate-180 transition-transform" />
      )}
    </Component>
  );
}
function DropdownMenu({
  className = "",
  children = null,
}: dropdownElementProps) {
  const { drop, setShow } = useContext(DropdownContext);
  return (
    <div
      onClick={() => setShow(false)}
      style={{ [drop]: 0, transformOrigin: `top ${drop}` }}
      className={`dropdown-menu absolute w-full bg-white rounded shadow-sm border border-gray-200 top-full z-[60] transition-[transform,opacity] pointer-events-none opacity-0 scale-90 group-data-active:pointer-events-auto group-data-active:opacity-100 group-data-active:scale-100 p-2 space-y-1 ${className}`}
    >
      {children}
    </div>
  );
}
function DropdownItem<E extends ElementType = "button">({
  as,
  className = "",
  children = null,
  eventKey = "",
  isActive = false,
  onClick = () => {},
  ...props
}: dropdownItemProps & ComponentProps<E> & asProps<E>) {
  const Component = as || "button";
  const { toggleShow, onSelect } = useContext(DropdownContext);
  const handleClick = (e: MouseEvent) => {
    toggleShow();
    onSelect(eventKey);
    onClick(e);
  };
  return (
    <Component
      type="button"
      data-active={isActive}
      className={`dropdown-item block w-full rounded text-left py-2 px-4 text-gray-700 hover:bg-primary-light hover:text-primary transition-colors data-active:bg-primary/5 data-active:text-primary-active ${className}`}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Component>
  );
}
Dropdown.Toggle = forwardRef(DropdownToggle);
Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownItem;
export default Dropdown;
