import Specification from "./Specification";
import Gallery from "./Gallery";
import SubProducts from "./SubProducts";
const OverViewTab = () => {
  return (
    <div className="space-y-6">
      <Specification />
      <Gallery />
      <SubProducts />
    </div>
  );
};

export default OverViewTab;
