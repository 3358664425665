export const roles = {
    addRole: "Add Role DE",
    general: "General DE",
    permissions: "Permissions DE",
    basicInfo: "Basic Info DE",
    name: "Name DE",
    description: "Description DE",
    shortDescription: "Short Description DE",
    permissionDependency: "Permission Dependency DE",
    thesePermissionsWillAlsoEnable: "These permissions will also enable DE",
    thesePermissionsWillAlsoDisable: "These permissions will also disable DE"
}