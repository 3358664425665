import { FC, memo, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker.scss";
import de from "date-fns/locale/de";
import { useTranslate } from "../../hooks";
import { Text, Dropdown, Icon } from "components";
registerLocale("de", de);

interface Props {
  [x: string]: any;
  label?: any;
  hint?: any;
  flag?: string;
  actions?: any;
}

const DatePicker: FC<Props> = memo(
  ({ label, flag, hint, actions, ...props }) => {
    const { i18n } = useTranslation();
    const [selectedItem, setSelectedItem] = useState("");
    const currentLanguage = i18n.language;
    const translate = useTranslate();

    const dateFormat = props.showTimeSelectOnly
      ? "hh:mm"
      : currentLanguage === "de"
      ? props.showTimeSelect
        ? "dd.MM.yyyy hh:mm"
        : "dd.MM.yyyy"
      : props.showTimeSelect
      ? "yyyy-MM-dd hh:mm"
      : "yyyy-MM-dd";

    return (
      <div className="input-group group w-full">
        <div className="flex items-center space-x-4 justify-between mb-4">
          {label && (
            <h6
              data-lang-map={label}
              className="block h6 font-normal text-gray-800 truncate group-[.input-group.required]:after:content-['*'] after:text-danger"
            >
              <Text>{label}</Text>
            </h6>
          )}
          {(flag || hint) && (
            <div className="flex items-center gap-4">
              {hint && (
                <span className={`text-body-2 font-normal text-${hint?.color}`}>
                  {hint?.label}
                </span>
              )}
              <img className="block" src={flag} alt="" />
              {actions?.length > 0 && (
                <Dropdown onSelect={setSelectedItem}>
                  <Dropdown.Toggle
                    as="button"
                    icon={true}
                    type="button"
                    className="text-gray-600 font-medium text-body-base uppercase"
                  >
                    <Icon icon="ellipsis-vertical"></Icon>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="min-w-fit mt-2 p-2">
                    <ul className="text-gray-700 w-max">
                      {actions?.map((e: any) => (
                        <li
                          key={e.id}
                          onClick={(value) => {
                            setSelectedItem(e.id);
                            e.onClick(value);
                          }}
                          className={`py-2 font-medium first:mb-1 px-4 h6 ${
                            e.id === selectedItem &&
                            "bg-primary-light text-primary "
                          } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                        >
                          {e.title}
                        </li>
                      ))}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
        </div>
        <div
          className={`input-box text-heading-5 text-gray-700 font-normal relative row items-stretch w-full bg-gray-100 border border-gray-100 rounded-md [&>textarea]:min-h-[14rem] [&>textarea]:py-2 [&>textarea]:leading-5 [&>*]:min-w-0 focus-within:border-gray-500 group-[.input-group.error]:border-danger [&>*:first-child]:rounded-l-md [&>*:last-child]:rounded-r-md transition-colors `}
        >
          <ReactDatePicker
            dateFormat={dateFormat}
            className={`h-11 w-full`}
            selected={props.value}
            onChange={props.onChange}
            {...props}
            placeholderText={
              !!props.placeholderText
                ? translate(props.placeholderText) || ""
                : ""
            }
          />
        </div>
      </div>
    );

    // return (
    //   <ReactDatePicker
    //     {...props}
    //     name={props.name}
    //     className={props.className}
    //     locale={currentLanguage}
    //     dateFormat={dateFormat}
    //     selected={props.value}
    //     onChange={props.onChange}
    //   />
    // );
  }
);

export default DatePicker;
