import { useState, useEffect } from "react";
import { useToggleState, usePermission } from "hooks";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  Icon,
  NoItems
} from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import { Link, useSearchParams } from "react-router-dom";
import AddModal from "./add-modal";
import { config, appPermissions } from "constant";

type LegalEntityItems = {
  code: string;
  createdBy: string;
  createdDate: string;
  description: string;
  isRemoved: boolean;
  legalEntityId: string;
  name: string;
  tenantId: string;
  updatedBy: string;
  updatedDate: string;
};

type ListType = {
  items?: LegalEntityItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function LegalEntitiesList() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const [isOpen, toggle] = useToggleState(false);

  //permissions
  const {
    AS_LegalEntityFullAccess,
    AS_CreateLegalEntity,
    AS_GetLegalEntity,
    AS_GetLegalEntityDetail,
  } = appPermissions;

  const FULL_ACCESS = usePermission(AS_LegalEntityFullAccess);
  const CREATE_PERMISSION = usePermission(AS_CreateLegalEntity);
  const GET_LIST_PERMISSION = usePermission(AS_GetLegalEntity);
  const GET_DETAILS_PERMISSION = usePermission(AS_GetLegalEntityDetail);

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_LEGALENTITY;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config).then(({ data }) => {
      setData(data);
      setLoadingList(false);
    });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  return FULL_ACCESS || GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        {loadingList ? (
          <Skeleton.Button />
        ) : FULL_ACCESS || CREATE_PERMISSION ? (
          <Button onClick={() => toggle()}>
            <Icon className="mr-2" icon={"plus"} />
            <Text>company.legalEntities.addLegalEntity</Text>
          </Button>
        ) : null}
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ?
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={FULL_ACCESS || GET_DETAILS_PERMISSION ? item.legalEntityId : ""}>
                      <td className="text-heading-6 text-gray-800">
                        <Link
                          to={item.legalEntityId}
                          className="w-fit inline-flex flex-center gap-2"
                        >
                          <h6>{item.name}</h6>
                        </Link>
                      </td>
                      <td className="text-heading-5 text-gray-800">{item.code}</td>
                      <td>
                        {FULL_ACCESS || GET_DETAILS_PERMISSION ? (
                          <Button
                            variant="light"
                            size="sm"
                            as={Link}
                            to={item.legalEntityId}
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams((p) => ({ ...p, pageSize: e }))
                }
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
      <AddModal isOpen={isOpen} toggle={toggle} getData={getData} />
    </div>
  ) : null;
}
