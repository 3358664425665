import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { authInstance } from "./constant";
import { I18nextProvider } from "react-i18next";
import i18n from "./langs/i18n";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import "./assets/css/index.css";
import "./assets/icons/css/fontawesome.css";
import "./assets/icons/css/duotone.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MsalProvider instance={authInstance}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </MsalProvider>
);
