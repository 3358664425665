import { ServiceApi } from "services";
export default function useSearchAddress() {
  const searchAddress = async (params: any) => {
    const url = "/addressservice/api/address/search";
    const config = { params: { countrySet: "DE", ...params } };
    return await ServiceApi.get(url, config).then(({ data }) => {
      return data.results.map((e: any) => ({
        name: e.address?.freeformAddress,
        id: e.id,
        address: {
          street: e.address?.streetName,
          houseNo: e.address?.streetNumber,
          postalCode: e.address?.postalCode,
          city: e.address?.municipality,
          state: e.address?.countrySubdivision,
          country: e.address?.countryCode,
        },
      }));
    });
  };
  return searchAddress;
}
