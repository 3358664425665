// import { IconName } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type IconProps = {
  icon: string;
  fixedWidth?: boolean;
  className?: string;
  size?:
    | "2xs"
    | "xs"
    | "sm"
    | "lg"
    | "xl"
    | "2xl"
    | "1x"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x";
  onClick?: () => void;
};

const Icon = ({ icon, size = "lg", className, ...props }: IconProps) => {
  return (
    <i className={`fa-duotone fa-${icon} ${className} fa-${size}`} {...props} />
  );
};

export default Icon;
