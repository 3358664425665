import { useContext, useEffect, useState } from "react";
import { InputGroup, HandleName, Icon, Text } from "components";
import { AttributeContext } from "../attributes";
import { ServiceApi, URLS } from "services";
import { findValue } from "../../helpers/find-attribute.value";
import { updateValue } from "../../helpers/update-attribute-value";

const Measurment = () => {
  const {
    attribute,
    values,
    channel,
    language,
    id,
    setValues,
    flag,
    onChangeValue,
  } = useContext(AttributeContext);

  const [units, setUnits] = useState<any>([]);
  const [selectedUnit, setSelectedUnit] = useState("");

  const label = attribute.isRequired ? (
    <>
      {attribute.translations?.[0]?.displayName}{" "}
      <Icon icon="star" size="sm" className="text-danger ml-1" />{" "}
    </>
  ) : (
    attribute.translations?.[0]?.displayName
  );

  useEffect(() => {
    const url = URLS.GET_MEASUREMENT_LIST_BY_CODE(
      attribute?.measurementFamilyCode
    );
    ServiceApi.get(url).then(({ data }) => {
      setUnits(data);
    });
  }, []);

  const selectedUnitTranslate = (measurementFamilyUnitCode: any) => {
    return units?.measurementFamilyUnits?.find(
      (item: any) => item.code === (selectedUnit || measurementFamilyUnitCode)
    );
  };

  const { value, measurementFamilyUnitCode } = findValue(
    attribute,
    values,
    id,
    channel,
    language
  );

  const onChangeHandler = (e: any, code: any) => {
    const changedData = updateValue(
      attribute,
      values,
      id,
      e,
      channel,
      language,
      code
    );
    setValues(changedData);
    onChangeValue(attribute, id);
  };

  let flagName = attribute?.perLanguage ? flag[language] : null;
  let hint = attribute?.perChannel ? { label: channel } : null;

  return (
    <div>
      <div className="flex items-center space-x-4 justify-between mb-4">
        {label && (
          <h6 className="block h6 font-normal text-gray-800 truncate group-[.input-group.required]:after:content-['*'] after:text-danger">
            <Text>{label}</Text>
          </h6>
        )}
        {(flagName || hint) && (
          <div className="flex items-center">
            {hint && (
              <span className={`text-body-2 font-normal`}>{hint?.label}</span>
            )}
            {flagName && <img className="block ml-4" src={flag} alt="" />}
          </div>
        )}
      </div>
      <div className="mt-4" style={{ display: "flex" }}>
        <InputGroup
          value={value}
          setValue={(e) => onChangeHandler(e, selectedUnit)}
          className="rounded-r-none"
          append={
            units?.measurementFamilyUnits?.map((item: any) => {
              if (item.code === attribute?.defaultValue) {
                return <h5 className="text-heading-5 text-gray-400 border-gray-300 border-l px-4 py-3">
                  {<HandleName translations={item?.measurementFamilyUnitTranslation} code={item?.code} keyName="name" />}
                </h5>
              }
            })
          }
        />
      </div>
    </div>
  );
};

export default Measurment;
