import {
    Button,
    Dropdown,
    Icon,
    Image,
    Modal,
    Skeleton,
    Text,
} from "components";
import { flagCountry } from "constant";
import { useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import { checkPriceAvailability } from "utils";
import Lightbox from "yet-another-react-lightbox";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { PimContext } from "..";

type PreviewType = {
  isOpen: boolean;
  toggle: () => void;
  id?: string;
};

export default function Preview(props: PreviewType) {
  const { i18n } = useTranslation();
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImage, setSelectedImage] = useState<number>();
  const { productData, channels } = useContext(PimContext);
  // const [loading, setLoading] = useToggleState();
  const [loadingAttributes, setLoadingAttributes] = useToggleState();
  const [loadingMedias, setLoadingMedias] = useToggleState();
  const [attributes, setAttributes] = useState<any>([]);
  const [toggler, setToggler] = useToggleState();
  const [medias, setMedias] = useState<any>([]);
  // const [previewData, setPreviewData] = useState<any>([]);
  const [selectedChannel, setSelectedChannel] = useState<any>();
  const [lang, setLang] = useState<string>("de");
  const [channelLanguage, setChannelLanguage] = useState<any>();
  const items = [
    {
      name: "de",
      id: "de",
    },
    {
      name: "en",
      id: "en",
    },
    {
      name: "tr",
      id: "tr",
    },
    {
      name: "fr",
      id: "fr",
    },
  ];

  const filterLanguagePerChannel = () => {
    const findChannel = channels.filter(
      (item: any) => item.code === selectedChannel
    );

    const filteredItems = items?.filter((lang: any) =>
      findChannel[0]?.availableLanguages?.includes(lang.id)
    );

    setChannelLanguage(filteredItems);
  };

  useEffect(() => {
    filterLanguagePerChannel();
  }, [selectedChannel]);

  const langCountry: any = {
    de: "German",
    en: "English",
    tr: "Turkish",
    fr: "French",
  };

  const findTranslatedName = (channel: any) => {
    return channel?.channelNameTranslates?.filter(
      (tr: any) => tr.language.toLowerCase() === i18n.language
    )?.[0]?.name;
  };

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const getPreview = (channel?: string) => {
    const url = URLS.GET_PIM_PRODUCT_ATTRIBUTES(productData?.id);
    ServiceApi.get(url)
      .then(({ data }) => {
        setAttributes(data?.pimProductGroupDtos);
        setLoadingAttributes()
      })
      .catch(() => setLoadingAttributes());
  };

  const getMedias = (channel?: string) => {
    setMedias([]);
    const url = URLS.GET_PIM_PRODUCT_MEDIA_LIST_URL(productData.id, 1, 100);
    ServiceApi.get(url)
      .then(({ data }) => {
        let array: any = [];
        let urlImg: any = [];
        data?.items?.forEach((image: any) => {
          if ((image.channelsCodeList?.length === 0 ||
            image.channelsCodeList?.includes(channel ? channel : selectedChannel))) {
            array.push(image)
            urlImg.push({ "src": image.url })
          }
        })
        setMedias(array);
        setImageUrls(urlImg)
        setLoadingMedias();
      })
      .catch(() => setLoadingMedias());
  };

  useEffect(() => {
    if (props.isOpen) {
      setLoadingAttributes();
      setLoadingMedias();
      setSelectedChannel(channels?.[0].code);
      getPreview(channels?.[0].code);
      getMedias(channels?.[0].code)
    }
  }, [props.isOpen]);

  const findAttributeGroupTranslate = (item: any) => {
    const translate = item.translations?.find((tr: any) => tr.language === lang);
    if (translate) {
      return translate.name;
    }
    return item.translations?.[0]?.name;
  };

  const findAttributeTranslate = (item: any) => {
    const translate = item.translations?.find(
      (tr: any) => tr.language === lang
    );
    if (translate) {
      return translate?.displayName;
    }
    return item.translations?.[0]?.displayName;
  };


  const findAttributeValueTranslate = (item: any) => {
    if (item.perLanguage) {
      const translate = item.values?.find((tr: any) => tr.language === lang);
      if (translate) {
        return translate?.value?.replace(/(<([^>]+)>)/ig, '');
      }
      return null;
    } else {
      const translate = item.values?.find((tr: any) => tr.channel === selectedChannel);
      if (translate) {
        return translate?.value?.replace(/(<([^>]+)>)/ig, '');
      }
      return null
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      modalClassName="z-40"
      size={"xl"}
    >
      <Modal.Header className="!text-left text-heading-3 text-gray-800 font-bold flex justify-between items-center">
        <div className="flex space-x-2 items-center">
          <span>
            <Text>productManagement.products.Details.productPreviewFor</Text>
          </span>
          <Dropdown>
            <Dropdown.Toggle
              as="button"
              type="button"
              className="text-gray-600 font-medium text-body-base uppercase flex space-x-2 bg-gray-100 p-3 rounded-md min-w-[10rem]"
            >
              <span>
                {findTranslatedName(
                  channels.filter(
                    (channel: any) => channel?.code === selectedChannel
                  )?.[0]
                )}{" "}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="min-w-fit mt-2 p-2">
              <ul className="text-gray-700 w-max">
                {channels.map((channel: any) => (
                  <li
                    key={channel?.code}
                    onClick={() => {
                      getPreview(channel.code);
                      getMedias(channel.code)
                      setSelectedChannel(channel.code);
                      setLoadingAttributes()
                      setLoadingMedias()
                    }}
                    className={`py-2 font-medium first:mb-1 px-4 h6 ${channel.code === selectedChannel &&
                      "bg-primary-light text-primary "
                      } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                  >
                    {findTranslatedName(channel)}
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle
              as="button"
              type="button"
              className="text-gray-600 font-medium text-body-base uppercase flex space-x-2 bg-gray-100 p-3 rounded-md"
            >
              <img src={flagCountry[lang]} />
              <span>{lang}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="min-w-fit mt-2 p-2">
              <ul className="text-gray-700 w-[104px]">
                {channelLanguage?.map((e: any) => (
                  <li
                    key={e.id}
                    onClick={() => onChangeLanguage(e.id)}
                    className={`py-2 font-medium first:mb-1 px-4 h6 ${e.id === lang && "bg-primary-light text-primary "
                      } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                  >
                    {langCountry[e.name]}
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Button size="sm" onClick={props.toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.close</Text>
        </Button>
      </Modal.Header>
      <Modal.Body className="overflow-auto min-h-[300px] space-y-4">
        <>
          <div className="border border-dashed rounded p-6 border-gray-400 space-y-4">
            <div className="flex flex-col gap-4 [&>div:nth-child(even)]:bg-gray-100">
              <p className="text-heading-3 text-gray-600 mb-4">
                <Text>productManagement.products.Details.basicInfo</Text>
              </p>
              <div className="grid grid-cols-12 gap-4 p-2">
                <span className="col-span-3"><Text>productManagement.products.allProducts.sku</Text>{" "}</span>
                <span className="col-span-9">{productData?.sku}</span>
              </div>
              <div className="grid grid-cols-12 gap-4 p-2">
                <span className="col-span-3">
                  <Text>productManagement.products.Details.price</Text>{" "}
                </span>
                <span className="col-span-9">
                  {productData?.price?.currencySymbo}{" "}
                  {checkPriceAvailability(productData?.price?.amount)}
                </span>
              </div>
              <div className="grid grid-cols-12 gap-4 p-2">
                <span className="col-span-3">
                  <Text>productManagement.products.Details.articleNumber</Text>{" "}
                </span>
                <span className="col-span-9">
                  #{productData?.articleNumber}
                </span>
              </div>
              {/* Supplier */}
              {productData?.supplierInfo && (
                <div className="grid grid-cols-12 gap-4 p-2">
                  <span className="col-span-3">
                    <Text>productManagement.products.Details.supplier</Text>{" "}
                  </span>
                  <span className="col-span-9">
                    {productData?.supplierInfo?.name}
                  </span>
                </div>
              )}
              {/* Program */}
              {productData?.programInfo && (
                <div className="grid grid-cols-12 gap-4 p-2">
                  <span className="col-span-3">
                    <Text>productManagement.products.Details.program</Text>{" "}
                  </span>
                  <span className="col-span-9">
                    {productData?.programInfo?.title}
                  </span>
                </div>
              )}
              {/* Brand */}
              {productData?.brandInfo && (
                <div className="grid grid-cols-12 gap-4 p-2">
                  <span className="col-span-3">
                    <Text>productManagement.products.Details.brand</Text>{" "}
                  </span>
                  <span className="col-span-9">
                    {productData?.brandInfo?.name}
                  </span>
                </div>
              )}
              {/* locations */}
              {productData?.locations?.length > 0 && (
                <div className="grid grid-cols-12 gap-4 p-2">
                  <span className="col-span-3">
                    <Text>
                      productManagement.products.Details.locationsTab
                    </Text>{" "}
                  </span>
                  <span className="col-span-9">
                    {productData?.locations?.map(
                      (location: any, index: any) => {
                        return (
                          <span className="mr-2">{location.storeName} ,</span>
                        );
                      }
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
        {loadingAttributes &&
          [1, 2, 3, 4].map((key: number) => <Skeleton.List key={key} />)}
        {!loadingAttributes &&
          attributes?.map((item: any) => {
            return (
              <>
                {findAttributeGroupTranslate(item) && (
                  <div className="border border-dashed rounded p-6 border-gray-400 space-y-4">
                    <p className="text-heading-3 text-gray-600 mb-8">
                      {findAttributeGroupTranslate(item)}
                    </p>
                    <div className="flex flex-col gap-4 [&>div:nth-child(even)]:bg-gray-100">
                      {item?.pimProductAttributeValues?.map((attr: any) => {
                        return attr?.values?.map((item: any) => {
                          if (item.channel === selectedChannel) {
                            return (
                              <div className="grid grid-cols-12 gap-4 p-2">
                                <span className="col-span-3">
                                  {findAttributeTranslate(attr)}
                                  {attr.isRequired && <Icon icon="star" className="text-danger" />}
                                </span>
                                <span className="col-span-9 text-body leading-5">
                                  {findAttributeValueTranslate(attr) || (
                                    <span className="text-gray-600 text-body-2">
                                      <Text>
                                        productManagement.products.Details.noData
                                      </Text>
                                    </span>
                                  )}
                                </span>
                              </div>
                            );
                          }
                        })
                      })}
                    </div>
                  </div>
                )}
              </>
            );
          })
        }
        {loadingMedias &&
          [1, 2, 3, 4].map((key: number) => <Skeleton.List key={key} />)}
        {!loadingMedias && (
          <div className="border border-dashed rounded p-6 border-gray-400 space-y-4">
            <p className="text-heading-3 text-gray-600 mb-4">
              <Text>productManagement.products.Details.mediaTab</Text>
            </p>
            <div className="flex flex-wrap justify-center gap-2">
              {medias?.map((image: any, index: any) => {
                return (

                  < Image
                    cover={image.tags.length === 0 ? false : true}
                    key={image?.url}
                    src={image?.url}
                    className="max-w-lg cursor-pointer flex-none bg-gray-100 w-[325px]"
                    imageClassName="w-72 object-contain"
                    onClick={() => {
                      setSelectedImage(index);
                      setToggler();
                    }}
                  />


                );
              })}
            </div>
            <Lightbox
              open={toggler}
              index={selectedImage}
              close={() => {
                document.body.style.overflow = "visible";
                setToggler();
              }}
              slides={imageUrls}
              plugins={[Zoom, Slideshow]}
            />
          </div>
        )}
      </Modal.Body>
      {/* End */}
    </Modal>
  );
}
