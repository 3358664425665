const store = [
  {
    name: "status.store.active",
    id: true,
    variant: "success",
  },
  {
    name: "status.store.deActive",
    id: false,
    variant: "danger",
  },
];
const supplier = [
  {
    name: "status.store.active",
    id: true,
    variant: "success",
  },
  {
    name: "status.store.deActive",
    id: false,
    variant: "gray",
  },
];
const required = [
  {
    name: "global.required",
    id: true,
    variant: "success",
  },
  {
    name: "global.notRequired",
    id: false,
    variant: "danger",
  },
];
const sellOff = [
  {
    name: "salesManagment.sellOffSales.sellOffProduct.preparation",
    variant: "primary",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.active",
    variant: "success",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.sold",
    variant: "info",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.needPhaseChange",
    variant: "warning",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.needClose",
    variant: "danger",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.closed",
    variant: "gray",
  },
  {
    name: "salesManagment.sellOffSales.sellOffProduct.notSold",
    variant: "gray",
  },
];
const requiredAttribute = [
  {
    name: "global.required",
    id: true,
    variant: "success",
  },
];
const perChannelAttribute = [
  {
    name: "productManagement.masterData.attributes.perChannel",
    id: true,
    variant: "info",
  },
];
const perLanguageAttribute = [
  {
    name: "productManagement.masterData.attributes.perLanguage",
    id: true,
    variant: "primary",
  },
];
const defaultLabel = [
  {
    name: "global.default",
    id: true,
    variant: "success",
  },
];
const standard = [
  {
    name: "global.standard",
    id: true,
    variant: "success",
  },
];

const productFamilies = [
  {
    name: "productManagement.masterData.productFamilies.perLanguage",
    variant: "primary",
  },
  {
    name: "productManagement.masterData.productFamilies.required",
    variant: "success",
  },
  {
    name: "productManagement.masterData.productFamilies.perChannel",
    variant: "info",
  },
  {
    name: "productManagement.masterData.productFamilies.inherit",
    variant: "gray",
  },
];
const roles = [
  {
    name: "global.user",
    id: 8,
    variant: "success",
  },
  {
    name: "global.admin",
    id: 4,
    variant: "success",
  },
];

const productActive = [
  {
    name: "status.store.active",
    id: true,
    variant: "success",
  },
  {
    name: "status.store.deActive",
    id: false,
    variant: "gray",
  },
];
const salesListStatus = [
  {
    name: "",
    id: 1,
    variant: "",
    icon:""
  },
  {
    name: "",
    id: 2,
    variant: "danger",
    icon:"triangle-exclamation"
  },
  {
    name: "status.salesList.pendingApproval",
    id: 3,
    variant: "warning",
    icon:"clock-five"
  },
  {
    name: "status.salesList.approved",
    id: 4,
    variant: "success",
    icon:"check"
  },
];

const sellFactorHasWarning = [
  {
    name: "status.salesList.sellFactorHasWarning",
    id: true,
    variant: "danger",
  },
  {
    name: "",
    id: false,
    variant: "",
  },
];

const shippingCostHasWarning = [
  {
    name: "status.salesList.shippingCostHasWarning",
    id: true,
    variant: "danger",
  },
  {
    name: "",
    id: false,
    variant: "",
  },
];


const active = [
  {
    name: "status.store.active",
    id: true,
    variant: "success",
  },
  {
    name: "status.store.deActive",
    id: false,
    variant: "danger",
  },
];

const status = {
  store,
  required,
  sellOff,
  supplier,
  requiredAttribute,
  perChannelAttribute,
  perLanguageAttribute,
  defaultLabel,
  standard,
  productFamilies,
  roles,
  productActive,
  salesListStatus,
  sellFactorHasWarning,
  shippingCostHasWarning,
  active
};
export default status;
