import { NotificationManager } from "common/notifications";
import { Button, InputGroup, SelectLang, Text, Wrapper } from "components";
import { flagCountry } from "constant";
import { useDataState, useSelector, useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { CategoryContext } from "..";

const General = () => {
  const { selectedCategory, getCategories } = useContext(CategoryContext);
  const profile = useSelector((s) => s.profile);
  const [lang, setLang] = useState<string>("de");
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loading, setLoading] = useToggleState();
  const [langItems, setLangItems] = useState([]);


  useEffect(() => {
    if (selectedCategory) {
      setBaseData(selectedCategory);
    }
    setLang(profile.companyDefaultLanguage)
  }, [selectedCategory]);


  useEffect(() => {
    if (selectedCategory) {
      setBaseData(selectedCategory);
    }
    setLang(profile.companyDefaultLanguage)
  }, []);

  useEffect(() => {
    let arr: any = [];
    profile?.companyContentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item })
    })
    setLangItems(arr)
  }, [profile])// eslint-disable-line react-hooks/exhaustive-deps

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const onChangeHandler = (value: any, key: string) => {
    const isAvailable = data?.translations?.filter(
      (tr: any) => tr.language.toLowerCase() === lang
    );
    let clone = [...data?.translations];
    if (isAvailable.length === 0) {
      clone.push({
        [key]: value,
        language: lang,
        description: "",
      });
    } else {
      data?.translations?.map((tr: any, index: number) => {
        if (tr.language.toLowerCase() === lang) {
          clone[index][key] = value;
        }
      });
    }
    setData({ ...data, translations: clone });
  };

  const findCategoryByLang = () => {
    const obj = data?.translations?.filter(
      (item: any) => item.language.toLowerCase() === lang
    )[0];
    return obj;
  };

  const submitHandler = () => {
    setLoading();
    const url = URLS.EDIT_CATEGORY_TRANSLATES(data.productCategoryId);
    const body = {
      code: data.code,
      pictureThumbnailUrl: data.pictureThumbnailUrl,
      pictureUrl: data.pictureUrl,
      translates: data.translations,
    };
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        getCategories();
        setLoading();
      })
      .catch(() => setLoading());
  };

  return (
    <>
      <Wrapper>
        <Wrapper.Header className="flex justify-between items-center text-heading-2 font-semibold">
          <span>
            <Text>productManagement.masterData.productCategory.basicInfo</Text>
          </span>
          <SelectLang value={lang} items={langItems} onChangeLanguage={onChangeLanguage} />
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-2 gap-9">
          <InputGroup
            value={data?.translations && findCategoryByLang()?.name}
            setValue={(e) => onChangeHandler(e, "name")}
            label="productManagement.masterData.productCategory.categoryName"
            flag={flagCountry[lang]}
          />
          <InputGroup
            value={selectedCategory?.code}
            label="productManagement.masterData.productCategory.categoryCode"
            disabled
            readOnly
          />
          <div className="col-span-2">
            <InputGroup
              as={"short-textarea"}
              setValue={(e) => onChangeHandler(e, "shortDescription")}
              value={
                data?.translations && findCategoryByLang()?.shortDescription
              }
              label="productManagement.masterData.productCategory.shortDescription"
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="sticky bottom-0 shadow-card">
        <Wrapper.Body className="flex items-center justify-end gap-4">
          <Button
            type="button"
            variant="primary"
            onClick={submitHandler}
            loading={loading}
            disabled={!isChanged}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Wrapper.Body>
      </Wrapper>
    </>
  );
};

export default General;
