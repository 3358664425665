import AttributesList from "pages/product-managment/master-data/attributes";
import Create from "pages/product-managment/master-data/attributes/create";
import Details from "pages/product-managment/master-data/attributes/details";

const Attributes = [
  {
    path: "",
    element: <AttributesList />,
  },
  {
    path: "new",
    element: <Create />,
  },
  {
    path: ":attributeId",
    element: <Details />,
  },
];

export default Attributes;
