import SalesChecklist from "pages/sales-managment/sell-off-sales/sales-check-list";
import PreparationPhase from "pages/sales-managment/sell-off-sales/preparation-phase";
import SellOffCommissions from "pages/sales-managment/sell-off-sales/sell-off-commissions";
import SalesTemplates from "./sales-templates";
import SellOffProductsRoutes from "./sell-off-products";

const SellOffSales = [
  {
    path: "sell-off-products",
    children: [...SellOffProductsRoutes],
  },
  {
    path: "sales-templates",
    children: [...SalesTemplates],
  },
  {
    path: "sales-settings",
    element: <>sales-settings</>,
  },
  {
    path: "sell-off-commissions",
    element: <SellOffCommissions />,
  },
  {
    path: "sales-check-list",
    element: <SalesChecklist />,
  },
  {
    path: "preparation-phase",
    element: <PreparationPhase />,
  },
];

export default SellOffSales;
