import { useSelector } from "hooks";
import Profile from "./profile";
import Lang from "./lang";

const Header = () => {
  const header =
    "sticky top-0 z-50 right-0 bg-white shadow h-16 w-full flex items-center gap-4 px-8 py-3 z-10 justify-end text-";

  const profile = useSelector((s) => s.profile);

  return (
    <header className={header}>
      <Lang />
      <span className="text-gray-600 text-body-base font-medium">
        {profile.firstName} {profile.lastName}
      </span>
      <Profile profile={profile} />
    </header>
  );
};

export default Header;
