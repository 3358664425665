import { Button, Icon, Modal, Text } from "components";
type Props = {
  isOpen: boolean;
  toggle: () => void;
  metaData?: any;
};

const ImageMetaData = (props: Props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      modalClassName="z-40"
      size={"lg"}
    >
      <Modal.Header className="!text-left text-heading-2 font-semibold">
        <Text>productManagement.products.Details.fileInfo</Text>
      </Modal.Header>
      <Modal.Body className="overflow-auto min-h-[300px] space-y-4">
        <div className="p-2 items-center grid grid-cols-12 gap-4">
          <span className="col-span-3">OriginalName </span>
          <span className="col-span-9 break-words leading-6">
            {props.metaData?.OriginalName}
          </span>
        </div>
        <div className="p-2 items-center grid grid-cols-12 gap-4 bg-gray-100">
          <span className="col-span-3">StoredHeight </span>
          <span className="col-span-9 break-words leading-6">
            {props.metaData?.StoredHeight}
          </span>
        </div>
        <div className="p-2 items-center grid grid-cols-12 gap-4">
          <span className="col-span-3">StoredWidth </span>
          <span className="col-span-9 break-words leading-6">
            {props.metaData?.StoredWidth}
          </span>
        </div>
        <div className="p-2 items-center grid grid-cols-12 gap-4 bg-gray-100">
          <span className="col-span-3">ActualHeight </span>
          <span className="col-span-9 break-words leading-6">
            {props.metaData?.ActualHeight}
          </span>
        </div>
        <div className="p-2 items-center grid grid-cols-12 gap-4">
          <span className="col-span-3">ActualWidth </span>
          <span className="col-span-9 break-words leading-6">
            {props.metaData?.ActualWidth}
          </span>
        </div>
        <div className="p-2 items-center grid grid-cols-12 gap-4 bg-gray-100">
          <span className="col-span-3">HorizontalResolution </span>
          <span className="col-span-9 break-words leading-6">
            {props.metaData?.HorizontalResolution}
          </span>
        </div>
        <div className="p-2 items-center grid grid-cols-12 gap-4">
          <span className="col-span-3">VerticalResolution </span>
          <span className="col-span-9 break-words leading-6">
            {props.metaData?.VerticalResolution}
          </span>
        </div>
        <div className="p-2 items-center grid grid-cols-12 gap-4 bg-gray-100">
          <span className="col-span-3">StoredSize </span>
          <span className="col-span-9 break-words leading-6">
            {props.metaData?.StoredSize}
          </span>
        </div>
        <div className="p-2 items-center grid grid-cols-12 gap-4">
          <span className="col-span-3">ActualSize </span>
          <span className="col-span-9 break-words leading-6">
            {props.metaData?.ActualSize}
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button size="sm" onClick={props.toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageMetaData;
