import {
    Fragment,
    createContext,
    createElement,
    useEffect,
    useState
} from "react";

import { NotificationManager } from "common/notifications";
import {
    Breadcrumb,
    Button,
    DeleteModal,
    Footer,
    Form,
    HandleName,
    Icon,
    Skeleton,
    Tabs,
    Text,
    Wrapper
} from "components";
import { appPermissions } from "constant";
import { useDataState, usePermission, useSelector, useToggleState } from "hooks";
import { useParams } from "react-router";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { ObjectUtils } from "utils";
import Attributes from "./tabs/attributes";
import General from "./tabs/general";

export const AttributesContext = createContext({});

export default function Update() {
  const { attributeGroupTemplateId } = useParams();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [langItems, setLangItems] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const profile = useSelector((s) => s.profile);
  const navigate = useNavigate();
  const [selected, setSelected] = useState<any>();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [langSelected, setLangSelected] = useState("de");

  //permissions
  const {
    PS_UpdateAttributeGroupTemplate,
  } = appPermissions;
  const UPDATE_PERMISSIONS = usePermission(PS_UpdateAttributeGroupTemplate);

  const tabs = [
    {
      label: "productManagement.masterData.attributesGroups.general",
      component: General,
    },
    {
      label: "productManagement.masterData.attributesGroups.attributes",
      component: Attributes,
    },
  ];

  const getData = () => {
    setLoading(true);
    let arr: any = [];
    profile?.companyContentLanguages?.forEach((item: any) => {
      arr.push({ id: item?.toLocaleLowerCase(), name: item })
    })
    const url = URLS.GET_ATTRIBUTE_GROUP_DETAILS_URLS(attributeGroupTemplateId);
    let newData: any;
    ServiceApi.get(url)
      .then(({ data }) => {
        newData = JSON.parse(JSON.stringify(data));
        newData["translations"]?.forEach((i: any, index: number) => {
          if (index === 0) {
            newData["title"] = i.name
          }
          if (i?.language?.toLocaleLowerCase() === profile?.companyDefaultLanguage?.toLocaleLowerCase()) {
            setName(i.name)
            setDescription(i.description)
          }
        })
        if (newData?.translations?.length !== arr?.length) {
          var props = ['id'];
          var result = arr?.filter(function (i: any) {
            return !newData?.translations?.some(function (a: any) {
              return i?.id?.toLocaleLowerCase() === a?.language?.toLocaleLowerCase();
            });
          }).map(function (o: any) {
            return props?.reduce(function (newo: any, language: any) {
              newo["id"] = o[language];
              return newo;
            }, {});
          });

          result?.forEach((item: any) => {
            newData?.translations?.push({
              language: item.id,
              name: "",
              description: ""
            })
          })
        }

        setBaseData(newData);
      }).finally(() => {
        setLoading(false);
      });
    setLangSelected(profile?.companyDefaultLanguage?.toLocaleLowerCase())
    setLangItems(arr)
  };

  const submit = () => {
    setSubmitLoading(true);
    data["translates"] = [];
    data.translations.forEach((item: any) => {
      if (item.name !== "") {
        data["translates"].push(item)
      }
    })
    delete data.translations
    delete data.title
    const url = URLS.UPDATE_ATTRIBUTE_GROUP_DETAILS_URLS(attributeGroupTemplateId);
    const body = { ...data };
    ServiceApi.put(url, body)
      .then(() => {
        setBaseData(data);
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        getData();
      }).finally(() => {
        setSubmitLoading(false);
      });
  };
  useEffect(getData, [profile])// eslint-disable-line react-hooks/exhaustive-deps


  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_ATTRIBUTE_GROUP_URLS(id);
    ServiceApi.delete(url).then(({ data }) => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      setLoadingButton(false);
      toggleDeleteModal();
      navigate(-1)
    }).catch(() => setLoadingButton(false));
  };

  return (
    <Tabs activeKey={tabs[searchParams.get("tab") as "0"].label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <div className="flex items-center">
          <div className="flex-1">
            <Breadcrumb />
          </div>
          {loading ? (
            <Skeleton.Button />
          ) : (UPDATE_PERMISSIONS ?
            <Button
              type="button"
              variant="white"
              textColor="danger"
              onClick={() => {
                setSelected(data);
                toggleDeleteModal();
              }}
            >
              <Icon className="mr-2" icon={"trash"} /><Text>global.buttons.delete</Text>
            </Button>
            : null)}
        </div>
        <Wrapper>
          <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
            <div className="lg:flex-1 space-y-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <h5 className="text-heading-5 text-gray-800 font-semibold">
                    {<HandleName translations={data?.translations} code={data?.code} keyName="name" />}
                  </h5>
                  <p className="text-gray-500 text-body-2 font-medium">{data.code}</p>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e) => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map((e) => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <AttributesContext.Provider
              value={{
                attributeGroupTemplateId: attributeGroupTemplateId,
                attributesGroupData: data,
                description: description,
                name: name,
                langItems: langItems,
                loading: loading,
                setLangItems: setLangItems,
                setName: setName,
                setDescription: setDescription,
                setAttributesGroupData: setData,
                getData: getData,
                setLoading: setLoading,
                langSelected: langSelected,
                setLangSelected: setLangSelected
              }}
            >
              {!ObjectUtils.isEmpty(data) && createElement(e.component)}
            </AttributesContext.Provider>
          </Tabs.Item>
        ))}
        {UPDATE_PERMISSIONS && (
          <Footer show={!isChanged} >
            <Button as={Link} to={"/productManagement/master-data/attribute-groupes"} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        )}
      </Form>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ id: attributeGroupTemplateId, name: <HandleName translations={selected?.translations} code={selected?.code} keyName="name" /> }}
        loading={loadingButton}
        onConfirm={submitDelete}
      />

    </Tabs>
  );
}
