import BRANDS from "./brands";
import STORE from "./store";
import USERS from "./users";
import ATTRIBUTES from "./attributes";
import ATTRIBUTES_GROUPS from "./attributes-groups";
import CATEGORIES from "./categories";
import SUPPLIERS from "./suppliers";
import INFO_PAGES from "./info-pages";
import LEGAL_ENTITY from "./legal-entity";
import API_KEYS from "./api-keys";
import POSITIONS from "./positions";
import ERP from "./erp";
import SHOP from "./shop";
import CRM from "./crm";
import NOTIFICATIONS from "./notifications";
import REPORTS from "./reports";
import SELL_PHASE from "./sell-phase";
import PERMISSIONS from "./permissions";
import SUB_ITEMS from "./sub-items";
import ROLES from "./roles";
import COMPANY from "./company";
import PIM from "./pim";
import USER_GROUPS from "./user-groups";
import MEASUREMENT from "./measurement";
import CUSTOMERS from "./customers";
import Locations from "./locations";
import ETC from "./etc";
import SELL_OF_PRODUCT from "./sell-of-product";
import MANAGE_CHECK_LIST from "./manage-check-list";
import PREPARATION_PHASE from "./preparation-phase";
import SSO_SETTINGS from "./sso-settings";
import IMPORT_PRODUCT from "./import-product";
import DATA_STORAGE from "./data-storage";
import SELL_OF_COMMISSIONS from "./sell-off-commissions";
import EMPLOYEES from "./employees";
import IWOFURN from "./iwofurn";

const URLS = {
  ...BRANDS,
  ...STORE,
  ...USERS,
  ...ATTRIBUTES,
  ...ATTRIBUTES_GROUPS,
  ...SELL_OF_PRODUCT,
  ...CATEGORIES,
  ...SUPPLIERS,
  ...INFO_PAGES,
  ...LEGAL_ENTITY,
  ...API_KEYS,
  ...POSITIONS,
  ...ERP,
  ...SHOP,
  ...CRM,
  ...NOTIFICATIONS,
  ...REPORTS,
  ...SELL_PHASE,
  ...PERMISSIONS,
  ...SUB_ITEMS,
  ...ROLES,
  ...COMPANY,
  ...PIM,
  ...USER_GROUPS,
  ...MEASUREMENT,
  ...CUSTOMERS,
  ...Locations,
  ...ETC,
  ...MANAGE_CHECK_LIST,
  ...PREPARATION_PHASE,
  ...SSO_SETTINGS,
  ...IMPORT_PRODUCT,
  ...DATA_STORAGE,
  ...SELL_OF_COMMISSIONS,
  ...EMPLOYEES,
  ...IWOFURN,
};

export default URLS;
