import {
    applications,
    company,
    configurations,
    dashboard,
    errors,
    global,
    menu,
    productManagement,
    salesManagment,
    status,
    thirdParty,
} from "./de_DE";
const de = {
  menu,
  configurations,
  status,
  global,
  errors,
  productManagement,
  thirdParty,
  salesManagment,
  applications,
  company,
  dashboard,
};
export default de;
