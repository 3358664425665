export const positions = {
  // Position list
  addPositionButton: "Add Position",
  positionActiveStatus: "Active",
  positionInactiveStatus: "inactive",
  positionEditToast: "Position edited successfully",
  positionRemoveToast: "Position removed successfully",
  addComponentButton: "Add component",
  // Position Form
  positionAddFormHeader: "Add position",
  positionEditFormHeader: "Edit position",
  positionFormName: "Position name",
  positionFormCode: "Code",
  positionFormDescription: "Description",
  positionFormComponentLayout: "Position component layout",
  positionFormCloseButton: "Close",
  positionFormSubmitButton: "Submit",
  positionAddFormToast: "Position added successfully.",
  positionEditFormToast: "Position edited successfully.",
  // Add component
  addComponentHeader: "Add component",
  addComponentCloseButton: "Close",
  addComponentTitle: "Title",
  addComponentCancelButton: "Cancel",
  addComponentSubmitButton: "Add component",
  componentDetailsButton: "Details",
  removeComponent: "Component removed successfully.",
  // Carousel form
  carouselAddToast: "Item added successfully.",
  carouselEditToast: "Item edited successfully.",
  carouselRemoveToast: "Item removed successfully.",
  carouselSubtitle: "Subtitle",
  carouselTitle: "Title",
  carouselLinkAssigned: "Link assigned to item",
  carouselLink: "Link",
  addItemButton: "Add item",
  carouselCancelButton: "Cancel",
  carouselSubmitButton: "Submit",
  // Banner form
  editBannerToast: "Banner edited successfully.",
  bannerSubtitle: "Subtitle",
  bannerTitle: "Title",
  bannerLinkAssigned: "Link assigned to item",
  bannerLink: "Link",
  bannerCancelButton: "Cancel",
  bannerSubmitButton: "Submit",
};
