export const salesList = {
  projectNumber: "Projektnummer",
  projectName: "Projektname",
  contract: "Vertrag",
  advisor: "Berater",
  createdAt: "Erstellt am",
  lastUpdated: "Zuletzt aktualisiert",
  targetSellFactor: "Zielverkaufsfaktor",
  sellFactor: "Verkaufsfaktor",
  filters: "Filter",
  resetFilters: "Filter zurücksetzen",
  close: "Schließen",
  keyword: "Stichwort",
  dateRange: "Datumsbereich",
  thisWeek: "Diese Woche",
  thisMonth: "Diesen Monat",
  lastMonth: "Letzter Monat",
  from: "Von",
  to: "Bis",
  status: "Status",
  topBuyDevices: "Top-Kaufgeräte",
  location: "Ort",
  store: "Filiale",
  sellFactorWarning: "Warnung Verkaufsfaktor",
  shippingCostWarning: "Warnung Versandkosten",
  hasContractNumber: "Hat Vertragsnummer",
  details: "Details",
  customer: "Kunde",
  comments: "Kommentare",
  topBuy: "Top Kauf",
  targetShippingCost: "Ziel-Versandkosten",
  shippingCost: "Versandkosten",
  shippingCostWithoutInstallation: "Versandkosten ohne Installation",
  serviceCost: "Servicekosten",
  name: "Name",
  preferredShippingDate: "Bevorzugtes Versanddatum",
  contractProcess: "Vertragsprozess",
  contractDate: "Vertragsdatum",
  statusNote: "Statusnotiz",
  updatedDateTime: "Aktualisiert am",
  totalAmount: "Gesamtbetrag",
  totalTarget: "Gesamtziel",
  purchaseNet: "Nettoeinkauf",
  yourComment: "Ihr Kommentar",
};
