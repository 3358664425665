import { Fragment, useMemo } from "react";
import { dataType } from ".";
import { Text, Wrapper } from "components";
import { useConverter } from "hooks";
import Percent from "./Percent";
import AverageFactor from "./AverageFactor";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
type storeReportProps = {
  data: dataType;
  storeData: dataType;
  dateFrom: Date;
  dateTo: Date;
};
export default function StoreReport({
  data,
  storeData,
  dateFrom,
  dateTo,
}: storeReportProps) {
  const { convertAmount } = useConverter();
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Body className="space-y-8">
          <div className="space-x-3">
            <span className="text-gray-700 text-body-2">
              <Text>salesManagment.kitchenSales.salesReport.totalSales</Text>
            </span>
            <span className="text-info h6">
              {convertAmount(storeData.totalSales)}
            </span>
            <Percent value={storeData.growPercentage} />
          </div>
          <AverageFactor
            average={storeData.averageSellFactor}
            target={storeData.averageTargetSellFactor}
            className="bg-gray-100 py-5 px-4"
          />
          <PieChart allData={data} currentData={storeData} />
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="xl:col-span-2">
        <Wrapper.Header className="flex items-center gap-4">
          <h2 className="me-auto">
            <Text>salesManagment.kitchenSales.salesReport.salesCompare</Text>
          </h2>
          <p className="text-body-1 font-medium text-gray-500">
            <span className="inline-block w-1 h-1 rounded-full bg-current me-1 align-middle" />
            <Text>
              salesManagment.kitchenSales.salesReport.similarToLastYear
            </Text>
          </p>
          <p className="text-body-1 font-medium text-info">
            <span className="inline-block w-1 h-1 rounded-full bg-current me-1 align-middle" />
            <Text>salesManagment.kitchenSales.salesReport.selectedDate</Text>
          </p>
        </Wrapper.Header>
        <Wrapper.Body>
          <LineChart
            currentData={storeData.salesItemsPerMonth}
            lastYearData={storeData.lastYearSalesItemsPerMonth}
            height={345}
          />
        </Wrapper.Body>
      </Wrapper>
    </Fragment>
  );
}
