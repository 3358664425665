import { useContext, useState } from "react";
import { ConfigurationsContext } from ".";
import { ServiceApi } from "services";
import { toast } from "react-hot-toast";
import { Button, Form, InputGroup, Text, Wrapper } from "components";
import { rules } from "constant";
import { cloneDeep } from "lodash";

export default function Tolerance() {
  const { data, setData } = useContext(ConfigurationsContext);
  const [loading, setLoading] = useState(false);

  const handleSetValue = (key: keyof typeof data.tolerance) => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        data.tolerance[key] = value;
        return data;
      });
  };

  const submit = () => {
    setLoading(true);
    const url = "/statisticsservice/api/companies/tolerance";
    const body = { tolerance: data.tolerance };
    ServiceApi.patch(url, body)
      .then(() => {
        toast.success(
          "salesManagment.kitchenSales.configuration.updateSuccessTolerance"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Form onSubmit={submit}>
      <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <InputGroup
          label="salesManagment.kitchenSales.configuration.minimumToleranceField"
          type="number"
          value={data.tolerance.min}
          setValue={handleSetValue("min")}
          rules={rules.required}
        />
        <InputGroup
          label="salesManagment.kitchenSales.configuration.maximumToleranceField"
          type="number"
          value={data.tolerance.max}
          setValue={handleSetValue("max")}
          rules={rules.required}
        />
      </Wrapper.Body>
      <Wrapper.Footer>
        <Button type="submit" variant="primary" loading={loading} size="sm">
          <Text>salesManagment.kitchenSales.configuration.submitTolerance</Text>
        </Button>
      </Wrapper.Footer>
    </Form>
  );
}
