import { NotificationManager } from "common/notifications";
import {
    Breadcrumb,
    Button,
    Footer,
    Form,
    Image,
    ImageUploader,
    InputGroup,
    Skeleton,
    Text,
    Toggler,
    Wrapper
} from "components";
import { useDataState, usePermission } from "hooks";
import {
    Fragment,
    useEffect,
    useState
} from "react";

import { appPermissions, rules } from "constant";
import { useToggleState } from "hooks";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { Media } from "types";

export default function Details() {
  const { brandId } = useParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [isOpen, toggle] = useToggleState(false);

  //permissions
  const {
    PS_UpdateBrand,
  } = appPermissions;
  const UPDATE_PERMISSIONS = usePermission(PS_UpdateBrand);

  const getData = () => {
    setLoading(true);
    const url = URLS.GET_BRAND_DETAILS_URL(brandId);
    ServiceApi.get(url)
      .then(({ data }) => {
        setBaseData(data);
      }).finally(() => {
        setLoading(false);
      });

  };

  const submit = () => {
    setSubmitLoading(true);
    const url = URLS.UPDATE_BRAND_DETAILS_URL(brandId);
    const body = { ...data };
    ServiceApi.put(url, body)
      .then(() => {
        setBaseData(data);
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
      }).finally(() => {
        setSubmitLoading(false);
      });
  };
  useEffect(getData, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onUploadImage = (data: Media) => {
    // setData((p) => ({ ...p, avatarUrl: data.url }));
    const url = URLS.UPLOAD_BRAND_IMAGES(brandId);
    const body = {
      url: data.url,
      type: data.type,
      thumbnailUrl: data.thumbnailUrl,
    };
    ServiceApi.patch(url, body).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
    }).finally(() => {
      getData();
    })
  };

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };


  return (
    <Fragment>
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
            <Image
              onClick={() => toggle()}
              logo
              editor
              src={data.logoUrl}
              alt={data.name}
              className="w-fit aspect-image bg-gray-100 p-2   lg:aspect-auto cursor-pointer lg:w-80 lg:h-40 "
            />
            <div className="lg:flex-1 space-y-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <h6 className="text-heading-6 text-gray-800">
                    {data.name}
                  </h6>
                  <p className="text-gray-500 text-body-2 font-medium">{data.code}</p>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) :
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.brand.name"
                  value={data.name}
                  setValue={handleSetData("name")}
                  rules={rules.required}
                />
                <InputGroup
                  label="productManagement.masterData.brand.code"
                  value={data.code}
                  setValue={handleSetData("code")}
                  rules={rules.required}
                />
                <Toggler
                  label={"productManagement.masterData.brand.showBrandLogoInProductList"}
                  value={data.showBrandLogoInProductList}
                  setValue={handleSetData("showBrandLogoInProductList")}
                />
              </div>
              <div className="[&_.input-group]:h-full [&_.input-box]:h-[calc(100%-17px-1rem)] [&_.form-control]:h-full">
                <InputGroup
                  as="textarea"
                  label="productManagement.masterData.brand.shortDescription"
                  value={data.description}
                  setValue={handleSetData("description")}

                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        }

        {UPDATE_PERMISSIONS && (
          <Footer show={!isChanged} >
            <Button as={Link} to={-1} variant="white">
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        )}
      </Form>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={data.logoUrl}
        onUpload={(data: Media) => onUploadImage(data)}
      />
    </Fragment>
  );
}
