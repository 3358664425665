import { NotificationManager } from "common/notifications";
import { Button, Icon, Modal, Text } from "components";
import QRCode from "qrcode.react";

type ShareType = {
  isOpen: boolean;
  toggle: () => void;
  id?: string;
};

export default function Share(props: ShareType) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_DIGITAL_SHOW_ROOM_WEB_URL}/#/scan?id=${props.id}`
    );
    NotificationManager.success(
      "global.toast.copy-msg",
      "global.toast.copy-title"
    );
  };

  const sendMail = () => {
    var link =
      "mailto:?" +
      "&subject=" +
      encodeURIComponent("product url") +
      "&body=" +
      `${process.env.REACT_APP_DIGITAL_SHOW_ROOM_WEB_URL}/#/scan?id=${props.id}`;
    window.location.href = link;
  };
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Modal.Header className="!text-left text-heading-3 text-gray-800 font-bold">
        <Text>productManagement.products.Details.shareProduct</Text>
      </Modal.Header>
      <Modal.Body className="overflow-auto min-h-[300px] space-y-4 flex-center flex-col">
        <QRCode
          data-testid="QRcode"
          imageRendering={"true"}
          width="200"
          height="200"
          value={`${process.env.REACT_APP_DIGITAL_SHOW_ROOM_WEB_URL}/#/scan?id=${props.id}`}
          renderAs="svg"
        />
        <div className="space-y-3">
          <span className="text-heading-6 text-gray-800 font-normal">
            <Text>productManagement.products.Details.url</Text>
          </span>
          <div className="p-4 leading-7 bg-gray-100 rounded-md">
            {`${process.env.REACT_APP_DIGITAL_SHOW_ROOM_WEB_URL}/#/scan?id=${props.id}`}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button size="sm" onClick={props.toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.close</Text>
        </Button>
        <div className="space-x-2">
          <Button type="button" className="" size="sm" light onClick={sendMail}>
            <Icon icon="paper-plane" className="mr-2"></Icon>
            <Text>productManagement.products.Details.sendEmail</Text>
          </Button>
          <Button
            type="button"
            className=""
            size="sm"
            light
            onClick={copyToClipboard}
          >
            <Icon icon="copy" className="mr-2"></Icon>
            <Text>productManagement.products.Details.copyUrl</Text>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
