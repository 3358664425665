import { Fragment, useContext, useState } from "react";
import { componentItemType } from "..";
import { Button, Icon, Table, DeleteModal } from "components";
import CarouselForm from "./CarouselForm";
import { useToggleState } from "hooks";
import { ServiceApi } from "services";
import { PositionContext } from ".";
import { cloneDeep } from "lodash";
import { toast } from "react-hot-toast";
import { NotificationManager } from "common/notifications";

type carouselItemProps = {
  item: componentItemType;
  componentId: string;
};
export default function CarouselItem({ item, componentId }: carouselItemProps) {
  const { positionData, setPositionData } = useContext(PositionContext);
  const [isEditOpen, toggleEdit] = useToggleState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [selected, setSelected] = useState<any>();
  
  const removeItem = () => {
    setLoading(true);
    const url = `/productservice/api/positions/${positionData.id}/component/${componentId}/remove-item/${item.id}`;
    ServiceApi.delete(url)
      .then(() => {
        setPositionData((p) => {
          const data = cloneDeep(p);
          const component = data.components.find((e) => e.id === componentId);
          const index = component?.items?.findIndex((e) => e.id === item.id);
          index !== undefined && component?.items?.splice(index, 1);
          return data;
        });
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        toggleDeleteModal();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  return (
    <Fragment>
      <tr className="shadow-[0px_4px_20px_0px_#38476d17]">
        <td>
          <button
            type="button"
            className="flex items-center text-start gap-6"
            onClick={toggleEdit}
          >
            <Table.Image src={item.url} alt={item.title} />
            <div className="space-y-1 flex-1">
              <h5 className="block truncate text-gray-700">{item.subTitle}</h5>
              <h6 className="block truncate text-gray-800">{item.title}</h6>
            </div>
          </button>
        </td>
        <td>
          <Button
            variant="danger"
            light
            size="sm"
            loading={loading}
            onClick={()=>{
              setSelected(item);
              toggleDeleteModal();
            }}
          >
            <Icon icon="trash" />
          </Button>
        </td>
      </tr>
      <CarouselForm
        isOpen={isEditOpen}
        toggle={toggleEdit}
        componentId={componentId}
        initData={item}
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: selected?.subTitle, id: selected?.id }}
        loading={loading}
        onConfirm={removeItem}
      />
    </Fragment>
  );
}
