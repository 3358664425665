import { createSlice } from "@reduxjs/toolkit";

const productFamilies = createSlice({
    name: "productFamilies",
    initialState: {} as any,
    reducers: {
        setProductFamilies: (state, action) => {
            return action.payload;
        },
    },
});

export const { setProductFamilies } = productFamilies.actions;
export default productFamilies.reducer;
