import { Button, Icon, Table, DeleteModal } from "components";
import { productType } from "../../../../type";
import { Fragment, useContext, useMemo, useState } from "react";
import { InfoPageContext } from "../../..";
import { LayoutContext } from "..";
import { ServiceApi } from "services";
import { cloneDeep } from "lodash";
import { toast } from "react-hot-toast";
import { useConverter, useToggleState } from "hooks";
import ProductData from "../ProductForm/ProductData";
import { NotificationManager } from "common/notifications";

export default function Product(props: productType) {
  const { convertAmount } = useConverter();
  const { infoPageData, setInfoPageData } = useContext(InfoPageContext);
  const { layoutData } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);
  const [isEditProductOpen, toggleEditProduct] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [selected, setSelected] = useState<any>();
  const hasDiscount = !!+props.discountPercent;
  const finalPrice = useMemo(() => {
    if (!hasDiscount) return props.price.amount;
    return +props.discountAmount;
  }, [hasDiscount, props]);

  const removeProduct = () => {
    setLoading(true);
    const url = `/productservice/api/infopages/${infoPageData.id}/layout/${layoutData.id}`;
    const body = { ...layoutData };
    const index = body.products.findIndex(
      (e) => e.productId === props.productId
    );
    body.products.splice(index, 1);
    ServiceApi.put(url, body)
      .then(() => {
        setInfoPageData((p) => {
          const data = cloneDeep(p);
          const index = data.infoPageLayouts.findIndex(
            (e) => e.id === layoutData.id
          );
          data.infoPageLayouts[index] = body;
          return data;
        });
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        toggleDeleteModal();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <tr>
        <td className="cursor-pointer" onClick={toggleEditProduct}>
          <div className="flex items-center gap-4">
            <Table.Image src={props.url} alt={props.articleNumber} />
            <div className="space-y-4 flex-1">
              <h6 className="w-full text-gray-800 truncate max-w-[10rem]">
                {props.tag}
              </h6>
              <p className="text-body-1 text-gray-600 space-x-2">
                {hasDiscount && (
                  <Fragment>
                    <span className="inline-block text-gray-500 line-through">
                      {convertAmount(props.price.amount)}
                    </span>
                    <span className="inline-block text-danger">
                      {props.discountPercent}%
                    </span>
                  </Fragment>
                )}
                <span className="inline-block text-gray-700">
                  {convertAmount(finalPrice)}
                </span>
              </p>
            </div>
          </div>
        </td>
        <td>
          <Button
            type="button"
            size="sm"
            light
            variant="danger"
            onClick={()=>{
              setSelected(props);
              toggleDeleteModal();
            }}
            loading={loading}
          >
            <Icon icon="trash" />
          </Button>
        </td>
      </tr>
      <ProductData
        isOpen={isEditProductOpen}
        toggle={toggleEditProduct}
        channelProduct={null}
        initData={{
          productId: props.productId,
          tag: props.tag,
          price: `${props.price.amount}`,
          discountPercent: props.discountPercent,
          url: props.url,
          articleNumber: props.articleNumber,
        }}
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: selected?.tag, id: selected?.id }}
        loading={loading}
        onConfirm={removeProduct}
      />
    </Fragment>
  );
}
