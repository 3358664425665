import { useId, useState } from "react";
import Text from "./Text";
import Dropdown from "./Dropdown";
import Icon from "./Icon";
import ReactQuill from "react-quill";
import "./TextEditor.scss";
import "quill/dist/quill.snow.css"; // Add css for snow theme
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme
type Props = {
  value?: any;
  setValue: any;
  flag?: any;
  label?: any;
  hint?: any;
  actions?: any;
  withAi?: boolean;
  onClickAI?: any;
};

const TextEditor = ({
  value = "",
  setValue,
  flag,
  label,
  hint,
  actions,
  withAi = false,
  onClickAI,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState("");
  var toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"], // toggled buttons

    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ["clean"],
  ];

  const inputId = useId();

  return (
    <div>
      {label || flag ? (
        <div className="flex items-center justify-between mb-4">
          {label && (
            <div>
              <label
                htmlFor={inputId}
                className="block h6 text-gray-800 w-full font-[400] truncate group-[.input-group.required]:after:content-['*'] after:text-danger"
              >
                <Text>{label}</Text>
              </label>
            </div>
          )}
          {(flag || hint) && (
            <div className="flex items-center gap-4">
              {hint && (
                <span className={`text-body-2 font-normal text-${hint?.color}`}>
                  {hint?.label}
                </span>
              )}
              <img className="block" src={flag} alt="" />
              {actions?.length > 0 && (
                <Dropdown onSelect={setSelectedItem}>
                  <Dropdown.Toggle
                    as="button"
                    icon={true}
                    type="button"
                    className="text-gray-600 font-medium text-body-base uppercase"
                  >
                    <Icon icon="ellipsis-vertical"></Icon>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="min-w-fit mt-2 p-2">
                    <ul className="text-gray-700 w-max">
                      {actions?.map((e: any) => (
                        <li
                          key={e.id}
                          onClick={(value) => {
                            setSelectedItem(e.id);
                            e.onClick(value);
                          }}
                          className={`py-2 font-medium first:mb-1 px-4 h6 ${
                            e.id === selectedItem &&
                            "bg-primary-light text-primary "
                          } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                        >
                          {e.title}
                        </li>
                      ))}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="relative">
        <ReactQuill
          theme="snow"
          className="bg-gray-100 rounded-md"
          value={value}
          onChange={(e, delta, source, editor) => {
            if (source === "user") {
              setValue(e);
            }
          }}
          placeholder="Write and Edit..."
          data-lang-map={label}
        />
        {withAi && (
          <span
            onClick={onClickAI}
            className="absolute flex-center opacity-80 w-6 h-6 right-4 bottom-4 text-white bg-purple-600 text-xs rounded-full cursor-pointer"
          >
            Ai
          </span>
        )}
      </div>
    </div>
  );
};

export default TextEditor;
