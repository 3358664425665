import { NotificationManager } from "common/notifications";
import { Button, Form, Icon, InputGroup, Modal, SelectLang, Text } from "components";
import { flagCountry, rules } from "constant";
import { useDataState, useSelector } from "hooks";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";


type createModalType = {
  isOpen: boolean;
  measurementFamilyId?: string;
  selected?: any;
  toggle: () => void;
  getData: () => void;
};

export default function UpdateModal(props: createModalType) {
  const [data, setData, setDataBase] = useDataState<any>({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [langSelected, setLangSelected] = useState("de");
  const [name, setName] = useState("");
  const profile = useSelector((s) => s.profile);
  const formRef = useRef<any>();

  useEffect(() => {
    if (props.isOpen) {
      setDataBase({});
      setData({})
      setName("")
      let arr: any = [];
      profile?.companyContentLanguages?.forEach((item: any) => {
        arr.push({ id: item, name: item })
      })
      let newData = props.selected;
      if (newData?.measurementFamilyUnitTranslation?.length !== arr.length) {
        var propsNew = ['id'];
        var result = arr?.filter(function (i: any) {
          return !newData?.measurementFamilyUnitTranslation?.some(function (a: any) {
            return i.id === a.language;
          });
        }).map(function (o: any) {
          return propsNew?.reduce(function (newo: any, language: any) {
            newo["id"] = o[language];
            return newo;
          }, {});
        });


        result?.forEach((item: any) => {
          newData?.measurementFamilyUnitTranslation.push({
            language: item.id,
            name: "",
          })
        })

      }
      newData?.measurementFamilyUnitTranslation?.forEach((item: any) => {
        if (item.language === profile.companyDefaultLanguage) {
          setName(item.name)
        }
      })

      setDataBase({
        measurementFamilyId: props.measurementFamilyId,
        symbol: newData.symbol,
        code: newData.code,
        unitId: newData.unitId,
        measurementFamilyUnitTranslation: newData.measurementFamilyUnitTranslation
      })
      setLangItems(arr)
      setLangSelected(profile.companyDefaultLanguage)
    }
  }, [props.isOpen])// eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitForm = (formData: any) => {
    setLoadingButton(true);
    let translatesNew: any = [];
    formData?.measurementFamilyUnitTranslation?.forEach((item: any, index: string) => {
      if (item.name !== "") {
        translatesNew[index] = item
      }
    })
    formData.translations = translatesNew;
    const url = URLS.UPDATE_MEASUREMENT_FAMILY_UNIT_URL(props.measurementFamilyId, formData.unitId);
    ServiceApi.patch(url, formData).then((res) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      props.getData();
    }).finally(() => {
      props.toggle();
      setLoadingButton(false);

    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    data?.measurementFamilyUnitTranslation?.forEach((item: any) => {
      if (item.language === lang) {
        setName(item.name)
      }
    })
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Form ref={formRef} onSubmit={onSubmitForm}>
        <Modal.Header className="flex items-center justify-between">
          <div>
            <h2 className="text-heading-2 text-gray-800">
              <Text>productManagement.masterData.measurementUnits.editUnit</Text>
            </h2>
          </div>
          <div>
            <SelectLang value={langSelected} items={langItems} onChangeLanguage={onChangeLanguage} />
          </div>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            <InputGroup
              label="productManagement.masterData.measurementUnits.code"
              value={data?.code}
              className="border-white"
              disabled
            // setValue={(code) => setData((p: any) => ({ ...p, code }))}
            />
            <InputGroup
              label="productManagement.masterData.measurementUnits.name"
              value={name}
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                data?.measurementFamilyUnitTranslation?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.name = value
                  }
                })
                setData((p: any) => ({ ...p, measurementFamilyUnitTranslation: data?.measurementFamilyUnitTranslation }));
                setName(value)
              }}
              rules={rules.required}
            />
            <InputGroup
              label="productManagement.masterData.measurementUnits.symbol"
              value={data?.symbol}
              className="border-white"
              setValue={(symbol) => setData((p: any) => ({ ...p, symbol }))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={() => props.toggle()} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingButton}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
