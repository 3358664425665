import { Button, Drawer, Form, Icon, InputGroup, Text } from "components";
import { rules, types } from "constant";
import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { ServiceApi } from "services";
import { toggleProps } from "types";
import { InfoPageContext } from "..";
import { layoutType } from "../../type";

export default function AddLayoutForm({ isOpen, toggle }: toggleProps) {
  const { infoPageData, setInfoPageData } = useContext(InfoPageContext);
  const [loading, setLoading] = useState(false);
  const initData = {
    title: "",
    subTitle: "",
    infoPageLayoutType: types.infoPageLayout[0].id,
    isVisible: true,
    products: [],
    order: 0,
    link: null,
    linkType: 0,
    linkTitle: null,
    url: null,
    id: "",
    style: null,
  };
  const [data, setData] = useState<layoutType>(initData);
  const submit = () => {
    setLoading(true);
    const url = `/productservice/api/infopages/${infoPageData.id}/layout`;
    const body = { ...data };
    ServiceApi.post(url, body)
      .then(({ data: id }) => {
        toast.success("applications.infoPage.addLayoutToast");
        setInfoPageData((p) => {
          const data = cloneDeep(p);
          data.infoPageLayouts ??= [];
          data.infoPageLayouts.push({ ...body, id });
          return data;
        });
        toggle();
        setData(initData);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={submit}>
        <Drawer.Menu>
          <Drawer.Header>
            <h2 className="text-start text-gray-800">
              <Text>applications.infoPage.addLayoutHeader</Text>
            </h2>
          </Drawer.Header>
          <Drawer.Body className="space-y-8">
            <InputGroup
              label="applications.infoPage.layoutTitle"
              value={data.title}
              setValue={(title) => setData((p) => ({ ...p, title }))}
              rules={rules.required}
            />
            <fieldset>
              <legend className="h5">
                <Text>applications.infoPage.selectLayouts</Text>
              </legend>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-24 gap-y-8 mt-6">
                {types.infoPageLayout.map((e) => (
                  <button
                    key={e.id}
                    type="button"
                    data-active={e.id === data.infoPageLayoutType}
                    className="group w-full space-y-2"
                    onClick={() =>
                      setData((p) => ({ ...p, infoPageLayoutType: e.id }))
                    }
                  >
                    <img
                      src={e.activeIcon}
                      alt={e.name}
                      className="w-full filter grayscale opacity-50 group-data-active:grayscale-0 group-data-active:opacity-100 transition-[filter,opacity]"
                    />
                    <h5 className="w-full text-start">
                      <span className="inline-flex flex-center align-middle w-4 h-4 rounded-full bg-gray-200 group-data-active:bg-primary me-2 transition-colors">
                        <span className="w-2 h-2 rounded-full bg-gray-200" />
                      </span>
                      <Text>{e.name}</Text>
                    </h5>
                  </button>
                ))}
              </div>
            </fieldset>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button type="button" variant="light" onClick={toggle}>
              <Text>applications.infoPage.closeLayoutMenu</Text>
            </Button>
            <Button type="submit" variant="primary" loading={loading}>
              <Icon icon="plus" />{" "}
              <Text>applications.infoPage.submitAddLayout</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
