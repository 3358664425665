export const errors = {

  // 1001: "CompanyNotFound DE",
  // 1002: "MobileNumberIsAlreadyRegisterd DE",
  // 1003: "DeviceTokenIsNotValid DE",
  // 1004: "EmailAddressIsAlreadyRegistered DE",
  // 1005: "EmailConfirmationCodeIsExpired DE",
  // 1006: "EmailConfirmationCodeIsNotValid DE",
  // 1007: "MobileNumberIsNotConfirmed DE",
  // 1008: "MobileNumberVerificationCodeIsNotValid DE",
  // 1009: "PassCodeMustBeNullOrEmpty DE",
  // 1010: "PassCodeMustExists DE",
  // 1011: "RemoveCustomerIsNotPossible DE",
  // 1012: "CustomerMustAcceptGDPR DE",
  // 1013: "CustomerMustBePerson DE",
  // 1014: "NewPassCodeIsNotMatch DE",
  // 1015: "MobileNumberMustRegisterd DE",
  // 1201: "InvalidPassCode DE",
  // 1202: "",
  // 1203: "",
  // 1301: "CustomerNotFound DE",
  // 1302: "",
  // 1303: "",

  error: "Fehler",
  1000: "Platzcode muss eindeutig sein",
  1001: "Ungültiger Kanal",
  1003: "Markencode muss eindeutig sein in der Firma",
  1004: "Platzcode muss eindeutig sein im Bereich",
  1006: "Attributgruppenvorlage nicht gefunden",
  1007: "Marke konnte nicht gefunden werden",
  1008: "Marke existiert bereits",
  1009: "Checklistenelement konnte nicht gefunden werden",
  1010: "Checklistenelementbezeichnung existiert bereits",
  1011: "Rechtssubjekt konnte nicht gefunden werden",
  1012: "Rechtssubjektkennung muss eindeutig sein in der Firma",
  1013: "Messfamilienkennung muss eindeutig sein",
  1014: "Kennung der Messfamilieneinheit muss eindeutig sein",
  1015: "Messfamilie konnte nicht gefunden werden",
  1016: "PIM-Produktfamilie konnte nicht gefunden werden",
  1017: "Platz konnte nicht gefunden werden",
  1018: "PIM-Produktmedium konnte nicht gefunden werden",
  1020: "Attributwerte dürfen keine doppelte Sprache in Attributwerten haben",
  1021: "Nur Kanal von nicht kanalspezifischen Attributwerten kann Null sein",
  1022: "Kanalspezifische Attribute sollten integrierte Kanäle verwenden",
  1023: "Nur Sprache von nicht sprachspezifischen Attributwerten kann Null sein",
  1024: "PIM-Produktmediumfelder dürfen keine doppelte Sprache im gleichen Kanal haben",
  1025: "Medienfelder sollten entweder alle oder keine Kanalwert haben",
  1026: "Medienfelder sollten entweder alle oder keine Sprachwert haben",
  1027: "Erforderliche Elemente sollten einen Wert haben",
  1028: "Checklistenelement löschen ist möglich, wenn es nicht in der Vorbereitungsphase verwendet wird",
  1029: "Checklistenelement löschen ist möglich, wenn es nicht in der Verkaufsphase verwendet wird",
  1030: "Produktfamilienkennung muss eindeutig sein in der Firma",
  1031: "Produktfamilie existiert bereits",
  1032: "Zonenkennung muss eindeutig sein im Geschäft",
  1033: "Geschäftskennung muss eindeutig sein in der Firma",
  1034: "Geschäft nicht gefunden",
  1035: "Produktfamilie nicht gefunden",
  1036: "Kanal-Einstellung nicht gefunden",
  1037: "Kanal ist in dieser Kategorie nicht verfügbar",
  1039: "Zugriff verweigert aufgrund fehlendem Abonnement-Schlüssel. Stellen Sie sicher, dass Sie beim Senden von Anfragen an eine API den Abonnement-Schlüssel angeben",
  2000: "Kanäle sollten in den durchsuchbaren Attributkanälen eindeutig sein",
  2001: "Kanäle sollten in den integrierten Kanälen sein",
  2003: "Attributsuchbare Kanäle müssen vorhanden sein",
  2004: "Attribut-Veröffentlichungsausnahmekanäle müssen vorhanden sein",
  2005: "Attribut-Element sollte nicht dupliziert werden",
  2006: "Attribut muss mindestens eine Übersetzung enthalten",
  2007: "Attributkennung muss eindeutig sein",
  2008: "Attribut-Element Standardwert muss in der Elementlisten-ID enthalten sein",
  2009: "Element muss in Attribut vorhanden sein",
  2010: "Attribut sollte nicht in der Gruppe verwendet werden",
  2011: "Listentyp muss in der Elementübersetzung dieselbe Übersetzung haben",
  2012: "Attributgruppenkennung muss eindeutig sein",
  2013: "Attributgruppe wird in PIM-Produktfamilie verwendet",
  2014: "Attribut darf nicht in der Gruppe wiederholt werden",
  2015: "Attribut muss in der Gruppe vorhanden sein",
  3000: "Produktkategorie kann nicht gelöscht werden, wenn sie in PIM-Produkt verwendet wird",
  3001: "Produktkategorie kann nur an verfügbare Kanäle veröffentlicht werden",
  3002: "Produktkategorie kann nur an integrierte Kanäle veröffentlicht werden",
  3003: "Produktkategorie-Kennung muss eindeutig sein",
  3004: "Produktkategorie kann nicht ihr eigener übergeordneter Kategorie sein",
  3005: "Übergeordneter Eintrag der Stammproduktkategorie kann nicht geändert werden",
  3006: "Die Produktkategorie und ihr übergeordneter Eintrag müssen sich in derselben Kategorie befinden",
  3007: "Die Produktkategorie darf keine Unterkategorien haben",
  3008: "Produktkategorie nicht gefunden",
  4000: "Lieferant nicht gefunden",
  4001: "Lieferanten-ILN muss eindeutig sein",
  4002: "Lieferant existiert bereits",
  4003: "Lieferant kann nicht gelöscht werden, wenn er in Selloff-Produkt verwendet wird",
  4004: "Lieferant kann nur an integrierte Kanäle veröffentlicht werden",
  4005: "Lieferantenkennung muss eindeutig sein in der Firma",
  4010: "Rabatt nicht gefunden",
  4011: "Ungültiger Rabattbetrag",
  4012: "Contact Person Not Found DE",
  5000: "Akzeptierter Anzahlungsbereich",
  5001: "Adresse darf nicht leer sein",
  5003: "Wichtige Dokumente müssen unterschrieben sein",
  5004: "Angebot sollte Artikel mit Anzahlung haben",
  6000: "Attribut nicht gefunden",
  6001: "Konfigurierbares Programm hat keine Attribute",
  6002: "Programm nicht gefunden",
  6003: "Programmattributkennung muss eindeutig sein",
  6004: "InformationsSeite nicht gefunden",
  6005: "Media-ID muss für das Entfernen von Medien aus der Lahoma-Bestellung vorhanden sein",
  6006: "Das Entfernen von Bildern oder Dokumenten ist für eine laufende Lahoma-Bestellung nicht möglich",
  6007: "Dokument muss vorhanden sein, um es aus Lahoma-Bestellungen zu entfernen",
  6008: "Mediarichtung muss in perspektivischen Medien eindeutig sein",
  6009: "Aufgegebene Lahoma-Bestellung sollte innerhalb des Unternehmens eindeutig sein",
  6010: "Das Entfernen von Bildern oder Dokumenten ist für eine eingereichte Lahoma-Bestellung nicht möglich",
  6011: "Positionscode muss eindeutig sein",
  6012: "Banner-Positionsbauteile dürfen nur ein Element haben",
  6013: "Komponente existiert nicht",
  6014: "Layout der Informationsseite nicht gefunden",
  6015: "Informationsseitenkennung muss eindeutig sein",
  6016: "Channel-Einstellung sollte eine aktive Einstellung haben",
  6017: "Wert ist keine Artikel-ID",
  6018: "Lahoma-Bestellung nicht gefunden",
  6019: "Position nicht gefunden",
  7000: "PIM-Produkt nicht gefunden",
  7001: "Attributgruppe sollte nicht in PIM-Produktfamilienbaum verwendet werden",
  7002: "Attributgruppe muss im PIM-Produktfamilienbaum vorhanden sein",
  7003: "Blatt kann im PIM-Produktfamilienbaum entfernt werden",
  7004: "PIM-Produktfamilienkennung muss eindeutig sein",
  7005: "PIM-Produktfamilie kann nicht entfernt werden, wenn sie in PIM-Produkten verwendet wird",
  7006: "PIM-Produktstandort darf nicht wiederholt werden",
  7007: "PIM-Produktmedien können an integrierte Kanäle veröffentlicht werden",
  7008: "Artikelnummer muss eindeutig sein",
  7009: "Attributwerte dürfen weder null noch leer sein",
  7010: "PIM-Produktattribute dürfen nicht null sein",
  7011: "PIM-Produkt kann nur an integrierte Kanäle veröffentlicht werden",
  7012: "Artikelnummer liegt außerhalb des Bereichs",
  7013: "Eltern der PIM-Produktfamilie nicht gefunden",

  8000: "Position nicht gefunden",
  8001: "Doppelte Angebotsversion ist nicht zulässig",
  8002: "Angebotskundenadresse sollte in anderem Typ sein",
  8003: "Hinzufügen von Unterpunkt ist nicht möglich, wenn Position nicht gefunden",
  8004: "Nur aktives Angebot kann dupliziert werden",
  8005: "Nur letzte Version des Angebots kann dupliziert werden",
  8006: "Nur akzeptiertes Angebot kann reaktiviert werden",
  8007: "Nur abgelehntes Angebot kann reaktiviert werden",
  8008: "Ablehnen des Angebots ist nur für aktives Angebot zulässig",
  8009: "Entfernen des Dokuments ist nicht möglich, wenn das Dokument nicht gefunden wurde",
  8010: "Nicht vorhandene Position kann nicht entfernt werden",
  8011: "Entfernen des Unterpunkts ist nicht möglich, wenn die Position nicht gefunden wurde",
  8012: "Nicht vorhandener Unterpunkt kann nicht entfernt werden",
  8013: "Nicht vorhandenes Dokument kann nicht entfernt oder aktualisiert werden",
  8014: "Unterzeichnetes Dokument kann nicht entfernt oder aktualisiert werden",
  8015: "Aktualisieren der Position ist nicht möglich, wenn die Position nicht gefunden wurde",
  8016: "Nur aktives Angebot kann aktualisiert werden",
  8017: "Letzte Version des Angebots kann aktualisiert werden",
  8018: "Aktualisieren des Unterpunkts ist nicht möglich, wenn die Position nicht gefunden wurde",
  8019: "Aktualisieren des Unterpunkts ist nicht möglich, wenn der Unterpunkt nicht gefunden wurde",
  8020: "Nur aktive Bestellung kann storniert werden",
  8021: "Bestellung kann nur für integrierte Kanäle veröffentlicht werden",
  8022: "Veröffentlichung an ERP ist möglich, wenn Bestelldokumente mit Signatur erforderlich unterschrieben sind",
  8023: "Unterzeichnetes Dokument kann nicht entfernt oder aktualisiert werden",
  8024: "Aktive Bestellung kann aktualisiert werden",
  8025: "Angebot nicht gefunden",
  8026: "Dokument nicht gefunden",
  8027: "Bestellung nicht gefunden",

  9000: "Vorbereitungsphase nicht gefunden",
  9001: "Abverkaufsprodukt nicht gefunden",
  9002: "Verkaufsphasenvorlage nicht gefunden",
  9003: "Vorbereitungsphase nicht gefunden",
  9004: "Abverkaufsprodukt-Zeitplan nicht gefunden",
  9005: "Abverkaufsprodukt-Zeitplan existiert bereits",
  9006: "Checklistenpunkte nicht gefunden",
  9007: "Reihenfolge der Abverkaufsprodukt-Assets sollte eindeutig sein",
  9008: "Phasenwechsel ist nach der aktuellen Phasendauer möglich",
  9009: "Phasenwechsel ist nur für aktive Abverkaufsprodukte möglich",
  9010: "Phasenwechsel ist nur möglich, wenn alle Checklistenpunkte der Verkaufsphase überprüft wurden",
  9011: "Abschlussphase ist nur nach der letzten Phasendauer möglich",
  9012: "Abschlussphase ist nur für aktive Abverkaufsprodukte möglich",
  9013: "Letzte Phase kann nicht geändert werden",
  9014: "Media-ID muss vorhanden sein, um ein Asset aus dem Abverkaufsproduktzu entfernen",
  9015: "Die nächste Verkaufsphase sollte einen Checklistenpunkt enthalten",
  9016: "Nur die letzte Phase kann geschlossen werden",
  9017: "Die Vorbereitungsphase sollte einen Checklistenpunkt enthalten",
  9018: "Der tatsächliche Verkaufspreis sollte größer als Null sein",
  9019: "Das Entfernen eines Bildes ist für geschlossene Abverkaufsprodukte nicht möglich",
  9020: "Das Entfernen eines Bildes ist für verkaufte Abverkaufsprodukte nicht möglich",
  9021: "Das Umsortieren von Assets ist für geschlossene Abverkaufsprodukte nicht möglich",
  9022: "Das Umsortieren von Assets ist für verkaufte Abverkaufsprodukte nicht möglich",
  9023: "Die Startphase ist ohne Produktfamilie nicht möglich",
  9024: "Die Startphase ist ohne Verkaufsphasenvorlage nicht möglich",
  9025: "Verkauft ist nur nach dem Startdatum und der -uhrzeit des Abverkaufsprodukts möglich",
  9026: "Verkauft ist nur für aktive Abverkaufsprodukte möglich",
  9027: "Die Startphase ist nur für die Vorbereitung von Abverkaufsprodukten möglich",
  9028: "Die Startphase ist nur möglich, wenn alle Checklistenpunkte der Vorbereitungsphase überprüft wurden",
  9029: "Aktualisierung ist für geschlossene Abverkaufsprodukte nicht möglich",
  9030: "Aktualisierung ist für verkaufte Abverkaufsprodukte nicht möglich",
  9031: "Die Aktualisierung eines Checklistenpunkts der Vorbereitungsphase ist nur für die Vorbereitung von Abverkaufsprodukten möglich",
  9032: "Die Aktualisierung eines Checklistenpunkts der Verkaufsphase ist nur nach der aktuellen Phasendauer möglich",
  9033: "Die Aktualisierung eines Checklistenpunkts der Verkaufsphase ist nur für aktive Abverkaufsprodukte möglich",
  9034: "Die Aktualisierung einer Verkaufsphasenvorlage ist für aktive Abverkaufsprodukte nicht möglich",
  9035: "Das Zurückziehen eines verkauften Artikels ist nur für verkaufte Abverkaufsprodukte möglich",
  9036: "Die Verkaufsphasenvorlagenkennung muss eindeutig sein",

  11000: "Anbieter-Produkt-Importverfolgung nicht gefunden",
  11001: "Anbieter-Produkt-Import nicht gefunden",

  7014: "Pim Product Attribute Is Required DE",
  7015: "Pim Product Attribute Is PerLanguage DE",
  7016: "Pim Product Attribute Is PerChannel DE",

  1019: "public const string Holiday Not Found DE",

  7018: "Pim Product Attribute Is Not In Pim Based On Pim Product Family DE"
};
