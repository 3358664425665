import { Fragment, useContext } from "react";
import cloneDeep from "lodash/cloneDeep";
import { InputGroup, Select, Text, Wrapper } from "components";
import SearchAddress from "components/SearchAddress";
import { countries } from "constant";
import { rules } from "constant";
import { AddStoreContext } from ".";
import { generateMapSrc } from "utils";

export default function Location() {
  const { data, setData } = useContext(AddStoreContext);
  const handleSetValue = (key) => {
    return (value) =>
      setData((p) => {
        const data = cloneDeep(p);
        data.address[key] = value;
        return data;
      });
  };
  const handleSetAddress = (address) => {
    setData((p) => ({ ...p, address }));
  };
  return (
    <Fragment>
      <h2 className="text-heading-2 text-gray-800">
        <Text>company.stores.location</Text>
      </h2>
      <SearchAddress setValue={handleSetAddress} />
      <Wrapper.Section className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 items-stretch">
        <InputGroup
          label="global.locations.street"
          value={data.address?.street}
          setValue={handleSetValue("street")}
          rules={rules.required}
        />
        <InputGroup
          label="global.locations.houseNumber"
          value={data.address?.houseNo}
          setValue={handleSetValue("houseNo")}
          rules={rules.required}
        />
        <InputGroup
          label="global.locations.postalCode"
          value={data.address?.postalCode}
          setValue={handleSetValue("postalCode")}
          rules={rules.required}
        />
        <InputGroup
          label="global.locations.city"
          value={data.address?.city}
          setValue={handleSetValue("city")}
          rules={rules.required}
        />
        <div className="col-span-full">
          <Select
            label="global.locations.country"
            value={data.address?.country}
            setValue={handleSetValue("country")}
            items={countries}
            rules={rules.required}
          />
        </div>
        <div className="col-span-full">
          <Select
            label="global.locations.state"
            value={data.address?.state}
            setValue={handleSetValue("state")}
            items={
              countries.find((e) => e.id === data.address?.country)?.states
            }
            rules={rules.required}
          />
        </div>
      </Wrapper.Section>
      <div className="flex flex-col">
        <h6 className="mb-2">
          <Text>company.stores.locationOnMap</Text>
        </h6>
        <iframe
          title="Address"
          src={generateMapSrc(data.address)}
          className="bg-gray-300 block aspect-video w-full rounded shadow"
        />
      </div>
    </Fragment>
  );
}
