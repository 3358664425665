import { useState, useEffect } from "react";
import { Button, Modal, Form, InputGroup, Text } from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import { rules } from "constant";
import { NotificationManager } from "common/notifications";

type addModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: () => void;
};

export default function AddModal({ isOpen, toggle, getData }: addModalType) {
  const [loadingPage, setLoadingPage] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
    code: "",
    isActive: true,
  });

  useEffect(() => {
    if (isOpen) {
      setData({
        name: "",
        description: "",
        code: "",
        isActive: true,
      });
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoadingPage(true);
    const url = URLS.ADD_NEW_LEGALENTITY;
    ServiceApi.post(url, data).then(({ data }) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      toggle(false);
      getData();
      setLoadingPage(false);
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Form onSubmit={submit}>
        <Modal.Header className="flex flex-row">
          <h2 className="text-heading-2 text-gray-800 font-semibold">
            <Text>company.legalEntities.addLegalEntity</Text>
          </h2>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto p-8 grid grid-cols-1 lg:grid-cols-1 gap-8 lg:gap-8">
          <InputGroup
            label="company.legalEntities.leganName"
            value={data.name}
            className="border-white"
            setValue={(name) => setData((p) => ({ ...p, name }))}
          />
          <InputGroup
            label="company.legalEntities.code"
            value={data.code}
            setValue={(code) => setData((p) => ({ ...p, code }))}
            rules={rules.required}
          />
          <InputGroup
            as="short-textarea"
            label="company.legalEntities.description"
            value={data.description}
            setValue={(description) => setData((p) => ({ ...p, description }))}
          />
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button
            type="button"
            className="mr-2"
            onClick={toggle}
            variant="white"
          >
            <Text>global.buttons.discard</Text>
          </Button>
          <Button type="submit" loading={loadingPage}>
            <Text>global.buttons.create</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
