export const Details = {
  generalTab: "General",
  priceTab: "Price",
  attributesTab: "Attributes",
  mediaTab: "Media",
  categoriesTab: "Categories",
  locationsTab: "Locations",
  channelsTab: "Channels",
  shareButton: "Share",
  articleNumber: "Article Number",
  supplierArticleNumber: "Supplier Article Number",
  importVersion: "import version",
  currentProductVersion: "Current Product Version",
  productFamily: "Product Family",
  category: "Category",
  creation: "creation",
  modify: "modify",
  active: "Active",
  notActive: "Not Active",
  supplierPrice: "Supplier Price",
  salesPrice: "Sales Price",
  addPriceDate: "Add Price/Date",
  addMedia: "Add Media",
  assignPlace: "Assign Place",
  availableTill: "Available Till",
  supplier: "Supplier",
  suppliers: "Suppliers",
  program: "Program",
  brand: "Brand",
  selectDate: "Select Date ...",
  select: "Select ...",
  previewCurrentVersion: "Preview Current Version",
  publishedVersion: "Published Version",
  unPublish: "unPublish",
  rePublish: "rePublish",
  publishCurrentVersion: "Publish Current Version",
  assignCategory: "Assign Category",
  missingRequiredAttribute: "Missing Required Attribute",
  channels: "Channels",
  language: "Language",
  saveAttributes: "Save Attributes",
  productCategory: "Product Category",
  selectCategory: "Select Category ...",
  fileInfo: "File Info",
  imageSetting: "Image Setting",
  deleteImage: "Delete",
  imagePublicationInChannels: "Image Publication in Channels",
  imageInfo: "Image Info",
  placeHolder: "PlaceHolder",
  description: "Description",
  productPreviewFor: "Product Preview For",
  basicInfo: "Basic Info",
  price: "Price",
  shareProduct: "Share Product",
  sendEmail: "Send Email",
  copyUrl: "Copy URL",
  url: "URL",
  startDate: "Start Date",
  endDate: "End Date",
  noData: "There is no data available",
  compareValues: "Compare Values",
  setValueForAllChannel: "Set Value For All Channels",
  noAttributeFound: "No Attribute Found",
  allInGroupFilter: "All",
  showInvisibleAttributes: "Show Invisible Attributes",
  searchCategory: "Serach Category",
  thereIsNoChannelEnabledForYourCompany:
    "There is no channel enabled for your company",
  aiTextGenerator: "AI Text Generator ",
  aiDescription: "Generate an AI Generated Text for the following product ",
  basicInfo: "Basic Info(optional) ",
  wordCount: "Word Count ",
  generate: "Generate ",
  copy: "copy ",
  setValue: "Set Value ",
};
