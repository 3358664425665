

export const AttributesTypes = (type: number) => {
  switch (type) {
    case 20:
      return "Yes/No"
    case 30:
      return "Select"
    case 40:
      return "Date"
    case 50:
      return "Time"
    case 60:
      return "Date & Time"
    case 70:
      return "Measurement"
    case 80:
      return "Text Editor"
    default:
      return "Text"
  }
};


