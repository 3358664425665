import { Text, Wrapper } from "components";
import { Fragment, useContext } from "react";
import { AddSalesTemplatesContext } from "../index";
import { convertAddress } from "utils";

export default function Review() {
  const { data } = useContext<any>(AddSalesTemplatesContext);
  return (
    <Fragment>
      <h2 className="text-heading-2 text-gray-800">
        <Text>configurations.company.stores.review</Text>
      </h2>
      <Wrapper.Section className="grid grid-cols-5 gap-4 items-start text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="text-gray-600 col-span-full">
          <Text>salesManagment.sellOffSales.salesTemplates.basicInfo</Text>
        </h3>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>salesManagment.sellOffSales.salesTemplates.templateName</Text>
          </span>
        </div>
        <div>  <h6 className="text-heading-6 text-gray-800" >{data?.title}</h6></div>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>salesManagment.sellOffSales.salesTemplates.code</Text>
          </span>
        </div>
        <div><h6 className="text-heading-6 text-gray-800" >{data?.code}</h6></div>
        <div>
          <span className="text-body-base text-gray-800">
            <Text>salesManagment.sellOffSales.salesTemplates.shortDescription</Text>
          </span>
        </div>
        <div className="text-heading-5 text-gray-700">{data?.description}</div>
      </Wrapper.Section>
      {data?.sellPhases?.map((item: any, index: any) => {
        return <Wrapper.Section className="grid grid-cols-5 gap-4 items-start text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
          <h3 className="text-gray-600 col-span-full">
            <Text>salesManagment.sellOffSales.salesTemplates.phase</Text>{index + 1}
          </h3>
          <div>
            <Text>salesManagment.sellOffSales.salesTemplates.phaseName</Text>
          </div>
          <div><h6 className="text-gray-800">{item?.title}</h6></div>
          <div>
            <Text>salesManagment.sellOffSales.salesTemplates.discount</Text>
          </div>
          {item?.discount ? <div><h6 className="text-gray-800">{item?.discount}%</h6></div> : ""}
          <div>
            <span className="text-body-base text-gray-800">
              <Text>salesManagment.sellOffSales.salesTemplates.duration</Text>
            </span>
          </div>
          {item?.duration ? <div className="text-heading-5 text-gray-700">{item?.duration}<span className="ml-1">days</span></div> : ""}
          <div>
            <span className="text-body-base text-gray-800">
              <Text>salesManagment.sellOffSales.salesTemplates.sellerCommission</Text>
            </span>
          </div>
          {item?.sellerCommission ? <div className="text-heading-5 text-gray-700">{item?.sellerCommission}%</div> : ""}
          <div>
            <span className="text-body-base text-gray-800">
              <Text>salesManagment.sellOffSales.salesTemplates.shortDescription</Text>
            </span>
          </div>
          <div className="text-heading-5 text-gray-700">{item?.description}</div>
          <div>
            <span className="text-body-base text-gray-800">
              <Text>salesManagment.sellOffSales.salesTemplates.checkListItems</Text>
            </span>
          </div>
          <div className="flex-1">
            {item?.checkListItems?.map((i: any) => {
              return <span className={`inline-block align-middle px-2 py-1 gap-8 font-medium rounded-md text-primary bg-primary-light text-body-2 ml-0 mr-1 m-0.5`}
              >{i.name}</span>
            })}
          </div>
        </Wrapper.Section>
      })}
    </Fragment>
  );
}
