export const measurementUnits = {
  addMeasurement: "Maßeinheit hinzufügen",
  general: "Allgemein",
  basicInfo: "Grundlegende Informationen",
  units: "Einheiten",
  name: "Name",
  code: "Code",
  symbol: "Symbol",
  addUnit: "Einheit hinzufügen",
  editUnit: "Einheit bearbeiten",
  unitsList: "Einheitenliste",
  standardUnitCode: "Standard-Einheitscode",
  standardUnitName: "Standard-Einheitsname",
  standardUnitSymbol: "Standard-Einheitssymbol"
};