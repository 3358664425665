export const customerList = {
    cimNumber: "CIM Number",
    firstName: "First Name",
    lastName: "Last Name",
    basicInfo: "Basic Info",
    address: "Address",
    gender: "Gender",
    birthdate: "Date Of Birth",
    mobileNumber: "Mobile Number",
    phoneNumber: "Phone Number",
    emailAddress: "Email Address",
    street: "Street",
    houseNumber: "House Number",
    postalCode: "Postal Code",
    city: "City",
    state: "State",
    country: "Country",
    companyName: "Company",
    company: "Company Name",
    taxNumber: "Tax Number",
    channels: "Channels",
    email: "Email",
    application: "Application",
    sms: "SMS",
    phone: "Phone",
    post: "Post",
    socialMedia: "Social Media",
    familyBonus: "Family Bonus",
    newsLetterSubscription: "News Letter Subscription",
}