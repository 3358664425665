import { NotificationManager } from "common/notifications";
import {
    Button,
    Drawer,
    Form,
    Icon,
    Image,
    ImageUploader,
    InputGroup,
    Text,
    Toggler
} from "components";
import { usePermission } from "hooks";
import {
    FormEvent,
    useEffect,
    useRef,
    useState
} from "react";

import { appPermissions, rules } from "constant";
import { useToggleState } from "hooks";
import { ServiceApi, URLS } from "services";
import { Media } from "types";


export default function AddBrand(props: any) {
  const [loading, setLoading] = useState(false);
  const [dataImg, setDataImg] = useState<any>({
    thumbnailUrl: "",
    type: "",
    url: ""
  });
  const [data, setData] = useState<any>({
    name: "",
    code: "",
    shortDescription: "",
    showBrandLogoInProductList: false,
  });
  const [isOpen, toggle] = useToggleState(false);
  const formRef = useRef<any>();


  useEffect(() => {
    if (props.isOpen) {
      setDataImg({
        thumbnailUrl: "",
        type: "",
        url: ""
      })
      setData({
        name: "",
        code: "",
        shortDescription: "",
        showBrandLogoInProductList: false,
      })
    }
  }, [props.isOpen])


  //permissions
  const {
    PS_CreateBrand,
  } = appPermissions;
  const CREATE_PERMISSIONS = usePermission(PS_CreateBrand);

  const onSubmitForm = (formData: any) => {
    setLoading(true);
    const url = URLS.ADD_BRAND_URL;
    ServiceApi.post(url, formData).then((res: any) => {
      if (formRef?.current?.dataImg?.url !== "") {
        const urlImg = URLS.UPLOAD_BRAND_IMAGES(res.data);
        ServiceApi.patch(urlImg, formRef.current.dataImg).then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title"
          );
          props.getData();
          props.toggle();
        }).catch(() => {
          setLoading(false);
        });
      } else {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        props.getData();
        props.toggle();
      }
    }).catch(() => {
      setLoading(false);
    });
  };

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };

  const onUploadImage = (data: Media) => {
    setDataImg({ thumbnailUrl: data.thumbnailUrl, type: data.type, url: data.url })
    formRef.current.dataImg = { thumbnailUrl: data.thumbnailUrl, type: data.type, url: data.url };
  };

  const submit = (e?: FormEvent) => {
    formRef.current.dataImg = dataImg;
    formRef.current.submitHandler(e, data);
 
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <h2 className="text-gray-800 text-heading-2 mt-2"><Text>productManagement.masterData.brand.addBrand</Text></h2>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                className="ml-2"
                onClick={() => props.toggle()}
              >
                <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <Form ref={formRef} onSubmit={onSubmitForm} className="space-y-6 pr-4" id={"create-drawer"} >
            <Image
              onClick={() => toggle()}
              src={dataImg?.thumbnailUrl}
              alt={data.name}
              logo
              className="flex-center rounded overflow-hidden cursor-pointer bg-gray-100 p-2"
            />
            <InputGroup
              label="productManagement.masterData.brand.name"
              value={data.name}
              setValue={handleSetData("name")}
              rules={rules.required}
            />
            <InputGroup
              label="productManagement.masterData.brand.code"
              value={data.code}
              setValue={handleSetData("code")}
              rules={rules.required}
            />
            <Toggler
              label={"productManagement.masterData.brand.showBrandLogoInProductList"}
              value={data.showBrandLogoInProductList}
              setValue={handleSetData("showBrandLogoInProductList")}
            />

            <InputGroup
              as="short-textarea"
              label="productManagement.masterData.brand.shortDescription"
              value={data.description}
              setValue={handleSetData("description")}

            />
          </Form>
        </Drawer.Body>
        {CREATE_PERMISSIONS && (<Drawer.Footer className="flex justify-between">
          <Button
            variant="white"
            type="button"
            onClick={() => props.toggle()}
          >
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={submit}
            loading={loading}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>)}
      </Drawer.Menu>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={dataImg?.thumbnailUrl}
        onUpload={(data: Media) => onUploadImage(data)}
      />
    </Drawer>
  );
}
