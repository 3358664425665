import { useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  Button,
  Text,
  Icon,
  Wrapper,
  Skeleton,
  Breadcrumb,
  Footer,
} from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import { useNavigate, Link } from "react-router-dom";
import { useDataState, usePermission } from "hooks";
import { useParams } from "react-router";
import { appPermissions } from "constant";
import { NotificationManager } from "common/notifications";

export default function Details() {
  const { legalEntityId } = useParams();
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);
  const navigate = useNavigate();

  //permissions
  const { AS_UpdateLegalEntity, AS_LegalEntityFullAccess } = appPermissions;
  const FULL_ACCESS = usePermission(AS_LegalEntityFullAccess);
  const UPDATE_PERMISSION = usePermission(AS_UpdateLegalEntity);

  useEffect(() => {
    const getData = async () => {
      await ServiceApi.get(URLS.LEGALENTITY_DETAILS(legalEntityId)).then(
        ({ data }) => {
          setBaseData(data);
          setLoadingPage(false);
        }
      );
    };
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.UPDATE_LEGALENTITY_DETAILS(legalEntityId);
    // data["updatedBy"]="Ehsan Amini"
    data["updatedDate"] = new Date();
    // data["infoPageLayouts"]=[];
    ServiceApi.put(url, data).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
      navigate(-1);
      setLoadingButton(false);
    });
  };

  const clipboard = () => {
    window.navigator.clipboard.writeText(data.code);
    NotificationManager.success(
      "global.toast.copy-msg",
      "global.toast.copy-title"
    );
  };

  return (
    <Form onSubmit={submit} className="relative space-y-6">
      <h3 className="mr-auto text-heading-3">
        <Breadcrumb />
      </h3>
      <Wrapper>
        <Wrapper.Header>
          <div className="flex">
            <div className="mr-auto">
              <h2 className="text-heading-2 text-gray-800 font-semibold">
                <Text>company.legalEntities.generalInfo</Text>
              </h2>
            </div>
          </div>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
          <div className="col-start-1 col-end-2">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="company.legalEntities.leganName"
                value={data.name}
                setValue={(name) => setData((p: any) => ({ ...p, name }))}
              />
            )}
          </div>
          <div className="col-start-1 col-end-2">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                disabled
                label="company.legalEntities.code"
                value={data.code}
                append={
                  <span
                    className="input-group-text"
                    onClick={() => {
                      clipboard();
                    }}
                  >
                    <Icon
                      className="text-primary ml-6 cursor-pointer"
                      icon={"files"}
                    />
                  </span>
                }
              />
            )}
          </div>
          <div className="col-start-1 col-end-2">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                as="short-textarea"
                label="company.legalEntities.description"
                value={data.description}
                setValue={(description) =>
                  setData((p: any) => ({ ...p, description }))
                }
              />
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      {FULL_ACCESS || UPDATE_PERMISSION ? (
        <Footer show={!isChanged}>
          <Button as={Link} to={-1} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      ) : null}
    </Form>
  );
}
