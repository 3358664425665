import Locations from "./locations";

const SalesArea = [
  {
    path: "locations",
    children: [...Locations],
  },
];

export default SalesArea;
