export const updateValue = (
  attribute: any,
  values: any,
  id: string,
  value: string,
  channel?: string,
  language?: string,
  measurementFamilyUnitCode?: string
) => {
  let data = JSON.parse(JSON.stringify(values));

  const attributeValues = values?.find(
    (item: any) => item.attributeGroupId === id
  );

  const target = attributeValues?.pimProductAttributeValues?.find(
    (item: any) => item.code === attribute.code
  );

  const updateIndex = attributeValues?.pimProductAttributeValues?.findIndex(
    (item: any) => item.code === attribute.code
  );



  const perChannelAttribute = () => {
    return target?.values?.find((item: any) => {
      return item.channel === channel;
    });
  };
  const perLangAttribute = () => {
    return target?.values?.find((item: any) => {
      return item.language === language;
    });
  };

  const perLangAndChannel = () => {
    return target?.values?.find((item: any) => {
      return item.language === language && item.channel === channel;
    });
  };

  const noneDependent = () => {
    return target?.values?.find((item: any) => {
      return !item.language && !item.channel;
    });
  };

  const notExist = () => {
    return {
      attributeConnectionInfo: {
        ...target?.values?.[0]?.attributeConnectionInfo,
      },
      channel: target.perChannel ? channel : null,
      displayName: null,
      language: target.perLanguage ? language : null,
      measurementFamilyUnitCode: null,
      value: value,
    };
  };

  let targetObject =
    attribute?.perChannel && attribute?.perLanguage
      ? perLangAndChannel()
      : attribute?.perChannel
        ? perChannelAttribute()
        : attribute?.perLanguage
          ? perLangAttribute()
          : noneDependent();

  if (targetObject) {
    if (attribute.type === 70) {
      targetObject = { ...targetObject, value, measurementFamilyUnitCode };
    } else {

      targetObject = { ...targetObject, value };
    }
  } else {
    targetObject = notExist();
  }

  const updateValuePerLang = (attrs: any) => {
    const specificValueIndex = attrs.values?.findIndex(
      (item: any) => item.language === language
    );
    return specificValueIndex;
  };
  const updateValuePerChannel = (attrs: any) => {
    const specificValueIndex = attrs.values?.findIndex(
      (item: any) => item.channel === channel
    );
    return specificValueIndex;
  };
  const updateValuePerLangAndChannel = (attrs: any) => {
    const specificValueIndex = attrs.values?.findIndex(
      (item: any) => item.language === language && item.channel === channel
    );
    return specificValueIndex;
  };
  const updateValueNoneDeps = (attrs: any) => {
    const specificValueIndex = attrs.values?.findIndex(
      (item: any) => !item.language && !item.channel
    );
    return specificValueIndex;
  };

  const perLangAndChannelDefinition = (values: any) => {
    return values?.filter((item: any) => item.language && item.channel)
  }
  const perChannelDefinition = (values: any) => {
    return values?.filter((item: any) => item.channel)
  }
  const perLangDefinition = (values: any) => {
    return values?.filter((item: any) => item.language)
  }
  const noneDependentDefinition = (values: any) => {
    return values?.filter((item: any) => !item.language && !item.channel)
  }

  const checkAttributeDefinitions = (values: any) => {
    let definitionsValues = attribute?.perChannel && attribute?.perLanguage
      ? perLangAndChannelDefinition(values)
      : attribute?.perChannel
        ? perChannelDefinition(values)
        : attribute?.perLanguage
          ? perLangDefinition(values)
          : noneDependentDefinition(values);
    return definitionsValues
  }


  if (updateIndex > -1) {
    const updatedValues = attributeValues?.pimProductAttributeValues?.[updateIndex];
    if (!updatedValues.values) {
      //new attr
      updatedValues.values = []
    }
    const specificValueIndex =
      attribute?.perChannel && attribute?.perLanguage
        ? updateValuePerLangAndChannel(updatedValues)
        : attribute?.perChannel
          ? updateValuePerChannel(updatedValues)
          : attribute?.perLanguage
            ? updateValuePerLang(updatedValues)
            : updateValueNoneDeps(updatedValues);

    if (specificValueIndex > -1) {
      updatedValues.values[specificValueIndex] = targetObject;
      updatedValues.values = checkAttributeDefinitions(updatedValues.values);
      const attributeGroupIndex = values?.findIndex(
        (item: any) => item.attributeGroupId === id
      );

      data[attributeGroupIndex] = attributeValues;

    } else {
      let isNullish = updatedValues.values?.map((item: any) => Object.values(item).every(value => {
        if (value === null) {
          return true;
        }

        return false;
      }));
      isNullish = isNullish ? isNullish : []
      const deleteFromObjectIndex = isNullish.findIndex((item: boolean) => item);
      if (deleteFromObjectIndex > -1) {
        updatedValues.values.splice(deleteFromObjectIndex, 1)
      }
      updatedValues.values?.push(targetObject);
      updatedValues.values = checkAttributeDefinitions(updatedValues.values);
      const attributeGroupIndex = values?.findIndex(
        (item: any) => item.attributeGroupId === id
      );
      data[attributeGroupIndex] = attributeValues;
    }
  }

  return data;
};
