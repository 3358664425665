import { Icon, Image, ImageUploader, Text, Wrapper } from "components";
import { useToggleState } from "hooks";
import { useContext, useState } from "react";
import { ServiceApi, URLS } from "services";
import { Media as MediaType } from "types";
import { CategoryContext } from "..";

const Media = () => {
  const { selectedCategory, setRoots, selectedIndex, roots } =
    useContext(CategoryContext);
  const [isOpen, toggle] = useToggleState(false);
  const [isOpenThumbnailUrl, toggleThumbnailUrl] = useToggleState(false);
  const [imageKey, setImageKey] = useState<string>("");

  const onUploadImage = (data: any) => {
    const updatedRoots = roots.map((item: any, index: number) => {
      if (index === selectedIndex) {
        const url = URLS.EDIT_CATEGORY_TRANSLATES(item.productCategoryId);
        const body = {
          code: item.code,
          pictureThumbnailUrl: item.pictureThumbnailUrl,
          pictureUrl: data.url,
          translates: item.translations,
        };
        ServiceApi.patch(url, body).then(() => { })
        return { ...item, pictureUrl: data.url };
      } else {
        return item;
      }
    });
    setRoots(updatedRoots);
  };

  const onUploadImageThumbnailUrl = (data: any) => {
    const updatedRoots = roots.map((item: any, index: number) => {
      if (index === selectedIndex) {
        const url = URLS.EDIT_CATEGORY_TRANSLATES(item.productCategoryId);
        const body = {
          code: item.code,
          pictureThumbnailUrl: data.thumbnailUrl,
          pictureUrl: item.pictureUrl,
          translates: item.translations,
        };
        ServiceApi.patch(url, body).then(() => { })
        return { ...item, pictureThumbnailUrl: data.thumbnailUrl };
      } else {
        return item;
      }
    });
    setRoots(updatedRoots);
  };

  return (
    <Wrapper>
      <Wrapper.Header className="text-heading-2 font-semibold">
        <Text>productManagement.masterData.productCategory.images</Text>
      </Wrapper.Header>
      <Wrapper.Body className="flex space-x-6">
        <div className="space-y-4">
          <span className="text-heading-6 text-gray-800 font-normal">
            <Text>productManagement.masterData.productCategory.mainImage</Text>
          </span>
          <div
            className="h-[220px] w-[176px] bg-gray-200 flex flex-center rounded-xl cursor-pointer"
            onClick={() => {
              toggle();
            }}
          >
            {selectedCategory.pictureUrl ? (
              <Image
                src={selectedCategory.pictureUrl}
                alt={""}
                editor
                imageClassName="h-full"
                className="aspect-image h-[220px] w-[176px] lg:aspect-auto cursor-pointer"
              />
            ) : (
              <div className="flex flex-col space-y-4">
                <Icon
                  icon="file-arrow-up"
                  size="3x"
                  className="text-gray-500"
                />
                <span className="text-body-base text-gray-600 font-medium">
                  <Text>
                    productManagement.masterData.productCategory.uploadImage
                  </Text>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4">
          <span className="text-heading-6 text-gray-800 font-normal">
            <Text>productManagement.masterData.productCategory.thumbnail</Text>
          </span>
          <div
            className="h-[220px] w-[176px] bg-gray-200 flex flex-center rounded-xl cursor-pointer"
            onClick={() => {
              toggleThumbnailUrl();
            }}
          >
            {selectedCategory.pictureThumbnailUrl ? (
              <Image
                src={selectedCategory.pictureThumbnailUrl}
                alt={""}
                editor
                imageClassName="h-full"
                className="aspect-image h-[220px] w-[176px] lg:aspect-auto cursor-pointer"
              />
            ) : (
              <div className="flex flex-col space-y-4">
                <Icon
                  icon="file-arrow-up"
                  size="3x"
                  className="text-gray-500"
                />
                <span className="text-body-base text-gray-600 font-medium">
                  <Text>
                    productManagement.masterData.productCategory.uploadImage
                  </Text>
                </span>
              </div>
            )}
          </div>
        </div>
      </Wrapper.Body>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={selectedCategory.pictureUrl}
        onUpload={(data: MediaType) => onUploadImage(data)}
      />
      <ImageUploader
        isOpen={isOpenThumbnailUrl}
        toggle={toggleThumbnailUrl}
        image={selectedCategory.pictureThumbnailUrl}
        onUpload={(data: MediaType) => onUploadImageThumbnailUrl(data)}
      />
    </Wrapper>
  );
};

export default Media;
