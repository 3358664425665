import { Button, DeleteModal, Icon, Text, Wrapper } from "components";
import { appPermissions } from "constant";
import { usePermission, useToggleState } from "hooks";
import { useContext, useState } from "react";
import { PimContext } from "..";
import AssignCategoryDrawer from "../drawers/assign-category";
const CategoriesTab = () => {
  const {
    productData,
    setData,
    isChanged,
    editProduct,
    submitLoading,
    setPatchData,
  } = useContext(PimContext);
  const [isOpen, toggle] = useToggleState();
  const [deletModal, toggleDelete] = useToggleState();

  const [selectedItem, setSelectedItem] = useState<any>({});

  const onDeleteHandler = (id: string) => {
    const clone = productData.detailCategories.filter(
      (category: any) => category.id !== id
    );
    const ids = productData.productCategoryIds.filter(
      (catId: string) => catId !== id
    );
    setPatchData((p: any) => ({
      ...p,
      detailCategories: clone,
      productCategoryIds: ids,
    }));
    setData((p: any) => ({
      ...p,
      detailCategories: clone,
      productCategoryIds: ids,
    }));
    toggleDelete();
  };

  //permissions

  const { PS_UpdatePimProduct } = appPermissions;
  const UPDATE_PERMISSION = usePermission(PS_UpdatePimProduct);

  return (
    <>
      {productData.detailCategories?.map((category: any) => {
        return (
          <div
            key={category.id}
            className="flex items-center justify-between p-4 h-[78px] bg-white shadow-card rounded-xl"
          >
            <h5 className="text-gray-800">{category?.translates[0]?.name}</h5>
            <Button
              variant={"danger"}
              light
              size="sm"
              onClick={() => {
                setSelectedItem(category);
                toggleDelete();
              }}
            >
              <Icon icon="trash" />
            </Button>
          </div>
        );
      })}
      <div
        onClick={toggle}
        className="flex gap-4 flex-center text-primary border border-dashed border-primary rounded-xl h-[52px] cursor-pointer select-none bg-gray-100"
      >
        <Icon
          icon="plus"
          size="xs"
          className="bg-primary-light p-2 rounded-md"
        />
        <h5 className="text-primary">
          <Text>productManagement.products.Details.assignCategory</Text>
        </h5>
      </div>
      <AssignCategoryDrawer
        isOpen={isOpen}
        toggle={toggle}
        productData={productData}
        setData={setData}
        setPatchData={setPatchData}
      />
      {UPDATE_PERMISSION && (
        <Wrapper className="sticky bottom-0 shadow-card">
          <Wrapper.Body className="flex items-center justify-end gap-4">
            <Button
              type="button"
              variant="primary"
              disabled={!isChanged}
              onClick={editProduct}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Wrapper.Body>
        </Wrapper>
      )}
      <DeleteModal
        isOpen={deletModal}
        toggle={toggleDelete}
        selected={{ name: selectedItem?.translates?.[0]?.name }}
        onConfirm={() => onDeleteHandler(selectedItem.id)}
        loading={false}
      />
    </>
  );
};

export default CategoriesTab;
