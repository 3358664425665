import MeasurementUnitsList from "pages/product-managment/master-data/measurement-units";
import Details from "pages/product-managment/master-data/measurement-units/details";


const MeasurementUnits = [
  {
    path: "",
    element: <MeasurementUnitsList />,
  },
  {
    path: ":measurementFamilyId",
    element: <Details />,
  },
];

export default MeasurementUnits;
