export default class DomHandler {
  static getClientWidth(el: any, margin?: number) {
    if (el) {
      let width = el.clientWidth;

      if (margin) {
        let style = getComputedStyle(el);

        width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      }

      return width;
    }

    return 0;
  }
}
