import {
    Icon,
    Image,
    ImageUploader,
    InputGroup,
    Text
} from "components";
import { rules } from "constant";
import {
    useToggleState
} from "hooks";
import { Fragment, useContext } from "react";
import { Media } from "types";
import { AddSupplierContext } from "..";


export default function Contact() {
  const { data, setData, dataImg, setDataImg } = useContext<any>(AddSupplierContext);
  const [isOpen, toggle] = useToggleState(false);

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };

  const onUploadImage = (data: Media) => {
    setDataImg({ thumbnailUrl: data.thumbnailUrl, type: data.type, url: data.url })
  };

  return (
    <Fragment>
      {dataImg.thumbnailUrl === "" ? <label
        onClick={() => toggle()}
        data-type="button"
        className="block w-full flex-center flex-col bg-primary-light border-[0.5px] border-dashed border-primary rounded p-8 text-center space-y-2 cursor-pointer"
      >
        <Icon icon="file-arrow-up" size="3x" className="text-primary" />
        <h5 className="text-gray-800">
          <Text>global.uploadFile</Text>
        </h5>
      </label> :
       <Image
        onClick={() => toggle()}
        src={dataImg?.thumbnailUrl}
        alt={data.name}
        logo
        className="flex-center rounded overflow-hidden cursor-pointer bg-gray-100 p-2 "
      />}
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={dataImg?.thumbnailUrl}
        onUpload={(data: Media) => onUploadImage(data)}
      />
      <InputGroup
        label="productManagement.masterData.suppliers.emailAddress"
        value={data?.email}
        setValue={handleSetData("email")}
        rules={rules.emailAddress}
      />
      <InputGroup
        label="productManagement.masterData.suppliers.phoneNumber"
        value={data?.phoneNumber}
        setValue={handleSetData("phoneNumber")}
      />
    </Fragment>
  );
}
