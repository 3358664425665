import Suppliers from "./suppliers";
import Brand from "./brand";
import Attributes from "./attributes";
import AttributesGroups from "./attributes-groups";
import ProductFamiliesRoutes from "./product-families";
import MeasurementUnits from "./measurement-units";
import ProductCategoryRoutes from "./product-category";

const MasterData = [
  {
    path: "attributes",
    children: [...Attributes],
  },
  {
    path: "attribute-groupes",
    children: [...AttributesGroups],
  },
  {
    path: "product-families",
    children: [...ProductFamiliesRoutes],
  },
  {
    path: "product-categories",
    children: [...ProductCategoryRoutes],
  },
  {
    path: "measurement-units",
    children: [...MeasurementUnits],
  },
  {
    path: "suppliers",
    children: [...Suppliers],
  },
  {
    path: "brands",
    children: [...Brand],
  },
];

export default MasterData;
