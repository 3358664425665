import {
  createContext,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
  ReactNode,
  TransitionEvent,
} from "react";
import { Icon } from "components";
type eventKeyType = string | null;
type MenuProps = {
  children: ReactNode;
  defaultActiveKey?: eventKeyType;
};
type MenuItemProps = {
  className?: string;
  eventKey?: eventKeyType;
  children: ReactNode;
  selected?: boolean;
  key?: any;
  active?: boolean;
};
type MenuToggleProps = {
  className?: string;
  children: ReactNode;
  clickable?: boolean;
  selected?: boolean;
  isActive?: boolean;
};
type MenuBodyProps = {
  className?: string;
  children: ReactNode;
  active?: boolean;
};
type MenuContextType = {
  activeKey: eventKeyType;
  setActiveKey: (val: eventKeyType) => void;
};
type MenuItemContextType = {
  eventKey: eventKeyType;
  isActive: boolean;
  active: boolean;
  setTest: any;
};

const MenuContext = createContext({} as MenuContextType);
const MenuItemContext = createContext({} as MenuItemContextType);

function Menu({ children = null, defaultActiveKey = null }: MenuProps) {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);
  return (
    <MenuContext.Provider value={{ activeKey, setActiveKey }}>
      {children}
    </MenuContext.Provider>
  );
}
function MenuItem({
  className = "",
  eventKey = null,
  children,
  selected,
  active = false,
  ...props
}: MenuItemProps) {
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  const key = eventKey ?? id;
  const { activeKey } = useContext(MenuContext);
  const [test, setTest] = useState(active);
  const isActive = activeKey === key || test;
  useEffect(() => {
    ref.current?.classList.toggle("active", isActive);
  }, [isActive]);
  return (
    <MenuItemContext.Provider
      value={{ eventKey: key, isActive, active, setTest }}
    >
      <div className="submenu-parent" key={props.key}>
        <div
          ref={ref}
          className={`Menu-item group w-full pl-[8px] pr-1 ${
            selected && "menu-active"
          } ${selected ? "text-gray-200" : "text-gray-500"} ${className}`}
        >
          {children}
        </div>
      </div>
    </MenuItemContext.Provider>
  );
}
function MenuToggle({
  className = "",
  children = null,
  clickable,
  ...props
}: MenuToggleProps) {
  const { setActiveKey } = useContext(MenuContext);
  const { eventKey, isActive, active, setTest } = useContext(MenuItemContext);
  const handleSetActiveKey = () => {
    if (!clickable) return;
    if (active) setTest(false);
    if (isActive) return setActiveKey(null);
    setActiveKey(eventKey);
  };
  return (
    <button
      type="button"
      onClick={handleSetActiveKey}
      className={`w-full flex items-center hover:text-gray-200 ${className} ${
        (clickable && isActive) || props.selected
          ? "text-gray-200 font-normal"
          : "text-gray-500 font-light"
      }`}
    >
      {children}
      {clickable && props.isActive && (
        <Icon
          icon={`${isActive ? "chevron-down" : "chevron-right"}`}
          size="2xs"
          className={`transition-transform ml-auto`}
        />
      )}
    </button>
  );
}
function MenuBody({ className = "", children = null }: MenuBodyProps) {
  const collapseRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const { isActive } = useContext(MenuItemContext);
  const handleTransition = ({
    currentTarget,
  }: TransitionEvent<HTMLDivElement>) => {
    currentTarget.style.height = isActive ? "auto" : "0";
  };
  useEffect(() => {
    const collapse = collapseRef.current;
    const body = bodyRef.current;
    const height = body?.getBoundingClientRect().height;
    if (collapse && body) {
      if (isActive) {
        collapse.style.height = `${height}px`;
      } else {
        collapse.style.height = `${height}px`;
        requestAnimationFrame(() => {
          collapse.style.height = "0px";
        });
      }
    }
  }, [isActive]);
  return (
    <div
      ref={collapseRef}
      onTransitionEnd={handleTransition}
      className={`w-full h-0 group-[.Menu-item.active]:h-auto overflow-hidden transition-[height] ${className}`}
    >
      <div ref={bodyRef} className="h-fit">
        {children}
      </div>
    </div>
  );
}

Menu.Item = MenuItem;
Menu.Toggle = MenuToggle;
Menu.Body = MenuBody;

export default Menu;
