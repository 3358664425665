import { NotificationManager } from "common/notifications";
import {
    Breadcrumb,
    Button,
    Form,
    Icon,
    Stepper,
    Text,
    Wrapper
} from "components";
import { appPermissions } from "constant";
import { usePermission } from "hooks";
import { Fragment, createContext, createElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import Contact from "./tabs/contact";
import General from "./tabs/general";
import Location from "./tabs/location";
import Review from "./tabs/review";


export const AddSupplierContext = createContext({});

export default function Create() {

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [data, setData] = useState<any>({
    code: "",
    name: "",
    iln: "",
    description: "",
    logoUrl: "",
    thumbnailUrl: "",
    email: "",
    phoneNumber: "",
    address: {
      name: "",
      country: "",
      state: "",
      city: "",
      postalCode: "",
      street: "",
      houseNo: "",
      location: {
        longitude: null,
        latitude: null,
      },
    },

  });
  const [dataImg, setDataImg] = useState<any>({
    thumbnailUrl: "",
    type: "",
    url: ""
  });
  const navigate = useNavigate();
  const tabs = [
    {
      label: "productManagement.masterData.suppliers.generalInfo",
      desc: "productManagement.masterData.suppliers.generalInfoDesc",
      component: General,
    },
    {
      label: "productManagement.masterData.suppliers.contactInfo",
      desc: "productManagement.masterData.suppliers.contactInfoDesc",
      component: Contact,
    },
    {
      label: "productManagement.masterData.suppliers.location",
      desc: "productManagement.masterData.suppliers.locationDesc",
      component: Location,
    },
    {
      label: "productManagement.masterData.suppliers.review",
      desc: "productManagement.masterData.suppliers.reviewDesc",
      component: Review,
    },
  ];
  const [loading, setLoading] = useState(false);
  const activeTab = tabs[activeTabIndex];
  const isFirstTab = activeTabIndex === 0;
  const isLastTab = activeTabIndex === tabs.length - 1;
  const goNext = () => {
    if (isLastTab) return submit();
    setActiveTabIndex((p) => p + 1);
  };
  const goPrev = () => {
    if (isFirstTab) return;
    setActiveTabIndex((p) => p - 1);
  };


  //permissions
  const {
    PS_CreateSupplier,
  } = appPermissions;
  const CREATE_PERMISSIONS = usePermission(PS_CreateSupplier);


  const submit = () => {
    setLoading(true);
    const url = URLS.ADD_SUPPLIER;
    ServiceApi.post(url, data).then((res: any) => {
      if (dataImg.url !== "") {
        const urlImg = URLS.UPLOAD_SUPPLIERS_IMAGES(res.data);
        ServiceApi.patch(urlImg, dataImg).then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title"
          );
          navigate(-1);
        }).finally(() => {
          setLoading(false);
        });
      } else {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        navigate(-1);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 items-start">
        <Stepper tabs={tabs as []} activeTab={activeTabIndex} />
        <Wrapper
          as={Form}
          onSubmit={goNext}
          className="col-span-full lg:col-span-8"
        >
          <Wrapper.Body className="w-full lg:w-[calc(100%-9rem)] mx-auto space-y-8">
            <AddSupplierContext.Provider
              value={{
                dataImg,
                setDataImg,
                data,
                setData,
              }}
            >
              {createElement(activeTab.component)}
            </AddSupplierContext.Provider>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                disabled={isFirstTab}
                onClick={goPrev}
              >
                <Icon icon="arrow-left" /> <Text>global.buttons.back</Text>
              </Button>
              {CREATE_PERMISSIONS && (
                <Button
                  type="submit"
                  variant="primary"
                  loading={loading}
                >
                  {isLastTab ? (
                    <Fragment>
                      <Icon icon="check" /> <Text>global.buttons.submit</Text>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Text>global.buttons.next</Text> <Icon icon="arrow-right" />
                    </Fragment>
                  )}
                </Button>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>
      </div>
    </div>
  );
}
