import { NotificationManager } from "common/notifications";
import { Button, Form, Icon, InputGroup, Modal, SelectLang, Text, Toggler } from "components";
import { flagCountry, rules } from "constant";
import { useDataState, useSelector } from "hooks";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";


type createModalType = {
  isOpen: boolean;
  attributeId?: string;
  toggle: () => void;
  getData: () => void;
};

export default function CreateModal(props: createModalType) {
  const [data, setData, setDataBase] = useDataState<any>({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [langSelected, setLangSelected] = useState("de");
  const [name, setName] = useState("");
  const profile = useSelector((s) => s.profile);
  const formRef = useRef<any>();

  useEffect(() => {
    if (props.isOpen) {
      setDataBase({});
      setData({})
      setName("")
      let arr: any = [];
      let translatesNew: any = [];
      profile?.companyContentLanguages?.forEach((item: any) => {
        arr.push({ id: item?.toLocaleLowerCase(), name: item })
        translatesNew.push({ displayName: "", language: item })
      })
      setDataBase({
        attributeTemplateId: props.attributeId,
        attributeItemId: "",
        translations: translatesNew,
        setAsDefault: false,

      })
      setLangItems(arr)
      setLangSelected(profile?.companyDefaultLanguage?.toLocaleLowerCase())
    }
  }, [props.isOpen])// eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitForm = (formData: any) => {
    formData["translates"] = [];
    setLoadingButton(true);
    formData.translations.forEach((item: any) => {
      if (item.displayName !== "") {
        formData["translates"].push(item)
      }
    })
    const url = URLS.ADD_ITEM_SELECT_ATTRIBUTES_URLS(props.attributeId);
    ServiceApi.patch(url, formData).then((res) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      props.getData();
    }).finally(() => {
      props.toggle();
      setLoadingButton(false);

    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    data?.translations?.forEach((item: any) => {
      if (item?.language?.toLocaleLowerCase() === lang?.toLocaleLowerCase()) {
        setName(item.displayName)
      }
    })
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Form ref={formRef} onSubmit={onSubmitForm} id="create-select-type">
        <Modal.Header className="flex items-center justify-between">
          <div>
            <h2 className="text-heading-2 text-gray-800">
              <Text>productManagement.masterData.attributes.addItem</Text>
            </h2>
          </div>
          <div>
            <SelectLang value={langSelected} items={langItems} onChangeLanguage={onChangeLanguage} />
          </div>

        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            <InputGroup
              label="productManagement.masterData.attributes.value"
              value={data?.attributeItemId}
              className="border-white"
              setValue={(attributeItemId) => setData((p: any) => ({ ...p, attributeItemId }))}
              rules={rules.required}
            />
            <InputGroup
              label="productManagement.masterData.attributes.name"
              value={name}
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                data?.translations?.forEach((item: any) => {
                  if (item?.language?.toLocaleLowerCase() === langSelected?.toLocaleLowerCase()) {
                    item.displayName = value
                  }
                })
                setData((p: any) => ({ ...p, translations: data?.translations }));
                setName(value)
              }}
              rules={rules.required}
            />
            <Toggler
              label={"productManagement.masterData.attributes.default"}
              value={data?.setAsDefault}
              setValue={(setAsDefault) => setData((p: any) => ({ ...p, setAsDefault }))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={() => props.toggle()} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingButton}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
