import { Text, Wrapper } from "components";
import { Fragment, useContext } from "react";
import { AddStoreContext } from ".";
import { convertAddress, generateMapSrc } from "utils";

export default function Review() {
  const { data } = useContext(AddStoreContext);
  return (
    <Fragment>
      <h2 className="text-heading-2 text-gray-800">
        <Text>company.stores.review</Text>
      </h2>
      <Wrapper.Section className="grid grid-cols-5 gap-4 items-start text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="text-gray-600 col-span-full">
          <Text>company.stores.basicInfo</Text>
        </h3>
        <div>
          <Text>company.stores.storeName</Text>
        </div>
        <div>{data?.title}</div>
        <div>
          <Text>company.stores.storeCode</Text>
        </div>
        <div>{data?.code}</div>
        <div>
          <Text>company.stores.legalEntity</Text>
        </div>
        <div>{data?.legalEntityCode}</div>
        <div>
          <Text>company.stores.shortDescription</Text>
        </div>
        <div>{data?.shortDescription}</div>
        <div>
          <Text>company.stores.description</Text>
        </div>
        <div>{data?.body}</div>
      </Wrapper.Section>
      <Wrapper.Section className="grid grid-cols-5 gap-4 items-start text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="text-gray-600 col-span-full">
          <Text>company.stores.location</Text>
        </h3>
        <div>
          <Text>global.locations.address</Text>
        </div>
        <div>{convertAddress(data.address)}</div>
        <div>
          <Text>global.locations.locationOnMap</Text>
        </div>
        <div>
          <iframe
            title="Address"
            src={generateMapSrc(data.address)}
            className="bg-gray-300 block aspect-video w-full rounded shadow"
          />
        </div>
      </Wrapper.Section>
      <Wrapper.Section>
        <h3 className="text-gray-600 mb-6">
          <Text>company.stores.image</Text>
        </h3>
        <img src={data.mainPhoto} alt={data.title} className="w-full" />
      </Wrapper.Section>
    </Fragment>
  );
}
