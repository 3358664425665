import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  Icon,
  NoItems,
} from "components";
import { usePermission } from "hooks";
import { ServiceApi, URLS } from "services";
import { Link, useSearchParams } from "react-router-dom";
import { config, appPermissions } from "constant";

type CustomerListItems = {
  id: string;
  code: string;
  personInfo: any;
  emailAddress: string;
  cimNumber: string;
  isErpCustomer: string;
};

type ListType = {
  items?: CustomerListItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function CustomerList() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();


  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_CUSTOMERS_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  //permissions
  const { AS_GetCustomers, AS_GetCustomerDetail } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(AS_GetCustomers);
  const GET_DETAIL_PERMISSION = usePermission(AS_GetCustomerDetail);

  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Link
                          to={GET_DETAIL_PERMISSION ? item.id : ""}
                          className="w-fit flex-center flex gap-2"
                        >
                          <Table.Image
                            placeholder="user"
                            src=""
                            alt={item.id}
                            className="h-[78px] w-[78px]"
                          />
                          <div className="space-y-2">
                            <div>
                              <h6 className="text-heading-6 text-gray-800 font-semibold">{`${item?.personInfo?.firstName} ${item?.personInfo?.lastName}`}</h6>
                            </div>
                            <div>
                              <Icon
                                className="text-gray-500 font-black mr-2 h-4 w-4"
                                icon="envelope"
                              />
                              <span className="text-body-2 text-gray-500 font-medium">
                                {item?.personInfo?.emailAddress}
                              </span>
                            </div>
                            <div>
                              <Icon
                                className="text-gray-500 font-black mr-2 h-4 w-4"
                                icon="mobile"
                              />
                              <span className="text-body-2 text-gray-500 font-medium">
                                {item?.personInfo?.mobileNumber}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td>
                        <div>
                          <span className="text-body-2 text-gray-600 font-medium">
                            <Text>
                              company.customers.customerList.cimNumber
                            </Text>
                            :
                          </span>
                          <span className="text-body-2 text-gray-500 font-medium ml-2">
                            {item?.cimNumber}
                          </span>
                        </div>
                      </td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button
                            as={Link}
                            to={item.id}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams((p) => ({ ...p, pageSize: e }))
                }
              />
            </>
          ) : (
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <>Permission Error</>
  );
}
