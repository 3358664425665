import i18n from "i18next";
import en from "./en";
import de from "./de";
import { config } from "../constant";
const resources = {
  en: { translation: en },
  de: { translation: de },
};
i18n.init({
  resources,
  lng: localStorage.getItem("lang") || config.lang,
});
export default i18n;
