import ProfileCompany from "pages/compony/profile";

const Profile = [
  {
    path: "",
    element: <ProfileCompany />,
  },
];

export default Profile;
