import Wrapper from "./Wrapper";

type FooterProps = {
  children?: any;
  show?: boolean;
};
export default function Footer({ show, children }: FooterProps) {
  return (
    <Wrapper className={`sticky bottom-0 shadow-[0px_-2px_7px_0px_#eef0f8] transition ${show ? "opacity-0" : "opacity-100"}`}>
      <Wrapper.Body className="flex items-center justify-end gap-4">
        {children}
      </Wrapper.Body>
    </Wrapper>
  );
}
