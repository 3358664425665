import { NotificationManager } from "common/notifications";
import { Button, DeleteModal, Icon, Skeleton, Table, Text } from "components";
import { appPermissions } from "constant";
import { usePermission, useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { PimContext } from "..";
import AssignPlace from "../drawers/assign-place";

const LocationsTab = () => {
  const { productData } = useContext(PimContext);
  const [locations, setLocations] = useState<any>([]);
  const [ids, setIds] = useState<any>([]);
  const [loading, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [deletModal, toggleDelete] = useToggleState();
  const [removeLoading, setRemoveLoading] = useToggleState();
  const [selectedItem, setSelectedItem] = useState<any>({});

  const getLocations = () => {
    setLoading();
    const url = URLS.GET_PLACES(productData?.id);
    ServiceApi.get(url)
      .then(({ data }) => {
        setLocations(data);
        let ids = data.map((place: any) => place.placeId);
        setIds(ids);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    getLocations();
  }, []);

  const onDeleteHandler = () => {
    setRemoveLoading();
    const url = URLS.REMOVE_PLACES(productData?.id);
    const body = { placeId: selectedItem.placeId };
    ServiceApi.put(url, body).then(() => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      setRemoveLoading();
      toggleDelete();
      getLocations();
    });
  };

  //permissions

  const { PS_PimProductAddToLocation, PS_PimProductRemoveFromLocation } =
    appPermissions;
  const ADD_PERMISSION = usePermission(PS_PimProductAddToLocation);
  const REMOVE_PERMISSION = usePermission(PS_PimProductRemoveFromLocation);

  return (
    <div className="space-y-4">
      {loading && [1, 2, 3, 4].map((key) => <Skeleton.List key={key} />)}
      <Table>
        {locations?.map((place: any) => {
          return (
            <tr key={place.placeCode}>
              <td className="space-y-2">
                <h5 className="text-gray-800">{place.placeDisplayName}</h5>
                <p className="text-body-2">{place.placeCode}</p>
              </td>
              <td className="text-body-base font-normal text-gray-800">
                {place.storeCode}
              </td>
              {REMOVE_PERMISSION && (
                <td>
                  <Button
                    variant={"danger"}
                    light
                    size="sm"
                    onClick={() => {
                      setSelectedItem(place);
                      toggleDelete();
                    }}
                  >
                    <Icon icon="trash" />
                  </Button>
                </td>
              )}
            </tr>
          );
        })}
      </Table>

      {ADD_PERMISSION && (
        <div
          onClick={toggle}
          className="flex gap-4 flex-center text-primary border border-dashed border-primary rounded-xl h-[52px] cursor-pointer select-none bg-gray-100"
        >
          <Icon
            icon="plus"
            size="xs"
            className="bg-primary-light p-2 rounded-md"
          />
          <h5 className="text-primary">
            <Text>productManagement.products.Details.assignPlace</Text>
          </h5>
        </div>
      )}
      <AssignPlace
        isOpen={isOpen}
        toggle={toggle}
        ids={ids}
        id={productData?.id}
        onConfirm={getLocations}
      />
      <DeleteModal
        isOpen={deletModal}
        toggle={toggleDelete}
        selected={{ name: selectedItem.placeDisplayName }}
        onConfirm={onDeleteHandler}
        loading={removeLoading}
      />
    </div>
  );
};

export default LocationsTab;
