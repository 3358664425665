export const appPermissions = {
  AS_CreateB2BUser: "AS_CreateB2BUser",
  AS_InviteB2BUserIdP: "AS_InviteB2BUserIdP",
  AS_UpdateUser: "AS_UpdateUser",
  AS_UpdateUserProviders: "AS_UpdateUserProviders",
  AS_GetB2BUserDetail: "AS_GetB2BUserDetail",
  AS_GetB2BUsers: "AS_GetB2BUsers",
  AS_SearchUserAD: "AS_SearchUserAD",
  AS_CreateUserGroup: "AS_CreateUserGroup",
  AS_UpdateUserGroup: "AS_UpdateUserGroup",
  AS_GetUserGroups: "AS_GetUserGroups",
  AS_GetUserGroupDetail: "AS_GetUserGroupDetail",
  AS_CreateRole: "AS_CreateRole",
  AS_UpdateRole: "AS_UpdateRole",
  AS_GetRoles: "AS_GetRoles",
  AS_GetRoleDetail: "AS_GetRoleDetail",
  PS_CreateSellPhaseTemplate: "PS_CreateSellPhaseTemplate",
  PS_UpdateSellPhaseTemplate: "PS_UpdateSellPhaseTemplate",
  PS_GetSellPhaseTemplate: "PS_GetSellPhaseTemplate",
  PS_GetSellPhaseTemplateDetail: "PS_GetSellPhaseTemplateDetail",
  PS_CreateProductFamily: "PS_CreateProductFamily",
  PS_UpdateProductFamily: "PS_UpdateProductFamily",
  PS_GetProductFamilyList: "PS_GetProductFamilyList",
  PS_GetProductFamilyDetail: "PS_GetProductFamilyDetail",
  PS_CreateSellOffProduct: "PS_CreateSellOffProduct",
  PS_UpdateSellOffProduct: "PS_UpdateSellOffProduct",
  PS_UpdateSellOffProductSellPhaseTemplate:
    "PS_UpdateSellOffProductSellPhaseTemplate",
  PS_UpdateSellOffProductPrice: "PS_UpdateSellOffProductPrice",
  PS_SetFastSalesProvisionAmount: "PS_SetFastSalesProvisionAmount",
  PS_AddSubProductItem: "PS_AddSubProductItem",
  PS_UpdateSubProductItem: "PS_UpdateSubProductItem",
  PS_RemoveSubProductItem: "PS_RemoveSubProductItem",
  PS_StartPhase: "PS_StartPhase",
  PS_ChangePhase: "PS_ChangePhase",
  PS_ClosePhase: "PS_ClosePhase",
  PS_SoldSellOffProduct: "PS_SoldSellOffProduct",
  PS_WithdrawSellOffProduct: "PS_WithdrawSellOffProduct",
  PS_UpdatePhaseCheckListItem: "PS_UpdatePhaseCheckListItem",
  PS_ManageSellOffProductAsset: "PS_ManageSellOffProductAsset",
  PS_DeleteSellOffProduct: "PS_DeleteSellOffProduct",
  PS_GetSellOffProductDetail: "PS_GetSellOffProductDetail",
  PS_GetSellOffProductList: "PS_GetSellOffProductList",
  PS_GetPhaseDetail: "PS_GetPhaseDetail",
  PS_UpsertPreparationPhase: "PS_UpsertPreparationPhase",
  PS_GetPreparationPhase: "PS_GetPreparationPhase",
  PS_CreateBrand: "PS_CreateBrand",
  PS_UpdateBrand: "PS_UpdateBrand",
  PS_GetBrands: "PS_GetBrands",
  PS_GetBrandDetail: "PS_GetBrandDetail",
  AS_GenerateApiKey: "AS_GenerateApiKey",
  AS_DeleteApiKey: "AS_DeleteApiKey",
  AS_GetApiKeys: "AS_GetApiKeys",
  PS_CreateCheckListItem: "PS_CreateCheckListItem",
  PS_UpdateCheckListItem: "PS_UpdateCheckListItem",
  PS_DeleteCheckListItem: "PS_DeleteCheckListItem",
  PS_ViewMeasurementFamily: "PS_ViewMeasurementFamily",
  PS_ManageMeasurementFamily: "PS_ManageMeasurementFamily",
  PS_GetCheckListItem: "PS_GetCheckListItem",
  SS_GetWinnerCacluations: "SS_GetWinnerCacluations",
  SS_ChangeWinnerCalculationStatusToPendingApproval:
    "SS_ChangeWinnerCalculationStatusToPendingApproval",
  SS_ChangeWinnerCalculationStatusToApproved:
    "SS_ChangeWinnerCalculationStatusToApproved",
  SS_ManageWinnerSettings: "SS_ManageWinnerSettings",
  PS_CreateAttributeTemplate: "PS_CreateAttributeTemplate",
  PS_UpdateAttributeTemplate: "PS_UpdateAttributeTemplate",
  PS_DeleteAttributeTemplate: "PS_DeleteAttributeTemplate",
  PS_GetAttributeTemplateList: "PS_GetAttributeTemplateList",
  PS_GetAttributeTemplateDetail: "PS_GetAttributeTemplateDetail",
  PS_CreateAttributeGroupTemplate: "PS_CreateAttributeGroupTemplate",
  PS_UpdateAttributeGroupTemplate: "PS_UpdateAttributeGroupTemplate",
  PS_DeleteAttributeGroupTemplate: "PS_DeleteAttributeGroupTemplate",
  PS_GetAttributeGroupTemplateList: "PS_GetAttributeGroupTemplateList",
  PS_GetAttributeGroupTemplateDetail: "PS_GetAttributeGroupTemplateDetail",
  AS_CreateStore: "AS_CreateStore",
  AS_UpdateStore: "AS_UpdateStore",
  AS_RemoveStore: "AS_RemoveStore",
  AS_GetStores: "AS_GetStores",
  AS_GetStoreDetail: "AS_GetStoreDetail",
  AS_SearchStoresByLocation: "AS_SearchStoresByLocation",
  AS_CreateCustomerTerminal: "AS_CreateCustomerTerminal",
  AS_GetCustomerTerminal: "AS_GetCustomerTerminal",
  AS_GetCustomerTerminals: "AS_GetCustomerTerminals",
  AS_UpdateCustomerTerminal: "AS_UpdateCustomerTerminal",
  PS_CreatePimProductFamily: "PS_CreatePimProductFamily",
  PS_UpdatePimProductFamily: "PS_UpdatePimProductFamily",
  PS_RemovePimProductFamily: "PS_RemovePimProductFamily",
  PS_GetPimProductFamilyList: "PS_GetPimProductFamilyList",
  PS_GetPimProductFamilyDetail: "PS_GetPimProductFamilyDetail",
  PS_CreateProductCategory: "PS_CreateProductCategory",
  PS_UpdateProductCategory: "PS_UpdateProductCategory",
  PS_DeleteProductCategory: "PS_DeleteProductCategory",
  PS_GetProductCategories: "PS_GetProductCategories",
  PS_GetProductCategoryDetail: "PS_GetProductCategoryDetail",
  PS_PublishProductCategoryToChannel: "PS_PublishProductCategoryToChannel",
  PS_CreatePimProduct: "PS_CreatePimProduct",
  PS_GetPimProduct: "PS_GetPimProduct",
  PS_SearchPimProducts: "PS_SearchPimProducts",
  PS_GetPimProductsList: "PS_GetPimProductsList",
  PS_UpdatePimProduct: "PS_UpdatePimProduct",
  PS_ImportPimProduct: "PS_ImportPimProduct",
  PS_UpdatePimProductAttributeValues: "PS_UpdatePimProductAttributeValues",
  PS_PublishPimProductToChannel: "PS_PublishPimProductToChannel",
  PS_CreateChannel: "PS_CreateChannel",
  PS_GetChannels: "PS_GetChannels",
  PS_GetChannelProducts: "PS_GetChannelProducts",
  PS_SearchChannelProductSalesTools: "PS_SearchChannelProductSalesTools",
  PS_GetChannelProductCategories: "PS_GetChannelProductCategories",
  PS_SearchChannelProductCategorySalesTools:
    "PS_SearchChannelProductCategorySalesTools",
  PS_CreateSupplier: "PS_CreateSupplier",
  PS_UpdateSupplier: "PS_UpdateSupplier",
  PS_RemoveSupplier: "PS_RemoveSupplier",
  PS_GetSuppliers: "PS_GetSuppliers",
  PS_GetSupplierDetail: "PS_GetSupplierDetail",
  PS_PublishSupplierToChannel: "PS_PublishSupplierToChannel",
  SP_SearchInquiry: "SP_SearchInquiry",
  PS_CreatePimProductMedia: "PS_CreatePimProductMedia",
  PS_UpdatePimProductMedia: "PS_UpdatePimProductMedia",
  PS_RemovePimProductMedia: "PS_RemovePimProductMedia",
  PS_GetPimProductMedia: "PS_GetPimProductMedia",
  PS_GetPimProductMediaList: "PS_GetPimProductMediaList",
  AS_GetCompanyChannel: "AS_GetCompanyChannel",
  AS_GetCompanyChannels: "AS_GetCompanyChannels",
  PS_CreateZone: "PS_CreateZone",
  PS_UpdateZone: "PS_UpdateZone",
  PS_GetZoneDetail: "PS_GetZoneDetail",
  PS_GetZones: "PS_GetZones",
  PS_CreateArea: "PS_CreateArea",
  PS_UpdateArea: "PS_UpdateArea",
  PS_GetAreaDetail: "PS_GetAreaDetail",
  PS_GetAreas: "PS_GetAreas",
  PS_CreatePlace: "PS_CreatePlace",
  PS_UpdatePlace: "PS_UpdatePlace",
  PS_GetPlaceDetail: "PS_GetPlaceDetail",
  PS_GetPlaces: "PS_GetPlaces",
  PS_PimProductAddToLocation: "PS_PimProductAddToLocation",
  PS_PimProductRemoveFromLocation: "PS_PimProductRemoveFromLocation",
  PS_PimProductGetLocations: "PS_PimProductGetLocations",
  AS_UpdateMyCompany: "AS_UpdateMyCompany",
  AS_GetMyCompany: "AS_GetMyCompany",
  AS_CreateCompanySSO: "AS_CreateCompanySSO",
  AS_UpdateCompanySSO: "AS_UpdateCompanySSO",
  AS_DeleteCompanySSO: "AS_DeleteCompanySSO",
  AS_GetCompanySSOs: "AS_GetCompanySSOs",
  AS_GetCompanySSODetail: "AS_GetCompanySSODetail",
  AS_CreateLahoma: "AS_CreateLahoma",
  AS_UpdateLahoma: "AS_UpdateLahoma",
  AS_GetLahomaDetail: "AS_GetLahomaDetail",
  NS_CreateActionGroup: "NS_CreateActionGroup",
  NS_UpdateActionGroup: "NS_UpdateActionGroup",
  NS_DeleteActionGroup: "NS_DeleteActionGroup",
  NS_GetActionGroups: "NS_GetActionGroups",
  NS_GetActionGroupDetail: "NS_GetActionGroupDetail",
  NS_CreateNotification: "NS_CreateNotification",
  NS_UpdateNotification: "NS_UpdateNotification",
  NS_RemoveNotification: "NS_RemoveNotification",
  NS_GetNotifications: "NS_GetNotifications",
  NS_GetNotificationDetail: "NS_GetNotificationDetail",
  NS_ModifyNotificationActionGroups: "NS_ModifyNotificationActionGroups",
  NS_GetNotificationMessages: "NS_GetNotificationMessages",
  NS_GetNotificationMessageDetail: "NS_GetNotificationMessageDetail",
  PS_CreateLahomaOrder: "PS_CreateLahomaOrder",
  PS_GetLahomaOrderDetail: "PS_GetLahomaOrderDetail",
  PS_GetLahomaOrders: "PS_GetLahomaOrders",
  PS_UpdateLahomaOrder: "PS_UpdateLahomaOrder",
  PS_GetSellOffCommissionList: "PS_GetSellOffCommissionList",
  PS_GetOwnSellOffCommissionList: "PS_GetOwnSellOffCommissionList",
  AS_CreateCustomer: "AS_CreateCustomer",
  AS_UpdateCustomer: "AS_UpdateCustomer",
  AS_RemoveCustomer: "AS_RemoveCustomer",
  AS_GetCustomers: "AS_GetCustomers",
  AS_GetCustomerDetail: "AS_GetCustomerDetail",
  AS_GetCustomerCRMSyncInfo: "AS_GetCustomerCRMSyncInfo",
  CMS_CreateDynamics365Configuration: "CMS_CreateDynamics365Configuration",
  CMS_UpdateDynamics365Configuration: "CMS_UpdateDynamics365Configuration",
  CMS_GetDynamics365ConfigurationDetail:
    "CMS_GetDynamics365ConfigurationDetail",
  PS_ImportConfigurationProviderIntoB2B:
    "PS_ImportConfigurationProviderIntoB2B",
  CMS_CreateBusinessCentralConfiguration:
    "CMS_CreateBusinessCentralConfiguration",
  CMS_UpdateBusinessCentralConfiguration:
    "CMS_UpdateBusinessCentralConfiguration",
  CMS_GetBusinessCentralConfigurationDetail:
    "CMS_GetBusinessCentralConfigurationDetail",
  CMS_CreateDynamics365ServiceBusConfiguration:
    "CMS_CreateDynamics365ServiceBusConfiguration",
  CMS_UpdateDynamics365ServiceBusConfiguration:
    "CMS_UpdateDynamics365ServiceBusConfiguration",
  CMS_GetDynamics365ServiceBusConfigurationDetail:
    "CMS_GetDynamics365ServiceBusConfigurationDetail",
  PS_CreateOffer: "PS_CreateOffer",
  PS_AddNewOfferVersion: "PS_AddNewOfferVersion",
  PS_UpdateOffer: "PS_UpdateOffer",
  PS_RejectOffer: "PS_RejectOffer",
  PS_ReactivateCanceledOffer: "PS_ReactivateCanceledOffer",
  PS_MakeOrderFromOffer: "PS_MakeOrderFromOffer",
  PS_GetOffers: "PS_GetOffers",
  PS_UpdateOrder: "PS_UpdateOrder",
  PS_CancelOrder: "PS_CancelOrder",
  PS_GetOrders: "PS_GetOrders",
  PS_PublishOrderToERP: "PS_PublishOrderToERP",
  PS_CreateChannelSetting: "PS_CreateChannelSetting",
  PS_UpdateChannelSetting: "PS_UpdateChannelSetting",
  PS_GetChannelSettings: "PS_GetChannelSettings",
  PS_GetChannelSettingDetail: "PS_GetChannelSettingDetail",
  PS_ChannelSettingFullAccess: "PS_ChannelSettingFullAccess",
  AS_LegalEntityFullAccess: "AS_LegalEntityFullAccess",
  AS_CreateLegalEntity: "AS_CreateLegalEntity",
  AS_GetLegalEntity: "AS_GetLegalEntity",
  AS_GetLegalEntityDetail: "AS_GetLegalEntityDetail",
  AS_UpdateLegalEntity: "AS_UpdateLegalEntity",
  PS_GetDocumentTemplates: "PS_GetDocumentTemplates",
  PS_GetDocumentTemplateDetail: "PS_GetDocumentTemplateDetail",
  PS_CreatePosition: "PS_CreatePosition",
  PS_UpdatePosition: "PS_UpdatePosition",
  PS_RemovePosition: "PS_RemovePosition",
  PS_GetPositions: "PS_GetPositions",
  PS_GetPositionDetail: "PS_GetPositionDetail",
  PS_CreateInfoPage: "PS_CreateInfoPage",
  PS_UpdateInfoPage: "PS_UpdateInfoPage",
  PS_RemoveInfoPage: "PS_RemoveInfoPage",
  PS_GetInfoPages: "PS_GetInfoPages",
  PS_GetInfoPageDetail: "PS_GetInfoPageDetail",
  PS_CreateProgram: "PS_CreateProgram",
  PS_UpdateProgram: "PS_UpdateProgram",
  PS_GetPrograms: "PS_GetPrograms",
  PS_GetProgramDetail: "PS_GetProgramDetail",
  PS_GetBlobConfigDetail: "PS_GetBlobConfigDetail",
  PS_UpsertBlobConfig: "PS_UpsertBlobConfig",
  PS_CreateHoliday: "PS_CreateHoliday",
  PS_UpdateHoliday: "PS_UpdateHoliday",
  PS_GetHolidays: "PS_GetHolidays",
  PS_GetHolidayDetail: "PS_GetHolidayDetail",
  AS_EmployeeFullAccess:"AS_EmployeeFullAccess",
  AS_GetEmployees:"AS_GetEmployees",
  AS_GetEmployeeDetail:"AS_GetEmployeeDetail",


};
