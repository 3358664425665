// import noItemsIcon from "../assets/icons/no items.svg";
import Image from "./Image";
import NoData from "assets/image/no-data.svg";
import Text from "./Text";
export default function NoItems() {
  return (
    <div className="w-full space-y-4 text-center p-4 text-2xl text-[#B5B5C3]">
      <Image className="block w-64 mx-auto" src={NoData} alt="No item found" />
      <h5>
        <Text>global.noItems</Text>
      </h5>
      <p className="text-base">
        <Text>global.noItemsElse</Text>
      </p>
    </div>
  );
}
