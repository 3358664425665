import { Text } from "components";
import { useMemo } from "react";
type averageFactorProps = {
  target: number;
  average: number;
  className?: string;
  warning?:boolean;
};
export default function AverageFactor({
  target,
  average,
  className,
  warning
}: averageFactorProps) {
  const handleAverageColor = useMemo(() => {
    return warning
      ? { bg: "bg-danger", color: "text-danger" }
      : { bg: "bg-info", color: "text-info" };
  }, [target, average]);
  const handleHeight = (value: number) => {
    if (!value) return 0;
    const fullHight = Math.max(target, average);
    const height = (value / fullHight).toFixed(1) ?? 0;
    return `${+height * 100}%`;
  };
  return (
    <section className={`w-full flex items-center gap-4 rounded ${className}`}>
      <div className="h-10 flex justify-center items-end gap-2">
        <span
          style={{ height: handleHeight(target) }}
          className="w-1 h-full rounded-full bg-gray-600"
        />
        <span
          style={{ height: handleHeight(average) }}
          className={`w-1 h-full rounded-full ${handleAverageColor.bg}`}
        />
      </div>
      <div>
        <p className="text-body-base text-gray-600 font-normal leading-6">
          <Text>salesManagment.kitchenSales.salesList.targetSellFactor</Text>:
          <span className="inline-block ms-2 text-body-base text-gray-800 font-normal">{target?.toFixed(2)}</span>
        </p>
        <p className={`text-body-base text-gray-600 font-normal leading-6`}>
          <Text>salesManagment.kitchenSales.salesList.sellFactor</Text>:
          <span className={`inline-block text-body-base font-normal ${handleAverageColor.color} ms-2`}>{average?.toFixed(2)}</span>
        </p>
      </div>
    </section>
  );
}
