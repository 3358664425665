import { useEffect, useState } from "react";
import { Media } from "types";
import {
  Drawer,
  Text,
  Button,
  Icon,
  InputGroup,
  Wrapper,
  ImageUploader,
  Image,
  SelectLang
} from "components";
import { useToggleState,useSelector } from "hooks";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";

type Props = {
  isOpen: boolean;
  toggle: any;
  channelInfo: any;
  id: string;
  selectedChanel: any;
  getChannelsInfo: any;
};

const ChannelDrawer = ({
  isOpen,
  toggle,
  channelInfo,
  id,
  selectedChanel,
  getChannelsInfo,
}: Props) => {
  const [lang, setLang] = useState<string>("de");
  const [imageKey, setImageKey] = useState<string>("");
  const [data, setData] = useState<any>({});
  const profile = useSelector((s) => s.profile);
  const [imageOpen, setImageOpen] = useToggleState();
  const [submitLoading, setSubmitLoading] = useToggleState();
  const [langItems, setLangItems] = useState([]);

  useEffect(() => {
    setLang(profile.companyDefaultLanguage)
  }, [])

  useEffect(() => {
    if (channelInfo) {
      setData(channelInfo);
    } else {
      setData({
        channelCode: "",
        pictureThumbnailUrl: "",
        pictureUrl: "",
        productCategoryChannelTranslates: [
          { language: "de", shortDescription: "" },
          { language: "en", shortDescription: "" },
        ],
      });
    }
  }, [channelInfo,isOpen]);


  useEffect(() => {
    let arr: any = [];
    profile?.companyContentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item })
    })
    setLangItems(arr)
  }, [profile])// eslint-disable-line react-hooks/exhaustive-deps

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const onUploadImage = (image: any) => {
    const channel = { ...data, [imageKey]: image.url };
    setData(channel);
  };

  const findCategoryByLang = () => {
    const obj = data?.productCategoryChannelTranslates?.filter(
      (item: any) => item.language.toLowerCase() === lang
    )[0];
    return obj;
  };

  const onChangeHandler = (value: any, key: string) => {
    const isAvailable = data?.productCategoryChannelTranslates?.filter(
      (tr: any) => tr.language.toLowerCase() === lang
    );
    let clone = [...data?.productCategoryChannelTranslates];
    if (isAvailable.length === 0) {
      clone.push({
        [key]: value,
        language: lang,
        description: "",
      });
    } else {
      data?.productCategoryChannelTranslates?.map((tr: any, index: number) => {
        if (tr.language.toLowerCase() === lang) {
          clone[index][key] = value;
        }
      });
    }
    setData({ ...data, productCategoryChannelTranslates: clone });
  };

  const submitHandler = () => {
    setSubmitLoading();
    const url = URLS.ADD_CATEGORY_TO_CHANNEL(id);
    const body = {
      channelCode: selectedChanel.code,
      pictureThumbnailUrl: data.pictureThumbnailUrl,
      pictureUrl: data.pictureUrl,
      translates: data.productCategoryChannelTranslates,
    };
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        setSubmitLoading();
        getChannelsInfo();
        toggle();
      })
      .catch(() => setSubmitLoading());
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>Channel Setting</Text>
            </span>
            <div className="flex gap-x-2">
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>Close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4 relative">
        <div className="!absolute right-4 top-[1.4rem]">
          <SelectLang value={lang} items={langItems} onChangeLanguage={onChangeLanguage} />
        </div>
      
          <InputGroup
            as={"short-textarea"}
            label="Short Description"
            setValue={(e) => onChangeHandler(e, "shortDescription")}
            value={
              data?.productCategoryChannelTranslates &&
              findCategoryByLang()?.shortDescription
            }
          />
          <Wrapper>
            <Wrapper.Header className="!pl-0 text-heading-5 font-semibold">
              Images
            </Wrapper.Header>
            <Wrapper.Body className="!pl-0 flex space-x-6">
              <div className="space-y-4">
                <span className="text-heading-6 text-gray-800 font-normal">
                  Main Image
                </span>
                <div
                  className="h-[220px] w-[176px] bg-gray-200 flex flex-center rounded-xl cursor-pointer"
                  onClick={() => {
                    setImageOpen();
                    setImageKey("pictureUrl");
                  }}
                >
                  {data?.pictureUrl ? (
                    <Image
                      src={data?.pictureUrl}
                      alt={""}
                      editor
                      imageClassName="h-full"
                      className="aspect-image h-[220px] w-[176px] lg:aspect-auto cursor-pointer"
                    />
                  ) : (
                    <div className="flex flex-col space-y-4">
                      <Icon
                        icon="file-arrow-up"
                        size="3x"
                        className="text-gray-500"
                      />
                      <span className="text-body-base text-gray-600 font-medium">
                        Upload Image
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-4">
                <span className="text-heading-6 text-gray-800 font-normal">
                  Thumbnail
                </span>
                <div
                  className="h-[220px] w-[176px] bg-gray-200 flex flex-center rounded-xl cursor-pointer"
                  onClick={() => {
                    setImageOpen();
                    setImageKey("pictureThumbnailUrl");
                  }}
                >
                  {data?.pictureThumbnailUrl ? (
                    <Image
                      src={data?.pictureThumbnailUrl}
                      alt={""}
                      editor
                      imageClassName="h-full"
                      className="aspect-image h-[220px] w-[176px] lg:aspect-auto cursor-pointer"
                    />
                  ) : (
                    <div className="flex flex-col space-y-4">
                      <Icon
                        icon="file-arrow-up"
                        size="3x"
                        className="text-gray-500"
                      />
                      <span className="text-body-base text-gray-600 font-medium">
                        Upload Image
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Wrapper.Body>
            <ImageUploader
              isOpen={imageOpen}
              toggle={setImageOpen}
              image={data?.[imageKey]}
              onUpload={(data: Media) => onUploadImage(data)}
            />
          </Wrapper>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button size="sm" variant={"light"} onClick={toggle}>
            <Text>Cancel</Text>
          </Button>
          <Button size="sm" onClick={submitHandler} loading={submitLoading}>
            <Text>Submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
};

export default ChannelDrawer;
