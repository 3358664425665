import { NotificationManager } from "common/notifications";
import { Button, Form, Icon, InputGroup, Modal, Text } from "components";
import { flagCountry, rules } from "constant";
import { useToggleState } from "hooks";
import { useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";

type addModalType = {
  isOpen: boolean;
  loading: boolean;
  toggle: () => void;
  selected: string;
  onConfirm: () => void;
};

export default function AddModal({
  isOpen,
  loading,
  toggle,
  selected,
  onConfirm,
}: addModalType) {
  const formRef = useRef<any>();
  const [loadingInput, setLoading] = useToggleState();
  const [data, setData] = useState<any>({
    code: "",
    nameDE: "",
    nameEN: "",
  });

  useEffect(() => {
    if (isOpen) {
      setData({
        code: "",
        nameDE: "",
        nameEN: "",
      })
    }
  }, [isOpen])

  const onChangeHandler = (key: string) => {
    return (value: any) => {
      setData((p: any) => ({ ...p, [key]: value }));
    };
  };

  const onSubmitHandler = (data: any) => {
    setLoading();
    const url = URLS.CREATE_GET_PUT_DELETE_PIM_PRODUCT_FAMILY_URL;
    const body = {
      code: data?.code,
      parentId: data?.selected,
      translates: [
        { language: "de", name: data.nameDE },
        { language: "en", name: data.nameEN },
      ],
    };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        setLoading();
        toggle();
        onConfirm();
      })
      .catch((err) => setLoading());
  };

  const submit = (e?: React.FormEvent) => {
    formRef.current.submitHandler(e, { ...data, selected });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      width="w-[400px]"
      modalClassName="z-40"
    >
      <Modal.Header className="flex text-heading-2 font-semibold">
        <Text>productManagement.masterData.productFamilies.addSubFamiliy</Text>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <div className="col-span-2">
          <Form
            ref={formRef}
            onSubmit={onSubmitHandler}
            id={"add-modal"}
            className="space-y-4"
          >
            <InputGroup
              disabled={loadingInput}
              readOnly={loadingInput}
              value={data?.nameDE}
              setValue={onChangeHandler("nameDE")}
              label="Family Name"
              flag={flagCountry["de"]}
              rules={rules.required}
            />
            <InputGroup
              disabled={loadingInput}
              readOnly={loadingInput}
              value={data?.nameEN}
              setValue={onChangeHandler("nameEN")}
              label="Family Name"
              flag={flagCountry["en"]}
              rules={rules.required}
            />
            <InputGroup
              disabled={loadingInput}
              readOnly={loadingInput}
              value={data?.code}
              setValue={onChangeHandler("code")}
              label="Family Code"
              rules={rules.required}
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          onClick={() => {
            setData({
              code: "",
              nameDE: "",
              nameEN: "",
            });
            toggle();
          }}
          variant="white"
        >
          <Icon className="mr-2" icon={"times"} />
          <Text>
            productManagement.masterData.productFamilies.cancelAddBranch
          </Text>
        </Button>
        <Button type="button" onClick={submit} loading={loadingInput}>
          <Icon className="mr-2" icon={"plus"} />
          <Text>productManagement.masterData.productFamilies.addBranch</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
