import { DatePicker, Icon, Text } from "components";
import { useContext } from "react";
import { AttributeContext } from ".";
import { PimContext } from "..";
import { findValue } from "../../helpers/find-attribute.value";
import { updateValue } from "../../helpers/update-attribute-value";
const DateTime = () => {
  const { channels } = useContext(PimContext);
  const {
    attribute,
    values,
    channel,
    language,
    id,
    setValues,
    flag,
    setAttribute,
    toggle,
    onChangeValue,
    showInChannel,
  } = useContext(AttributeContext);
  const label = attribute.isRequired ? (
    <>
      {showInChannel && (
        <Icon icon="star" size="sm" className="text-primary mr-1" />
      )}
      {attribute.translations?.[0]?.displayName}{" "}
      <Icon icon="star" size="sm" className="text-danger ml-1" />{" "}
    </>
  ) : (
    <>
      {showInChannel && (
        <Icon icon="eye-slash" size="sm" className="text-primary mr-1" />
      )}
      {attribute.translations?.[0]?.displayName}{" "}
    </>
  );

  const { value } = findValue(attribute, values, id, channel, language);

  const onChangeHandler = (e: any) => {
    const changedData = updateValue(
      attribute,
      values,
      id,
      e,
      channel,
      language
    );
    setValues(changedData);
    onChangeValue(attribute, id);
  };

  const perChannelTemplate = (value: any) => {
    return (
      <>
        {channels.map((channel: any) => {
          const changedData = updateValue(
            attribute,
            values,
            id,
            value,
            channel.code,
            language
          );
          setValues(changedData);
          onChangeValue(attribute, id);
        })}
      </>
    );
  };

  let items = [
    {
      title: <Text>productManagement.products.Details.compareValues</Text>,
      id: "compare",
      onClick: () => {
        setAttribute(attribute);
        toggle();
      },
    },
  ];

  if (attribute.perChannel) {
    items.push(
      attribute.perChannel && {
        title: (
          <Text>productManagement.products.Details.setValueForAllChannel</Text>
        ),
        id: "setValue",
        onClick: () => {
          perChannelTemplate(value);
        },
      }
    );
  }

  return (
    <DatePicker
      placeholder={attribute?.translations?.[0]?.placeHolder}
      label={label}
      onChange={onChangeHandler}
      value={value && new Date(value)}
      dateFormat="MM/dd/yyyy h:mm aa"
      showTimeSelect
      flag={attribute?.perLanguage ? flag[language] : null}
      hint={attribute?.perChannel ? { label: channel } : null}
      actions={items}
    />
  );
};

export default DateTime;
