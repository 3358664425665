import SalesTemplatesList from "pages/sales-managment/sell-off-sales/sales-templates"
import Create from "pages/sales-managment/sell-off-sales/sales-templates/create";
import Details from "pages/sales-managment/sell-off-sales/sales-templates/details";

const SalesTemplates = [
  {
    path: "",
    element: <SalesTemplatesList />,
  },
  {
    path: "new",
    element: <Create />,
  },
  {
    path: ":salesTemplateId",
    element: <Details />,
  },
];

export default SalesTemplates;
