import { Fragment, ReactNode } from "react";
import Text from "./Text";
import Wrapper from "./Wrapper";
import Icon from "./Icon";

type StepperItemProps = {
  label: string;
  desc?: string;
  component: ReactNode;
};

type StepperProps = {
  tabs: StepperItemProps[];
  activeTab: number;
};

const Stepper = ({ tabs, activeTab }: StepperProps) => {
  const isCompleted = (index: number) => {
    return index < activeTab;
  };
  return (
    <Wrapper className="col-span-full lg:col-span-4">
      <Wrapper.Body className="space-y-2">
        {tabs.map((e, i) => (
          <Fragment key={e.label}>
            {i !== 0 && (
              <span className="block w-0 h-9 border-l border-dashed border-gray-500 ml-5" />
            )}
            <div
              data-active={activeTab === i}
              className="group w-full flex items-center gap-4"
            >
              <span className="bg-primary-light text-primary flex-center w-10 h-10 rounded group-data-active:bg-primary group-data-active:text-white transition-colors">
                {isCompleted(i) ? <Icon icon="check" /> : i + 1}
              </span>
              <div className="flex-1 space-y-1">
                <h4
                  data-lang-map={e.label}
                  className="w-full truncate text-gray-800 group-data-active:text-primary-active"
                >
                  <Text>{e.label}</Text>
                </h4>
                {e.desc && (
                  <p className="w-full truncate text-body-2 font-medium text-gray-500">
                    <Text>{e.desc}</Text>
                  </p>
                )}
              </div>
            </div>
          </Fragment>
        ))}
      </Wrapper.Body>
    </Wrapper>
  );
};

export default Stepper;
