const REPORTS = {
  KITCHEN_CALCULATIONS_URL:
    "statisticsservice/api/kitchen-calculations/search-kitchen",
  KITCHEN_CALCULATIONS_DETAILS_URL:(id)=>
    `statisticsservice/api/kitchen-calculations/${id}`,
  PENDING_APPROVAL_URL: (id) =>
    `statisticsservice/api/kitchen-calculations/${id}/change-to-pending-approval`,
  STATUS_APPROVED_URL: (id) =>
    `statisticsservice/api/kitchen-calculations/${id}/change-to-approved`,
  SEARCH_SALES_REPORT: `statisticsservice/api/kitchen-calculations/search-kitchen-sales-report`,

  //------------------- Reports Sell Factor --------------
  SELL_FACTOR_URL: "statisticsservice/api/companies/sell-factor",
  TOLERANCE_URL: "statisticsservice/api/companies/tolerance",
};

export default REPORTS;
