import { useContext } from "react";
import { Wrapper, Text } from "components";
import { SellOffProductDetailsContext } from "..";

const Specification = () => {
  const { data } = useContext(SellOffProductDetailsContext);
  return (
    <Wrapper>
      <Wrapper.Header>
        <h2 className="text-gray-800">
          <Text>salesManagment.sellOffSales.sellOffProduct.specification</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body>
        <div className="grid grid-cols-5 gap-2 text-body-2">
          <p className="col-span-1">
            <Text>salesManagment.sellOffSales.sellOffProduct.description</Text>
          </p>
          <p className="col-span-4">{data?.description}</p>
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
};

export default Specification;
