export const allProducts = {
  addProduct: "Add Product",
  skuName: "Product Original Name",
  sku: "Product Original Name",
  article: "Article",
  productFamily: "Product Family", 
  created: "Created",
  modified: "Modified",
  completeness: "Completeness",
  keyword: "Keyword",
  productCategory: "Product Category",
  status: "Status",
  brand: "Brand",
  suppliers: "Suppliers",
  program: "Program",
  store: "Store",
  priceRange: "Price Range",
  to: "To",
  from: "From",
  useAsProductNameInThisDashboard: "Use as product name in this dashboard",
  filters: "Filters",
  resetFilters: "Reset Filters",
  close: "Close",
};
