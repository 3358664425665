import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputGroup,
  Select,
  Text,
} from "components";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { toggleProps } from "types";
import { paramsType } from ".";
import { useTranslate } from "hooks";
type filterDrawerProps = toggleProps & {
  params: paramsType;
  setParams: Dispatch<SetStateAction<paramsType>>;
};
type formDataType = {
  year: string;
  quarter: string;
  dateFrom: Date;
  dateTo: Date;
};
export default function FilterDrawer({
  isOpen,
  toggle,
  params,
  setParams,
}: filterDrawerProps) {
  const translate = useTranslate();
  const [data, setData] = useState<formDataType>({
    year: params.dateTo.getFullYear().toString(),
    quarter: "",
    dateFrom: params.dateFrom,
    dateTo: params.dateTo,
  });
  const yearItems = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return [0, 1, 2, 3]
      .map((index) => currentYear - index)
      .map((e) => ({
        name: `${e}`,
        id: `${e}`,
      }));
  }, []);
  const quarterItems = useMemo(() => {
    return [0, 1, 2, 3, 4].map((e) => ({
      name: !e
        ? "salesManagment.kitchenSales.salesReport.all"
        : translate("salesManagment.kitchenSales.salesReport.quarterItem", {
            value: e,
          }),
      id: !e ? "" : `${e}`,
    }));
  }, []);
  const twoDigit = (num: number) => {
    return num < 10 ? `0${num}` : `${num}`;
  };
  const handleSetValue = (key: keyof formDataType) => {
    return (value: any) => {
      const isYear = key === "year";
      const isQuarter = key === "quarter";
      const hasQuarterValue = isQuarter && !!value;
      const quarterMonth = hasQuarterValue
        ? [(value - 1) * 3 + 1, value * 3].map(twoDigit)
        : ["01", "12"];
      setData((p) => ({
        ...p,
        [key]: value,
        ...(isYear && {
          dateFrom: new Date(`${value}-01-01`),
          dateTo: new Date(`${value}-12-30`),
        }),
        ...(hasQuarterValue && {
          dateFrom: new Date(`${p.year}-${quarterMonth[0]}-01`),
          dateTo: new Date(`${p.year}-${quarterMonth[1]}-30`),
        }),
      }));
    };
  };

  const submit = () => {
    setParams((p) => ({ ...p, dateFrom: data.dateFrom, dateTo: data.dateTo }));
    toggle();
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={submit}>
        <Drawer.Menu>
          <Drawer.Header>
            <h2>
              <Text>salesManagment.kitchenSales.salesReport.date</Text>
            </h2>
          </Drawer.Header>
          <Drawer.Body className="space-y-10">
            <section className="space-y-6">
              <Select
                label="salesManagment.kitchenSales.salesReport.year"
                value={data.year}
                setValue={handleSetValue("year")}
                items={yearItems}
              />
              <Select
                label="salesManagment.kitchenSales.salesReport.quarter"
                value={data.quarter}
                setValue={handleSetValue("quarter")}
                items={quarterItems}
              />
            </section>
            <fieldset className="grid grid-cols-2 gap-6 border-0 border-t border-gray-200 pt-6">
              <legend className="w-fit h6 text-gray-800 pe-4">
                <Text>salesManagment.kitchenSales.salesReport.customDate</Text>
              </legend>
              <DatePicker
                label="salesManagment.kitchenSales.salesReport.from"
                value={data.dateFrom}
                onChange={handleSetValue("dateFrom")}
              />
              <DatePicker
                label="salesManagment.kitchenSales.salesReport.to"
                value={data.dateTo}
                onChange={handleSetValue("dateTo")}
              />
            </fieldset>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button variant="light" onClick={toggle}>
              <Text>salesManagment.kitchenSales.salesReport.cancel</Text>
            </Button>
            <Button type="submit" variant="primary">
              <Text>salesManagment.kitchenSales.salesReport.apply</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
