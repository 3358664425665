import {
  Drawer,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
} from "components";
import { useConverter } from "hooks";
import { useEffect, useState } from "react";
import { ServiceApi } from "services";
import { toggleProps, channelProductType } from "types";
type selectProductProps = toggleProps & {
  onSelect: (product: channelProductType) => void;
};
type dataType = {
  items: channelProductType[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};
export default function SelectProduct({
  isOpen,
  toggle,
  onSelect,
}: selectProductProps) {
  const { convertAmount } = useConverter();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} as dataType);
  const [params, setParams] = useState({
    pageSize: 5,
    pageNumber: 1,
    channelCode: "digitalstorefront",
    cache: true,
  });
  const handleSetValue = (product: channelProductType) => {
    return () => {
      onSelect(product);
      toggle();
    };
  };
  const getProducts = () => {
    if (!isOpen) return;
    setLoading(true);
    const url =
      "/productservice/api/channelproducts/get-channelproduct-by-channelcode";
    const config = { params };
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getProducts, [isOpen, params]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="!z-40" size="xl">
      <Drawer.Menu>
        <Drawer.Header>
          <h5 className="text-start">
            <Text>applications.infoPage.productAddTitle</Text>:
          </h5>
        </Drawer.Header>
        <Drawer.Header className="bg-gray-100 border-b-0">
          <SearchBox
            onSubmit={(keyword) => setParams((p) => ({ ...p, keyword }))}
            totalItems={data.totalItems}
          />
        </Drawer.Header>
        <Drawer.Body>
          {loading ? (
            <div className="space-y-4">
              <Skeleton.ImageList />
              <Skeleton.ImageList />
              <Skeleton.ImageList />
            </div>
          ) : (
            <Table shadow>
              {data.items?.map((e) => (
                <tr
                  key={e.id}
                  onClick={handleSetValue(e)}
                  className="cursor-pointer"
                >
                  <td>
                    <div className="flex items-center gap-4">
                      <Table.Image
                        src={e.mediaList?.at(0)?.thumbnailUrl}
                        alt={e.sku}
                      />
                      <div className="flex-1">
                        <h5 className="block w-full max-w-md truncate text-gray-800">
                          {e.sku ?? "--"}
                        </h5>
                        <p className="block w-full max-w-md truncate text-body-1 text-gray-600">
                          {e.articleNumber}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>{convertAmount(e.price?.amount)}</td>
                </tr>
              ))}
            </Table>
          )}
        </Drawer.Body>
        <Drawer.Footer>
          <Pagination
            pageNumber={params.pageNumber}
            pageSize={params.pageSize}
            totalItems={data.totalItems}
            totalPages={data.totalPages}
            setActivePage={(value) =>
              setParams((p) => ({ ...p, pageNumber: +value }))
            }
          />
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
