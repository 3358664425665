import UserPlaceHolder from "assets/image/UserPlaceholder.svg";
import { Button, Icon, NoItems, Table, Text, Wrapper } from "components";
import { useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { convertAddress } from "utils";
import { SuppliersContext } from "..";
import ContactPersonDrawer from "./contactPersonDrawer";

export default function ContactPerson() {
  const { suppliersData, getData, supplierLoading } =
    useContext<any>(SuppliersContext);
  const [contactDrawer, toggle] = useToggleState();
  const [selectedPerson, setSelectedPerson] = useState({});
  const [contactPerson, setContactPerson] = useState<any>([]);

  useEffect(() => {
    setContactPerson(suppliersData?.contactPersons);
  }, [suppliersData]);

  const departmentItems = [
    {
      id: 0,
      name: "General",
    },
    {
      id: 1,
      name: "Sales",
    },
    {
      id: 2,
      name: "Logistics",
    },
    {
      id: 3,
      name: "Marketing",
    },
    {
      id: 4,
      name: "Support",
    },
    {
      id: 10,
      name: "Other",
    },
  ];

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <div className="flex items-center gap-4">
            {/* <div className="w-[18rem]">
          {loading ? (
            <Skeleton.Input />
          ) : (
            <div
              className={`w-full text-body-2 font-medium flex-center gap-2 rounded px-2 py-1 bg-white focus-within:ring-1 ring-gray-500 ring-offset-1 transition-shadow`}
            >
              <button type="button" onClick={(e: any) => onClickIcon(e)}>
                <Icon icon="search" />
              </button>
              <input
                onKeyUp={submitSearch}
                value={value}
                onChange={handleChangeSearch}
                placeholder={translate("global.placeholders.search") ?? ""}
                className="flex-1 h-8 px-2"
              />
              {submitted && (
                <button
                  type="button"
                  onClick={(e: any) => clearValueSearch(e)}
                  className="bi bi-x-lg text-dark hover:text-danger transition-colors"
                />
              )}
            </div>
          )}
        </div>
        <TotalItems totalItems={totalItems} /> */}
          </div>
        </div>
        <Button
          size="sm"
          type="button"
          onClick={() => {
            setSelectedPerson({});
            toggle();
          }}
        >
          <Icon className="mr-2" icon={"plus"} />
          <Text>productManagement.masterData.suppliers.addContactPerson</Text>
        </Button>
      </div>
      {!supplierLoading && contactPerson?.length > 0 ? (
        <>
          {contactPerson?.map((person: any) => {
            return (
              <Wrapper>
                <div className="p-4">
                  <Table>
                    <tr>
                      <td className="w-[600px]">
                        <div className="flex gap-4">
                          <Table.Image
                            src={UserPlaceHolder}
                            className="h-[78px] w-[78px]"
                          />
                          <div className="space-y-3">
                            <span className="text-heading-6 font-semibold">
                              {person?.firstName + " " + person?.lastName}
                            </span>
                            <p className="text-body-2">
                              <Icon icon="diagram-subtask" className="mr-1" />{" "}
                              Department :{" "}
                              {
                                departmentItems.filter(
                                  (dep: any) => dep.id === person?.department
                                )?.[0]?.name
                              }
                            </p>
                            <p className="text-body-2">
                              <Icon icon="location-dot" className="mr-1" />{" "}
                              {convertAddress(person?.address)}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="w-[500px] text-left">
                        <div className="space-y-3">
                          {person?.phoneNumber1 && (
                            <p className="text-body-2">
                              <Icon icon="phone" className="mr-1" />{" "}
                              {person.phoneNumber1}
                            </p>
                          )}
                          {person?.phoneNumber2 && (
                            <p className="text-body-2">
                              <Icon icon="phone" className="mr-1" />
                              {person.phoneNumber2}
                            </p>
                          )}
                          {person?.emailAddress1 && (
                            <p className="text-body-2">
                              <Icon icon="envelope" className="mr-1" />
                              {person.emailAddress1}
                            </p>
                          )}
                          {person?.emailAddress2 && (
                            <p className="text-body-2">
                              <Icon icon="envelope" className="mr-1" />
                              {person.emailAddress2}
                            </p>
                          )}
                        </div>
                      </td>
                      <td className="w-[200px] text-left">
                        <p className="text-body-2">{person?.description}</p>
                      </td>
                      <td>
                        <Button
                          type="button"
                          size="sm"
                          light
                          onClick={() => {
                            setSelectedPerson(person);
                            toggle();
                          }}
                        >
                          <Icon icon="edit" />
                        </Button>
                      </td>
                    </tr>
                  </Table>
                </div>
              </Wrapper>
            );
          })}
        </>
      ) : (
        <NoItems></NoItems>
      )}
      <ContactPersonDrawer
        isOpen={contactDrawer}
        toggle={toggle}
        person={selectedPerson}
        id={suppliersData?.supplierId}
        getData={getData}
      />
    </div>
  );
}
