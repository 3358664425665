import { Button, Drawer, Icon, Table, Text } from "components";
import { toggleProps } from "types";
import { componentType } from "..";
import { Fragment } from "react";
import CarouselForm from "./CarouselForm";
import { useToggleState } from "hooks";
import CarouselItem from "./CarouselItem";
type editCarouselComponentProps = toggleProps & componentType;
export default function EditCarouselComponent({
  isOpen,
  toggle,
  id,
  items,
  title,
}: editCarouselComponentProps) {
  const [isFormOpen, toggleForm] = useToggleState(false);
  return (
    <Fragment>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header>
            <h2 className="block text-start text-gray-800">{title}</h2>
          </Drawer.Header>
          <Drawer.Body>
            <Table>
              {items?.map((e) => (
                <CarouselItem key={e.id} item={e} componentId={id} />
              ))}
            </Table>
            <button
              type="button"
              className="h4 block bg-primary-light text-gray-800 border border-dashed border-primary w-full rounded p-6"
              onClick={toggleForm}
            >
              <Icon icon="plus" className="text-primary" />{" "}
              <Text>applications.positions.addItemButton</Text>
            </button>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button type="button" variant="light" size="sm" onClick={toggle}>
              <Text>applications.positions.carouselCancelButton</Text>
            </Button>
            <Button type="button" variant="primary" size="sm" onClick={toggle}>
              <Text>applications.positions.carouselSubmitButton</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <CarouselForm isOpen={isFormOpen} toggle={toggleForm} componentId={id} />
    </Fragment>
  );
}
