const Notifications = [
  {
    path: "alerts",
    element: <>alerts</>,
  },
  {
    path: "action-groups",
    element: <>action-groups</>,
  },
];

export default Notifications;
