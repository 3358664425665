import { Icon, Text, TextEditor } from "components";
import { useToggleState } from "hooks";
import { useContext } from "react";
import { AttributeContext } from ".";
import { PimContext } from "..";
import { findValue } from "../../helpers/find-attribute.value";
import { updateValue } from "../../helpers/update-attribute-value";
import AiGeneratorDrawer from "../drawers/ai-generator";
const TextArea = () => {
  const { channels, productData } = useContext(PimContext);
  const {
    attribute,
    values,
    channel,
    language,
    id,
    setValues,
    flag,
    setAttribute,
    toggle,
    onChangeValue,
    showInChannel,
  } = useContext(AttributeContext);

  const [aiToggle, setAiToggle] = useToggleState();

  const label = attribute.isRequired ? (
    <>
      {showInChannel && (
        <Icon icon="star" size="sm" className="text-primary mr-1" />
      )}
      {attribute.translations?.[0]?.displayName}{" "}
      <Icon icon="star" size="sm" className="text-danger ml-1" />{" "}
    </>
  ) : (
    <>
      {showInChannel && (
        <Icon icon="eye-slash" size="sm" className="text-primary mr-1" />
      )}
      {attribute.translations?.[0]?.displayName}{" "}
    </>
  );

  const { value } = findValue(attribute, values, id, channel, language);

  const onChangeHandler = (e: any) => {
    let value = e === "<p><br></p>" ? "" : e;
    const changedData = updateValue(
      attribute,
      values,
      id,
      value,
      channel,
      language
    );
    setValues(changedData);
    onChangeValue(attribute, id);
  };

  const perChannelTemplate = (value: any) => {
    return (
      <>
        {channels.map((channel: any) => {
          const changedData = updateValue(
            attribute,
            values,
            id,
            value,
            channel.code,
            language
          );
          setValues(changedData);
          onChangeValue(attribute, id);
        })}
      </>
    );
  };

  let items = [
    {
      title: <Text>productManagement.products.Details.compareValues</Text>,
      id: "compare",
      onClick: () => {
        setAttribute(attribute);
        toggle();
      },
    },
  ];

  if (attribute.perChannel) {
    items.push(
      attribute.perChannel && {
        title: (
          <Text>productManagement.products.Details.setValueForAllChannel</Text>
        ),
        id: "setValue",
        onClick: () => {
          perChannelTemplate(value);
        },
      }
    );
  }

  return (
    <>
      <TextEditor
        label={label}
        value={value}
        setValue={onChangeHandler}
        flag={attribute?.perLanguage ? flag[language] : null}
        hint={attribute?.perChannel ? { label: channel } : null}
        actions={items}
        withAi={
          attribute.code === "puls_short_description" ||
          attribute.code === "puls_description"
        }
        onClickAI={setAiToggle}
      />
      <AiGeneratorDrawer
        isOpen={aiToggle}
        toggle={setAiToggle}
        name={productData?.sku}
        setValue={onChangeHandler}
      />
    </>
  );
};

export default TextArea;
