import { NotificationManager } from "common/notifications";
import {
    Button,
    DatePicker,
    Drawer,
    Form,
    Icon,
    InputGroup,
    Text
} from "components";
import { rules } from "constant";
import { useDataState } from "hooks";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ServiceApi, URLS } from "services";


type updateModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: (value: any) => void;
  selected: any;
  supplierId: any;
};

export default function UpdateModal(props: updateModalType) {
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<any>();
  const [data, setData] = useDataState({
    title: "",
    startDate: null,
    endDate: null,
    lastOrderDate: null,
    earliestShipDate: null,
    holidayId: null

  });


  useEffect(() => {
    if (props.isOpen) {
      setData({
        title: props?.selected?.title,
        startDate: props?.selected?.startDate,
        endDate: props?.selected?.endDate,
        lastOrderDate: props?.selected?.lastOrderDate,
        earliestShipDate: props?.selected?.earliestShipDate,
        holidayId: props?.selected?.holidayId

      })
    }
  }, [props.isOpen])


  const onSubmitForm = (formData: any) => {
    setLoading(true);
    const url = URLS.PUT_HOLIDAYS_SUPPLIERS(formData.holidayId);
    ServiceApi.put(url, formData).then(({ }) => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
      props.toggle(false);
      props.getData({ supplierId: props?.supplierId });
    }).finally(() => {
      setLoading(false);
    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };



  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="!text-left">
          <h2><Text>productManagement.masterData.suppliers.holiday</Text></h2>
        </Drawer.Header>
        <Drawer.Body >
          <Form ref={formRef} onSubmit={onSubmitForm} id={"update-modal"} className="grid grid-cols-1 lg:grid-cols-1 py-8 gap-8">
            <InputGroup
              label="productManagement.masterData.suppliers.title"
              value={data.title}
              setValue={(title) => setData((p) => ({ ...p, title }))}
              rules={rules.required}
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <DatePicker
                showYearDropdown
                showMonthDropdown
                selectsStart
                selected={data.startDate !== null ? new Date(data.startDate) : null}
                startDate={data.startDate !== null ? new Date(data.startDate) : null}
                endDate={data.endDate !== null ? new Date(data.endDate) : null}
                label="productManagement.masterData.suppliers.startDate"
                value={data.startDate !== null ? new Date(data.startDate) : null}
                onChange={(startDate: any) => setData((p) => ({ ...p, startDate }))}
              />
              <DatePicker
                showYearDropdown
                showMonthDropdown
                selectsEnd
                selected={data.endDate !== null ? new Date(data.endDate) : null}
                startDate={data.startDate !== null ? new Date(data.startDate) : null}
                endDate={data.endDate !== null ? new Date(data.endDate) : null}
                minDate={data.startDate !== null ? new Date(data.startDate) : null}
                label="productManagement.masterData.suppliers.endDate"
                value={data.endDate !== null ? new Date(data.endDate) : null}
                onChange={(endDate: any) => setData((p) => ({ ...p, endDate }))}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <DatePicker
                showYearDropdown
                showMonthDropdown
                label="productManagement.masterData.suppliers.lastOrderDate"
                value={data.lastOrderDate !== null ? new Date(data.lastOrderDate) : null}
                onChange={(lastOrderDate: any) => setData((p) => ({ ...p, lastOrderDate }))}
              />
              <DatePicker
                showYearDropdown
                showMonthDropdown
                minDate={data.lastOrderDate !== null ? new Date(data.lastOrderDate) : null}
                label="productManagement.masterData.suppliers.earliestShipDate"
                value={data.earliestShipDate !== null ? new Date(data.earliestShipDate) : null}
                onChange={(earliestShipDate: any) => setData((p) => ({ ...p, earliestShipDate }))}
              />
            </div>
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button type="button" className="mr-2" onClick={props.toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loading}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}