import { Fragment, useContext } from "react";
import cloneDeep from "lodash/cloneDeep";
import { InputGroup, Text, Wrapper } from "components";
import { StoreContext } from ".";

export default function Setting() {
  const { storeData, setStoreData } = useContext(StoreContext);
  const handleChangeGeneral = (key) => {
    return (value) =>
      setStoreData((p) => {
        const data = cloneDeep(p);
        data.generalSettings[key] = value;
        return data;
      });
  };
  const handleChangeSales = (key) => {
    return (value) =>
      setStoreData((p) => {
        const data = cloneDeep(p);
        data.salesSettings[key] = value;
        return data;
      });
  };
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Header>
          <h2>
            <Text>company.stores.generalSetting</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body>
          <div className="s-full lg:w-1/2">
            <InputGroup
              label="company.stores.iln"
              value={storeData.generalSettings.iln}
              setValue={handleChangeGeneral("iln")}
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <h2>
            <Text>company.stores.salesSetting</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body>
          <div className="s-full lg:w-1/2 space-y-8">
            <InputGroup
              label="company.stores.iwofurnSalesCalculationFactor"
              value={storeData.salesSettings.iwofurnSalesCalculationFactor}
              setValue={handleChangeSales("iwofurnSalesCalculationFactor")}
              type="number"
            />
            <InputGroup
              label="company.stores.iwofurnRoundingMethod"
              value={storeData.salesSettings.iwofurnRoundingMethod}
              setValue={handleChangeSales("iwofurnRoundingMethod")}
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
    </Fragment>
  );
}
