import { useState, useEffect, useRef, FormEvent, Fragment } from "react";
import {
  Form,
  Button,
  Text,
  Icon,
  MultiSelect,
  Drawer,
  InputGroup,
  Skeleton
} from "components";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";
import { config, rules } from "constant";

export default function Edit(props: any) {
  const [loadingList, setLoadingList] = useState(true);
  const [data, setData] = useState<any>({})
  const [chooseRoleItems, setChooseRoleItems] = useState([])
  const [loadingButton, setLoadingButton] = useState(false);
  const [params] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const formRef = useRef<any>();

  useEffect(() => {
    getRoleItems();
    if (props.isOpen) {
      getData();
    }
  }, [props.isOpen]);// eslint-disable-line react-hooks/exhaustive-deps


  const getRoleItems = () => {
    const urlRoles = URLS.GET_ROLES_URL;
    const config = { params };
    ServiceApi.get(urlRoles, config).then(({ data }) => {
      setChooseRoleItems(data.items);
    }).finally(() => {
      setLoadingList(false);
    });
  }

  const getData = () => {
    setLoadingList(true);
    const url = URLS.GET_DETAILS_USER_GROUP_URL(props.selected);
    ServiceApi.get(url)
      .then(({ data }) => {
        let result = JSON.parse(JSON.stringify(data));
        result.roles?.forEach((item: any, index: string) => {
          result.roles[index] = item.id
        })
        setData(result);
      })
      .finally(() => {
        setLoadingList(false);
      });

  }


  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };


  const onSubmitForm = (formData: any) => {
    setLoadingButton(true);
    const url = URLS.PUT_USER_GROUP_DETAIL_URL(formData.id);
    formData.roleIds = formData.roles;
    ServiceApi.put(url, formData).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
      props.toggle()
    }).catch(() => setLoadingButton(false));
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <h2 className="text-gray-800 text-heading-2"><Text>configurations.userManagment.userGroups.addGroup</Text></h2>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                size="sm"
                className="ml-2"
                onClick={() => props.toggle()}
              >
                <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4">
          <Form ref={formRef} className="space-y-4 pr-4" onSubmit={onSubmitForm} id={"edit-modal"}>
            {loadingList ?
              <Fragment>
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
              </Fragment>
              :
              <>
                <InputGroup
                  label="configurations.userManagment.userGroups.name"
                  value={data.name}
                  setValue={handleSetData("name")}
                  rules={rules.required}
                />
                <InputGroup
                  as="short-textarea"
                  label="configurations.userManagment.userGroups.description"
                  value={data.description}
                  setValue={handleSetData("description")}
                  rules={rules.required}

                />
                <MultiSelect
                  label="configurations.userManagment.userGroups.chooseRole"
                  items={chooseRoleItems}
                  value={data.roles}
                  setValue={(value) => {
                    return setData((p: any) => ({ ...p, roles: value.length !== 0 ? value?.map((val: any) => val.id) : [] }));
                  }}
                />
              </>
            }
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button type="button" className="mr-2" onClick={() => props.toggle()} variant="light">
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingButton}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
