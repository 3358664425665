import { createSlice } from "@reduxjs/toolkit";

const selloff = createSlice({
    name: "selloff",
    initialState: {} as any,
    reducers: {
        setSellOff: (state, action) => {
            return action.payload;
        },
    },
});

export const { setSellOff } = selloff.actions;
export default selloff.reducer;
