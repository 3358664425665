export const attributesGroups = {
  addAttributeGroups: "Add attribute Groups",
  code: "Code",
  name: "Name",
  title: "Title",
  description: "Description",
  perChannel: "Per Channel",
  type: "Type",
  attributes: "Attributes",
  attributeType: "Attribute Type",
  attributeTypeDesc: "Text, Date, Yes/No ...",
  infoAndProperties: "Info and properties",
  infoAndPropertiesDesc: "Name, Description ...",
  review: "Review",
  reviewDesc: "Finalize information",
  attributeName: "Attribute Name",
  attributeCode: "Attribute Code",
  groupName: "Group Name",
  groupCode: "Group Code",
  shortDescription: "Short Description",
  isRequired: "Is Required",
  perLanguage: "Per Language",
  chooseAttributeType: "Choose Attribute type",
  basicInfo: "Basic info",
  properties: "Properties",
  general: "General",
  channels: "Channels",
  addToGroup: "Add To Group",
  selectAttribute: "Select Attribute",
  updatefromProviderAttribute: "Update from provider attribute",
  searchAttribute: "Search Attribute",
  value: "Value",
};
