import { classNames, checkPriceAvailability } from "utils";
import { Text } from "components";
import { useConverter } from "hooks";

const PriceTemplate = ({ item, className, inDetails = false }: any) => {
  const { convertAmount } = useConverter();
  const originalPriceClassName = classNames(
    "text-gray-500 font-semibold text-heading-4",
    {
      "line-through": item?.currentPrice || item?.currentPrice === 0,
      "text-gray-700": !item?.currentPrice || item?.currentPrice === 0,
    }
  );
  return (
    <div className={`${className} text-left`}>
      <div className={originalPriceClassName}>
        {/* {checkPriceAvailability(item?.originalPrice)} */}
        {item?.originalPrice < 0 ? "--" : convertAmount(item?.originalPrice)}
      </div>
      {item?.currentDiscount && (
        <p className="text-danger">{item?.currentDiscount}%</p>
      )}
      {item?.currentPrice && (
        <>
          {inDetails ? (
            <h2 className={"text-primary-active"}>
              {checkPriceAvailability(item?.currentPrice)}
            </h2>
          ) : (
            <h6 className={"text-primary-active"}>
              {checkPriceAvailability(item?.currentPrice)}
            </h6>
          )}
        </>
      )}

      <div>
        {item?.currentPrice && (
          <>
            {inDetails ? (
              <h2
                className={"text-primary-active font-semibold text-heading-2"}
              >
                {/* {checkPriceAvailability(item?.currentPrice)} */}
                {item?.currentPrice < 0
                  ? "--"
                  : convertAmount(item?.currentPrice)}
              </h2>
            ) : (
              <h6
                className={"text-primary-active font-semibold text-heading-2"}
              >
                {/* {checkPriceAvailability(item?.currentPrice)} */}
                {item?.currentPrice < 0
                  ? "--"
                  : convertAmount(item?.currentPrice)}
              </h6>
            )}
          </>
        )}
      </div>
      {item?.isSold && !inDetails && (
        <div className="text-body-3 font-normal">
          <Text>salesManagment.sellOffSales.sellOffProduct.soldPrice</Text>:{" "}
          <span className="text-success block mt-1 text-heading-6 font-semibold">
            {" "}
            {/* {checkPriceAvailability(item?.realSoldPrice)} */}
            {item?.realSoldPrice < 0
              ? "--"
              : convertAmount(item?.realSoldPrice)}
          </span>
        </div>
      )}
      {item?.isSold && (
        <div className="text-heading-6 text-gray-500">
          <Text>salesManagment.sellOffSales.sellOffProduct.soldPrice</Text> :{" "}
          <span className="text-heading-2 text-success font-semibold">
            {/* {checkPriceAvailability(data?.realSoldPrice)} */}
            {item?.realSoldPrice < 0
              ? "--"
              : convertAmount(item?.realSoldPrice)}
          </span>
        </div>
      )}
    </div>
  );
};

export default PriceTemplate;
