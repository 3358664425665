import { NotificationManager } from "common/notifications";
import {
    Button,
    DeleteModal,
    Drawer,
    Dropdown,
    Icon,
    Image,
    InputGroup,
    SelectLang,
    Text,
    Toggler
} from "components";
import { appPermissions, flagCountry } from "constant";
import { usePermission, useSelector, useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { PimContext } from "..";
import ImageMetaData from "./image-metadata";
type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  image: any;
  getMedias: any;
};

const ImageSettingDrawer = ({
  isOpen,
  toggle,
  image,
  getMedias,
}: drawerProps) => {
  const { i18n } = useTranslation();
  const { channels, getPIMProduct, productData } = useContext(PimContext);
  const profile = useSelector((s) => s.profile);
  const [deletModal, toggleDelete] = useToggleState();
  const [metaDataModal, toggleMetaData] = useToggleState();
  const [removeLoading, setRemoveLoading] = useToggleState();
  const [submitLoading, setSubmitLoading] = useToggleState();
  const [coverLoading, setCoverLoading] = useToggleState();
  const [toggler, setToggler] = useToggleState();

  const [lang, setLang] = useState<string>("de");
  const [channelsCodeList, setChannelCodeList] = useState<any>([]);
  const [mediaFields, setMediaFields] = useState<any>([]);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [langItems, setLangItems] = useState([]);

  useEffect(() => {
    if (image?.publishForAllChannels) {
      let clone = channels?.map((channel: any) => channel.code);
      setChannelCodeList(clone);
    } else {
      setChannelCodeList(image?.channelsCodeList);
    }
    // console.log("effect", image);
    setSelectedChannel(channels?.[0]?.code);
    setMediaFields(image?.mediaFields);
  }, [image]);


  useEffect(() => {
    let arr: any = [];
    profile?.companyContentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item })
    })
    setLangItems(arr)
  }, [profile])// eslint-disable-line react-hooks/exhaustive-deps


 

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const togglerValue = (code: string) => {
    return channelsCodeList?.includes(code);
  };

  const onTogglerChangeHandler = (value: boolean, code: string) => {
    let clone = [...channelsCodeList];
    if (value) {
      clone.push(code);
    } else {
      clone = clone.filter((channelCode: string) => channelCode !== code);
    }
    setChannelCodeList(clone);
  };

  const findTranslatedName = (channel: any) => {
    return channel?.channelNameTranslates?.filter(
      (tr: any) => tr.language.toLowerCase() === i18n.language
    )?.[0]?.name;
  };

  const findInputValue = (key: string) => {
    const fieldObject = mediaFields?.filter(
      (field: any) =>
        field.language === lang && field.channelCode === selectedChannel
    );
    if (fieldObject?.length) {
      return fieldObject[0][key];
    }
  };

  const onChangeHandler = (value: string, key: string) => {
    const fieldObject = mediaFields?.filter(
      (field: any) =>
        field.language === lang && field.channelCode === selectedChannel
    );
    if (fieldObject?.length) {
      const index = mediaFields.findIndex((object: any) => {
        return (
          object.language === lang && object.channelCode === selectedChannel
        );
      });

      let clone = fieldObject[0];
      clone[key] = value;

      let newMediaFields = [...mediaFields];
      newMediaFields[index] = clone;

      setMediaFields(newMediaFields);
    }
  };

  const onSubmit = () => {
    setSubmitLoading();
    const url = URLS.UPDATE_PIM_PRODUCT_MEDIA_URL(image?.id);
    // console.log(channelsCodeList);
    const body = {
      ...image,
      mediaFields,
      channelsCodeList,
      publishForAllChannels: channelsCodeList?.length === channels?.length,
    };
    ServiceApi.put(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        setSubmitLoading();
        getMedias();
        toggle();
      })
      .catch(() => setSubmitLoading());
  };

  const onDeleteHandler = () => {
    setRemoveLoading();
    const url = URLS.UPDATE_PIM_PRODUCT_MEDIA_URL(image?.id);
    ServiceApi.delete(url).then(() => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      setRemoveLoading();
      toggleDelete();
      getMedias();
      toggle();
    });
  };

  const onUploadHandler = (image: any) => {
    setCoverLoading();
    const url = URLS.UPDATE_PIM_PRODUCT_AVATAR_URL(productData?.id);
    ServiceApi.patch(url, image)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        getPIMProduct();
        setCoverLoading();
      })
      .catch(() => setCoverLoading());
    const urlPimProductMedias = URLS.UPDATE_PIM_PRODUCT_MEDIA_URL(image?.id);
    image["tags"] = ["CoverPhoto"];
    ServiceApi.put(urlPimProductMedias, image)
      .then(() => { })
      .catch(() => setCoverLoading());
  };

  //permissions

  const { PS_RemovePimProductMedia } = appPermissions;

  const REMOVE_PERMISSION = usePermission(PS_RemovePimProductMedia);

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.Details.imageSetting</Text>
            </span>
            <div className="flex gap-x-2">
              {REMOVE_PERMISSION && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={toggleDelete}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>productManagement.products.Details.deleteImage</Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-6 pr-4">
          <Image
            src={image?.url}
            className="max-w-lg cursor-pointer bg-gray-100"
            imageClassName=" object-contain"
            onClick={() => {
              document.body.style.overflow = "hidden";
              setToggler();
            }}
            info
            setCover
            onInfoClick={toggleMetaData}
            onSetCover={() => onUploadHandler(image)}
            setCoverLoading={coverLoading}
          />

          {/* Channels */}
          <h5 className="text-gray-800">
            <Text>
              productManagement.products.Details.imagePublicationInChannels
            </Text>
          </h5>
          {channels?.map((channel: any) => {
            return (
              <div className="flex bg-white shadow-card rounded-xl p-4 justify-between">
                <span>
                  {
                    channel?.channelNameTranslates?.filter(
                      (tr: any) => tr.language.toLowerCase() === i18n.language
                    )?.[0]?.name
                  }
                </span>
                <div>
                  <Toggler
                    size="sm"
                    value={togglerValue(channel?.code)}
                    setValue={(e) => {
                      onTogglerChangeHandler(e, channel?.code);
                    }}
                  />
                </div>
              </div>
            );
          })}
          {/* DropDowns */}
          <div className="flex justify-between items-center !mt-8">
            <h5 className="text-gray-800">
              <Text>productManagement.products.Details.imageInfo</Text>
            </h5>
            <div className="space-x-4 flex">
              <Dropdown onSelect={onChangeLanguage}>
                <Dropdown.Toggle
                  as="button"
                  type="button"
                  className="text-gray-600 font-medium text-body-base uppercase flex space-x-2 bg-gray-200 p-2 rounded-md"
                >
                  <span>
                    {findTranslatedName(
                      channels.filter(
                        (channel: any) => channel?.code === selectedChannel
                      )?.[0]
                    )}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="min-w-fit mt-2 p-2">
                  <ul className="text-gray-700 w-max">
                    {channels.map((channel: any) => (
                      <li
                        key={channel?.code}
                        onClick={() => setSelectedChannel(channel.code)}
                        className={`py-2 font-medium first:mb-1 px-4 h6 ${channel.code === selectedChannel &&
                          "bg-primary-light text-primary "
                          } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                      >
                        {findTranslatedName(channel)}
                      </li>
                    ))}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <SelectLang value={lang} items={langItems} onChangeLanguage={onChangeLanguage} />
            </div>
          </div>
          <InputGroup
            label="productManagement.products.Details.placeHolder"
            flag={flagCountry[lang]}
            value={findInputValue("placeHolder")}
            setValue={(e) => onChangeHandler(e, "placeHolder")}
          />
          <InputGroup
            as={"short-textarea"}
            label="productManagement.products.Details.description"
            flag={flagCountry[lang]}
            value={findInputValue("description")}
            setValue={(e) => onChangeHandler(e, "description")}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex justify-end">
          <Button size="sm" onClick={onSubmit} loading={submitLoading}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
      <DeleteModal
        isOpen={deletModal}
        toggle={toggleDelete}
        selected={{ name: "Media" }}
        onConfirm={onDeleteHandler}
        loading={removeLoading}
      />
      <ImageMetaData
        isOpen={metaDataModal}
        toggle={toggleMetaData}
        metaData={image?.mediaMetadata}
      />
      <Lightbox
        open={toggler}
        index={0}
        close={() => {
          document.body.style.overflow = "visible";
          setToggler();
        }}
        slides={[{ src: image?.url }]}
        plugins={[Zoom]}
      />
    </Drawer>
  );
};

export default ImageSettingDrawer;
