export const sellOffProduct = {
  startProccess: "Start Proccess",
  changePhase: "Change Phase",
  closeProccess: "Close Proccess",
  deleteProduct: "Delete Product",
  articleNumber: "Article Number",
  soldPrice: "Sold Price",
  withdraw: "Withdraw",
  sold: "Sold",
  overview: "Overview",
  saleProccess: "Sale Proccess",
  gallery: "Gallery",
  addPhoto: "Add Photo",
  edit: "Edit",
  specification: "Specification",
  description: "Description",
  subProducts: "Sub Products",
  addSubProduct: "Add Sub Product",
  currentPhase: "Current Phase",
  nextPhase: "Next Phase",
  lastDay: "Last Day",
  days: "Days",
  salesTimeline: "Sales Timeline",
  salesTemplate: "Sales Template",
  submit: "Submit",
  price: "Price",
  supplierPrice: "Supplier Price",
  regularPrice: "Regular Price",
  discount: "Discount",
  phasePrice: "Phase Price",
  salesStatus: "Sales Status",
  soldDate: "Sold Date",
  seller: "Seller",
  sellerCommision: "Seller Commision",
  soldBy: "Sold By",
  changePhaseproccess: "Change Phase Proccess",
  checkList: "Checklist",
  close: "Close",
  closePhase: "Close Phase",
  cancel: "Cancel",
  areUSure: "Are You Sure You Want To Close This Phase ?",
  afterClosing:
    "After closing the phase you will not be able to change it to active .",
  reorder: "Reorder",
  editProduct: "Edit Product",
  productTitle: "Product Title",
  subProductTitle: "Sub Product Title",
  productFamily: "Product Family",
  brand: "Brand",
  suppliers: "Suppliers",
  store: "Store",
  location: "Location",
  shortDescription: "Short Description",
  originalPrice: "Original Price",
  editSubProduct: "Edit Sub product",
  deleteSubProduct: "Delete Sub product",
  count: "Count",
  soldProduct: "Sold Product",
  areUSureSold: "Are You Sure about this submission",
  contractDesc:
    "if you have a contract number please enter the number in the field , otherwise just leave it empty .",
  contractNumber: "Contract Number",
  confirmSoldPrice: "Confirm Sold Price",
  startSelloffProccess: "Start Sell-Off proccess",
  preparation: "Preparation",
  start: "Start",
  withdrawProduct: "Withdraw Product",
  areUSureWithdraw: "Are you sure you want to withdraw the sold product",
  filters: "Filters",
  resetFilters: "Reset Filters",
  status: "Status",
  startProccessDateRange: "Start Proccess Date Range",
  changePhaseDateRange: "Change Phase Date Range",
  needPhaseChange: "Need Phase Change",
  needClosePhase: "Need Close Phase",
  hasProvision: "Has Provision",
  active: "Active",
  needClose: "Need Close",
  closed: "Closed",
  notSold: "Not Sold",
  noAction: "No Action",
  createProduct: "Create Product",
  startPhase: "Start Phase",
  fastSaleCommission: "Fast Sale Commission",
};
