import AddNewStore from "pages/compony/store/AddNew";
import StoreDetails from "pages/compony/store/Details";
import StoreList from "pages/compony/store/store-list";

const Stores = [
  {
    path: "",
    element: <StoreList />,
  },
  {
    path: "new",
    element: <AddNewStore />,
  },
  {
    path: ":storeId",
    element: <StoreDetails />,
  },
];

export default Stores;
