import { useContext, useEffect, useState, createContext } from "react";
import { Wrapper, Text } from "components";
import { SellOffProductDetailsContext } from "..";
import { ServiceApi, URLS } from "services";
import { isEmpty } from "lodash";
import SaleProccessTimeline from "./saleProccessTimeline";
import CurrentPhase from "./currentPhase";
import PriceTemplate from "./priceTemplate";
import SalesStatus from "./salesStatus";
import PreparationSalesTemplate from "./preparaationSalesTemplate";

export const saleProccessContext = createContext<any>({});

const SaleProccessTab = () => {
  const [timeLine, setTimeLine] = useState([]);

  const { data, setChangePhase, setStartPrePhase, setClosePhase } = useContext(
    SellOffProductDetailsContext
  );

  useEffect(() => {
    if (!isEmpty(data)) {
      const url = URLS.GET_SUBPRODUCT_TIMELINE_URL(data.sellOffProductId);
      ServiceApi.get(url).then(({ data }) => {
        setTimeLine(data.sellOffProductTimelineItems);
      });
    }
  }, [data]);

  const isSold = data.isSold;
  const active = data.currentPhaseDay;


  
  return (
    <>
      <div className="grid grid-cols-3 gap-6">
        {isSold || active ? (
          <saleProccessContext.Provider
            value={{ data, setChangePhase, setStartPrePhase, setClosePhase }}
          >
            <CurrentPhase />
            <PriceTemplate />
            <SalesStatus />
          </saleProccessContext.Provider>
        ) : (
          <PreparationSalesTemplate data={data} />
        )}
        {/* {!data.isClosed && ( */}
          <Wrapper className="col-span-3">
            <Wrapper.Header className="text-heading-2 font-semibold">
              <Text>
                salesManagment.sellOffSales.sellOffProduct.salesTimeline
              </Text>
            </Wrapper.Header>
            <Wrapper.Body>
              <SaleProccessTimeline timeLine={timeLine} />
            </Wrapper.Body>
          </Wrapper>
        {/* )} */}
      </div>
    </>
  );
};

export default SaleProccessTab;
