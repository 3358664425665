import {
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import { infoPageType } from "../type";
import { ServiceApi } from "services";
import { useNavigate, useParams } from "react-router";
import {
  Breadcrumb,
  Button,
  Icon,
  Skeleton,
  Text,
  Toggler,
  Wrapper,
  DeleteModal
} from "components";
import { useToggleState } from "hooks";
import EditInfoPage from "./EditInfoPage";
import Layouts from "./Layouts";
import { NotificationManager } from "common/notifications";

type contextType = {
  infoPageData: infoPageType;
  setInfoPageData: Dispatch<SetStateAction<infoPageType>>;
  getInfoPageData: () => void;
};
export const InfoPageContext = createContext({} as contextType);
export default function InfoPageDetails() {
  const navigate = useNavigate();
  const { infoPageId } = useParams();
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [disabledStatus, setDisabledStatus] = useState(false);
  const [isEditOpen, toggleEdit] = useToggleState(false);
  const [data, setData] = useState({} as infoPageType);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [selected, setSelected] = useState<any>();

  const removeInfoPage = () => {
    setRemoveLoading(true);
    const url = `/productservice/api/infopages/${infoPageId}`;
    ServiceApi.delete(url)
      .then(() => {
        // @ts-ignore: Unreachable code error
        navigate(-1, { replace: true });
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        toggleDeleteModal();
      })
      .catch(() => {
        setRemoveLoading(false);
      });
  };

  const toggleStatus = () => {
    setDisabledStatus(true);
    const url = `/productservice/api/infopages/${infoPageId}`;
    const body = { ...data };
    body.isActive = !body.isActive;
    ServiceApi.put(url, body)
      .then(() => {
        setData(body);
      })
      .finally(() => {
        setDisabledStatus(false);
      });
  };
  const getData = () => {
    setLoading(true);
    const url = `/productservice/api/infopages/${infoPageId}`;
    ServiceApi.get(url)
      .then(({ data }) => {
        const result: infoPageType = { ...data };
        result.infoPageLayouts ??= [];
        result.infoPageLayouts = result.infoPageLayouts.sort(
          (a, b) => a.order - b.order
        );
        setData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getData, [infoPageId]);
  return (
    <section className="space-y-4">
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <Breadcrumb />
        </div>
        {loading ? (
          <Skeleton.Button />
        ) : (
          <Button
            type="button"
            variant="white"
            className="!text-danger"
            onClick={() => {
              setSelected(data);
              toggleDeleteModal();
            }}
            loading={removeLoading}
          >
            <Icon icon="trash" />{" "}
            <Text>applications.infoPage.deletePageButton</Text>
          </Button>
        )}
      </div>
      {loading ? (
        <Fragment>
          <Skeleton.List />
          <Skeleton.ImageList />
          <Skeleton.ImageList />
          <Skeleton.ImageList />
        </Fragment>
      ) : (
        <Fragment>
          <Wrapper>
            <Wrapper.Body className="flex items-start">
              <div className="flex-1 space-y-4">
                <h5 className="text-gray-800">
                  {data.title}{" "}
                  <button
                    type="button"
                    onClick={toggleEdit}
                    className="text-primary"
                  >
                    <Icon icon="edit" />
                  </button>
                </h5>
                <p className="text-body-2 text-gray-500">{data.code}</p>
                <p className="text-body-1 text-gray-600">{data.description}</p>
              </div>
              <Toggler
                className="!w-fit"
                label={
                  data.isActive
                    ? "applications.infoPage.activeStatus"
                    : "applications.infoPage.inactiveStatus"
                }
                value={data.isActive}
                setValue={toggleStatus}
                disabled={disabledStatus}
              />
            </Wrapper.Body>
          </Wrapper>
          <InfoPageContext.Provider
            value={{
              infoPageData: data,
              setInfoPageData: setData,
              getInfoPageData: getData,
            }}
          >
            <Layouts />
            <EditInfoPage isOpen={isEditOpen} toggle={toggleEdit} />
          </InfoPageContext.Provider>
          <DeleteModal
            isOpen={isOpenDeleteModal}
            toggle={toggleDeleteModal}
            selected={{ name: selected?.title, id: infoPageId }}
            loading={removeLoading}
            onConfirm={removeInfoPage}
          />
        </Fragment>
      )}
    </section>
  );
}
