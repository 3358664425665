import { Fragment } from "react";
import { config } from "../constant";
import { classNames } from "utils";
import { pageSizeType } from "types";
import Icon from "./Icon";
import Select from "./Select";
type paginationProps = {
  pageNumber?: number;
  pageSize?: number;
  setActivePage: (val: number | string) => void;
  totalItems?: number;
  totalPages?: number;
  onPageSizeChange?: CallableFunction | null;
  pageSizeItems?: pageSizeType;
};

export default function Pagination({
  pageNumber: activePage = 1,
  pageSize = config.pageSize,
  setActivePage,
  totalItems = 0,
  totalPages = 1,
  onPageSizeChange = null,
  pageSizeItems = config.pageSizeItems,
}: paginationProps) {
  const items = {
    from: pageSize * (activePage - 1) + 1,
    to: pageSize * activePage > totalItems ? totalItems : pageSize * activePage,
  };
  const min = 1;
  const max = totalPages;
  const len = 1;
  const disabled = {
    prev: activePage === min,
    next: activePage === max,
  };
  const setPage = (page: number) => {
    return () => setActivePage(page);
  };
  const isActive = (page: number) => {
    return page === activePage;
  };
  const renderPages = () => {
    const elements = [...Array(max)].map((e, i) => {
      const page = i + 1;
      const isPrev = page < activePage;
      // const isNext = page > activePage;
      const isActive = page === activePage;
      const isInRange = page >= activePage - len && page <= activePage + len;
      const rules = [page === min, page === max, isInRange, isActive];
      const pos = isPrev ? "prev" : "next";
      return rules.some(Boolean) ? page : pos;
    });

    // @ts-ignore: Unreachable code error

    return [...new Set(elements)].map((page: number) => {
      const btnClassnames = classNames(
        "border-none w-[28px] h-[28px] text-body-base font-light rounded-md",
        {
          "bg-primary text-white text-body-2 font-semibold": isActive(page),
        }
      );
      return typeof page === "string" ? (
        <span key={page} className="my-auto">
          ...
        </span>
      ) : (
        <button
          type="button"
          key={page}
          onClick={setPage(page)}
          className={btnClassnames}
        >
          {page}
        </button>
      );
    });
  };

  return (
    <Fragment>
      {max > 1 && (
        <div className="flex items-center justify-between w-full mt-4 whitespace-nowrap">
          <div className="flex flex-1 flex-row items-center">
            {!!onPageSizeChange && (
              <Select
                className={"!w-[70px] !mr-2"}
                setValue={(val) => {
                  onPageSizeChange(val);
                }}
                items={pageSizeItems}
                value={pageSize}
              />
            )}
            <p className="basis-3/4 text-body-2 font-medium text-gray-700 flex-1 truncate">{`Showing ${items.from} to ${items.to} of ${totalItems} entries`}</p>
          </div>
          <div className="w-fit flex-center text-sm gap-2">
            <button
              type="button"
              onClick={setPage(1)}
              disabled={disabled.prev}
              className="mr-2"
            >
              <Icon icon={"chevrons-left"} size="1x" />
            </button>
            <button
              type="button"
              onClick={setPage(activePage - 1)}
              disabled={disabled.prev}
              className="mr-2"
            >
              <Icon icon={"chevron-left"} size="1x" />
            </button>
            {renderPages()}
            <button
              type="button"
              onClick={setPage(activePage + 1)}
              disabled={disabled.next}
              className="ml-2"
            >
              <Icon icon={"chevron-right"} size="1x" />
            </button>
            <button
              type="button"
              onClick={setPage(totalPages)}
              disabled={disabled.next}
              className="ml-2"
            >
              <Icon icon={"chevrons-right"} size="1x" />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
}
