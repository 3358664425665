import Positions from "pages/applications/mobile-app/positions";
import InfoPageList from "../../../pages/applications/mobile-app/info-pages/list";
import InfoPageDetails from "pages/applications/mobile-app/info-pages/details";

const MobileApp = [
  {
    path: "positions",
    element: <Positions />,
  },
  {
    path: "info-pages",
    element: <InfoPageList />,
  },
  {
    path: "info-pages/:infoPageId",
    element: <InfoPageDetails />,
  },
  {
    path: "content-managment",
    element: <>content-managment</>,
  },
  {
    path: "settings",
    element: <>settings</>,
  },
];

export default MobileApp;
