import ProductCategory from "pages/product-managment/master-data/product-category";
import EditSubCategory from "pages/product-managment/master-data/product-category/edit";
const ProductCategoryRoutes = [
  {
    path: "",
    element: <ProductCategory />,
  },
  {
    path: ":id",
    element: <EditSubCategory />,
  },
];

export default ProductCategoryRoutes;
