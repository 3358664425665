import { SearchBox, Table } from "components";
import { Fragment, useMemo, useState } from "react";
import { dataType, paramsType } from ".";
import SellerReport from "./SellerReport";
type sellersReportType = {
  storeData: dataType;
  params: paramsType;
};
export default function SellersReport({
  storeData,
  params,
}: sellersReportType) {
  const [keyword, setKeyword] = useState<string | null>(null);
  const sellers = useMemo(() => {
    const list = storeData.advisorsBasedOnTimeInterval?.sort(
      (a, b) => b.totalSales - a.totalSales
    );
    if (!keyword) return list;
    return list.filter((e) =>
      e.advisorFullName.toLowerCase().includes(keyword.toLowerCase())
    );
  }, [storeData, keyword]);
  return (
    <Fragment>
      <section className="col-span-full">
        <SearchBox
          onSubmit={setKeyword}
          totalItems={storeData.advisorsBasedOnTimeInterval?.length}
        />
      </section>
      <section className="col-span-full">
        <Table>
          {sellers?.map((e) => (
            <SellerReport
              key={e.advisorFullName}
              advisorFullName={e.advisorFullName}
              totalSales={e.totalSales}
              totalTargetSales={e.totalTargetSales}
              storeData={storeData}
              params={params}
            />
          ))}
        </Table>
      </section>
    </Fragment>
  );
}
