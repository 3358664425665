import UserPage from "pages/configurations/user-managment/users-list";
import Create from "pages/configurations/user-managment/users-list/create";
import Invite from "pages/configurations/user-managment/users-list/invite";
import Details from "pages/configurations/user-managment/users-list/details";

const UserList = [
  {
    path: "",
    element: <UserPage />,
  },
  {
    path: "new",
    element: <Create />,
  },
  {
    path: "Invite",
    element: <Invite />,
  },
  {
    path: ":userId",
    element: <Details />,
  },
];

export default UserList;
