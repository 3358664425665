import AllProducts from "./all-products";
import ImportProducts from "./import-product";

const Products = [
  {
    path: "all-products",
    children: [...AllProducts],
  },
  {
    path: "import-product",
    children: [...ImportProducts],
  },
];

export default Products;
