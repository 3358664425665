import { useEffect, useState, useRef, FormEvent } from "react";
import {
  Form,
  Icon,
  Text,
  InputGroup,
  Button,
  MultiSelect,
  Skeleton,
  Drawer,
  DeleteModal
} from "components";
import { useToggleState } from "hooks";
import { rules } from "constant";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";

type drawerProps = {
  isOpen: boolean;
  salesTemplateId?: string;
  selectedIndex?: any;
  selected?: any;
  toggle: () => void;
  data: any;
  getData: () => void;
};


export default function Phase({ isOpen, salesTemplateId, data, toggle, getData, selected, selectedIndex }: drawerProps) {
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataAdd, setDataAdd] = useState<any>(null);
  const [checkListItems, setCheckListItems] = useState([])
  const [params] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
  });
  const [selectedName, setSelectedName] = useState<string>();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const formRef = useRef<any>();


  useEffect(() => {
    if (isOpen) {
      setLoadingPage(true)
      formRef.current.data = data;
      let newData = JSON.parse(JSON.stringify(selected))
      let arr: any = [];
      newData?.checkListItemIds.forEach((i: any) => {
        arr.push({ name: i.label, id: i.checkListItemId })
      })
      newData.checkListItemIds = arr;
      setDataAdd(newData)
      setCheckListItems([]);
      const url = URLS.GET_CHECK_LIST_URL;
      const config = { params };
      ServiceApi.get(url, config).then(({ data }) => {
        let arr: any = []
        data.items.forEach((e: any) => {
          arr.push({ id: e.checkListItemId, name: e.label })
        })
        setCheckListItems(arr);
        setLoadingPage(false)
      });
    }
  }, [isOpen])// eslint-disable-line react-hooks/exhaustive-deps


  const handleSetValue = (key: any) => {
    return (value: any) => setDataAdd((p: any) => ({ ...p, [key]: value }));
  };


  const submitDelete = (id: any) => {
    setLoadingButton(true);
    let dataNew = JSON.parse(JSON.stringify(data));
    dataNew?.sellPhases?.forEach((item: any, index: string) => {
      if (item.id === id) {
        let i: any = index;
        if (i >= 0) {
          dataNew?.sellPhases.splice(i, 1);
        }
      }
    })
    dataNew?.sellPhases?.forEach((item: any) => {
      item.checkListItemIds.forEach((i: any, index: any) => {
        item.checkListItemIds[index] = i.id ? i.id : i.checkListItemId
      })
    })

    setLoadingButton(true);
    const url = URLS.UPDATE_DETAILS_SELL_PHASES_URL(salesTemplateId);
    ServiceApi.put(url, dataNew).then(() => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      getData();
      toggleDeleteModal();
      setLoadingButton(false)
    }).catch(() => setLoadingButton(false));

  };



  const onSubmitForm = (formData: any) => {
    let dataNew = formRef.current.data;
    dataNew?.sellPhases?.forEach((item: any, index: string) => {
      if (item.id === formData.id) {
        dataNew.sellPhases[index] = formData
      }
    })
    dataNew.sellPhases.forEach((item: any) => {
      item.checkListItemIds.forEach((i: any, index: any) => {
        item.checkListItemIds[index] = i.id ? i.id : i.checkListItemId
      })
    })
    setLoadingPage(true)
    const url = URLS.UPDATE_DETAILS_SELL_PHASES_URL(salesTemplateId);
    ServiceApi.put(url, dataNew).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      getData();
      toggle()
    }).finally(() => {
      setLoadingPage(false);
    });
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, dataAdd);
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <h2 className="text-gray-800 text-heading-2 mt-2"><Text>salesManagment.sellOffSales.salesTemplates.editPhase</Text><span>{selectedIndex}</span></h2>
            <div className="flex items-center justify-between">
              {selectedIndex !== 1 ? loadingPage ? (
                <Skeleton.Button />
              ) :
                <Button
                  type="button"
                  variant="danger"
                  light
                  onClick={() => {
                    setSelectedName(dataAdd?.title);
                    toggleDeleteModal();

                  }}
                >
                  <Icon className="mr-2" icon={"trash"} /><Text>global.buttons.delete</Text>
                </Button>
                : ""}
              {loadingPage ? (
                <Skeleton.Button />
              ) :
                <Button
                  type="button"
                  variant="light"
                  className="ml-2"
                  onClick={() => toggle()}
                >
                  <Icon className="mr-2" icon={"times"} /><Text>global.buttons.close</Text>
                </Button>
              }
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <Form ref={formRef} className="space-y-4 pr-4" onSubmit={onSubmitForm} id={"update-modal"}>
            {loadingPage ? (
              <>
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.TextArea />
                <Skeleton.Input />

              </>
            ) : (
              <>
                <InputGroup
                  label="salesManagment.sellOffSales.salesTemplates.phaseName"
                  value={dataAdd?.title}
                  setValue={handleSetValue("title")}
                  rules={rules.required}
                />
                <InputGroup
                  label="salesManagment.sellOffSales.salesTemplates.discount"
                  value={dataAdd?.discount}
                  setValue={(discount) => {
                    if (discount >= 0 && discount <= 100) {
                      setDataAdd((p: any) => ({ ...p, "discount": discount }));
                    }
                  }}
                  rules={rules.discount}
                  keyfilter="pnum"
                  append={<h5 className="text-heading-5 font-normal text-gray-400 border-gray-300 border-l px-4 py-3">%</h5>}
                />
                <InputGroup
                  type="number"
                  label="salesManagment.sellOffSales.salesTemplates.duration"
                  value={dataAdd?.duration}
                  setValue={handleSetValue("duration")}
                  rules={rules.required}
                  append={<h5 className="text-heading-5 font-normal text-gray-400 border-gray-300 border-l px-4 py-3">Day</h5>}
                />
                <InputGroup
                  keyfilter="pnum"
                  label="salesManagment.sellOffSales.salesTemplates.sellerCommission"
                  value={dataAdd?.sellerCommission}
                  setValue={(sellerCommission) => {
                    if (sellerCommission >= 0 && sellerCommission <= 100) {
                      setDataAdd((p: any) => ({ ...p, "sellerCommission": sellerCommission }));
                    }
                  }}
                  rules={rules.discount}
                  append={<h5 className="text-heading-5 font-normal text-gray-400 border-gray-300 border-l px-4 py-3">%</h5>}
                />
                <InputGroup
                  as="short-textarea"
                  label="salesManagment.sellOffSales.salesTemplates.shortDescription"
                  value={dataAdd?.description}
                  setValue={handleSetValue("description")}
                />
                {checkListItems.length !== 0 && selectedIndex !== 1 ? <MultiSelect
                  label="salesManagment.sellOffSales.salesTemplates.checkListItems"
                  items={checkListItems}
                  value={dataAdd?.checkListItemIds}
                  setValue={(value) => {
                    return setDataAdd((p: any) => ({ ...p, checkListItemIds: value.length !== 0 ? value?.map((val: any) => val) : [] }));
                  }}
                /> : ""}
              </>)}
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          {loadingPage ? (
            <Skeleton.Button />
          ) :
            <Button type="button" className="mr-2" onClick={() => toggle()} variant="light">
              <Text>global.buttons.cancel</Text>
            </Button>
          }
          {loadingPage ? (
            <Skeleton.Button />
          ) :
            <Button type="button" onClick={submit} loading={loadingPage}>
              <Text>global.buttons.submit</Text>
            </Button>
          }
        </Drawer.Footer>
      </Drawer.Menu>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: selectedName, id: selected?.id }}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </Drawer >
  );
}
