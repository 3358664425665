// reverese a array from child with parent id (search until parentID===null => root Parent):
// (This helper is made especially  for showing path of product family and product category from root to child)

const reverseArrayHelper = (
    arrayData: any,
    selectedArrayId: string,
    searchItemObjectName: string
) => {
    let currentArray = arrayData.find(
        (item: any) => item[searchItemObjectName] === selectedArrayId
    );
    if (!currentArray) return [];
    let parentId = currentArray.parentId;
    let path = [];
    path.push(currentArray);
    while (parentId != null) {
        currentArray = arrayData.find(
            (item: any) => item[searchItemObjectName] === parentId
        );
        path.push(currentArray);
        parentId = currentArray.parentId;
    }
    let reverseArray = [];
    for (var i = path.length - 1; i >= 0; i--) {
        reverseArray.push(path[i]);
    }
    return reverseArray;
};
export default reverseArrayHelper;