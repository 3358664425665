import {
    InputGroup,
    SelectLang,
    Skeleton,
    Text,
    Wrapper
} from "components";
import { flagCountry, rules } from "constant";
import {
    Fragment,
    useContext,
} from "react";
import { AttributesContext } from "..";

export default function General() {

  const {
    attributesGroupData,
    setAttributesGroupData,
    description,
    name,
    langItems,
    setName,
    setDescription,
    loading,
    langSelected,
    setLangSelected
  } = useContext<any>(AttributesContext);


  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    attributesGroupData?.translations?.forEach((item: any) => {
      if (item?.language?.toLocaleLowerCase() === lang?.toLocaleLowerCase()) {
        setName(item.name)
        setDescription(item.description)
      }
    })
  };

  return (
    <Fragment>
      <div className="flex gap-6">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) :
          <Wrapper>
            <Wrapper.Header>
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-gray-800 font-semibold" ><Text>productManagement.masterData.attributesGroups.basicInfo</Text></h2>
                </div>
                <div>
                  <SelectLang value={langSelected} items={langItems} onChangeLanguage={onChangeLanguage} />
                </div>
              </div>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributesGroups.groupName"
                  value={name}
                  flag={flagCountry[langSelected]}
                  setValue={(value) => {
                    attributesGroupData?.translations?.forEach((item: any) => {
                      if (item?.language?.toLocaleLowerCase() === langSelected?.toLocaleLowerCase()) {
                        item.name = value
                      }
                    })
                    setAttributesGroupData((p: any) => ({ ...p, translates: attributesGroupData?.translations }));
                    setName(value)
                  }}
                  rules={rules.required}
                />
                <InputGroup
                  as="short-textarea"
                  flag={flagCountry[langSelected]}
                  label="productManagement.masterData.attributesGroups.shortDescription"
                  value={description}
                  setValue={(value) => {
                    attributesGroupData?.translations?.forEach((item: any) => {
                      if (item?.language?.toLocaleLowerCase() === langSelected?.toLocaleLowerCase()) {
                        item.description = value
                      }
                    })
                    setAttributesGroupData((p: any) => ({ ...p, translates: attributesGroupData?.translations }));
                    setDescription(value)

                  }}
                />

              </div>
            </Wrapper.Body>
          </Wrapper>
        }
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) :
          <Wrapper>
            <Wrapper.Header>
              <h2 className="text-gray-800 font-semibold" ><Text>productManagement.masterData.attributes.properties</Text></h2>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributesGroups.groupCode"
                  value={attributesGroupData?.code}
                  disabled
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        }
      </div>
    </Fragment>
  );
}
