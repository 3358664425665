import {
  useEffect,
  useState
} from "react";
import {
  Button,
  Modal,
  Text,
  Icon,
  Status,
  InputGroup,
  Form
} from "components";
import { useConverter, usePermission } from "hooks";
import { rules } from "constant";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";
import { appPermissions } from "constant";

type changeStatusModalType = {
  isOpen: boolean;
  toggle: () => void;
  selected: any;
  comments: any;
  projectId: string;
  getData: any;
};

export default function ChangeStatusModal({
  isOpen,
  toggle,
  selected,
  comments,
  projectId,
  getData
}: changeStatusModalType) {

  const { convertDate, convertAmount } = useConverter();
  const [comment, setComment] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);


  //permissions
  const {
    SS_ChangeWinnerCalculationStatusToPendingApproval,
    SS_ChangeWinnerCalculationStatusToApproved
  } = appPermissions;
  const PENDING_PERMISSIONS = usePermission(SS_ChangeWinnerCalculationStatusToPendingApproval);
  const APPROVED_PERMISSIONS = usePermission(SS_ChangeWinnerCalculationStatusToApproved);


  useEffect(() => {
    setComment("")
  }, [isOpen])


  const submit = () => {
    setLoadingButton(true);
    let url = "";
    if (selected.status === 2) {
      url = URLS.PENDING_APPROVAL_URL(projectId)
    } else if (selected.status === 3) {
      url = URLS.STATUS_APPROVED_URL(projectId)
    }
    ServiceApi.post(url, {
      comment: comment,
      winnerCalculationHistoryId: selected.id
    }).then((res) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      toggle()
      getData()
    }).finally(() => {
      setLoadingButton(false);
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      width="w-[500px]"
      modalClassName="z-40"
    >
      <Form onSubmit={submit}>
        <Modal.Body className="max-h-100 overflow-auto space-y-8">
          <div className={`grid ${selected?.shippingCostHasWarning && selected?.sellFactorHasWarning ? "grid-cols-2" : "grid-cols-1"}  gap-4`}>
            {selected?.shippingCostHasWarning &&
              <div className="text-center py-4 px-2 rounded-md bg-danger-light space-y-2">
                <div>
                  <Icon
                    size="2x"
                    className="text-danger"
                    icon={"triangle-exclamation"}
                  />
                </div>
                <div><span className="text-body-2 text-danger font-normal"><Text>status.salesList.shippingCostHasWarning</Text></span></div>
                <div>
                  <span className="text-body-base text-gray-600 font-normal"><Text>salesManagment.kitchenSales.salesList.targetShippingCost</Text>:</span>
                  <span className="ml-1 text-body-base text-gray-800 font-normal">{convertAmount(selected?.targetShippingCost)}</span>
                </div>
                <div><span className="text-body-base text-gray-600  font-normal"><Text>salesManagment.kitchenSales.salesList.shippingCost</Text>:</span>
                  <span className="ml-1 text-body-base text-gray-800 font-normal">{convertAmount(selected?.shippingCost)}</span>
                </div>
              </div>
            }
            {selected?.sellFactorHasWarning &&
              <div className="text-center py-4 px-2 rounded-md bg-danger-light space-y-2">
                <div>
                  <Icon
                    size="2x"
                    className="text-danger"
                    icon={"triangle-exclamation"}
                  />
                </div>
                <div><span className="text-body-2 text-danger font-normal"><Text>status.salesList.sellFactorHasWarning</Text></span></div>
                <div>
                  <span className="text-body-base text-gray-600 font-normal"><Text>salesManagment.kitchenSales.salesList.targetSellFactor</Text>:</span>
                  <span className="ml-1 text-body-base text-gray-800 font-normal">{selected?.targetSellFactor?.toFixed(2)}</span>
                </div>
                <div><span className="text-body-base text-gray-600  font-normal"><Text>salesManagment.kitchenSales.salesList.sellFactor</Text>:</span>
                  <span className="ml-1 text-body-base text-gray-800 font-normal">{selected?.sellFactor?.toFixed(2)}</span>
                </div>
              </div>
            }
          </div>
          {comments?.map((item: any) => {
            return (
              <div className="pb-2 space-y-2">
                <div className="flex items-center gap-2">
                  <div>
                    <Status.salesCommentsIconStatus id={item?.statusType} />
                  </div>
                  <div className="space-y-2">
                    <div><span className="text-body-2 text-gray-700 font-normal">{item?.userFullName}</span></div>
                    <div><span className="text-body-2 text-gray-500 font-medium">{convertDate(item?.createdAt)}</span></div>
                  </div>
                </div>
                <div>
                  <span className="text-body-base text-gray-600 font-normal">{item?.comment}</span>
                </div>
              </div>
            )
          })}
          {(PENDING_PERMISSIONS || APPROVED_PERMISSIONS) &&
            <InputGroup
              as="short-textarea"
              label="salesManagment.kitchenSales.salesList.yourComment"
              value={comment}

              setValue={(e) => setComment(e)}
              rules={rules.required}
            />
          }
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          {(PENDING_PERMISSIONS || APPROVED_PERMISSIONS) &&
            <>
              <Button type="button" onClick={toggle} variant="white">
                <Icon className="mr-2" icon={"times"} />
                <Text>global.buttons.cancel</Text>
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={loadingButton}
              >
                <Text>global.buttons.approve</Text>
              </Button>
            </>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
