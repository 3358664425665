import { NotificationManager } from "common/notifications";
import {
    Accordion,
    Button,
    Dropdown,
    HandleName,
    Icon,
    SelectLang,
    Skeleton,
    Text,
    Toggler,
    Wrapper
} from "components";
import { appPermissions, flagCountry } from "constant";
import { useDataState, usePermission, useSelector, useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import { PimContext } from "..";
import { findValue } from "../../helpers/find-attribute.value";
import FormFieldGenerator from "../attributes";

const AttributesTab = () => {
  const { i18n } = useTranslation();
  const { productData, channels, setAttributeChanged } = useContext(PimContext);
  const [loading, setLoading] = useToggleState();
  const profile = useSelector((s) => s.profile);
  const [submitLoading, setSubmitLoading] = useToggleState();
  const [attributes, setAttributes] = useState<any>([]);
  const [groupFilter, setGroupfilter] = useState<any>("all");
  const [selectedChannel, setSelectedChannel] = useState<any>("");
  const [showOriginalAttribute, setShowOriginalAttribute] =
    useState<any>(false);
  const [selectedFilter, setSelectedFilter] = useState<any>("");
  const [items, setItems] = useState<any>([]);
  const [lang, setLang] = useState<string>("de");
  const [values, setValues, setBaseData, isChanged] = useDataState<any>({});
  const [
    changedAttributes,
    setChangedAttributesValues,
    setBaseAttributeValue,
    isChangedAttribute,
  ] = useDataState<any>([]);

  useEffect(() => {
    setAttributeChanged(isChanged);
  }, [isChanged]);


  useEffect(() => {
    let arr: any = {};
    let arrItem: any = [];
    profile?.companyContentLanguages?.forEach((item: any) => {
      arr[item] = item;
      arrItem.push({
        name: item,
        id: item,
      },)
    })
    setItems(arrItem)
  }, [])

  const getProductAttributes = () => {
    setLoading();
    const url = URLS.GET_PIM_PRODUCT_ATTRIBUTES(productData?.id);
    ServiceApi.get(url)
      .then(({ data }) => {
        setAttributes(data.pimProductGroupDtos);
        setBaseData(data?.pimProductGroupDtos);
        setSelectedChannel(channels?.[0]?.code);
        setSelectedFilter("all");
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    getProductAttributes();
  }, [productData?.id]);

  const findMissingAttributes = (attribute: any) => {
    const requiredFields = attribute.pimProductAttributeValues.filter(
      (item: any) => {
        const { value } = findValue(
          item,
          values,
          attribute.attributeGroupId,
          selectedChannel,
          lang
        );
        return item.isRequired && !value;
      }
    );

    if (requiredFields.length) {
      return (
        <>
          <Icon icon="star-exclamation" className="text-danger mr-1" />
          <span className="mr-6">
            {requiredFields.length}{" "}
            <span>
              <Text>
                productManagement.products.Details.missingRequiredAttribute
              </Text>
            </span>
          </span>
        </>
      );
    }
    return null;
  };

  const findTranslatedName = (channel: any) => {
    return channel?.channelNameTranslates?.filter(
      (tr: any) => tr.language.toLowerCase() === i18n.language
    )?.[0]?.name;
  };

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const onSubmitHandler = () => {
    setSubmitLoading();
    const body = { attributes: changedAttributes };
    const url = URLS.EDIT_PIM_PRODUCT_ATTRIBUTE_URL(productData?.id);
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        setSubmitLoading();
        setChangedAttributesValues([]);
        getProductAttributes();
      })
      .catch(() => setSubmitLoading());
  };

  const onChangeValue = (attribute: any, id: string) => {
    const clone = [...changedAttributes];
    const { target } = findValue(attribute, values, id, selectedChannel, lang);
    const isExist = clone.find((item: any) => item?.code === attribute?.code);
    if (!isExist) {
      clone.push(target);
    } else {
      const objIndex = clone.findIndex(
        (item: any) => item?.code === attribute?.code
      );
      clone[objIndex] = target;
    }
    setChangedAttributesValues(clone);
  };

  //permissions

  const { PS_UpdatePimProductAttributeValues } = appPermissions;
  const UPDATE_ATTRIBUTE_PERMISSION = usePermission(
    PS_UpdatePimProductAttributeValues
  );

  const filterItems = [
    { id: "all", name: "All Attribute" },
    {
      id: "missingRequierd",
      name: "Missing required attributes",
    },
    {
      id: "missing",
      name: "All missing attributes",
    },
  ];

  return (
    <>
      <div className="grid grid-col-1 xl:grid-cols-3 gap-4">
        {/* Header DropDowns */}
        <section className="col-span-full flex flex-wrap items-center gap-4 py-2 sticky top-[64px] bg-light z-20">
          <h6 className="font-normal">
            <Text>productManagement.products.Details.channels</Text>
          </h6>
          {loading ? (
            <Skeleton.Button />
          ) : (
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                type="button"
                className="text-gray-600 font-medium text-body-base uppercase flex space-x-2 bg-white p-3 rounded-md min-w-[10rem]"
              >
                <span>
                  {findTranslatedName(
                    channels.filter(
                      (channel: any) => channel?.code === selectedChannel
                    )?.[0]
                  )}{" "}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="min-w-fit mt-2 p-2">
                <ul className="text-gray-700 w-max">
                  {channels.map((channel: any) => (
                    <li
                      key={channel?.code}
                      onClick={() => setSelectedChannel(channel.code)}
                      className={`py-2 font-medium first:mb-1 px-4 h6 ${channel.code === selectedChannel &&
                        "bg-primary-light text-primary "
                        } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                    >
                      {findTranslatedName(channel)}
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <h6 className="font-normal">
            <Text>productManagement.products.Details.language</Text>
          </h6>
          {loading ? (
            <Skeleton.Button />
          ) : (
            <SelectLang value={lang} items={items} onChangeLanguage={onChangeLanguage} className="bg-white" />
          )}
          <div>
            <Toggler
              label="productManagement.products.Details.showInvisibleAttributes"
              size="sm"
              value={showOriginalAttribute}
              setValue={(e) => setShowOriginalAttribute(e)}
            />
          </div>
          <div className="ml-auto">
            {loading ? (
              <Skeleton.Button />
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  as="button"
                  type="button"
                  className="text-gray-600 font-medium text-body-base uppercase flex space-x-2 bg-white p-3 rounded-md min-w-[10rem]"
                >
                  <span>
                    {
                      filterItems.filter(
                        (filter: any) => filter?.id === selectedFilter
                      )?.[0]?.name
                    }{" "}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="min-w-fit mt-2 p-2">
                  <ul className="text-gray-700 w-max">
                    {filterItems.map((filter: any) => (
                      <li
                        key={filter?.id}
                        onClick={() => setSelectedFilter(filter.id)}
                        className={`py-2 font-medium first:mb-1 px-4 h6 hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                      >
                        {filter.name}
                      </li>
                    ))}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </section>
        {!loading && (
          <section className="mb-2 col-span-full flex flex-wrap items-center gap-4">
            <Button
              size="sm"
              variant={groupFilter === "all" ? "primary" : "white"}
              className="!rounded-full"
              onClick={() => setGroupfilter("all")}
            >
              <Text>productManagement.products.Details.allInGroupFilter</Text>
            </Button>
            {attributes?.map((attribute: any) => {
              return (
                attribute?.pimProductAttributeValues?.length > 0 && (
                  <Button
                    size="sm"
                    className="!rounded-full"
                    variant={
                      groupFilter === attribute.translations?.[0]?.name
                        ? "primary"
                        : "white"
                    }
                    onClick={() =>
                      setGroupfilter(attribute.translations?.[0]?.name)
                    }
                  >
                    {<HandleName translations={attribute?.translations} code={attribute?.code} keyName="name" />}
                  </Button>
                )
              );
            })}
          </section>
        )}
        <section className="col-span-full space-y-6">
          {loading
            ? [1, 2, 3, 4].map((key) => <Skeleton.List key={key} />)
            : attributes?.map((attribute: any, index: any) => {
              let sortAttribute = attribute.pimProductAttributeValues?.sort(
                (a: any, b: any) => {
                  return a.attributeOrder - b.attributeOrder;
                }
              );
              return (
                <>
                  {(groupFilter === "all" ||
                    groupFilter === attribute?.translations?.[0]?.name) && (
                      <Wrapper
                        key={attribute.attributeGroupId}
                        className="grid grid-cols-1 items-start text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium"
                      >
                        <Accordion>
                          {sortAttribute?.length > 0 && (
                            <Accordion.Item
                              active={
                                index === 0 ||
                                groupFilter !== "all" ||
                                selectedFilter !== "all"
                              }
                              className="border-none"
                            >
                              <Accordion.Toggle className="flex justify-between border-b sticky top-[120px] bg-white z-10 rounded-xl">
                                <div className="flex items-center gap-[10px] px-6">
                                  <div>
                                    <Icon
                                      size="1x"
                                      icon={"chevron-down"}
                                      className="transition-transform group-[.accordion-item.active]:rotate-180"
                                    />
                                  </div>
                                  <div>
                                    <h4 className="text-gray-800 font-normal">
                                      {<HandleName translations={attribute?.translations} code={attribute?.code} keyName="name" />}
                                    </h4>
                                  </div>
                                </div>
                                <div className="text-body-2 text-gray-600">
                                  {findMissingAttributes(attribute)}
                                </div>
                              </Accordion.Toggle>
                              <Accordion.Body className="grid grid-cols-1 px-6 ">
                                <FormFieldGenerator
                                  id={attribute.attributeGroupId}
                                  attributes={
                                    attribute.pimProductAttributeValues
                                  }
                                  values={values}
                                  language={lang}
                                  channel={selectedChannel}
                                  setValues={setValues}
                                  flag={flagCountry}
                                  filter={selectedFilter}
                                  showOriginalAttribute={showOriginalAttribute}
                                  onChangeValue={onChangeValue}
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </Wrapper>
                    )}
                </>
              );
            })}
        </section>
      </div>
      {!loading && UPDATE_ATTRIBUTE_PERMISSION && (
        <Wrapper className="sticky bottom-0 shadow-card z-20">
          <Wrapper.Body className="flex items-center justify-end gap-4">
            <Button
              type="button"
              variant="primary"
              onClick={onSubmitHandler}
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>productManagement.products.Details.saveAttributes</Text>
            </Button>
          </Wrapper.Body>
        </Wrapper>
      )}
    </>
  );
};
export default AttributesTab;
