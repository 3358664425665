export const menu = {
  dashboard: "DASHBOARD",
  productManagement: {
    root: "PRODUKT MANAGEMENT",
    masterData: {
      root: "STAMMDATEN",
      Attributes: "Produkt Attributen",
      AttributeGroups: "Attributgruppen",
      ProductFamilies: "Produktfamilie",
      ProductCategories: "Produktkategorien",
      MeasurementUnits: "Maßeinheiten",
      Suppliers: "Lieferanten",
      Brands: "Marken",
    },
    products: {
      root: "PRODUKTMANAGEMENT",
      AllProducts: "Alle Produkte",
      ImportProduct: "Produkte importieren",
    },
    setting: {
      root: "EINSTELLUNGEN",
      Configurations: "Produkt Konfiguration",
    },
  },
  salesManagement: {
    root: "VERKAUFSMANAGEMENT",
    sellOffSales: {
      root: "ABVERKAUFSPROZESS",
      sellOffProducts: "Abverkaufsartikel",
      salesTemplates: "Vorlagen",
      salesSettings: "Verkaufseinstellungen",
      SellOffCommissions: "Abverkaufprovisions",
      salesChecklist: "Checkliste",
      preparationPhase: "Vorbereitungsphase",
    },
    settings: {
      root: "EINSTELLUNGEN",
      salesChannels: "Verkaufskanäle",
    },
    reports: "BERICHTEE",
    kitchenSales: {
      root: "KÜCHEN",
      salesList: "Verkaufsliste",
      salesReport: "Verkaufsbericht",
      configuration: "Konfiguration",
    },
    salesArea: {
      root: "VERKAUFSFLÄCHE",
      locations: "Fläche",
    },
  },
  applications: {
    root: "ANWENDUNGEN",
    mobileApp: {
      root: "MOBILE APP",
      positions: "Positions",
      infoPages: "Info Seiten",
      contentManagement: "Inhaltsverwaltung",
      settings: "Einstellungen",
    },
  },
  thirdParty: {
    root: "THIRD-PARTY",
    settings: "Einstellungen",
    crmConfiguration: "CRM-Konfiguration",
    erpConfiguration: "ERP-Konfiguration",
    nopCommerce: "NOP Commerce DE",
    iwofurn: "Iwofurn DE",
  },
  company: {
    root: "UNTERNEHMEN",
    profile: "Unternehmensprofil",
    stores: "Filialen",
    legalEntities: "Legal entities",
    employees: "Employees DE",
    customers: {
      root: "KUNDENVERWALTUNG",
      customerList: "Kundenliste",
      settings: "Einstellungen",
      Appointments: "Termine",
    },
  },
  configurations: {
    root: "EINSTELLUNGEN",
    notifications: {
      root: "BENACHRICHTIGUNGEN",
      alerts: "Alerts",
      actionGroups: "Aktionsgruppen",
    },
    globalSettings: {
      root: "GLOBALE EINSTELLUNGEN",
      apiKeys: "API Keys",
      ssoSettings: "SSO Konfiguration",
      dataStorage: "Speicherkonfiguration",
    },
    userManagement: {
      root: "BENUTZERVERWALTUNG",
      usersList: "Benutzer",
      userGroups: "Benutzergruppe",
      roles: "Rollen",
    },
  },
};
