const COMPANY = {
  //------------------company -------------------------
  MY_COMPANY_URL: "accountservice/api/me/companies",
  ADD_IMAGE_TO_MY_COMPANY_URL: "accountservice/api/me/companies/avatar",
  COMPANY_URL: "statisticsservice/api/companies",

  //-------------------channels-------------------------

  CHANNEL_URL: "accountservice/api/companies/channels",
};

export default COMPANY;
