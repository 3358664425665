import { NotificationManager } from "common/notifications";
import {
    Icon,
    InputGroup,
    Select,
    Text,
    Wrapper
} from "components";
import SearchAddress from "components/SearchAddress";
import { countries, rules } from "constant";
import cloneDeep from "lodash/cloneDeep";
import { useContext } from "react";
import { SuppliersContext } from "..";

export default function General() {
  const { suppliersData, setSuppliersData } = useContext<any>(SuppliersContext);

  const handleSetData = (key: any) => {
    return (value: any) => setSuppliersData((p: any) => ({ ...p, [key]: value }));
  };

  const handleSetValue = (key: any) => {
    return (value: any) =>
      setSuppliersData((p: any) => {
        const data = cloneDeep(p);
        data.address[key] = value;
        return data;
      });
  };

  const handleSetAddress = (address: any) => {
    address["number"] = address?.houseNo;
    setSuppliersData((p: any) => {
      return {
        ...p,
        address: address
      }
    });
    return {};
  };


  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <h2 className="text-heading-2 text-gray-800 font-semibold"><Text>productManagement.masterData.suppliers.generalInfo</Text></h2>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
          <div className="space-y-8">
            <div className="col-start-1 col-end-2">
              <InputGroup
                label="productManagement.masterData.suppliers.supplierName"
                value={suppliersData.name}
                setValue={handleSetData("name")}
                rules={rules.required}
              />
            </div>
            <div className="col-start-1 col-end-2">
              <InputGroup
                label="productManagement.masterData.suppliers.emailAddress"
                value={suppliersData.email}
                setValue={handleSetData("email")}
                rules={rules.emailAddress}
              />
            </div>
            <div className="col-start-1 col-end-2">
              <InputGroup
                label="productManagement.masterData.suppliers.phoneNumber"
                value={suppliersData.phoneNumber}
                setValue={handleSetData("phoneNumber")}
              />
            </div>
          </div>
          <div className="space-y-8">
            <div>
              <InputGroup
                disabled
                append={
                  <span
                    className="input-group-text"
                    onClick={() => {
                      window.navigator.clipboard.writeText(suppliersData.code)
                      NotificationManager.success(
                        "global.toast.copy-msg",
                        "global.toast.copy-title"
                      );
                    }}
                  >
                    <Icon
                      className="text-primary ml-6 cursor-pointer"
                      icon={"files"}
                    />
                  </span>
                }
                label="productManagement.masterData.suppliers.code"
                value={suppliersData.code}
              />
            </div>
            <div>
              <InputGroup
                label="productManagement.masterData.suppliers.ilnNumber"
                value={suppliersData.iln}
                setValue={handleSetData("iln")}
              />
            </div>
            <div>
              <InputGroup
                as="short-textarea"
                label="productManagement.masterData.suppliers.description"
                value={suppliersData.description}
                setValue={handleSetData("description")}
              />
            </div>
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <h2 className="text-heading-2 text-gray-800 font-semibold"> <Text>productManagement.masterData.suppliers.location</Text></h2>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
          <section className="space-y-8">
            <SearchAddress setValue={handleSetAddress} />
            <Wrapper.Section className="grid grid-cols-1 lg:grid-cols-1 gap-x-6 gap-y-8 items-stretch">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
                <div>
                  <InputGroup
                    label="global.locations.street"
                    value={suppliersData.address?.street}
                    setValue={handleSetValue("street")}

                  />
                </div>
                <div>
                  <InputGroup
                    label="global.locations.houseNumber"
                    value={suppliersData.address?.number}
                    setValue={handleSetValue("number")}

                  />
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
                <div>
                  <InputGroup
                    label="global.locations.postalCode"
                    value={suppliersData.address?.postalCode}
                    setValue={handleSetValue("postalCode")}

                  />
                </div>
                <div>
                  <InputGroup
                    label="global.locations.city"
                    value={suppliersData?.address?.city}
                    setValue={handleSetValue("city")}

                  />
                </div>
              </div>
              <div className="col-span-full">
                <Select
                  label="global.locations.country"
                  value={suppliersData?.address?.country}
                  setValue={handleSetValue("country")}
                  items={countries}
                />
              </div>
              <div className="col-span-full">
                <Select
                  label="global.locations.state"
                  value={suppliersData?.address?.state}
                  setValue={handleSetValue("state")}
                  items={
                    suppliersData?.address ? countries?.find((e: any) => e?.id === suppliersData?.address?.country)?.states as [] : []
                  }
                />
              </div>
            </Wrapper.Section>
          </section>
        </Wrapper.Body>
      </Wrapper>
    </>
  )
}
