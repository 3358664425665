export const status = {
  store: {
    active: "Active",
    deActive: "Deactive",
  },
  salesList: {
    shippingCostHasWarning: "Shipping Cost Warning",
    sellFactorHasWarning: "Sell Factor Warning",
    pendingApproval: "Pending Approval",
    approved: "Approved",
  },
};
