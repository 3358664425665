export const global = {
  items: "Gegenstände",
  home: "STARTSEITE",
  addNew: "Neu hinzufügen",
  details: "Detail",
  user: "Benutzer",
  admin: "Administrator",
  uploadFile: "Datei hochladen",
  dragDropFile: "Dateien hier per Drag & Drop ablegen",
  required: "erforderlich",
  default: "Standard",
  standard: "Standard",
  editImage: "Bild bearbeiten",
  changeLogo: "Logo ändern",
  fromCamera: "From camera DE",
  chooseFile: "Choose file DE",
  uploadNewImage: "Neues Bild hochladen",
  save: "Speichern",
  cancel: "Abbrechen",
  discard: "Verwerfen",
  notRequired: "Optional",
  noItems: "Keine Daten verfügbar",
  noItemsElse: "Bitte versuchen Sie etwas anderes",
  areYouSureYouWantToDelete: "Möchten Sie diese Daten wirklich löschen?",
  youCantUndoThis: "Dies kann nicht rückgängig gemacht werden.",
  placeholders: {
    search: "Suchen...",
  },
  buttons: {
    view: "Details anzeigen",
    discard: "Verwerfen",
    delete: "Löschen",
    saveChanges: "Änderungen speichern",
    cancel: "Abbrechen",
    close: "Schließen",
    ImNotSure: "Ich bin mir nicht sicher",
    next: "Weiter",
    back: "Zurück",
    submit: "Absenden",
    create: "Erstellen",
    copy: "Kopieren",
    details: "Detail",
    edit: "Bearbeiten",
    actions: "Aktionen",
    invite: "Einladen",
    disable: "Deaktivieren",
    enable: "Aktivieren",
    selectAll: "Alle auswählen",
    deselectAll: "Alle abwählen",
    setAsCoverPhoto: "Set as cover photo DE",
    coverPhoto: "Cover Photo DE",
    approve: "Approve DE",
    filters: "Filter",
    resetFilters: "Filter zurücksetzen",
  },
  locations: {
    street: "Straße",
    houseNumber: "Hausnummer",
    postalCode: "Postleitzahl",
    city: "Stadt",
    country: "Land",
    state: "Bundesland",
    location: "Standort",
    locationOnMap: "Standort auf Karte",
    address: "Adresse",
    searchPlaceHolder: "Adressensuche",
    name: 'Name DE'
  },
  toast: {
    "delete-msg": "Objekt wurde erfolgreich gelöscht",
    "delete-title": "Objekt wurde gelöscht",
    "add-msg": "Objekt wurde erfolgreich gespeichert.",
    "add-title": "Objekt hinzugefügt",
    "edit-msg": "Objekt wurde erfolgreich aktualisiert.",
    "edit-title": "Objekt wurde aktualisiert",
    "copy-msg": "Element erfolgreich kopiert",
    "copy-title": "Element kopieren",
    "start-phase-msg": "Phase erfolgreich gestartet",
    "start-phase-title": "Phase starten",
    "change-phase-msg": "Phase erfolgreich ändern",
    "change-phase-title": "Phase ändern",
    "close-phase-msg": "Der Vorgang wurde erfolgreich abgeschlossen",
    "close-phase-title": "Phase schließen",
    "sold-msg": "Erfolgreich verkauft",
    "sold-title": "Verkauft",
    "invite-msg": "Element erfolgreich einladen",
    "invite-title": "Element einladen",
    "publish-msg": "Element erfolgreich veröffentlichen",
    "publish-title": "Element veröffentlichen",
    "unpublish-msg": "Element erfolgreich ausblenden",
    "unpublish-title": "Element ausblendenDE",
    "import-msg": "Element erfolgreich importieren",
    "import-title": "Element importieren",
  },
  types: {
    infoPageLayout: {
      bannerOne: "Banner eins",
      bannerTwo: "Banner zwei",
      bannerThree: "Banner three DE",
      introduction: "Einführung",
      products: "Produkte",
      scrollbar: "Scrollbar",
    },
    position: {
      banner: "Banner",
      carousel: "Karussell",
    },
    mobileAppLink: {
      none: "Keine",
      infoPage: "Infoseite",
      externalLink: "Externer Link",
      channelProduct: "Kanalprodukt",
      button: "Button DE",
    },
  },
};
