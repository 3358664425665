export const profile = {
  basicInfo: "Basic info",
  companyName: "Company Name",
  setting: "Setting",
  general: "General",
  language: "Language",
  phoneNumber: "Phone Number",
  mobileNumber: "Mobile Number",
  shortDescription: "Shortscription",
  dashboardLanguage: "Dashboard Language",
  contentLanguage: "content Language",
  activeContentLanguage: "Active Content Language",
  activeDashboardLanguage: "Active Dashboard Language",
  defaultContentLanguage: "Default Content Language",
  defaultDashboardLanguage: "Default Dashboard Language",
  customerSettings: "Customer Settings",
  discountSettings: "Discount Settings",
  customerNumberPrefix: "Customer Number Prefix",
  title: "Title",
  changeAvatar: "Change Avatar ",
  upload: "Upload ",
  channels: "Channels",
  availableLanguages: "Available languages",
  defaultLanguage: "Default Language"
};
