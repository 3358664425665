import { useState, useEffect } from "react";
import { Button, Modal, Form, InputGroup, Text, Icon } from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import { rules } from "constant";
import { NotificationManager } from "common/notifications";

type addModalType = {
  isOpen: boolean;
  toggle?: () => void;
  getData: () => void;
};

export default function AddModal({ isOpen, toggle, getData }: addModalType) {
  const [data, setData] = useState({
    name: "",
  });
  const [result, setResult] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowResult(false);
      setData({ name: "" });
      setResult("");
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.GENERATE_API_KEY_URL;
    ServiceApi.post(url, data).then((res) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      getData();
      setResult(res.data);
      setShowResult(true);
      setLoadingButton(false);
    });
  };

  const clipboard = () => {
    window.navigator.clipboard.writeText(result);
    NotificationManager.success(
      "global.toast.copy-msg",
      "global.toast.copy-title"
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Form onSubmit={submit}>
        <Modal.Header className="flex flex-row">
          <h2 className="text-heading-2 text-gray-800 font-semibold">
            <Text>configurations.globalSettings.apiKeys.generateAPIKey</Text>
          </h2>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto p-8 grid grid-cols-1 lg:grid-cols-1 gap-8 lg:gap-8">
          {showResult ? (
            <>
              <InputGroup
                disabled
                append={
                  <span
                    className="input-group-text"
                    onClick={() => {
                      clipboard();
                    }}
                  >
                    <Icon
                      className="text-primary ml-6 cursor-pointer"
                      icon={"files"}
                    />
                  </span>
                }
                label="configurations.globalSettings.apiKeys.yourApiKey"
                value={result}
              />
              <div className="text-body-base font-normal">
                <span className="text-danger">
                  <Text>
                    configurations.globalSettings.apiKeys.pmGenerateApikey
                  </Text>
                </span>
              </div>
            </>
          ) : (
            <InputGroup
              label="configurations.globalSettings.apiKeys.apiKeyName"
              value={data.name}
              className="border-white"
              setValue={(name) => setData((p) => ({ ...p, name }))}
              rules={rules.required}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>

          {showResult ? (
            <Button
              type="button"
              onClick={() => {
                clipboard();
              }}
            >
              <Icon className="mr-2" icon={"files"} />
              <Text>global.buttons.copy</Text>
            </Button>
          ) : (
            <Button type="submit" loading={loadingButton}>
              <Text>global.buttons.create</Text>
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
