import { NotificationManager } from "common/notifications";
import {
    Button,
    InputGroup,
    SelectLang,
    Skeleton,
    Text,
    Wrapper,
} from "components";
import { flagCountry } from "constant";
import { useSelector, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ServiceApi, URLS } from "services";

const General = () => {
  const productFamily = useSelector((s) => s.productFmilies);
  const profile = useSelector((s) => s.profile);
  const [lang, setLang] = useState<string>("de");
  const [data, setData] = useState<any>(
    JSON.parse(JSON.stringify(productFamily))
  );
  const [loading, setLoading] = useToggleState();
  const [langItems, setLangItems] = useState([]);

  useEffect(() => {
    setLang(profile.companyDefaultLanguage)
  }, [])

  useEffect(() => {
    let arr: any = [];
    profile?.companyContentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item })
    })
    setLangItems(arr)
  }, [profile])// eslint-disable-line react-hooks/exhaustive-deps


  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const onChangeHandler = (value: any) => {
    const isAvailable = data?.translations?.filter(
      (tr: any) => tr.language.toLowerCase() === lang
    );
    let clone = [...data?.translations];

    if (isAvailable.length === 0) {
      clone.push({
        name: value,
        language: lang,
        description: "",
      });
    } else {
      data?.translations?.map((tr: any, index: number) => {
        if (tr.language.toLowerCase() === lang) {
          clone[index].name = value;
        }
      });
    }
    setData({ ...data, translations: clone });
  };

  const getTranslatedName = () => {
    const translate = data?.translations.filter(
      (tr: any) => tr.language.toLowerCase() === lang
    );
    return translate[0]?.name;
  };

  const submitHandler = () => {
    setLoading();
    const url = URLS.CREATE_GET_PUT_DELETE_PIM_PRODUCT_FAMILY_URL;
    const body = {
      pimProductFamilyId: data?.pimProductFamilyId,
      translates: data?.translations,
    };
    ServiceApi.put(url, body)
      .then(({ data }) => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.title-msg"
        );
        setLoading();
      })
      .catch((err) => setLoading());
  };

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <div className="flex justify-between items-center text-heading-2 font-semibold">
            <Text>productManagement.masterData.productFamilies.basicInfo</Text>
            {/* <Dropdown onSelect={onChangeLanguage}> */}
            <SelectLang value={lang} items={langItems} onChangeLanguage={onChangeLanguage} />
          </div>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-4">
          <div className="col-span-2 space-y-4">
            {loading ? (
              <>
                <Skeleton.Input />
                <Skeleton.Input />
              </>
            ) : (
              <>
                <InputGroup
                  value={data?.translations && getTranslatedName()}
                  setValue={(e) => onChangeHandler(e)}
                  label="Family Name"
                  flag={flagCountry[lang]}
                />
                <InputGroup
                  value={data?.code}
                  label="Family Code"
                  disabled
                  readOnly
                />
              </>
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="sticky bottom-0">
        <Wrapper.Body className="flex items-center justify-end gap-4">
          <Button as={Link} to={-1} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={submitHandler}
          // disabled={!isChanged}
          // loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Wrapper.Body>
      </Wrapper>
    </>
  );
};

export default General;
