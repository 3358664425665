import Dropdown from "./Dropdown";
import { flagCountry } from "constant";

type HandleNameProps = {
  value: any;
  items?: any;
  className?: string;
  onChangeLanguage?: any;
};

const SelectLang = ({ value, items, onChangeLanguage, className }: HandleNameProps) => {

  const langCountry: any = {
    de: "German",
    en: "English",
    tr: "Turkish",
    fr: "French",
  };

  return (
    <Dropdown onSelect={onChangeLanguage}>
      <Dropdown.Toggle
        as="button"
        type="button"
        className={`text-gray-600 font-medium text-body-base uppercase flex space-x-2 bg-gray-200 p-2 rounded-md ${className}`}
      >
        <img src={flagCountry[value]} />
        <span>{value}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="min-w-fit mt-2 p-2">
        <ul className="text-gray-700 w-[104px]">
          {items?.map((e: any) => (
            <li
              key={e.id}
              onClick={() => onChangeLanguage(e.id)}
              className={`py-2 font-medium first:mb-1 px-4 h6 ${e.id === value && "bg-primary-light text-primary "
                } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
            >
              {langCountry[e.name]}
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  )

};

export default SelectLang;
