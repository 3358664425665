export const findValue = (
  attribute: any,
  values: any,
  id: string,
  channel?: string,
  language?: string,
) => {

  const attributeValues = values?.find(
    (item: any) => item.attributeGroupId === id
  );

  const target = attributeValues?.pimProductAttributeValues?.find(
    (item: any) => item.code === attribute.code
  );

  const perChannelAttribute = () => {
    return target?.values?.find((item: any) => {
      return item.channel === channel;
    });
  };
  const perLangAttribute = () => {
    return target?.values?.find((item: any) => {
      return item.language === language;
    });
  };

  const perLangAndChannel = () => {
    return target?.values?.find((item: any) => {
      return item.language === language && item.channel === channel;
    });
  };

  const noneDependent = () => {
    return target?.values?.find((item: any) => {
      return !item.language && !item.channel;
    });
  };

  const targetObject =
    attribute?.perChannel && attribute?.perLanguage
      ? perLangAndChannel()
      : attribute?.perChannel
        ? perChannelAttribute()
        : attribute?.perLanguage
          ? perLangAttribute()
          : noneDependent();

  const output: any = {
    value: targetObject?.value,
    target: { ...target, id: target.attributeTemplateId },
    measurementFamilyUnitCode: targetObject?.measurementFamilyUnitCode
  }


  return output;
};
