const INFO_PAGES = {
  GET_INFOPAGES: "productservice/api/infopages",
  REMOVE_INFOPAGES: (id) => `productservice/api/infopages/${id}`,
  ADD_NEW_INFOPAGES: "productservice/api/infopages",
  INFOPAGES_DETAILS: (id) => `productservice/api/infopages/${id}`,
  UPDATE_INFOPAGES_DETAILS: (id) => `productservice/api/infopages/${id}`,
  ADD_NEW_LAYOUTS: (id) => `productservice/api/infopages/${id}/layout`,
  REORDER_LAYOUTS: (id) => `productservice/api/infopages/${id}/reorder-layout`,
  EDIT_INFOPAGES: (id) => `productservice/api/infopages/${id}`,
  GET_INFOPAGES_LAYOUT: (layoutId) =>
    `productservice/api/infopages/layout/${layoutId}`,
  ADD_LAYOUT_TO_INFOPAGES: (id) => `productservice/api/infopages/${id}/layout`,
  REMOVE_LAYOUT_FROM_INFOPAGES: (id, layoutId) =>
    `productservice/api/infopages/${id}/layout/${layoutId}`,
  EDIT_LAYOUT_INFOPAGES: (id, layoutId) =>
    `productservice/api/infopages/${id}/layout/${layoutId}`,
  GET_CHANNEL_PRODUCTS:
    "productservice/api/channelproducts/get-channelproduct-by-channelcode",
};

export default INFO_PAGES;
