export const attributes = {
  addAttributes: "Add attributes",
  code: "Code",
  name: "Name",
  title: "Title",
  description: "Description",
  shortDescription: "Short Description",
  perChannel: "Per Channel",
  type: "Type",
  attributeType: "Attribute Type",
  attributeTypeDesc: "Text, Date, Yes/No ...",
  infoAndProperties: "Info and properties",
  infoAndPropertiesDesc: "Name, Description ...",
  review: "Review",
  reviewDesc: "Finalize information",
  attributeName: "Attribute Name",
  attributeCode: "Attribute Code",
  isRequired: "Is Required",
  perLanguage: "Per Language",
  chooseAttributeType: "Choose Attribute type",
  basicInfo: "Basic info",
  properties: "Properties",
  translation: "Translation",
  general: "General",
  channels: "Channels",
  setting: "Setting",
  itemsList: "Items List",
  default: "Default",
  addItem: "Add Item",
  editItem: "Edit Item",
  positiveName: "Positive Name",
  negativeName: "Negative Name",
  updatefromProviderAttribute: "Update from provider attribute",
  showAttributeInFilters: "Show attribute in filters",
  attributeDefaultValue: "Attribute Default Value",
  measurementTypeList: "Measurement Type List",
  unitsList: "Units List",
  order: "Order",
  placeHolder: "Placeholder",
  value: "Value",
};
