import { NotificationManager } from "common/notifications";
import {
    Button,
    Drawer,
    Dropdown,
    Icon,
    InputGroup,
    Text,
} from "components";
import { flagCountry } from "constant";
import { useSelector, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { ServiceApi } from "services";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  name?: any;
  setValue?: any;
};

const AiGeneratorDrawer = ({ isOpen, toggle, name, setValue }: drawerProps) => {
  const profile = useSelector((s) => s.profile);
  const [lang, setLang] = useState<string>("de");
  const [generatedText, setGeneratedText] = useState<string>("");
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = useToggleState();
  const [data, setData] = useState<any>({
    ProductName: name,
    MoreInfo: "",
    LanguageCode: lang,
    MaxWord: 80,
  });

  const langCountry: any = {
    de: "German",
    en: "English",
    tr: "Turkish",
    fr: "French",
  };

  useEffect(() => {
    let arr: any = {};
    let arrItem: any = [];
    profile?.companyContentLanguages?.forEach((item: any) => {
      arr[item] = item;
      arrItem.push({
        name: item,
        id: item,
      });
    });
    setItems(arrItem);
  }, []);

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
    setData((p: any) => ({ ...p, LanguageCode: lang }));
  };

  const onChangeHandler = (key: any) => {
    return (value: any) =>
      setData((p: any) => {
        if (key === "MaxWord") {
          return { ...p, [key]: +value };
        }
        return { ...p, [key]: value };
      });
  };

  const generateHandler = () => {
    setGeneratedText("");
    setLoading();
    ServiceApi.post(
      "https://pulsopenai.azurewebsites.net/api/GenerateDescription?code=LeP5QiU8IAEwhQafSEZI6vefzv31TnYD2hY1-B6u8OjUAzFuovasjQ==",
      data
    )
      .then(({ data }) => {
        setLoading();
        setGeneratedText(data);
      })
      .catch(() => setLoading());
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedText);
    NotificationManager.success(
      "global.toast.copy-msg",
      "global.toast.copy-title"
    );
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.Details.aiTextGenerator</Text>
            </span>
            <div className="flex gap-x-2">
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-6 pr-4">
          <p className="text-sm text-gray-800">
            <Text>productManagement.products.Details.aiDescription</Text>
          </p>
          <p className="text-sm font-semibold block text-gray-800">{name}</p>
          <div>
            <span className="text-sm">
              <Text>productManagement.products.Details.language</Text>
            </span>
            <Dropdown className="w-full mt-2">
              <Dropdown.Toggle
                as="button"
                type="button"
                className="justify-between text-gray-600 w-full bg-gray-100 font-medium text-body-base uppercase flex space-x-2 p-3 rounded-md"
              >
                <div className="flex space-x-2">
                  <img src={flagCountry[lang]} />
                  <span>{lang}</span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="min-w-fit mt-2 p-2">
                <ul className="text-gray-700">
                  {items?.map((e: any) => (
                    <li
                      key={e.id}
                      onClick={() => onChangeLanguage(e.id)}
                      className={`py-2 font-medium first:mb-1 px-4 h6 ${
                        e.id === lang && "bg-primary-light text-primary "
                      } hover:bg-primary-light hover:text-primary cursor-pointer rounded-lg text-body-base`}
                    >
                      {langCountry[e.name]}
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* info */}
          <InputGroup
            label={"productManagement.products.Details.basicInfo"}
            value={data?.MoreInfo}
            setValue={onChangeHandler("MoreInfo")}
          />
          <InputGroup
            type="number"
            label={"productManagement.products.Details.wordCount"}
            value={data?.MaxWord}
            setValue={onChangeHandler("MaxWord")}
          />
          <Button size="sm" onClick={generateHandler} loading={loading}>
            <Text>productManagement.products.Details.generate</Text>
          </Button>
          <InputGroup as={"textarea"} value={generatedText} />
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button size="sm" onClick={toggle} variant={"light"}>
            <Text>Close</Text>
          </Button>
          <div className="space-x-4">
            <Button
              size="sm"
              disabled={loading || generatedText === ""}
              onClick={copyToClipboard}
              light
            >
              <Text>productManagement.products.Details.copy</Text>
            </Button>
            <Button
              size="sm"
              disabled={loading || generatedText === ""}
              onClick={() => {
                setValue(generatedText);
                toggle();
              }}
              light
            >
              <Text>productManagement.products.Details.setValue</Text>
            </Button>
          </div>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
};

export default AiGeneratorDrawer;
