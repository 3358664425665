import {
    InputGroup,
} from "components";
import { rules } from "constant";
import { Fragment, useContext } from "react";
import { AddSupplierContext } from "..";

export default function General() {
  const { data, setData } = useContext<any>(AddSupplierContext);

  const handleSetData = (key:any) => {
    return (value:any) => setData((p:any) => ({ ...p, [key]: value }));
  };


  return (
    <Fragment>
        <InputGroup
          label="productManagement.masterData.suppliers.code"
          value={data?.code}
          setValue={handleSetData("code")}
          rules={rules.required}
        />
        <InputGroup
          label="productManagement.masterData.suppliers.name"
          value={data?.name}
          setValue={handleSetData("name")}
          rules={rules.required}
        />
        <InputGroup
          label="productManagement.masterData.suppliers.ilnNumber"
          value={data?.iln}
          setValue={handleSetData("iln")}
        />
        <InputGroup
          as="textarea"
          label="productManagement.masterData.suppliers.description"
          value={data?.description}
          setValue={handleSetData("description")}
        />
    </Fragment>
  );
}
