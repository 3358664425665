const required = [
  (val: any) => `${val ?? ""}`.length !== 0 || "This field is required",
];
const emailRequired = [
  ...required,
  (val: any) => {
    return (
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(val) ||
      "Please provide a valid email address"
    );
  },
];
const emailAddress = [
  (val: any) => {
    if (`${val ?? ""}`.length !== 0) {
      return (
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
          val
        ) || "Please provide a valid email address"
      );
    }
    return true;
  },
];
const discount = [
  ...required,
  (val: any) =>
    (val >= 0 && val <= 100) ||
    "Value must be between 0 and 100",
];
const color = [
  ...required,
  (val: any) => {
    var s = new Option().style;
    s.color = val;
    // console.log(s.color);
    const validation = (s.color ?? "") !== "";
    return validation || "Color is not valid.";
  },
];
const url = [
  ...required,
  (val: any) =>
    String(val ?? "").startsWith("https") || "Url must start with https.",
  (val: any) => {
    let valid;
    try {
      new URL(val);
      valid = true;
    } catch (error) {
      valid = false;
    }
    return valid || "Url is not valid";
  },
];
const rules = { required, emailRequired, emailAddress, discount, color, url };
export default rules;
