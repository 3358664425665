import { useNavigate } from "react-router";

export const useMenu = () => {
  const navigate = useNavigate();

  const menu = [
    //dashboard
    {
      label: "menu.dashboard",
      path: "/ ",
      icon: "gauge",
      command: () => {
        navigate("/");
      },
    },
    //productManagement
    {
      label: "menu.productManagement.root",
      path: "productManagement",
      icon: "box",
      items: [
        {
          label: "menu.productManagement.masterData.root",
          path: "master-data",
          icon: "grip-dots",
          items: [
            {
              label: "menu.productManagement.masterData.Attributes",
              icon: "rectangle-list",
              path: "attributes",
              pathname: "/productManagement/master-data/attributes",
              command: () => {
                navigate("/productManagement/master-data/attributes");
              },
            },
            {
              label: "menu.productManagement.masterData.AttributeGroups",
              icon: "layer-group",
              path: "attribute-groupes",
              command: () => {
                navigate("/productManagement/master-data/attribute-groupes");
              },
            },
            {
              label: "menu.productManagement.masterData.ProductFamilies",
              icon: "diagram-project",
              path: "product-families",
              command: () => {
                navigate("/productManagement/master-data/product-families");
              },
            },
            {
              label: "menu.productManagement.masterData.ProductCategories",
              icon: "list-tree",
              path: "product-categories",
              command: () => {
                navigate("/productManagement/master-data/product-categories");
              },
            },
            {
              label: "menu.productManagement.masterData.MeasurementUnits",
              icon: "ruler-combined",
              path: "measurement-units",
              command: () => {
                navigate("/productManagement/master-data/measurement-units");
              },
            },
            {
              label: "menu.productManagement.masterData.Suppliers",
              icon: "industry-windows",
              path: "suppliers",
              command: () => {
                navigate("/productManagement/master-data/suppliers");
              },
            },
            {
              label: "menu.productManagement.masterData.Brands",
              icon: "copyright",
              path: "brands",
              command: () => {
                navigate("/productManagement/master-data/brands");
              },
            },
          ],
        },
        {
          label: "menu.productManagement.products.root",
          icon: "grip-dots",
          path: "products",
          items: [
            {
              label: "menu.productManagement.products.AllProducts",
              icon: "grid-2",
              path: "all-products",
              command: () => {
                navigate("/productManagement/products/all-products");
              },
            },
            {
              label: "menu.productManagement.products.ImportProduct",
              icon: "grid-2-plus",
              path: "import-product",
              command: () => {
                navigate("/productManagement/products/import-product");
              },
            },
          ],
        },
        // {
        //   label: "menu.productManagement.setting.root",
        //   icon: "grip-dots",
        //   path: "settings",
        //   items: [
        //     {
        //       label: "menu.productManagement.setting.Configurations",
        //       icon: "gear",
        //       path: "configurations",
        //       command: () => {
        //         navigate("/productManagement/settings/configurations");
        //       },
        //     },
        //   ],
        // },
      ],
    },
    //salesManagement
    {
      label: "menu.salesManagement.root",
      icon: "cart-circle-check",
      path: "salesmanagment",
      items: [
        {
          label: "menu.salesManagement.sellOffSales.root",
          icon: "grip-dots",
          path: "sell-off-sales",
          items: [
            {
              label: "menu.salesManagement.sellOffSales.sellOffProducts",
              icon: "box-open",
              path: "sell-off-products",
              command: () => {
                navigate("/salesmanagment/sell-off-sales/sell-off-products");
              },
            },
            {
              label: "menu.salesManagement.sellOffSales.salesTemplates",
              icon: "timeline-arrow",
              path: "sales-templates",
              command: () => {
                navigate("/salesmanagment/sell-off-sales/sales-templates");
              },
            },
            {
              label: "menu.salesManagement.sellOffSales.salesChecklist",
              icon: "square-sliders-vertical",
              path: "sales-check-list",
              command: () => {
                navigate("/salesmanagment/sell-off-sales/sales-check-list");
              },
            },
            {
              label: "menu.salesManagement.sellOffSales.SellOffCommissions",
              icon: "file-invoice-dollar",
              path: "sell-off-commissions",
              command: () => {
                navigate("/salesmanagment/sell-off-sales/sell-off-commissions");
              },
            },
            {
              label: "menu.salesManagement.sellOffSales.preparationPhase",
              icon: "file-invoice-dollar",
              path: "preparation-phase",
              command: () => {
                navigate("/salesmanagment/sell-off-sales/preparation-phase");
              },
            },
          ],
        },
        // {
        //   label: "menu.salesManagement.settings.root",
        //   icon: "grip-dots",
        //   path: "settings",
        //   items: [
        //     {
        //       label: "menu.salesManagement.settings.salesChannels",
        //       icon: "chart-network",
        //       path: "sales-channels",
        //       command: () => {
        //         navigate("/salesmanagment/settings/sales-channels");
        //       },
        //     },
        //   ],
        // },
        // {
        //   label: "menu.salesManagement.reports",
        //   icon: "grip-dots",
        //   path: "reports",
        //   command: () => {
        //     navigate("/salesmanagment/reports");
        //   },
        // },
        {
          label: "menu.salesManagement.kitchenSales.root",
          icon: "grip-dots",
          path: "kitchen-sales",
          items: [
            {
              label: "menu.salesManagement.kitchenSales.salesList",
              icon: "circle-small",
              path: "sales-list",
              command: () => {
                navigate("/salesmanagment/kitchen-sales/sales-list");
              },
            },
            {
              label: "menu.salesManagement.kitchenSales.salesReport",
              icon: "print-magnifying-glass",
              path: "sales-report",
              command: () => {
                navigate("/salesmanagment/kitchen-sales/sales-report");
              },
            },
            {
              label: "menu.salesManagement.kitchenSales.configuration",
              icon: "square-sliders-vertical",
              path: "configurations",
              command: () => {
                navigate("/salesmanagment/kitchen-sales/configurations");
              },
            },
          ],
        },
        {
          label: "menu.salesManagement.salesArea.root",
          icon: "grip-dots",
          path: "sales-area",
          items: [
            {
              label: "menu.salesManagement.salesArea.locations",
              icon: "circle-small",
              path: "locations",
              command: () => {
                navigate("/salesmanagment/sales-area/locations");
              },
            },
          ],
        },
      ],
    },
    //applications
    {
      label: "menu.applications.root",
      icon: "laptop-mobile",
      path: "applications",
      items: [
        {
          label: "menu.applications.mobileApp.root",
          icon: "grip-dots",
          path: "mobile-app",
          items: [
            {
              label: "menu.applications.mobileApp.positions",
              icon: "chart-tree-map",
              path: "positions",
              command: () => {
                navigate("/applications/mobile-app/positions");
              },
            },
            {
              label: "menu.applications.mobileApp.infoPages",
              icon: "file-word",
              path: "info-pages",
              command: () => {
                navigate("/applications/mobile-app/info-pages");
              },
            },
            // {
            //   label: "menu.applications.mobileApp.contentManagement",
            //   icon: "file-word",
            //   path: "content-managment",
            //   command: () => {
            //     navigate("/applications/mobile-app/content-managment");
            //   },
            // },
            // {
            //   label: "menu.applications.mobileApp.settings",
            //   icon: "square-sliders-vertical",
            //   path: "settings",
            //   command: () => {
            //     navigate("/applications/mobile-app/settings");
            //   },
            // },
          ],
        },
      ],
    },
    //THIRD PARTY
    {
      label: "menu.thirdParty.root",
      icon: "gear",
      path: "thirdParty",
      items: [
        {
          label: "menu.thirdParty.crmConfiguration",
          icon: "square-sliders-vertical",
          path: "crm-configuration",
          command: () => {
            navigate("/thirdParty/crm-configuration");
          },
        },
        {
          label: "menu.thirdParty.erpConfiguration",
          icon: "square-sliders-vertical",
          path: "erp-configuration",
          command: () => {
            navigate("/thirdParty/erp-configuration");
          },
        },
        {
          label: "menu.thirdParty.nopCommerce",
          icon: "square-sliders-vertical",
          path: "nop-commerce",
          command: () => {
            navigate("/thirdParty/nop-commerce");
          },
        },
        {
          label: "menu.thirdParty.iwofurn",
          icon: "square-sliders-vertical",
          path: "iwofurn",
          command: () => {
            navigate("/thirdParty/iwofurn");
          },
        },
      ],
    },
    //company
    {
      label: "menu.company.root",
      icon: "buildings",
      path: "company",
      items: [
        {
          label: "menu.company.profile",
          icon: "memo-pad",
          path: "profile",
          command: () => {
            navigate("/company/profile");
          },
        },
        {
          label: "menu.company.stores",
          icon: "store",
          path: "stores",
          command: () => {
            navigate("/company/stores");
          },
        },
        {
          label: "menu.company.employees",
          icon: "building-user",
          path: "employees",
          command: () => {
            navigate("/company/employees");
          },
        },
        {
          label: "menu.company.customers.root",
          icon: "users-viewfinder",
          path: "customers",
          items: [
            {
              label: "menu.company.customers.customerList",
              icon: "address-card",
              path: "customer-list",
              command: () => {
                navigate("/company/customers/customer-list");
              },
            },
            // {
            //   label: "menu.company.customers.Appointments",
            //   icon: "calendar-circle-user",
            //   path: "appointments",
            //   command: () => {
            //     navigate("/company/customers/appointments");
            //   },
            // },
          ],
        },
        {
          label: "menu.company.legalEntities",
          icon: "gavel",
          path: "legal-entities",
          command: () => {
            navigate("/company/legal-entities");
          },
        },
      ],
    },
    //configurations
    {
      label: "menu.configurations.root",
      icon: "gear",
      path: "configurations",
      items: [
        // {
        //   label: "menu.configurations.notifications.root",
        //   icon: "grip-dots",
        //   path: "notifications",
        //   items: [
        //     {
        //       label: "menu.configurations.notifications.alerts",
        //       icon: "bell",
        //       path: "alerts",
        //       command: () => {
        //         navigate("/configurations/notifications/alerts");
        //       },
        //     },
        //     {
        //       label: "menu.configurations.notifications.actionGroups",
        //       icon: "users-rays",
        //       path: "action-groups",
        //       command: () => {
        //         navigate("/configurations/notifications/action-groups");
        //       },
        //     },
        //   ],
        // },
        {
          label: "menu.configurations.globalSettings.root",
          icon: "grip-dots",
          path: "global-settings",
          items: [
            {
              label: "menu.configurations.globalSettings.apiKeys",
              icon: "key",
              path: "api-keys",
              command: () => {
                navigate("/configurations/global-settings/api-keys");
              },
            },
            {
              label: "menu.configurations.globalSettings.ssoSettings",
              icon: "circle-small",
              path: "sso-settings",
              command: () => {
                navigate("/configurations/global-settings/sso-settings");
              },
            },
            {
              label: "menu.configurations.globalSettings.dataStorage",
              icon: "circle-small",
              path: "data-storage",
              command: () => {
                navigate("/configurations/global-settings/data-storage");
              },
            },
          ],
        },
        {
          label: "menu.configurations.userManagement.root",
          icon: "grip-dots",
          path: "user-managment",
          items: [
            {
              label: "menu.configurations.userManagement.usersList",
              icon: "users",
              path: "users-list",
              command: () => {
                navigate("/configurations/user-managment/users-list");
              },
            },
            {
              label: "menu.configurations.userManagement.userGroups",
              icon: "screen-users",
              path: "user-groups",
              command: () => {
                navigate("/configurations/user-managment/user-groups");
              },
            },
            {
              label: "menu.configurations.userManagement.roles",
              icon: "circle-small",
              path: "roles",
              command: () => {
                navigate("/configurations/user-managment/roles");
              },
            },
          ],
        },
      ],
    },
  ];
  return { menu };
};
