type SkeletonImageListProps = {
  image?: Boolean;
};

const Skeleton = () => {
  return <div className="h-9 w-20 ml-auto mt-auto bg-slate-200 rounded"></div>;
};

const SkeletonImageList = ({ image = true }: SkeletonImageListProps) => {
  return (
    <div className="border border-gray bg-white shadow-sm rounded-md p-4 min-w-full w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        {image && <div className="rounded-md bg-slate-200 h-20 w-32"></div>}
        <div className="flex-1 space-y-6 py-1">
          <div className="flex items-center gap-4">
            <div className="space-y-3 flex-1">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
            </div>
            <div className="h-9 w-20 bg-slate-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonInput = () => {
  return (
    <div className="h-11 w-full ml-auto mt-auto bg-slate-100 rounded animate-pulse"></div>
  );
};

const SkeletonTextArea = () => {
  return (
    <div className="h-40 w-full ml-auto mt-auto bg-slate-100 rounded animate-pulse"></div>
  );
};

const SkeletonButton = () => {
  return <div className="h-9 w-20 bg-slate-200 rounded animate-pulse"></div>;
};

Skeleton.ImageList = SkeletonImageList;
Skeleton.List = () => <SkeletonImageList image={false} />;
Skeleton.Input = SkeletonInput;
Skeleton.Button = SkeletonButton;
Skeleton.TextArea = SkeletonTextArea;
export default Skeleton;
