import {
  DatePicker,
  InputGroup,
  Skeleton,
  Text,
  Wrapper
} from "components";
import {
  Fragment,
  useContext
} from "react";
import { AttributesTypes } from "utils";
import { AttributesContext } from "..";

export default function AttributesTypeDate() {
  const { attributesData, setAttributesData, loading } = useContext<any>(AttributesContext);
  const handleSetData = (key: any) => {
    return (value: any) => setAttributesData((p: any) => ({ ...p, [key]: value }));
  };

  return (
    <Fragment>
      <div className="flex">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) :
          <Wrapper>
            <Wrapper.Header>
              <h2 className="text-gray-800 font-semibold" ><Text>productManagement.masterData.attributes.setting</Text></h2>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.attributes.attributeType"
                  value={AttributesTypes(attributesData?.type)}
                  disabled
                />
                {attributesData.type === 40 && <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  label="productManagement.masterData.attributes.attributeDefaultValue"
                  value={attributesData?.defaultValue !== "" ? new Date(attributesData?.defaultValue) : null}
                  onChange={handleSetData("defaultValue")}
                />
                }
                {attributesData.type === 50 && <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  label="productManagement.masterData.attributes.attributeDefaultValue"
                  showTimeSelectOnly
                  showTimeSelect
                  value={attributesData?.defaultValue !== "" ? new Date(attributesData?.defaultValue) : null}
                  onChange={handleSetData("defaultValue")}
                />
                }
                {attributesData.type === 60 && <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  label="productManagement.masterData.attributes.attributeDefaultValue"
                  showTimeSelect
                  value={attributesData?.defaultValue !== "" ? new Date(attributesData?.defaultValue) : null}
                  onChange={handleSetData("defaultValue")}
                />
                }

              </div>
            </Wrapper.Body>
          </Wrapper>
        }
      </div>
    </Fragment>
  );
}
