import SuppliersList from "pages/product-managment/master-data/suppliers";
import Create from "pages/product-managment/master-data/suppliers/create";
import Details from "pages/product-managment/master-data/suppliers/details";

const Suppliers = [
  {
    path: "",
    element: <SuppliersList />,
  },
  {
    path: "new",
    element: <Create />,
  },
  {
    path: ":supplierId",
    element: <Details />,
  },
];

export default Suppliers;
