import { NotificationManager } from "common/notifications";
import {
  AddressViewer,
  Breadcrumb,
  Button,
  Footer,
  Form,
  Image,
  ImageUploader,
  Skeleton,
  Tabs,
  Text,
  Wrapper,
} from "components";
import { appPermissions } from "constant";
import {
  useDataState,
  useMountEffect,
  usePermission,
  useToggleState,
} from "hooks";
import React, { Fragment, createContext, createElement, useState } from "react";
import { useDispatch } from "react-redux";
import { ServiceApi, URLS } from "services";
import { setProfile } from "store/profile";
import { Media } from "types";
import General from "./General";
import Language from "./Language";
import Setting from "./Setting";
import Channels from "./Channels";

type dataType = {
  avatarUrl?: string | null;
  name?: string | null;
  city?: string | null;
  country?: string | null;
  houseNo?: string | null;
  postalCode?: string | null;
  state?: string | null;
  street?: string | null;
};

export const ProfileContext = createContext({});

const ProfileCompany: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<dataType>({});
  const [isOpen, toggle] = useToggleState(false);

  //permission
  const { AS_UpdateMyCompany, AS_GetMyCompany } = appPermissions;
  const GET_COMPANY = usePermission(AS_GetMyCompany);
  const UPDATE_COMPANY = usePermission(AS_UpdateMyCompany);

  const tabs = [
    {
      label: "company.profile.general",
      component: General,
    },
    {
      label: "company.profile.language",
      component: Language,
    },
    {
      label: "company.profile.setting",
      component: Setting,
    },
    {
      label: "company.profile.channels",
      component: Channels,
    },
  ];

  const updateUserProfile = async () => {
    const result = await ServiceApi.get(URLS.B2B_USER_URL);
    // console.log(result.data);
    dispatch(setProfile(result.data));
  };

  const getCompanyProfile = () => {
    const url = URLS.MY_COMPANY_URL;
    setLoading(true);
    ServiceApi.get(url)
      .then(async (response) => {
        setBaseData(response.data);
        updateUserProfile();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useMountEffect(getCompanyProfile);

  const onSubmit = () => {
    const url = URLS.MY_COMPANY_URL;
    setSubmitLoading(true);
    ServiceApi.put(url, data)
      .then(async () => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        getCompanyProfile();
        updateUserProfile();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
    //set basedata on call put api
  };

  const onUploadImage = (data: Media) => {
    // setData((p) => ({ ...p, avatarUrl: data.url }));
    const url = URLS.ADD_IMAGE_TO_MY_COMPANY_URL;
    const body = {
      url: data.url,
      type: data.type,
      thumbnailUrl: data.thumbnailUrl,
    };
    ServiceApi.patch(url, body).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
      getCompanyProfile();
    });
  };

  return (
    <Fragment>
      {GET_COMPANY ? (
        <Tabs activeKey={tabs[0].label}>
          <Form className="relative space-y-6" onSubmit={onSubmit}>
            <Breadcrumb />
            <Wrapper>
              <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
                <Image
                  onClick={() => toggle()}
                  src={data.avatarUrl}
                  alt={""}
                  logo
                  editor
                  className="flex flex-center w-full aspect-image lg:w-80 lg:h-40 lg:aspect-auto cursor-pointer bg-gray-100 p-2"
                />
                {!loading && (
                  <div className="lg:flex-1 space-y-4">
                    <h6 className="text-heading-6 text-gray-800">
                      {data.name}
                    </h6>
                    <AddressViewer
                      address={{
                        city: data.city,
                        country: data.country,
                        houseNo: data.houseNo,
                        postalCode: data.postalCode,
                        state: data.state,
                        street: data.street,
                      }}
                    />
                  </div>
                )}
                {loading && (
                  <div className="lg:flex-1 space-y-4">
                    <Fragment>
                      <Skeleton.Input />
                      <Skeleton.Input />
                    </Fragment>
                  </div>
                )}
              </Wrapper.Body>
              <Wrapper.Footer className="!py-0">
                <Tabs.ButtonGroup>
                  {tabs.map((e) => (
                    <Tabs.Button key={e.label} eventKey={e.label}>
                      <Text>{e.label}</Text>
                    </Tabs.Button>
                  ))}
                </Tabs.ButtonGroup>
              </Wrapper.Footer>
            </Wrapper>
            {loading ? (
              <Wrapper>
                <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Wrapper.Body>
              </Wrapper>
            ) : (
              tabs.map((e) => (
                <Tabs.Item key={e.label} eventKey={e.label}>
                  <ProfileContext.Provider
                    value={{
                      profileData: data,
                      setProfileData: setData,
                    }}
                  >
                    {createElement(e.component)}
                  </ProfileContext.Provider>
                </Tabs.Item>
              ))
            )}
            {!loading && UPDATE_COMPANY && (
              <Footer show={!isChanged}>
                <Button
                  type="submit"
                  // onClick={onSubmit}
                  variant="primary"
                  disabled={!isChanged}
                  loading={submitLoading}
                >
                  <Text>global.buttons.saveChanges</Text>
                </Button>
              </Footer>
            )}
          </Form>
          <ImageUploader
            isOpen={isOpen}
            toggle={toggle}
            image={data.avatarUrl}
            onUpload={onUploadImage}
          />
        </Tabs>
      ) : null}
    </Fragment>
  );
};

export default ProfileCompany;
