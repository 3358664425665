import { NotificationManager } from "common/notifications";
import { Image, ImageUploader, Text, Toggler } from "components";
import { useConverter, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import ProductFamilyPath from "../helpers/product-family-path";
type Props = {
  productData: any;
  loading: boolean;
  setData: any;
  setPatchData: any;
  getPIMProduct: any;
};

const Info = ({
  productData,
  loading,
  setPatchData,
  setData,
  getPIMProduct,
}: Props) => {
  const { convertDate } = useConverter();
  const [isOpen, toggle] = useToggleState();
  const [programBrand, setProgramBrand] = useState<any>();


  useEffect(() => {
    //setBrand
    if (productData?.supplierId && productData?.brandId) {
      findBrandInfo();
    } else {
      setProgramBrand({});
    }
  }, [productData?.brandId]);


  const findBrandInfo = () => {
    const id = URLS.GET_BRAND_DETAILS(productData?.brandId);
    ServiceApi.get(id).then(({ data }) => {
      setProgramBrand(data);
    });
  };

  const onChangeHandler = (key: string) => {
    return (value: any) => {
      setPatchData((p: any) => ({ ...p, [key]: value }));
      return setData((p: any) => ({ ...p, [key]: value }));
    };
  };

  const onUploadHandler = (image: any) => {
    const url = URLS.UPDATE_PIM_PRODUCT_AVATAR_URL(productData?.id);
    ServiceApi.patch(url, image).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
      getPIMProduct();
    });
  };

  return (
    <>
      <div className="flex space-x-4">
        <Image
          className="w-[349px] h-[191px] bg-gray-100 cursor-pointer"
          src={productData?.avatarUrl}
          onClick={toggle}
          editor
        />
        <section className="space-y-4">
          {loading ? (
            <div className="w-96 animate-pulse space-y-4">
              <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              <div className="h-2 bg-slate-200 rounded col-span-2"></div>
              <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              <div className="h-2 bg-slate-200 rounded col-span-2"></div>
              <div className="h-2 bg-slate-200 rounded col-span-1"></div>
            </div>
          ) : (
            <>
              <h5 className="text-gray-800">{productData?.sku}</h5>
              <div className="flex gap-y-4 gap-x-10 text-body-2">
                <div className="space-y-4">
                  <p className="text-gray-500 font-medium">
                    <Text>productManagement.products.Details.articleNumber</Text>
                    <span className="text-gray-700 font-medium">: {productData?.articleNumber ? `#${productData?.articleNumber}` : "--"}</span>
                  </p>
                  <p className="text-gray-500 font-medium">
                    <Text>
                      productManagement.products.Details.supplierArticleNumber
                    </Text>
                    <span className="text-gray-700 font-medium"> : {productData?.productKey ? productData?.productKey : "--"}</span>
                  </p>
                  <p className="text-gray-500 font-medium">
                    <Text>productManagement.products.Details.importVersion</Text>
                    <span className="text-gray-700 font-medium">   : {productData?.connectionInfo?.productImportVersion ? productData?.connectionInfo?.productImportVersion : "--"}</span>
                  </p>
                  <p className="text-gray-500 font-medium">
                    <Text>
                      productManagement.products.Details.currentProductVersion
                    </Text>
                    <span className="text-gray-700 font-medium">  : {productData?.version ? productData?.version : "--"}</span>
                  </p>
                  <div className="flex gap-2">
                    {productData?.brandName && <p className="flex gap-2 items-center pr-2 border-r-[2px] border-gray-300">
                      {programBrand?.logoUrl && (
                        <Image
                          logo
                          src={programBrand?.logoUrl}
                          className="w-auto h-6 border-none !rounded-none"
                        />
                      )}
                      <span className="text-gray-700 font-medium"> {productData?.brandName}</span>
                    </p>}
                    <p className="flex gap-2 items-center">
                      {productData?.supplierInfo?.logoUrl && (
                        <Image
                          logo
                          src={productData?.supplierInfo?.logoUrl}
                          className="w-auto h-6 border-none !rounded-none"
                        />
                      )}
                      <span className="text-gray-700 font-medium"> {productData?.supplierName}</span>
                    </p>
                  </div>
                </div>
                <div className="space-y-4">
                  <p className="text-gray-500 font-medium">
                    <Text>productManagement.products.Details.productFamily</Text>
                    <span className="text-gray-700 font-medium">:{" "}
                      <ProductFamilyPath
                        productData={productData}
                        pimProductFamilyId={productData?.pimProductFamilyId}
                      /></span>
                  </p>
                  <p className="text-gray-500 font-medium">
                    <Text>productManagement.products.Details.category</Text>:{" "}
                    <span className="text-gray-700 font-medium">
                      {productData?.detailCategories?.map(
                        (detail: any, index: number) => {
                          return (
                            detail.translates?.[0].name +
                            (index !== productData?.detailCategories?.length - 1
                              ? " | "
                              : "")
                          );
                        }
                      )}
                    </span>
                  </p>
                  <p className="text-gray-500 font-medium">
                    <Text>productManagement.products.Details.creation</Text>:{" "}
                    <span className="text-gray-700 font-medium">
                      {productData?.creatorName}{" "}
                      {convertDate(productData?.creationAt)}
                    </span>
                  </p>
                  <p className="text-gray-500 font-medium">
                    <Text>productManagement.products.Details.modify</Text>:{" "}
                    <span className="text-gray-700 font-medium">
                      {productData?.lastModifierName}{" "}
                      {convertDate(productData?.lastModifiedAt)}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <div className="flex gap-4 items-start flex-none">
        {loading ? (
          <div className="h-9 w-20 ml-auto bg-slate-200 rounded"></div>
        ) : (
          <Toggler
            size="sm"
            label={
              productData?.active
                ? "productManagement.products.Details.active"
                : "productManagement.products.Details.notActive"
            }
            value={productData?.active}
            setValue={onChangeHandler("active")}
          />
        )}
      </div>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={""}
        onUpload={(image) => onUploadHandler(image)}
      />
    </>
  );
};

export default Info;
