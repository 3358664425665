import { NotificationManager } from "common/notifications";
import {
    Breadcrumb,
    Button,
    DeleteModal,
    HandleName,
    Icon,
    Tabs,
    Text,
    Wrapper
} from "components";
import { useToggleState } from "hooks";
import { createContext, createElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import LeftSide from "./leftSide";
import Channels from "./tabs/channels";
import General from "./tabs/general";
import Media from "./tabs/media";
import SubCategories from "./tabs/sub-categories";

export const CategoryContext = createContext<any>({});

const ProductCategory = () => {
  const { i18n } = useTranslation();
  const [roots, setRoots] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [deleteModal, setDeleteModal] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [loading, setLoading] = useToggleState();

  const tabs = [
    {
      label: "productManagement.masterData.productCategory.generalTab",
      component: General,
    },
    {
      label: "productManagement.masterData.productCategory.subCategoriesTab",
      component: SubCategories,
    },
    {
      label: "productManagement.masterData.productCategory.mediaTab",
      component: Media,
    },
    {
      label: "productManagement.masterData.productCategory.channelsTab",
      component: Channels,
    },
  ];

  const getCategories = () => {
    setLoading();
    const url = URLS.GET_ROOT_CATEGORIES;
    ServiceApi.get(url)
      .then(({ data }) => {
        setRoots(data);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    getCategories();
  }, []);

  const selectedCategory = roots[selectedIndex];

  const onDeleteHandler = () => {
    setDeleteLoading();
    const url = URLS.DELETE_CATEGORY(selectedCategory?.productCategoryId);
    ServiceApi.delete(url)
      .then(() => {
        setSelectedIndex(0);
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        setDeleteLoading();
        setDeleteModal();
        getCategories();
      })
      .catch(() => setDeleteLoading());
  };

  return (
    <div className="space-y-4">
      <Breadcrumb />
      <div className="flex gap-8">
        <LeftSide
          loading={loading}
          roots={roots}
          setRoots={setRoots}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          getCategories={getCategories}
        />
        {roots?.length !== 0 &&
          <div className="w-full space-y-6">
            <Tabs activeKey={tabs[0].label}>
              <Wrapper>
                <Wrapper.Body className="flex justify-between items-center">
                  <div className="space-y-4">
                    <span className="text-heading-5 font-semibold text-gray-800">
                      {<HandleName translations={selectedCategory?.translations} code={selectedCategory?.code} keyName="name"  />}
                    </span>
                    <p className="text-body-2 font-medium">
                      {selectedCategory?.code}
                    </p>
                  </div>
                  <Button
                    variant={"danger"}
                    light
                    size="sm"
                    onClick={() => {
                      setDeleteModal();
                    }}
                  >
                    <Icon icon={"trash"} className="mr-2" />
                    <Text>
                      productManagement.masterData.productCategory.deleteButton
                    </Text>
                  </Button>
                </Wrapper.Body>
                <Wrapper.Footer className="!py-0">
                  <Tabs.ButtonGroup>
                    {tabs.map((e) => (
                      <Tabs.Button key={e.label} eventKey={e.label}>
                        <Text>{e.label}</Text>
                      </Tabs.Button>
                    ))}
                  </Tabs.ButtonGroup>
                </Wrapper.Footer>
              </Wrapper>
              {tabs.map((e) => (
                <CategoryContext.Provider
                  value={{
                    selectedCategory,
                    setRoots,
                    selectedIndex,
                    roots,
                    getCategories,
                  }}
                >
                  <Tabs.Item key={e.label} eventKey={e.label}>
                    {createElement(e.component)}
                  </Tabs.Item>
                </CategoryContext.Provider>
              ))}
            </Tabs>
          </div>
        }
      </div>
      <DeleteModal
        isOpen={deleteModal}
        loading={deleteLoading}
        toggle={setDeleteModal}
        onConfirm={onDeleteHandler}
        selected={{ id: selectedCategory?.code, name: <HandleName translations={selectedCategory?.translations} code={selectedCategory?.code} keyName="name"  /> }}
      />
    </div>
  );
};

export default ProductCategory;
