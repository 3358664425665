import {
  InputGroup,
} from "components";
import { Fragment, useContext } from "react";
import { AddSalesTemplatesContext } from "..";
import { rules } from "constant";

export default function Basic() {
  const { data, setData } = useContext<any>(AddSalesTemplatesContext);

  const handleSetData = (key:any) => {
    return (value:any) => setData((p:any) => ({ ...p, [key]: value }));
  };


  return (
    <Fragment>
        <InputGroup
          label="salesManagment.sellOffSales.salesTemplates.templateName"
          value={data?.title}
          setValue={handleSetData("title")}
          rules={rules.required}
        />
        <InputGroup
          label="salesManagment.sellOffSales.salesTemplates.code"
          value={data?.code}
          setValue={handleSetData("code")}
        />
        <InputGroup
          as="short-textarea"
          label="salesManagment.sellOffSales.salesTemplates.shortDescription"
          value={data?.description}
          setValue={handleSetData("description")}
        />
    </Fragment>
  );
}
