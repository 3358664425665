import React, { useContext } from "react";
import { Wrapper, Text, InputGroup, Select } from "components";
import { countries } from "constant";
import SearchAddress from "components/SearchAddress";
import { ProfileContext } from ".";
import { rules } from "constant";

type AddressType = {
  city?: string | null;
  country?: string | null;
  houseNo?: string | null;
  postalCode?: string | null;
  state?: string | null;
  street?: string | null;
};

const General: React.FunctionComponent = () => {
  const { profileData, setProfileData } = useContext<any>(ProfileContext);

  const onChangeHandler = (key: any) => {
    return (value: any) => setProfileData((p: any) => ({ ...p, [key]: value }));
  };

  const searchAddressHandler = (address: AddressType) => {
    setProfileData((p: any) => {
      return {
        ...p,
        street: address.street,
        city: address.city,
        houseNumber: address.houseNo,
        postalCode: address.postalCode,
        country: address.country,
        state: address.state,
      };
    });
    return {};
  };

  return (
    <Wrapper>
      <Wrapper.Header>
        <h1 className="text-heading-2 font-semibold">
          <Text>company.profile.basicInfo</Text>
        </h1>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
        <div className="space-y-8">
          <InputGroup
            value={profileData.name}
            setValue={onChangeHandler("name")}
            label="company.profile.companyName"
            rules={rules.required}
          />
          <InputGroup
            value={profileData.phoneNumber}
            setValue={onChangeHandler("phoneNumber")}
            label="company.profile.phoneNumber"
          />
          <InputGroup
            value={profileData.mobileNumber}
            setValue={onChangeHandler("mobileNumber")}
            label="company.profile.mobileNumber"
          />
          <InputGroup
            as="short-textarea"
            value={profileData.description}
            setValue={onChangeHandler("description")}
            label="company.profile.shortDescription"
          />
        </div>
        <section className="space-y-8">
          <SearchAddress setValue={searchAddressHandler} />
          <Wrapper.Section className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 items-stretch">
            <InputGroup
              value={profileData.street}
              label="global.locations.street"
              setValue={onChangeHandler("street")}
            />
            <InputGroup
              value={profileData.houseNumber}
              setValue={onChangeHandler("houseNumber")}
              label="global.locations.houseNumber"
            />
            <InputGroup
              value={profileData.postalCode}
              setValue={onChangeHandler("postalCode")}
              label="global.locations.postalCode"
            />
            <InputGroup
              value={profileData.city}
              setValue={onChangeHandler("city")}
              label="global.locations.city"
            />
            <div className="col-span-2 space-y-8">
              <Select
                label="global.locations.country"
                value={profileData.country}
                setValue={onChangeHandler("country")}
                items={countries}
              />
              <Select
                label="global.locations.state"
                value={profileData.state}
                setValue={onChangeHandler("state")}
                items={
                  countries.find((e) => e.id === profileData.country)
                    ?.states as []
                }
              />
            </div>
          </Wrapper.Section>
        </section>
      </Wrapper.Body>
    </Wrapper>
  );
};

export default General;
