import {
  Breadcrumb,
  Wrapper,
  Status,
  Text,
  Skeleton,
} from "components";
import { useDataState, usePermission } from "hooks";
import {
  useEffect,
  useState,
  Fragment
} from "react";

import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import { appPermissions, rules } from "constant";
import { Link } from "react-router-dom";
import { useToggleState, useConverter } from "hooks";
import { Media } from "types";
import CangeStatusModal from "./changeStatusModal"

export default function Details() {
  const { projectId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const { convertDate, convertAmount } = useConverter();

  const [selected, setSelected] = useState<any>();
  const [comments, setComments] = useState<any>();
  const [isOpen, toggle] = useToggleState(false);
  const [submitLoading, setSubmitLoading] = useState(false);


  // //permissions
  // const {
  //   PS_UpdateBrand,
  // } = appPermissions;
  // const UPDATE_PERMISSIONS = usePermission(PS_UpdateBrand);

  const getData = () => {
    setLoading(true);
    const url = URLS.KITCHEN_CALCULATIONS_DETAILS_URL(projectId);
    ServiceApi.get(url)
      .then(({ data }) => {
        setBaseData(data);
      }).finally(() => {
        setLoading(false);
      });

  };

  useEffect(getData, []); // eslint-disable-line react-hooks/exhaustive-deps




  return (
    <Fragment>
      <Breadcrumb />
      <Wrapper className="mt-[22px]">
        <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
          <div className="lg:flex-1 space-y-4">
            {loading ? (
              <Fragment>
                <Skeleton.Input />
                <Skeleton.Input />
              </Fragment>
            ) : (
              <Fragment>
                <div className="flex justify-between">
                  <div className="flex items-center gap-28">
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <span className="text-body-base text-gray-800 font-normal">
                          <Text>salesManagment.kitchenSales.salesList.projectNumber</Text>:
                        </span>
                        <h6 className="text-heading-6 text-gray-800 font-semibold">{data?.projectId}</h6>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-body-2 text-gray-700 font-medium">
                          <Text>salesManagment.kitchenSales.salesList.contract</Text>:
                        </span>
                        <span className="text-body-2 text-gray-500 font-medium">{data?.updates[0]?.contractNumber}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-body-2 text-gray-700 font-medium">
                          <Text>salesManagment.kitchenSales.salesList.createdAt</Text>:
                        </span>
                        <span className="text-body-2 text-gray-500 font-medium">{convertDate(data?.updates[0]?.createdAt)}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-body-2 text-gray-700 font-medium">
                          <Text>salesManagment.kitchenSales.salesList.lastUpdated</Text>:
                        </span>
                        <span className="text-body-2 text-gray-500 font-medium">{convertDate(data?.updates[0]?.updatedAt)}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-body-2 text-gray-700 font-medium">
                          <Text>salesManagment.kitchenSales.salesList.store</Text>:
                        </span>
                        <span className="text-body-2 text-gray-500 font-medium">{data?.updates[0]?.location}</span>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <span className="text-body-2 text-gray-700 font-medium">
                          <Text>salesManagment.kitchenSales.salesList.advisor</Text>:
                        </span>
                        <span className="text-body-2 text-gray-500 font-medium">{data?.updates[0]?.advisorFullName}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-body-2 text-gray-700 font-medium">
                          <Text>salesManagment.kitchenSales.salesList.targetSellFactor</Text>:
                        </span>
                        <span className="text-body-2 text-gray-500 font-medium">{data?.updates[0]?.targetSellFactor?.toFixed(2)}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-body-2 text-gray-700 font-medium">
                          <Text>salesManagment.kitchenSales.salesList.sellFactor</Text>:
                        </span>
                        <span className="text-body-2 text-gray-500 font-medium">{data?.updates[0]?.sellFactor?.toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    {(data?.currentStatus === 3 || data?.currentStatus === 4) && <div>
                      <Status.salesListStatus id={data?.currentStatus} />
                    </div>}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <div className="flex flex-col lg:flex-row gap-6 mt-[22px]">
        <Wrapper>
          <Wrapper.Header>
            <h2 className="text-heading-2 text-gray-800 font-semibold">
              <Text>salesManagment.kitchenSales.salesList.details</Text>
            </h2>
          </Wrapper.Header>
          <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
            <div className="lg:flex-1 space-y-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="space-y-6">
                    <div className="flex items-center justify-between">
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        <Text>salesManagment.kitchenSales.salesList.projectName</Text>:
                      </h6>
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        {data?.projectName}
                      </h6>
                    </div>
                    <div className="flex items-center justify-between">
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        <Text>salesManagment.kitchenSales.salesList.topBuy</Text>:
                      </h6>
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        {data?.topBuy}
                      </h6>
                    </div>
                    <div className="flex items-center justify-between">
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        <Text>salesManagment.kitchenSales.salesList.targetShippingCost</Text>:
                      </h6>
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        {convertAmount(data?.updates[0]?.targetShippingCost)}
                      </h6>
                    </div>
                    <div className="flex items-center justify-between">
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        <Text>salesManagment.kitchenSales.salesList.shippingCost</Text>:
                      </h6>
                      <h6 className={`text-heading-6  font-normal ${data?.updates[0]?.shippingCostHasWarning ? "text-danger" : "text-gray-700"}`} >
                        {convertAmount(data?.updates[0]?.shippingCost)}
                      </h6>
                    </div>
                    <div className="flex items-center justify-between">
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        <Text>salesManagment.kitchenSales.salesList.shippingCostWithoutInstallation</Text>:
                      </h6>
                      <h6 className="text-heading-6  font-normal text-gray-700" >
                        {data?.updates[0]?.shippingWithoutInstallationCost === null ? "--" : data?.updates[0]?.shippingWithoutInstallationCost}
                      </h6>
                    </div>
                    <div className="flex items-center justify-between">
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        <Text>salesManagment.kitchenSales.salesList.serviceCost</Text>:
                      </h6>
                      <h6 className="text-heading-6  font-normal text-gray-700" >
                        {convertAmount(data?.updates[0]?.installationCost)}
                      </h6>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>
        <Wrapper>
          <Wrapper.Header>
            <h2 className="text-heading-2 text-gray-800 font-semibold">
              <Text>salesManagment.kitchenSales.salesList.customer</Text>
            </h2>
          </Wrapper.Header>
          <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
            <div className="lg:flex-1 space-y-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="space-y-6">
                    <div className="flex items-center justify-between">
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        <Text>salesManagment.kitchenSales.salesList.name</Text>:
                      </h6>
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        {data?.updates[0]?.customerFullName}
                      </h6>
                    </div>
                    <div className="flex items-center justify-between">
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        <Text>salesManagment.kitchenSales.salesList.preferredShippingDate</Text>:
                      </h6>
                      <h6 className="text-heading-6 text-gray-700 font-normal" >
                        {data?.updates[0]?.customerPreferredShippingDate === null ? "--" : data?.updates[0]?.customerPreferredShippingDate}
                      </h6>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>
        <Wrapper>
          <Wrapper.Header>
            <h2 className="text-heading-2 text-gray-800 font-semibold">
              <Text>salesManagment.kitchenSales.salesList.comments</Text>
            </h2>
          </Wrapper.Header>
          <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5 overflow-x-hidden overflow-y-scroll">
            <div className="lg:flex-1 space-y-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  {data?.statusChangesComments?.map((item: any) => {
                    return (
                      <div className="border-b-2 pb-2 space-y-2">
                        <div className="flex items-center gap-2">
                          <div>
                            <Status.salesCommentsIconStatus id={item?.statusType} />
                          </div>
                          <div className="space-y-2">
                            <div><span className="text-body-2 text-gray-700 font-normal">{item?.userFullName}</span></div>
                            <div><span className="text-body-2 text-gray-500 font-medium">{convertDate(item?.createdAt)}</span></div>
                          </div>
                        </div>
                        <div>
                          <span className="text-body-base text-gray-600 font-normal">{item?.comment}</span>
                        </div>
                      </div>
                    )
                  })}

                </Fragment>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>
      </div>
      <Wrapper className="mt-[22px]">
        <Wrapper.Header>
          <h2 className="text-heading-2 text-gray-800 font-semibold">
            <Text>salesManagment.kitchenSales.salesList.contractProcess</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5 !p-0 overflow-scroll">
          <div className="lg:flex-1 space-y-4">
            {loading ? (
              <Fragment>
                <Skeleton.Input />
                <Skeleton.Input />
              </Fragment>
            ) : (
              <Fragment>
                <table className="w-full">
                  <thead className="px-8 py-4">
                    <tr className="text-left bg-gray-100">
                      <th className="text-body-base text-gray-700 font-medium px-4 py-2">
                        #
                      </th>
                      <th className="text-body-base text-gray-700 font-medium px-4 py-4">
                        <Text>salesManagment.kitchenSales.salesList.contractDate</Text>
                      </th>
                      <th className="text-body-base text-gray-700 font-medium px-4 py-4">
                        <Text>salesManagment.kitchenSales.salesList.statusNote</Text>
                      </th>
                      <th className="text-body-base text-gray-700 font-medium px-4 py-4">
                        <Text>salesManagment.kitchenSales.salesList.updatedDateTime</Text>
                      </th>
                      <th className="text-body-base text-gray-700 font-medium px-4 py-4">
                        <Text>salesManagment.kitchenSales.salesList.totalAmount</Text>
                      </th>
                      <th className="text-body-base text-gray-700 font-medium px-4 py-4">
                        <Text>salesManagment.kitchenSales.salesList.totalTarget</Text>
                      </th>
                      <th className="text-body-base text-gray-700 font-medium px-4 py-4">
                        <Text>salesManagment.kitchenSales.salesList.purchaseNet</Text>
                      </th>
                      <th className="text-body-base text-gray-700 font-medium px-4 py-4">
                        <Text>salesManagment.kitchenSales.salesList.targetSellFactor</Text>
                      </th>
                      <th className="text-body-base text-gray-700 font-medium px-4 py-4">
                        <Text>salesManagment.kitchenSales.salesList.sellFactor</Text>
                      </th>
                      <th className="text-body-base text-gray-700 font-medium px-4 py-4">
                        <Text>salesManagment.kitchenSales.salesList.status</Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="px-8 py-4">
                    {data?.updates?.map((item: any, index: any) => {
                      return (
                        <tr className="text-left">
                          <td className="text-body-base text-gray-700 font-medium px-4 py-2">{data?.updates.length - index}</td>
                          <td className="text-body-base text-gray-700 font-medium px-4 py-4">{convertDate(item?.contractDateTime)}</td>
                          <td className="text-body-base text-gray-700 font-medium px-4 py-4">{item?.contractStatusNote}</td>
                          <td className="text-body-base text-gray-700 font-medium px-4 py-4">{convertDate(item?.updatedAt)}</td>
                          <td className="text-body-base text-gray-700 font-medium px-4 py-4">{convertAmount(item?.totalAmount)}</td>
                          <td className="text-body-base text-gray-700 font-medium px-4 py-4">{convertAmount(item?.totalTargetAmount)}</td>
                          <td className="text-body-base text-gray-700 font-medium px-4 py-4">{convertAmount(item?.purchaseNet)}</td>
                          <td className="text-body-base text-gray-700 font-medium px-4 py-4">{item?.targetSellFactor?.toFixed(2)}</td>
                          <td className="text-body-base text-gray-700 font-medium px-4 py-4">{item?.sellFactor?.toFixed(2)}</td>
                          <td className="text-body-base text-gray-700 font-medium px-4 py-4">{
                            index === 0 ?
                              (item?.status === 2 || item?.status === 3) ?
                                <div className="cursor-pointer" onClick={() => {
                                  setSelected(item);
                                  setComments(data?.statusChangesComments);
                                  toggle()
                                }}>
                                  <Status.salesListIconStatus id={item?.status} />
                                </div>
                                : <Status.salesListIconStatus id={item?.status} />
                              :
                              <Status.salesListIconStatus id={item?.status} />
                          }</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <CangeStatusModal
        isOpen={isOpen}
        getData={getData}
        toggle={toggle}
        selected={selected}
        comments={comments}
        projectId={data?.projectId}
      />
    </Fragment>
  );
}
