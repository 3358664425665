import { useMemo, ReactNode } from "react";
import { variantType } from "types";
type badgeProps = {
  children: ReactNode;
  className?: string;
  variant?: variantType;
};

export default function Badge({
  children,
  variant = "primary",
  className = "",
}: badgeProps) {
  const activeVariant = useMemo(() => {
    if (!variant) return "";
    const variants: { [key in Exclude<variantType, null>]: string } = {
      primary: "text-[#508ACD] bg-[#508ACD] bg-opacity-10",
      danger: "text-danger bg-danger bg-opacity-10",
      warning: "text-warning bg-warning bg-opacity-10",
      info: "text-info bg-info bg-opacity-10",
      success: "text-success bg-success bg-opacity-10",
      gray: "text-gray-600 bg-gray-200",
      secondary: "",
      white: "",
      light: "",
      dark: "",
    };
    const active = variants[variant];
    return active;
  }, [variant]);
  return (
    <span
      className={`inline-block align-middle whitespace-nowrap p-2 rounded-md font-normal ${activeVariant} text-body-3 ${className}`}
    >
      {children}
    </span>
  );
}
