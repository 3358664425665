import { useId, ReactNode } from "react";
import Text from "./Text";

type checkBoxProps = {
  label?: string;
  className?: string;
  children?: ReactNode;
  value: boolean;
  disabled?: boolean;
  setValue?: (val: boolean) => void;
};

export default function CheckBox({
  value,
  setValue = () => {},
  label,
  disabled = false,
  children,
  className,
}: checkBoxProps) {
  const id = useId();
  return (
    <label
      data-active={value}
      data-lang-map={label}
      className="checkbox group w-full flex-center gap-4"
    >
      <input
        id={id}
        type="checkbox"
        checked={value}
        disabled={disabled}
        onChange={() => setValue(!value)}
        hidden
      />
      <span
        className={`${className} w-6 cursor-pointer h-6 rounded-md border bg-gray-200 group-data-active:bg-primary transition-colors ${
          disabled && "group-data-active:opacity-50"
        }`}
      >
        <i
          className={`bi bi-check-lg w-full h-full text-lg text-white opacity-0 group-data-active:opacity-100 transition-opacity `}
        />
      </span>
      {label && (
        <span className="flex-1">
          <Text>{label}</Text>
        </span>
      )}
      {children && <div className="flex-1">{children}</div>}
    </label>
  );
}
