import { useState } from "react";
import { NotificationManager } from "common/notifications";
import { Button, InputGroup, Modal, Text, Form } from "components";
import { useToggleState } from "hooks";
import { ServiceApi, URLS } from "services";
import { rules } from "constant";

type Props = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: CallableFunction;
  id?: string;
  data?: any;
};
const WithdrawModal = ({ isOpen, toggle, getData, id, data }: Props) => {
  const [loading, setLoading] = useToggleState();
  const [withdrawReason, setWithdrawReason] = useState<any>("");

  const withdrawProductHandler = () => {
    if (id) {
      setLoading();
      const url = URLS.WITHDRAW_URL(id);
      ServiceApi.post(url, { withdrawReason: withdrawReason })
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title"
          );
          setLoading();
          getData();
          toggle();
        })
        .catch(() => {
          setLoading();
        });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="z-40">
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>
            salesManagment.sellOffSales.sellOffProduct.withdrawProduct
          </Text>
        </h6>
      </Modal.Header>
      <Form onSubmit={withdrawProductHandler}>
        <Modal.Body className="max-h-100 overflow-auto space-y-6">
          <div className="flex flex-col space-y-4">
            <span>
              <Text>
                salesManagment.sellOffSales.sellOffProduct.areUSureWithdraw
              </Text>
              ?{" "}
            </span>
          </div>
          <InputGroup
            label="Comment"
            rules={rules.required}
            value={withdrawReason}
            setValue={(e) => setWithdrawReason(e)}
          />
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button variant={"light"} size="sm" onClick={toggle}>
            <Text>salesManagment.sellOffSales.sellOffProduct.cancel</Text>
          </Button>
          <Button size="sm" type="submit" loading={loading}>
            <Text>salesManagment.sellOffSales.sellOffProduct.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default WithdrawModal;
