import {
    InputGroup,
    SelectLang,
    Skeleton,
    Text,
    Wrapper
} from "components";
import {
    Fragment,
    useContext
} from "react";

import { flagCountry, rules } from "constant";

import { MeasurementContext } from "..";

export default function General() {

  const {
    measurementData,
    setMeasurementData,
    name,
    langItems,
    setName,
    loading,
    langSelected,
    setLangSelected,
  } = useContext<any>(MeasurementContext);

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    measurementData?.translations?.forEach((item: any) => {
      if (item.language === lang) {
        setName(item.name)
      }
    })
  };

  return (
    <Fragment>
      <div className="flex gap-6">
        {loading ? (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-2 lg:grid-cols-2 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        ) :
          <Wrapper>
            <Wrapper.Header>
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-gray-800 font-semibold" ><Text>productManagement.masterData.measurementUnits.basicInfo</Text></h2>
                </div>
                <div>
                  <SelectLang value={langSelected} items={langItems} onChangeLanguage={onChangeLanguage} />
                </div>
              </div>
            </Wrapper.Header>
            <Wrapper.Body className="grid grid-cols-2 lg:grid-cols-2 gap-4">
              <div className="space-y-8">
                <InputGroup
                  label="productManagement.masterData.measurementUnits.name"
                  value={name}
                  flag={flagCountry[langSelected]}
                  setValue={(value) => {
                    measurementData?.translations?.forEach((item: any) => {
                      if (item.language === langSelected) {
                        item.name = value
                      }
                    })
                    setMeasurementData((p: any) => ({ ...p, translates: measurementData?.translations }));
                    setName(value)
                  }}
                  rules={rules.required}
                />
                <InputGroup
                  label="productManagement.masterData.measurementUnits.code"
                  value={measurementData?.code}
                  disabled
                />
              </div>
            </Wrapper.Body>
          </Wrapper>
        }
      </div>
    </Fragment>
  );
}
