import {
    Button,
    DropdownButton,
    Icon,
    NoItems,
    Pagination,
    Skeleton,
    Status,
    Table,
    Text,
    TotalItems
} from "components";
import { appPermissions, config } from "constant";
import { usePermission, useToggleState, useTranslate } from "hooks";
import { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { SuppliersContext } from "..";
import AttributeDrawer from "./program/attribute/attribute-drawer";
import CreateDrawer from "./program/create-drawer";
import DiscountDrawer from "./program/discount/discount-drawer";
import DetailsDrawer from "./program/edit-drawer";

export default function Program() {
  const { suppliersData } = useContext<any>(SuppliersContext);
  const [dataBeas, setDataBeas] = useState<any>()
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [isOpen, toggle] = useToggleState(false);
  const [isOpenUpdate, toggleUpdate] = useToggleState(false);
  const [isOpenDrawerAttributes, toggleDrawerAttributes] = useToggleState(false);
  const [isOpenDrawerDiscount, toggleDrawerDiscount] = useToggleState(false);
  const [programId, setProgramId] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [attributes, setAttributes] = useState([]);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });


  const getData = () => {
    setLoading(true);
    const urlPrograms = URLS.GET_SUPPLIER_PROGRAMS(suppliersData.code);
    ServiceApi.get(urlPrograms)
      .then(({ data }) => {
        setData(data.slice(0, 10))
        setDataBeas(data)
        setTotalItems(data.length)
        setParams({
          pageNumber: 1,
          pageSize: config.pageSize,
          keyword: "",
        })
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(getData, []);// eslint-disable-line react-hooks/exhaustive-deps


  const handleSetParams = (key: string) => {
    return (value: any) => {
      if (key !== "pageNumber") {
        setParams((p) => ({ ...p, [key]: value, pageNumber: 1 }));
        return;
      }
      setParams((p) => ({ ...p, [key]: value }));
      setData(dataBeas.slice(value !== 1 ? (10 * value) - 10 : 0, value !== 1 ? 10 * value : 10))
    };
  };


  //permissions
  const {
    PS_GetPrograms,
    PS_CreateProgram,
    PS_GetProgramDetail,
    PS_UpdateSupplier,
  } = appPermissions;
  const GET_LIST_PERMISSIONS = usePermission(PS_GetPrograms);
  const CREATE_PERMISSIONS = usePermission(PS_CreateProgram);
  const UPDATE_DETAIL_PERMISSIONS = usePermission(PS_GetProgramDetail);
  const UPDATE_PERMISSIONS = usePermission(PS_UpdateSupplier);


  //.....search
  const translate = useTranslate();
  const [submitted, setSubmitted] = useState(false);
  const [value, setValue] = useState("");
  const clearValueSearch = (e: any) => {
    setSubmitted(false);
    setValue("");
    getData();
    submitSearch(null);
  };
  const submitSearch = (e: any) => {
    if (e?.keyCode === 13) {
      if (value !== "") {
        let list: any = [];
        dataBeas?.forEach((item: any) => {
          if (item.title.toUpperCase().includes(value.toUpperCase())) {
            list.push(item)
          }
        })
        setData(list)
        setSubmitted(true);

      } else {
        getData()
        setSubmitted(false);
      }
      e.preventDefault();
    }
  };
  const onClickIcon = (e: any) => {
    if (value !== "") {
      let list: any = [];
      dataBeas?.forEach((item: any) => {
        if (item.title.toUpperCase().includes(value.toUpperCase())) {
          list.push(item)
        }
      })
      setData(list)
      setSubmitted(true);

    } else {
      getData()
    }
  }
  const handleChangeSearch = (e: any) => {
    e.preventDefault();
    setValue(e.target.value);
  };
  //...search

  return GET_LIST_PERMISSIONS ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <div className="flex items-center gap-4">
            <div className="w-[18rem]">
              {loading ? (
                <Skeleton.Input />
              ) : (
                <div
                  className={`w-full text-body-2 font-medium flex-center gap-2 rounded px-2 py-1 bg-white focus-within:ring-1 ring-gray-500 ring-offset-1 transition-shadow`}
                >
                  <button type="button" onClick={(e: any) => onClickIcon(e)}>
                    <Icon icon="search" />
                  </button>
                  <input
                    onKeyUp={submitSearch}
                    value={value}
                    onChange={handleChangeSearch}
                    placeholder={translate("global.placeholders.search") ?? ""}
                    className="flex-1 h-8 px-2"
                  />
                  {submitted && (
                    <button
                      type="button"
                      onClick={(e: any) => clearValueSearch(e)}
                      className="bi bi-x-lg text-dark hover:text-danger transition-colors"
                    />
                  )}
                </div>
              )}
            </div>
            <TotalItems totalItems={totalItems} />
          </div>
        </div>
        {CREATE_PERMISSIONS &&
          <Button
            type="button"
            onClick={() => toggle()}
          >
            <Icon className="mr-2" icon={"plus"} /><Text>productManagement.masterData.suppliers.addProgram</Text>
          </Button>
        }
      </div>

      {loading ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <Fragment>
          {data?.length !== 0 ?
            <>
              <Table>
                {data?.map((item: any) => {
                  return (
                    <tr key={item.id} >
                      <td>
                        <Link to=""
                          onClick={() => {
                            if (UPDATE_DETAIL_PERMISSIONS) {
                              setProgramId(item.id)
                              toggleUpdate()
                            }
                          }} className="w-fit inline-flex flex-center gap-2">
                          <div className="space-y-4">
                            <h5 className="text-heading-5 text-gray-800">{item.title}</h5>
                            <div className="flex">
                              <p className="text-body-base text-gray-700 mr-4">{item.code}</p>
                              {
                                item.programType === 1 && <p className="text-body-base text-gray-700"><Text>productManagement.masterData.suppliers.manual</Text></p>
                              }
                              {item.programType === 2 && <p className="text-body-base text-gray-700"><Text>productManagement.masterData.suppliers.configurable</Text></p>
                              }
                            </div>
                            <div>
                              <p className="text-body-base text-gray-700 mr-4"><Text>productManagement.masterData.suppliers.zrNumber</Text>: <span className="ml-1">{item.zrNumber}</span></p>

                            </div>
                          </div>
                        </Link>
                      </td>
                      <td>
                        {item?.discounts?.map((i: any) => {
                          return <div className="text-left mt-1">
                            <div>
                              <span className="text-body-base text-danger mr-1">{i?.amount}%</span>
                              <span className="text-body-3 text-gray-700">OFF</span>
                            </div>
                            <div>
                              <span className="text-body-3 text-gray-700">{i?.title}</span>
                            </div>
                          </div>
                        })}
                      </td>
                      <td>
                        <p className="text-body-base text-gray-600 break-words text-left">{item.shortDescription}</p>
                      </td>
                      <td>
                        <Status.Supplier id={item.isActive} />
                      </td>
                      <td>
                        <div>
                          <DropdownButton
                            items={[
                              {
                                id: "edit",
                                title: "global.buttons.edit",
                                permission: UPDATE_DETAIL_PERMISSIONS,
                                onClick: () => {
                                  setProgramId(item.id)
                                  toggleUpdate()
                                }
                              },
                              {
                                id: "attribute",
                                title: "productManagement.masterData.suppliers.attribute",
                                permission: UPDATE_PERMISSIONS && item.programType === 1 ? true : false,
                                onClick: () => {
                                  setAttributes(item.attributes)
                                  setProgramId(item.id)
                                  toggleDrawerAttributes()
                                }
                              },
                              {
                                id: "discount",
                                title: "productManagement.masterData.suppliers.discount",
                                permission: UPDATE_PERMISSIONS,
                                onClick: () => {
                                  setAttributes(item.attributes)
                                  setProgramId(item.id)
                                  toggleDrawerDiscount()
                                }
                              },

                            ]}>
                          </DropdownButton>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={totalItems}
                totalPages={Math.ceil(totalItems / 10)}
                pageNumber={params?.pageNumber}
                pageSize={params?.pageSize}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) => setParams((p) => ({ ...p, pageSize: e }))}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </Fragment>
      )}
      <CreateDrawer isOpen={isOpen} toggle={toggle} getData={getData} supplierCode={suppliersData.code} supplierData={suppliersData} />
      <DetailsDrawer isOpen={isOpenUpdate} toggle={toggleUpdate} programId={programId} getData={getData} supplierData={suppliersData} />
      <AttributeDrawer isOpen={isOpenDrawerAttributes} toggle={toggleDrawerAttributes} attributes={attributes} programId={programId} getData={getData} />
      <DiscountDrawer isOpen={isOpenDrawerDiscount} toggle={toggleDrawerDiscount} programId={programId} getData={getData} />
    </div >
  ) : (
    <>Permission Error</>
  );
}
