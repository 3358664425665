import { InputGroup, Select, Text } from "components";
import { rules } from "constant";
import { Fragment, useContext, useEffect, useState } from "react";
import { ServiceApi } from "services";
import LEGALE_NTITY from "services/urls/legal-entity";
import { AddStoreContext } from "./";

export default function BasicInfo() {
  const { data, setData } = useContext(AddStoreContext);
  const [legalEntities, setLegalEntities] = useState([]);
  const getLegalEntities = () => {
    const url = LEGALE_NTITY.GET_LEGALENTITY;
    const config = { params: { pageNumber: 1, pageSize: 50 } };
    ServiceApi.get(url, config).then(({ data }) => {
      const result = data.items.map((e) => ({
        name: e.name,
        id: e.code,
      }));
      setLegalEntities(result);
    });
  };
  const handleSetData = (key) => {
    return (value) => setData((p) => ({ ...p, [key]: value }));
  };
  useEffect(getLegalEntities, []);
  return (
    <Fragment>
      <h1 className="text-heading-2 font-semibold">
        <Text>company.stores.basicInfo</Text>
      </h1>
      <InputGroup
        label="company.stores.storeName"
        value={data.title}
        setValue={handleSetData("title")}
        rules={rules.required}
      />
      <InputGroup
        label="company.stores.storeCode"
        value={data.code}
        setValue={handleSetData("code")}
        rules={rules.required}
      />
      <Select
        label="company.stores.legalEntity"
        items={legalEntities}
        value={data.legalEntityCode}
        setValue={handleSetData("legalEntityCode")}
        rules={rules.required}
      />
      <InputGroup
        as="short-textarea"
        label="company.stores.shortDescription"
        value={data.shortDescription}
        setValue={handleSetData("shortDescription")}
        rules={rules.required}
      />
      <InputGroup
        as="textarea"
        label="company.stores.description"
        value={data.body}
        setValue={handleSetData("body")}
        rules={rules.required}
      />
    </Fragment>
  );
}
