import { Image, Skeleton, Text, Wrapper, NoItems } from "components";
import { useConverter } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ServiceApi } from "services";
type productType = {
  productFamilyId: string;
  productFamilyTranslates: { name: string; language: string }[];
  sellOffProductId: string;
  soldDateTime: Date;
  thumbnailUrl: null | string;
  title: string;
};
export default function RecentlySoldSellOffProducts() {
  const { convertDate } = useConverter();
  const [data, setData] = useState<productType[]>([]);
  const [loading, setLoading] = useState(false);
  const getData = () => {
    setLoading(true);
    const url = "/productservice/api/selloffproducts/latest-sold";
    const config = { params: { number: 20 } };
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getData, []);
  return (
    <Wrapper className="xl:col-span-full">
      <Wrapper.Header>
        <h2 className="text-gray-800">
          <Text>dashboard.recentlySoldSellOffProducts</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body className="!px-0 !pt-0 !pb-3">
        <div className="space-y-4 xl:h-[20rem] xl:overflow-auto pt-8 px-8">
          {loading ? (
            <Fragment>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Fragment>
          ) : (
            data.length !== 0 ?
              data?.map((e) => (
                <Link
                  key={e.sellOffProductId}
                  to={`/salesmanagment/sell-off-sales/sell-off-products/--/${e.sellOffProductId}`}
                  className="flex items-center gap-4"
                >
                  <Image
                    src={e.thumbnailUrl}
                    alt={e.title}
                    className="w-14 h-14"
                    imageClassName="w-full h-full"
                  />
                  <div className="flex-1 space-y-2">
                    <h6 className="text-primary font-normal line-clamp-2">
                      {e.title}
                    </h6>
                    <p className="text-body-2 text-gray-500">
                      <Text>dashboard.soldAt</Text>: {convertDate(e.soldDateTime)}
                    </p>
                  </div>
                </Link>
              )) :
              <div className="flex flex-col items-center space-y-4 w-full">
                <NoItems />
              </div>
          )}
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
