import excludeIcon from "assets/icons/attributes/exclude.svg";
import includeIcon from "assets/icons/attributes/include.svg";
import { NotificationManager } from "common/notifications";
import {
    Button,
    NoItems,
    Skeleton,
    Table,
    Toggler
} from "components";
import {
    Fragment,
    useContext,
    useEffect,
    useState
} from "react";
import { ServiceApi, URLS } from "services";
import { AttributesContext } from "..";



export default function Channels() {
  const { attributeId } = useContext<any>(AttributesContext);
  const [attributeChannel, setattributeChannel] = useState([])
  const [loadingChannel, setLoadingChannel] = useState(false)

  useEffect(() => {
    getDataAttributes();
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const getDataAttributes = () => {
    setLoadingChannel(true);
    const url = URLS.GET_ATTRIBUTES_DETAILS_URLS(attributeId);
    ServiceApi.get(url)
      .then(({ data }) => {
        let items: any = [
          { title: "Digital Storefront", channelCode: "digitalstorefront", pm: "Exclude from this channel", button: 1, searchable: false },
          { title: "CRM Channel", channelCode: "crmchannel", pm: "Exclude from this channel", button: 1, searchable: false },
          { title: "ERP Channel", channelCode: "erpchannel", pm: "Exclude from this channel", button: 1, searchable: false },
          { title: "Sales Tools Channel", channelCode: "salestoolschannel", pm: "Exclude from this channel", button: 1, searchable: false },
          { title: "Online Shop Channel", channelCode: "onlineshopchannel", pm: "Exclude from this channel", button: 1, searchable: false },
        ]
        items?.forEach((item: any, index: any) => {
          if (data?.attributeChannelExceptions.length !== 0) {
            data?.attributeChannelExceptions?.forEach((channelItem: any) => {
              if (item.channelCode === channelItem.channelCode) {
                items[index].pm = "Include in this channel"
                items[index].button = 2
              }
            })
          }
          data?.attributeSearchableChannels?.forEach((i: any) => {
            if (i.channelCode === item.channelCode) {
              items[index].searchable = true
            }
          })
        })

        setattributeChannel(items)
      }).finally(() => {
        setLoadingChannel(false);
      });
  };



  const addSearchable = (code: any) => {
    let data = {
      "attributeSearchableChannels": {
        "channelCode": code
      }
    }
    const url = URLS.ADD_CHANNEL_TO_SEARCHABLE_ATTRIBUTE(attributeId);
    ServiceApi.patch(url, data).then((res) => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      getDataAttributes()
    });
  }
  const removeSearchable = (code: any) => {
    let data = {
      "attributeSearchableChannels": {
        "channelCode": code
      }
    }
    const url = URLS.REMOVE_CHANNEL_TO_SEARCHABLE_ATTRIBUTE(attributeId);
    ServiceApi.delete(url, { data: data }).then((res) => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title"
      );
      getDataAttributes()
    });
  }


  return (
    <Fragment>
      {loadingChannel ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {attributeChannel.length !== 0 ?
            <Table>
              {attributeChannel?.map((item: any) => {
                return <tr key={item.id}>
                  <td>
                    <div className="flex items-center gap-4">
                      <div><img src={item.button === 1 ? excludeIcon : includeIcon} alt="" ></img></div>
                      <div><h6 className="text-gray-800 text-heading-6 font-semibold">{item.title}</h6></div>
                    </div>
                  </td>
                  {item.button === 1 ? <td>
                    <div className="w-[60%]">
                      <Toggler
                        label={"productManagement.masterData.attributes.showAttributeInFilters"}
                        value={item.searchable}
                        size="sm"
                        setValue={() => {
                          item.searchable === false ?
                            addSearchable(item.channelCode)
                            : removeSearchable(item.channelCode)
                        }}
                      />
                    </div>
                  </td> : <td></td>}
                  <td>
                    <Button
                      type="button"
                      variant="light"
                      textColor={item.button === 1 ? "danger" : "primary"}
                      size="sm"
                      onClick={() => {
                        const url = item.button === 2 ? URLS.REMOVE_CHANNEL_TO_ATTRIBUTE(attributeId) : URLS.ADD_CHANNEL_TO_ATTRIBUTE(attributeId);
                        if (item.button === 2) {
                          const body = { attributeChannelPublishException: { channelCode: item.channelCode } };
                          ServiceApi.delete(url, { data: body })
                            .then(() => {
                              NotificationManager.success(
                                "global.toast.delete-msg",
                                "global.toast.delete-title"
                              );
                            }).finally(() => {
                              getDataAttributes()
                            });
                        } else {
                          const body = { attributeChannelPublishException: { channelCode: item.channelCode } };
                          ServiceApi.patch(url, body)
                            .then(() => {
                              NotificationManager.success(
                                "global.toast.add-msg",
                                "global.toast.add-title"
                              );
                            }).finally(() => {
                              getDataAttributes()
                            });
                        }
                      }}
                    >
                      {item.pm}
                    </Button>
                  </td>
                </tr>
              })}
            </Table>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )
      }
    </Fragment >
  );
}
