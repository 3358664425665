import { useEffect, useRef, useState } from "react";
import { Dropdown, InputGroup } from ".";
import { useSearchAddress } from "hooks";

type SearchAddressProps = {
  setValue: ({}) => {};
};

type addressItemsType = {
  name: string;
  id: string;
  address: any;
}[];
export default function SearchAddress({ setValue }: SearchAddressProps) {
  const timeoutRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const searchAddress = useSearchAddress();
  const [search, setSearch] = useState<string>("");
  const [items, setItems] = useState<addressItemsType>([]);
  const handleChangeEnd = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      handleSearchAddress(search);
    }, 500);
  };
  const handleSearchAddress = (query: string) => {
    if (!query) return setItems([]);
    setLoading(true);
    searchAddress({ query })
      .then(setItems)
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSetAddress = (id: string) => {
    const address = items.find((e) => e.id === id)?.address;
    if (address) setValue(address);
  };
  useEffect(handleChangeEnd, [search]);
  return (
    <Dropdown
      data-active={"true"}
      className="block w-full"
      onSelect={handleSetAddress}
    >
      <Dropdown.Toggle
        as={InputGroup}
        label="global.locations.address"
        value={search}
        setValue={setSearch}
        placeholder="global.locations.searchPlaceHolder"
        prepend={
          <div hidden={!loading} className="input-group-text">
            <span className="inline-block w-4 h-4 rounded-full border-2 border-current border-r-transparent align-[-0.2em] animate-spin" />
          </div>
        }
        append={
          <i className="bi bi-chevron-down text-primary input-group-text" />
        }
      />
      <Dropdown.Menu>
        {items.map((e: any) => (
          <Dropdown.Item key={e.id} eventKey={e.id}>
            {e.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
