import { Badge, Button, Icon, Table, Text, DeleteModal } from "components";
import { types } from "constant";
import { useToggleState } from "hooks";
import { cloneDeep } from "lodash";
import {
  DragEvent,
  Fragment,
  createContext,
  useContext,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { ServiceApi } from "services";
import { InfoPageContext } from "../..";
import { layoutType } from "../../../type";
import EditBanner from "./EditBanner";
import EditProducts from "./EditProducts";
import { NotificationManager } from "common/notifications";
type contextType = { layoutData: layoutType };
export const LayoutContext = createContext({} as contextType);
export default function Layout({ ...props }: layoutType) {
  const { id, title, infoPageLayoutType } = props;
  const { infoPageData, setInfoPageData } = useContext(InfoPageContext);
  const [loading, setLoading] = useState(false);
  const [isEditDrawerOpen, toggleEditDrawer] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const layoutImage = (id: number) => {
    return types.infoPageLayout.find((e) => e.id === id)?.icon;
  };
  const layoutType = (id: number) => {
    return types.infoPageLayout.find((e) => e.id === id)?.name;
  };
  const isBannerOne = infoPageLayoutType === 0;
  const isBannerTwo = infoPageLayoutType === 1;
  const isBannerThree = infoPageLayoutType === 5;
  const isIntroduction = infoPageLayoutType === 2;
  const isProducts = infoPageLayoutType === 3;
  const isScrollbar = infoPageLayoutType === 4;

  const isBanner = [
    isBannerOne,
    isBannerTwo,
    isIntroduction,
    isBannerThree,
  ].some(Boolean);
  const isProduct = [isProducts, isScrollbar].some(Boolean);

  const handleDragStart = ({
    currentTarget,
  }: DragEvent<HTMLTableRowElement>) => {
    currentTarget.classList.add("dragging");
  };
  const handleDragEnd = ({ currentTarget }: DragEvent<HTMLTableRowElement>) => {
    currentTarget.classList.remove("dragging");
  };

  const removeLayout = () => {
    setLoading(true);
    const url = `/productservice/api/infopages/${infoPageData.id}/layout/${id}`;
    ServiceApi.delete(url)
      .then(() => {
        setInfoPageData((p) => {
          const data = cloneDeep(p);
          const index = data.infoPageLayouts.findIndex((e) => e.id === id);
          data.infoPageLayouts.splice(index, 1);
          return data;
        });
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        toggleDeleteModal();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <tr
        data-id={id}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        draggable
      >
        <td>
          <div className="flex items-center gap-1">
            <Table.TouchPad />
            <Table.Image
              src={layoutImage(infoPageLayoutType)}
              alt={title}
              className="!bg-transparent !border-none [&_img]:!object-contain"
            />
            <div className="space-y-2">
              <h6>{title}</h6>
              <Badge variant="gray">
                <Text>{layoutType(infoPageLayoutType)}</Text>
              </Badge>
            </div>
          </div>
        </td>
        <td className="space-x-2">
          <Button
            type="button"
            variant="danger"
            light
            size="sm"
            loading={loading}
            onClick={()=>{
              toggleDeleteModal();
            }}
          >
            <Icon icon="trash" />
          </Button>
          <Button
            type="button"
            variant="light"
            size="sm"
            onClick={toggleEditDrawer}
          >
            <Text>applications.infoPage.layoutDetails</Text>
          </Button>
        </td>
      </tr>
      <LayoutContext.Provider value={{ layoutData: props }}>
        {isBanner && (
          <EditBanner
            isOpen={isEditDrawerOpen}
            toggle={toggleEditDrawer}
            initData={props}
          />
        )}
        {isProduct && (
          <EditProducts
            isOpen={isEditDrawerOpen}
            toggle={toggleEditDrawer}
            initData={props}
          />
        )}
      </LayoutContext.Provider>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: title, id: id }}
        loading={loading}
        onConfirm={removeLayout}
      />
    </Fragment>
  );
}
