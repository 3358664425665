const MANAGE_CHECK_LIST = {
  GET_CHECK_LIST_URL: "productservice/api/checklistitems",
  CREATE_CHECK_LIST_URL: "productservice/api/checklistitems",
  UPDATE_CHECK_LIST_URL: (id) => `productservice/api/checklistitems/${id}`,
  GET_CHECK_LIST_DETAILS_URL_: (id) => `productservice/api/checklistitems/${id}`,
  DELETE_CHECK_LIST_URL: (id) => `productservice/api/checklistitems/${id}`,

};

export default MANAGE_CHECK_LIST;
