import { useContext, useState } from "react";
import { useParams } from "react-router";
import { Wrapper, NoItems, Button, Icon, Image, Text } from "components";
import { SellOffProductDetailsContext } from "..";
import PriceTemplate from "../../List/PriceTemplate";
import { useToggleState } from "hooks";
import EditSubProductDrawer from "../../sell-off-drawers/EditSubProductDrawer";

const SubProducts = () => {
  const { data, getData } = useContext(SellOffProductDetailsContext);
  const { store } = useParams();
  const [isOpen, toggle] = useToggleState();
  const [subProduct, setSubProduct] = useState<any>({});
  const [isEditDrawer, setIsEditDrawer] = useState<boolean>(false);

  const subItemHandler = (item: any, isEdit: boolean) => {
    setIsEditDrawer(isEdit);
    setSubProduct(item);
    toggle();
  };

  const hiddenButtons = data?.isClosed || data?.isSold;

  return (
    <>
      <Wrapper>
        <Wrapper.Header className="flex justify-between items-center">
          <h2 className="text-gray-800">
            <Text>salesManagment.sellOffSales.sellOffProduct.subProducts</Text>
          </h2>
          {!hiddenButtons && (
            <Button
              variant={"light"}
              size="sm"
              onClick={() => subItemHandler({}, false)}
            >
              <Icon icon="plus" className="mr-2" />
              <span>
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.addSubProduct
                </Text>
              </span>
            </Button>
          )}
        </Wrapper.Header>
        <Wrapper.Body>
          {data?.subProductItems.length ? (
            <>
              <Wrapper className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                {data?.subProductItems.map((subItem: any) => {
                  return (
                    <Wrapper.Body className="shadow-card rounded-xl">
                      <div className="flex flex-col gap-y-2 text-gray-500 space-y-4 text-body-2 font-medium">
                        <h6 className="text-heading-6 space-x-2 flex items-center">
                          <span>{subItem?.title}</span>
                          <span>{"(" + subItem?.count + ")"}</span>
                          {!hiddenButtons && (
                            <Icon
                              icon="edit"
                              className="mr-2 text-primary cursor-pointer"
                              onClick={() => subItemHandler(subItem, true)}
                            />
                          )}
                        </h6>
                        <p>
                          <Icon
                            icon="location-dot"
                            size="xl"
                            className="mr-1"
                          />{" "}
                          {store} / {subItem?.location}
                        </p>
                        <PriceTemplate
                          item={subItem}
                          className="flex space-x-2"
                        />
                        <div className="flex gap-2">
                          <p className="flex gap-2 items-center">
                            {subItem?.brandLogoUrl && (
                              <Image
                                src={subItem?.brandLogoUrl}
                                className="w-14 h-6 border-none"
                              />
                            )}
                            {subItem?.brandName}
                          </p>
                          <p className="flex gap-2 items-center">
                            {subItem?.supplierLogoUrl && (
                              <Image
                                src={subItem?.supplierLogoUrl}
                                className="w-14 h-6 border-none"
                              />
                            )}
                            {subItem?.supplierName}
                          </p>
                        </div>
                      </div>
                    </Wrapper.Body>
                  );
                })}
              </Wrapper>
            </>
          ) : (
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          )}
        </Wrapper.Body>
      </Wrapper>
      {/* {!isEmpty(subProduct) && ( */}
      <EditSubProductDrawer
        isOpen={isOpen}
        isEdit={isEditDrawer}
        data={subProduct}
        toggle={toggle}
        productId={data?.sellOffProductId}
        getData={getData}
      />
      {/* )} */}
    </>
  );
};

export default SubProducts;
