import { Badge, Skeleton, Text, Toggler, Wrapper } from "components";
import { useConverter } from "hooks";
import { useContext, useEffect, useMemo, useState } from "react";
import { SalesReportContext, dataType } from ".";
import AverageFactor from "./AverageFactor";
import Percent from "./Percent";

export default function MainData() {
  const { convertAmount } = useConverter();
  const { excludeSideCosts, toggleExcludeSideCosts, searchSalesReport } =
    useContext(SalesReportContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} as dataType);
  const params = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const dateFrom = new Date(`${currentYear}-01-01`);
    const dateTo = new Date(`${currentYear}-12-30`);
    return {
      dateFrom,
      dateTo,
      location: "",
      advisorFullName: "",
      excludeSideCosts,
    };
  }, [excludeSideCosts]);
  const getData = () => {
    setLoading(true);
    searchSalesReport(params)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getData, [params, searchSalesReport]);
  return loading ? (
    <div className="col-span-full">
      <Skeleton.ImageList />
    </div>
  ) : (
    <Wrapper className="col-span-full">
      <Wrapper.Body className="flex flex-col lg:flex-row lg:justify-between gap-4">
        <div className="space-y-4">
          <h5 className="text-gray-800">
            <Text>salesManagment.kitchenSales.salesReport.allStores</Text>
          </h5>
          <div className="space-x-4">
            <span className="text-gray-700 text-body-2">
              <Text>salesManagment.kitchenSales.salesReport.totalSales</Text>
            </span>{" "}
            <span className="text-info h6">
              {convertAmount(data.totalSales)}
            </span>
            <Percent value={data.growPercentage} />
            <Badge variant="gray">{params.dateTo.getFullYear()}</Badge>
          </div>
          <AverageFactor
            average={data.averageSellFactor}
            target={data.averageTargetSellFactor}
          />
        </div>
        <div>
          <Toggler
            value={excludeSideCosts}
            setValue={toggleExcludeSideCosts}
            label="salesManagment.kitchenSales.salesReport.excludeSideCost"
          />
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
