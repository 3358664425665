import { useContext, useEffect, useState } from "react";
import { Select, Text, Wrapper, Skeleton, Footer, Button } from "components";
import { ServiceApi, URLS } from "services";
import { UserContext } from "..";
import { config } from "constant";
import { rules } from "constant";
import AsyncSelect from "react-select/async";
import { Link } from "react-router-dom";

export default function Group() {
  const { userData, setUserData, submitLoading, isChanged } =
    useContext<any>(UserContext);
  const [groupList, setGroupList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [selectedOptionsGroup, setSelectedOptionsGroup] = useState<any>([]);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  const getData = () => {
    const url = URLS.USER_GROUP_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setGroupList(data.items);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetData = (selected: any) => {
    if (!selected) {
      setSelectedOptionsGroup(null);
    } else {
      setSelectedOptionsGroup(selected);
    }
    setUserData((p: any) => ({ ...p, ["userGroupId"]: selected?.id || "" }));
  };

  const handleSearchGroups = async (filter: string) => {
    const url = URLS.USER_GROUP_URL;
    const params = {
      pageNumber: 1,
      pageSize: 100,
      keyword: filter,
    };
    return ServiceApi.get(url, { params }).then(({ data }) => {
      const groups = data.items.map((group: any) => ({
        ...group,
        id: group.id,
        label: group.name,
        value: group.id,
        code: group.id,
      }));
      const selectedItem = groups.find(
        (item: any) => item.id === userData?.userGroupId
      );
      setSelectedOptionsGroup(selectedItem);
      return groups;
    });
  };

  const promiseGroupOptions = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(handleSearchGroups(inputValue));
      }, 200);
    });

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>configurations.userManagment.userList.usersGroup</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          {loadingList ? (
            <Skeleton.Input />
          ) : (
            <div className="space-y-4">
              <label className="h6 block w-full truncate font-[400] text-gray-800 ">
                <Text>configurations.userManagment.userList.userGroup</Text>
              </label>
              <AsyncSelect
                value={selectedOptionsGroup}
                cacheOptions
                placeholder={
                  <Text>
                    {"configurations.userManagment.userList.usersGroup"}
                  </Text>
                }
                loadOptions={promiseGroupOptions}
                defaultOptions
                onChange={handleSetData}
                styles={config.styleSelectReact}
                isClearable
                isDisabled={userData.userType === 4}
                required={userData.userType === 8}
              />
            </div>
          )}
        </Wrapper.Body>
      </Wrapper>
      <Footer show={!isChanged}>
        <Button as={Link} to={-1} variant="white">
          <Text>global.buttons.discard</Text>
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={!isChanged}
          loading={submitLoading}
        >
          <Text>global.buttons.saveChanges</Text>
        </Button>
      </Footer>
    </>
  );
}
