import { Status, Text } from "components";
import { convertDate } from "utils";

const SoldStatusTemplate = ({ item }: any) => {
  return (
    <div className="space-y-2 font-normal">
      <h6 className="text-heading-6">
        <Status.SellOff
          id={"salesManagment.sellOffSales.sellOffProduct.sold"}
        />
      </h6>
      <p>{item.currentPhaseName}</p>
      <p className="text-body-2">
        <Text>salesManagment.sellOffSales.sellOffProduct.soldBy</Text>{" "}
        {item?.sellerName}
      </p>
      <p className="text-body-2">{convertDate(item.soldDateTime)}</p>
    </div>
  );
};

export default SoldStatusTemplate;
