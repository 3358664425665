import { NotFound } from "pages";
import { MainLayout } from "../layuots";
import applications from "./applications";
import company from "./company";
import configurations from "./configurations";
import dashboard from "./dashboard";
import productManagement from "./product-managment";
import profile from "./profile";
import salesManagment from "./sales-managment";
import thirdParty from "./third-party";

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      ...dashboard,
      ...productManagement,
      ...salesManagment,
      ...applications,
      ...thirdParty,
      ...configurations,
      ...profile,
      ...company
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
export default routes;
