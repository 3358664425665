import RolesPage from "pages/configurations/user-managment/roles";
import Details from "pages/configurations/user-managment/roles/details";

const Roles = [
  {
    path: "",
    element: <RolesPage />,
  },
  {
    path: ":roleId",
    element: <Details />,
  },
];

export default Roles;
