import AttributesGroupsList from "pages/product-managment/master-data/attributes-groups";
import Details from "pages/product-managment/master-data/attributes-groups/details";


const attributesGroups = [
  {
    path: "",
    element: <AttributesGroupsList />,
  },
  {
    path: ":attributeGroupTemplateId",
    element: <Details />,
  },
];

export default attributesGroups;
