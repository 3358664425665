import {
  Button,
  DatePicker,
  Drawer,
  Icon,
  InputGroup,
  Select,
  Text,
  Toggler
} from "components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HandleParamsUrl } from "utils";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  resetFilter: () => void;
  onChange: (key: any) => any;
  data: any;
  params: any;
  setParams: (key: any) => void;
  setKeyword: (key: any) => void;
  keyword: any;
};

const FilterDrawer = ({
  isOpen,
  data,
  params,
  toggle,
  onChange,
  resetFilter,
  setParams,
  setKeyword,
  keyword,
}: drawerProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();


  const createItems = (key: string) => {
    const clone = data?.[key]?.map((item: any) => ({
      id: item.value,
      name: item.title + "(" + item.count + ")",
    }));
    clone?.unshift({ id: null, name: "All" });
    return clone;
  };

  const createStatusItems = () => {
    const clone = data?.status?.map((item: any) => ({
      id: item.value,
      name: item.value + "(" + item.count + ")",
    }));
    clone?.unshift({ id: null, name: "All" });
    return clone;
  };

  const createLocationItems = () => {
    const clone = data?.location?.map((item: any) => ({
      id: item.value,
      name: item.value + "(" + item.count + ")",
    }));
    clone?.unshift({ id: null, name: "All" });
    return clone;
  };

  const createProductFamilyItems = () => {
    const clone = data?.productFamily?.map((item: any) => ({
      id: item.value,
      name: item.title + "(" + item.count + ")",
    }));
    clone?.unshift({ id: null, name: "All" });
    return clone;
  };

  const submit = (e: any) => {
    e.preventDefault();
    if (keyword !== "") {
      setParams((prev: any) => {
        prev.set("searchFields", keyword)
        return prev
      })
    } else {
      setParams((prev: any) => {
        prev.delete("searchFields")
        return prev
      })
    }
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>salesManagment.sellOffSales.sellOffProduct.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {searchParams?.size !== 0 && <Button
                size="sm"
                variant={"danger"}
                light
                onClick={() => resetFilter()}
              >
                <Icon icon="times-circle" className="mr-1" />
                <Text>
                  salesManagment.sellOffSales.sellOffProduct.resetFilters
                </Text>
              </Button>}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>salesManagment.sellOffSales.sellOffProduct.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4">
          <div className="flex flex-center gap-2">
            <form
              onSubmit={submit}
              className="w-full"
            >
              <InputGroup
                label="productManagement.products.importProduct.keyword"
                value={keyword}
                placeholder="global.placeholders.search"
                setValue={(value) => setKeyword(value)}
              />
            </form>
            <div className="bg-gray-100 flex items-center justify-center rounded-md cursor-pointer h-[46px] w-[46px] p-3 mt-8"
              onClick={() => {
                if (keyword !== "") {
                  setParams((prev: any) => {
                    prev.set("searchFields", keyword)
                    return prev
                  })
                } else {
                  setParams((prev: any) => {
                    prev.delete("searchFields")
                    return prev
                  })
                }
              }}
            >
              <Icon className="h-5 w-5 text-primary !leading-6" icon={"search"} />
            </div>
          </div>
          <Select
            label="salesManagment.sellOffSales.sellOffProduct.productFamily"
            placeholder="Select"
            value={params?.get("pimProductFamilyId")}
            items={createProductFamilyItems()}
            setValue={onChange("pimProductFamilyId")}
          />
          <Select
            label="salesManagment.sellOffSales.sellOffProduct.status"
            placeholder="Select"
            value={params?.get("status")}
            items={createStatusItems()}
            setValue={onChange("status")}
          />
          <Select
            label="salesManagment.sellOffSales.sellOffProduct.brand"
            placeholder="Select"
            value={params?.get("brandId")}
            items={createItems("brand")}
            setValue={onChange("brandId")}
          />
          <Select
            label="salesManagment.sellOffSales.sellOffProduct.suppliers"
            placeholder="Select"
            value={params?.get("supplierId")}
            items={createItems("supplier")}
            setValue={onChange("supplierId")}
          />
          <Select
            label="salesManagment.sellOffSales.sellOffProduct.store"
            placeholder="Select"
            value={params?.get("storeId")}
            items={createItems("store")}
            setValue={onChange("storeId")}
          />
          <Select
            label="salesManagment.sellOffSales.sellOffProduct.location"
            placeholder="Select"
            value={params?.get("location")}
            items={createLocationItems()}
            setValue={onChange("location")}
          />
          <div className="grid grid-cols-2 gap-2 gap-y-4">
            <span className="col-span-2 text-sm text-gray-800">
              <Text>
                salesManagment.sellOffSales.sellOffProduct.startProccessDateRange
              </Text>
            </span>
            <DatePicker
              placeholderText="From"
              value={
                params?.get("startedDateFrom")
                  ? new Date(params?.get("startedDateFrom"))
                  : null
              }
              onChange={onChange("startedDateFrom")}
            />
            <DatePicker
              placeholderText="To"
              value={
                params?.get("startedDateTo")
                  ? new Date(params?.get("startedDateTo"))
                  : null
              }
              onChange={onChange("startedDateTo")}
            />
            <span className="col-span-2 text-sm text-gray-800">
              <Text>
                salesManagment.sellOffSales.sellOffProduct.changePhaseDateRange
              </Text>
            </span>
            <DatePicker
              placeholderText="From"
              value={
                params?.get("phaseChangedFrom")
                  ? new Date(params?.get("phaseChangedFrom"))
                  : null
              }
              onChange={onChange("phaseChangedFrom")}
            />
            <DatePicker
              placeholderText="To"
              value={
                params?.get("phaseChangedTo")
                  ? new Date(params?.get("phaseChangedTo"))
                  : null
              }
              onChange={onChange("phaseChangedTo")}
            />
          </div>
          <Toggler
            label={"salesManagment.sellOffSales.sellOffProduct.needPhaseChange"}
            value={params?.get("needChangePhase")}
            setValue={(e) => {
              if (e) {
                setParams((prev: any) => {
                  prev.set("needChangePhase", e)
                  return prev
                })
              } else {
                setParams((prev: any) => {
                  prev.delete("needChangePhase")
                  return prev
                })
              }
            }}
          />
          <Toggler
            label={"salesManagment.sellOffSales.sellOffProduct.needClosePhase"}
            value={params?.get("needClosePhase")}
            setValue={(e) => {
              if (e) {
                setParams((prev: any) => {
                  prev.set("needClosePhase", e)
                  return prev
                })
              } else {
                setParams((prev: any) => {
                  prev.delete("needClosePhase")
                  return prev
                })
              }
            }}
          />
          <Toggler
            label={"salesManagment.sellOffSales.sellOffProduct.hasProvision"}
            value={params?.get("hasFastSalesProvisionAmount")}
            setValue={(e) => {
              if (e) {
                setParams((prev: any) => {
                  prev.set("hasFastSalesProvisionAmount", e)
                  return prev
                })
              } else {
                setParams((prev: any) => {
                  prev.delete("hasFastSalesProvisionAmount")
                  return prev
                })
              }
            }}
          />
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
};

export default FilterDrawer;
