export const suppliers = {
  addSupplier: "Add Supplier",
  supplierName: "Supplier name",
  code: "Code",
  title: "Title",
  text: "Text",
  name: "Name",
  ilnNumber: "ILN (International Location Number)",
  iln: "ILN",
  description: "Description",
  emailAddress: "Email address",
  emailAddress1: "Email address 1",
  emailAddress2: "Email address 2",
  phoneNumber: "Phone number",
  basicInfo: "Basic info",
  generalInfo: "General info",
  contactInfo: "Contact info",
  program: "Program",
  saleSetting: "Sale Setting",
  location: "Location",
  review: "Review",
  configurable: "Configurable",
  manual: "Manual",
  addProgram: "Add Program",
  editProgram: "Edit Program",
  attribute: "Attribute",
  attributes: "Attributes",
  active: "Active",
  deActive: "Deactive",
  addAttribute: "Add attribute",
  required: "Required",
  generalInfoDesc: "Name, description, ...",
  contactInfoDesc: "Logo, email address, ...",
  reviewDesc: "Finalize information",
  locationDesc: "Supplier location",
  logo: "Logo",
  amount: "Amount",
  discount: "Discount",
  discounts: "Discounts",
  adddiscount: "Add Discount",
  supplierDiscount: "Supplier discount",
  programDiscount: "Program discount",
  customText: "Custom text",
  customTitle: "Custom title",
  dragChangeOrder: "Drag items to change order",
  sortOrder: "Sort order",
  zrNumber: "ZR Number",
  settings: "Settings",
  holidays: "Holidays",
  addHoliday: "Add Holiday",
  start: "Start",
  end: "End",
  lastOrder: "Last Order",
  earliestShip: "Earliest Ship",
  startDate: "Start Date",
  endDate: "End Date",
  lastOrderDate: "Last Order Date",
  earliestShipDate: "Earliest Ship Date",
  bankInfo: "Bank Info",
  defaultBankInfo: "Default Bank Info",
  bankName: "Bank Name",
  accountOwnerName: "Account Owner Name",
  iban: "IBAN",
  bic: "BIC",
  shipping: "Shipping",
  defaultMinimumShippingTime: "Default Minimum shipping Time",
  none: "None",
  workDay: "WorkDay",
  calendarDay: "CalendarDay",
  holiday: "Holiday",
  useDefaultMinimumShippingTime: "Use Default Minimum Shipping Time",
  minimumShippingTime: "Minimum shipping Time",
  useDefaultBankInfo: "Use Default Bank Info",
  brand: "Brand",
  select: "Select...",
  contactPerson: "Contact Person",
  addContactPerson: "Add Contact Person",
  firstName: "First Name",
  lastName: "Last Name",
  department: "Department",
  phone: "Phone",
  phone1: "Phone 1",
  phone2: "Phone 2",
};
