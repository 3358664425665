import { NotificationManager } from "common/notifications";
import { Button, Form, Icon, InputGroup, Modal, SelectLang, Text } from "components";
import { flagCountry, rules } from "constant";
import { useSelector } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ServiceApi, URLS } from "services";

type createModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: () => void;
};

export default function CreateModal(props: createModalType) {
  const [data, setData] = useState<any>(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [langSelected, setLangSelected] = useState("de");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const profile = useSelector((s) => s.profile);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isOpen) {
      setName("")
      setDescription("")
      let arr: any = [];
      let translatesNew: any = [];
      profile?.companyContentLanguages?.forEach((item: any) => {
        arr.push({ id: item, name: item })
        translatesNew.push({ name: "", description: "", language: item })
      })
      setData({ code: "", translations: translatesNew })
      setLangItems(arr)
      setLangSelected(profile.companyDefaultLanguage)

    }
  }, [profile, props.isOpen])// eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    setLoadingButton(true);
    data["translates"] = [];
    data?.translations?.forEach((item: any) => {
      if (item.name !== "") {
        data["translates"].push(item)
      }
    })
    const url = URLS.ADD_ATTRIBUTE_GROUP_URLS;
    ServiceApi.post(url, data).then((res) => {
      props.toggle();
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      props.toggle();
      navigate(`/productManagement/master-data/attribute-groupes/${res.data}?tab=1`);
    }).finally(() => {
      setLoadingButton(false);
    });
  };

  const onChangeLanguage = (lang: any) => {
    setLangSelected(lang);
    data?.translations?.forEach((item: any) => {
      if (item.language?.toLocaleLowerCase() === lang?.toLocaleLowerCase()) {
        setName(item.name)
        setDescription(item.description)
      }
    })
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Form onSubmit={submit} id="create-attributes-groups">
        <Modal.Header className="flex items-center justify-between">
          <div>
            <h2 className="text-heading-2 text-gray-800">
              <Text>productManagement.masterData.attributesGroups.addAttributeGroups</Text>
            </h2>
          </div>
          <div>
            <SelectLang value={langSelected} items={langItems} onChangeLanguage={onChangeLanguage} />
          </div>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            <InputGroup
              label="productManagement.masterData.attributesGroups.code"
              value={data?.code}
              className="border-white"
              setValue={(code) => setData((p: any) => ({ ...p, code }))}
              rules={rules.required}
            />
            <InputGroup
              label="productManagement.masterData.attributes.attributeName"
              value={name}
              flag={flagCountry[langSelected]}
              setValue={(value) => {
                data?.translations?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.name = value
                  }
                })
                setData((p: any) => ({ ...p, translations: data?.translations }));
                setName(value)
              }}
              rules={rules.required}
            />
            <InputGroup
              as="short-textarea"
              flag={flagCountry[langSelected]}
              label="productManagement.masterData.attributes.shortDescription"
              value={description}
              setValue={(value) => {
                data?.translations?.forEach((item: any) => {
                  if (item.language === langSelected) {
                    item.description = value
                  }
                })
                setData((p: any) => ({ ...p, translations: data?.translations }));
                setDescription(value)

              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={props.toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="submit" loading={loadingButton}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
