import { useTranslation } from "react-i18next";

type HandleNameProps = {
  translations: any;
  language?: any;
  code?: string;
  className?: string;
  keyName?: string
};

const HandleName = ({ translations, code, keyName = "name", language }: HandleNameProps) => {
  const { i18n } = useTranslation();
  if (language === undefined) {
    language = i18n.language;
  }
  if (translations) {
    let newArray = translations?.filter(function (item: any) {
      return language?.toLocaleLowerCase() === item.language?.toLocaleLowerCase() && item;
    });
    return (
      newArray.length !== 0 && newArray[0][keyName] ? newArray[0][keyName] :
      keyName !== "description" && <span className="text-gray-600 font-normal">{`No name in ${language} [${code}]`}</span>
    )
  }
  return ""
};

export default HandleName;
