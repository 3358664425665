import ImportProductsList from "pages/product-managment/products/import-product";


const ImportProducts = [
  {
    path: "",
    element: <ImportProductsList />,
  },
];

export default ImportProducts;
