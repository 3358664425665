import { NotificationManager } from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  Footer,
  Form,
  Icon,
  InputGroup,
  Skeleton,
  Text,
  Wrapper,
} from "components";
import { rules } from "constant";
import { useDataState, useToggleState } from "hooks";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";

export default function Iwofurn() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData, setBaseData, isChanged, base] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onEditHandler = (active: boolean) => {
    setLoadingButton(true);
    const emptyData = isEmpty(base);
    const { iwofurnPassword, iwofurnUserName, iwofurnUrl } = data;

    let putData, url, axiosHandler;

    if (!emptyData) {
      putData = {
        iwofurnPassword,
        iwofurnUserName,
        iwofurnUrl,
        isActive: active,
      };
      url = URLS.PUT_IWOFURN_URL(data.iwofurnConfigurationId);
      axiosHandler = ServiceApi.put;
    } else {
      url = URLS.CREATE_GET_IWOFURN_URL;
      putData = {
        iwofurnPassword,
        iwofurnUserName,
        iwofurnUrl,
      };
      axiosHandler = ServiceApi.post;
    }

    axiosHandler(url, putData)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
      })
      .finally(() => {
        setLoadingButton(false);
        if (isOpenDeleteModal) {
          toggleDeleteModal();
        }
        getData();
      });
  };

  const getData = () => {
    setLoadingPage(true);
    ServiceApi.get(URLS.CREATE_GET_IWOFURN_URL).then(({ data }) => {
      setBaseData(data);
      setLoadingPage(false);
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <Breadcrumb />
        <Button
          size="sm"
          variant={"white"}
          textColor="danger"
          disabled={isEmpty(base)}
          onClick={(e) => {
            // console.log("asdoig");
            toggleDeleteModal();
          }}
        >
          <Icon icon="trash" className="mr-2" />
          <span className="mr-2">
            <Text>thirdParty.iwofurn.delete</Text>
          </span>
        </Button>
      </div>
      <Form onSubmit={() => onEditHandler(true)} className="relative space-y-6">
        <Wrapper>
          <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
            <div className="space-y-8">
              {loadingPage ? (
                <Skeleton.Input />
              ) : (
                <InputGroup
                  label="thirdParty.iwofurn.userName"
                  value={data.iwofurnUserName}
                  setValue={(iwofurnUserName) =>
                    setData((p: any) => ({ ...p, iwofurnUserName }))
                  }
                  rules={rules.required}
                />
              )}
              {loadingPage ? (
                <Skeleton.Input />
              ) : (
                <InputGroup
                  label="thirdParty.iwofurn.password"
                  value={data.iwofurnPassword}
                  setValue={(iwofurnPassword) =>
                    setData((p: any) => ({ ...p, iwofurnPassword }))
                  }
                  rules={rules.required}
                />
              )}
              {loadingPage ? (
                <Skeleton.Input />
              ) : (
                <InputGroup
                  label="thirdParty.iwofurn.url"
                  value={data.iwofurnUrl}
                  setValue={(iwofurnUrl) =>
                    setData((p: any) => ({ ...p, iwofurnUrl }))
                  }
                  rules={rules.required}
                />
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>

        <Footer show={!isChanged}>
          <Button type="button" onClick={getData} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      </Form>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{ name: data.iwofurnUserName }}
        loading={loadingButton}
        onConfirm={() => onEditHandler(false)}
      />
    </div>
  );
}
