import { Fragment, useState } from "react";
import { toggleProps } from "types";
import SelectProduct from "./SelectProduct";
import ProductData from "./ProductData";
import { channelProductType } from "types";

export default function ProductForm({ isOpen, toggle }: toggleProps) {
  const [channelProduct, setChannelProduct] =
    useState<channelProductType | null>(null);
  const clearProduct = () => {
    setChannelProduct(null);
  };
  return (
    <Fragment>
      <SelectProduct
        isOpen={isOpen}
        toggle={toggle}
        onSelect={setChannelProduct}
      />
      <ProductData
        isOpen={!!channelProduct}
        toggle={clearProduct}
        channelProduct={channelProduct}
      />
    </Fragment>
  );
}
