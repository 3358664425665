import { useState, createContext, Fragment, createElement } from "react";
import {
  Form,
  Button,
  Text,
  Wrapper,
  Breadcrumb,
  Stepper,
  Icon
} from "components";
import { ServiceApi, URLS } from "services";
import { useNavigate } from "react-router-dom";
import { usePermission } from "hooks";
import { NotificationManager } from "common/notifications";
import { appPermissions } from "constant";

//... tabs 
import General from "./tabs/general"
import UserGroup from "./tabs/users-Group"
import Location from "./tabs/location";
import Review from "./tabs/review";



export const AddUserListContext = createContext({});

export default function Create() {

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [data, setData] = useState<any>({
    firstName: "",
    lastName: "",
    personalNumber: "",
    emailAddress: "",
    gender: 0,
    dateOfBirth: null,
    phoneNumber: "",
    mobileNumber: "",
    userGroupId: "",
    street: "",
    houseNumber: "",
    postalCode: "",
    city: "",
    country: "",
    state: "",
  });
  const [dataImg, setDataImg] = useState<any>({
    thumbnailUrl: "",
    type: "",
    url: ""
  });
  const navigate = useNavigate();
  const tabs = [
    {
      label: "configurations.userManagment.userList.general",
      desc: "configurations.userManagment.userList.generalDesc",
      component: General,
    },
    {
      label: "configurations.userManagment.userList.userGroup",
      desc: "configurations.userManagment.userList.userGroupDesc",
      component: UserGroup,
    },
    {
      label: "configurations.userManagment.userList.location",
      desc: "configurations.userManagment.userList.locationDesc",
      component: Location,
    },
    {
      label: "configurations.userManagment.userList.review",
      desc: "configurations.userManagment.userList.reviewDesc",
      component: Review,
    },
  ];
  const [loading, setLoading] = useState(false);
  const activeTab = tabs[activeTabIndex];
  const isFirstTab = activeTabIndex === 0;
  const isLastTab = activeTabIndex === tabs.length - 1;
  const goNext = () => {
    if (isLastTab) return submit();
    setActiveTabIndex((p) => p + 1);
  };
  const goPrev = () => {
    if (isFirstTab) return;
    setActiveTabIndex((p) => p - 1);
  };


  //permissions
  const {
    PS_CreateSellPhaseTemplate,
  } = appPermissions;
  const CREATE_PERMISSIONS = usePermission(PS_CreateSellPhaseTemplate);


  const submit = () => {
    setLoading(true);
    const url = URLS.ADD_B2B_USERS_URL;
    ServiceApi.post(url, data).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      navigate(-1);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 items-start">
        <Stepper tabs={tabs as []} activeTab={activeTabIndex} />
        <Wrapper
          as={Form}
          onSubmit={goNext}
          className="col-span-full lg:col-span-8"
        >
          <Wrapper.Body className="w-full lg:w-[calc(100%-9rem)] mx-auto space-y-8">
            <AddUserListContext.Provider
              value={{
                dataImg,
                setDataImg,
                data,
                setData,
              }}
            >
              {createElement(activeTab.component)}
            </AddUserListContext.Provider>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                disabled={isFirstTab}
                onClick={goPrev}
              >
                <Icon icon="arrow-left" /> <Text>global.buttons.back</Text>
              </Button>
              {CREATE_PERMISSIONS && (
                <Button
                  type="submit"
                  variant="primary"
                  loading={loading}
                >
                  {isLastTab ? (
                    <Fragment>
                      <Icon icon="check" /> <Text>global.buttons.submit</Text>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Text>global.buttons.next</Text> <Icon icon="arrow-right" />
                    </Fragment>
                  )}
                </Button>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>
      </div>
    </div>
  );
}
