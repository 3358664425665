import {
  Accordion,
  Button,
  CheckBox,
  Drawer,
  Icon,
  InputGroup,
  Select,
  Skeleton,
  Text,
} from "components";
import { useToggleState } from "hooks";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  productData: any;
  setData: any;
  setPatchData: any;
};

const AssignCategoryDrawer = ({
  isOpen,
  toggle,
  productData,
  setData,
  setPatchData,
}: drawerProps) => {
  const [loadRoots, setRootLoading] = useToggleState();
  const [loadChildren, setChildrenLoading] = useToggleState();
  const [roots, setRoots] = useState([{ id: "", name: "" }]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [categoryChildrenList, setCategoryChildrenList] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [publishedCategory, setPublishedCategory] = useState<any>({});

  const getRoots = () => {
    setCategoryList([]);
    setRootLoading();
    const url = URLS.GET_ROOT_CATEGORIES;
    ServiceApi.get(url)
      .then(({ data }) => {
        let rootsItem = data.map((category: any) => ({
          id: category.productCategoryId,
          name: category.code,
        }));
        setRoots(rootsItem);
        setSelectedCategory("");
        setRootLoading();
      })
      .catch(() => setRootLoading());
  };

  useEffect(() => {
    if (isOpen) {
      getRoots();
    }
  }, [isOpen]);

  const findChildrens = (data: any, id: string, parent: any) => {
    const childrens = data.filter((item: any) => item.parentId === id);
    if (childrens.length > 0) {
      parent.items = childrens;
      childrens.map((category: any) => {
        findChildrens(data, category.productCategoryId, category);
      });
    }
    return [parent];
  };

  const getCategoryChildren = (categoryId: string) => {
    setChildrenLoading();
    const url = URLS.GET_CATEGORY_WITH_CHILDREN(categoryId || selectedCategory);
    ServiceApi.get(url)
      .then(({ data }) => {
        setCategoryChildrenList(data);
        let parent = data.find((item: any) => item.parentId === null);
        const categoryList = findChildrens(
          data,
          parent.productCategoryId,
          parent
        );
        setCategoryList(categoryList);
        setChildrenLoading();
      })
      .catch(() => setChildrenLoading());
  };

  const onSelectCategoryHandler = (categoryId: string) => {
    setSelectedCategory(categoryId);
    getCategoryChildren(categoryId);
  };

  const createCategoryAccordion = (categoryArr: any) => {
    return categoryArr?.map((category: any) => {
      return (
        <Accordion key={category?.code}>
          <Accordion.Item
            className={`bg-white shadow-nested border px-4 rounded-2xl mb-4`}
          >
            <Accordion.Toggle className="flex items-center justify-between">
              <span className="text-heading-6 font-medium">
                {category?.translations?.[0]?.name}
              </span>
              <p className="text-body-2 font-medium">{category?.code}</p>
              <div>
                <CheckBox
                  key={`checkbox_${category?.code}`}
                  disabled={productData.productCategoryIds.includes(
                    category?.productCategoryId
                  )}
                  value={
                    publishedCategory.productCategoryId ===
                      category?.productCategoryId ||
                    productData.productCategoryIds.includes(
                      category?.productCategoryId
                    )
                  }
                  setValue={(e) => {
                    if (e) {
                      setPublishedCategory(category);
                    } else {
                      setPublishedCategory({});
                    }
                  }}
                />
              </div>
            </Accordion.Toggle>
            {category?.items && (
              <>
                <Accordion.Body className="px-4">
                  {createCategoryAccordion(category?.items)}
                </Accordion.Body>
              </>
            )}
          </Accordion.Item>
        </Accordion>
      );
    });
  };

  const onSubmit = () => {
    const clone = [...(productData.detailCategories || [])];
    const ids = [...(productData.productCategoryIds || [])];
    if (!ids.includes(publishedCategory.productCategoryId)) {
      const newCategory = {
        id: publishedCategory.productCategoryId,
        translates: publishedCategory.translations,
      };
      clone.push(newCategory);
      ids.push(publishedCategory.productCategoryId);
      setPatchData((p: any) => ({
        ...p,
        detailCategories: clone,
        productCategoryIds: ids,
      }));
      setData((p: any) => ({
        ...p,
        detailCategories: clone,
        productCategoryIds: ids,
      }));
      toggle();
    }
  };

  useEffect(() => {
    if (categoryChildrenList) {
      let clone: any = [];
      clone = categoryChildrenList?.filter((category: any) => {
        const name = category.translations?.[0].name
          ? category.translations?.[0].name.toLowerCase()
          : "";
        const code = category.code.toLowerCase();
        if (name.includes(searchValue) || code.includes(searchValue)) {
          return category;
        }
      });
      if (clone.length === categoryChildrenList?.length) {
        let parent = categoryChildrenList.find(
          (item: any) => item.parentId === null
        );
        const categoryList = findChildrens(
          categoryChildrenList,
          parent?.productCategoryId,
          parent
        );
        setCategoryList(categoryList);
      } else {
        setCategoryList(clone);
      }
    }
  }, [searchValue]);

  const onSearchHandler = (e: string) => {
    setSearchValue(e);
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle} size={"lg"}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.Details.assignCategory</Text>
            </span>
            <div className="flex gap-x-2">
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-6 pr-4">
          {loadRoots ? (
            <div className="space-y-4">
              <span className="text-gray-800 h6 font-normal">
                <Text>productManagement.products.Details.productCategory</Text>
              </span>
              <Skeleton.Input />
            </div>
          ) : (
            <div className="space-y-2">
              <Select
                label="productManagement.products.Details.productCategory"
                placeholder="productManagement.products.Details.selectCategory"
                items={roots}
                value={selectedCategory}
                setValue={onSelectCategoryHandler}
              />
              {categoryChildrenList.length > 0 && (
                <div className="grid grid-cols-2 gap-4 items-center">
                  <InputGroup
                    value={searchValue}
                    setValue={onSearchHandler}
                    placeholder="productManagement.products.Details.searchCategory"
                    disabled={loadChildren}
                  />
                  <p className="text-body-2">
                    {searchValue.length === 0
                      ? categoryChildrenList.length
                      : categoryList.length}{" "}
                    Items
                  </p>
                </div>
              )}
            </div>
          )}
          {loadChildren
            ? [1, 2, 3, 4].map((key) => <Skeleton.List key={key} />)
            : !loadRoots && (
                <div className="space-y-4">
                  {createCategoryAccordion(categoryList)}
                </div>
              )}
        </Drawer.Body>
        <Drawer.Footer className="flex justify-end">
          <Button
            size="sm"
            onClick={onSubmit}
            disabled={isEmpty(publishedCategory)}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
};

export default AssignCategoryDrawer;
