import { Button, Modal, Text, Icon } from "components";

type deleteModalType = {
  isOpen: boolean;
  loading: boolean;
  toggle: () => void;
  onConfirm: (id: any) => void;
  id: string;
  selectedName?: any;
};

export default function DeleteModal(props: deleteModalType) {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      width="w-[400px]"
      modalClassName="z-40"
    >
      <Modal.Body className="max-h-100 overflow-auto">
        <div className="text-center pb-4 text-[80px]">
          <Icon
            size="lg"
            className="text-danger"
            icon={"triangle-exclamation"}
          />
        </div>
        <div className="text-body-base text-gray-700 text-center">
          <span>
            <Text>
              global.areYouSureYouWantToDelete
            </Text>
          </span>
        </div>
        <div className="text-heading-6 text-gray-800 text-center py-4">
          <h6>
            {props.selectedName}
            <span>?</span>
          </h6>
        </div>
        <div className="text-body-base text-danger text-center pb-2">
          <span>
            <Text>global.youCantUndoThis</Text>!
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button onClick={props.toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.ImNotSure</Text>
        </Button>
        <Button
          type="submit"
          className=""
          variant="danger"
          onClick={() => props.onConfirm(props.id)}
          loading={props.loading}
        >
          <Icon className="mr-2" icon={"trash"} />
          <Text>global.buttons.delete</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
