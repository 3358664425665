export const roles = {
    addRole: "Add Role",
    general: "General",
    permissions: "Permissions",
    basicInfo: "Basic Info",
    name: "Name",
    description: "Description",
    shortDescription: "Short Description",
    permissionDependency: "Permission Dependency",
    thesePermissionsWillAlsoEnable: "These permissions will also enable",
    thesePermissionsWillAlsoDisable: "These permissions will also disable"
}