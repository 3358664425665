import { useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  Button,
  Text,
  Wrapper,
  Skeleton,
  Breadcrumb,
  Footer,
} from "components";
import { ServiceApi, URLS } from "services";
import { useDataState, usePermission } from "hooks";
import { appPermissions, rules } from "constant";
import { NotificationManager } from "common/notifications";

export default function SsoSettings() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataFirst, setDataFirst] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);

  //permissions
  const { AS_UpdateCompanySSO, AS_GetCompanySSODetail } = appPermissions;
  const GET_PERMISSION = usePermission(AS_GetCompanySSODetail);
  const UPDATE_PERMISSION = usePermission(AS_UpdateCompanySSO);

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.EDIT_COMPANY_SSO;
    if (dataFirst) {
      ServiceApi.put(url, data)
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title"
          );
        })
        .finally(() => {
          setLoadingButton(false);
          getData();
        });
    } else {
      ServiceApi.post(url, data)
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title"
          );
        })
        .finally(() => {
          setLoadingButton(false);
          getData();
        });
    }
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setLoadingPage(true);
    await ServiceApi.get(URLS.GET_COMPANY_SSO).then(({ data }) => {
      setBaseData(data);
      setLoadingPage(false);
      if (data.length === 0) {
        setDataFirst(false);
      } else {
        setDataFirst(true);
      }
    });
  };

  return GET_PERMISSION ? (
    <Form onSubmit={submit} className="relative space-y-6">
      <h3 className="mr-auto text-heading-3">
        <Breadcrumb />
      </h3>
      <Wrapper>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
          <div className="space-y-8">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.ssoSettings.title"
                value={data.title}
                setValue={(title) => setData((p: any) => ({ ...p, title }))}
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.ssoSettings.clientId"
                value={data.clientId}
                setValue={(clientId) =>
                  setData((p: any) => ({ ...p, clientId }))
                }
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.ssoSettings.clientSecret"
                value={data.clientSecret}
                setValue={(clientSecret) =>
                  setData((p: any) => ({ ...p, clientSecret }))
                }
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.ssoSettings.appTenantId"
                value={data.appTenantId}
                setValue={(appTenantId) =>
                  setData((p: any) => ({ ...p, appTenantId }))
                }
                rules={rules.required}
              />
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      {UPDATE_PERMISSION ? (
        <Footer show={!isChanged}>
          <Button type="button" onClick={getData} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      ) : null}
    </Form>
  ) : (
    <>Permission Error</>
  );
}
