import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Pagination,
  SearchBox,
  Text,
  Skeleton,
  Breadcrumb,
  Icon,
  NoItems,
  Status,
} from "components";
import { usePermission, useToggleState } from "hooks";
import { ServiceApi, URLS } from "services";
import { Link, useSearchParams } from "react-router-dom";
import { config, appPermissions } from "constant";
import InviteSearchModal from "./invite/invite-search-modal";

type UserListItems = {
  userId: string;
  code: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  personalNumber: string;
  userGroupName: string;
  userType: number;
  imageThumbnailUrl: any;
};

type ListType = {
  items?: UserListItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function UserList() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [isOpen, toggle] = useToggleState(false);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();


  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_B2B_USERS_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  //permissions
  const {
    AS_GetB2BUsers,
    AS_GetB2BUserDetail,
    AS_CreateB2BUser,
    AS_InviteB2BUserIdP,
  } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(AS_GetB2BUsers);
  const CREATE_PERMISSION = usePermission(AS_CreateB2BUser);
  const INVITE_PERMISSION = usePermission(AS_InviteB2BUserIdP);
  const GET_DETAIL_PERMISSION = usePermission(AS_GetB2BUserDetail);

  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {loadingList ? (
            <Skeleton.Button />
          ) : INVITE_PERMISSION ? (
            <Button
              type="button"
              variant="white"
              textColor="primary"
              onClick={toggle}
            >
              <Icon className="mr-2" icon={"plus"} />
              <Text>configurations.userManagment.userList.inviteUser</Text>
            </Button>
          ) : null}
          {loadingList ? (
            <Skeleton.Button />
          ) : CREATE_PERMISSION ? (
            <Button type="button" as={Link} to="new">
              <Icon className="mr-2" icon={"plus"} />
              <Text>configurations.userManagment.userList.addUser</Text>
            </Button>
          ) : null}
        </div>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.userId}>
                      <td>
                        <Link
                          to={GET_DETAIL_PERMISSION ? item.userId : ""}
                          className="w-fit flex-center flex gap-2"
                        >
                          <Table.Image
                            placeholder="user"
                            src={item?.imageThumbnailUrl}
                            alt={item.firstName}
                            className="h-[78px] w-[78px]"
                          />
                          <div className="space-y-2">
                            <div>
                              <h6 className="text-heading-6 text-gray-800 font-semibold">{`${item.firstName} ${item.lastName}`}</h6>
                            </div>
                            <div>
                              <Icon
                                className="text-gray-500 font-black mr-2 h-4 w-4"
                                icon="envelope"
                              />
                              <span className="text-body-2 text-gray-500 font-medium">
                                {item.emailAddress}
                              </span>
                            </div>
                            {item.personalNumber && (
                              <div>
                                <Icon
                                  className="text-gray-500 font-black mr-2"
                                  icon="hashtag"
                                />
                                <span className="text-body-2 text-gray-500 font-medium">
                                  {item.personalNumber}
                                </span>
                              </div>
                            )}
                          </div>
                        </Link>
                      </td>
                      <td>
                        <div className="text-body-base font-normal  text-left">
                          <span className="text-gray-800">
                            <Text>
                              configurations.userManagment.userList.group
                            </Text>
                          </span>
                          :
                          <span className="text-gray-600 ml-1">
                            {item.userGroupName}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Status.roles id={item.userType} />
                      </td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button
                            as={Link}
                            to={item.userId}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams((p) => ({ ...p, pageSize: e }))
                }
              />
              <InviteSearchModal isOpen={isOpen} toggle={toggle} />
            </>
          ) : (
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <>Permission Error</>
  );
}
