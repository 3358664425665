import { useState, useEffect, Fragment } from "react";
import {
  Button,
  Table,
  Text,
  Skeleton,
  Breadcrumb,
  NoItems,
  Icon,
  InputGroup,
  Form
} from "components";
import { useToggleState, usePermission } from "hooks";
import { useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { appPermissions } from "constant";
import InviteDrawwer from "./invite-drawer"

export default function Invite() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<any>([]);
  const [loadingList, setLoadingList] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [selected, setSelected] = useState("");
  const [isOpenDrawerInvite, toggleDrawerInvite] = useToggleState(false);
  const [inputValue, setInputValue] = useState(searchParams.get("search"))

  const getData = () => {
    const url = URLS.SEARCH_AD_USERS(inputValue);
    setLoadingList(true);
    ServiceApi.get(url).then(({ data }) => {
      setData(data);
    }).finally(() => {
      setLoadingList(false);
      setLoadingButton(false);
    });
  };
  useEffect(getData, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    if (inputValue !== "") {
      searchParams.set("search", inputValue as "");
      setSearchParams(searchParams);
      setLoadingButton(true)
      getData()
    }
  }

  //permissions
  const { AS_InviteB2BUserIdP } = appPermissions;
  const INVITE_PERMISSION = usePermission(AS_InviteB2BUserIdP);


  return INVITE_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <>
        <div>
          <Form onSubmit={submit}>
            <div className="flex gap-4 bg-white rounded-xl p-4 items-center">
              <div className="w-[443px]">
                <InputGroup
                  value={inputValue}
                  className="border-white"
                  placeholder="Search User Name, Email ..."
                  setValue={(value) => setInputValue(value)}
                />
              </div>
              <div>
                <Button type="submit"
                  loading={loadingButton}
                  disabled={inputValue === "" ? true : false}>
                  <Fragment>
                    <Icon icon="search" className="mr-2" />
                    <Text>configurations.userManagment.userList.searchUser</Text>
                  </Fragment>
                </Button>
              </div>
            </div>
          </Form>
        </div>
        {!loadingList ?
          <>
            <Table>
              {data?.length === 0 ? (
                <div className="flex flex-col items-center space-y-4 w-full">
                  <NoItems />
                </div>

              ) : (
                data?.map((item: any) => {
                  return (
                    <tr key={item.userId} >
                      <td className="space-y-2">
                        <div><h6 className="text-heading-6 text-gray-800 font-semibold">{item.userPrincipalName}</h6></div>
                        <div><Icon className="text-gray-500 font-black mr-2" icon="envelope" /><span className="text-body-2 text-gray-500 font-medium">{item.email}</span></div>
                      </td>
                      <td>
                        <span className="text-body-base text-gray-600 font-normal">{item.displayName}</span>
                      </td>
                      <td>
                        {INVITE_PERMISSION && (
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() => {
                              toggleDrawerInvite();
                              setSelected(item.id);
                            }}
                          >
                            <Text>global.buttons.invite</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </Table>
          </>
          :
          <>
            <Skeleton.List />
            <Skeleton.List />
            <Skeleton.List />
            <Skeleton.List />
          </>
        }
      </>
      <InviteDrawwer isOpen={isOpenDrawerInvite} toggle={toggleDrawerInvite} selected={selected} />
    </div>
  ) : (
    <>Permission Error</>
  );
}
