import { InputGroup, Select, Wrapper } from "components";
import { countries } from "constant";
import SearchAddress from "components/SearchAddress";
import { AddUserListContext } from "..";
import { Fragment, useContext } from "react";
import cloneDeep from "lodash/cloneDeep";
import { rules } from "constant";

export default function Location() {
  const { data, setData } = useContext<any>(AddUserListContext);
  const handleSetValue = (key: any) => {
    return (value: any) =>
      setData((p: any) => {
        const data = cloneDeep(p);
        data[key] = value;
        return data;
      });
  };

  const handleSetAddress = (address: any) => {
    setData((p: any) => {
      return {
        ...p,
        street: address.street,
        houseNumber: address.houseNo,
        postalCode: address.postalCode,
        city: address.city,
        country: address.country,
        state: address.state,
      };
    });
    return {};
  };

  return (
    <Fragment>
      <SearchAddress setValue={handleSetAddress as any} />
      <Wrapper.Section className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 items-stretch">
        <InputGroup
          label="global.locations.street"
          value={data.street}
          setValue={handleSetValue("street")}
          rules={rules.required}
        />
        <InputGroup
          label="global.locations.houseNumber"
          value={data.houseNumber}
          setValue={handleSetValue("houseNumber")}
          rules={rules.required}
        />
        <InputGroup
          label="global.locations.postalCode"
          value={data.postalCode}
          setValue={handleSetValue("postalCode")}
          rules={rules.required}
        />
        <InputGroup
          label="global.locations.city"
          value={data.city}
          setValue={handleSetValue("city")}
          rules={rules.required}
        />
        <div className="col-span-full">
          <Select
            label="global.locations.country"
            value={data.country}
            setValue={handleSetValue("country")}
            items={countries}
            rules={rules.required}
          />
        </div>
        <div className="col-span-full">
          <Select
            label="global.locations.state"
            value={data.state}
            setValue={handleSetValue("state")}
            items={countries.find((e) => e.id === data.country)?.states as []}
            rules={rules.required}
          />
        </div>
      </Wrapper.Section>
    </Fragment>
  );
}
