import { NotificationManager } from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  Footer,
  Form,
  HandleName,
  Icon,
  Skeleton,
  Tabs,
  Text,
  Wrapper
} from "components";
import { appPermissions } from "constant";
import {
  useDataState,
  usePermission,
  useSelector,
  useToggleState,
} from "hooks";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { ObjectUtils } from "utils";
//..... tabs
import { AttributesTypes } from "utils";
import AttributesType from "./tabs/attributes-type";
import AttributesTypeDate from "./tabs/attributes-type-date";
import AttributesTypeMeasurement from "./tabs/attributes-type-measurement";
import AttributesTypeSelect from "./tabs/attributes-type-select";
import AttributesTypeYesNo from "./tabs/attributes-type-yesno";
import Channels from "./tabs/channels";
import General from "./tabs/general";

export const AttributesContext = createContext({});

export default function Details() {
  const { attributeId } = useParams();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [langItems, setLangItems] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [placeHolder, setPlaceHolder] = useState("");
  const profile = useSelector((s) => s.profile);
  const navigate = useNavigate();
  const [selected, setSelected] = useState<any>();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [langSelected, setLangSelected] = useState("de");

  //permissions
  const { PS_UpdateAttributeTemplate } = appPermissions;
  const UPDATE_PERMISSIONS = usePermission(PS_UpdateAttributeTemplate);
  
  const tabType = (e: any) => {
    switch (e) {
      case 20:
        return AttributesTypeYesNo;
      case 30:
        return AttributesTypeSelect;
      case 40:
        return AttributesTypeDate;
      case 50:
        return AttributesTypeDate;
      case 60:
        return AttributesTypeDate;
      case 70:
        return AttributesTypeMeasurement;
      case 80:
        return AttributesType;
      default:
        return AttributesType;
    }
  };

  const tabs = [
    {
      label: "productManagement.masterData.attributes.general",
      component: General,
    },
    {
      label: "productManagement.masterData.attributes.attributeType",
      component: tabType(data?.type),
    },
    {
      label: "productManagement.masterData.attributes.channels",
      component: Channels,
    },
  ];

  const getData = () => {
    setLoading(true);
    let arr: any = [];
    profile?.companyContentLanguages?.forEach((item: any) => {
      arr.push({ id: item?.toLocaleLowerCase(), name: item });
    });
    const url = URLS.GET_ATTRIBUTES_DETAILS_URLS(attributeId);
    let newData: any;
    ServiceApi.get(url)
      .then(({ data }) => {
        newData = JSON.parse(JSON.stringify(data));
        newData &&
          newData.translations?.forEach((i: any, index: number) => {
            if (
              i?.language?.toLocaleLowerCase() ===
              profile?.companyDefaultLanguage?.toLocaleLowerCase()
            ) {
              setName(i.displayName);
              setDescription(i.description);
              setPlaceHolder(i.placeHolder);
            }
          });
        if (newData?.translations?.length !== arr?.length) {
          var props = ["id"];
          var result = arr
            ?.filter(function (i: any) {
              return !newData?.translations?.some(function (a: any) {
                return (
                  i?.id?.toLocaleLowerCase() === a?.language?.toLocaleLowerCase()
                );
              });
            })
            .map(function (o: any) {
              return props?.reduce(function (newo: any, language: any) {
                newo["id"] = o[language];
                return newo;
              }, {});
            });

          result?.forEach((item: any) => {
            newData?.translations.push({
              language: item.id,
              displayName: "",
              description: "",
              placeHolder: "",
            });
          });
        }
        // }
        setBaseData(newData);
      })
      .finally(() => {
        setLoading(false);
      });
    setLangSelected(profile?.companyDefaultLanguage?.toLocaleLowerCase())
    setLangItems(arr);
  };

  const submit = () => {
    setSubmitLoading(true);
    data["translates"] = [];
    data["order"] = data.orderAttribute;
    if (data.type === 20) {
      const url = URLS.UPDATE_ITEM_YESNO_ATTRIBUTES_URLS(attributeId);
      data?.items?.forEach((item: any) => {
        let translations: any = [];
        item?.translates?.forEach((i: any) => {
          if (i.displayName !== "") {
            translations.push(i);
          }
        });
        if (item?.id === "true") {
          let bodyTrue = {
            attributeItemId: "true",
            icon: "",
            setAsDefault: data.defaultValue === "true" ? true : false,
            translates: translations,
          };
          if (bodyTrue?.translates?.length !== 0) {
            ServiceApi.patch(url, bodyTrue);
          }
        } else {
          let bodyFalse = {
            attributeItemId: "false",
            icon: "",
            setAsDefault: data.defaultValue === "false" ? true : false,
            translates: translations,
          };
          if (bodyFalse?.translates?.length !== 0) {
            ServiceApi.patch(url, bodyFalse);
          }
        }
      })

    }
    const url = URLS.UPDATE_ATTRIBUTES_URLS(attributeId);
    data?.translations?.forEach((item: any) => {
      if (item.displayName !== "") {
        data["translates"].push(item);
      }
    });
    const body = { ...data };
    ServiceApi.put(url, body)
      .then(() => {
        getData();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        getData();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  useEffect(getData, [profile]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_ATTRIBUTES_URLS(id);
    ServiceApi.delete(url)
      .then(async () => {
        setLoadingButton(false);
        toggleDeleteModal();
        navigate(-1);
      })
      .catch(() => setLoadingButton(false));
  };

  return (
    <Tabs activeKey={tabs[searchParams.get("tab") as "0"].label}>
      <Form onSubmit={submit} className="relative space-y-6">
        <div className="flex items-center">
          <div className="flex-1">
            <Breadcrumb />
          </div>
          {loading ? (
            <Skeleton.Button />
          ) : UPDATE_PERMISSIONS ? (
            <Button
              type="button"
              variant="white"
              textColor="danger"
              onClick={() => {
                setSelected({ id: attributeId, name: <HandleName translations={data?.translations} code={data?.code} keyName="displayName" /> });
                toggleDeleteModal();
              }}
            >
              <Icon className="mr-2" icon={"trash"} />
              <Text>global.buttons.delete</Text>
            </Button>
          ) : null}
        </div>
        <Wrapper>
          <Wrapper.Body className="flex flex-col lg:flex-row items-start gap-5">
            <div className="lg:flex-1 space-y-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <div>
                    <h5 className="text-heading-5 text-gray-800 font-semibold">
                      {<HandleName translations={data?.translations} code={data?.code} keyName="displayName" />}
                    </h5>
                  </div>
                  <div>
                    <span className="text-gray-500 text-body-2 font-medium pr-2">
                      {data.code}
                    </span>
                    <span className="text-body-2 text-gray-500 border-l-2 border-gray-300  px-2">
                      {AttributesTypes(data.type)}
                    </span>
                  </div>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e) => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map((e) => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <AttributesContext.Provider
              value={{
                attributeId: attributeId,
                attributesData: data,
                description: description,
                placeHolder: placeHolder,
                name: name,
                langItems: langItems,
                loading: loading,
                setLangItems: setLangItems,
                setName: setName,
                setDescription: setDescription,
                setPlaceHolder: setPlaceHolder,
                setAttributesData: setData,
                getData: getData,
                setLoading: setLoading,
                langSelected: langSelected,
                setLangSelected: setLangSelected
              }}
            >
              {!ObjectUtils.isEmpty(data) && createElement(e.component)}
            </AttributesContext.Provider>
          </Tabs.Item>
        ))}
        {UPDATE_PERMISSIONS && (
          <Footer show={!isChanged} >
            <Button
              as={Link}
              to={"/productManagement/master-data/attributes"}
              variant="white"
            >
              <Text>global.buttons.discard</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!isChanged}
              loading={submitLoading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        )}
      </Form>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={selected}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </Tabs>
  );
}
