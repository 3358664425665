import { configureStore } from "@reduxjs/toolkit";
import profile from "./profile";
import loading from "./loading";
import permissions from "./permissions";
import selloff from "./sell-off";
import productFmilies from "./productFmilies";
import productCategories from "./productCategories";
const store = configureStore({
  reducer: { profile, loading, permissions, selloff, productFmilies, productCategories },
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
