import { useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  Button,
  Text,
  Wrapper,
  Skeleton,
  Breadcrumb,
  MultiSelect,
  Footer
} from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import { Link } from "react-router-dom";
import { useDataState, usePermission } from "hooks";
import { appPermissions } from "constant";
import { NotificationManager } from "common/notifications";

export default function PreparationPhase() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [checkListItems, setCheckListItems] = useState([])
  const [params] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
  });

  //permissions
  const { PS_UpsertPreparationPhase, PS_GetPreparationPhase } = appPermissions;
  const GET_PERMISSION = usePermission(PS_GetPreparationPhase);
  const UPDATE_PERMISSION = usePermission(PS_UpsertPreparationPhase);

  useEffect(() => {
    setCheckListItems([]);
    const url = URLS.GET_CHECK_LIST_URL;
    const config = { params };
    ServiceApi.get(url, config).then(({ data }) => {
      let arr: any = []
      data.items.forEach((e: any) => {
        arr.push({ id: e.checkListItemId, name: e.label })
      })
      setCheckListItems(arr);
    });
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setLoadingPage(true);
    const result = await ServiceApi.get(
      URLS.GET_PREPARATION_PHASE_URL
    ).then(({ data }) => {
      let newData = JSON.parse(JSON.stringify(data))
      let arr: any = [];
      newData?.checkListItemDtos.forEach((i: any) => {
        arr.push({ name: i.label, id: i.checkListItemId })
      })
      newData.checkListItemDtos = arr;
      setBaseData(newData);
      setLoadingPage(false);
    })
  };

  const submit = () => {
    setLoadingButton(true);
    let arr: any = [];
    data.checkListItemDtos.forEach((i: any, index: any) => {
      arr.push(i.id ? i.id : i.checkListItemId)
    })
    data.checkListItemIds = arr;
    delete data.checkListItemDtos
    const url = URLS.PUT_PREPARATION_PHASE_URL;
    ServiceApi.put(url, data).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title"
      );
    }).finally(() => {
      setLoadingButton(false);
      getData();
    });
  };

  return GET_PERMISSION ?
    <Form onSubmit={submit} className="relative space-y-6">
      <h3 className="mr-auto text-heading-3">
        <Breadcrumb />
      </h3>
      <Wrapper>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
          <div className="col-start-1 col-end-2">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="salesManagment.sellOffSales.preparationPhase.name"
                value={data.title}
                setValue={(title) => setData((p: any) => ({ ...p, title }))}
              />
            )}
          </div>
          <div className="col-start-1 col-end-2">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                as="short-textarea"
                label="salesManagment.sellOffSales.preparationPhase.shortDescription"
                value={data.description}
                setValue={(description) => setData((p: any) => ({ ...p, description }))}
              />
            )}
          </div>
          <div className="col-start-1 col-end-2">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <MultiSelect
                label="salesManagment.sellOffSales.preparationPhase.checkListItems"
                items={checkListItems}
                value={data?.checkListItemDtos}
                setValue={(value) => {
                  return setData((p: any) => ({ ...p, ["checkListItemDtos"]: value.length !== 0 ? value?.map((val: any) => val) : [] }));
                }}
              />
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      {UPDATE_PERMISSION ? (
        <Footer show={!isChanged} >
          <Button type="button" onClick={getData} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      ) : null}
    </Form>
    : (
      <>Permission Error</>
    )

}
