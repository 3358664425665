export const stores = {
  storeList: "Filialliste",
  addStore: "Filiale hinzufügen",
  basicInfo: "Grundlegende Informationen",
  basicInfoDesc: "Name, Beschreibung ...",
  location: "Standort",
  locationDesc: "Filialstandort",
  image: "Bild",
  imageDesc: "Bild für die Filiale",
  review: "Überprüfung",
  reviewDesc: "Informationen abschließen",
  general: "Allgemein",
  setting: "Einstellungen",
  locationOnMap: "Standort auf Karte",
  openingHours: "Öffnungszeiten",
  addDay: "Tag hinzufügen",
  generalSetting: "Allgemeine Einstellungen",
  salesSetting: "Verkaufseinstellungen",
  storeName: "Filialname",
  storeCode: "Filialcode",
  legalEntity: "Rechtseinheit",
  shortDescription: "Kurzbeschreibung",
  description: "Beschreibung",
  day: "Tag",
  active: "Aktiv",
  deActive: "Inaktiv",
  iln: "ILN (Internationale Standortnummer)",
  iwofurnSalesCalculationFactor: "iwofurnVerkaufsberechnungsfaktor",
  iwofurnRoundingMethod: "iwofurnRundungsmethode",
  storeAdded: "Filiale erfolgreich hinzugefügt.",
  storeUpdated: "Filiale erfolgreich aktualisiert."
};