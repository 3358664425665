const POSITIONS = {
  GET_POSITIONS: "productservice/api/positions",
  POSITION_DETAILS: (id) => `productservice/api/positions/${id}`,
  REMOVE_POSITION: (id) => `productservice/api/positions/${id}`,
  ADD_NEW_POSITION_URL: "productservice/api/positions",
  ADD_NEW_COMPONENT: (id) => `productservice/api/positions/${id}/component`,
  REMOVE_COMPONENT: (id, componentId) =>
    `productservice/api/positions/${id}/component/${componentId}`,
  ADD_NEW_COMPONENT_ITEM: (id, componentId) =>
    `productservice/api/positions/${id}/component/${componentId}/add-item`,
  UPDATE_COMPONENT_ITEM: (id, componentId, componentItemId) =>
    `productservice/api/positions/${id}/component/${componentId}/update-item/${componentItemId}`,
  REMOVE_COMPONENT_ITEM: (id, componentId, componentItemId) =>
    `productservice/api/positions/${id}/component/${componentId}/remove-item/${componentItemId}`,
};

export default POSITIONS;
