import { useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  Button,
  Text,
  Wrapper,
  Skeleton,
  Breadcrumb,
  Footer,
} from "components";
import { ServiceApi, URLS } from "services";
import { useDataState, usePermission } from "hooks";
import { appPermissions, rules } from "constant";
import { NotificationManager } from "common/notifications";

export default function DataStorage() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);

  //permissions
  const { PS_UpsertBlobConfig, PS_GetBlobConfigDetail } = appPermissions;
  const GET_PERMISSION = usePermission(PS_GetBlobConfigDetail);
  const UPDATE_PERMISSION = usePermission(PS_UpsertBlobConfig);

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.POST_DATA_STORAGE_URL;
    ServiceApi.post(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
      })
      .finally(() => {
        setLoadingButton(false);
        getData();
      });
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setLoadingPage(true);
    await ServiceApi.get(URLS.GET_DATA_STORAGE_URL)
      .then(({ data }) => {
        setBaseData(data);
        setLoadingPage(false);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  return GET_PERMISSION ? (
    <Form onSubmit={submit} className="relative space-y-6">
      <h3 className="mr-auto text-heading-3">
        <Breadcrumb />
      </h3>
      <Wrapper>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
          <div className="space-y-8">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.dataStorage.key"
                value={data.key}
                setValue={(key) => setData((p: any) => ({ ...p, key }))}
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.dataStorage.account"
                value={data.account}
                setValue={(account) => setData((p: any) => ({ ...p, account }))}
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.dataStorage.connectionString"
                value={data.connectionString}
                setValue={(connectionString) =>
                  setData((p: any) => ({ ...p, connectionString }))
                }
                rules={rules.required}
              />
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      {UPDATE_PERMISSION ? (
        <Footer show={!isChanged}>
          <Button type="button" onClick={getData} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      ) : null}
    </Form>
  ) : (
    <>Permission Error</>
  );
}
