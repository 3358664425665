import { convertAddress } from "utils";
import Icon from "./Icon";

type AddressViewerProps = {
  type?: "full" | "short";
  address: {};
};

// type: full | short
export default function AddressViewer({
  type = "full",
  address = {},
}: AddressViewerProps) {
  return (
    <p className="text-gray-500 text-body-2 font-medium">
      <Icon icon="location-dot" size="xl" /> {convertAddress(address)}
    </p>
  );
}
