import { crmConfiguration } from "./crm-configuration";
import { erpConfiguration } from "./erp-configuration";
import { iwofurn } from "./iwofurn-configuration";
import { nopCommerce } from "./nop-commerce";
export const thirdParty = {
    crmConfiguration: crmConfiguration,
    erpConfiguration: erpConfiguration,
    nopCommerce: nopCommerce,
    iwofurn

}