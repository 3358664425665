import { useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  Button,
  Text,
  Wrapper,
  Skeleton,
  Breadcrumb,
  Footer
} from "components";
import { ServiceApi, URLS } from "services";
import { useDataState, usePermission } from "hooks";
import { appPermissions, rules } from "constant";
import { NotificationManager } from "common/notifications";

export default function ErpConfiguration() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataFirst, setDataFirst] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);

  //permissions
  const { CMS_UpdateDynamics365Configuration, CMS_GetDynamics365ConfigurationDetail } = appPermissions;
  const GET_PERMISSION = usePermission(CMS_GetDynamics365ConfigurationDetail);
  const UPDATE_PERMISSION = usePermission(CMS_UpdateDynamics365Configuration);

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.EDI_ERPT_DYNAMICS_365;
    data["inboundQueue"] = data.inboundQueueName;
    data["outboundQueue"] = data.outboundQueueName;
    if (dataFirst) {
      ServiceApi.put(url, data).then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
      }).finally(() => {
        setLoadingButton(false);
        getData();
      });
    } else {
      ServiceApi.post(url, data).then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
      }).finally(() => {
        setLoadingButton(false);
        getData();
      });
    }

  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setLoadingPage(true);
    await ServiceApi.get(
      URLS.GET_ERP_DYNAMICS_365
    ).then(({ data }) => {
      setBaseData(data);
      if (data.length === 0) {
        setDataFirst(false)
      } else {
        setDataFirst(true)
      }
      setLoadingPage(false);
    })
  };

  return GET_PERMISSION ?
    <Form onSubmit={submit} className="relative space-y-6">
      <h3 className="mr-auto text-heading-3">
        <Breadcrumb />
      </h3>
      <Wrapper>
        <Wrapper.Header>
          <div className="flex">
            <div className="mr-auto">
              <h6 className="text-heading-2 text-gray-800 font-semibold">
                <Text>thirdParty.erpConfiguration.dynamics365</Text>
              </h6>
            </div>
          </div>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-8">
          <div className="space-y-8">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.name"
                value={data.name}
                setValue={(name) => setData((p: any) => ({ ...p, name }))}
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.TextArea />
            ) : (
              <InputGroup
                as="short-textarea"
                label="thirdParty.erpConfiguration.inboundConnectionString"
                value={data.inboundConnectionString}
                setValue={(inboundConnectionString) => setData((p: any) => ({ ...p, inboundConnectionString }))}
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.TextArea />
            ) : (
              <InputGroup
                as="short-textarea"
                label="thirdParty.erpConfiguration.outboundConnectionString"
                value={data.outboundConnectionString}
                setValue={(outboundConnectionString) => setData((p: any) => ({ ...p, outboundConnectionString }))}
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.inboundQueueName"
                value={data.inboundQueueName}
                setValue={(inboundQueueName) => setData((p: any) => ({ ...p, inboundQueueName }))}
                rules={rules.required}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="thirdParty.erpConfiguration.outboundQueueName"
                value={data.outboundQueueName}
                setValue={(outboundQueueName) => setData((p: any) => ({ ...p, outboundQueueName }))}
                rules={rules.required}
              />
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      {UPDATE_PERMISSION ? (
        <Footer show={!isChanged} >
          <Button type="button" onClick={getData} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      ) : null}
    </Form>
    : (
      <>Permission Error</>
    )

}
