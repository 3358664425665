export const employees = {
    general: "General",
    technicalInfo: "Technical Info",
    employeeNumber: "Employee Number",
    salesPersonNumber: "Sales Person Number",
    creationDate: "Creation Date",
    displayName: "Display Name",
    firstName: "First Name",
    lastName: "Last Name",
    nickName: "Nick Name",
    basicInfo: "Basic Info",
    employmentInfo: "Employment Info",
    contactInfo: "Contact Info",
    jobTitle: "Job Title",
    store: "Store",
    departments: "Departments",
    mobilePhone: "Mobile Phone",
    businessPhone: "Business Phone",
    faxNumber: "Fax Number",
    emailAddress: "Email Address",
    address: "Address",
    userPrincipalName: "User Principal Name",
    userObjectId: "User ObjectId",
    filters:"Filters",
    resetFilters:"Reset Filters",
    from:"From",
    to:"To",
    keyword:"Keyword"

}