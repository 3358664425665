import { Button, Form, Icon, InputGroup, Modal, Text } from "components";
import { rules } from "constant";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ServiceApi } from "services";
import { toggleProps } from "types";
type dataType = {
  title: string;
  description: string;
  code: string;
  isActive: boolean;
};
export default function AddInfoPage({ isOpen, toggle }: toggleProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<dataType>({
    title: "",
    code: "",
    description: "",
    isActive: true,
  });
  const handleSetValue = (key: keyof dataType) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const submit = () => {
    setLoading(true);
    const url = "/productservice/api/infopages";
    const body = { ...data };
    ServiceApi.post(url, body)
      .then(({ data: id }) => {
        navigate(id);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={submit}>
        <Modal.Header>
          <h4 className="text-start text-gray-800">
            <Text>applications.infoPage.addInfoPageTitle</Text>
          </h4>
        </Modal.Header>
        <Modal.Body className="space-y-8">
          <InputGroup
            label="applications.infoPage.infoPageTitle"
            value={data.title}
            setValue={handleSetValue("title")}
            rules={rules.required}
          />
          <InputGroup
            label="applications.infoPage.infoPageCode"
            value={data.code}
            setValue={handleSetValue("code")}
            rules={rules.required}
          />
          <InputGroup
            as="short-textarea"
            label="applications.infoPage.infoPageDescription"
            value={data.description}
            setValue={handleSetValue("description")}
            rules={rules.required}
          />
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" variant="white" size="sm" onClick={toggle}>
            <Icon icon="close" />{" "}
            <Text>applications.infoPage.infoPageCloseButton</Text>
          </Button>
          <Button type="submit" variant="primary" size="sm" loading={loading}>
            <Text>applications.infoPage.infoPageSubmitButton</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
