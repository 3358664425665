import { saleProccessContext } from ".";
import { useContext } from "react";
import { Wrapper, Text } from "components";
import { checkPriceAvailability } from "utils";
import { useConverter } from "hooks";

const PriceTemplate = () => {
  const { convertAmount } = useConverter();
  const { data } = useContext(saleProccessContext);
  return (
    <Wrapper>
      <Wrapper.Header>
        <h2>
          <Text>salesManagment.sellOffSales.sellOffProduct.price</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body>
        <div className="space-y-4 text-heading-5 font-semibold">
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>
                salesManagment.sellOffSales.sellOffProduct.supplierPrice
              </Text>{" "}
              :
            </p>
            <p className="text-heading-4 text-gray-600">
              {/* {checkPriceAvailability(data?.supplierPrice)} */}
              {data?.supplierPrice < 0 ? "--" : convertAmount(data?.supplierPrice)}
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>
                salesManagment.sellOffSales.sellOffProduct.regularPrice
              </Text>
              :
            </p>
            <p className="text-heading-5 text-gray-600">
              {/* {checkPriceAvailability(data?.originalPrice)} */}
              {data?.originalPrice < 0 ? "--" : convertAmount(data?.originalPrice)}
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>salesManagment.sellOffSales.sellOffProduct.discount</Text>:
            </p>
            <p className="text-danger text-heading-5">
              {data?.currentDiscount}%
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>salesManagment.sellOffSales.sellOffProduct.phasePrice</Text>
              :
            </p>
            <p className="text-primary-active text-heading-2">
              {/* {checkPriceAvailability(data?.currentPrice)} */}
              {data?.currentPrice < 0 ? "--" : convertAmount(data?.currentPrice)}
            </p>
          </div>
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
};

export default PriceTemplate;
