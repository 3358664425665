export const positions = {
  // Position list
  addPositionButton: "Position hinzufügen",
  positionActiveStatus: "Aktiv",
  positionInactiveStatus: "Inaktiv",
  positionEditToast: "Position erfolgreich bearbeitet.",
  positionRemoveToast: "Position erfolgreich entfernt.",
  addComponentButton: "Komponente hinzufügen",
  // Position Form
  positionAddFormHeader: "Position hinzufügen",
  positionEditFormHeader: "Position bearbeiten",
  positionFormName: "Positionsnamen",
  positionFormCode: "Code",
  positionFormDescription: "Beschreibung",
  positionFormComponentLayout: "Komponentenlayout der Position",
  positionFormCloseButton: "Schließen",
  positionFormSubmitButton: "Senden",
  positionAddFormToast: "Position erfolgreich hinzugefügt.",
  positionEditFormToast: "Position erfolgreich bearbeitet.",
  // Add component
  addComponentHeader: "Komponente hinzufügen",
  addComponentCloseButton: "Schließen",
  addComponentTitle: "Titel",
  addComponentCancelButton: "Abbrechen",
  addComponentSubmitButton: "Komponente hinzufügen",
  componentDetailsButton: "Details",
  removeComponent: "Komponente erfolgreich entfernt.",
  // Carousel form
  carouselAddToast: "Element erfolgreich hinzugefügt.",
  carouselEditToast: "Element erfolgreich bearbeitet.",
  carouselRemoveToast: "Element erfolgreich entfernt.",
  carouselSubtitle: "Untertitel",
  carouselTitle: "Titel",
  carouselLinkAssigned: "Link dem Element zugeordnet",
  carouselLink: "Link",
  addItemButton: "Element hinzufügen",
  carouselCancelButton: "Abbrechen",
  carouselSubmitButton: "Senden",
  // Banner form
  editBannerToast: "Banner erfolgreich bearbeitet.",
  bannerSubtitle: "Untertitel",
  bannerTitle: "Titel",
  bannerLinkAssigned: "Link dem Element zugeordnet",
  bannerLink: "Link",
  bannerCancelButton: "Abbrechen",
  bannerSubmitButton: "Senden",
};