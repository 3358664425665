export const configuration = {
  sellFactorTab: "SELL FACTOR",
  toleranceTab: "TOLERANCE",
  sellFactorField: "Sell factor",
  minimumToleranceField: "Minimum tolerance",
  maximumToleranceField: "Maximum tolerance",
  submitSellFactor: "Update",
  submitTolerance: "Update",
  updateSuccessSellFactor: "Sell factor updated successfully.",
  updateSuccessTolerance: "Tolerance updated successfully.",
};
