import { createSlice } from "@reduxjs/toolkit";

const productCategories = createSlice({
    name: "productCategories",
    initialState: {} as any,
    reducers: {
        setproductCategories: (state, action) => {
            return action.payload;
        },
    },
});

export const { setproductCategories } = productCategories.actions;
export default productCategories.reducer;
