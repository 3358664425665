import { useRef, useState, useEffect } from "react";
import Button from "./Button";
import Text from "./Text";
import Icon from "./Icon";
import { variantType } from "types";
// import { IconName } from "@fortawesome/fontawesome-svg-core";

type DropdownButtonItems = {
  id: string;
  title: string;
  permission?: boolean;
  onClick?: any;
};

type DropdownButtonProps = {
  children?: any;
  content?: string;
  items?: DropdownButtonItems[];
  drop?: string;
  onSelect?: (val: any) => void;
  onClick?: (val: any) => void;
  eventKey?: any;
  isActive?: boolean;
  className?: string;
  iconOnly?: boolean;
  icon?: string;
  variant?: variantType;
};

export default function DropdownButton({
  children,
  items,
  variant = "primary",
  drop = "left",
  isActive = false,
  onSelect = () => {},
  eventKey = "",
  onClick = () => {},
  className,
  iconOnly = false,
  content,
  ...props
}: DropdownButtonProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((p) => !p);
  };
  const handleClick = (e: MouseEvent) => {
    onSelect(eventKey);
    toggleShow();
    onClick(e);
  };

  const handleClickSelect = (e: MouseEvent) => {
    const outside = !ref.current?.contains(e.target as Node);
    outside && setShow(false);
  };
  useEffect(() => {
    window.addEventListener("click", handleClickSelect);
    return () => {
      window.removeEventListener("click", handleClickSelect);
    };
  }, []);

  return (
    <div
      ref={ref}
      data-active={show}
      className={`dropdown inline-block relative group ${className}`}
    >
      <Button
        type="button"
        data-active={isActive}
        variant={variant}
        className={`dropdown-item block rounded text-left btn btn-light btn-sm`}
        onClick={handleClick}
        {...props}
      >
        {iconOnly && props.icon ? <Icon icon={props.icon} /> : children}
        {!iconOnly ? (
          content ? (
            content
          ) : (
            <Text>global.buttons.actions</Text>
          )
        ) : null}
        {!iconOnly ? (
          show ? (
            <i className="bi bi-chevron-up text-primary ml-2" />
          ) : (
            <i className="bi bi-chevron-down text-primary ml-2" />
          )
        ) : null}
      </Button>
      <div
        style={{ transformOrigin: `top ${drop}`, zIndex: "1" }}
        className={`${
          iconOnly ? "right-1/2 mt-1" : "left-0"
        } dropdown-menu text-left verflow-y-hidden text-xs bg-white absolute w-[115px]  rounded shadow-sm border border-gray-200 top-full  transition-[transform,opacity] pointer-events-none opacity-0 scale-90 group-data-active:pointer-events-auto group-data-active:opacity-100 group-data-active:scale-100 p-1 space-y-1`}
      >
        {items?.map((item) => {
          return (
            item.permission && (
              <div
                className="mb-2 cursor-pointer hover:bg-primary-light hover:text-primary rounded p-2"
                id={item.id}
                onClick={() => {
                  item.onClick();
                  setShow(false);
                }}
              >
                <Text>{item.title}</Text>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
