import { useState, useEffect, useRef, FormEvent } from "react";
import {
  Form,
  Button,
  Text,
  Icon,
  DatePicker,
  Select,
  Drawer,
  InputGroup,
} from "components";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";
import { config, rules } from "constant";
import { useNavigate } from "react-router-dom";

export default function Invite(props: any) {
  const [loadingList, setLoadingList] = useState(true);
  const [data, setData] = useState<any>({});
  const [dataUserGroup, setDataUserGroup] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const navigate = useNavigate();
  const [params] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });
  const formRef = useRef<any>();

  const getData = () => {
    setLoadingList(true);
    const urluser = URLS.GET_INVITE_AD_USERS(props.selected);
    ServiceApi.get(urluser)
      .then(({ data }) => {
        setData({
          dateOfBirth: data.birthday,
          emailAddress: data.mail,
          firstName: data.givenName,
          idPUserObjectId: data.id,
          lastName: data.surname,
          userGroupId: "",
        });
      })
      .finally(() => {
        setLoadingList(false);
      });
    const url = URLS.USER_GROUP_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config)
      .then(({ data }) => {
        setDataUserGroup(data.items);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  useEffect(() => {
    if (props.isOpen) {
      getData();
    }
  }, [props.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };

  const onSubmitForm = (formData: any) => {
    setLoadingButton(true);
    const url = URLS.POST_INVITE_USER;
    ServiceApi.post(url, formData)
      .then(() => {
        NotificationManager.success(
          "global.toast.invite-msg",
          "global.toast.invite-title"
        );
        props.toggle();
        navigate(-1);
      })
      .catch(() => setLoadingButton(false));
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <h2 className="text-gray-800 text-heading-2">
              <Text>configurations.userManagment.userList.inviteUser</Text>
            </h2>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                size="sm"
                className="ml-2"
                onClick={() => props.toggle()}
              >
                <Icon className="mr-2" icon={"times"} />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4">
          <Form
            ref={formRef}
            className="space-y-4 pr-4"
            onSubmit={onSubmitForm}
            id={"invite-drawer"}
          >
            <InputGroup
              label="configurations.userManagment.userList.emailAddress"
              disabled
              value={data.emailAddress}
            />
            <InputGroup
              label="configurations.userManagment.userList.firstName"
              value={data.firstName}
              setValue={handleSetData("firstName")}
              rules={rules.required}
            />
            <InputGroup
              label="configurations.userManagment.userList.lastName"
              value={data.lastName}
              setValue={handleSetData("lastName")}
              rules={rules.required}
            />
            <DatePicker
              label="configurations.userManagment.userList.dateOfBirth"
              showYearDropdown
              showMonthDropdown
              value={data?.dateOfBirth}
              onChange={handleSetData("dateOfBirth")}
              rules={rules.required}
            />
            <Select
              label="configurations.userManagment.userList.gender"
              items={[
                { id: 0, name: "Rather not say" },
                { id: 1, name: "Male" },
                { id: 2, name: "Female" },
              ]}
              value={data.gender}
              setValue={handleSetData("gender")}
            />
            <Select
              label="configurations.userManagment.userList.usersGroup"
              items={dataUserGroup}
              value={data.userGroupId}
              setValue={(e) => {
                dataUserGroup?.forEach((i: any) => {
                  if (i.id === e) {
                    setData((p: any) => ({ ...p, userGroupId: i.id }));
                  }
                });
              }}
              rules={rules.required}
            />
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button
            type="button"
            className="mr-2"
            onClick={() => props.toggle()}
            variant="light"
          >
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button type="button" onClick={submit} loading={loadingButton}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
