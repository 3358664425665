import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
} from "components";
import { appPermissions, config } from "constant";
import { useConverter, usePermission, useToggleState } from "hooks";
import { createContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import { HandleParamsUrl } from "utils";
import CreateDrawer from "./all-products-drawers/CreateDrawer";
import FilterDrawer from "./all-products-drawers/FilterDrawer";


export const ProductsContext = createContext<any>({ id: "" });

export default function AllProducts() {
  const [data, setData] = useState<any>();
  const [loadingList, setLoadingList] = useState(true);
  const [filterModal, setFilterModal] = useToggleState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData = {
    productId: null,
    categoryId: null,
    keyword: "",
    language: null,
    pageNumber: "1",
    pageSize: config.pageSize,
    pimProductFamilyId: null,
    priceFrom: null,
    priceTo: null,
    programId: null,
    searchFields: "",
    sortType: null,
    supplierId: null,
    brandId: null,
    title: null,
    version: null
  };
  const [selectedID, setSelectedID] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isOpenModalCreate, toggleModalCreate] = useToggleState(false);
  const { convertDate, convertAmount } = useConverter();
  const [params, setParams] = useSearchParams();

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.POST_SEARCH_LIST_PRODUCTS;
    const config = params;
    setLoadingList(true);
    ServiceApi.post(url, config)
      .then(({ data }) => {
        let newData = data;
        newData?.searchResult?.forEach((item: any) => {
          newData?.pimProductFamily?.forEach((productFamily: any) => {
            if (productFamily.value === item.pimProductFamilyId) {
              item["productFamily"] = productFamily.title
            }
          })
          newData?.brand?.forEach((brand: any) => {
            if (brand.value === item.brandId) {
              item["brandName"] = brand.title;
            }
          });
          newData?.supplier?.forEach((supplier: any) => {
            if (supplier.value === item.supplierId) {
              item["supplierName"] = supplier.title;
            }
          });

        });

        setData(newData);
        setKeyword(params.searchFields)
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetFilter = () => {
    setParams({})
    setKeyword("");
  };

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    }
  };

  //permissions
  const { PS_GetPimProductsList, PS_GetPimProduct, PS_CreatePimProduct } =
    appPermissions;
  const GET_LIST_PERMISSION = usePermission(PS_GetPimProductsList);
  const CREATE_PERMISSION = usePermission(PS_CreatePimProduct);
  const GET_DETAIL_PERMISSION = usePermission(PS_GetPimProduct);

  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        {loadingList ? (
          <Skeleton.Button />
        ) : CREATE_PERMISSION ? (
          <Button type="button" onClick={toggleModalCreate}>
            <Icon className="mr-2" icon={"plus"} />
            <Text>productManagement.products.allProducts.addProduct</Text>
          </Button>
        ) : null}
      </div>
      <SearchBox
        value={keyword}
        onSubmit={handleSetFilterData("searchFields")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onFilter={() => setFilterModal()}
        resetFilter={searchParams?.size === 0 ? undefined : resetFilter}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.searchResult && data?.searchResult?.length !== 0 ? (
            <>
              <Table>
                {data?.searchResult?.map((item: any) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Link
                          to={GET_DETAIL_PERMISSION ? item.id : ""}
                          className="w-fit flex gap-2"
                        >
                          <Table.Image
                            className="w-unset"
                            src={item?.avatarThumbnailUrl}
                            alt={item.sku}
                          />
                          <div className="space-y-2 py-2 w-[21rem]">
                            <h6 className="text-heading-6 font-semibold text-gray-800">
                              {item.sku}
                            </h6>
                            <div className="text-body-2 text-gray-500 font-medium">
                              <Text>
                                productManagement.products.allProducts.article
                              </Text>
                              <span className="ml-1">
                                #{item.articleNumber}
                              </span>
                            </div>
                            <div className="flex items-center space-x-4 text-gray-700 text-body-2 font-medium">
                              {item.supplierName && (
                                <div>
                                  <span>{item.supplierName}</span>
                                </div>
                              )}
                              {item.brandName && (
                                <div>
                                  <span>{item.brandName}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="text-body-2 font-medium text-gray-600 text-left space-y-1">
                        <div>
                          <Text>
                            productManagement.products.allProducts.productFamily
                          </Text>
                          :<span className="ml-1">{item.productFamily}</span>
                        </div>
                        <div>
                          <Text>
                            productManagement.products.allProducts.created
                          </Text>
                          :
                          <span className="ml-1">
                            {convertDate(item.creationAt)}
                          </span>
                          <span className="ml-1">{item.creatorName}</span>
                        </div>
                        <div>
                          <Text>
                            productManagement.products.allProducts.modified
                          </Text>
                          :
                          <span className="ml-1">
                            {convertDate(item.lastModifiedAt)}
                          </span>
                          <span className="ml-1">{item.lastModifierName}</span>
                        </div>
                      </td>
                      <td className="space-y-4">
                        <Status.productActive id={item.active} />
                        <div>
                          <h6 className="text-gray-700 text-heading-6 font-normal">{convertAmount(item?.price?.amount)}</h6>
                        </div>
                      </td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button
                            as={Link}
                            to={item.id}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetFilterData("pageNumber")}
                onPageSizeChange={handleSetFilterData("pageSize")}
              />
            </>
          ) : (
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          )}
        </>
      )}
      <ProductsContext.Provider value={{ id: selectedID }}>
        <FilterDrawer
          isOpen={filterModal}
          data={data}
          toggle={setFilterModal}
          onChange={handleSetFilterData}
          resetFilter={resetFilter}
          keyword={keyword}
          setKeyword={setKeyword}
          setParams={setParams}
          params={params}
        />
        <CreateDrawer isOpen={isOpenModalCreate} toggle={toggleModalCreate} getData={getData} />
      </ProductsContext.Provider>
    </div>
  ) : (
    <>Permission Error</>
  );
}
