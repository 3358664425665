import { useContext, Fragment } from "react";
import { Text, Wrapper, Skeleton } from "components";
import { useConverter } from "hooks";
import { CustomerContext } from "..";

export default function TechnicalInfo() {
  const { userData, loading } = useContext<any>(CustomerContext);
  const { convertDate } = useConverter();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-1">
      <Wrapper.Section className="space-y-4">
        <h3 className="text-heading-3 text-gray-600 font-semibold mb-2">
          <Text>company.employees.technicalInfo</Text>
        </h3>
        {loading ? (
          <Fragment>
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
            <Skeleton.Input />
          </Fragment>
        ) : (
          <Fragment>
            <div className="grid grid-cols-3 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.employees.userPrincipalName</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.userPrincipalName}
              </h5>
            </div>
            <div className="grid grid-cols-3 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.employees.userObjectId</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {userData?.userObjectId}
              </h5>
            </div>
            <div className="grid grid-cols-3 gap-8">
              <span className="text-body-base text-gray-800 font-medium">
                <Text>company.employees.creationDate</Text>
              </span>
              <h5 className="text-heading-5 text-gray-700 font-normal">
                {convertDate(userData?.userCreatedDateTime)}
              </h5>
            </div>
          </Fragment>
        )}
      </Wrapper.Section>
    </div>
  );
}
