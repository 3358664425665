const SELL_OF_PRODUCT = {
  GET_SELL_OFF_PRODUCT_LIST: "productservice/api/selloffproducts",

  GET_SELL_PHASE_TEMPLATES:
    "/productservice/api/sellphasetemplate?pageNumber=1&pageSize=100",

  PUT_SELL_PHASE_TEMPLATES: (id) =>
    `/productservice/api/selloffproducts/${id}/sell-phase-template`,

  REORDER_ASSETS_URL: (id) =>
    `productservice/api/selloffproducts/${id}/reorder-assets`,

  GET_PUT_PRODUCT_DETAILS_URL: (id) =>
    `productservice/api/selloffproducts/${id}`,

  GET_SUBPRODUCT_TIMELINE_URL: (id) =>
    `productservice/api/selloffproducttimelines/${id}`,

  ADD_SUB_PRODUCT_URL: (id) =>
    `/productservice/api/selloffproducts/${id}/sub-item`,

  PUT_SUB_PRODUCT_URL: (id, subItemId) =>
    `/productservice/api/selloffproducts/${id}/sub-item/${subItemId}`,

  DELETE_SUB_PRODUCT_URL: (id, subItemId) =>
    `/productservice/api/selloffproducts/${id}/sub-item/${subItemId}`,
  DELETE_PRODUCT_URL: (id) => `/productservice/api/selloffproducts/${id}`,

  PREPARATION_PHASE_CHECKLIST_URL: (id) =>
    `productservice/api/selloffproducts/${id}/preparationphase-checklistitem`,

  CHANGE_PHASE_CHECKLIST_URL: (id) =>
    `productservice/api/selloffproducts/${id}/sellphase-checklistitem`,

  PREPARATION_PHASE_START_REQUIREMENT_URL: (id) =>
    `productservice/api/selloffproducts/${id}/start-phase-requirement`,

  CHANGE_PHASE__REQUIREMENT_URL: (id) =>
    `productservice/api/selloffproducts/${id}/change-phase-requirement`,

  SOLD_URL: (id) => `productservice/api/selloffproducts/${id}/sold`,
  WITHDRAW_URL: (id) =>
    `productservice/api/selloffproducts/${id}/withdraw-sold-item`,

  START_PHASE_URL: (id) =>
    `productservice/api/selloffproducts/${id}/start-phase`,

  CLOSE_PHASE_URL: (id) =>
    `productservice/api/selloffproducts/${id}/close-phase`,

  CHANGE_PHASE_URL: (id) =>
    `productservice/api/selloffproducts/${id}/change-phase`,

  GET_DETAILS_BASIC_INFO: (id) =>
    `productservice/api/selloffproducts/${id}/basic-info`,

  GET_DETAILS_STATUS_INFO: (id) =>
    `productservice/api/selloffproducts/${id}/status-info`,

  UPDATE_DELETE_ASSETS: (id) =>
    `productservice/api/selloffproducts/${id}/assets`,

  GET_SELL_OFF_STORES: `accountservice/api/stores?pageNumber=1&pageSize=100`,
  GET_SELL_OFF_PRODUCT_FAMILIES: `productservice/api/productfamilies`,
  GET_SELL_OFF_PRODUCT_FAMILIES_Details: (id)=>`productservice/api/productfamilies/${id}`,
  GET_SELL_OFF_BRANDS: `productservice/api/brands?pageNumber=1&pageSize=100`,
  GET_SELL_OFF_SUPPLIERS: `productservice/api/suppliers?pageNumber=1&pageSize=100`,
  GET_SELL_OFF_STORES: `accountservice/api/stores?pageNumber=1&pageSize=100`,

  // ------------- sell off products--------------------
  SELL_OFF_PRODUCTS_URLS: "productservice/api/selloffproducts",
  SELL_OFF_PRODUCTS_TEMPLATE_URL: (id) =>
    `productservice/api/selloffproducts/${id}/sell-phase-template`,
  SELL_OFF_PRODUCTS_PRICE_URL: (id) =>
    `productservice/api/selloffproducts/${id}/price`,
  SELL_OFF_SUB_PRODUCTS_PRICE_URL: (id, subId) =>
    `productservice/api/selloffproducts/${id}/sub-item-price/${subId}`,
  PRODUCT_TIMELINE_URL: "/productservice/api/selloffproducttimelines",

  GET_CHANGE_PHASE_NEEDED_PRODUCTS_URL:
    "productservice/api/selloffproducts/need-phase-change",
  GET_PRODUCT_HISTORY_URL: "productservice/api/audits?aggregateId:",
  GET_PRODUCT_HISTORY_URL_ACCOUNT: "accountservice/api/audits?aggregateId=",

  GET_LATEST_SOLD_ITEMS_URL:
    "/productservice/api/selloffproducts/latest-sold?number=",
  // SEARCH_PRODUCTS_URL:
  //   "/productservice/api/selloffproducts/search-sell-off-product",
  SEARCH_PRODUCTS_URL: "/productservice/api/selloffproducts/search-sell-off",
  SEARCH_SOLD_PRODUCTS_URL: "/productservice/api/selloffproducts/search-sold",
  WITHDRAW_SOLD_PRODUCT: (id) =>
    `productservice/api/selloffproducts/${id}/withdraw-sold-item`,
  SEARCH_SELL_OFF_COMISSION:
    "/productservice/api/selloffcommissions/search-sell-off-commission",
  SEARCH_OWN_SELL_OFF_COMISSION:
    "/productservice/api/selloffcommissions/search-own-sell-off-commission",
  DOWNLOAD_SELL_OFF_COMISSION:
    "/productservice/api/selloffcommissions/download-sell-off-commission",
  UPDATE_PROVISION: (id) =>
    `productservice/api/selloffproducts/${id}/fast-sales-provision-amount`,
};

export default SELL_OF_PRODUCT;
