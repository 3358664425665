const USERS = {
  B2B_USER_URL: "/accountservice/api/users/b2b-user-profile",
  GET_B2B_USERS_URL: "accountservice/api/users/b2b-users",
  ADD_B2B_USERS_URL: "accountservice/api/users/b2b-user",
  B2B_USER_DETAIL_URL: (id) => `/accountservice/api/users/${id}%E2%80%8B/b2b-user`,
  UPDATE_B2B_USERS_URL: (id) => `/accountservice/api/users/${id}`,
  UPDATE_B2B_USER: "accountservice/api/users/user-profile",
  UPDATE_PROVIDERS_USER_URL: (id) => `/accountservice/api/users/${id}/update-providers`,
  UPDATE_SET_DEFAULT_FILTER_USER_URL: (id) => `/accountservice/api/users/${id}/set-default-filter`,
  UPLOAD_USER_IMAGES: (id) => `/accountservice/api/users/${id}/image`,
  SEARCH_AD_USERS: (keyword)=>`/accountservice/api/users/user-ad?keyword=${keyword}`,
  GET_INVITE_AD_USERS:(id)=>`accountservice/api/users/user-ad-detail?userAdObjectId=${id}`,
  POST_INVITE_USER: "/accountservice/api/users/invite-b2b-user-idp",

  

  B2B_ADMIN_URL: "/accountservice/api/users/b2b-admin",
  EDIT_B2B_ADMIN_URL: (id) => `/accountservice/api/users/${id}/b2b-admin`,
  SET_DEFAULT_FILTER_USER_URL: (id) =>
    `accountservice/api/users/${id}/set-default-filter`,
  EDIT_B2B_USER_URL: "/accountservice/api/users/",
  //  ADD_IMAGE_TO_USER_URL : '/accountservice/api/users/image',
  ADD_IMAGE_TO_USER_URL: "/accountservice/api/users",
  UPDATE_MY_PROFILE_URL: "/accountservice/api/users/user-profile",
  GET_MY_PROFILE_URL: "/accountservice/api/users/b2b-user-profile-detail",
  ADD_IMAGE_TO_MY_PROFILE_URL: "/accountservice/api/users/image-profile",

  B2B_ADMIN_UPDATE_URL: "/accountservice/api/users/",
  GET_MY_PROFILE_B2B_ADMIN_URL: "/accountservice/api/users/b2b-user-profile",
  GET_AD_USER_DETAIL: "/accountservice/api/users/user-ad-detail",
};

export default USERS;
