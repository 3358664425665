import { useContext, useEffect, useState } from "react";

import { Wrapper, Skeleton, Select, Button, Text } from "components";
import { ServiceApi, URLS } from "services";
import { useToggleState } from "hooks";
import { SellOffProductDetailsContext } from "..";
import { NotificationManager } from "common/notifications";

const PreparationSalesTemplate = ({ data }: any) => {
  const { getData } = useContext(SellOffProductDetailsContext);
  const [loading, toggle] = useToggleState();
  const [submitLoading, submitToggle] = useToggleState();
  const [items, setItems] = useState([]);
  const [selectedPhaseId, setSelectedPhaseId] = useState("");

  useEffect(() => {
    toggle();
    const url = URLS.GET_SELL_PHASE_TEMPLATES;
    ServiceApi.get(url)
      .then(({ data }) => {
        const items = data.items.map((item: any) => ({
          id: item.sellPhaseTemplateId,
          name: item.title,
        }));
        setItems(items);
        toggle();
      })
      .catch(() => toggle());
  }, []);

  const submitHandler = () => {
    submitToggle();
    const url = URLS.PUT_SELL_PHASE_TEMPLATES(data.sellOffProductId);
    ServiceApi.patch(url, {
      sellPhaseTemplateId: selectedPhaseId || data.sellPhaseTemplateId,
    })
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
        getData();
        submitToggle();
      })
      .catch(() => submitToggle());
  };

  return (
    <Wrapper className="col-span-3">
      <Wrapper.Header>
        <Text>salesManagment.sellOffSales.sellOffProduct.salesTemplate</Text>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-2 space-y-4">
        {loading ? (
          <div className="flex-col space-y-4">
            <Skeleton.Input />
            <Skeleton.Button />
          </div>
        ) : (
          <div className="flex-col space-y-4">
            <Select
              value={selectedPhaseId || data.sellPhaseTemplateId}
              items={items}
              setValue={(val) => setSelectedPhaseId(val)}
            />
            <Button onClick={submitHandler} loading={submitLoading} size="sm">
              <Text>salesManagment.sellOffSales.sellOffProduct.submit</Text>
            </Button>
          </div>
        )}
      </Wrapper.Body>
    </Wrapper>
  );
};

export default PreparationSalesTemplate;
