
import {
  Text
} from "components";
import { config } from "constant";
import { useContext, useEffect, useState } from "react";
import { AttributeContext } from "..";

export default function AttributeType() {
  const { setData } = useContext<any>(AttributeContext);
  const [selected, setSelected] = useState(0)

  useEffect(() => {
    setSelected(10)
    setData((p: any) => ({ ...p, type: 10 }));
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="space-y-8">
      <div className="flex items-center">
        <h2 className="text-gray-800" ><Text>productManagement.masterData.attributes.chooseAttributeType</Text></h2>
      </div>
      <div className="space-y-4">
        <div className="grid grid-cols-4 gap-4">
          {config?.attributeTypesOptions?.map((item: any) => {
            return <div key={item.type}
              className={`w-full h-[110px] shadow-[0_4px_20px_rgba(56,71,109,0.09)] rounded-xl text-center cursor-pointer ${selected === item.type ? "bg-primary-light" : ""}`} >
              <div className="flex items-center justify-center w-full h-full" onClick={() => {
                setSelected(item.type)
                setData((p: any) => ({ ...p, type: item.type }));
              }}>
                <div className="text-center space-y-4">
                  <div>
                    <img className="text-primary block ml-auto mr-auto" src={item.icon} alt="" />
                  </div>
                  <div>
                    <h6 className="text-gray-800 font-normal">
                      {item.name}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  );
}
