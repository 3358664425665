// @ts-ignore: Unreachable code error
import takePhotoSound from "assets/mp3/camera.mp3";
import { useToggleState } from "hooks";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { toggleProps } from "types";
import Icon from "./Icon";
import Modal from "./Modal";
type TakePhotoModalProps = toggleProps & {
  onTaken: (image: string) => void;
};
export default function TakePhotoModal({
  isOpen,
  toggle,
  onTaken,
}: TakePhotoModalProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audio = useMemo(() => new Audio(takePhotoSound), []);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [imageSrc, setImageSrc] = useState("");
  const [isSelf, toggleSelf] = useToggleState(false);
  const [error, setError] = useState("");
  const clearPhoto = () => {
    setImageSrc("");
  };
  const handleStream = () => {
    const video = videoRef.current;
    if (!stream) return;
    if (!video) return;
    video.srcObject = stream;
  };
  const onCamera = () => {
    if (imageSrc || !isOpen) return;
    navigator.mediaDevices
      ?.getUserMedia({
        video: { facingMode: isSelf ? "user" : "environment" },
        audio: false,
      })
      .then(setStream)
      .catch(setError);
  };
  const onOffCamera = () => {
    if (isOpen) return;
    const video = videoRef.current;
    if (!video) return;
    video.pause();
    video.src = "";
    video.srcObject = null;
    stream?.getTracks().forEach((track) => {
      track.stop();
    });
  };
  const takePhoto = () => {
    const video = videoRef.current;
    if (!video) return;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const src = canvas.toDataURL("image/jpeg");
    audio.play();
    setImageSrc(src);
  };
  const handleToggle = () => {
    setStream(null);
    setImageSrc("");
    setError("");
    toggle();
  };
  const savePhoto = () => {
    onTaken(imageSrc);
    handleToggle();
  };
  useEffect(onCamera, [isOpen, imageSrc, isSelf]);
  useEffect(onOffCamera, [isOpen, imageSrc, stream]);
  useEffect(handleStream, [stream]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleToggle}
      modalClassName="z-[51]"
      size="xl"
      closeBtn
    >
      <Modal.Header> </Modal.Header>
      <Modal.Body className="flex flex-col lg:flex-row items-center gap-8">
        {imageSrc && (
          <Fragment>
            <div className="relative flex-center w-full lg:w-auto flex-1 aspect-vertical-video lg:aspect-video rounded bg-light">
              <img
                className="absolute inset-0 w-full h-full object-contain rounded bg-light animate-take-photo"
                src={imageSrc}
                alt="taken"
              />
            </div>
          </Fragment>
        )}
        <div
          hidden={!!imageSrc}
          className="relative flex-center w-full lg:w-auto flex-1 aspect-vertical-video lg:aspect-video rounded bg-light"
        >
          <video
            ref={videoRef}
            autoPlay
            className="absolute inset-0 w-full h-full object-contain rounded bg-light"
            hidden={!!error}
          ></video>
          {!!error && (
            <p className="relative p-8 text-center">
              An error occurred: <br />
              <span className="text-danger">{`${error}`}</span>
            </p>
          )}
        </div>
        {!error && (
          <div className="flex-center flex-row-reverse lg:flex-col gap-4">
            {!!imageSrc ? (
              <Fragment>
                <button
                  type="button"
                  className="w-7 h-7 flex-center border-2 border-danger rounded-full text-danger"
                  onClick={clearPhoto}
                >
                  <Icon icon="trash" className="w-4 h-4" />
                </button>
                <button
                  type="button"
                  className="w-9 h-9 flex-center border-2 border-primary rounded-full text-primary"
                  onClick={savePhoto}
                >
                  <Icon icon="check" className="w-4 h-4" />
                </button>
              </Fragment>
            ) : (
              <Fragment>
                <button
                  type="button"
                  className="w-7 h-7 flex-center border-2 border-primary rounded-full text-primary"
                  onClick={toggleSelf}
                >
                  <Icon icon="repeat" className="w-4 h-4" />
                </button>
                <button
                  type="button"
                  className="w-9 h-9 flex-center border-2 border-primary rounded-full text-primary"
                  onClick={takePhoto}
                >
                  <Icon icon="circle" className="w-4 h-4" />
                </button>
              </Fragment>
            )}
            <span className="w-7 h-7"></span>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
