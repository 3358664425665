const STORE = {
  ADD_STORE_URL: "accountservice/api/stores",
  GET_STORE_URL: (id) => `accountservice/api/stores/${id}`,
  UPDATE_STORE_URL: (id) => `accountservice/api/stores/${id}`,
  GET_DELETE_EDIT_STORE_DETAIL_URL: "accountservice/api/stores/",
  SEARCH_STORE_LOCATION: (
    query,
    countrySet,
    language = "",
    radius = "",
    limit = 1,
    lat = "",
    lon = ""
  ) =>
    `addressservice/api/address/search?query=${query}&countrySet=${countrySet}&language=${language}&radius=${radius}&limit=${limit}&lat=${lat}&lon=${lon}`,
};

export default STORE;
