import { NotificationManager } from "common/notifications";
import { Button, Modal, Text } from "components";
import { useToggleState } from "hooks";
import { ServiceApi, URLS } from "services";

type Props = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: CallableFunction;
  id?: string;
};
const ClosePhaseModal = ({ isOpen, toggle, getData, id }: Props) => {
  const [loading, setLoading] = useToggleState();

  const closePhaseHandler = () => {
    if (id) {
      setLoading();
      const url = URLS.CLOSE_PHASE_URL(id);
      ServiceApi.post(url)
        .then(() => {
          NotificationManager.success(
            "global.toast.close-phase-msg",
            "global.toast.close-phase-title"
          );
          setLoading();
          getData();
          toggle();
        })
        .catch(() => {
          setLoading();
        });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="z-40">
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>salesManagment.sellOffSales.sellOffProduct.closePhase</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="max-h-100 overflow-auto space-y-10">
        <div className="flex flex-col space-y-4">
          <span>
            <Text>salesManagment.sellOffSales.sellOffProduct.areUSure</Text>
          </span>
          <span>
            <Text>salesManagment.sellOffSales.sellOffProduct.afterClosing</Text>
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button variant={"light"} size="sm" onClick={toggle}>
          <Text>salesManagment.sellOffSales.sellOffProduct.cancel</Text>
        </Button>
        <Button
          variant={"danger"}
          size="sm"
          loading={loading}
          onClick={closePhaseHandler}
        >
          <Text>salesManagment.sellOffSales.sellOffProduct.closePhase</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClosePhaseModal;
