import { TimeLine, Skeleton } from "components";
import { checkPriceAvailability } from "utils";
import { useConverter } from "hooks";

const SaleProccessTimeline = ({ timeLine }: any) => {
  const { convertDate } = useConverter();
  // useEffect(() => {
  //   if (timeLine.length) {
  //     const slider = document.querySelector(".parent") as HTMLDivElement;
  //     let mouseDown = false;
  //     let startX: any, scrollLeft: any;

  //     let startDragging = function (e: any) {
  //       mouseDown = true;
  //       startX = e.pageX - slider.offsetLeft;
  //       scrollLeft = slider.scrollLeft;
  //       slider.style.cursor = "grabbing";
  //       slider.style.userSelect = "none";
  //     };
  //     let stopDragging = function () {
  //       mouseDown = false;
  //       slider.style.cursor = "grab";
  //     };

  //     slider.addEventListener("mousemove", (e) => {
  //       e.preventDefault();
  //       if (!mouseDown) {
  //         return;
  //       }
  //       const x = e.pageX - slider.offsetLeft;

  //       const scroll = x - startX;
  //       slider.scrollLeft = scrollLeft - scroll;
  //     });

  //     // Add the event listeners
  //     slider?.addEventListener("mousedown", startDragging, false);
  //     slider?.addEventListener("mouseup", stopDragging, false);
  //     slider?.addEventListener("mouseleave", stopDragging, false);

  //     return () => {
  //       slider?.removeEventListener("mousedown", startDragging, false);
  //       slider?.removeEventListener("mouseup", stopDragging, false);
  //       slider?.removeEventListener("mouseleave", stopDragging, false);
  //     };
  //   }
  // }, [timeLine]);

  const checkActionType = (type: number) => {
    switch (type) {
      case 0:
        return "salesManagment.sellOffSales.sellOffProduct.noAction";
      case 1:
        return "salesManagment.sellOffSales.sellOffProduct.createProduct";
      case 2:
        return "salesManagment.sellOffSales.sellOffProduct.startPhase";
      case 3:
        return "salesManagment.sellOffSales.sellOffProduct.changePhase";
      case 4:
        return "salesManagment.sellOffSales.sellOffProduct.sold";
      case 5:
        return "salesManagment.sellOffSales.sellOffProduct.closePhase";
      case 6:
        return "salesManagment.sellOffSales.sellOffProduct.withdraw";
    }
  };

  const timelineBackground = (type: number) => {
    switch (type) {
      case 0:
        return "gray-500";
      case 1:
        return "primary";
      case 2:
        return "success";
      case 3:
        return "warning";
      case 4:
        return "info";
      case 5:
        return "danger";
      case 6:
        return "warning";
    }
  };

  const formattedValue = (t: any) => {
    switch (t.key) {
      case "Duration":
        return t.value + " Days";
      case "Discount":
        return t.value + "%";
      case "OriginalPrice":
      case "SellerCommission":
      case "PriceWithDiscount":
      case "SoldPrice":
      case "OriginalPrice":
      case "RealSoldPrice":
        return checkPriceAvailability(t.value);
      case "ExpectedStartDate":
        return convertDate(t.value);

      default:
        return t.value;
    }
  };

  const createItemsDetails = (item: any, date: any) => {
    return (
      <div className="space-y-2">
        <p className="text-gray-600">
          {date.performedBy
            ? date.performedBy + " at " + convertDate(date.performedAt)
            : convertDate(date.performedAt)}{" "}
        </p>
        {item?.map((t: any) => {
          return (
            <div className="space-x-2">
              <span>{t.key.match(/[A-Z][a-z]+/g).join(" ")}:</span>
              <span>{formattedValue(t)}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const createTimelineItems = (timeline: any) => {
    const items = timeline?.map((item: any) => {
      return {
        title: checkActionType(item.actionType),
        description: item.title,
        label: item.createdItemAt
          ? convertDate(item.createdItemAt).split(",")[0]
          : "",
        color: timelineBackground(item.actionType),
        details: createItemsDetails(item.dynamicFields, {
          performedAt: item.performedAt,
          performedBy: item.performedBy,
        }),
      };
    });
    return items;
  };

  return (
    <>
      {!timeLine.length ? (
        <div className="grid grid-cols-4 gap-4">
          {[1, 2, 3, 4].map((s) => (
            <Skeleton.TextArea key={s} />
          ))}
        </div>
      ) : (
        <TimeLine
          items={createTimelineItems(timeLine).reverse()}
          className="parent overflow-x-auto"
        />
      )}
    </>
  );
};

export default SaleProccessTimeline;
