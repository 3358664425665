import { Breadcrumb, HandleName, Skeleton, Tabs, Text, Wrapper } from "components";
import { useToggleState } from "hooks";
import { createContext, createElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import AttributeGroups from "./AttributeGroups";
import General from "./General";
import SubFamily from "./SubFamily";

export const ProductFamiliesContext = createContext<any>({ id: "" });

const ProductFamilies = () => {
  const { i18n } = useTranslation();
  const [productFamilies, setProductFamilies] = useState([]);
  const [root, setRoot] = useState<any>([]);
  const [subFamily, setSubFamily] = useState([]);
  const [loading, setLoading] = useToggleState();

  const tabs = [
    {
      label: "productManagement.masterData.productFamilies.general",
      component: General,
    },
    {
      label: "productManagement.masterData.productFamilies.subFamily",
      component: SubFamily,
    },
    {
      label: "Attribute Groups",
      component: AttributeGroups,
    },
  ];

  const getSubFamily = (array: any, id: string) => {
    return array.filter((item: any) => item.parentId === id);
  };

  const getProductFamilies = () => {
    setLoading();
    const url = URLS.CREATE_GET_PUT_DELETE_PIM_PRODUCT_FAMILY_URL;
    ServiceApi.get(url)
      .then(({ data }) => {
        setProductFamilies(data);
        const root = data.filter((item: any) => !item.parentId)[0];
        setRoot(root);
        const subFamily = getSubFamily(data, root.pimProductFamilyId);
        setSubFamily(subFamily);
        setLoading();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getProductFamilies();
  }, []);

  return (
    <>
      <Tabs activeKey={tabs[0].label}>
        {/* BreadCrumb */}
        <div className="space-y-4">
          <div className="flex items-center">
            <div className="flex-1">
              <Breadcrumb />
            </div>
          </div>
          {loading && (
            <>
              <Wrapper>
                {/* <Wrapper.Body> */}
                <Skeleton.List />
                {/* </Wrapper.Body> */}
              </Wrapper>
              <Wrapper>
                <Wrapper.Body>
                  <Skeleton.TextArea />
                </Wrapper.Body>
              </Wrapper>
            </>
          )}
          {!loading && (
            <>
              <Wrapper>
                <Wrapper.Body className="flex justify-between items-center">
                  <div className="space-y-4">
                    <span className="text-heading-5 font-semibold text-gray-800">
                      {<HandleName translations={root?.translations} code={root?.code} keyName="name" />}
                    </span>
                    <p className="text-body-2 font-medium">{root.code}</p>
                  </div>
                  {/* <Button
                    variant={"danger"}
                    light
                    size="sm"
                    onClick={() => {
                      console.log(root.pimProductFamilyId);
                    }}
                  >
                    <Icon icon={"trash"} className="mr-2" />
                    Delete
                  </Button> */}
                </Wrapper.Body>
                <Wrapper.Footer className="!py-0">
                  <Tabs.ButtonGroup>
                    {tabs.map((e) => (
                      <Tabs.Button key={e.label} eventKey={e.label}>
                        <Text>{e.label}</Text>
                      </Tabs.Button>
                    ))}
                  </Tabs.ButtonGroup>
                </Wrapper.Footer>
              </Wrapper>
              {tabs.map((e) => (
                <ProductFamiliesContext.Provider
                  value={{
                    root,
                    subFamily,
                    getSubFamily,
                    productFamilies,
                    getProductFamilies,
                  }}
                >
                  <Tabs.Item key={e.label} eventKey={e.label}>
                    {createElement(e.component)}
                  </Tabs.Item>
                </ProductFamiliesContext.Provider>
              ))}
            </>
          )}
        </div>
      </Tabs>
    </>
  );
};

export default ProductFamilies;
