import { useState } from "react";
import {
  Button,
  Drawer,
  Icon,
  ImageUploader,
  Text,
  Image,
  CardDraggable,
} from "components";
import { useToggleState } from "hooks";
import { URLS, ServiceApi } from "services";
import { NotificationManager } from "common/notifications";
import DeleteModal from "./delet-modal";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  data: any;
  onComplete: () => void;
};

const EditImageDrawer = ({ isOpen, toggle, data, onComplete }: drawerProps) => {
  const [isOpenUploader, setUploaderToggle] = useToggleState();
  const [isOpenDelete, setDeleteToggle] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [reorderoading, setReorderLoading] = useToggleState();
  const [selectedId, setSelectedId] = useState("");
  const [assetsOrder, setAssetOrders] = useState<any>([]);

  const deleteAssetsHandler = (id: any) => {
    setDeleteLoading();
    const url = URLS.UPDATE_DELETE_ASSETS(data?.sellOffProductId);
    const ids: any = [id];
    const body = { assetIds: ids };

    ServiceApi.delete(url, { data: body })
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title"
        );
        setDeleteLoading();
        setDeleteToggle();
        onComplete();
      })
      .catch((err) => {
        setDeleteLoading();
        // console.log(err);
      });
  };

  const addAssetsHandler = (image: any) => {
    const url = URLS.UPDATE_DELETE_ASSETS(data?.sellOffProductId);
    const body = {
      assets: [
        {
          id: null,
          url: image.url,
          type: image.type,
          thumbnailUrl: image.thumbnailUrl,
        },
      ],
    };

    ServiceApi.patch(url, body).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title"
      );
      onComplete();
    });
  };

  const reorderHandler = () => {
    setReorderLoading();
    const url = URLS.REORDER_ASSETS_URL(data?.sellOffProductId);
    ServiceApi.patch(url, { assets: assetsOrder })
      .then(() => {
        setReorderLoading();
        onComplete();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title"
        );
      })
      .catch(() => setReorderLoading());
  };
  function sortDataBy() {
    let sortedData;
    sortedData = data?.assets?.sort(function (a: any, b: any) {
      let x = a.order;
      let y = b.order;
      if (x > y) {
        return 1;
      }
      if (x < y) {
        return -1;
      }
      return 0;
    });
    return sortedData;
  }

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pt-6 pr-4">
            <div className="flex items-center justify-between">
              <span className="text-heading-2 font-semibold">
                <Text>salesManagment.sellOffSales.sellOffProduct.gallery</Text>
              </span>
              <div className="flex gap-x-2">
                <Button size="sm" variant={"light"} onClick={toggle}>
                  <Icon icon="times" className="mr-1" />
                  <Text>salesManagment.sellOffSales.sellOffProduct.close</Text>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4 pr-4">
            <label
              data-type="button"
              onClick={setUploaderToggle}
              className="p-4 items-center space-x-4 flex h-20 w-full bg-primary-light border-[0.5px] border-dashed border-primary rounded text-center space-y-2 cursor-pointer"
            >
              <Icon icon="file-arrow-up" size="3x" className="text-primary" />
              <h5 className="text-gray-800">
                <Text>global.uploadFile</Text>
              </h5>
            </label>
            <div className="space-y-5">
              {data?.assets?.length > 0 && (
                <CardDraggable setItems={(arr: any) => setAssetOrders(arr)}>
                  {sortDataBy()?.map((image: any) => (
                    <div
                      className="flex flex-col p-4 w-full rounded-xl"
                      id={image?.id}
                      key={image?.id}
                    >
                      <div className="flex justify-between items-center">
                        <Image
                          src={image?.url}
                          className="h-[75px] w-[136px]"
                        />
                        <Icon
                          icon="circle-trash"
                          size="2x"
                          className="text-danger-active cursor-pointer"
                          onClick={() => {
                            setSelectedId(image.id);
                            setDeleteToggle();
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </CardDraggable>
              )}
            </div>
          </Drawer.Body>
          <Drawer.Footer className="flex justify-end">
            <Button
              size="sm"
              onClick={reorderHandler}
              loading={reorderoading}
              disabled={!data?.assets?.length}
            >
              <Text>salesManagment.sellOffSales.sellOffProduct.reorder</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <ImageUploader
        isOpen={isOpenUploader}
        toggle={setUploaderToggle}
        image={""}
        onUpload={(image) => addAssetsHandler(image)}
      />
      <DeleteModal
        isOpen={isOpenDelete}
        loading={deleteLoading}
        toggle={setDeleteToggle}
        id={selectedId}
        onConfirm={deleteAssetsHandler}
      />
    </>
  );
};

export default EditImageDrawer;
