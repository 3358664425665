import {
  Button,
  Drawer,
  Icon,
  InputGroup,
  Select,
  Text
} from "components";
import { useSearchParams } from "react-router-dom";


type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  resetFilter: () => void;
  onChange: (key: any) => any;
  data: any;
  params: any;
  setParams: (key: any) => void;
  setKeyword: (key: any) => void;
  keyword: any;
};

const FilterDrawer = ({
  isOpen,
  data,
  params,
  toggle,
  onChange,
  resetFilter,
  setParams,
  setKeyword,
  keyword
}: drawerProps) => {

  const [searchParams] = useSearchParams();

  const createItems = (key: string) => {
    const clone = data?.[key]?.map((item: any) => ({
      id: item.value,
      name: item.title + "(" + item.count + ")",
    }));
    clone?.unshift({ id: null, name: "All" });
    return clone;
  };

  // const createStatusItems = () => {
  //   const clone = data?.status?.map((item: any) => ({
  //     id: item.value,
  //     name: item.value + "(" + item.count + ")",
  //   }));
  //   clone?.unshift({ id: null, name: "All" });
  //   return clone;
  // };

  const createProductFamilyItems = () => {
    const clone = data?.pimProductFamily?.map((item: any) => ({
      id: item.value,
      name: item.title + "(" + item.count + ")",
    }));
    clone?.unshift({ id: null, name: "All" });
    return clone;
  };



  const submit = (e: any) => {
    e.preventDefault();
    if (keyword !== "") {
      setParams((prev: any) => {
        prev.set("searchFields", keyword)
        return prev
      })
    } else {
      setParams((prev: any) => {
        prev.delete("searchFields")
        return prev
      })
    }
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pt-6 pr-4">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.allProducts.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {searchParams?.size !== 0 && <Button
                size="sm"
                variant={"danger"}
                light
                onClick={() => resetFilter()}
              >
                <Icon icon="times-circle" className="mr-1" />
                <Text>
                  productManagement.products.allProducts.resetFilters
                </Text>
              </Button>}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>productManagement.products.allProducts.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 px-6 py-8">
          <div className="flex flex-center gap-2">
            <form
              onSubmit={submit}
              className="w-full"
            >
              <InputGroup
                label="productManagement.products.importProduct.keyword"
                value={keyword}
                placeholder="global.placeholders.search"
                setValue={(value) => setKeyword(value)}
              />
            </form>
            <div className="bg-gray-100 flex items-center justify-center rounded-md cursor-pointer h-[46px] w-[46px] p-3 mt-8"
              onClick={() => {
                if (keyword !== "") {
                  setParams((prev: any) => {
                    prev.set("searchFields", keyword)
                    return prev
                  })
                } else {
                  setParams((prev: any) => {
                    prev.delete("searchFields")
                    return prev
                  })
                }
              }}
            >
              <Icon className="h-5 w-5 text-primary !leading-6" icon={"search"} />
            </div>
          </div>
          <Select
            label="productManagement.products.allProducts.productFamily"
            placeholder="Select"
            value={params?.get("pimProductFamilyId")}
            items={createProductFamilyItems()}
            setValue={onChange("pimProductFamilyId")}
          />
          <Select
            label="productManagement.products.allProducts.productCategory"
            placeholder="Select"
            value={params?.get("categoryId")}
            items={createItems("category")}
            setValue={onChange("categoryId")}
          />
          {/* <Select
            label="productManagement.products.allProducts.status"
            placeholder="Select"
            value={filterData.status}
            items={createStatusItems()}
            setValue={onChange("status")}
          /> */}
          <Select
            label="productManagement.products.allProducts.brand"
            placeholder="Select"
            value={params?.get("brandId")}
            items={createItems("brand")}
            setValue={onChange("brandId")}
          />
          <Select
            label="productManagement.products.allProducts.suppliers"
            placeholder="Select"
            value={params?.get("supplierId")}
            items={createItems("supplier")}
            setValue={onChange("supplierId")}
          />
          <Select
            label="productManagement.products.allProducts.program"
            placeholder="Select"
            value={params?.get("programId")}
            items={createItems("program")}
            setValue={onChange("programId")}
          />
          {/* <Select
            label="productManagement.products.allProducts.store"
            placeholder="Select"
            value={filterData.storeCode}
            items={createItems("store")}
            setValue={onChange("storeCode")}
          /> */}

          <div className="grid grid-cols-2 gap-2 gap-y-4">
            <span className="col-span-2 text-sm text-gray-800">
              <Text>productManagement.products.allProducts.priceRange</Text>
            </span>
            <InputGroup
              keyfilter="onlyNum"
              placeholder="productManagement.products.allProducts.from"
              value={params?.get("priceFrom")}
              setValue={onChange("priceFrom")}
            />
            <InputGroup
              keyfilter="onlyNum"
              placeholder="productManagement.products.allProducts.to"
              value={params?.get("priceTo")}
              setValue={onChange("priceTo")}
            />
          </div>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
};

export default FilterDrawer;
