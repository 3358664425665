export const measurementUnits = {
    addMeasurement: "Add Measurement",
    general: "General",
    basicInfo: "Basic Info",
    units: "Units",
    name: "Name",
    code: "Code",
    symbol:"Symbol",
    addUnit: "Add Unit",
    editUnit: "Edit Unit",
    unitsList:"Units List",
    standardUnitCode:"Standard Unit Code",
    standardUnitName:"Standard Unit Name",
    standardUnitSymbol:"Standard Unit Symbol"

}