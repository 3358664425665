// import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Icon, Skeleton, Text, Wrapper, NoItems } from "components";
import { Fragment, useEffect, useState } from "react";
import { ServiceApi } from "services";
type statusType = {
  count: number;
  title: string | null;
  value: string;
};
type sellOfProductType = {
  icon: string;
  label: string;
  value: number;
  className: string;
};
const sellOfProducts: sellOfProductType[] = [
  {
    icon: "clock-three",
    label: "Preparation",
    value: 0,
    className: "bg-warning-light text-warning",
  },
  {
    icon: "cart-shopping",
    label: "Active",
    value: 0,
    className: "bg-success-light text-success",
  },
  {
    icon: "clipboard-check",
    label: "Sold",
    value: 0,
    className: "bg-info-light text-info",
  },
  {
    icon: "square-xmark",
    label: "Closed",
    value: 0,
    className: "bg-danger-light text-danger",
  },
];
export default function SellOffProducts() {
  const [data, setData] = useState<sellOfProductType[]>([]);
  const [loading, setLoading] = useState(false);
  const getData = () => {
    setLoading(true);
    const url = "/productservice/api/selloffproducts/search-sell-off";
    const body = { pageNumber: 1, pageSize: 10 };
    ServiceApi.post(url, body)
      .then(({ data }) => {
        const status: statusType[] = data.status;
        const result = sellOfProducts?.map((product) => {
          const resultItem = status.find(
            (e) => e.value.toLowerCase() === product.label.toLowerCase()
          );
          product.value = resultItem?.count ?? 0;
          return product;
        });
        setData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(getData, []);
  return (
    <Wrapper>
      <Wrapper.Header>
        <h2 className="text-gray-800">
          <Text>dashboard.sellOffProducts</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body className="space-y-4">
        <ul className="space-y-4">
          {loading ? (
            <Fragment>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Fragment>
          ) : data ? (
            data?.map((e) => (
              <li
                key={e.label}
                className={`flex items-center gap-4 p-4 rounded ${e.className}`}
              >
                <span className="w-5 h-5 flex-center">
                  <Icon icon={e.icon} />
                </span>
                <h5 className="text-gray-700 flex-1 font-normal">{e.label}</h5>
                <h3 className="text-gray-700">{e.value}</h3>
              </li>
            ))
          ) : (
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          )}
        </ul>
      </Wrapper.Body>
    </Wrapper>
  );
}
