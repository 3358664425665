import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text
} from "components";
import { appPermissions, config } from "constant";
import { usePermission, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import AddDrawer from "./add-drawer";

type BrandItems = {
  brandId: string;
  code: string;
  name: string;
  description: string;
  logoThumbnailUrl: any
  logoUrl: any
};


type ListType = {
  items?: BrandItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

export default function Brand() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [isOpenDrawerCreate, toggleDrawerCreate] = useToggleState(false);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();


  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams((prev) => {
        value !== null && value !== "" ?
          (key === "pageNumber" && value === 1) ? prev.delete(key) : prev.set(key, value) : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber")
        return prev
      })
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_BRANDS_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, config).then(({ data }) => {
      setData(data);
    }).finally(() => {
      setLoadingList(false);
    });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps


  //permissions
  const { PS_GetBrands, PS_GetBrandDetail, PS_CreateBrand } = appPermissions;
  const GET_LIST_PERMISSION = usePermission(PS_GetBrands);
  const CREATE_PERMISSION = usePermission(PS_CreateBrand);
  const GET_DETAIL_PERMISSION = usePermission(PS_GetBrandDetail);


  return GET_LIST_PERMISSION ? (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        {loadingList ? (
          <Skeleton.Button />
        ) : (CREATE_PERMISSION ?
          <Button
            type="button"
            onClick={toggleDrawerCreate}
          >
            <Icon className="mr-2" icon={"plus"} /><Text>productManagement.masterData.brand.addBrand</Text>
          </Button>
          : null)}
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ?
            <>
              <Table>
                {data?.items?.map((item) => {
                  return (
                    <tr key={item.brandId} >
                      <td>
                        <Link to={GET_DETAIL_PERMISSION ? item.brandId : ""} className="w-fit inline-flex flex gap-2">
                          <Table.Logo className="w-unset" src={item?.logoThumbnailUrl} alt={item.name} />
                          <div className="space-y-4">
                            <h6 className="text-heading-6">{item.name}</h6>
                            <div><span className="text-body-2 text-gray-500">{item.code}</span></div>
                          </div>
                        </Link>
                      </td>
                      <td className="text-body-base text-gray-600 text-left">{item.description}</td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button as={Link} to={item.brandId} variant="light" size="sm">
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={params?.get("pageNumber") ? parseInt(params?.get("pageNumber") as string) : 1}
                pageSize={params?.get("pageSize") ? parseInt(params?.get("pageSize") as string) : config.pageSize}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) => setParams((p) => ({ ...p, pageSize: e }))}
              />
            </>
            :
            <div className="flex flex-col items-center space-y-4 w-full">
              <NoItems />
            </div>
          }
        </>
      )}
      <AddDrawer isOpen={isOpenDrawerCreate} toggle={toggleDrawerCreate} getData={getData} />
    </div>
  ) : (
    <>Permission Error</>
  );
}
