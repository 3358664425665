import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { sizeType } from "types";
import Portal from "./Portal";
type drawerMainProps = {
  className?: string;
  children: ReactNode;
};
type drawerContextType = {
  size: sizeType;
};
type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  size?: sizeType;
};

const DrawerContext = createContext({} as drawerContextType);
function Drawer({
  className = "",
  children,
  isOpen,
  toggle,
  size = null,
}: drawerMainProps & drawerProps) {
  const drawerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    drawerRef.current?.classList.toggle("active", isOpen);
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);
  return (
    <Portal>
      <div
        ref={drawerRef}
        className={`drawer group fixed inset-0 flex z-[90] bg-black/20 opacity-0 pointer-events-none transition-opacity !m-0 [&.active]:opacity-100 [&.active]:pointer-events-auto ${className}`}
      >
        <button
          type="button"
          onClick={toggle}
          className="h-full flex-1 opacity-0 cursor-default min-w-[5%]"
        />
        <DrawerContext.Provider value={{ size }}>
          {children}
        </DrawerContext.Provider>
      </div>
    </Portal>
  );
}
function DrawerMenu({ children, className = "" }: drawerMainProps) {
  const { size } = useContext(DrawerContext);
  const handleSizeWidth = useMemo(() => {
    if (!size) return "w-[31rem]";
    const sizes = {
      sm: "",
      md: "",
      lg: "w-[50vw]",
      xl: "w-[75vw]",
    };
    return sizes[size];
  }, [size]);
  return (
    <div
      className={`${handleSizeWidth} h-full max-w-full flex flex-col bg-white rounded-l translate-x-full transition-[transform,width] group-[.drawer.active]:translate-x-0 ${className}`}
    >
      {children}
    </div>
  );
}
function DrawerHeader({ className = "", children }: drawerMainProps) {
  return (
    <div className={`py-3 px-6 text-center border-b border-gray ${className}`}>
      {children}
    </div>
  );
}
function DrawerBody({ className = "", children }: drawerMainProps) {
  return (
    <div className={`py-3 px-6 flex-1 overflow-auto ${className}`}>
      {children}
    </div>
  );
}
function DrawerFooter({ className = "", children }: drawerMainProps) {
  return (
    <div className={`w-full px-6 py-3 border-t border-gray ${className}`}>
      {children}
    </div>
  );
}
Drawer.Menu = DrawerMenu;
Drawer.Header = DrawerHeader;
Drawer.Body = DrawerBody;
Drawer.Footer = DrawerFooter;
export default Drawer;
