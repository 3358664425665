export const profile = {
  basicInfo: "Grundlegende Informationen",
  companyName: "Firmenname",
  setting: "Einstellungen",
  general: "Allgemein",
  language: "Sprache",
  phoneNumber: "Telefonnummer",
  mobileNumber: "Handynummer",
  shortDescription: "Kurzbeschreibung",
  dashboardLanguage: "Dashboard-Sprache",
  contentLanguage: "Inhaltssprache",
  activeContentLanguage: "Aktive Inhaltssprache",
  activeDashboardLanguage: "Aktive Dashboard-Sprache",
  defaultContentLanguage: "Standard-Inhaltssprache",
  defaultDashboardLanguage: "Standard-Dashboard-Sprache",
  customerSettings: "Kundeneinstellungen",
  discountSettings: "Rabatteinstellungen",
  customerNumberPrefix: "Kundennummernpräfix",
  title: "Titel",
  changeAvatar: "Avatar ändern",
  upload: "Hochladen",
  channels: "Channels DE",
  availableLanguages: "Available languages DE",
  defaultLanguage: "Default Language DE"
};
