export default function arrayToMatrix<T>(
  list: T[] | null | undefined = [],
  perSubArray: number = 3
) {
  if (!list) return [];
  var matrix: T[][] = [],
    i,
    k;
  for (i = 0, k = -1; i < list.length; i++) {
    if (i % Math.ceil(perSubArray) === 0) {
      k++;
      matrix[k] = [];
    }
    matrix[k].push(list[i]);
  }
  return matrix;
}
