import { useId, ReactNode } from "react";
import Text from "./Text";

type radioButtonProps = {
  label?: string;
  children?: ReactNode;
  value: boolean;
  disabled?: boolean;
  setValue?: (val: boolean) => void;
};

export default function RadioButton({
  value,
  setValue = () => {},
  label,
  disabled = false,
  children,
}: radioButtonProps) {
  const id = useId();
  return (
    <label
      data-active={value}
      className="radioButton group w-full flex-center gap-4"
    >
      <input
        id={id}
        type="radio"
        checked={value}
        disabled={disabled}
        onChange={() => setValue(!value)}
        hidden
      />
      <span className="flex items-center justify-center cursor-pointer h-5 w-5 rounded-full text-center border bg-gray-200 group-data-active:bg-primary transition-colors">
        <i className="bi-circle-fill text-[9px] align-text-bottom text-white opacity-0 group-data-active:opacity-100 transition-opacity" />
      </span>
      {label && (
        <span className="flex-1" data-lang-map={label}>
          <Text>{label}</Text>
        </span>
      )}
      {children && <div className="flex-1">{children}</div>}
    </label>
  );
}
