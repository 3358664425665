const CUSTOMERS = {
  // ----------------------  Customers  ------------------------
  ADD_CUSTOMERS_URL: "accountservice/api/customers",
  GET_CUSTOMERS_COMPANIES: "accountservice/api/customers/company",
  GET_CUSTOMERS_PERSON_OF_COMPANY_URL:
    "accountservice/api/customers/person-of-company",
  GET_CUSTOMERS_URL: "accountservice/api/customers/",
  DETAILS_CUSTOMERS_URL: (id) => `accountservice/api/customers/${id}`,
  PUT_CUSTOMERS_URL: (id) => `accountservice/api/customers/${id}`,

  //------------------------ customer terminal--------------
  CUSTOMER_TRMINAL_URL: "accountservice/api/customer-terminals",
  GET_UPDATE_CUSTOMER_TRMINAL_DETAIL_URL:
    "accountservice/api/customer-terminals/",
};

export default CUSTOMERS;
