import { useContext } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Button, Icon, InputGroup, Text, Wrapper } from "components";
import { rules } from "constant";
import { StoreContext } from ".";

export default function OpeningHours() {
  const { storeData, setStoreData } = useContext(StoreContext);
  const handleSetValue = (index, key) => {
    return (value) =>
      setStoreData((p) => {
        const data = cloneDeep(p);
        data.openingHours[index][key] = value;
        return data;
      });
  };
  const removeItem = (index) => {
    return () =>
      setStoreData((p) => {
        const data = cloneDeep(p);
        data.openingHours.splice(index, 1);
        return data;
      });
  };
  const addDay = () => {
    const item = { code: "", value: "" };
    setStoreData((p) => {
      const data = cloneDeep(p);
      data.openingHours.push(item);
      return data;
    });
  };
  return (
    <Wrapper>
      <Wrapper.Header>
        <h1 className="text-heading-2 font-semibold">
          <Text>company.stores.openingHours</Text>
        </h1>
      </Wrapper.Header>
      <Wrapper.Body className="space-y-8">
        {storeData.openingHours?.map((e, i) => (
          <div className="flex items-start gap-6 w-full lg:w-1/2" key={i}>
            <div className="flex-1">
              <InputGroup
                label="company.stores.day"
                value={e.code}
                setValue={handleSetValue(i, "code")}
                rules={rules.required}
              />
            </div>
            <div className="flex-1">
              <InputGroup
                label="company.stores.openingHours"
                value={e.value}
                setValue={handleSetValue(i, "value")}
                rules={rules.required}
              />
            </div>
            <button
              type="button"
              className="w-11 h-11 flex-center mt-9"
              onClick={removeItem(i)}
            >
              <Icon icon="trash" size="lg" className="text-danger" />
            </button>
          </div>
        ))}
        <Button
          type="button"
          variant={null}
          //   outline
          className="block w-fit bg-gray-100 text-primary hover:bg-primary-active hover:text-white"
          onClick={addDay}
        >
          <Text>company.stores.addDay</Text>
        </Button>
      </Wrapper.Body>
    </Wrapper>
  );
}
