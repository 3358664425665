import { Button, HandleName, Icon, Skeleton, Text, Wrapper } from "components";
import { useToggleState } from "hooks";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { classNames } from "utils";
import AddCategory from "./drawers/add-category";

type Props = {
  roots: any;
  setRoots: any;
  loading: boolean;
  selectedIndex: any;
  setSelectedIndex: any;
  getCategories: any;
};

const LeftSide = ({
  roots,
  selectedIndex,
  setSelectedIndex,
  loading,
  getCategories,
}: Props) => {
  const [isActive, setIsActive] = useToggleState(true);
  const [isOpen, toggle] = useToggleState();
  const { i18n } = useTranslation();

  const drawer = classNames(
    "flex flex-center rounded overflow-hidden p-[5px] w-8 h-8 transition-transform absolute -right-[.9rem] bg-white shadow top-1/2",
    {
      "rotate-180": !isActive,
    }
  ); //rotate-180

  const panel = classNames(`flex-none relative transition-[width] h-[641px]`, {
    "!w-[374px]": isActive,
    "!w-[66px]": !isActive,
  });

  return (
    <Wrapper className={panel}>
      <div
        className={`flex transition-transform border-b h-[70px] ${isActive ? "p-4 justify-between" : "py-4 px-1 justify-center"
          }`}
      >
        <div className="flex gap-2 items-center">
          <Icon
            icon={"list-tree"}
            size="1x"
            className="text-primary flex-none"
          />
          <CSSTransition
            in={isActive}
            timeout={50}
            unmountOnExit
            classNames="alert"
          >
            <span className="text-heading-2 font-semibold text-gray-800">
              <Text>
                productManagement.masterData.productCategory.primaryCategories
              </Text>
            </span>
          </CSSTransition>
        </div>
        <CSSTransition
          in={isActive}
          timeout={50}
          unmountOnExit
          classNames="alert"
        >
          <Button size="sm" onClick={toggle}>
            <Icon icon={"plus"} className="mr-2" />
            <Text>productManagement.masterData.productCategory.addButton</Text>
          </Button>
        </CSSTransition>
      </div>
      <div className="space-y-4 flex flex-col p-6 bg-white rounded">
        {loading &&
          [1, 2, 3, 4, 5].map((item: any) => <Skeleton.Input key={item} />)}
        {!loading &&
          roots?.map((category: any, index: number) => {
            return (
              <div
                className={` h-12 rounded text-body-base shadow-sm p-1 cursor-pointer ${index === selectedIndex
                  ? "bg-primary-light text-primary font-semibold"
                  : "bg-gray-100 text-gray-800 font-normal"
                  }`}
                onClick={() => setSelectedIndex(index)}
              >
                <CSSTransition
                  in={isActive}
                  timeout={200}
                  unmountOnExit
                  classNames="alert"
                >
                  <span className="w-full h-full flex px-4 items-center">
                    {<HandleName translations={category?.translations} code={category?.code} keyName="name"  />}
                  </span>
                </CSSTransition>
              </div>
            );
          })}
      </div>
      <button
        className={drawer}
        onClick={() => {
          setIsActive();
        }}
      >
        <Icon icon={"chevrons-left"} size="1x" className="text-primary" />
      </button>
      {/* {isOpen && ( */}
      <AddCategory
        isOpen={isOpen}
        toggle={toggle}
        getCategories={getCategories}
      />
      {/* )} */}
    </Wrapper>
  );
};

export default LeftSide;
