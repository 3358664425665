const ETC = {
  // ------------- product family--------------------
  PRODUCT_FAMILY_URL: "productservice/api/productfamilies",

  // ------------- preparation phase-------------------
  PREPARATION_PHASE_URL: "/productservice/api/preparationphase",

  //-------------------- UPLOAD IMAGES -------------
  UPLOAD_IMAGES_URL: "/assetservice/api/assets/batch",

  //-------------------- LAHOMA ------------------
  CREATE_LAHOMA_KEY: "accountservice/api/lahomas",
  LAHOMA_ORDER: "productservice/api/lahomaorders",

  //--------------Inquiry----------------------
  GET_INQUIRIES_URL: "supportservice/api/inquiries/search-inquiry",
};

export default ETC;
